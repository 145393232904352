import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpGetsUserDetailsService } from './http-gets-user-details.service';
import { GETS_USER_DETAILS_DTO } from '../../application/ports/secondary/gets-user-details.dto-port';

@NgModule({
  providers: [
    HttpGetsUserDetailsService,
    {
      provide: GETS_USER_DETAILS_DTO,
      useExisting: HttpGetsUserDetailsService,
    },
  ],
  imports: [CommonModule],
})
export class HttpGetsUserDetailsServiceModule {}

export class PlanEstimate {
  constructor(
    public readonly nett: number,
    public readonly gross: number,
    public readonly vatValue: number,
    public readonly vatPercentage: number,
    public readonly hasValidDiscountCode: boolean,
    public readonly grossValueBeforeDiscount: number,
  ) {}
}

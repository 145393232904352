import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserIsInvitedEvent } from '../../../application/events/user-is-invited.event';
import {
  SetsIsInvitedUserQueryPort,
  SETS_IS_INVITED_USER_QUERY,
} from '../../../application/ports/primary/sets-is-invited-user.query-port';

@Injectable()
export class UserIsInvitedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserIsInvitedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_IS_INVITED_USER_QUERY)
    private _setIsInvitedUser: SetsIsInvitedUserQueryPort,
  ) {}

  handle([event]: [UserIsInvitedEvent]) {
    this._setIsInvitedUser.setIsInvitedUser(event.isUserInvited).subscribe();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPaymentCardComponent } from './add-payment-card.component';
import { PaymentMethodFormComponentModule } from '../../../modals/change-payment-plan-modal/upgrade-plan-step/fill-payment-method/payment-method-form/payment-method-form.component-module';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AddPaymentCardComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, PaymentMethodFormComponentModule],
  exports: [AddPaymentCardComponent],
})
export class AddPaymentCardComponentModule {}

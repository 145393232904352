import { NgModule } from '@angular/core';
import { LabelManagerRecommendationState } from './label-manager-recommendation.state';
import { CLOSES_LM_RECOMMENDATION_QUERY } from '../../ports/primary/closes-lm-recommendation.query-port';
import { GETS_CURRENT_LM_RECOMMENDATION_QUERY } from '../../ports/primary/gets-current-lm-recommendation.query-port';
import { SETS_LABEL_MANAGER_CONFIG_FOR_RECOMMENDATION_COMMAND_PORT } from '../../ports/primary/sets-label-manager-config-for-recommendation.command-port';
import { SETS_LABEL_MANAGER_REPORT_FOR_RECOMMENDATION_COMMAND_PORT } from '../../ports/primary/sets-label-manager-report-for-recommendation.command-port';
import { IS_RECOMMENDATION_LIST_EMPTY_COMMAND_PORT } from '../../ports/primary/is-recommendations-list-empty.command-port';

@NgModule({
  providers: [
    LabelManagerRecommendationState,
    {
      provide: GETS_CURRENT_LM_RECOMMENDATION_QUERY,
      useExisting: LabelManagerRecommendationState,
    },
    {
      provide: CLOSES_LM_RECOMMENDATION_QUERY,
      useExisting: LabelManagerRecommendationState,
    },
    {
      provide: SETS_LABEL_MANAGER_CONFIG_FOR_RECOMMENDATION_COMMAND_PORT,
      useExisting: LabelManagerRecommendationState,
    },
    {
      provide: SETS_LABEL_MANAGER_REPORT_FOR_RECOMMENDATION_COMMAND_PORT,
      useExisting: LabelManagerRecommendationState,
    },
    {
      provide: IS_RECOMMENDATION_LIST_EMPTY_COMMAND_PORT,
      useExisting: LabelManagerRecommendationState,
    },
  ],
})
export class LabelManagerRecommendationStateModule {}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { ClientsListLoadedEvent } from '../../../application/events/clients-list-loaded.event';
import { Inject, Injectable } from '@angular/core';
import {
  STORES_CLIENTS_LIST_COMMAND,
  StoresClientsListCommandPort,
} from '../../../application/ports/primary/stores-clients-list.command-port';

@Injectable()
export class ClientsListLoadedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ClientsListLoadedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(STORES_CLIENTS_LIST_COMMAND)
    private _storesClientsListCommands: StoresClientsListCommandPort[],
  ) {}

  handle([event]: [ClientsListLoadedEvent]) {
    // TODO - rethink the use of `multi: true` providers
    this._storesClientsListCommands.forEach(commandHandler => {
      commandHandler.storeClientsList(event.clients, event.teamId);
    });
  }
}

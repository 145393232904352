<section class="cs-container h-100">
  <div class="d-flex flex-column justify-content-center align-items-center h-100 cs-text-2">
    <div
      class="cs-card cs-asset-bg-gradient-1 cs-bg-size-contain cs-bg-shade-0 cs-shadow-shade-10 br-15 cs-b-none cs-min-width-700-lg cs-min-width-100-pc-xs cs-pv-80 cs-ph-80-lg cs-ph-20-sm"
    >
      <div class="d-flex justify-content-center cs-mb-20">
        <img class="cs-height-30-xs" src="assets/logos/cobiro-logo-with-text.svg" alt="Cobiro" />
      </div>
      <hui-stepper #stepper>
        <cdk-step>
          <header class="flex-column justify-content-center align-items-center cs-pt-0 cs-m-0">
            <h2 class="cs-title-3 text-center">
              {{ 'login_password_recovery' | language }}
            </h2>
            <p class="cs-text-2 text-center cs-mt-20">
              {{ 'login_password_recovery_remember' | language }}
              <a
                routerLink="/auth/login"
                [relativeTo]="''"
                class="cs-link"
                [libAnalytics]="['password-recovery', 'back-to-login']"
              >
                {{ '_here' | language }}
              </a>
            </p>
          </header>
          <form
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
            class="cs-mt-60 cs-ph-80-lg cs-ph-20-sm cs-mb-15"
          >
            <div class="cs-control-wrapper">
              <input
                class="cs-input"
                placeholder="Email"
                formControlName="email"
                data-selector="recover-email-input"
                data-analytics="recover-email-input"
                autofocus
              />
              <div class="cs-height-20-xs">
                <p *ngIf="hasError('email')" class="cs-control-error">
                  {{ 'login_input_not_email' | language }}
                </p>
              </div>
            </div>

            <button
              class="cs-btn cs-btn-primary w-100 d-flex align-items-center justify-content-center cs-mt-10"
              [disabled]="form.invalid"
              [huiLoading]="isLoading$ | async"
              data-selector="recover-button"
              [libAnalytics]="['password-recovery', 'continue']"
            >
              <span class="cs-c-shade-0">
                {{ '_reset_password' | language }}
              </span>
            </button>
          </form>
        </cdk-step>
        <cdk-step>
          <h2 class="cs-title-3 text-center">
            {{ 'login_password_recovery_check_your_email' | language }}
          </h2>
          <p class="cs-mt-20 cs-text-2 text-center">
            {{ 'login_password_recovery_text_1' | language }}
            <span class="cs-c-shade-100">{{ control('email').value }}</span>
            <br />
            {{ 'login_password_recovery_text_2' | language }}
            <a
              (click)="onResendClicked()"
              class="cs-link"
              [libAnalytics]="['password-recovery', 'back-to-login']"
              >{{ '_here' | language }}</a
            >
          </p>
          <div class="d-flex justify-content-center cs-ph-80-lg cs-ph-20-sm">
            <button
              class="cs-btn cs-btn-primary cs-mt-60 cs-min-width-240-xs"
              routerLink="/auth/login"
              [relativeTo]="''"
              [huiLoading]="isLoading$ | async"
              [libAnalytics]="['password-recovery', 'back-to-login']"
            >
              <span class="cs-c-shade-0">
                {{ 'login_password_recovery_back_login' | language }}
              </span>
            </button>
          </div>
        </cdk-step>
      </hui-stepper>
    </div>
    <a
      class="cs-link text-center d-block cs-pl-10 cs-mt-15"
      href="https://cobiro.com"
      [libAnalytics]="['password-recovery', 'back-to-cobiro']"
      >{{ '_back_to' | language }} cobiro.com</a
    >
  </div>
</section>

import { NgModule } from '@angular/core';
import { HttpRegistersUserService } from './adapters/secondary/http-registers-user.service';
import { REGISTERS_USER } from '@app.cobiro.com/user/core';

@NgModule()
export class CoreUsersRegisterModule {
  static forRoot() {
    return {
      ngModule: CoreUsersRegisterModule,
      providers: [{ provide: REGISTERS_USER, useClass: HttpRegistersUserService }],
    };
  }
}

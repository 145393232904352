import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_CURRENT_WORKSPACE_COMMAND = new InjectionToken<IsCurrentWorkspaceCommandPort>(
  'IS_CURRENT_WORKSPACE_COMMAND',
);

export interface IsCurrentWorkspaceCommandPort {
  isCurrentWorkspace(workspaceId: string): Observable<boolean>;
}

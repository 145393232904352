<div class="cs-card">
  <h2 class="cs-title-4">
    {{ 'user_delete_we_re_sorry' | language }}
  </h2>

  <h4 class="cs-title-5 cs-c-shade-60 cs-mt-20">
    {{ 'user_delete_before_delete' | language }}
  </h4>

  <ul>
    <li class="cs-text-1">
      {{ 'user_delete_info_1_p1' | language }}
      <a class="cs-link" [routerLink]="['../marketing-preferences']">{{
        '_unsubscribe' | language
      }}</a>
      {{ 'user_delete_info_1_p2' | language }}
    </li>
    <li class="cs-text-1">
      {{ 'user_delete_info_2_p1' | language }}
      <a class="cs-link" href="https://support.cobiro.com/hc/en-us">{{
        '_cobiro_support' | language
      }}</a>
      {{ 'user_delete_info_2_p2' | language }}
    </li>
    <li class="cs-text-1">
      {{ 'user_delete_info_3' | language }}
    </li>
    <li class="cs-text-1">
      {{ 'user_delete_info_4' | language }}
    </li>
  </ul>

  <p class="cs-title-7 cs-mt-30">
    {{ 'user_delete_if_still' | language }}
  </p>

  <a (click)="onDisableEmailNotificationsClicked()" data-selector="disable-email-notifications"></a>
  <form class="cs-form cs-mt-5" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="cs-control-wrapper">
      <input
        type="password"
        data-selector="user-password"
        class="cs-input"
        formControlName="password"
        huiInputError="password"
      />
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-end cs-mt-80">
      <button
        class="cs-btn cs-btn-stroked cs-min-width-240-xs"
        data-selector="cancel-delete-button"
        (click)="onCancelDeleteClicked()"
      >
        {{ 'user_delete_change_mind' | language }}
      </button>
      <button
        class="cs-btn cs-btn-error cs-ml-10-md cs-min-width-240-xs"
        type="submit"
        data-selector="user-delete-button"
        [class.is-loading]="isDeleting$ | async"
        [disabled]="form.invalid"
      >
        {{ 'user_delete_account_btn' | language }}
      </button>
    </div>
  </form>
</div>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const LOGINS = new InjectionToken<LoginsUser>('LOGINS');

export interface AuthData {
  id?: string;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
}

export interface LoginsUser {
  login(email: string, password: string): Observable<AuthData>;
}

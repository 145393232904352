import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { NgModule } from '@angular/core';
import { SwitchInProgressComponent } from './switch-in-progress.component';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [SwitchInProgressComponent],
  exports: [SwitchInProgressComponent],
})
export class SwitchInProgressComponentModule {}

import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { TracksInDataLayer } from '../domain/tracks-in-data-layer';

@Injectable()
export class WindowTracksInDataLayerService implements TracksInDataLayer {
  private _dataLayerKey = 'dataLayer';
  constructor(@Inject(WINDOW) private _window: Window) {}
  track(eventName: string, eventData: object = {}) {
    window[this._dataLayerKey] = window[this._dataLayerKey] || [];
    window[this._dataLayerKey].push({ event: eventName, ...eventData });
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ProductQuery } from './product.query';

export interface GetsProductListQuery {
  getAllProducts(): Observable<ProductQuery[]>;
}

export const GETS_PRODUCT_LIST_QUERY = new InjectionToken<GetsProductListQuery>(
  'GETS_PRODUCT_LIST_QUERY',
);

import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { INITS_LM_SIMULATION_DTO } from '../../../../application/ports/secondary/inits-lm-simulation.dto-port';
import { HttpLabelManagerSimulationService } from './http-label-manager-simulation.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpLabelManagerSimulationService,
    {
      provide: INITS_LM_SIMULATION_DTO,
      useExisting: HttpLabelManagerSimulationService,
    },
  ],
})
export class HttpLabelManagerSimulationServiceModule {}

import { CardDto } from '../../secondary/dto/payments/card.dto';

export class CardQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly maskedNumber: string,
    public readonly logo: string,
  ) {}

  static fromDto(dto: CardDto): CardQuery {
    return new CardQuery(
      dto.id,
      dto.name,
      `XXXX-XXXX-XXXX-${dto.lastDigits}`,
      `assets/payment-plans/images/${dto.brand}.svg`,
    );
  }
}

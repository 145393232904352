export class Image {
  public readonly sources: Map<string, string>;
  private readonly sourceMapper = new Map([
    ['thumb', '200w'],
    ['small', '400w'],
    ['regular', '1080w'],
    ['large', '1920w'],
    ['xlarge', '2560w'],
    ['fallback', '1080w'],
    ['download', ''],
  ]);

  constructor(
    public id: string,
    public urls: HasImageUrls,
    public author?: { name: string; link: string },
    public description?: string,
  ) {
    this.sources = Object.keys(urls).reduce((map, key) => {
      map.set(this.sourceMapper.get(key), urls[key]);
      return map;
    }, new Map());
  }
}

export interface HasImageUrls {
  thumb: string;
  small: string;
  regular: string;
  large: string;
  xlarge: string;
  fallback: string;
  download: string;
}

export class ImageListResponse {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        author: {
          name: string;
          link: string;
        };
        description: string;
      };
      links: HasImageUrls;
    },
  ];
  links: {
    next: string;
  };
}

export class ImageUploadResponse {
  data: {
    links: HasImageUrls;
  };
}

export class ImageSearchQueryParams implements HasPage {
  query: string;
  page: number;
  color?: string;
  orientation?: string;
}

export interface HasPage {
  page: number;
}

export class FileMedia {
  mimeType: string;
  content: string;
}

export class ProcessedImage extends FileMedia {
  public readonly mimeType: string;
  public readonly content: string;
  constructor(processedEventResult: string) {
    super();
    const [mimeString, contentString] = processedEventResult.split(';');
    this.mimeType = mimeString.replace('data:', '');
    this.content = contentString.replace('base64,', '');
  }
}

export class NoImageUploadTargetError extends Error {
  message = 'No image upload target was specified';
  name = 'NO_IMAGE_UPLOAD_TARGET';
}

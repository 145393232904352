/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HasData, HasErrorResponse } from '@cobiro/jsonapi';
import { catchError, map } from 'rxjs/operators';
import {
  SaveTeamSettingsErrorDto,
  SaveTeamSettingsErrorTypes,
} from '../../../../application/ports/secondary/dto/settings/save-team-settings-error.dto';
import { SavesTeamSettingsDtoPort } from '../../../../application/ports/secondary/dto/settings/saves-team-settings.dto-port';
import { GetsTeamSettingsDtoPort } from '../../../../application/ports/secondary/dto/settings/gets-team-settings.dto-port';
import { TeamSettingsDto } from '../../../../application/ports/secondary/dto/settings/team-settings.dto';
import { SavesTeamSettingsDto } from '../../../../application/ports/secondary/dto/settings/saves-team-settings.dto';
import { GetsTeamSettingsDto } from '../../../../application/ports/secondary/dto/settings/gets-team-settings.dto';

export interface TeamSettingsAttributes {
  workspaceName: string;
  details: {
    companyName: string;
    contactEmail: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    countryCode: string;
    vatNumber: string | null;
    avatar?: string;
  };
}

@Injectable()
export class HttpTeamSettingsService implements GetsTeamSettingsDtoPort, SavesTeamSettingsDtoPort {
  private readonly _errors: Map<string, number> = new Map([
    ['INVALID_ZIP_CODE', SaveTeamSettingsErrorTypes.ZIP_CODE],
    ['INVALID_VAT_NUMBER', SaveTeamSettingsErrorTypes.VAT_NUMBER],
  ]);
  constructor(private readonly _client: GatewayClient) {}

  getTeamSettings(dto: GetsTeamSettingsDto): Observable<TeamSettingsDto | null> {
    return this._client
      .get<HasData<TeamSettingsAttributes>>(
        `v1/sites/workspaces/${dto.workspaceId}/billing-settings`,
      )
      .pipe(
        map(response => {
          return response.data.attributes.details
            ? {
                agencyName: response.data.attributes.details.companyName || null,
                contactEmail: response.data.attributes.details.contactEmail || null,
                addressLine1: response.data.attributes.details.addressLine1 || null,
                addressLine2: response.data.attributes.details.addressLine2 || null,
                zipCode: response.data.attributes.details.zipCode || null,
                city: response.data.attributes.details.city || null,
                countryCode: response.data.attributes.details.countryCode || null,
                vatNumber: response.data.attributes.details.vatNumber || null,
                avatar: response.data.attributes.details.avatar || null,
              }
            : null;
        }),
      );
  }

  saveTeamSettings(dto: SavesTeamSettingsDto): Observable<boolean> {
    const body: HasData<Partial<TeamSettingsAttributes>> = {
      data: {
        id: dto.workspaceId,
        type: 'billing-settings',
        attributes: {
          details: {
            companyName: dto.settings.agencyName,
            contactEmail: dto.settings.contactEmail,
            addressLine1: dto.settings.addressLine1,
            addressLine2: dto.settings.addressLine2,
            zipCode: dto.settings.zipCode,
            city: dto.settings.city,
            countryCode: dto.settings.countryCode,
            vatNumber: dto.settings.vatNumber,
          },
        },
      },
    };
    return this._client.patch(`v1/sites/workspaces/${dto.workspaceId}/billing-settings`, body).pipe(
      map(() => true),
      catchError((error: HasErrorResponse) => {
        const rawErrors = error?.error?.errors ?? [];
        return throwError(
          rawErrors.map(error => new SaveTeamSettingsErrorDto(this._errors.get(error.detail))),
        );
      }),
    );
  }
}

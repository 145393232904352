<div [class.overflow-hidden]="isAnimated" class="h-100">
  <div
    *ngFor="let step of steps; let i = index"
    [@.disabled]="!isAnimated"
    [@horizontalStepTransition]="_getAnimationDirection(i)"
    (@horizontalStepTransition.done)="onAnimationEnded($event)"
    [id]="_getStepContentId(i)"
    [ngStyle]="{
      overflow: selectedIndex === i ? 'inherit' : 'hidden',
      height: selectedIndex === i ? 'inherit' : '0'
    }"
  >
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
</div>

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { GetsVatNumberValidationQueryPort } from '../ports/primary/gets-vat-number-validation.query-port';
import { SavesSelectedCountryCommand } from '../ports/primary/saves-selected-country.command';
import { SavesSelectedCountryCommandPort } from '../ports/primary/saves-selected-country.command-port';
import { VatNumberValidationQuery } from '../ports/primary/vat-number-validation.query';
import {
  GETS_TEAM_SETTINGS_DTO_PORT,
  GetsTeamSettingsDtoPort,
} from '../ports/secondary/gets-team-settings.dto-port';
import { GetsTeamSettingsQueryPort } from '../ports/primary/gets-team-settings.query-port';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { TeamSettingsQuery } from '../ports/primary/team-settings.query';
import { catchError, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { SavesTeamSettingsCommand } from '../ports/primary/saves-team-settings.command-port';
import {
  SAVES_TEAM_SETTINGS_DTO_PORT,
  SavesTeamSettingsDtoPort,
} from '../ports/secondary/saves-team-settings.dto-port';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { TeamSettingsDto } from '../ports/secondary/team-settings.dto';
import { LoadsTeamSettingsCommandPort } from '../ports/primary/loads-team-settings.command-port';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { TEAM_UPDATED_DISPATCHER } from '../ports/secondary/team-updated.dispatcher-port';
import { ApplicationBus, APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import {
  CobiroProTeamUpdatedEvent,
  CobiroProWorkspaceSwitchedEvent,
  PaymentBillingEvent,
} from '@app.cobiro.com/core/events';
import { ClearsTeamSettingsCommandPort } from '../ports/primary/clears-team-settings.command-port';
import { GetsCountriesQueryPort } from '../ports/primary/gets-countries.query-port';
import { CountryQuery } from '../ports/primary/country.query';
import { CountryDto } from '../ports/secondary/country.dto';
import {
  GETS_COUNTRIES_DTO,
  GetsCountriesDtoPort,
} from '../ports/secondary/gets-countries.dto-port';
import {
  SaveTeamSettingsErrorDto,
  SaveTeamSettingsErrorTypes,
} from '../ports/secondary/save-team-settings-error.dto';
import { GetsSaveTeamCommandErrorQueryPort } from '../ports/primary/gets-save-team-command-error.query-port';
import {
  GetsVatNumberValidationDtoPort,
  GETS_VAT_NUMBER_VALIDATION_DTO,
} from '../ports/secondary/gets-vat-number-validation.dto-port';
import { GetsUserDetails, GETS_USER_DETAILS } from '@app.cobiro.com/user/core';

const COUNTRY_CODES_TO_PASS_VAT_VALIDATION = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'EL',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

@Injectable()
export class TeamSettingsState
  implements
    GetsTeamSettingsQueryPort,
    SavesTeamSettingsCommand,
    LoadsTeamSettingsCommandPort,
    ClearsTeamSettingsCommandPort,
    GetsCountriesQueryPort,
    GetsSaveTeamCommandErrorQueryPort,
    GetsVatNumberValidationQueryPort,
    SavesSelectedCountryCommandPort
{
  constructor(
    @Inject(GETS_TEAM_SETTINGS_DTO_PORT)
    private readonly _getsWorkspaceSettingsDto: GetsTeamSettingsDtoPort,
    @Inject(SAVES_TEAM_SETTINGS_DTO_PORT)
    private readonly _savesWorkspaceSettingsDto: SavesTeamSettingsDtoPort,
    // TODO: (PRO-DEBT) query used in state
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(TEAM_UPDATED_DISPATCHER)
    private readonly _cobiroProTeamUpdatedEventDispatcher: Dispatcher<CobiroProTeamUpdatedEvent>,
    @Inject(GETS_COUNTRIES_DTO)
    private readonly _getCountriesDto: GetsCountriesDtoPort,
    private readonly _alert: HuiAlert,
    @Inject(GETS_VAT_NUMBER_VALIDATION_DTO)
    private readonly _getsVatNumberValidationDto: GetsVatNumberValidationDtoPort,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(GETS_USER_DETAILS)
    private readonly _getUserDetails: GetsUserDetails,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
  ) {
    this._applicationBus
      .on(CobiroProWorkspaceSwitchedEvent)
      .pipe(switchMap(() => this.loadTeamsSettings()))
      .subscribe();

    this._getCountriesDto.getCountries().subscribe(countries => {
      this._countries$.next(countries);
    });
  }

  private readonly _teamSettingsErrorsMap: Map<SaveTeamSettingsErrorTypes, string> = new Map([
    [SaveTeamSettingsErrorTypes.VAT_NUMBER, 'vatNumber'],
    [SaveTeamSettingsErrorTypes.ZIP_CODE, 'zipCode'],
  ]);
  private readonly _errorFields$ = new Subject<string[]>();
  private readonly _teamSettingsDto$ = new BehaviorSubject<TeamSettingsDto | null>(null);
  private readonly _countries$ = new BehaviorSubject<CountryDto[] | null>(null);
  private readonly _selectedCountry$ = new BehaviorSubject<string>('');

  getTeamSettings(): Observable<TeamSettingsQuery | null> {
    return this._teamSettingsDto$
      .asObservable()
      .pipe(
        map(workspaceSettingsDto =>
          workspaceSettingsDto ? TeamSettingsQuery.fromDto(workspaceSettingsDto) : null,
        ),
      );
  }

  getSaveTeamCommandErrorQuery(): Observable<string[]> {
    return this._errorFields$.asObservable();
  }

  saveTeamSettings(settings: {
    agencyName: string;
    contactEmail: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    countryCode: string;
    vatNumber: string | null;
    avatar: string | null;
  }): Observable<boolean> {
    return this._savesWorkspaceSettingsDto
      .saveTeamSettings({
        settings: { ...settings },
        workspaceId: this._workspaceIdGetter.getWorkspaceId(),
      })
      .pipe(
        switchMap(() => this._getsCobiroProContextQuery.getContext()),
        take(1),
        switchMap((context: CobiroProContextQuery) =>
          this._setsSelectedTeamCommand.setSelectedTeam(
            context.selectedTeamId,
            settings.agencyName,
            context.selectedTeamUserRole,
            context.selectedTeamAvatar,
          ),
        ),
        tap({
          next: () =>
            this._alert.open('success', 'cobiro_pro_workspace_settings_form_submit_success'),
          error: () => this._alert.open('error', 'cobiro_pro_workspace_settings_form_submit_error'),
        }),
        catchError((errors: SaveTeamSettingsErrorDto[]) => {
          const failedFieldNames = errors.map(error => this._teamSettingsErrorsMap.get(error.type));
          this._errorFields$.next(failedFieldNames);
          return of(false);
        }),
      );
  }

  getCountriesQuery(): Observable<CountryQuery[]> {
    return this._countries$.asObservable().pipe(
      filter(countries => Boolean(countries)),
      map(countries => countries && countries.map(({ name, code }) => ({ name, code }))),
      shareReplay(1),
    );
  }

  getVatValidation(vatNumber: string): Observable<VatNumberValidationQuery> {
    if (!this._selectedCountry$.getValue()) {
      return of(new VatNumberValidationQuery(false));
    }
    if (
      !COUNTRY_CODES_TO_PASS_VAT_VALIDATION.includes(
        this._selectedCountry$.getValue().toUpperCase(),
      )
    ) {
      return of(new VatNumberValidationQuery(true));
    }

    return this._getsVatNumberValidationDto
      .getVatValidation(this._selectedCountry$.getValue(), vatNumber)
      .pipe(map(dto => new VatNumberValidationQuery(dto.isValid, 'invalidField')));
  }

  loadTeamsSettings(): Observable<void> {
    return this._getsWorkspaceSettingsDto
      .getTeamSettings({ workspaceId: this._workspaceIdGetter.getWorkspaceId() })
      .pipe(
        switchMap((teamSettingsDto: TeamSettingsDto | null) =>
          !teamSettingsDto
            ? combineLatest([
                this._getsCobiroProContextQuery.getContext(),
                this._getUserDetails.getDetails(),
              ]).pipe(
                map(([context, userDetails]) => {
                  return {
                    agencyName: context.selectedTeamName || null,
                    contactEmail: userDetails.email || null,
                    addressLine1: null,
                    addressLine2: null,
                    zipCode: null,
                    city: null,
                    countryCode: userDetails.country || null,
                    vatNumber: null,
                    avatar: null,
                  };
                }),
              )
            : of(teamSettingsDto),
        ),
        tap((teamSettingsDto: TeamSettingsDto) => {
          this._applicationBus.dispatch(
            new PaymentBillingEvent(
              null,
              this._workspaceIdGetter.getWorkspaceId(),
              teamSettingsDto.agencyName,
              teamSettingsDto.contactEmail,
              teamSettingsDto.addressLine1,
              teamSettingsDto.addressLine2,
              teamSettingsDto.zipCode,
              teamSettingsDto.city,
              teamSettingsDto.countryCode,
              teamSettingsDto.vatNumber,
              teamSettingsDto.avatar,
            ),
          );
          this._teamSettingsDto$.next(teamSettingsDto);
        }),
        map(() => void 0),
      );
  }

  clearTeamSettings(): void {
    this._teamSettingsDto$.next(null);
  }

  saveSelectedCountry(command: SavesSelectedCountryCommand): Observable<void> {
    this._selectedCountry$.next(command.countryCode);
    return of(void 0);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { IntegrationErrorComponent } from './integration-error.component';
import { IntegrationErrorSanitizerPipe } from './integration-error.sanitizer-pipe';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [IntegrationErrorComponent, IntegrationErrorSanitizerPipe],
  exports: [IntegrationErrorComponent],
})
export class IntegrationErrorComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetsSearchPhraseCommand } from './sets-search-phrase.command';

export interface SetsSearchPhraseCommandPort {
  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void>;
}

export const SETS_CLIENTS_LIST_SEARCH_PHRASE_COMMAND =
  new InjectionToken<SetsSearchPhraseCommandPort>('SETS_CLIENTS_LIST_SEARCH_PHRASE_COMMAND');

import { Inject, Injectable } from '@angular/core';
import {
  ApplicationEvent,
  ApplicationEventsHandler,
  ongoingEventsOrchestrationStrategy,
} from '@cobiro/eda';
import { CobiroProBackToWorkspaceEvent } from '@app.cobiro.com/core/events';
import {
  BACK_TO_CURRENT_TEAM_COMMAND,
  BackToCurrentTeamCommandPort,
} from '../../application/ports/primary/back-to-current-team-command.port';

@Injectable()
export class TeamBackToWorkspaceTriggeredEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProBackToWorkspaceEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(BACK_TO_CURRENT_TEAM_COMMAND)
    private readonly _backToWorkspace: BackToCurrentTeamCommandPort,
  ) {}

  handle([event]: [ApplicationEvent]) {
    this._backToWorkspace.back();
  }
}

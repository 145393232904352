interface Coords {
  lat: number;
  lng: number;
}

export class GoogleMapsGeocodeQuery {
  constructor(
    public readonly latitude: number,
    public readonly longitude: number,
    public readonly bounds: {
      northeast: Coords;
      southwest: Coords;
    },
  ) {}
}

import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserLoginState } from '../../application/state/user-login.state';

@Component({
  selector: 'lib-login-page',
  templateUrl: './login-page.component.html',
  host: {
    class: 'cs-height-inherit-xs',
  },
})
export class LoginPageComponent {
  public readonly form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
  });

  isLoading$: Observable<boolean> = this._userLoginState.isLoginRequestPending$;

  passwordVisible = false;
  constructor(private _userLoginState: UserLoginState) {}

  control(name: string) {
    return this.form.get(name);
  }

  hasError(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control.invalid && control.touched;
  }

  onSubmit() {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      this._userLoginState.login(formValue.email, formValue.password);
    }
  }
}

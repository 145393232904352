import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { CancelsClientSubscriptionLMDto } from '../../../../application/ports/secondary/dto/clients/cancels-client-subscription-lm.dto';
import { CancelsClientSubscriptionLMDtoPort } from '../../../../application/ports/secondary/dto/clients/cancels-client-subscription-lm.dto-port';

@Injectable()
export class HttpCancelsClientSubscriptionLMService implements CancelsClientSubscriptionLMDtoPort {
  constructor(private readonly _gateway: GatewayClient) {}

  cancel({
    subscriptionId,
    endOfTerm,
    reasonCode,
    comment,
  }: CancelsClientSubscriptionLMDto): Observable<void> {
    return this._gateway.post<void>(`v1/payments/subscriptions/${subscriptionId}/cancel`, {
      data: {
        type: 'cancel-subscription',
        attributes: {
          endOfTerm,
          reasonCode,
          cancellationComment: comment,
        },
      },
    });
  }
}

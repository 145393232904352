import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserTeamDto } from './user-team.dto';

export const GET_USER_TEAMS_DTO = new InjectionToken<GetsUserTeamsDtoPort>(
  'GET_USER_TEAMS_COUNT_DTO',
);

export interface GetsUserTeamsDtoPort {
  getUserTeams(): Observable<UserTeamDto[]>;
}

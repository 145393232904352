/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Component, ChangeDetectionStrategy, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DeletesTeamUserCommandPort,
  DELETE_TEAM_USER_COMMAND,
} from '../../../../../application/ports/primary/deletes-team-user.command-port';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  SetsSearchPhraseCommandPort,
  SETS_USERS_LIST_SEARCH_PHRASE_COMMAND_PORT,
} from '../../../../../application/ports/primary/sets-search-phrase.command-port';
import {
  CreatesUserCommandPort,
  CREATES_USER_COMMAND,
} from '../../../../../application/ports/primary/creates-user.command-port';
import {
  EditAccessUserCommandPort,
  EDIT_ACCESS_USER_COMMAND,
} from '../../../../../application/ports/primary/edit-access-user.command-port';

@Component({
  selector: 'lib-cobiro-pro-user-management-controls',
  templateUrl: './user-management-controls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProUserManagementControlsComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  public readonly searchControl = new UntypedFormControl();
  private routeQueryParams$: Subscription;

  constructor(
    @Inject(SETS_USERS_LIST_SEARCH_PHRASE_COMMAND_PORT)
    private readonly _setsSearchPhraseCommand: SetsSearchPhraseCommandPort,
    @Inject(DELETE_TEAM_USER_COMMAND)
    private readonly _deleteTeamUserCommandPort: DeletesTeamUserCommandPort,
    @Inject(CREATES_USER_COMMAND)
    private readonly _createsUserCommandPort: CreatesUserCommandPort,
    @Inject(EDIT_ACCESS_USER_COMMAND)
    private readonly _editAccessUserCommandPort: EditAccessUserCommandPort,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(searchValue =>
          this._setsSearchPhraseCommand.setSearchPhrase({ phrase: searchValue }),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();

    this.routeQueryParams$ = this._route.queryParams
      .pipe(
        mergeMap(params => {
          if (params['add']) {
            return this._createsUserCommandPort
              .createUser({
                route: this._route,
              })
              .pipe(catchError(() => of(undefined)));
          }

          if (params['delete']) {
            return this._deleteTeamUserCommandPort
              .deleteTeamUser({
                id: params['delete'],
                route: this._route,
              })
              .pipe(catchError(() => of(undefined)));
          }

          if (params['edit']) {
            return this._editAccessUserCommandPort
              .editAccessUser({
                id: params['edit'],
                route: this._route,
              })
              .pipe(catchError(() => of(undefined)));
          }

          return of(void 0);
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onAddMemberClicked() {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        add: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

// Take emojis from here: https://emojipedia.org
export enum Emojis {
  NONE = '',
  STOPWATCH = '⏱️',
  BAR_CHART = '📊️',
  CHECKMARK = '✅',
  INCOMING_ENVELOPE = '📨',
  BRIEFCASE = '💼',
  PERSON_RAISING_HAND = '🙋',
  CASTLE = '🏰',
  CARD = '💳',
  PARTY_POPPER = '🎉',
  NO_ACCESS = '🚫',
}

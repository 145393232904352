import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleMapsGeocodeQuery } from './primary/google-maps-geocode.query';

export interface GetsGoogleMapGeocodeQueryAdapter {
  getGeocode(address: string): Observable<GoogleMapsGeocodeQuery>;
}

export const GETS_GOOGLE_MAP_GEOCODE = new InjectionToken<GetsGoogleMapGeocodeQueryAdapter>(
  'GETS_GOOGLE_MAP_GEOCODE',
);

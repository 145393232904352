import { Component } from '@angular/core';

@Component({
  selector: 'lib-verify-page',
  templateUrl: './verify-page.component.html',
  host: {
    class: 'cs-height-inherit-xs',
  },
})
export class VerifyPageComponent {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { WorkspaceDto } from './workspace.query';

export const SETS_CURRENT_WORKSPACE_QUERY = new InjectionToken<SetsCurrentWorkspaceQueryPort>(
  'SETS_CURRENT_WORKSPACE_QUERY',
);

export interface SetsCurrentWorkspaceQueryPort {
  setCurrentWorkspace(workspace: WorkspaceDto): Observable<boolean>;
}

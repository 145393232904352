<div
  class="cs-mv-10 ml-auto mr-auto w-100 position-relative align-items-stretch d-flex flex-column flex-sm-row flex-lg-row"
>
  <section class="cs-card d-flex flex-1 flex-column cs-mr-20-lg mb-lg-0 cs-min-width-360-xs">
    <lib-label-manager-results class="w-100" [isSimulation]="false"></lib-label-manager-results>
    <lib-cobiro-pro-label-manager-revenue-and-costs
      class="w-100"
    ></lib-cobiro-pro-label-manager-revenue-and-costs>
  </section>
  <aside
    class="d-flex flex-grow flex-colum overflow-y-auto cs-max-height-1110-xs cs-max-width-100-pc-xs cs-max-width-360-lg cs-mt-20 cs-mt-0-sm"
  >
    <lib-cobiro-label-manager-recommendations
      [isSimulation]="false"
    ></lib-cobiro-label-manager-recommendations>
  </aside>
</div>
<div class="cs-card ml-auto mr-auto cs-mv-10 w-100 position-relative d-flex flex-column cs-ph-20">
  <lib-label-manager-linking-result></lib-label-manager-linking-result>
</div>
<div
  class="cs-max-width-1240-xs cs-mv-10 ml-auto mr-auto w-100 position-relative align-items-stretch d-flex flex-column flex-sm-row flex-lg-row"
>
  <section class="cs-card d-flex flex-column flex-1 cs-mr-20-lg mb-lg-0 cs-min-width-360-xs">
    <h4 class="align-self-left cs-title-4 cs-mb-10">
      {{ '_settings' | language }}
    </h4>
    <lib-label-manager-attributes></lib-label-manager-attributes>
  </section>
  <aside
    class="d-flex flex-grow flex-column cs-max-width-100-pc-xs cs-max-width-360-lg cs-mt-20 cs-mt-0-sm cs-ml-10-sm"
  >
    <lib-label-manager-status class="cs-card cs-mb-10"></lib-label-manager-status>
    <lib-label-manager-support></lib-label-manager-support>
  </aside>
</div>

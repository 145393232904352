/* eslint-disable max-lines-per-function */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  distinctUntilChanged,
  filter,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { EmailConsentQuery } from '../../../../application/ports/primary/email-consent.query';
import {
  GETS_EMAIL_CONSENTS_QUERY_PORT,
  GetsEmailConsentsQueryPort,
} from '../../../../application/ports/primary/gets-email-consents.query-port';
import {
  SAVES_EMAIL_CONSENT_QUERY_PORT,
  SavesEmailConsentQueryPort,
} from '../../../../application/ports/primary/saves-email-consent.query-port';
import {
  IS_SAVE_IN_PROGRESS_COMMAND,
  IsSaveInProgressCommandPort,
} from '../../../../application/ports/primary/is-save-in-progress.command-port';

@Component({
  selector: 'lib-cobiro-pro-marketing-preferences',
  templateUrl: './marketing-preferences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingPreferencesComponent implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();

  public consentsForm = new FormGroup({});
  public consents$: Observable<EmailConsentQuery[]>;
  public saveInProgress$ = this._isSaveInProgressCommandPort
    .isInProgress()
    .pipe(tap(res => (res ? this.consentsForm.disable() : this.consentsForm.enable())));
  private readonly _subscriptions: Subscription[] = [];

  constructor(
    @Inject(GETS_EMAIL_CONSENTS_QUERY_PORT)
    private readonly _getsEmailConsents: GetsEmailConsentsQueryPort,
    @Inject(SAVES_EMAIL_CONSENT_QUERY_PORT)
    private readonly _savesEmailConsent: SavesEmailConsentQueryPort,
    @Inject(IS_SAVE_IN_PROGRESS_COMMAND)
    private readonly _isSaveInProgressCommandPort: IsSaveInProgressCommandPort,
  ) {
    this.consents$ = this._getsEmailConsents.getEmailConsent().pipe(
      tap(consents => {
        consents.forEach(emailConsent => {
          this.consentsForm.registerControl(
            emailConsent.name,
            new FormControl(emailConsent.consent),
          );
          this._subscriptions.push(
            this.consentsForm
              .get(emailConsent.name)
              .valueChanges.pipe(
                distinctUntilChanged(),
                withLatestFrom(this.saveInProgress$),
                filter(([value, saveInProgress]) => !saveInProgress),
              )
              .subscribe(([value]) => {
                this._updateConsent(emailConsent.name, value);
              }),
          );
        });
      }),
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private _updateConsent(channel: string, value: boolean): void {
    this._savesEmailConsent
      .saveEmailConsent(channel, value)
      .pipe(takeUntil(this._destroyed$))
      .subscribe();
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GoesToNextStepCommandPort,
  GOES_TO_NEXT_STEP_COMMAND,
} from '../../../../../../application/ports/primary/setup/goes-to-next-step.command-port';
import {
  GoesToPreviousStepCommandPort,
  GOES_TO_PREVIOUS_STEP_COMMAND,
} from '../../../../../../application/ports/primary/setup/goes-to-previous-step.command-port';
import { BehaviorSubject, map, take } from 'rxjs';
import { AddPaymentMethodCommand } from '../../../../../../application/ports/primary/payments/adds-payment-method.command';
import {
  AddsPaymentMethodCommandPort,
  ADDS_PAYMENT_METHOD_COMMAND,
} from '../../../../../../application/ports/primary/payments/adds-payment-method.command-port';

@Component({
  selector: 'lib-cobiro-pro-add-payment-card',
  templateUrl: './add-payment-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaymentCardComponent {
  private _isValidForm$ = new BehaviorSubject<boolean>(false);
  private _paymentMethod$ = new BehaviorSubject<AddPaymentMethodCommand>(null);
  private _isProcessing$ = new BehaviorSubject<boolean>(false);

  readonly isInvalid$ = this._isValidForm$.asObservable().pipe(map(res => !res));
  readonly isProcessing$ = this._isProcessing$.asObservable();

  constructor(
    @Inject(GOES_TO_NEXT_STEP_COMMAND)
    private readonly _goesToNextStepCommandPort: GoesToNextStepCommandPort,
    @Inject(GOES_TO_PREVIOUS_STEP_COMMAND)
    private readonly _goesToPreviousStepCommandPort: GoesToPreviousStepCommandPort,
    @Inject(ADDS_PAYMENT_METHOD_COMMAND)
    private readonly _addPaymentMethodCommand: AddsPaymentMethodCommandPort,
  ) {}

  onFormGroupChange(form: AddPaymentMethodCommand): void {
    this._paymentMethod$.next(form);
  }

  onValidityChange(isValid: boolean): void {
    this._isValidForm$.next(isValid);
  }

  onFormSubmit(): void {
    this._isProcessing$.next(true);
    this._addPaymentMethodCommand
      .addPaymentMethod(this._paymentMethod$.getValue())
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this._goesToNextStepCommandPort.goToNextStep();
        }
        this._isProcessing$.next(false);
      });
  }

  goToPreviusStep(): void {
    this._goesToPreviousStepCommandPort.goToPreviousStep();
  }
}

import { ClientDTO } from '../secondary/client.dto';

export class ClientQuery {
  constructor(
    public readonly id: string,
    public readonly teamId: string,
    public readonly companyName: string,
    public readonly contactPerson: string,
    public readonly contactEmail: string,
    public readonly contactPhone: string,
    public readonly url: string,
    public readonly avatar: string,
  ) {}

  static fromClientDTO(dto: ClientDTO): ClientQuery {
    return new ClientQuery(
      dto.id,
      dto.teamId,
      dto.companyName,
      dto.contactPerson,
      dto.contactEmail,
      dto.contactPhone,
      dto.url,
      dto.avatar,
    );
  }
}

import { InjectionToken } from '@angular/core';

export interface TeamSwitcherItem {
  readonly id: string;
  readonly name: string;
  readonly avatar: string | null;
  readonly role: string;
  readonly membersCount: number;
}

export interface SetsTeamSwitcherItemsCommandPort {
  setTeamSwitcherItems(items: TeamSwitcherItem[]);
}

export const SETS_TEAM_SWITCHER_ITEMS_COMMAND =
  new InjectionToken<SetsTeamSwitcherItemsCommandPort>('SETS_WORKSPACE_MANAGER_TEAMS');

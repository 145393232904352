import { ApplicationEvent } from '@cobiro/eda';
class LabelManagerReportQuery {
  constructor(
    public readonly id: string,
    public readonly data: LabelManagerReportDateData[],
    public readonly status: string,
    public readonly error: null | { code: string },
  ) {}
}
interface LabelManagerReportDateData {
  date: Date | null;
  totalProducts: number;
  chartSeries: { data: number[] }[];
  dataLabels: string[];
  segments: {
    clicks: Record<string, number>;
    conversionsValue: Record<string, number>;
    cost: Record<string, number>;
  } | null;
}

export class LabelManagerSetReportEvent extends ApplicationEvent {
  constructor(public readonly report: LabelManagerReportQuery) {
    super();
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { VatNumberValidationDto } from './vat-number-validation.dto';

export const GETS_VAT_NUMBER_VALIDATION_DTO = new InjectionToken<GetsVatNumberValidationDtoPort>(
  'GETS_VAT_NUMBER_VALIDATION_DTO',
);

export interface GetsVatNumberValidationDtoPort {
  getVatValidation(countryCode: string, vatNumber: string): Observable<VatNumberValidationDto>;
}

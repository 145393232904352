import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowEnvConfig } from './window-config.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    WindowEnvConfig,
    {
      provide: 'DATA_LAYER_CONTAINER',
      useValue: window,
    },
  ],
})
export class ConfigModule {}

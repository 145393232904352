import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientProductStatusComponent } from './client-product-status.component';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ClientProductStatusComponent],
  imports: [CommonModule, MatIconModule, LanguageModule, MatTooltipModule],
  exports: [ClientProductStatusComponent],
})
export class ClientProductStatusComponentModule {}

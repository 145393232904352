import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientListItemQuery } from './client-list-item.query';

export interface ToggleClientCommandPort {
  toggleRow(client: ClientListItemQuery): Observable<void>;
}

export const TOGGLE_CLIENT_COMMAND = new InjectionToken<ToggleClientCommandPort>(
  'TOGGLE_CLIENT_COMMAND',
);

import { Observable } from 'rxjs';
import { PaymentSourceQuery } from './payment-source.query';
import { InjectionToken } from '@angular/core';

export interface GetsPaymentSourcesQueryPort {
  getPaymentSourcesQuery(): Observable<PaymentSourceQuery[]>;
}

export const GETS_PAYMENT_SOURCES_QUERY_PORT = new InjectionToken<GetsPaymentSourcesQueryPort>(
  'GETS_PAYMENT_SOURCES_QUERY_PORT',
);

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { GETS_LANGUAGE, GetsLanguage, LanguageModule } from '@app.cobiro.com/common/language';
import { Class } from '@app.cobiro.com/core/utils';
import { HuiTextErrorComponent } from '@app.cobiro.com/shared/hui/input/components/text-error/hui-text-error.component';

// @Usage
// You can override INPUT_ERRORS like this
// const inputErrorsFactory = languageService => {
//   return {
//     required: () => languageService.get('string_to_translate'),
//     minlength: ({ requiredLength, actualLength }) =>
//       languageService.get('string_to_translate', [
//         requiredLength,
//         actualLength,
//       ]),
//   };
// };
// @NgModule({
//   import: [HuiInputModule],
//   providers: [
//     { provide: INPUT_ERRORS, useFactory: inputErrorsFactory, deps: [LanguageService] }
//      // or if have already translated input errors
//     { provide: INPUT_ERRORS, useValue: TranslatedInputErrors }
//   ]
// });

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [...fromDirectives.directives, ...fromComponents.components],
  exports: [...fromDirectives.directives, ...fromComponents.components],
})
export class HuiInputModule {
  static forRoot(getsLanguage: Class<GetsLanguage>): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
      providers: [
        {
          provide: GETS_LANGUAGE,
          useClass: getsLanguage,
        },
      ],
    };
  }
}

import { GetsPlanEstimate } from '../../domain/gets-plan-estimate';
import { Observable } from 'rxjs';
import { PlanEstimate } from '../../domain/plan-estimate';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HasData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { EstimatePlanDto } from '../../domain/estimate-plan.dto';
import { removeNullProperties } from '@app.cobiro.com/core/utils';

export interface PlanEstimateAttributes {
  items: PlanEstimateItemAttributes[];
  total: PlanEstimateTotalAttributes;
}

export interface PlanEstimateItemAttributes {
  id: string;
  name: string;
  type: string;
  nettoValue: number;
  grossValue: number;
  currency: string;
  taxValue: number;
  taxPercentage: number;
  grossValueBeforeDiscount: number;
}

export interface PlanEstimateTotalAttributes {
  nettoValue: number;
  grossValue: number;
  currency: string;
  taxValue: number;
  taxPercentage: number;
  grossValueBeforeDiscount: number;
}

@Injectable()
export class HttpGetsPlanEstimateService implements GetsPlanEstimate {
  constructor(private _client: GatewayClient) {}
  get(dto: EstimatePlanDto): Observable<PlanEstimate> {
    const { planId, countryCode, discountCode, vatNumber } = dto;
    const paramsObj = removeNullProperties({ discountCode, vatNumber });
    const params = new HttpParams(paramsObj ? { fromObject: paramsObj } : {});

    return this._client
      .get<HasData<PlanEstimateAttributes>>(
        `v1/payments/plans/${planId}/${countryCode}/price-estimations`,
        {
          params,
        },
      )
      .pipe(map(response => this._mapAttributesToPlanEstimate(response.data.attributes.total)));
  }

  private _mapAttributesToPlanEstimate(attributes: PlanEstimateTotalAttributes): PlanEstimate {
    return new PlanEstimate(
      attributes.nettoValue / 100,
      attributes.grossValue / 100,
      attributes.taxValue / 100,
      attributes.taxPercentage,
      !(attributes.grossValueBeforeDiscount === null),
      (attributes.grossValueBeforeDiscount || attributes.grossValue) / 100,
    );
  }
}

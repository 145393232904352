import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceListTableComponent } from './workspace-list-table.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [WorkspaceListTableComponent],
  imports: [
    CommonModule,
    HuiLoaderContainerModule,
    LanguageModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [WorkspaceListTableComponent],
})
export class WorkspaceListTableComponentModule {}

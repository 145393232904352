import { InjectionToken } from '@angular/core';

// TODO (PRO-DEBT): Create command class
export interface SetsClientsListPageCommandPort {
  setClientsListPage(pageIndex: number): void;
}

export const SETS_CLIENTS_LIST_PAGE_COMMAND = new InjectionToken<SetsClientsListPageCommandPort>(
  'SETS_CLIENTS_LIST_PAGE_COMMAND',
);

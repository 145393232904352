import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UPDATE_ERROR_FIELDS } from '../../../domain/update-error-fields';

export interface GetsCreateNewSubscriptionUpdateErrorQueryPort {
  getCreateNewSubscriptionUpdateError(): Observable<UPDATE_ERROR_FIELDS>;
}

export const GETS_CREATE_NEW_SUBSCRIPTION_UPDATE_ERROR_QUERY_PORT =
  new InjectionToken<GetsCreateNewSubscriptionUpdateErrorQueryPort>(
    'GETS_CREATE_NEW_SUBSCRIPTION_UPDATE_ERROR_QUERY_PORT',
  );

import { InjectionToken } from '@angular/core';

export interface ResetsClientsListPaginationCommandPort {
  resetClientListPagination(): void;
}

export const RESETS_CLIENTS_LIST_PAGINATION_COMMAND =
  new InjectionToken<ResetsClientsListPaginationCommandPort>(
    'RESETS_CLIENTS_LIST_PAGINATION_COMMAND',
  );

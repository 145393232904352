import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const SAVES_EMAIL_CONSENT_QUERY_PORT = new InjectionToken<SavesEmailConsentQueryPort>(
  'SAVES_EMAIL_CONSENT_QUERY_PORT',
);

export interface SavesEmailConsentQueryPort {
  saveEmailConsent(channel: string, consent: boolean): Observable<boolean>;
}

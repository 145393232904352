import { Injectable } from '@angular/core';
import { GetsProductMarketingListDtoPort } from '../../../../application/ports/secondary/dto/setup/gets-product-marketing-list.dto-port';
import { ProductMarketingDTO } from '../../../../application/ports/secondary/dto/setup/product-marketing.dto';
@Injectable()
export class InMemoryProductService implements GetsProductMarketingListDtoPort {
  getProductsMarketing(product: string): ProductMarketingDTO {
    return COBIRO_PRO_PRODUCT_MARKETING_LIST.find(item => item.product === product);
  }
}

export const COBIRO_PRO_PRODUCT_MARKETING_LIST: ProductMarketingDTO[] = [
  {
    product: 'css',
    img: '/assets/apps/store/css/marketing_css.jpg',
    advantages: [
      'cobiro_pro_setup_css_advantage_1',
      'cobiro_pro_setup_css_advantage_2',
      'cobiro_pro_setup_css_advantage_3',
      'cobiro_pro_setup_css_advantage_4',
    ],
  },
  {
    product: 'label-manager',
    img: '/assets/apps/store/label-manager/marketing_label-manager.jpg',
    advantages: [
      'cobiro_pro_setup_label-manager_advantage_1',
      'cobiro_pro_setup_label-manager_advantage_2',
      'cobiro_pro_setup_label-manager_advantage_3',
      'cobiro_pro_setup_label-manager_advantage_4',
    ],
  },
];

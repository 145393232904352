import { InjectionToken } from '@angular/core';
import { SetsSelectedPlanCommand } from './sets-selected-plan.command';

export interface SetsSelectedPlanCommandPort {
  setPlan(command: SetsSelectedPlanCommand): void;
}

export const SETS_SELECTED_PLAN_COMMAND = new InjectionToken<SetsSelectedPlanCommandPort>(
  'SETS_SELECTED_PLAN_COMMAND',
);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientProductInfoComponent } from './client-product-info.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ClientProductStatusComponentModule } from '../client-product-status/client-product-status.component-module';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [ClientProductInfoComponent],
  imports: [
    CommonModule,
    LanguageModule,
    ClientProductStatusComponentModule,
    HuiLoaderContainerModule,
  ],
  exports: [ClientProductInfoComponent],
})
export class ClientProductInfoModule {}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  UPLOAD_IMAGE_DTO,
  UploadsImageDtoPort,
} from '../../../ports/secondary/uploads-image.dto-port';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Image, ProcessedImage, ProcessMediaService } from '../../../../../../media-center-image';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

/**
 * This component requires providing 2 things via content projection
 * - `recommendedSize` - text with recommended image size
 * - `imagePreview` - template which would be displaying an image
 *
 * The template provided in imagePreview should use the native (load) handler on img tag which should then
 * trigger the `previewLoaded` setter in this component instance
 */
@Component({
  selector: 'lib-shared-image-upload',
  templateUrl: 'shared-image-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['shared-image-upload.component.scss'],
  host: {
    class: 'd-block cs-b-shade-15 position-relative cs-height-200-xs cs-cursor-pointer',
  },
})
export class SharedImageUploadComponent {
  @Output() imageUrl = new EventEmitter<string>();
  @Input() set previewLoaded(loaded: boolean) {
    this._previewLoaded$.next(loaded);
  }

  private readonly _loading$ = new BehaviorSubject<boolean>(false);

  private readonly _fileUploaded$ = new BehaviorSubject(false);

  private readonly _previewLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(UPLOAD_IMAGE_DTO) private readonly _uploadsImageDto: UploadsImageDtoPort,
    private readonly _processImage: ProcessMediaService,
  ) {}

  onFileUpload(fileList: FileList): void {
    if (!fileList.item(0)) {
      return;
    }
    this._loading$.next(true);
    this._fileUploaded$.next(false);
    this._previewLoaded$.next(false);

    this._processImage
      .processImage(fileList.item(0))
      .pipe(
        take(1),
        switchMap((processedImage: ProcessedImage) =>
          this._uploadsImageDto.uploadImage({
            content: processedImage.content,
            mimeType: processedImage.mimeType,
          }),
        ),
        tap((image: Image) => {
          this.imageUrl.emit(image.urls.thumb);
          this._loading$.next(false);
          this._fileUploaded$.next(true);
        }),
      )
      .subscribe({
        error: () => {
          this._loading$.next(false);
          this._fileUploaded$.next(false);
        },
      });
  }

  shouldDisplaySpinner(): Observable<boolean> {
    return combineLatest([this._loading$, this._fileUploaded$, this._previewLoaded$]).pipe(
      map(([loading, fileUploaded, previewLoaded]) => {
        return loading || (fileUploaded && !previewLoaded);
      }),
    );
  }

  shouldDisplayPreview(): Observable<boolean> {
    return combineLatest([this._loading$, this._previewLoaded$]).pipe(
      map(([loading, previewLoaded]) => {
        return !loading && previewLoaded;
      }),
    );
  }

  onRemoveClicked($event: MouseEvent) {
    $event.stopPropagation();

    this._fileUploaded$.next(false);
    this._previewLoaded$.next(false);
    this.imageUrl.emit(null);
  }
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { take, tap } from 'rxjs';
import {
  PatchesWorkspacesContextStoragePort,
  PATCHES_WORKSPACES_CONTEXT_STORAGE,
} from '../../../application/ports/secondary/context/patches-workspaces-context.storage-port';
import { CurrentWorkspaceChangedEvent } from '../../../application/events/current-workspace-changed.event';

@Injectable()
export class CurrentWorkspaceChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CurrentWorkspaceChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(PATCHES_WORKSPACES_CONTEXT_STORAGE)
    private readonly _patchesWorkspacesContextStoragePort: PatchesWorkspacesContextStoragePort,
  ) {}

  handle([event]: [CurrentWorkspaceChangedEvent]) {
    this._patchesWorkspacesContextStoragePort
      .patch({ currentWorkspace: event.workspace })
      .pipe(take(1));
  }
}

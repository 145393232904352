import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SignsUpByGoogleCommand } from '../../application/ports/primary/signs-up-by-google.command';
import {
  SIGNS_UP_BY_GOOGLE_COMMAND,
  SignsUpByGoogleCommandPort,
} from '../../application/ports/primary/signs-up-by-google.command-port';

declare global {
  interface Window {
    google: any;
  }
}

@Component({
  selector: 'lib-sign-up-by-google',
  templateUrl: './sign-up-by-google.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SignUpByGoogleComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();

  createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200',
    });

    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      'div[role=button]',
    ) as HTMLElement;

    return {
      click: () => {
        googleLoginWrapperButton?.click();
      },
    };
  };

  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(SIGNS_UP_BY_GOOGLE_COMMAND)
    private readonly _signsUpByGoogleCommand: SignsUpByGoogleCommandPort,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this._signsUpByGoogleCommand
      .signUpByGoogle(new SignsUpByGoogleCommand(this._router.url))
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }

  async onSignUpByGoogleClicked(): Promise<void> {
    this.isLoading$.next(true);
    this.createFakeGoogleWrapper().click();
    this.isLoading$.next(false);
  }
}

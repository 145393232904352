import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[libFilePicker]',
  exportAs: 'libFilePicker',
})
export class HuiFilePickerDirective implements OnDestroy, OnChanges {
  private _form: HTMLFormElement;
  private readonly _nativeFileElement: HTMLInputElement;
  private _multiple = false;

  @Input()
  set multiple(val: boolean) {
    this._multiple = coerceBooleanProperty(val);
  }

  get multiple() {
    return this._multiple;
  }
  @Output() filesChanged = new EventEmitter<FileList>();
  @Output() filesReset = new EventEmitter();

  constructor(@Optional() @Inject(DOCUMENT) private _document: Document) {
    if (this._document) {
      this._form = this._document.createElement('form');
      this._nativeFileElement = this._document.createElement('input');
      this._nativeFileElement.type = 'file';
      this._nativeFileElement.multiple = this.multiple;
      this._nativeFileElement.addEventListener('change', this._onFilesChanged);
      this._form.appendChild(this.nativeFileElement);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  drop(event: DragEvent) {
    event.preventDefault();
    this._nativeFileElement.files = event.dataTransfer.files;
    this._onFilesChanged();
  }

  @HostListener('click', ['$event'])
  _onClick(event: Event) {
    event.preventDefault();
    this._nativeFileElement.click();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.multiple) {
      this._nativeFileElement.multiple = this.multiple;
    }
  }

  ngOnDestroy() {
    this._nativeFileElement.removeEventListener('change', this._onFilesChanged);
    this._nativeFileElement.remove();
    this._form.remove();
  }

  get files(): FileList | undefined {
    return this._nativeFileElement.files;
  }

  get nativeFileElement() {
    return this._nativeFileElement;
  }

  private _onFilesChanged = () => {
    this.filesChanged.emit(this._nativeFileElement.files);
  };

  reset() {
    this._form.reset();
    this.filesReset.emit();
  }
}

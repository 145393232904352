import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { ClientsListLoadedEventHandler } from '../adapters/primary/handlers/clients-list-loaded.event-handler';
import { ClientAddedEventHandler } from '../adapters/primary/handlers/client-added.event-handler';
import { HttpClientsServiceModule } from '../adapters/secondary/infrastructure/http-clients.service-module';
import { ClientsListStateModule } from '../application/state/clients-list.state-module';
import { ClientsStateModule } from '../application/state/clients.state-module';
import { HttpClientSitesServiceModule } from '../adapters/secondary/infrastructure/http-client-sites.service-module';
import { HttpClientServicesServiceModule } from '../adapters/secondary/infrastructure/http-client-services.service-module';
import { HttpClientShortcutsServiceModule } from '../adapters/secondary/infrastructure/http-client-shortcuts.service-module';

@NgModule({
  imports: [
    ClientsStateModule,
    ClientsListStateModule,
    HttpClientsServiceModule,
    HttpClientSitesServiceModule,
    HttpClientServicesServiceModule,
    HttpClientShortcutsServiceModule,
  ],
  providers: [
    provideApplicationEventsHandler(ClientsListLoadedEventHandler),
    provideApplicationEventsHandler(ClientAddedEventHandler),
  ],
})
export class ClientProvidersModule {}

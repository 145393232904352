import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceListControlComponent } from './workspace-list-control.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [WorkspaceListControlComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, ReactiveFormsModule],
  exports: [WorkspaceListControlComponent],
})
export class WorkspaceListControlComponentModule {}

import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GETS_ROLES, GetsRoles } from './gets-roles';

@Injectable()
export class RolesState {
  private userRoles$: Observable<Set<string>>;

  constructor(@Inject(GETS_ROLES) private getsRoles: GetsRoles) {
    this.userRoles$ = getsRoles.getAll();
  }

  hasRoles(roles: string[]): Observable<boolean> {
    return this.userRoles$.pipe(
      map(
        (userRoles: Set<string>) =>
          [...new Set(roles)].find(role => !userRoles.has(role)) === undefined,
      ),
    );
  }
}

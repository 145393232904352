import { NgModule } from '@angular/core';
import { CANCELS_CLIENT_SUBSCRIPTION_LM_DTO } from '../../../../application/ports/secondary/dto/clients/cancels-client-subscription-lm.dto-port';
import { HttpCancelsClientSubscriptionLMService } from './http-cancels-client-subscription-lm.service';

@NgModule({
  providers: [
    HttpCancelsClientSubscriptionLMService,
    {
      provide: CANCELS_CLIENT_SUBSCRIPTION_LM_DTO,
      useExisting: HttpCancelsClientSubscriptionLMService,
    },
  ],
})
export class HttpCancelsClientSubscriptionLMServiceModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface GetsFormErrorQueryPort {
  getFormErrorQuery(): Observable<string[]>;
}

export const GETS_FORM_ERROR_QUERY_PORT = new InjectionToken<GetsFormErrorQueryPort>(
  'GETS_FORM_ERROR_QUERY_PORT',
);

import { InjectionToken } from '@angular/core';

export interface GetsTeamSettingsFormErrorKeyDtoPort {
  getErrorKey(fieldName: string, errors: { [key: string]: boolean }): string;
}

export const GETS_TEAM_SETTINGS_FORM_ERROR_KEY_DTO_PORT =
  new InjectionToken<GetsTeamSettingsFormErrorKeyDtoPort>(
    'GETS_TEAM_SETTINGS_FORM_ERROR_KEY_DTO_PORT',
  );

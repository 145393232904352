import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerNoIntegrationComponent } from './label-manager-no-integration.component';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  declarations: [LabelManagerNoIntegrationComponent],
  imports: [CommonModule, LanguageModule],
  exports: [LabelManagerNoIntegrationComponent],
})
export class LabelManagerNoIntegrationComponentModule {}

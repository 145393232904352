<form data-selector="add-payment-method-card-form" [formGroup]="form">
  <div class="row">
    <div class="col-12">
      <label class="cs-label">
        {{ 'cobiro_pro_settings_payment_methods_card_owner' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>

      <input
        formControlName="cardOwner"
        data-selector="card-owner-input"
        class="cs-input"
        type="text"
        [placeholder]="'cobiro_pro_settings_payment_methods_card_owner_placeholder' | language"
      />
      <ng-container
        *ngIf="form.get('cardOwner').touched && form.get('cardOwner').hasError('required')"
      >
        <p class="cs-control-error" data-selector="card-owner-required-validation-message">
          {{ 'cobiro_pro_settings_payment_methods_required_name' | language }}
        </p>
      </ng-container>
    </div>
  </div>
  <div class="row cs-mt-20">
    <div class="col-lg-12 cs-mb-20 cs-mb-0-lg">
      <label class="cs-label">
        {{ 'cobiro_pro_settings_payment_methods_card_number' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <input
        formControlName="cardNumber"
        class="cs-input"
        mask="0000 0000 0000 0999 999"
        [validation]="true"
        autocomplete="cc-number"
        placeholder="0000 0000 0000 0000"
        data-selector="credit-card-number-input"
      />
      <ng-container
        *ngIf="form.get('cardNumber').touched && form.get('cardNumber').hasError('required')"
      >
        <p class="cs-control-error" data-selector="credit-card-number-required-validation-message">
          {{ 'cobiro_pro_settings_payment_methods_card_number_required' | language }}
        </p>
      </ng-container>
    </div>
  </div>
  <div class="row cs-mt-20">
    <div class="col-lg-6 cs-mb-20 cs-mb-0-lg">
      <label class="cs-label">
        {{ 'cobiro_pro_settings_payment_methods_expiration_label' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <input
        formControlName="expirationDate"
        class="cs-input"
        placeholder="MM/YY"
        mask="M0/00"
        [validation]="true"
        data-selector="credit-card-expiration-date-input"
        huiCardExpireDate
      />
      <ng-container
        *ngIf="
          form.get('expirationDate').touched && form.get('expirationDate').hasError('required')
        "
      >
        <p
          class="cs-control-error"
          data-selector="credit-card-expiration-date-required-validation-message"
        >
          {{ 'cobiro_pro_settings_payment_methods_invalid_date' | language }}
        </p>
      </ng-container>
    </div>
    <div class="col-lg-6">
      <label class="cs-label">
        CVV
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>

      <input
        formControlName="cvv"
        class="cs-input"
        placeholder="XXX"
        mask="0009"
        [validation]="false"
        data-selector="credit-card-cvv-input"
      />
      <ng-container *ngIf="form.get('cvv').touched && form.get('cvv').hasError('required')">
        <p
          class="cs-control-error cs-width-80-xs"
          data-selector="credit-card-cvv-required-validation-message"
        >
          {{ 'cobiro_pro_settings_payment_methods_invalid_cvv' | language }}
        </p>
      </ng-container>
    </div>
  </div>
</form>

import { NgModule } from '@angular/core';
import { ApplicationBusUserAdditionalDataChangedDispatcher } from './application-user-additional-data-changed.event-dispatcher';
import { USER_ADDITIONAL_DATA_CHANGED_DISPATCHER } from '../../../application/ports/secondary/user-additional-data-changed.dispatcher-port';

@NgModule({
  providers: [
    {
      provide: USER_ADDITIONAL_DATA_CHANGED_DISPATCHER,
      useClass: ApplicationBusUserAdditionalDataChangedDispatcher,
    },
  ],
})
export class ApplicationBusUserAdditionalDataChangedDispatcherModule {}

import { Observable } from 'rxjs';
import { CobiroProContextDto } from './cobiro-pro-context.dto';
import { InjectionToken } from '@angular/core';

export interface GetsCobiroProContextDtoPort {
  getContext(): Observable<CobiroProContextDto>;
}

export const GETS_COBIRO_PRO_CONTEXT_DTO = new InjectionToken<GetsCobiroProContextDtoPort>(
  'GETS_COBIRO_PRO_CONTEXT_DTO',
);

import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { TeamBackToWorkspaceTriggeredEventHandler } from '../../adapters/event-handlers/team-back-to-workspace-triggered.event-handler';
import { TeamUpdatedEventHandler } from '../../adapters/event-handlers/team-updated.event-handler';
import { CobiroProTeamsDispatchersModule } from '../../adapters/secondary/dispatchers/cobiro-pro-teams.dispatchers-module';
import { HttpTeamsServiceModule } from '../../adapters/secondary/infrastructure/http-teams.service-module';
import { BACK_TO_CURRENT_TEAM_COMMAND } from '../ports/primary/back-to-current-team-command.port';
import { CREATES_TEAM_COMMAND } from '../ports/primary/creates-team-command.port';
import { GETS_ALL_TEAM_QUERY } from '../ports/primary/gets-all-team-query.port';
import { GETS_CURRENT_TEAM_QUERY } from '../ports/primary/gets-current-team-query.port';
import { HAS_TEAM_QUERY } from '../ports/primary/has-team-query.port';
import { IS_TEAM_LIST_LOADING_QUERY } from '../ports/primary/is-team-list-loading-query.port';
import { LOAD_TEAMS_COMMAND } from '../ports/primary/load-teams-command.port';
import { SET_CURRENT_TEAM_COMMAND } from '../ports/primary/set-current-team-command.port';
import { UPDATE_TEAM_COMMAND_PORT } from '../ports/primary/updates-team.command-port';
import { TeamsState } from './teams.state';

@NgModule({
  declarations: [],
  imports: [HttpTeamsServiceModule, CobiroProTeamsDispatchersModule],
  providers: [
    TeamsState,
    { provide: CREATES_TEAM_COMMAND, useExisting: TeamsState },
    { provide: GETS_ALL_TEAM_QUERY, useExisting: TeamsState },
    { provide: GETS_CURRENT_TEAM_QUERY, useExisting: TeamsState },
    { provide: HAS_TEAM_QUERY, useExisting: TeamsState },
    { provide: SET_CURRENT_TEAM_COMMAND, useExisting: TeamsState },
    { provide: BACK_TO_CURRENT_TEAM_COMMAND, useExisting: TeamsState },
    { provide: UPDATE_TEAM_COMMAND_PORT, useExisting: TeamsState },
    {
      provide: LOAD_TEAMS_COMMAND,
      useExisting: TeamsState,
    },
    {
      provide: IS_TEAM_LIST_LOADING_QUERY,
      useExisting: TeamsState,
    },
    provideApplicationEventsHandler(TeamBackToWorkspaceTriggeredEventHandler),
    provideApplicationEventsHandler(TeamUpdatedEventHandler),
  ],
})
export class TeamsStateModule {}

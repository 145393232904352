import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserMenuOptionQuery } from './user-menu-option.query';

export const GETS_USER_MENU_OPTIONS_QUERY = new InjectionToken<GetsUserMenuOptionsQueryPort>(
  'GETS_USER_MENU_OPTIONS_QUERY',
);

export interface GetsUserMenuOptionsQueryPort {
  getUserMenuOptions(): Observable<UserMenuOptionQuery[]>;
}

import { PlanEstimateTotalAttributes } from '../../../../../adapters/secondary/infrastucture/http-gets-plan-estimate/http-gets-plan-estimate.service';

export class PlanEstimateDto {
  constructor(
    public readonly nett: number,
    public readonly gross: number,
    public readonly vatValue: number,
    public readonly vatPercentage: number,
    public readonly hasValidDiscountCode: boolean,
    public readonly grossValueBeforeDiscount: number,
  ) {}

  static fromPlanEstimateAttributes(attributes: PlanEstimateTotalAttributes): PlanEstimateDto {
    return new PlanEstimateDto(
      attributes.nettoValue / 100,
      attributes.grossValue / 100,
      attributes.taxValue / 100,
      attributes.taxPercentage,
      !(attributes.grossValueBeforeDiscount === null),
      (attributes.grossValueBeforeDiscount || attributes.grossValue) / 100,
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { DeleteWorkspaceModalComponent } from './delete-workspace-modal.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatDialogModule, MatIconModule],
  declarations: [DeleteWorkspaceModalComponent],
})
export class DeleteWorkspaceModalComponentModule {}

<div
  class="position-relative d-flex flex-column align-items-center cs-bg-shade-0 h-100 cs-ph-40 cs-pb-40"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-create-workspace-close-modal"
    matDialogClose
  ></mat-icon>

  <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">🏢</div>

  <h4 class="cs-title-4 cs-mt-20">{{ 'cobiro_pro_workspace_create_new_title' | language }}</h4>
  <p class="cs-text-1 text-center cs-mt-10">
    {{ 'cobiro_pro_workspace_create_new_description' | language }}
  </p>

  <form
    class="d-flex flex-column justify-content-between w-100 cs-mt-20 h-100"
    [formGroup]="workspaceForm"
  >
    <div class="cs-control-wrapper">
      <input
        data-selector="cbr-pro-cleate-workspace-name-input"
        class="cs-input"
        type="text"
        formControlName="name"
      />
      <p
        class="cs-control-error"
        data-selector="cobiro-pro-create-workspace-required-error"
        *ngIf="
          workspaceForm.get('name').invalid &&
          workspaceForm.get('name').touched &&
          workspaceForm.get('name').hasError('required')
        "
      >
        {{ '_field_is_required' | language }}
      </p>
    </div>

    <button
      class="cs-btn cs-btn-primary cs-mt-40"
      data-selector="cbr-pro-create-workspace-submit-btn"
      type="submit"
      [disabled]="workspaceForm.invalid"
      [matDialogClose]="workspaceForm.value"
    >
      {{ 'cobiro_pro_workspace_create_new_button' | language }}
    </button>
  </form>
</div>

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserQuery } from './user.query';

export const GETS_WORKSPACE_USERS_LIST_QUERY = new InjectionToken<GetsWorkspaceUsersListQueryPort>(
  'GETS_WORKSPACE_USERS_LIST_QUERY',
);

export interface GetsWorkspaceUsersListQueryPort {
  getWorkspaceUserListQuery(): Observable<UserQuery[]>;
}

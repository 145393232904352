import { NgModule } from '@angular/core';
import { HttpCompanyDetailsService } from './http-company-details.service';
import { SAVES_COMPANY_DETAILS_DTO_PORT } from '../../../../application/ports/secondary/saves-company-details.dto-port';
import { GETS_COMPANY_DETAILS_DTO_PORT } from '../../../../application/ports/secondary/gets-company-details.dto-port';
import { DELETES_COMPANY_DTO_PORT } from '../../../../application/ports/secondary/deletes-company.dto-port';

@NgModule({
  providers: [
    HttpCompanyDetailsService,
    { provide: GETS_COMPANY_DETAILS_DTO_PORT, useExisting: HttpCompanyDetailsService },
    {
      provide: SAVES_COMPANY_DETAILS_DTO_PORT,
      useClass: HttpCompanyDetailsService,
    },
    { provide: DELETES_COMPANY_DTO_PORT, useClass: HttpCompanyDetailsService },
  ],
})
export class HttpCompanyDetailsServiceModule {}

import { ClientDTO } from '../secondary/client.dto';
import { ClientQuery } from './client.query';
import { ClientServicesQuery } from './client-service.query';
import { CssIntegrationStatusQuery } from './css-integration-status.query';

export class ClientListItemQuery implements ClientQuery {
  constructor(
    public readonly id: string,
    public readonly teamId: string,
    public readonly cssIntegrationStatus: CssIntegrationStatusQuery,
    public readonly merchantSiteId: string | null,
    public readonly siteId: string | null,
    public readonly sitePublicId: string,
    public readonly companyName: string,
    public readonly contactPerson: string,
    public readonly contactEmail: string,
    public readonly contactPhone: string,
    public readonly url: string,
    public readonly avatar: string,
    public readonly source: string,
    public readonly plan: ClientPlanQuery,
    public readonly installedApps: ClientServicesQuery,
  ) {}

  static fromClientDTO(dto: ClientDTO): ClientListItemQuery {
    return new ClientListItemQuery(
      dto.id,
      dto.teamId,
      CssIntegrationStatusQuery.fromStatus(dto.cssIntegrationStatus),
      dto.merchantSiteId,
      dto.siteId,
      dto.sitePublicId,
      dto.companyName,
      dto.contactPerson,
      dto.contactEmail,
      dto.contactPhone,
      dto.url,
      dto.avatar,
      dto.source,
      new ClientPlanQuery(dto.plan),
      ClientServicesQuery.fromRaw(dto.installedApps, [], dto.source, dto.sitePublicId),
    );
  }
}

export class ClientPlanQuery {
  constructor(public readonly name: string) {}
}

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToDoClickedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/home';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class ToDoClickedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ToDoClickedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  handle([event]: [ToDoClickedEvent]): void {
    let queryParams = {};

    if (event.toDo.queryParams) {
      queryParams = {
        ...queryParams,
        ...event.toDo.queryParams,
      };
    }

    this._router.navigate(
      ['/', 'connect', this._teamIdGetter.getTeamId(), event.toDo.redirectUrl],
      {
        queryParams,
      },
    );
  }
}

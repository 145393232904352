import { InjectionToken, NgModule } from '@angular/core';
import { UIState } from '../ui.state';

export const APP_PRELOADER_VISIBLE: InjectionToken<UIState<boolean>> = new InjectionToken<
  UIState<boolean>
>('APP_PRELOADER_VISIBLE');

@NgModule({
  providers: [
    {
      provide: APP_PRELOADER_VISIBLE,
      useFactory: () => new UIState<boolean>(true),
    },
  ],
})
export class AppPreloaderModule {}

import { InjectionToken, StaticClassProvider, Type } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

export const PAYMENT_HANDLERS = new InjectionToken('PAYMENT_HANDLERS');

export interface PaymentHandler {
  readonly component: ComponentType<unknown>;
  readonly conditionFn: () => boolean;
}

export const paymentHandlerProvider: (
  handler: Type<PaymentHandler>,
  deps?: unknown[],
) => StaticClassProvider = (handler: Type<PaymentHandler>, deps = []) => ({
  provide: PAYMENT_HANDLERS,
  multi: true,
  useClass: handler,
  deps: deps,
});

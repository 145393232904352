import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HasDataCollection, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PlanTypeCollection } from '../../../../application/ports/primary/payments/payment-plan-options.query';
import { GetsPaymentPlansDtoPort } from '../../../../application/ports/secondary/dto/payments/gets-payment-plans.dto-port';
import { PaymentPlanDto } from '../../../../application/ports/secondary/dto/payments/payment-plan.dto';

export interface PaymentPlanDataAttributes {
  name: string;
  bundledPrices?: { [key: string]: number };
  prices: { [key: string]: number };
  advantages?: { id: string; value: string }[];
  payment_schedule?: { [key: string]: string };
  payment_discount?: { [key: string]: string };
}

@Injectable()
export class HttpGetsPaymentPlanService implements GetsPaymentPlansDtoPort {
  headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(
    private readonly _http: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  getPaymentPlans(): Observable<PaymentPlanDto[]> {
    return this._http
      .get<HasDataCollection<PaymentPlanDataAttributes>>(
        `${this._getsConfig.get('publicCdn')}/json/${
          this._getsConfig.get('production') ? 'prod' : 'staging'
        }/payment-plans.json?timestamp=${new Date().valueOf()}`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        catchError(() =>
          of({
            data: [
              {
                type: 'payment-plan-datas',
                id: 'free',
                attributes: {
                  name: 'Free',
                  prices: {
                    monthly: 0,
                    yearly: 0,
                  },
                },
              },
              {
                type: 'payment-plan-datas',
                id: 'business',
                attributes: {
                  name: 'Business',
                  prices: {
                    monthly: 25,
                    yearly: 20,
                  },
                  payment_schedule: {
                    monthly: 'Paid monthly',
                    yearly: 'Paid annually',
                  },
                  payment_discount: {
                    monthly: '',
                    yearly: '20% discount',
                  },
                  advantages: [
                    {
                      id: 'service_model',
                      value: 'Self-service solution',
                    },
                    {
                      id: 'setup costs',
                      value: 'No setup costs of hidden fees',
                    },
                    {
                      id: 'integration_type',
                      value: 'Create new Merchant Center or Switch your own',
                    },
                    {
                      id: 'bundle_discount',
                      value: 'Get discounts on other Cobiro products',
                    },
                    {
                      id: 'support_option',
                      value: 'Experts team support',
                    },
                    {
                      id: 'cancelation_model',
                      value: 'Cancel anytime',
                    },
                  ],
                },
              },
              {
                type: 'payment-plan-datas',
                id: 'label-manager',
                attributes: {
                  name: 'Label Manager',
                  prices: {
                    monthly: 59,
                    yearly: 47,
                  },
                  bundledPrices: {
                    monthly: 39,
                    yearly: 31,
                  },
                  payment_schedule: {
                    monthly: 'Paid monthly',
                    yearly: 'Paid annually',
                  },
                  payment_discount: {
                    monthly: '',
                    yearly: '20% discount',
                  },
                  advantages: [
                    {
                      id: 'setup costs',
                      value: 'No setup costs of hidden fees',
                    },
                    {
                      id: 'integration_type',
                      value: 'Use with Cobiro CSS or as standalone tool',
                    },
                    {
                      id: 'support_option',
                      value: 'Experts team support',
                    },
                    {
                      id: 'cancelation_model',
                      value: 'Cancel anytime',
                    },
                  ],
                },
              },
            ],
          }),
        ),
        map(() => ({
          data: [
            {
              type: 'payment-plan-datas',
              id: 'free',
              attributes: {
                name: 'Free',
                prices: {
                  monthly: 0,
                  yearly: 0,
                },
              },
            },
            {
              type: 'payment-plan-datas',
              id: 'business',
              attributes: {
                name: 'Business',
                prices: {
                  monthly: 25,
                  yearly: 20,
                },
                payment_schedule: {
                  monthly: 'Paid monthly',
                  yearly: 'Paid annually',
                },
                payment_discount: {
                  monthly: '',
                  yearly: '20% discount',
                },
                advantages: [
                  {
                    id: 'service_model',
                    value: 'Self-service solution',
                  },
                  {
                    id: 'setup costs',
                    value: 'No setup costs of hidden fees',
                  },
                  {
                    id: 'integration_type',
                    value: 'Create new Merchant Center or Switch your own',
                  },
                  {
                    id: 'bundle_discount',
                    value: 'Get discounts on other Cobiro products',
                  },
                  {
                    id: 'support_option',
                    value: 'Experts team support',
                  },
                  {
                    id: 'cancelation_model',
                    value: 'Cancel anytime',
                  },
                ],
              },
            },
            {
              type: 'payment-plan-datas',
              id: 'label-manager',
              attributes: {
                name: 'Label Manager',
                prices: {
                  monthly: 59,
                  yearly: 47,
                },
                bundledPrices: {
                  monthly: 59,
                  yearly: 47,
                },
                payment_schedule: {
                  monthly: 'Paid monthly',
                  yearly: 'Paid annually',
                },
                payment_discount: {
                  monthly: '',
                  yearly: '20% discount',
                },
                advantages: [
                  {
                    id: 'setup costs',
                    value: 'No setup costs of hidden fees',
                  },
                  {
                    id: 'integration_type',
                    value: 'Use with Cobiro CSS or as standalone tool',
                  },
                  {
                    id: 'support_option',
                    value: 'Experts team support',
                  },
                  {
                    id: 'cancelation_model',
                    value: 'Cancel anytime',
                  },
                ],
              },
            },
          ],
        })),
        map((paymentPlans: HasDataCollection<PaymentPlanDataAttributes>) =>
          paymentPlans.data.map(({ attributes }) => ({
            name: attributes.name.replace(' ', '-'),
            advantages: attributes.advantages
              ? attributes.advantages.map(advantage => ({
                  id: advantage.id,
                  value: advantage.value,
                }))
              : null,
            paymentSchedule: attributes.payment_schedule as PlanTypeCollection<string>,
            paymentDiscount: attributes.payment_discount as PlanTypeCollection<string>,
            prices: attributes.prices as PlanTypeCollection<number>,
            bundledPrices: attributes.bundledPrices as PlanTypeCollection<number>,
          })),
        ),
      );
  }
}

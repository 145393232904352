<div
  *ngIf="confirmation$ | async as confirmation"
  class="d-flex flex-column justify-content-between h-100"
>
  <div>
    <h1 class="cs-title-3">{{ confirmation.titleKey | language }}</h1>
    <p class="cs-mt-10 cs-text-2">
      {{ confirmation.descriptionKey | language }}
    </p>
  </div>

  <button
    *ngIf="confirmation.isSuccess; else tryAgain"
    (click)="endProcess()"
    class="cs-btn cs-btn-primary w-100 cs-mt-20"
    data-analytics="payment-plans-end"
  >
    {{ 'payment_plans_upgrade_success_button' | language }}
  </button>

  <ng-template #tryAgain>
    <button
      (click)="goBack()"
      class="cs-btn cs-btn-primary w-100 cs-mt-20"
      data-analytics="payment-plans-back"
    >
      {{ '_go_back' | language }}
    </button>
  </ng-template>
</div>

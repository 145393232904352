import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { SavesTeamSettingsDto } from './saves-team-settings.dto';

export interface SavesTeamSettingsDtoPort {
  saveTeamSettings(command: SavesTeamSettingsDto): Observable<boolean>;
}

export const SAVES_TEAM_SETTINGS_DTO_PORT = new InjectionToken<SavesTeamSettingsDtoPort>(
  'SAVES_TEAM_SETTINGS_DTO_PORT',
);

import { NgModule } from '@angular/core';
import { GETS_USER_DETAILS_DTO } from '../../../../application/ports/secondary/gets-user-details.dto-port';
import { UPDATES_USER_DETAILS_DTO } from '../../../../application/ports/secondary/updates-user-details.dto-port';
import { HttpUserDetailsService } from './http-user-details.service';

@NgModule({
  providers: [
    HttpUserDetailsService,
    {
      provide: UPDATES_USER_DETAILS_DTO,
      useClass: HttpUserDetailsService,
    },
    {
      provide: GETS_USER_DETAILS_DTO,
      useClass: HttpUserDetailsService,
    },
  ],
})
export class HttpUserDetailsServiceModule {}

import { LaunchesSurvey } from '../domain/launches-survey';
import { createPopup } from '@typeform/embed';
import { Injectable } from '@angular/core';

@Injectable()
export class TypeformLaunchesSurveyService implements LaunchesSurvey {
  launch(surveyId: string) {
    const popup = createPopup(surveyId, { onSubmit: () => popup.close() });
    popup.open();
  }
}

import { NgModule } from '@angular/core';
import { CompanyDetailsState } from './company-details.state';
import { GETS_NUMBER_OF_EMPLOYEES_QUERY } from '../ports/primary/gets-number-of-employees.query-port';
import { GETS_NUMBER_OF_CLIENTS_QUERY } from '../ports/primary/gets-number-of-clients.query-port';
import { GETS_COMPANY_TYPES_QUERY } from '../ports/primary/gets-company-types.query-port';
import { GETS_COMPANY_DETAILS_QUERY_PORT } from '../ports/primary/gets-company-details.query-port';
import { SAVES_COMPANY_DETAILS_QUERY_PORT } from '../ports/primary/saves-company-details.query-port';
import { GETS_ALL_TEAM_QUERY } from '../ports/primary/gets-all-team.query-port';
import { HAS_SUBSCRIPTIONS_QUERY } from '../ports/primary/has-subscriptions.query-port';
import { DELETES_TEAMS_COMMAND_PORT } from '../ports/primary/deletes-team.command-port';
import { DeleteTeamModalComponentModule } from '../../adapters/primary/ui/delete-team-modal/delete-team-modal.component-module';

@NgModule({
  imports: [DeleteTeamModalComponentModule],
  providers: [
    CompanyDetailsState,
    { provide: GETS_COMPANY_TYPES_QUERY, useExisting: CompanyDetailsState },
    { provide: GETS_NUMBER_OF_CLIENTS_QUERY, useExisting: CompanyDetailsState },
    { provide: GETS_NUMBER_OF_EMPLOYEES_QUERY, useExisting: CompanyDetailsState },
    { provide: GETS_COMPANY_DETAILS_QUERY_PORT, useExisting: CompanyDetailsState },
    { provide: SAVES_COMPANY_DETAILS_QUERY_PORT, useExisting: CompanyDetailsState },
    { provide: GETS_ALL_TEAM_QUERY, useExisting: CompanyDetailsState },
    { provide: HAS_SUBSCRIPTIONS_QUERY, useExisting: CompanyDetailsState },
    { provide: DELETES_TEAMS_COMMAND_PORT, useExisting: CompanyDetailsState },
  ],
})
export class CompanyDetailsStateModule {}

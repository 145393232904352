import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelsIntegrationCommand } from './cancels-integration.command';

export const CANCELS_INTEGRATION_COMMAND = new InjectionToken<CancelsIntegrationCommandPort>(
  'CANCELS_INTEGRATION_COMMAND',
);

export interface CancelsIntegrationCommandPort {
  cancelIntegration(command: CancelsIntegrationCommand): Observable<void>;
}

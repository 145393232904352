import { NgModule } from '@angular/core';
import { GETS_COUNTRIES_QUERY } from '../ports/primary/gets-countries.query-port';
import { GETS_USER_DETAILS_QUERY } from '../ports/primary/gets-user-details.query-port';
import { UPDATES_USER_DETAILS_QUERY } from '../ports/primary/updates-user-details.query-port';
import { UserDetailsState } from './user-details.state';
import { GETS_EMAIL_CONSENTS_QUERY_PORT } from '../ports/primary/gets-email-consents.query-port';
import { SAVES_EMAIL_CONSENT_QUERY_PORT } from '../ports/primary/saves-email-consent.query-port';
import { IS_SAVE_IN_PROGRESS_COMMAND } from '../ports/primary/is-save-in-progress.command-port';

@NgModule({
  providers: [
    UserDetailsState,
    { provide: GETS_USER_DETAILS_QUERY, useExisting: UserDetailsState },
    { provide: UPDATES_USER_DETAILS_QUERY, useExisting: UserDetailsState },
    { provide: GETS_COUNTRIES_QUERY, useExisting: UserDetailsState },
    { provide: GETS_EMAIL_CONSENTS_QUERY_PORT, useExisting: UserDetailsState },
    { provide: SAVES_EMAIL_CONSENT_QUERY_PORT, useExisting: UserDetailsState },
    { provide: IS_SAVE_IN_PROGRESS_COMMAND, useExisting: UserDetailsState },
  ],
})
export class UserDetailsStateModule {}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { PasswordChangedEvent } from '../../../../../../core/events/src/lib/user/password-changed/password-changed.event';
import { CHANGES_PASSWORD, ChangesPassword } from '../../domain/changes-password';
import {
  InvalidPasswordError,
  PasswordConfirmationVO,
} from '../../domain/password-confirmation.vo';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';

@Injectable()
export class ChangePasswordState {
  private _isLoading = new BehaviorSubject(false);

  constructor(
    @Inject(CHANGES_PASSWORD) private _changesPassword: ChangesPassword,
    private _route: ActivatedRoute,
    @Inject(APPLICATION_BUS) private _applicationBus: Dispatcher<PasswordChangedEvent>,
    private _alert: HuiAlert,
  ) {}

  get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  change(password: string, passwordConfirmation: string): void {
    this._isLoading.next(true);
    this._getQueryParams()
      .pipe(
        switchMap(params => {
          const passwordConfirmationVO = new PasswordConfirmationVO(password, passwordConfirmation);

          return this._changesPassword.change(
            params.email,
            passwordConfirmationVO.value,
            passwordConfirmationVO.value,
            params.token,
          );
        }),
        tap(() => this._applicationBus.dispatch(new PasswordChangedEvent())),
        catchError((error: Error) => {
          if (error.name === InvalidPasswordError.id) {
            this._alert.open('error', 'error_passwords_do_not_match');
          } else {
            this._alert.open('error', error.message);
          }

          return throwError(error);
        }),
        finalize(() => this._isLoading.next(false)),
        take(1),
      )
      .subscribe();
  }

  private _getQueryParams(): Observable<{ token: string; email: string }> | never {
    return this._route.queryParamMap.pipe(
      map(params => {
        const token = params.get('token');
        const email = params.get('email');

        if (!token) {
          throw new MissingTokenParamError();
        }

        if (!email) {
          throw new MissingEmailParamError();
        }

        return {
          token,
          email,
        };
      }),
    );
  }
}

export class MissingTokenParamError extends Error {
  public readonly name = 'MISSING_TOKEN_PARAM';
  public readonly message = 'error_missing_token_param';
}

export class MissingEmailParamError extends Error {
  public readonly name = 'MISSING_TOKEN_EMAIL';
  public readonly message = 'error_missing_token_param';
}

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { PlanPaymentStartedEvent } from '@app.cobiro.com/core/events';

export class DataLayerPlanPaymentStartedEventMapper implements DataLayerTrackingMapper {
  eventClass = PlanPaymentStartedEvent;
  mapEvent(event: PlanPaymentStartedEvent) {
    return {
      eventName: '/Payment - Payment started',
      eventData: { context: event.featureId },
    };
  }
}

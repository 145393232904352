/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import {
  LinksGoogleAdsAccountQueryPort,
  LINKS_GOOGLE_ADS_ACCOUNT_QUERY_PORT,
} from '../../../../../application/ports/primary/links-google-ads-account.query-port';
import {
  GetsIntegrationTypeQueryPort,
  GETS_INTEGRATION_TYPE_QUERY,
} from '../../../../../application/ports/primary/gets-integration-type.query-port';
import { combineLatest, tap } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  SavesLabelManagerConfigCommandPort,
  SAVES_LABEL_MANAGER_CONFIG_COMMAND_PORT,
} from '../../../../../application/ports/primary/saves-label-manager-config.command-port';

@Component({
  selector: 'lib-set-google-ads-linking',
  templateUrl: './set-google-ads-linking.component.html',
  styleUrls: ['./set-google-ads-linking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetGoogleAdsLinkingComponent implements OnDestroy, OnInit {
  private _ngDestroy$ = new Subject<void>();
  private readonly isLinkSended$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly isApproved$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly _isLinkSended = this.isLinkSended$.asObservable();
  readonly _isApproved = this.isApproved$.asObservable();
  readonly steps = [
    {
      title: 'cobiro_pro_label_manager_config_window_link_step_1_title',
      subtitle: 'cobiro_pro_label_manager_config_window_link_step_1_subtitle',
      button: {
        action: () => this.linkGoogleAdsAccount(),
        label: 'cobiro_pro_label_manager_config_window_link_step_1_button',
      },
    },
    {
      title: 'cobiro_pro_label_manager_config_window_link_step_2_title',
      subtitle: 'cobiro_pro_label_manager_config_window_link_step_2_subtitle',
    },
    {
      title: 'cobiro_pro_label_manager_config_window_link_step_3_title',
      subtitle: 'cobiro_pro_label_manager_config_window_link_step_3_subtitle',
    },
    {
      title: 'cobiro_pro_label_manager_config_window_link_step_4_title',
      subtitle: 'cobiro_pro_label_manager_config_window_link_step_4_subtitle',
      checkbox: {
        action: () => this.onApproveLinkRequest(),
        dataSelector: 'team-settings-form-provide-no-vat-number-checkbox',
        label: 'cobiro_pro_label_manager_config_window_link_step_4_checkbox',
        errorCondition: combineLatest([
          this.isApproved$.asObservable(),
          this.isLinkSended$.asObservable(),
        ]).pipe(
          map(
            ([isApproved, isLinkSended]: [boolean, boolean]) =>
              isApproved && isLinkSended === false,
          ),
        ),
        errorText: 'cobiro_pro_label_manager_config_window_link_error',
      },
    },
  ];

  constructor(
    @Optional() public dialogRef: MatDialogRef<SetGoogleAdsLinkingComponent>,
    @Inject(GETS_INTEGRATION_TYPE_QUERY)
    private readonly _getsIntegrationTypeQueryPort: GetsIntegrationTypeQueryPort,
    @Inject(SAVES_LABEL_MANAGER_CONFIG_COMMAND_PORT)
    private readonly _savesLabelManagerConfigCommandPort: SavesLabelManagerConfigCommandPort,
    @Inject(LINKS_GOOGLE_ADS_ACCOUNT_QUERY_PORT)
    private readonly _linkGoogleAdsAccountQueryPort: LinksGoogleAdsAccountQueryPort,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { isSingleComponent: boolean },
  ) {}
  ngOnInit(): void {
    this._getsIntegrationTypeQueryPort
      .getIntegrationType()
      .pipe(
        takeUntil(this._ngDestroy$),
        tap((type: string) => {
          if (type === 'non-css') {
            this.steps.unshift({
              title: 'cobiro_pro_label_manager_config_window_link_step_0_title',
              subtitle: 'cobiro_pro_label_manager_config_window_link_step_0_subtitle',
              button: {
                action: () =>
                  window.open(
                    'https://support.cobiro.com/hc/en-us/articles/17295811174802-Getting-Started-Cobiro-Label-Manager-without-Cobiro-CSS',
                    '_blank',
                  ),
                label: 'cobiro_pro_label_manager_config_window_link_step_0_button',
              },
            });
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }

  linkGoogleAdsAccount(): void {
    this._linkGoogleAdsAccountQueryPort
      .linkGoogleAdsAccount()
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe(() => {
        this.isLinkSended$.next(true);
      });
  }

  onApproveLinkRequest(): void {
    const currentValue = this.isApproved$.getValue();
    this.isApproved$.next(!currentValue);
  }

  goToConfig(): void {
    this.data && this.data?.isSingleComponent
      ? this.dialogRef.close()
      : this._savesLabelManagerConfigCommandPort
          .saveConfig()
          .pipe(
            takeUntil(this._ngDestroy$),
            tap(() => this.dialogRef.close()),
          )
          .subscribe();
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsGoogleAdsLinkingStatusDto } from './gets-google-ads-linking-status.dto';

export const GETS_GOOGLE_ADS_LINKING_STATUS_DTO_PORT =
  new InjectionToken<GetsGoogleAdsLinkingStatusDtoPort>('GETS_GOOGLE_ADS_LINKING_STATUS_DTO_PORT');

export interface GetsGoogleAdsLinkingStatusDtoPort {
  getStatus(dto: GetsGoogleAdsLinkingStatusDto): Observable<boolean>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportWidgetComponentComponent } from './support-widget-component.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SupportWidgetComponentComponent],
  imports: [CommonModule, LanguageModule, RouterModule],
  exports: [SupportWidgetComponentComponent],
})
export class CobiroProSupportWidgetComponentModule {}

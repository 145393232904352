import { Inject, Injectable } from '@angular/core';

import { iif, Observable, of, throwError } from 'rxjs';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { map, switchMap } from 'rxjs/operators';
import { HasDataCollection } from '@cobiro/jsonapi';
import { CardInfo, GetsCards } from '@app.cobiro.com/payment-plans';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';

interface PaymentSourceAttributes {
  details: {
    brand: string;
    ownerName: string;
    last4Digits: string;
    expirationMonth: string;
    expirationYear: string;
  };
}

@Injectable()
export class HttpGetsPaymentSourcesService implements GetsCards {
  constructor(
    private readonly _gateway: GatewayClient,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
  ) {}

  get(): Observable<CardInfo[]> {
    const teamId = this._teamIdGetter.getTeamId();
    return iif(
      () => !!teamId,
      of(teamId),
      throwError(() => 'TeamId not provided'),
    ).pipe(switchMap((id: string) => this._fetchCards(id)));
  }

  private _fetchCards(teamId: string): Observable<CardInfo[]> {
    return this._gateway
      .get<HasDataCollection<PaymentSourceAttributes>>(
        `v1/payments/teams/${teamId}/payment-sources`,
      )
      .pipe(
        map(response =>
          response.data.map(item => ({
            id: item.id,
            lastDigits: item.attributes.details.last4Digits,
            brand: item.attributes.details.brand,
            name: item.attributes.details.ownerName,
            expiryMonth: item.attributes.details.expirationMonth,
            expiryYear: item.attributes.details.expirationYear,
          })),
        ),
      );
  }
}

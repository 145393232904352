import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetsClientsListFilterCommand } from './sets-clients-list-filter.command';

export interface SetsClientsListFilterCommandPort {
  setFilter(command: SetsClientsListFilterCommand): Observable<void>;
}

export const SETS_CLIENTS_LIST_FILTER_COMMAND =
  new InjectionToken<SetsClientsListFilterCommandPort>('SETS_CLIENTS_LIST_FILTER_COMMAND');

import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { UpdateDtoError, UpdatesUserBillingDtoPort } from './updates-user-billing-dto.port';
import { HasData } from '@cobiro/jsonapi';
import { UpdateUserBillingDto } from './update-user-billing.dto';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UPDATE_ERROR_FIELDS } from '../../domain/update-error-fields';

interface UpdateBillingAttributes {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  zipCode?: string;
  countryCode: string;
  vatNumber?: string;
}

// TODO: move this file to sites/payment-plans
@Injectable()
export class HttpUpdatesUserBillingService implements UpdatesUserBillingDtoPort {
  constructor(private readonly _gateway: GatewayClient) {}

  updateBilling(updateBillingDto: UpdateUserBillingDto) {
    return this._gateway
      .put<HasData<UpdateBillingAttributes>>('v1/payments/user-billing', {
        data: {
          type: 'user-billing',
          attributes: this._prepareUpdateBillingPayload(updateBillingDto),
        },
      })
      .pipe(
        map(() => true),
        catchError(error => this._handleError(error)),
      );
  }

  private _prepareUpdateBillingPayload(dto: UpdateUserBillingDto): UpdateBillingAttributes {
    const payload: UpdateBillingAttributes = {
      countryCode: dto.countryCode,
      addressLine1: dto.addressLine1 || null,
      addressLine2: dto.addressLine2,
      firstName: dto.firstName || null,
      lastName: dto.lastName || null,
      companyName: dto.companyName || null,
      city: dto.city || null,
      zipCode: dto.zipCode || null,
      vatNumber: dto.vatNumber || null,
    };
    return payload;
  }

  private _handleError(error: any) {
    const errorObject = error?.error?.errors?.find(err => err.title === 'INVALID_FORMAT');
    let errorField: UPDATE_ERROR_FIELDS | null;
    if (errorObject) {
      switch (errorObject.source.pointer) {
        case 'data.attributes.vatNumber':
          errorField = UPDATE_ERROR_FIELDS.vatNumber;
          break;
        case 'data.attributes.zipCode':
          errorField = UPDATE_ERROR_FIELDS.zipCode;
          break;
        default:
          errorField = null;
          break;
      }
    } else {
      errorField = null;
    }
    const errorDto: UpdateDtoError = { errorField };
    return throwError(errorDto);
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface GetsAddPaymentMethodSucceedQueryPort {
  getAddPaymentMethodSucceedQuery(): Observable<void>;
}

export const GETS_ADD_PAYMENT_METHOD_SUCCEED_QUERY = new InjectionToken(
  'GET_ADD_PAYMENT_METHOD_SUCCEED_QUERY',
);

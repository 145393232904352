import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { Injectable } from '@angular/core';
import { PaymentMethodEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class DataLayerPaymentMethodEventMapper implements DataLayerTrackingMapper {
  eventClass = PaymentMethodEvent;
  mapEvent(event: PaymentMethodEvent) {
    return {
      eventName: `/Payment - Method`,
      eventData: {
        workspaceId: event.workspaceId,
        siteId: event.siteId,
      },
    };
  }
}

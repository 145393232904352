import { ClientDTO } from './client.dto';

export const CLIENT_DTO_STUB: ClientDTO = {
  id: '123',
  siteId: '456',
  cssIntegrationStatus: null,
  merchantSiteId: 'testId',
  companyName: 'Legend of programming',
  contactPerson: 'John Doe',
  contactEmail: 'john@legend.com',
  sitePublicId: '95465646-35e5-456e-83f5-df5974a26dcb',
  contactPhone: '567890123',
  url: 'www.cobiro-test.com',
  teamId: '1',
  avatar: 'https://upload.wikimedia.org/wikipedia/en/6/63/Feels_good_man.jpg',
  source: 'b-rex',
  plan: 'professional',
  installedApps: ['cobirocss'],
  archived: false,
};

export const CLIENT_DTO_STUB_FREE: ClientDTO = {
  id: '123',
  siteId: '456',
  cssIntegrationStatus: null,
  merchantSiteId: 'testId',
  companyName: 'Tik Tokers Union',
  contactPerson: 'John Tic Tac',
  contactEmail: 'johntic@legend.com',
  sitePublicId: '95465646-35e5-456e-83f5-df5974a26dcb',
  contactPhone: '567890123',
  url: 'www.cobiro-test.com',
  teamId: '1',
  avatar: 'https://upload.wikimedia.org/wikipedia/en/6/63/Feels_good_man.jpg',
  source: 'b-rex',
  plan: 'free',
  installedApps: ['cobirocss'],
  archived: false,
};

export const CLIENT_DTO_OUT_SIDE_COBIRO_SITE_STUB: ClientDTO = {
  id: '123',
  siteId: '456',
  cssIntegrationStatus: null,
  merchantSiteId: 'testId',
  companyName: 'Outsiders',
  contactPerson: 'John Silent Outsider',
  contactEmail: 'john@outsider.com',
  sitePublicId: '95465646-35e5-456e-83f5-df5974a26dcb',
  contactPhone: '123321123',
  url: 'www.outsiders.com',
  teamId: '2',
  avatar: 'https://upload.wikimedia.org/wikipedia/en/6/63/Feels_good_man.jpg',
  source: 'outside-cobiro',
  plan: 'professional',
  installedApps: ['cobiro_css'],
  archived: false,
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { MatIconModule } from '@angular/material/icon';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { NoWorkspaceAccessModalComponent } from './no-workspace-access-modal.component';

@NgModule({
  declarations: [NoWorkspaceAccessModalComponent],
  exports: [NoWorkspaceAccessModalComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    HuiLoaderContainerModule,
    MatIconModule,
    HuiAvatarModule,
    LanguageModule,
  ],
})
export class NoWorkspaceAccessModalComponentModule {}

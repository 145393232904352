import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerResultsComponent } from './label-manager-results.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';

@NgModule({
  declarations: [LabelManagerResultsComponent],
  imports: [
    CommonModule,
    LanguageModule,
    NgApexchartsModule,
    MatIconModule,
    HuiLoaderContainerModule,
    HuiLoadingModule,
    MatTooltipModule,
  ],
  exports: [LabelManagerResultsComponent],
})
export class LabelManagerResultsComponentModule {}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  map,
  merge,
  Observable,
  of,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { GetsCurrentWorkspaceQueryPort } from '../ports/primary/gets-current-workspace.query-port';
import { CurrentWorkspaceChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/workspaces';
import { WorkspaceDto, WorkspaceQuery } from '../ports/primary/workspace.query';
import { SetsCurrentWorkspaceQueryPort } from '../ports/primary/sets-current-workspace.query-port';
import { UserDTO } from '../ports/secondary/dto/user.dto';
import { UserQuery } from '../ports/primary/user.query';
import { UsersContext } from '../ports/secondary/context/usersContext';
import { SetsSearchPhraseCommand } from '../ports/primary/sets-search-phrase.command';
import {
  GetsCobiroProContextQueryPort,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  TeamIdGetter,
  TEAM_ID_GETTER,
  CobiroProContextQuery,
} from '@app.cobiro.com/cobiro-pro/context';
import {
  SelectsUsersContextStoragePort,
  SELECTS_USERS_CONTEXT_STORAGE,
} from '../ports/secondary/context/selects-users-context.storage-port';
import {
  PatchesUsersContextStoragePort,
  PATCHES_USERS_CONTEXT_STORAGE,
} from '../ports/secondary/context/patches-users-context.storage-port';
import {
  GetsAllWorkspaceUsersDtoPort,
  GETS_ALL_WORKSPACE_USERS_DTO,
} from '../ports/secondary/dto/gets-all-workspace-users.dto-port';
import { LoadWorkspaceUsersCommandPort } from '../ports/primary/load-workspace-users.command-port';
import { GetsWorkspaceUsersListQueryPort } from '../ports/primary/gets-workspace-users-list.query-port';
import { SetsSearchPhraseWorkspaceCommandPort } from '../ports/primary/sets-search-phrase-workspace-users.command-port';
import { DeleteWorkspaceUserCommandPort } from '../ports/primary/deletes-workspace-user.command-port';
import { DeletesWorkspaceUserCommand } from '../ports/primary/deletes-workspace-user.command';
import { DeleteWorkspaceUserModalComponent } from '../../adapters/primary/ui/modals/delete-workspace-user-modal/delete-workspace-user-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { SetsContextWorkspaceQueryPort } from '../ports/primary/sets-context-workspace.query-port';
import {
  GetsAllWorkspacesDtoPort,
  GETS_ALL_WORKSPACES_DTO,
} from '../ports/secondary/dto/gets-all-workspaces.dto-port';
import { GetsWorkspacesListQuery } from '../ports/primary/gets-workspace-list.query';
import {
  DeletesWorkspaceUserDtoPort,
  DELETES_WORKSPACE_USER_DTO,
} from '../ports/secondary/dto/deletes-workspace-user.dto-port';
import { CobiroProWorkspaceSwitchedEvent, UUID } from '@app.cobiro.com/core/events';
import { UsersListChangedEvent } from '../events/users-list-changed.event';
import { HttpErrorResponse } from '@angular/common/http';
import { AddsAccessCommand } from '../ports/primary/adds-access.command';
import { AddsAccessCommandPort } from '../ports/primary/adds-access.command-port';
import { SendsInvitationCommand } from '../ports/primary/sends-invitation.command';
import { SendsInvitationCommandPort } from '../ports/primary/sends-invitation.command-port';
import {
  SENDS_INVITATION_DTO,
  SendsInvitationDtoPort,
} from '../ports/secondary/dto/sends-invitation.dto-port';
import { ADD_ACCESS_DTO, AddAccessDtoPort } from '../ports/secondary/dto/add-access.dto-port';
import {
  GETS_ALL_USERS_DTO,
  GetsAllUsersDtoPort,
} from '../ports/secondary/dto/gets-all-users.dto-port';

@Injectable()
export class WorkspaceUsersState
  implements
    GetsCurrentWorkspaceQueryPort,
    SetsCurrentWorkspaceQueryPort,
    LoadWorkspaceUsersCommandPort,
    GetsWorkspaceUsersListQueryPort,
    SetsSearchPhraseWorkspaceCommandPort,
    DeleteWorkspaceUserCommandPort,
    SetsContextWorkspaceQueryPort,
    GetsWorkspacesListQuery,
    AddsAccessCommandPort,
    SendsInvitationCommandPort
{
  private readonly _userList$ = new BehaviorSubject<UserDTO[]>([]);
  private readonly _currentWorkspace$ = new BehaviorSubject<WorkspaceDto>(null);

  constructor(
    @Inject(GETS_ALL_WORKSPACES_DTO)
    private readonly _getsAllWorkspacesDtoPort: GetsAllWorkspacesDtoPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    @Inject(GETS_ALL_WORKSPACE_USERS_DTO)
    private readonly _getAllWorkspaceUsersDTO: GetsAllWorkspaceUsersDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(SELECTS_USERS_CONTEXT_STORAGE)
    private readonly _selectsUsersContextStorage: SelectsUsersContextStoragePort,
    @Inject(PATCHES_USERS_CONTEXT_STORAGE)
    private readonly _patchesUsersContextStoragePort: PatchesUsersContextStoragePort,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(DELETES_WORKSPACE_USER_DTO)
    private readonly _deletesWorkspaceUserDtoPort: DeletesWorkspaceUserDtoPort,
    @Inject(SENDS_INVITATION_DTO)
    private readonly _sendsInvitationDto: SendsInvitationDtoPort,
    @Inject(ADD_ACCESS_DTO)
    private readonly _addAccessDtoPort: AddAccessDtoPort,
    @Inject(GETS_ALL_USERS_DTO)
    private readonly _getAllUsersDTO: GetsAllUsersDtoPort,
    private readonly _matDialog: MatDialog,
    private readonly _alert: HuiAlert,
  ) {
    merge(
      this._applicationBus.on(CobiroProWorkspaceSwitchedEvent).pipe(
        map((res: CobiroProWorkspaceSwitchedEvent) => ({
          workspace: {
            id: res.workspaceId,
            name: res.workspaceName,
            avatar: res.avatar,
            membersCount: res.membersCount,
            clientCount: res.clientCount,
          },
        })),
      ),
      this._applicationBus.on(CurrentWorkspaceChangedEvent),
    )
      .pipe(
        tap((event: CurrentWorkspaceChangedEvent) => this._currentWorkspace$.next(event.workspace)),
      )
      .subscribe();
  }
  getWorkspaceListQuery(): Observable<WorkspaceQuery[]> {
    return this._getsAllWorkspacesDtoPort
      .getsAll(this._teamIdGetter.getTeamId())
      .pipe(map((dtos: WorkspaceDto[]) => dtos.map(dto => WorkspaceQuery.fromDTO(dto))));
  }

  setContextWorkspace(): Observable<boolean> {
    return this._getsCobiroProContextQuery.getContext().pipe(
      switchMap(res => {
        const workspace: WorkspaceDto = {
          id: res.selectedWorkspaceId,
          name: res.selectedWorkspaceName,
          avatar: res.selectedWorkspaceAvatar,
          membersCount: res.selectedWorkspaceMembersCount,
        };
        return this.setCurrentWorkspace(workspace);
      }),
    );
  }

  setCurrentWorkspace(workspace: WorkspaceDto): Observable<boolean> {
    this._currentWorkspace$.next(workspace);
    return of(true);
  }

  getCurrentWorkspace(): Observable<WorkspaceQuery> {
    return this._currentWorkspace$.asObservable().pipe(map(dto => WorkspaceQuery.fromDTO(dto)));
  }

  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void> {
    return this._selectsUsersContextStorage.select().pipe(
      take(1),
      map((userContext: UsersContext) =>
        userContext.list.filter((user: UserDTO) =>
          user.email.toLowerCase().includes(command.phrase.toLowerCase()),
        ),
      ),
      tap((users: UserDTO[]) => this._userList$.next(users)),
      map(() => void 0),
    );
  }

  getWorkspaceUserListQuery(): Observable<UserQuery[]> {
    return this._userList$.asObservable().pipe(
      map(users => {
        return this._mapUsersDtoToQuery(users);
      }),
    );
  }

  loadWorkspaceUsers(): Observable<UserDTO[]> {
    const teamId = this._teamIdGetter.getTeamId();
    return this._currentWorkspace$.asObservable().pipe(
      switchMap(workspace =>
        this._getAllWorkspaceUsersDTO
          .getAllWorkspaceUsers({ teamId: teamId, workspaceId: workspace.id })
          .pipe(
            tap(users => {
              this._userList$.next(users);
              this._patchesUsersContextStoragePort.patch({ list: users });
            }),
          ),
      ),
    );
  }

  deleteUser(command: DeletesWorkspaceUserCommand): Observable<void> {
    return this._userList$.asObservable().pipe(
      filter(list => !!list.length),
      map(list => list.find(user => user.id === command.id)),
      switchMap(user =>
        this._matDialog
          .open(DeleteWorkspaceUserModalComponent, {
            minWidth: '400px',
            maxWidth: '700px',
            panelClass: 'cs-mat-dialog',
            data: {
              id: user.id,
              userName: user.name,
              workspaceName: this._currentWorkspace$.getValue().name,
              route: command.route,
            },
          })
          .afterClosed()
          .pipe(concatMap(result => (result ? this._deleteUser(command) : of(void 0)))),
      ),
    );
  }

  private _deleteUser(command: DeletesWorkspaceUserCommand): Observable<void> {
    return this._deletesWorkspaceUserDtoPort
      .deleteWorkspaceUser({
        id: command.id,
        teamId: this._teamIdGetter.getTeamId(),
        workspaces: Array.of(this._currentWorkspace$.getValue().id),
      })
      .pipe(
        switchMap(() =>
          this._selectsUsersContextStorage.select().pipe(
            take(1),
            map(usersContext => usersContext.list),
            tap({
              next: (users: UserDTO[]) => {
                const updatedUsers: UserDTO[] = users.filter(user => user.id !== command.id);
                this._userList$.next(updatedUsers);
                this._patchesUsersContextStoragePort.patch({ list: [...updatedUsers] });
                this._applicationBus.dispatch(new UsersListChangedEvent(updatedUsers));
                this._alert.open(
                  'success',
                  'cobiro_pro_users_delete_workspace_user_success_message',
                );
              },
              error: e => {
                this._alert.open('error', 'cobiro_pro_users_delete_workspace_user_failed_message');
                return throwError(() => e);
              },
            }),
            map(() => void 0),
          ),
        ),
      );
  }

  private _mapUsersDtoToQuery(users: UserDTO[]): UserQuery[] {
    return users.map(userDto => UserQuery.fromUserDTO(userDto));
  }

  sendInvitation(command: SendsInvitationCommand): Observable<void> {
    const id = new UUID().value;
    const teamId = this._teamIdGetter.getTeamId();
    const newUserData: UserDTO = {
      id: id,
      teamId: teamId,
      email: command.userEmail,
      role: command.userRole as 'basic_user' | 'owner' | 'admin',
      createdAt: new Date().toISOString().replace('T', ' '),
      workspaces: command.workspaceIds,
    };
    return this._sendsInvitationDto.sendInvitation(newUserData).pipe(
      catchError(async error => (error.status === 409 ? newUserData.email : false)),
      switchMap(res => {
        const addAccessCommand = {
          teamId: teamId,
          memberId: id,
          workspaceIds: command.workspaceIds,
          ...(typeof res === 'string' && { email: res }),
        };

        return command.userRole === 'basic_user'
          ? this._addAccessDtoPort.addAccess(addAccessCommand)
          : of(void 0);
      }),
      switchMap(() =>
        this._selectsUsersContextStorage.select().pipe(
          take(1),
          map(userContext => userContext.list),
        ),
      ),
      tap({
        next: (userList: UserDTO[]) => {
          const updatedUsers: UserDTO[] = [newUserData, ...userList];
          this._userList$.next(updatedUsers);
          this._patchesUsersContextStoragePort.patch({ list: [...updatedUsers] });
          this._applicationBus.dispatch(new UsersListChangedEvent(updatedUsers));
          this._alert.open('success', 'cbr_pro_members_invitation_sent');
        },
        error: err => {
          if (err instanceof HttpErrorResponse && err.status === 409) {
            this._alert.open('error', 'cbr_pro_members_already_invited');
          } else {
            this._alert.open('error', '_something_went_wrong');
          }
          return throwError(() => err);
        },
      }),
      map(() => void 0),
    );
  }

  addAccess(command: AddsAccessCommand): Observable<void> {
    const teamId = this._teamIdGetter.getTeamId();
    return this._addAccessDtoPort
      .addAccess({
        teamId: teamId,
        memberId: command.memberId,
        workspaceIds: command.workspaceIds,
      })
      .pipe(
        switchMap(() =>
          combineLatest([
            this._getAllUsersDTO
              .getAll({ teamId })
              .pipe(map((users: UserDTO[]) => users.find(user => user.id === command.memberId))),
            this._selectsUsersContextStorage.select().pipe(
              take(1),
              map(userContext => userContext.list),
            ),
          ]),
        ),
        tap({
          next: ([user, userList]: [UserDTO, UserDTO[]]) => {
            const updatedUsers: UserDTO[] = [
              { ...user, workspaces: [...user.workspaces, ...command.workspaceIds] },
              ...userList,
            ];
            this._userList$.next(updatedUsers);
            this._patchesUsersContextStoragePort.patch({ list: [...updatedUsers] });
            this._applicationBus.dispatch(new UsersListChangedEvent(updatedUsers));
            this._alert.open('success', 'cbr_pro_members_invitation_sent');
          },
          error: err => {
            if (err instanceof HttpErrorResponse && err.status === 409) {
              this._alert.open('error', 'cbr_pro_members_already_invited');
            } else {
              this._alert.open('error', '_something_went_wrong');
            }
            return throwError(() => err);
          },
        }),
        map(() => void 0),
      );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { SwitchExistingIntegrationAccountFormComponent } from './switch-existing-integration-account-form.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    HuiInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
  ],
  declarations: [SwitchExistingIntegrationAccountFormComponent],
  exports: [SwitchExistingIntegrationAccountFormComponent],
})
export class SwitchExistingIntegrationAccountFormComponentModule {}

import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlanSelectedEvent } from '@app.cobiro.com/core/events';
import { FeaturesLoadedEvent } from '@app.cobiro.com/core/payments-features';
import { State } from '@app.cobiro.com/core/state';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { combineLatest, Observable, of, ReplaySubject, zip } from 'rxjs';
import {
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { USER_PLAN } from '../ports/primary/payment-plan-cards-mapper';
import { PlanSelectionQuery } from '../../../upgrade/ui/upgrade-plan-flow/upgrade-plan-modal-data';
import { GETS_PAYMENT_PLAN, GetsPaymentPlans } from '../../domain/gets-payment-plans';
import { GETS_PLAN_FEATURES, GetsPlanFeatures } from '../../domain/gets-plan-features';
import {
  PaymentPlan,
  PaymentPlanData,
  PaymentPlanId,
  PaymentPlanPeriod,
} from '../../domain/payment-plan';
import { SelectedPlan } from '../../domain/selected-plan';
import { CURRENT_PLAN_TYPE_STORAGE } from '../../domain/storage/current-plan-type.storage';
import { FEATURE_CONTEXT_STORAGE } from '../../domain/storage/feature-context.storage';
import { SELECTED_PLAN_STORAGE } from '../../domain/storage/selected-plan.storage';
import { RedirectToUpgradeRequestedEvent } from '../../events';

export interface PaymentPlansStateModel {
  items: PaymentPlanPeriod[];
  paymentPlanData: PaymentPlanData[];
  plansForFeature: Set<string>;
  selectedPlanPeriod: PaymentPlanPeriod;
  selectedPlanName: USER_PLAN;
  selectedPlanId: string;
  enabledPlans: Map<string, boolean>;
}

const INIT_STATE: PaymentPlansStateModel = {
  items: [PaymentPlanPeriod.MONTHLY, PaymentPlanPeriod.YEARLY],
  paymentPlanData: [],
  plansForFeature: null,
  selectedPlanPeriod: PaymentPlanPeriod.MONTHLY,
  selectedPlanName: null,
  selectedPlanId: null,
  enabledPlans: new Map(),
};

// TODO refactor IT!

@Injectable()
export class PaymentPlansState extends State<PaymentPlansStateModel> {
  private _planFeaturesState = new ReplaySubject<Map<string, Map<string, number | null>>>(1);
  private _featureFlagMap = new Map<string, { flag: string; isBlocked: boolean }>([
    ['pricing_fbAds', { flag: 'facebook_fake_door_test', isBlocked: true }],
  ]);
  private _planFeatureFlags = new Map<string, string>([
    ['free', 'payment_plan_availability_free'],
    ['starter', 'payment_plan_availability_starter'],
    ['professional', 'payment_plan_availability_professional'], // Deprecated
    ['premium', 'payment_plan_availability_premium'],
    ['business', 'payment_plan_availability_business'],
  ]);

  readonly planTypeCollection$ = this.state$.pipe(
    distinctUntilKeyChanged('items'),
    distinctUntilKeyChanged('selectedPlanPeriod'),
    map(state => ({ selected: state.selectedPlanPeriod, items: state.items })),
  );

  readonly paymentPlans$: Observable<PaymentPlan[]> = combineLatest([
    this.state$.pipe(
      distinctUntilKeyChanged('selectedPlanPeriod'),
      map(state => state.selectedPlanPeriod),
    ),
    this.state$.pipe(
      distinctUntilKeyChanged('paymentPlanData'),
      map(state => state.paymentPlanData),
    ),
    this.state$.pipe(
      distinctUntilKeyChanged('enabledPlans'),
      map(state => state.enabledPlans),
    ),
  ]).pipe(
    map(([selectedPlanPeriod, paymentPlanData, enabledPlans]) => {
      return [
        ...paymentPlanData.map(plan => PaymentPlan.fromPlanData(plan, selectedPlanPeriod)),
      ].filter(plan => enabledPlans.get(plan.name.toLocaleLowerCase()));
    }),
  );

  get plansForFeature$(): Observable<PaymentPlan[]> {
    return this.state$.pipe(
      filter(state => !!state.plansForFeature),
      map(state => {
        return [
          ...state.paymentPlanData
            .filter(item => state.plansForFeature.has(item.name.toLocaleLowerCase()))
            .filter(item => state.enabledPlans.get(item.name.toLocaleLowerCase()))
            .map(plan => PaymentPlan.fromPlanData(plan, state.selectedPlanPeriod)),
        ];
      }),
    );
  }

  selectedPlanPeriod$: Observable<'yearly' | 'monthly'> = this.state$.pipe(
    distinctUntilKeyChanged('selectedPlanPeriod'),
    map(state => state.selectedPlanPeriod),
  );

  selectedPlanName$: Observable<USER_PLAN> = this.state$.pipe(
    distinctUntilKeyChanged('selectedPlanName'),
    map(state => state.selectedPlanName),
  );

  selectedPlan$: Observable<SelectedPlan> = this._selectedPlanStorage.select();

  constructor(
    @Inject(GETS_PAYMENT_PLAN) private _getsPaymentPlan: GetsPaymentPlans,
    private _dialog: MatDialog,
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
    @Inject(GETS_PLAN_FEATURES) private _getsPlanFeatures: GetsPlanFeatures,
    @Inject(CURRENT_PLAN_TYPE_STORAGE)
    private _currentPlanTypeStorage: ReactiveSingleValueStorage<string>,
    @Inject(FEATURE_CONTEXT_STORAGE)
    private _featureContextStorage: ReactiveSingleValueStorage<string>,
    @Inject(SELECTED_PLAN_STORAGE)
    private _selectedPlanStorage: ReactiveSingleValueStorage<SelectedPlan>,
    private _featureFlagsState: FeatureFlagsState,
  ) {
    super(INIT_STATE);
    this._initFeatureMap();
    this._setSelectedPlan();

    // TODO: Move this part to PlanFeaturesState class
    combineLatest([this._currentPlanTypeStorage.select(), this._planFeaturesState]).subscribe(
      ([currentPlan, planFeatures]) => {
        this._applicationBus.dispatch(new FeaturesLoadedEvent(planFeatures.get(currentPlan)));
      },
    );
  }

  changePlanPeriod(selectedPlanPeriod: 'yearly' | 'monthly') {
    this.patchState({ selectedPlanPeriod: selectedPlanPeriod as PaymentPlanPeriod });
  }

  changePlanName(selectedPlanName: USER_PLAN) {
    this.patchState({ selectedPlanName: selectedPlanName });
  }

  changeSelectedPlan(planId: string): void {
    this.patchState({ selectedPlanId: planId });
    this._featureContextStorage
      .select()
      .pipe(take(1))
      .subscribe(context => {
        this._applicationBus.dispatch(new PlanSelectedEvent(context, planId));
      });
  }

  public initState(): Observable<boolean> {
    return combineLatest([
      this._getsPaymentPlan
        .getPaymentPlans()
        .pipe(map(plans => plans.map(plan => this._filterFeatures(plan)))),
      this._getsPlanFeatures.get(),
    ]).pipe(
      take(1),
      tap(
        ([paymentPlanData, planFeatureMap]: [
          PaymentPlanData[],
          Map<string, Map<string, number | null>>,
        ]) => {
          this.patchState({ paymentPlanData });
          this._planFeaturesState.next(planFeatureMap);
        },
      ),
      switchMap(
        ([paymentPlanData, paymentPlanFeatures]: [
          PaymentPlanData[],
          Map<string, Map<string, number | null>>,
        ]) => {
          return zip(
            of(paymentPlanData),
            zip(
              ...paymentPlanData.map(plan => {
                return this._planFeatureFlags.has(plan.name.toLocaleLowerCase())
                  ? this._featureFlagsState.hasFlags([
                      this._planFeatureFlags.get(plan.name.toLocaleLowerCase()),
                    ])
                  : of(false);
              }),
            ),
          );
        },
      ),
      tap(([paymentPlanData, plansEnabled]: [PaymentPlanData[], boolean[]]) => {
        const enabledPlans = new Map<string, boolean>();
        paymentPlanData.forEach((planData, index) => {
          enabledPlans.set(planData.name.toLocaleLowerCase(), plansEnabled[index]);
        });
        this.patchState({
          enabledPlans,
        });
      }),
      map(() => true),
    );
  }

  private _selectPlansByFeature(featureId: string): void {
    zip(this._planFeaturesState, this.state$)
      .pipe(take(1))
      .subscribe(([planFeatures, state]) => {
        const plansForFeature = new Set<string>();

        planFeatures.forEach((featuresPerPlan, planId) => {
          if (featuresPerPlan.has(featureId) && state.enabledPlans.has(planId)) {
            plansForFeature.add(planId);
          }
        });

        this.patchState({ plansForFeature: plansForFeature });
      });
  }

  startUpgradeFlow(featureId: string, planSelection?: PlanSelectionQuery): void {
    if (!featureId) {
      throw new MissingFeatureIdError();
    }
    // this._planFeaturesState.pipe(take(1)).subscribe(planFeatures => {
    //   if (!Array.from(planFeatures.values()).find(features => features.has(featureId))) {
    //     throw new InvalidFeatureIdProvidedError(featureId);
    //   }
    //   this._featureContextStorage.save(featureId);
    //   this._selectPlansByFeature(featureId);
    //   this._applicationBus.dispatch(new UpgradeFlowStartedEvent(featureId));
    //   if (planSelection) {
    //     const planId = new PaymentPlanId(
    //       planSelection.name,
    //       planSelection.period as PaymentPlanPeriod,
    //     );
    //
    //     this.changeSelectedPlan(planId.value);
    //   }
    //
    //   this._featureFlagsState
    //     .hasFlags(['cobiro_pro_team_payments'])
    //     .pipe(
    //       take(1),
    //     )
    //     .subscribe((component: ComponentType<unknown>) =>
    //       this._dialog
    //         .open(component, {
    //           data: { planSelection },
    //           panelClass: 'cs-mat-dialog',
    //         })
    //         .afterClosed()
    //         .pipe(
    //           take(1),
    //           tap(success => {
    //             this._applicationBus.dispatch(new UpgradeFlowEndedEvent(success || false));
    //           }),
    //         ),
    //     ); // fixme investigate why event is not used
    // });
  }

  redirectToPlanComparison() {
    this._applicationBus.dispatch(new RedirectToUpgradeRequestedEvent());
  }

  clear() {
    const { plansForFeature, selectedPlanId, selectedPlanPeriod } = INIT_STATE;
    this.patchState({ plansForFeature, selectedPlanId, selectedPlanPeriod });
  }

  private _setSelectedPlan() {
    combineLatest([
      this.state$.pipe(
        distinctUntilKeyChanged('selectedPlanId'),
        map(state => state.selectedPlanId),
      ),
      this.state$.pipe(
        distinctUntilKeyChanged('selectedPlanPeriod'),
        map(state => state.selectedPlanPeriod),
      ),
    ])
      .pipe(
        withLatestFrom(this.paymentPlans$),
        filter(
          ([[selectedPlanId, selectedPlanPeriod]]) =>
            !!selectedPlanId &&
            selectedPlanId ===
              new PaymentPlanId(selectedPlanId.split('-')[0], selectedPlanPeriod).value,
        ),
        map(([[selectedPlanId, selectedPlanPeriod], plans]) => {
          const selectedPlan = plans.find(plan => plan.id === selectedPlanId);
          return {
            id: selectedPlanId,
            name: selectedPlan.name,
            period: selectedPlanPeriod as PaymentPlanPeriod,
            price:
              selectedPlan.pricedPeriods[selectedPlanPeriod] *
              (selectedPlanPeriod === 'yearly' ? 12 : 1),
          };
        }),
        tap(plan => this._selectedPlanStorage.save(plan)),
      )
      .subscribe();
  }

  private _filterFeatures(plan: PaymentPlanData): PaymentPlanData {
    return {
      ...plan,
      features: plan.features.filter(f => !this._featureFlagMap.get(f.id)?.isBlocked),
    };
  }

  private _initFeatureMap() {
    Array.from(this._featureFlagMap.entries()).forEach(([key, value]) => {
      this._featureFlagsState
        .hasFlags([value.flag])
        .pipe(take(1))
        .subscribe(isAllowed => this._featureFlagMap.set(key, { ...value, isBlocked: !isAllowed }));
    });
  }
}

export class MissingFeatureIdError extends Error {
  message = 'Missing feature id';
  name = 'MISSING_FEATURE_ID';
}

export class InvalidFeatureIdProvidedError extends Error {
  constructor(featureId: string) {
    super();
    this.message = `Provided featureId: ${featureId} does not appear on the features list`;
    this.name = 'INVALID_FEATURE_ID_PROVIDED';
  }
}

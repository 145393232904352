import { PAYMENT_STATUS } from '../domain/payment-status.enum';

export interface PaymentConfirmationDisplay {
  titleKey: string;
  descriptionKey: string;
}

export class UnknownConfirmationError extends Error {
  title = 'No confirmation known';

  constructor(confirmation: PAYMENT_STATUS) {
    super();
    this.message = `No confirmation ${confirmation}`;
  }
}

export class PaymentConfirmationQuery {
  private readonly messages: Map<PAYMENT_STATUS, PaymentConfirmationDisplay> = new Map([
    [
      PAYMENT_STATUS.SUCCESS,
      {
        titleKey: 'payment_plans_upgrade_success',
        descriptionKey: 'payment_plans_upgrade_success_description',
      },
    ],
    [
      PAYMENT_STATUS.DEFAULT_FAILED,
      {
        titleKey: 'payment_plans_upgrade_failure',
        descriptionKey: 'payment_plans_upgrade_failure_description',
      },
    ],
    [
      PAYMENT_STATUS.INSUFFICIENT_FUNDS,
      {
        titleKey: 'payment_plans_fail_modal_insufficient_funds_title',
        descriptionKey: 'payment_plans_fail_modal_insufficient_funds_description',
      },
    ],
  ]);

  titleKey: string;
  descriptionKey: string;
  value: PAYMENT_STATUS;

  get isSuccess() {
    return this.value === PAYMENT_STATUS.SUCCESS;
  }

  constructor(paymentStatus: PAYMENT_STATUS) {
    this.value = paymentStatus;
    const display: PaymentConfirmationDisplay = this.messages.get(paymentStatus);

    if (!display) {
      throw new UnknownConfirmationError(paymentStatus);
    }

    this.titleKey = display.titleKey;
    this.descriptionKey = display.descriptionKey;
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AvailableAppQuery } from './available-app.query';

export const GETS_AVAILABLE_APPS_QUERY_PORT = new InjectionToken('GETS_AVAILABLE_APPS_QUERY_PORT');

export interface GetsAvailableAppsQueryPort {
  getAllAvailableApps(): Observable<AvailableAppQuery[]>;
  isAppAvailable(id: string): Observable<boolean>;
}

export enum NOTIFICATION_TYPES {
  MESSAGE = 'message',
  NEW_ORDER = 'newOrder',
}

export abstract class Notification {
  public readonly type: NOTIFICATION_TYPES;
  private _isRead: boolean;
  public get isRead() {
    return this._isRead;
  }
  protected constructor(public readonly id: string, public readonly date: Date, isRead: boolean) {
    this._isRead = isRead;
  }

  markAsRead() {
    this._isRead = true;
  }
}

export class NewOrderNotification extends Notification {
  public readonly type = NOTIFICATION_TYPES.NEW_ORDER;
  constructor(
    public readonly id: string,
    public readonly date: Date,
    isRead: boolean,
    public readonly orderId: string,
  ) {
    super(id, date, isRead);
  }
}

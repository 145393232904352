import { Observable } from 'rxjs';
import { CurrentPlanQuery } from './current-plan.query';
import { InjectionToken } from '@angular/core';

export const GETS_CURRENT_PLAN_QUERY_PORT = new InjectionToken<GetsCurrentPlanQueryPort>(
  'GETS_CURRENT_PLAN_QUERY_PORT',
);

export interface GetsCurrentPlanQueryPort {
  get(): Observable<CurrentPlanQuery>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { WorkspaceQuery } from './workspace.query';

export interface GetsMemberWorkspacesListQuery {
  getMemberWorkspaceListQuery(): Observable<WorkspaceQuery[]>;
}

export const GETS_MEMBER_WORKSPACES_LIST_QUERY = new InjectionToken<GetsMemberWorkspacesListQuery>(
  'GETS_MEMBER_WORKSPACES_LIST_QUERY',
);

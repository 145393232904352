<div class="cs-max-width-500-xs">
  <form [formGroup]="form" class="cs-ph-20 cs-ph-0-lg">
    <div class="cs-control-wrapper cs-mb-20">
      <label class="cs-label" for="company-name-input">
        {{ '_company_name' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <input
        class="cs-input"
        data-selector="profile-company-name-input"
        formControlName="companyName"
        id="company-name-input"
      />
      <p
        *ngIf="hasError('companyName')"
        class="cs-control-error"
        data-selector="profile-company-name-required-message"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>

    <div *ngIf="companyTypes$ | async as companyTypes" class="cs-control-wrapper cs-mb-20">
      <label class="cs-label" for="company-type">
        {{ 'create_profile_company_details_company_type_placeholder' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <mat-form-field class="cs-dropdown">
        <mat-select
          disableRipple="true"
          formControlName="companyType"
          id="company-type"
          panelClass="cs-dropdown-options"
        >
          <mat-option *ngFor="let companyType of companyTypes" [value]="companyType.value">
            {{ companyType.displayValue | language }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p
        *ngIf="hasError('companyType')"
        class="cs-control-error"
        data-selector="profile-company-type-required-message"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>

    <div
      *ngIf="showNumberOfEmployees && numberOfEmployees$ | async as numberOfEmployeesList"
      class="cs-control-wrapper cs-mb-20"
    >
      <label class="cs-label" for="number-of-employees">
        {{ 'create_profile_company_details_number_of_employees_placeholder' | language }}
      </label>
      <mat-form-field class="cs-dropdown">
        <mat-select
          disableRipple="true"
          formControlName="numberOfEmployees"
          id="number-of-employees"
          panelClass="cs-dropdown-options"
        >
          <mat-option [value]="null"> --- </mat-option>
          <mat-option
            *ngFor="let numberOfEmployees of numberOfEmployeesList"
            [value]="numberOfEmployees.value"
          >
            {{ numberOfEmployees.displayValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="numberOfClients$ | async as numberOfClientsList"
      class="cs-control-wrapper cs-mb-20"
    >
      <label class="cs-label" for="number-of-clients">
        {{ 'create_profile_company_details_number_of_clients_placeholder' | language }}
      </label>
      <mat-form-field class="cs-dropdown">
        <mat-select
          disableRipple="true"
          formControlName="numberOfClients"
          id="number-of-clients"
          panelClass="cs-dropdown-options"
        >
          <mat-option [value]="null"> --- </mat-option>
          <mat-option
            *ngFor="let numberOfClients of numberOfClientsList"
            [value]="numberOfClients.value"
          >
            {{ numberOfClients.displayValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-between align-items-center cs-mt-20 w-100">
      <button
        (click)="onSubmitClicked()"
        class="cs-btn cs-btn-primary"
        data-selector="company-details-save-btn"
        [disabled]="form.invalid || form.pristine"
        type="submit"
      >
        {{ '_save' | language }}
      </button>
      <button
        *ngIf="(couldBeDeleted$ | async) === true"
        (click)="onDeleteButtonClicked()"
        class="cs-btn cs-btn-sm cs-btn-error"
        type="button"
      >
        {{ 'cobiro_pro_users_column_delete' | language }}
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </form>
</div>

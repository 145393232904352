import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  hasLowercaseValidator,
  hasNumberValidator,
  hasUppercaseValidator,
} from '@app.cobiro.com/shared/validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// TODO: Mark as touched mechanisms
@Component({
  selector: 'lib-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private static readonly MIN_PASSWORD_LENGTH = 8;
  private static readonly SPECIAL_CHARACTERS_PATTERN = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  private static readonly VALIDATORS = [
    hasLowercaseValidator,
    hasUppercaseValidator,
    hasNumberValidator,
    Validators.pattern(PasswordFormComponent.SPECIAL_CHARACTERS_PATTERN),
    Validators.required,
    Validators.minLength(PasswordFormComponent.MIN_PASSWORD_LENGTH),
  ];

  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() disableErrorMessage = false;
  @ViewChild('inputElement') inputElement: ElementRef;

  @Output() enterPressed = new EventEmitter();

  private destroyed$ = new Subject<void>();

  public readonly validationHints = [
    {
      text: 'signup_password_input_lowercase',
      errorName: 'noLowerCaseCharacter',
      translationParams: [],
    },
    {
      text: 'signup_password_input_uppercase',
      errorName: 'noUpperCaseCharacter',
      translationParams: [],
    },
    {
      text: 'signup_password_input_symbol',
      errorName: 'pattern',
      translationParams: [],
    },
    {
      text: 'signup_password_input_number',
      errorName: 'noNumber',
      translationParams: [],
    },
    {
      text: 'signup_password_input_length',
      errorName: 'minlength',
      translationParams: [PasswordFormComponent.MIN_PASSWORD_LENGTH],
    },
  ];
  showPasswordHint = false;
  inputPassword = true;
  isPhone = false;
  passwordFormControl = new UntypedFormControl('', PasswordFormComponent.VALIDATORS);

  private onChange = (_: string | null) => {};
  private onTouched = () => {};

  constructor() {}

  ngOnInit() {
    this.passwordFormControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChange(value);
    });
  }

  onFocus(): void {
    this.showPasswordHint = true;
  }

  onBlur(): void {
    this.showPasswordHint = false;
  }

  focusInput(): void {
    this.inputElement.nativeElement.focus();
  }

  onEnterPressed($event: Event): void {
    $event.preventDefault();
    this.enterPressed.emit();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(value: string | null): void {
    (value || typeof value === 'string') && this.passwordFormControl.setValue(value);
    this.onChange(value);
  }

  registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return PasswordFormComponent.VALIDATORS.reduce(
      (validators, currValidatorFn) => ({ ...currValidatorFn(control), ...validators }),
      {},
    );
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerReportQuery } from './label-manager-report.query';

export const LOADS_LABEL_MANAGER_REPORT_QUERY_PORT =
  new InjectionToken<LoadsLabelManagerReportQueryPort>('LOADS_LABEL_MANAGER_REPORT_QUERY_PORT');

export interface LoadsLabelManagerReportQueryPort {
  loadReport(isSimulation: boolean): Observable<LabelManagerReportQuery>;
}

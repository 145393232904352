import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const CALL_PAYMENT_BILLING_COMMAND = new InjectionToken<CallPaymentBillingCommandPort>(
  'CALL_PAYMENT_BILLING_COMMAND',
);

export interface CallPaymentBillingCommandPort {
  dispatchEvent(): Observable<void>;
}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  repeat,
  skipWhile,
  switchMap,
  take,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { SetsLabelManagerConfigCommand } from '../../ports/primary/sets-label-manager-config.command';
import {
  GetsAppsMetaDtoPort,
  GETS_APPS_META_DTO_PORT,
} from '../../ports/secondary/gets-apps-meta.dto-port';
import { AppMetadataQuery } from '../../ports/primary/app-metadata.query';
import { AppMetadataDto } from '../../ports/secondary/app-metadata.dto';
import { GetsLabelManagerMetaQueryPort } from '../../ports/primary/gets-label-manager-meta.query-port';
import { GetsInstalledAppsQueryPort } from '../../ports/primary/gets-installed_apps.query-port';
import {
  GetsInstalledAppsIdsDtoPort,
  GETS_INSTALLED_APPS_IDS_DTO_PORT,
} from '../../ports/secondary/gets-installed-apps-ids';
import { STORAGE } from '@app.cobiro.com/core/storage';
import {
  LinksGoogleAdsAccountDtoPort,
  LINKS_GOOGLE_ADS_ACCOUNT_DTO_PORT,
} from '../../ports/secondary/links-google-ads-account.dto-port';
import {
  GetsGoogleAdsLinkingStatusDtoPort,
  GETS_GOOGLE_ADS_LINKING_STATUS_DTO_PORT,
} from '../../ports/secondary/gets-google-ads-linking-status.dto-port';
import { LinksGoogleAdsAccountQueryPort } from '../../ports/primary/links-google-ads-account.query-port';
import { GetsGoogleAdsLinkingStatusQueryPort } from '../../ports/primary/gets-google-ads-linking-status.query-port';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { IntegrationIdChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/cobiro-css';
import { GetsLabelManagerConfigQueryPort } from '../../ports/primary/gets-label-manager-config.query-port';
import { SetsLabelManagerConfigCommandPort } from '../../ports/primary/sets-label-manager-config.command-port';
import {
  SetsLabelManagerConfigDtoPort,
  SETS_LABEL_MANAGER_CONFIG_DTO_PORT,
} from '../../ports/secondary/sets-label-manager-config.dto-port';
import {
  GetsLabelManagerConfigDtoPort,
  GETS_LABEL_MANAGER_CONFIG_DTO_PORT,
} from '../../ports/secondary/gets-label-manager-config.dto-port';
import { LabelManagerConfigDto } from '../../ports/secondary/label-manager-config.dto';
import { ShowConfigStepperQueryPort } from '../../ports/primary/shows-config-stepper.query-port';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LabelManagerNoIntegrationComponent } from '../../../adapters/primary/ui/modals/label-manager-no-integration/label-manager-no-integration.component';
import { ShowStartPageQueryPort } from '../../ports/primary/shows-start-page.query-port';
import {
  GetsLabelManagerReportDtoPort,
  GETS_LABEL_MANAGER_REPORT_DTO_PORT,
} from '../../ports/secondary/gets-label-manager-report.dto-port';
import { GetsLabelManagerReportQueryPort } from '../../ports/primary/gets-label-manager-report.query-port';
import {
  LabelManagerReportDateData,
  LabelManagerReportQuery,
  LabelManagerReportSegmentData,
} from '../../ports/primary/label-manager-report.query';
import { LabelManagerReportDto } from '../../ports/secondary/label-manager-report.dto';
import { LabelManagerConfigQuery } from '../../ports/primary/label-manager-config.query';
import { GetsIntegrationIdQueryPort } from '../../ports/primary/gets-integration-id.query-port';
import { HttpErrorResponse } from '@angular/common/http';
import { IntegrationInfoQuery } from '../../ports/primary/integration-info.query';
import { SetsIntegrationQueryPort } from '../../ports/primary/sets-integration.query-port';
import { GetsIntegrationTypeQueryPort } from '../../ports/primary/gets-integration-type.query-port';
import { StartsIntegrationProcessCommandPort } from '../../ports/primary/starts-integration-process.command-port';
import {
  GetsClientSubsciptionsDtoPort,
  GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT,
} from '../../ports/secondary/gets-client-subscriptions.dto-port';
import { StartsIntegrationProcessCommand } from '../../ports/primary/starts-integration-process.command';
import {
  TeamIdGetter,
  TEAM_ID_GETTER,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { LabelManagerNonCssIntegrationComponent } from '../../../adapters/primary/ui/modals/label-manager-non-css-integration/label-manager-non-css-integration.component';
import {
  GetsLabelManagerLatestConfigDtoPort,
  GETS_LABEL_MANAGER_LATEST_CONFIG_DTO_PORT,
} from '../../ports/secondary/gets-label-manager-latest-config.dto-port';
import { CreatesLMSimulationQueryPort } from '../../ports/primary/creates-lm-simulation.query-port';
import { SetGoogleAdsLinkingComponent } from '../../../adapters/primary/ui/modals/label-manager-google-ads-linking/set-google-ads-linking.component';
import {
  InitsLMSimulationDtoPort,
  INITS_LM_SIMULATION_DTO,
} from '../../ports/secondary/inits-lm-simulation.dto-port';
import {
  LabelManagerSetConfigEvent,
  LabelManagerSetReportEvent,
  LabelManagerSimulationRequestEvent,
  PaymentFlowStartedEvent,
} from '@app.cobiro.com/core/events';
import { ShowsSimulationOptionQueryPort } from '../../ports/primary/shows-simulation-option.query-port';
import { ShowsSimulationOptionQuery } from '../../ports/primary/shows-simulation-option.query';
import { ClientSubscriptionDto } from '../../ports/secondary/subscription.dto';
import { Router } from '@angular/router';
import { GetsLabelManagerReportLengthQueryPort } from '../../ports/primary/gets-label-manager-report-length.query-port';
import { GetsLabelManagerLoaderMessageQueryPort } from '../../ports/primary/gets-label-manager-loader-message.query-port';
import { IsLabelManagerReportLoadingQueryPort } from '../../ports/primary/is-label-manager-report-loading.query-port';
import { CleansLMReportDateQueryPort } from '../../ports/primary/cleans-lm-report-date.query-port';
import { GetsLabelManagerErrorMessageQueryPort } from '../../ports/primary/gets-label-manager-error-message.query-port';
import { SavesLabelManagerConfigCommandPort } from '../../ports/primary/saves-label-manager-config.command-port';
import { LABEL_MANAGER_INITIAL_CONFIG_QUERY_STUB } from '../../ports/primary/label-manager-config.query.stub';
import { LABEL_MANAGER_CONFIG_DTO_STUB } from '../../ports/secondary/label-manager-config.dto.stub';
import { SetsCurrentLMReportQueryPort } from '../../ports/primary/sets-current-lm-report.query-port';
import { GetsLabelManagerSegmentsQueryPort } from '../../ports/primary/gets-label-manager-segments.query-port';
import { LoadsLabelManagerReportQueryPort } from '../../ports/primary/loads-label-manager-report.query-port';
import { IntegrationInfoDto } from '../../ports/secondary/integration-info.dto';
import { IntegrationErrorQuery } from '../../ports/primary/integration-error.query';
import { OpenNonCssDilogCommandPort } from '../../ports/primary/opens-non-css-dialog.command-port';

const LM_INTEGRATION_ERROR_MAP = {
  ['SHOPPING_CONTENT_API_REQUEST_FAILED']: {
    message: 'lm_integration_error_message_1',
  },
  ['INVALID_TOKEN_ID']: {
    message: 'lm_integration_error_message_2',
  },
  ['ADS_ACCOUNT_LINK_FAILED']: {
    message: 'lm_integration_error_message_2',
  },
  ['ADS_ACCOUNT_NOT_LINKED']: {
    message: 'lm_integration_error_message_2',
  },
  ['ACCOUNT_IS_NOT_ACCESSIBLE']: {
    message: 'lm_integration_error_message_3',
  },
  ['UNEXPECTED_ERROR']: {
    message: 'lm_integration_error_message_4',
  },
  ['MARKET_INSIGHTS_NOT_ENABLED']: {
    message: 'lm_integration_error_message_4',
  },
};

@Injectable()
export class LabelManagerIntegrationState
  implements
    GetsLabelManagerMetaQueryPort,
    GetsInstalledAppsQueryPort,
    LinksGoogleAdsAccountQueryPort,
    GetsGoogleAdsLinkingStatusQueryPort,
    GetsLabelManagerConfigQueryPort,
    SetsLabelManagerConfigCommandPort,
    ShowConfigStepperQueryPort,
    ShowStartPageQueryPort,
    StartsIntegrationProcessCommandPort,
    GetsLabelManagerReportQueryPort,
    GetsIntegrationIdQueryPort,
    GetsIntegrationTypeQueryPort,
    SetsIntegrationQueryPort,
    CreatesLMSimulationQueryPort,
    ShowsSimulationOptionQueryPort,
    GetsLabelManagerReportLengthQueryPort,
    GetsLabelManagerLoaderMessageQueryPort,
    IsLabelManagerReportLoadingQueryPort,
    CleansLMReportDateQueryPort,
    GetsLabelManagerErrorMessageQueryPort,
    SavesLabelManagerConfigCommandPort,
    SetsCurrentLMReportQueryPort,
    GetsLabelManagerSegmentsQueryPort,
    LoadsLabelManagerReportQueryPort,
    OpenNonCssDilogCommandPort
{
  private _integrationType$: BehaviorSubject<'owned' | 'switched' | 'non-css' | null> =
    new BehaviorSubject(null);
  private _integrationStatus$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _integrationId$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _errors$: BehaviorSubject<IntegrationErrorQuery | null> = new BehaviorSubject(null);
  private _isAccountLinked$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  private _isInitialConfiguration$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _showStartPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _configAttributes$: BehaviorSubject<LabelManagerConfigDto> =
    new BehaviorSubject<LabelManagerConfigDto>(LABEL_MANAGER_CONFIG_DTO_STUB);
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _lmSimulationTime$: BehaviorSubject<number | null> = new BehaviorSubject(null);
  private _loadingMessage$: BehaviorSubject<string> = new BehaviorSubject(
    'cobiro_pro_label_manager_loading_message_1',
  );
  private _currentLMResult$: BehaviorSubject<
    ({ isLatest?: boolean } & LabelManagerReportDateData) | null
  > = new BehaviorSubject(null);
  private _errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject(null);

  private _currentLMReport$: BehaviorSubject<LabelManagerReportQuery | null> = new BehaviorSubject(
    null,
  );

  constructor(
    @Inject(GETS_APPS_META_DTO_PORT)
    private readonly _getAppsMetaDtoPort: GetsAppsMetaDtoPort,
    @Inject(GETS_INSTALLED_APPS_IDS_DTO_PORT)
    private readonly _getInstalledAppsIdsDtoPort: GetsInstalledAppsIdsDtoPort,
    @Inject(GETS_GOOGLE_ADS_LINKING_STATUS_DTO_PORT)
    private readonly _getGoogleAdsLinkingStatusDtoPort: GetsGoogleAdsLinkingStatusDtoPort,
    @Inject(LINKS_GOOGLE_ADS_ACCOUNT_DTO_PORT)
    private readonly _linkGoogleAdsAccountDtoPort: LinksGoogleAdsAccountDtoPort,
    @Inject(SETS_LABEL_MANAGER_CONFIG_DTO_PORT)
    private readonly _setsLabelManagerConfigDtoPort: SetsLabelManagerConfigDtoPort,
    @Inject(GETS_LABEL_MANAGER_CONFIG_DTO_PORT)
    private readonly _getsLabelManagerConfigDtoPort: GetsLabelManagerConfigDtoPort,
    @Inject(GETS_LABEL_MANAGER_REPORT_DTO_PORT)
    private readonly _getsLabelManagerReportDtoPort: GetsLabelManagerReportDtoPort,
    @Inject(GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT)
    private readonly _getsClientSubsciptionsDtoPort: GetsClientSubsciptionsDtoPort,
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
    private readonly _alert: HuiAlert,
    private readonly _matDialog: MatDialog,
    @Inject(WORKSPACE_ID_GETTER) private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(GETS_LABEL_MANAGER_LATEST_CONFIG_DTO_PORT)
    private readonly _getsLabelManagerLatestConfigDtoPort: GetsLabelManagerLatestConfigDtoPort,
    @Inject(INITS_LM_SIMULATION_DTO)
    private readonly _initsLMSimulation: InitsLMSimulationDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {
    this._applicationBus
      .on(IntegrationIdChangedEvent)
      .pipe(
        tap((event: IntegrationIdChangedEvent) =>
          this._setIntegration(IntegrationInfoQuery.fromDTO(event.integration)),
        ),
      )
      .subscribe();
  }

  getSegments(): Observable<LabelManagerReportSegmentData> {
    return this._currentLMResult$.asObservable().pipe(
      filter(Boolean),
      map(res => res.segments),
    );
  }

  setCurrentLMReport(value: LabelManagerReportDateData): void {
    this._currentLMResult$.next(value);
  }

  saveConfig(): Observable<boolean> {
    return this._getsLabelManagerConfigDtoPort
      .getConfig({ integrationId: this._integrationId$.getValue() })
      .pipe(
        map((res: LabelManagerConfigDto): boolean => {
          this._configAttributes$.next(res);
          this._applicationBus.dispatch(new LabelManagerSetConfigEvent(res));
          return true;
        }),
        catchError(() =>
          this._getsLabelManagerLatestConfigDtoPort
            .getLatestConfig({ siteId: this._getSiteId() })
            .pipe(
              switchMap(res => {
                this._configAttributes$.next(res);

                this._applicationBus.dispatch(new LabelManagerSetConfigEvent(res));
                return this.setConfig({
                  breakEvenROAS: res.breakEvenROAS,
                  lookBackWindow: res.lookBackWindow,
                  customLabel: res.customLabel,
                  analysisFrequency: res.analysisFrequency,
                  initialConfiguration: false,
                });
              }),
              catchError(() => {
                this._isInitialConfiguration$.next(true);
                const initialConfig = LABEL_MANAGER_INITIAL_CONFIG_QUERY_STUB;
                return this.setConfig({
                  breakEvenROAS: initialConfig.breakEvenROAS,
                  lookBackWindow: initialConfig.lookBackWindow,
                  customLabel: initialConfig.customLabel.value,
                  analysisFrequency: initialConfig.analysisFrequency,
                  initialConfiguration: true,
                });
              }),
            ),
        ),
        switchMap(() => this.loadReport(false).pipe(map(() => true))),
      );
  }

  getErrorMessage(): Observable<string> {
    return this._errorMessage$.asObservable();
  }

  cleanLMReportDate(): void {
    this._storage.removeItem('lm-report-timer');
    this._currentLMResult$.next(null);
  }

  getMessage(): Observable<string> {
    return this._loadingMessage$.asObservable();
  }

  isLoading(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  getReportLength(): Observable<number> {
    return this._integrationId$.asObservable().pipe(
      filter(query => !!query),
      take(1),
      switchMap((integrationId: string) =>
        this._getsLabelManagerReportDtoPort.getReport({ integrationId: integrationId }).pipe(
          map(
            reportData =>
              LabelManagerReportQuery.fromDto({
                ...reportData,
                data: reportData.data.filter(
                  data => Date.now() - new Date(data.date).getTime() <= 259200000,
                ),
              }).data.length,
          ),
        ),
      ),
    );
  }

  showsSimulationOption(query: ShowsSimulationOptionQuery): Observable<boolean> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return combineLatest([
      this._getsClientSubsciptionsDtoPort.getSubscriptions({
        workspaceId: workspaceId,
        clientId: query.clientId,
      }),
      this._errors$.asObservable().pipe(
        map(res => {
          const errors = res && res.title === 'INTEGRATION_IN_TEMPORARY_STATE' ? null : res;
          return !!errors;
        }),
      ),
    ]).pipe(
      map(
        ([subscriptions, isErrors]: [ClientSubscriptionDto[], boolean]) =>
          !subscriptions.some(subscription => subscription.productName === 'label-manager') &&
          !isErrors,
      ),
    );
  }

  createLMSimulation(clientId: string): Observable<boolean> {
    const siteId = this._getSiteId();
    const errors = this._errors$.getValue();
    const integrationStatus = this._integrationStatus$.getValue();
    if (integrationStatus === 'pending' && errors.title === 'INTEGRATION_IN_TEMPORARY_STATE') {
      return this.openNonCssDialog(true, clientId).pipe(map(() => true));
    }

    return this._getGoogleAdsLinkingStatusDtoPort.getStatus({ siteId: siteId }).pipe(
      map(res => {
        this._isAccountLinked$.next(res);
        return res;
      }),
      switchMap(res =>
        res
          ? of(void 0)
          : this._matDialog
              .open(SetGoogleAdsLinkingComponent, {
                data: { isSingleComponent: true },
                panelClass: ['cs-mat-dialog', 'cs-mat-dialog-no-box'],
                width: '650px',
                scrollStrategy: new NoopScrollStrategy(),
                autoFocus: false,
                disableClose: false,
              })
              .afterClosed(),
      ),
      switchMap(() => {
        if (!this._isAccountLinked$.getValue()) {
          return of(false);
        }
        this._lmSimulationTime$.next(new Date().setSeconds(0, 0));
        this._applicationBus.dispatch(
          new LabelManagerSimulationRequestEvent(siteId, this._integrationId$.getValue()),
        );
        return this._initsLMSimulation
          .initSimulation({
            integrationId: this._integrationId$.getValue(),
          })
          .pipe(
            map(() => true),
            tap({
              next: () => {},
              error: err => {
                this._alert.open('error', '_something_went_wrong');
                this._lmSimulationTime$.next(null);
                return throwError(() => err);
              },
            }),
          );
      }),
    );
  }

  startIntegration(command: StartsIntegrationProcessCommand): Observable<boolean> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._getsClientSubsciptionsDtoPort
      .getSubscriptions({ workspaceId: workspaceId, clientId: command.clientId })
      .pipe(
        map(subscriptions =>
          subscriptions.some(subscription => subscription.productName === 'label-manager'),
        ),
        tap((res: boolean) => {
          if (!res) {
            this._applicationBus.dispatch(
              new PaymentFlowStartedEvent(command.clientId, 'label-manager'),
            );
            this._router.navigate([
              '/',
              'connect',
              this._teamIdGetter.getTeamId(),
              'clients',
              'setup',
            ]);
          } else {
            this._showStartPage$.next(false);
          }
        }),
      );
  }

  private _setIntegration(integration: IntegrationInfoQuery): void {
    console.warn(integration);
    this._integrationId$.next(integration.id);
    this._integrationType$.next(integration.type);
    this._integrationStatus$.next(integration.status);
    this._errors$.next(integration.errors);
    this._configAttributes$.next(null);
    this._applicationBus.dispatch(new LabelManagerSetConfigEvent(null));
  }

  setIntegration(integration: IntegrationInfoDto): Observable<boolean> {
    this._applicationBus.dispatch(new IntegrationIdChangedEvent(integration));
    this._currentLMReport$.next(null);
    this._applicationBus.dispatch(new LabelManagerSetReportEvent(null));

    this._showStartPage$.next(true);
    return of(true);
  }

  getIntegrationType(): Observable<string> {
    return this._integrationType$.asObservable();
  }

  getIntegrationId(): Observable<string> {
    return this._integrationId$.asObservable();
  }

  loadReport(isSimulation: boolean): Observable<LabelManagerReportQuery> {
    if (!this._integrationId$.getValue()) {
      return of(null);
    }
    this._isLoading$.next(true);
    this._storage.setItem('lm-report-timer', Date.now().toString());
    return this._integrationId$.asObservable().pipe(
      filter(query => !!query),
      take(1),
      switchMap((integrationId: string) =>
        this._getsLabelManagerReportDtoPort.getReport({ integrationId: integrationId }).pipe(
          repeat({ delay: 15000 }),
          skipWhile((data: LabelManagerReportDto) => {
            this._checkLoaderMessage();
            this._errorMessage$.next(data.error ? LM_INTEGRATION_ERROR_MAP[data.error.code] : null);
            return this.isCurrentReport(data) && !data.error;
          }),
          take(1),
        ),
      ),
      map((reportData: LabelManagerReportDto) => {
        this._isLoading$.next(false);
        this._lmSimulationTime$.next(null);
        this._storage.removeItem('lm-report-timer');
        const report = LabelManagerReportQuery.fromDto(
          isSimulation
            ? {
                ...reportData,
                data: reportData.data.filter(
                  data => Date.now() - new Date(data.date).getTime() <= 259200000,
                ),
              }
            : reportData,
        );
        this._currentLMReport$.next(report);
        this._applicationBus.dispatch(new LabelManagerSetReportEvent(report));
        return report;
      }),
    );
  }

  getReport(): Observable<LabelManagerReportQuery> {
    return this._currentLMReport$.asObservable();
  }

  private isCurrentReport(reportData: LabelManagerReportDto): boolean {
    const lmSimulationTime = this._lmSimulationTime$.getValue();
    const offsetMinutes = new Date().getTimezoneOffset();
    if (lmSimulationTime) {
      const maxDate = Math.max(
        ...reportData.data.map(data => (data.date ? new Date(data.date).getTime() : null)),
      );
      return reportData.data.length === 0
        ? reportData.status !== 'completed'
        : maxDate - offsetMinutes * 60 * 1000 < lmSimulationTime ||
            reportData.status !== 'completed';
    }
    return reportData.status === 'pending';
  }

  showStartPage(): Observable<boolean> {
    return this._showStartPage$.asObservable();
  }

  showStepper(clientId: string): Observable<void> {
    const siteId = this._getSiteId();
    const errors = this._errors$.getValue();
    const integrationStatus = this._integrationStatus$.getValue();
    const integrationId = this._integrationId$.getValue();
    let isError =
      errors !== null || (integrationStatus !== 'success' && integrationStatus !== 'missing');
    if (integrationStatus === 'pending' && errors.title === 'INTEGRATION_IN_TEMPORARY_STATE') {
      isError = false;
    }
    return isError
      ? this._openErrorsDialog()
      : integrationId === null ||
        (integrationStatus === 'pending' && errors.title === 'INTEGRATION_IN_TEMPORARY_STATE')
      ? this.openNonCssDialog(false, clientId).pipe(map(() => void 0))
      : this._openConfigurationDialog(siteId);
  }

  private _checkLoaderMessage() {
    const lmReportTimer = this._storage.getItem('lm-report-timer');
    const dateNow = Date.now();
    if (lmReportTimer) {
      const timeDifference = dateNow - parseInt(lmReportTimer);
      if (timeDifference <= 180000) {
        this._loadingMessage$.next('cobiro_pro_label_manager_loading_message_1');
      }
      if (timeDifference >= 180000 && timeDifference < 300000) {
        this._loadingMessage$.next('cobiro_pro_label_manager_loading_message_2');
      }
      if (timeDifference >= 300000) {
        this._loadingMessage$.next('cobiro_pro_label_manager_loading_message_3');
      }
    }
  }

  openNonCssDialog(isSimulation: boolean, clientId: string): Observable<boolean> {
    const dialog = this._matDialog.open(LabelManagerNonCssIntegrationComponent, {
      panelClass: 'cs-mat-dialog',
      width: '730px',
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      disableClose: false,
      data: { clientId: clientId },
    });
    return combineLatest([
      dialog.backdropClick().pipe(tap(() => this._showStartPage$.next(true))),
      dialog.afterClosed().pipe(switchMap(() => (isSimulation ? of(void 0) : this.saveConfig()))),
    ]).pipe(map(() => true));
  }

  private _openErrorsDialog(): Observable<void> {
    return this._matDialog
      .open(LabelManagerNoIntegrationComponent, {
        panelClass: 'cs-mat-dialog',
        width: '650px',
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        disableClose: false,
      })
      .backdropClick()
      .pipe(
        tap(() => this._showStartPage$.next(true)),
        map(() => void 0),
      );
  }

  private _openConfigurationDialog(siteId: string): Observable<void> {
    return this._getGoogleAdsLinkingStatusDtoPort.getStatus({ siteId: siteId }).pipe(
      tap((res: boolean) => this._isAccountLinked$.next(res)),
      switchMap((res: boolean) =>
        res
          ? this.saveConfig()
          : this._matDialog
              .open(SetGoogleAdsLinkingComponent, {
                data: { isSingleComponent: false },
                panelClass: ['cs-mat-dialog', 'cs-mat-dialog-no-box'],
                width: '650px',
                scrollStrategy: new NoopScrollStrategy(),
                autoFocus: false,
                disableClose: false,
              })
              .backdropClick()
              .pipe(
                map(() => void 0),
                tap(() => this._showStartPage$.next(true)),
              ),
      ),
    );
  }

  getConfig(): Observable<LabelManagerConfigQuery> {
    return this._configAttributes$.asObservable().pipe(
      filter(query => !!query),
      map((dto: LabelManagerConfigDto) => LabelManagerConfigQuery.fromDto(dto)),
    );
  }

  setConfig({
    breakEvenROAS,
    lookBackWindow,
    customLabel,
    analysisFrequency,
    initialConfiguration,
  }: SetsLabelManagerConfigCommand): Observable<boolean> {
    return this._integrationId$.asObservable().pipe(
      switchMap((integrationId: string) => {
        console.warn(integrationId);
        return this._setsLabelManagerConfigDtoPort
          .setConfig({
            integrationId: integrationId,
            config: {
              breakEvenROAS,
              lookBackWindow,
              customLabel: customLabel as any,
              analysisFrequency,
              initialConfiguration,
            },
          })
          .pipe(
            switchMap(() =>
              initialConfiguration
                ? timer(1000).pipe(
                    switchMap(() =>
                      this._getsLabelManagerConfigDtoPort.getConfig({
                        integrationId: integrationId,
                      }),
                    ),
                  )
                : of({
                    id: integrationId,
                    breakEvenROAS: breakEvenROAS,
                    lookBackWindow: lookBackWindow,
                    customLabel: customLabel as any,
                    analysisFrequency: analysisFrequency,
                  }),
            ),
            map((dto: LabelManagerConfigDto) => {
              this._configAttributes$.next(dto);

              this._applicationBus.dispatch(new LabelManagerSetConfigEvent(dto));
              this._alert.open(
                'success',
                'cobiro_pro_label_manager_config_window_save_settings_success',
              );
              return true;
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 429) {
                this._alert.open(
                  'error',
                  'cobiro_pro_label_manager_config_window_save_settings_error_too_many_requests',
                );
              } else {
                this._alert.open(
                  'error',
                  'cobiro_pro_label_manager_config_window_save_settings_error',
                );
              }
              return of(false);
            }),
          );
      }),
    );
  }

  linkGoogleAdsAccount(): Observable<boolean> {
    const siteId = this._getSiteId();
    return this._linkGoogleAdsAccountDtoPort.linkGoogleAdsAccount({ siteId: siteId }).pipe(
      tap(() => {
        this._isAccountLinked$.next(true);
        this._alert.open('success', 'cbr_pro_members_invitation_sent');
      }),
    );
  }

  getStatus(): Observable<boolean> {
    return this._isAccountLinked$.asObservable();
  }

  getInstalledApps(): Observable<string[]> {
    return this._getInstalledAppsIdsDtoPort
      .getAll(this._getSiteId())
      .pipe(tap((apps: string[]) => this._showStartPage$.next(!apps.includes('label_manager'))));
  }

  getAppMeta(): Observable<AppMetadataQuery> {
    return this._getAppsMetaDtoPort
      .getAppsMeta()
      .pipe(
        map((apps: AppMetadataDto[]) =>
          AppMetadataQuery.fromDto(apps.find(app => app.identifier === 'label-manager')),
        ),
      );
  }

  private _getSiteId(): string {
    return String(this._storage.getItem('cobiro-pro-current-client'));
  }
}

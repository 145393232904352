function createFeaturesIconPath(name: string) {
  return `/assets/svg-icons/features/icon-feature-${name}.svg`;
}

export const FEATURES_ICONS_MAP = new Map([
  ['feature-create-store', createFeaturesIconPath('create-store')],
  ['feature-fast', createFeaturesIconPath('fast')],
  ['feature-free', createFeaturesIconPath('free')],
  ['feature-gauge', createFeaturesIconPath('gauge')],
  ['feature-gift', createFeaturesIconPath('gift')], //
  ['feature-google-logo', createFeaturesIconPath('google-logo')],
  ['feature-growth-essentials', createFeaturesIconPath('growth-essentials')],
  ['feature-jamstack', createFeaturesIconPath('jamstack')],
  ['feature-marketing-essentials', createFeaturesIconPath('marketing-essentials')],
  ['feature-secure', createFeaturesIconPath('secure')],
  ['feature-star-essentials', createFeaturesIconPath('star-essentials')],
  ['feature-cas', createFeaturesIconPath('star-essentials')],
  ['feature-cas', createFeaturesIconPath('cas')],
  ['feature-google-analytics', createFeaturesIconPath('google-analytics')],
  [
    'feature-facebook-logo-black-and-white',
    createFeaturesIconPath('facebook-logo-black-and-white'),
  ],
  [
    'feature-facebook-logo-rounded-transparent',
    createFeaturesIconPath('facebook-logo-rounded-transparent'),
  ],
  [
    'feature-facebook-logo-rounded-white-letter',
    createFeaturesIconPath('facebook-logo-rounded-white-letter'),
  ],
  ['feature-google-logo-transparent', createFeaturesIconPath('google-logo-transparent')],
  ['feature-prestashop-logo', createFeaturesIconPath('prestashop-logo')],
  ['feature-shopify-logo', createFeaturesIconPath('shopify-logo')],
  ['feature-woo-commerce-logo', createFeaturesIconPath('woo-commerce-logo')],
  ['feature-twitter-logo', createFeaturesIconPath('twitter-logo')],
  ['feature-magento-logo', createFeaturesIconPath('magento-logo')],
  ['feature-ecommerce-cart', createFeaturesIconPath('ecommerce-cart')],
  ['feature-google-css-with-tag', createFeaturesIconPath('google-css-with-tag')],
  ['feature-linkedin-logo-transparent', createFeaturesIconPath('linkedin-logo-transparent')],
  ['feature-domains', createFeaturesIconPath('domains')],
  ['feature-paypal-bg-grey', createFeaturesIconPath('paypal-bg-grey')],
  ['feature-amazon-logo', createFeaturesIconPath('amazon-logo')],
]);

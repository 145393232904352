import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SwitchCssDomainDto } from './switches-css-domain.dto';

export const SWITCHES_CSS_DOMAIN_DTO = new InjectionToken<SwitchCssDomainDtoPort>(
  'SWITCHES_CSS_DOMAIN_DTO',
);

export interface SwitchCssDomainDtoPort {
  switchCssDomain(dto: SwitchCssDomainDto): Observable<void>;
}

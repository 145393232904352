import { InjectionToken } from '@angular/core';
import { EditClientCommand } from './edit-client.command';

export const EDITS_CLIENT_COMMAND = new InjectionToken<EditsClientCommandPort>(
  'EDITS_CLIENT_COMMAND',
);

export interface EditsClientCommandPort {
  editClient(command: EditClientCommand): void;
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-cobiro-pro-success-non-css-linking',
  templateUrl: './success-non-css-linking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessNonCssLinkingComponent implements OnInit {
  ngOnInit(): void {
    const event = new CustomEvent('linkingFinished', { detail: 'loaded' });
    window.parent.document.dispatchEvent(event);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CssMerchantQuery } from './css-merchant.query';

export interface GetsCssMerchantIdsQueryPort {
  getCssMerchantIds(): Observable<CssMerchantQuery[]>;
}

export const GETS_CSS_MERCHANT_IDS_QUERY_PORT = new InjectionToken<GetsCssMerchantIdsQueryPort>(
  'GETS_CSS_MERCHANT_IDS_QUERY_PORT',
);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface AddPaymentMethodCommand {
  readonly cardOwner: string;
  readonly cvv: string;
  readonly number: string;
  readonly expiryYear: string;
  readonly expiryMonth: string;
}

export interface AddsPaymentMethodCommandPort {
  addPaymentMethod(command: AddPaymentMethodCommand): Observable<boolean>;
}

export const ADDS_PAYMENT_METHOD_COMMAND = new InjectionToken<AddsPaymentMethodCommandPort>(
  'ADD_PAYMENT_METHOD_COMMAND',
);

import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HasDataCollection } from '@cobiro/jsonapi';
import { GetsAllTeamsDtoPort } from '../../../../application/ports/secondary/gets-all-team-dto.port';
import { TeamDTO, USER_ROLE } from '../../../../application/ports/secondary/team.dto';

interface TeamAttributes {
  name: string;
  role: string;
  avatar: string;
  counter: number;
  details: {
    avatar: string;
  };
}

@Injectable()
export class HttpTeamsService implements GetsAllTeamsDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  getsAll(criterion: Partial<TeamDTO> = {}): Observable<TeamDTO[]> {
    return this._client.get<HasDataCollection<TeamAttributes>>('v1/sites/teams').pipe(
      map(dataCollection => {
        const userTeamsIds: string[] = [];
        const userTeams = dataCollection.data.map(data => {
          userTeamsIds.push(data.id);

          return {
            id: data.id,
            name: data.attributes.name,
            role: data.attributes.role as USER_ROLE,
            avatar: data.attributes.details.avatar,
            membersCount: data.attributes.counter,
          };
        });

        return [...new Set(userTeamsIds)]
          .map(userTeamId => userTeams.find(team => team.id === userTeamId))
          .filter(Boolean);
      }),
    );
  }
}

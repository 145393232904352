import { NgModule } from '@angular/core';
import { ClientsListChangedEventDispatcher } from '../../../adapters/secondary/dispatchers/clients-list-changed.event-dispatcher';
import { GETS_LATEST_CLIENTS_LIST_PAGINATION_QUERY } from '../../ports/primary/clients/gets-latest-clients-list-pagination.query-port';
import { GETS_LATEST_CLIENTS_LIST_SELECTION_QUERY } from '../../ports/primary/clients/gets-latest-clients-list-selection.query-port';
import { GETS_LATEST_CLIENTS_QUERY } from '../../ports/primary/clients/gets-latest-clients.query-port';
import { IS_ALL_SELECTED_LATEST_CLIENTS_COMMAND } from '../../ports/primary/clients/is-all-selected-latest-clients.command-port';
import { LOAD_LATEST_CLIENTS_COMMAND } from '../../ports/primary/clients/load-latest-clients.command-port';
import { SETS_LATEST_CLIENTS_LIST_SORT_COMMAND } from '../../ports/primary/clients/sets-latest-clients-list-sort.command-port';
import { TOGGLE_ALL_LATEST_CLIENTS_COMMAND } from '../../ports/primary/clients/toggle-all-latest-clients.command-port';
import { CLIENTS_LIST_CHANGED_DISPATCHER } from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { LatestClientsListState } from './latest-clients-list.state';

@NgModule({
  providers: [
    LatestClientsListState,
    { provide: LOAD_LATEST_CLIENTS_COMMAND, useExisting: LatestClientsListState },
    { provide: GETS_LATEST_CLIENTS_QUERY, useExisting: LatestClientsListState },
    { provide: GETS_LATEST_CLIENTS_LIST_PAGINATION_QUERY, useExisting: LatestClientsListState },
    { provide: GETS_LATEST_CLIENTS_LIST_SELECTION_QUERY, useExisting: LatestClientsListState },
    { provide: IS_ALL_SELECTED_LATEST_CLIENTS_COMMAND, useExisting: LatestClientsListState },
    { provide: TOGGLE_ALL_LATEST_CLIENTS_COMMAND, useExisting: LatestClientsListState },
    { provide: SETS_LATEST_CLIENTS_LIST_SORT_COMMAND, useExisting: LatestClientsListState },
    { provide: CLIENTS_LIST_CHANGED_DISPATCHER, useClass: ClientsListChangedEventDispatcher },
  ],
})
export class LatestClientsListStateModule {}

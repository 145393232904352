import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';
import {
  SetsSelectedWorkspaceCommandPort,
  SETS_SELECTED_WORKSPACE_COMMAND,
} from '@app.cobiro.com/cobiro-pro/context';
import { take } from 'rxjs';

@Injectable()
export class CobiroProWorkspaceSwitchedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProWorkspaceSwitchedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_SELECTED_WORKSPACE_COMMAND)
    private readonly _setsSelectedWorkspaceCommandPort: SetsSelectedWorkspaceCommandPort,
  ) {}

  handle([event]: [CobiroProWorkspaceSwitchedEvent]): void {
    this._setsSelectedWorkspaceCommandPort
      .setSelectedWorkspace(
        event.workspaceId,
        event.workspaceName,
        event.avatar,
        event.membersCount,
      )
      .pipe(take(1))
      .subscribe();
  }
}

<ng-container *huiLoaderContainer="apps$ | async as installedApps">
  <lib-cobiro-pro-cobiro-css-start-page
    (startIntegration)="startIntegration()"
    *ngIf="showStartPage$ | async; else cobiroIntegration"
    class="w-100"
  ></lib-cobiro-pro-cobiro-css-start-page>

  <ng-template #cobiroIntegration>
    <lib-cobiro-css-integration class="w-100 d-block"></lib-cobiro-css-integration>
  </ng-template>
</ng-container>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserVerifiedEvent } from '@app.cobiro.com/user/register';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

/* tslint:disable:no-console */
@Injectable()
export class UserVerifiedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserVerifiedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router) {}

  handle([event]: [UserVerifiedEvent]): void {
    this._router.navigate(['auth', 'register', 'create-profile']);
  }
}

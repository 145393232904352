import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { HuiAlertModule } from '@app.cobiro.com/shared/hui/alert';
import { ApplicationBusModule, provideApplicationEventsHandler } from '@cobiro/eda';
import { UserImpersonateLogoutEventHandler } from './adapters/user-impersonate-logout.event-handler';
import { UserImpersonationState } from './application/user-impersonation.state';
import { ImpersonatesUserGuard } from './ui/guard/impersonates-user.guard';
import { ImpersonationWidgetComponent } from './ui/impersonation-widget/impersonation-widget.component';

@NgModule({
  imports: [ApplicationBusModule, CommonModule, HuiAlertModule],
  providers: [
    ImpersonatesUserGuard,
    UserImpersonationState,
    { provide: STORAGE, useValue: localStorage },
  ],
  declarations: [ImpersonationWidgetComponent],
  exports: [ImpersonationWidgetComponent],
})
export class UserImpersonationModule {
  static forRoot(): ModuleWithProviders<UserImpersonationModule> {
    return {
      ngModule: UserImpersonationModule,
      providers: [provideApplicationEventsHandler(UserImpersonateLogoutEventHandler)],
    };
  }
}

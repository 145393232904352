import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientListItemQuery } from './client-list-item.query';

export const GETS_ALL_CLIENT_QUERY = new InjectionToken<GetsAllClientQueryPort>(
  'GETS_ALL_CLIENT_QUERY',
);

export interface GetsAllClientQueryPort {
  getAllClientQuery(): Observable<ClientListItemQuery[]>;
}

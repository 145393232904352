<ng-container *huiLoaderContainer="users$ | async as users">
  <div class="d-flex cs-position-relative-xs table-responsive-container">
    <table
      mat-table
      [dataSource]="users"
      class="cs-mat-table highlight-row cs-min-width-100-xs cs-position-relative-xs"
      data-selector="cobiro-pro-users-table"
    >
      <ng-container matColumnDef="cobiro_pro_users_table_user_name" sticky>
        <th mat-header-cell *matHeaderCellDef>
          {{ 'cobiro_pro_user' | language }}
        </th>
        <td mat-cell *matCellDef="let user" data-selector="cobiro-pro-users-table-user-name">
          <span class="cs-title-7" *ngIf="user.registered; else resendValidation">
            {{ user.name }}</span
          >
          <ng-template #resendValidation>
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <span class="cs-text-2 cs-cursor-pointer" data-selector="cobiro-pro-user-name">
                  {{ 'cobiro_pro_invitation_pending' | language }}
                </span>
                <span
                  class="cs-text-2 cs-cursor-pointer break-word"
                  data-selector="cobiro-pro-user-email"
                >
                  <a (click)="onResendInvitationClicked(user)" class="cs-link">{{
                    'cobiro_pro_resend_invitation' | language
                  }}</a>
                </span>
              </div>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_users_table_email">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'cobiro_pro_email' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let user"
          class="pr-0"
          data-selector="cobiro_pro_users_table_email"
        >
          {{ user.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_users_table_role">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          {{ 'cobiro_pro_role' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let user"
          class="text-center"
          data-selector="cobiro-pro-users-table-role"
        >
          <span class="cs-badge br-15 cs-white-space-nowrap" [ngClass]="user.role.colorClass">{{
            user.role.label | language
          }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_users_table_workspaces">
        <th mat-header-cell *matHeaderCellDef class="pr-2">
          {{ 'cobiro_pro_workspaces' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let user"
          class="pr-0"
          data-selector="cobiro-pro-users-table-workspaces"
        >
          {{
            user.workspaces.length +
              ' ' +
              ((user.workspaces.length === 1 ? '_workspace' : '_workspaces') | language)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_users_table_access">
        <th mat-header-cell *matHeaderCellDef class="cs-width-120-xs text-center">
          {{ 'cobiro_pro_users_column_access' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let user"
          class="cs-width-120-xs px-0"
          data-selector="cobiro-pro-users-table-edit-access"
        >
          <div class="d-flex justify-content-center align-items-center">
            <button
              (click)="onEditButtonClicked(user)"
              class="cs-btn cs-btn-sm cs-btn-stroked"
              type="button"
            >
              {{ 'cobiro_pro_users_access_button' | language }}
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="cobiro_pro_users_table_delete">
        <th mat-header-cell *matHeaderCellDef class="cs-width-120-xs text-center">
          {{ 'cobiro_pro_users_column_delete' | language }}
        </th>
        <td
          mat-cell
          *matCellDef="let user"
          class="cs-width-120-xs px-0"
          data-selector="cobiro-pro-users-table-delete"
        >
          <div class="d-flex justify-content-center align-items-center">
            <button
              (click)="onDeleteButtonClicked(user)"
              class="cs-btn cs-btn-sm cs-btn-error"
              type="button"
            >
              {{ 'cobiro_pro_users_column_delete' | language }}
              <mat-icon svgIcon="trash"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row class="cs-bb-1 cs-b-shade-10" *matRowDef="let row; columns: columns"></tr>
    </table>
  </div>
</ng-container>

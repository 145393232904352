import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserState } from '../application/user.state';
import { map, take } from 'rxjs/operators';

@Injectable()
export class TokenLoginGuard {
  constructor(private router: Router, private userState: UserState) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    // No token and no redirect uri
    if (!next.queryParams?.token && !next.queryParams?.redirectUri) {
      return of(true);
    }
    // No token and redirect uri
    if (!next.queryParams?.token && next.queryParams?.redirectUri) {
      return of(this.router.createUrlTree([next.queryParams.redirectUri]));
    }

    return this.userState
      .tokenLogin(next.queryParams.token, next.queryParams.refreshToken || null)
      .pipe(
        take(1),
        map(result => {
          return result
            ? this.router.createUrlTree([next.queryParams?.redirectUri || '/onboarding/site'])
            : true;
        }),
      );
  }
}

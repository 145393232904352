import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetsNotifications } from '../domain/gets-notifications';
import { FORM_TYPES, MessageNotification } from '../domain/messages/message-notification';
import { Notification } from '../domain/notification';

export interface RegistriesResponseAttributes {
  formType: string;
}

export interface MessageResponseAttributes {
  values: { [key: string]: string };
  labels: { [key: string]: string };
  formType: string;
  formId: string;
  createdAt: number;
  read: boolean;
}

@Injectable()
export class HttpGetsNotificationsService implements GetsNotifications {
  constructor(private readonly _gatewayClient: GatewayClient) {}

  getNotifications(): Observable<Notification[]> {
    return this._gatewayClient
      .get<HasDataCollection<RegistriesResponseAttributes>>(`v1/app-registry/forms`)
      .pipe(
        switchMap(response => {
          const contactFormIds = response.data
            .filter(form => form.attributes.formType === 'contact_form')
            .map(form => form.id);
          const advancedFormIds = response.data
            .filter(form => form.attributes.formType === 'advanced_form')
            .map(form => form.id);
          return zip(
            contactFormIds?.length ? this._getMessagesForFormIds(contactFormIds, false) : of([]),
            advancedFormIds?.length ? this._getMessagesForFormIds(advancedFormIds, true) : of([]),
          );
        }),
        map(([contactFormMessages, advancedFormMessages]) =>
          [...contactFormMessages, ...advancedFormMessages].sort(
            (a: Notification, b: Notification) => b.date.getTime() - a.date.getTime(),
          ),
        ),
      );
  }

  private _getMessagesForFormIds(ids: string[], isAdvancedForm = true): Observable<Notification[]> {
    const params = new URLSearchParams();
    ids.forEach(id => params.append('filter[formId][]', id));
    return this._gatewayClient
      .get<HasDataCollection<MessageResponseAttributes>>(
        `v1/${isAdvancedForm ? 'advanced-forms' : 'contacts'}/form-messages`,
        { params: new HttpParams({ fromString: params.toString() }) },
      )
      .pipe(
        map(response => {
          return response.data.map(
            notificationDTO =>
              new MessageNotification(
                notificationDTO.id,
                new Date(notificationDTO.attributes.createdAt * 1000),
                notificationDTO.attributes.read,
                notificationDTO.attributes.formId,
                notificationDTO.attributes.formType === 'advanced_form'
                  ? FORM_TYPES.advancedForm
                  : FORM_TYPES.contactForm,
                this._mapMessageFields(notificationDTO.attributes),
              ),
          );
        }),
      );
  }

  private _mapMessageFields(
    attributes: MessageResponseAttributes,
  ): { name: string; value: string }[] {
    return Object.keys(attributes.labels).map(fieldKey => {
      return {
        name: attributes.labels[fieldKey],
        value: attributes.values[fieldKey],
      };
    });
  }
}

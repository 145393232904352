import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { isEmailValidator } from '@app.cobiro.com/shared/validators';
import { map, Observable, take } from 'rxjs';
import {
  ChecksUserInvitationCommandPort,
  CHECKS_USER_INVITATION_COMMAND,
} from '../../../../../application/ports/primary/checks-user-invitation.command-port';
import { ChecksUserInvitationCommand } from '../../../../../application/ports/primary/checks-user-invitation.command';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './invite-user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUserModalComponent implements OnDestroy {
  readonly userForm: UntypedFormGroup = this._fb.group({
    email: [
      '',
      [Validators.required, isEmailValidator],
      [this.isUserAlreadyInvited(this._checksUserInvitationCommand)],
    ],
  });

  constructor(
    @Inject(CHECKS_USER_INVITATION_COMMAND)
    private readonly _checksUserInvitationCommand: ChecksUserInvitationCommandPort,
    private readonly _fb: UntypedFormBuilder,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA) private readonly data: { route: ActivatedRoute },
  ) {}
  ngOnDestroy(): void {
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }

  isUserAlreadyInvited(
    checksUserInvitationCommand: ChecksUserInvitationCommandPort,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      checksUserInvitationCommand
        .checksUserInvitation(new ChecksUserInvitationCommand(control.value))
        .pipe(
          take(1),
          map((res: boolean) => (res ? { emailAlreadyExists: true } : null)),
        );
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentPlanDto } from './payment-plan.dto';

export interface GetsPaymentPlansDtoPort {
  getPaymentPlans(): Observable<PaymentPlanDto[]>;
}

export const GETS_PAYMENT_PLANS_DTO = new InjectionToken<GetsPaymentPlansDtoPort>(
  'GETS_PAYMENT_PLANS_DTO',
);

import { NgModule } from '@angular/core';
import { LocalstorageContextService } from './localstorage-context.service';
import { SAVES_COBIRO_PRO_CONTEXT_DTO } from '../../../application/ports/secondary/saves-cobiro-pro-context.dto-port';
import { GETS_COBIRO_PRO_CONTEXT_DTO } from '../../../application/ports/secondary/gets-cobiro-pro-context.dto-port';

@NgModule({
  providers: [
    LocalstorageContextService,
    { provide: SAVES_COBIRO_PRO_CONTEXT_DTO, useExisting: LocalstorageContextService },
    { provide: GETS_COBIRO_PRO_CONTEXT_DTO, useExisting: LocalstorageContextService },
  ],
})
export class LocalstorageContextServiceModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_CURRENT_PRODUCT_QUERY = new InjectionToken<GetsCurrentProductQueryPort>(
  'GETS_CURRENT_PRODUCT_QUERY',
);

export interface GetsCurrentProductQueryPort {
  getCurrentProduct(): Observable<string>;
}

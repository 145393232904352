import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupFlowHeaderComponent } from './setup-flow-header.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SetupFlowHeaderComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, RouterModule],
  exports: [SetupFlowHeaderComponent],
})
export class SetupFlowHeaderComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsQuestionsDto } from './gets-questions.dto';
import { QuestionDto } from './question.dto';

export interface GetsQuestionsDtoPort {
  getQuestions(dto: GetsQuestionsDto): Observable<QuestionDto[]>;
}

export const GETS_QUESTIONS_DTO = new InjectionToken<GetsQuestionsDtoPort>('GETS_QUESTIONS_DTO');

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CompanyDetailsQuery } from './company-details.query';

export const SAVES_COMPANY_DETAILS_QUERY_PORT = new InjectionToken<SavesCompanyDetailsQueryPort>(
  'SAVES_COMPANY_DETAILS_QUERY_PORT',
);

export interface SavesCompanyDetailsQueryPort {
  savesCompanyDetail(dto: CompanyDetailsQuery): Observable<boolean>;
}

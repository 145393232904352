import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmailValidator, isPhoneNumberValidator } from '@app.cobiro.com/shared/validators';
import { ClientQuery } from '../../../../../application/ports/primary/clients/client.query';
import {
  GetsEditClientQueryPort,
  GETS_EDIT_CLIENT_QUERY,
} from '../../../../../application/ports/primary/clients/gets-edit-client-query.port';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Component({
  selector: 'lib-cobiro-pro-edit-client-modal',
  templateUrl: './edit-client-modal.component.html',
  styleUrls: ['./edit-client-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditClientModalComponent implements OnInit, OnDestroy {
  client$: Observable<ClientQuery> = this._getsEditClient$.getEditClientQuery();
  private readonly _imageUrl$ = new BehaviorSubject<string | null>(null);
  readonly imageUrl$: Observable<string> = this._imageUrl$.asObservable();
  readonly clientForm: UntypedFormGroup = this._fb.group({
    companyName: this._fb.control(null, [Validators.required]),
    contactPerson: this._fb.control(null),
    contactEmail: this._fb.control(null, [isEmailValidator]),
    contactPhone: this._fb.control(null, [isPhoneNumberValidator]),
    teamId: this._fb.control(null),
    avatar: this._fb.control(null),
  });

  constructor(
    @Inject(GETS_EDIT_CLIENT_QUERY) private readonly _getsEditClient$: GetsEditClientQueryPort,
    private readonly _fb: UntypedFormBuilder,
    private readonly _matDialogRef: MatDialogRef<EditClientModalComponent>,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA) private readonly data: { route: ActivatedRoute },
  ) {}

  ngOnInit(): void {
    this.client$.pipe(take(1)).subscribe(client => {
      const {
        companyName,
        contactPerson,
        contactEmail,
        contactPhone,
        id,
        workspaceId,
        teamId,
        avatar,
      } = client;
      this.clientForm.patchValue({
        companyName,
        contactPerson,
        contactEmail,
        contactPhone,
        id,
        workspaceId,
        teamId,
        avatar,
      });
      if (avatar) {
        this._imageUrl$.next(avatar);
      }
    });
  }

  onFormClosed(): void {
    this._matDialogRef.close();
  }

  onFormSubmitted(): void {
    const formValue = this.clientForm.getRawValue();
    this._matDialogRef.close(formValue);
  }

  onFileUploaded(imageUrl: string) {
    this._imageUrl$.next(imageUrl);
    this.clientForm.patchValue({ avatar: imageUrl });
  }

  ngOnDestroy(): void {
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  TeamIdGetter,
  TEAM_ID_GETTER,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';
import { FrontendPagination, PaginationQuery, SORT_ORDER } from '@app.cobiro.com/core/pagination';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { map, Observable, switchMap, tap } from 'rxjs';
import { GetsLatestUsersPaginationQueryPort } from '../ports/primary/gets-latest-users-pagination.query-port';
import { GetsLatestUsersQueryPort } from '../ports/primary/gets-latest-users.query-port';
import { LoadLatestUsersCommandPort } from '../ports/primary/load-latest-users.command-port';
import { UserQuery } from '../ports/primary/user.query';
import {
  SelectsUsersContextStoragePort,
  SELECTS_USERS_CONTEXT_STORAGE,
} from '../ports/secondary/context/selects-users-context.storage-port';
import {
  USERS_LIST_CHANGED_DISPATCHER,
  UsersListChangedDispatcherPort,
} from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import {
  GETS_ALL_WORKSPACE_USERS_DTO,
  GetsAllWorkspaceUsersDtoPort,
} from '../ports/secondary/dto/gets-all-workspace-users.dto-port';
import { UserDTO } from '../ports/secondary/dto/user.dto';

@Injectable()
export class LatestUsersState
  implements
    GetsLatestUsersQueryPort,
    GetsLatestUsersPaginationQueryPort,
    LoadLatestUsersCommandPort
{
  private readonly _pagination: FrontendPagination<UserDTO> = FrontendPagination.fromRaw(
    this._selectsUsersContextStorage.select().pipe(map(usersContext => usersContext.list)),
    0,
    5,
    ['createdAt', SORT_ORDER.DESC],
    {},
  );

  constructor(
    @Inject(GETS_ALL_WORKSPACE_USERS_DTO)
    private readonly _getAllWorkspaceUsersDTO: GetsAllWorkspaceUsersDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(SELECTS_USERS_CONTEXT_STORAGE)
    private readonly _selectsUsersContextStorage: SelectsUsersContextStoragePort,
    @Inject(USERS_LIST_CHANGED_DISPATCHER)
    private readonly _usersListChangedDispatcher: UsersListChangedDispatcherPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
  ) {
    this._applicationBus
      .on(CobiroProWorkspaceSwitchedEvent)
      .pipe(switchMap(() => this.loadUsers()))
      .subscribe();
  }

  getLatestUsersQuery(): Observable<UserQuery[]> {
    return this._pagination.getPaginatedList().pipe(
      map(users => {
        return this._mapUsersDtoToQuery(users);
      }),
    );
  }

  getUserListPagination(): Observable<PaginationQuery> {
    return this._pagination.getPaginationQuery();
  }

  loadUsers(): Observable<UserDTO[]> {
    const teamId = this._teamIdGetter.getTeamId();
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._getAllWorkspaceUsersDTO
      .getAllWorkspaceUsers({ teamId: teamId, workspaceId: workspaceId })
      .pipe(
        tap(users => {
          this._usersListChangedDispatcher.dispatch({ updatedUsers: users });
        }),
      );
  }

  private _mapUsersDtoToQuery(users: UserDTO[]): UserQuery[] {
    return users.map(userDto => UserQuery.fromUserDTO(userDto));
  }
}

import { InjectionToken } from '@angular/core';

// TODO (PRO-DEBT): Create command class
export interface RedirectsToAppCommand {
  redirectToApp(siteId: string, appId: string, sitePublicId: string, appInstalled: boolean): void;
}

export const REDIRECTS_TO_APP_COMMAND = new InjectionToken<RedirectsToAppCommand>(
  'REDIRECTS_TO_APP_COMMAND',
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedImageUploadModule } from '@app.cobiro.com/shared/image-upload';
import { EditClientModalComponent } from './edit-client-modal.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    SharedImageUploadModule,
  ],
  declarations: [EditClientModalComponent],
  exports: [EditClientModalComponent],
})
export class EditClientModalComponentModule {}

import { InjectionToken } from '@angular/core';
import { ClientDTO } from '../secondary/client.dto';

// TODO (PRO-DEBT): Create Command Class
export interface SetsClientsListFilterCommandPort {
  setFilter(key: keyof ClientDTO, value: any): void;
}

export const SETS_CLIENTS_LIST_FILTER_COMMAND =
  new InjectionToken<SetsClientsListFilterCommandPort>('SETS_CLIENTS_LIST_FILTER_COMMAND');

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from './user.dto';

export const DELETES_WORKSPACE_USER_DTO = new InjectionToken<DeletesWorkspaceUserDtoPort>(
  'DELETES_WORKSPACE_USER_DTO',
);

export interface DeletesWorkspaceUserDtoPort {
  deleteWorkspaceUser(dto: Pick<UserDTO, 'id' | 'teamId' | 'workspaces'>): Observable<void>;
}

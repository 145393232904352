import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { UserDeletionCanceledEvent, UserSignedOutEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  UserDeleteCannotRemoveProAccountError,
  UserDeleteUnknownError,
  UserDeleteWrongPasswordError,
} from '../ports/secondary/deletes-user-error.dto';
import { DomainError } from '@app.cobiro.com/core/state';
import { DeletesUserDtoPort, DELETES_USER_DTO } from '../ports/secondary/deletes-user.dto-port';
import { CancelUserDeletionQueryPort } from '../ports/primary/cancel-user-deletion.query-port';
import { DeletesUserQueryPort } from '../ports/primary/deletes-user.query-port';
import { DisablesEmailNotificationQueryPort } from '../ports/primary/disables-email-notification.query-port';

@Injectable()
export class UserDeletionState
  implements DeletesUserQueryPort, CancelUserDeletionQueryPort, DisablesEmailNotificationQueryPort
{
  private readonly _errorMessageKeys: Map<string, string> = new Map([
    [UserDeleteWrongPasswordError.code, 'user_delete_invalid_password_alert'],
    [
      UserDeleteCannotRemoveProAccountError.code,
      'user_delete_cobiro_pro_cannot_remove_their_account',
    ],
    [UserDeleteUnknownError.code, '_something_went_wrong'],
  ]);

  constructor(
    @Inject(DELETES_USER_DTO) private readonly _deletesUser: DeletesUserDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    private readonly _alert: HuiAlert,
  ) {}

  deleteUser(password: string): Observable<boolean> {
    return this._deletesUser.delete(password).pipe(
      tap({
        next: success => {
          if (success) {
            this._applicationBus.dispatch(new UserSignedOutEvent());
            this._alert.open('success', 'user_deletion_deleted');
          }
        },
        error: (error: DomainError) => {
          this._alert.open('error', this._errorMessageKeys.get(error.name));
        },
      }),
    );
  }

  cancelUserDeletion(): Observable<void> {
    return of(this._applicationBus.dispatch(new UserDeletionCanceledEvent()));
  }

  disableEmailNotifications(): Observable<void> {
    return this.cancelUserDeletion();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { IntegrationPausedComponent } from './integration-paused.component';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [IntegrationPausedComponent],
  exports: [IntegrationPausedComponent],
})
export class IntegrationPausedComponentModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserDetailQuery, UserDetailQueryPartial } from './user-details.query';

export const UPDATES_USER_DETAILS_QUERY = new InjectionToken<UpdatesUserDetailsQueryPort>(
  'UPDATES_USER_DETAILS_QUERY',
);

export interface UpdatesUserDetailsQueryPort {
  update(dto: UserDetailQueryPartial): Observable<UserDetailQuery>;
}

import { CobiroProTeamCreatedEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingMapper } from '../../../domain/data-layer-tracking-mapper';

export class DataLayerCobiroProTeamCreatedEventMapper implements DataLayerTrackingMapper {
  eventClass = CobiroProTeamCreatedEvent;
  mapEvent(event: CobiroProTeamCreatedEvent) {
    return {
      eventName: '/Cobiro Pro - Agency Created',
      eventData: {
        teamName: event.teamName,
      },
    };
  }
}

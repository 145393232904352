/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TeamSettingsQuery } from '../../../../../../../../application/ports/primary/settings/team-settings.query';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  zip,
} from 'rxjs';
import { CountryQuery } from '../../../../../../../../application/ports/primary/settings/country.query';
import {
  GetsTeamSettingsQueryPort,
  GETS_TEAM_SETTINGS_QUERY_PORT,
} from '../../../../../../../../application/ports/primary/settings/gets-team-settings.query-port';
import {
  GetsCountriesQueryPort,
  GETS_COUNTRIES_QUERY,
} from '../../../../../../../../application/ports/primary/settings/gets-countries.query-port';
import {
  CallPaymentBillingCommandPort,
  CALL_PAYMENT_BILLING_COMMAND,
} from '../../../../../../../../application/ports/primary/settings/calls-payment-billing-event.command-port';
import { isEmailValidator } from '@app.cobiro.com/shared/validators';
import {
  SetsSelectedCountryCommandPort,
  SETS_SELECTED_COUNTRY_COMMAND,
} from '../../../../../../../../application/ports/primary/settings/sets-selected-country.command-port';
import { SetsSelectedCountryCommand } from '../../../../../../../../application/ports/primary/settings/sets-selected-country.command';
import {
  GetsVatNumberValidationQueryPort,
  GETS_VAT_NUMBER_VALIDATION_QUERY,
} from '../../../../../../../../application/ports/primary/settings/gets-vat-number-validation.query-port';
import { VatNumberValidationQuery } from '../../../../../../../../application/ports/primary/settings/vat-number-validation.query';
import {
  GetsSaveTeamCommandErrorQueryPort,
  GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT,
} from '../../../../../../../../application/ports/primary/settings/gets-save-team-command-error.query-port';

const EMPTY_VAT_NUMBER = '---';

const CountrySelectionRequiredValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null =>
  control?.value !== '' && !instanceOfACountry(control?.value) ? { invalidCountry: true } : null;

function instanceOfACountry(country: any): country is CountryQuery {
  return !!country && typeof country !== 'string' && 'code' in country;
}

@Component({
  selector: 'lib-cobiro-pro-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['./billing-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingAddressFormComponent implements OnInit, OnDestroy {
  @Output() formGroupChange = new EventEmitter<TeamSettingsQuery>();
  @Output() validityChanged = new EventEmitter<boolean>();

  showSuccessMessage = false;
  private _ngDestroy$ = new Subject<void>();
  readonly teamSettings$: Observable<TeamSettingsQuery> =
    this._getsTeamSettingsQuery.getTeamSettings();

  readonly form = new FormGroup({
    agencyName: new FormControl<string>('', [Validators.required]),
    contactEmail: new FormControl<string>('', [Validators.required, isEmailValidator]),
    addressLine1: new FormControl<string>('', [Validators.required]),
    addressLine2: new FormControl<string>(''),
    zipCode: new FormControl<string>('', [Validators.required]),
    city: new FormControl<string>('', [Validators.required]),
    country: new UntypedFormControl(null, [Validators.required, CountrySelectionRequiredValidator]),
    vatNumberToBeProvided: new FormControl<boolean>(false),
    vatNumber: new FormControl<string>('', { updateOn: 'blur' }),
  });

  readonly countries$ = combineLatest([
    this._getsCountriesQuery.getCountriesQuery().pipe(
      filter(x => !!x),
      map(countries =>
        countries.map(({ name, code }) =>
          name == 'Greece' ? { name, code: 'EL' } : { name, code },
        ),
      ),
    ),
    this.form.get('country').valueChanges.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([countries, controlValue]: [CountryQuery[], string]) => {
      const controlValueString = controlValue?.toString() ?? '';
      return countries.filter(country =>
        country.name.toLowerCase().includes(controlValueString.toLowerCase()),
      );
    }),
    takeUntil(this._ngDestroy$),
  );

  constructor(
    @Inject(GETS_TEAM_SETTINGS_QUERY_PORT)
    private readonly _getsTeamSettingsQuery: GetsTeamSettingsQueryPort,
    @Inject(GETS_COUNTRIES_QUERY)
    private readonly _getsCountriesQuery: GetsCountriesQueryPort,
    @Inject(CALL_PAYMENT_BILLING_COMMAND)
    private readonly _callPaymentBillingCommand: CallPaymentBillingCommandPort,
    @Inject(SETS_SELECTED_COUNTRY_COMMAND)
    private readonly _setsSelectedCountryCommand: SetsSelectedCountryCommandPort,
    @Inject(GETS_VAT_NUMBER_VALIDATION_QUERY)
    private readonly _getsVatNumberValidationQuery: GetsVatNumberValidationQueryPort,
    @Inject(GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT)
    private readonly _getsSaveTeamCommandErrorQuery: GetsSaveTeamCommandErrorQueryPort,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.handleVatNumberChange();
    this.handleBuildForm();
    this.handleCountryChange();
    this.handleSaveError();

    this.form.valueChanges.subscribe(() => {
      const formValues = this.form.getRawValue();
      return this.formGroupChange.emit({
        agencyName: formValues.agencyName,
        contactEmail: formValues.contactEmail,
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2 || null,
        zipCode: formValues.zipCode,
        city: formValues.city,
        countryCode: formValues.country?.code === 'EL' ? 'GR' : formValues.country?.code,
        vatNumber: formValues.vatNumber === EMPTY_VAT_NUMBER ? null : formValues.vatNumber,
        avatar: null,
      } as TeamSettingsQuery);
    });
    this.form.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this._ngDestroy$))
      .subscribe(res => this.validityChanged.emit(res === 'VALID'));
  }

  private handleCountryChange(): void {
    this.form
      .get('country')
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter(Boolean),
        switchMap(controlValue =>
          this._setsSelectedCountryCommand.setSelectedCountry(
            new SetsSelectedCountryCommand(controlValue.code),
          ),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  private handleVatNumberChange(): void {
    this.form
      .get('vatNumberToBeProvided')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this._ngDestroy$))
      .subscribe((value: boolean) => {
        if (value) {
          this.form.get('vatNumber').clearValidators();
          this.form.get('vatNumber').clearAsyncValidators();
          this.form.get('vatNumber').setValue(EMPTY_VAT_NUMBER);
        } else {
          this.form.get('vatNumber').setValue('');
          this.form.get('vatNumber').setValidators([Validators.required]);
          this.form
            .get('vatNumber')
            .setAsyncValidators([this.vatValidator(this._getsVatNumberValidationQuery)]);
        }
        this.form.get('vatNumber').updateValueAndValidity();
      });
  }

  private handleBuildForm(): void {
    zip(
      this.teamSettings$.pipe(
        first(query => !!query),
        tap(() => this._callPaymentBillingCommand.dispatchEvent().pipe(take(1))),
        map(query => (query.countryCode === 'GR' ? { ...query, countryCode: 'EL' } : query)),
      ),
      this._getsCountriesQuery.getCountriesQuery().pipe(
        filter(countries => !!countries),
        map(countries =>
          countries.map(({ name, code }) =>
            name == 'Greece' ? { name, code: 'EL' } : { name, code },
          ),
        ),
      ),
    )
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe(([teamSettings, countriesQuery]: [TeamSettingsQuery, CountryQuery[]]) => {
        const {
          agencyName,
          contactEmail,
          addressLine1,
          addressLine2,
          zipCode,
          city,
          countryCode,
          vatNumber,
        } = teamSettings;
        const country =
          countryCode &&
          countriesQuery.find(availableCountry => availableCountry.code === countryCode);
        this.form.patchValue({
          agencyName,
          contactEmail,
          addressLine1,
          addressLine2,
          zipCode,
          city,
          country,
          vatNumberToBeProvided: false,
          vatNumber,
        });
      });
  }

  private handleSaveError(): void {
    this._getsSaveTeamCommandErrorQuery
      .getSaveTeamCommandErrorQuery()
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe((errorFieldNames: string[]) => {
        errorFieldNames.forEach(errorFieldName => {
          const control = this.form.get(errorFieldName);
          if (control) {
            control.setErrors({ ...control.errors, invalidField: true });
          }
        });
        this.form.updateValueAndValidity();
        this._changeDetectorRef.detectChanges();
      });
  }

  vatValidator(getsVatNumberValidationQuery: GetsVatNumberValidationQueryPort): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      getsVatNumberValidationQuery.getVatValidation(control.value).pipe(
        take(1),
        map((res: VatNumberValidationQuery) => (res.isValid ? null : { [res.errorKey]: true })),
        tap(() => (this.showSuccessMessage = true)),
      );
  }

  displayCountryName(countryQuery: CountryQuery): string | null {
    return countryQuery?.name;
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

import { DOCUMENT } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationsState } from './application/notifications.state';
import { GETS_NOTIFICATIONS } from './domain/gets-notifications';
import { MARKS_AS_READ } from './domain/marks-as-read';
import { HttpGetsNotificationsService } from './infrastructure/http-gets-notifications.service';
import { HttpMarksAsReadService } from './infrastructure/http-marks-as-read.service';

@NgModule({
  providers: [
    {
      provide: MARKS_AS_READ,
      useClass: HttpMarksAsReadService,
    },
    {
      provide: GETS_NOTIFICATIONS,
      useClass: HttpGetsNotificationsService,
    },
    { provide: DOCUMENT, useValue: document },
    NotificationsState,
  ],
})
export class CoreNotificationsModule {}

import { InjectionToken } from '@angular/core';
import { ClientDTO } from '../dto/clients/client.dto';

export interface ClientsListChangedDispatcherPort {
  dispatch(event: { updatedClients: ClientDTO[] }): void;
}

export const CLIENTS_LIST_CHANGED_DISPATCHER = new InjectionToken<ClientsListChangedDispatcherPort>(
  'CLIENTS_LIST_CHANGED_DISPATCHER',
);

import { Injectable } from '@angular/core';
import { UserImpersonateLogoutEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserImpersonationState } from '../application/user-impersonation.state';

@Injectable()
export class UserImpersonateLogoutEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UserImpersonateLogoutEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _state: UserImpersonationState) {}

  handle([_]: [UserImpersonateLogoutEvent]): void {
    this._state.clearImpersonation();
  }
}

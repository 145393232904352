import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { TagManagerEnv } from '../../../../../environments/environment.config';

export interface GtmEvent {
  event: string;
  user_id?: string;
  user_email?: string;
}

interface DataLayerContainer {
  dataLayer: any;
}

@Injectable({
  providedIn: 'root',
})
export class GtmTracker {
  private tagManagerEnabled: boolean;
  private tagManagerKey: string;
  private dataLayerContainer: DataLayerContainer;

  constructor(@Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig) {
    const tagManagerEnv = this._getsConfig.get<TagManagerEnv>('tagManager');
    this.tagManagerEnabled = tagManagerEnv.enabled;
    this.tagManagerKey = tagManagerEnv.key;
    this.dataLayerContainer = window as any;

    this.init();
  }

  track(eventData: GtmEvent): void {
    console.log(this.tagManagerEnabled);
    if (!this.tagManagerEnabled) {
      return;
    }

    this.dataLayerContainer.dataLayer.push(eventData);
  }

  private init(): void {
    if (!this.tagManagerEnabled) {
      return;
    }

    const scriptTagName = 'script';
    const dataLayerKey = 'dataLayer';

    window[dataLayerKey] = window[dataLayerKey] || [];
    window[dataLayerKey].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const firstScriptElement = document.getElementsByTagName(scriptTagName)[0];
    const newScriptElement = document.createElement(scriptTagName);
    const dataLayerParam = '&l=' + dataLayerKey;
    newScriptElement.async = true;
    newScriptElement.src =
      'https://www.googletagmanager.com/gtm.js?id=' + this.tagManagerKey + dataLayerParam;
    firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement);
  }
}

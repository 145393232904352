import { InjectionToken } from '@angular/core';

export interface IncrementsTeamMembersCountCommandPort {
  incrementTeamMembersCount(teamId: string): void;
}

export const INCREMENTS_TEAM_MEMBERS_COUNT_COMMAND =
  new InjectionToken<IncrementsTeamMembersCountCommandPort>(
    'INCREMENTS_TEAM_MEMBERS_COUNT_COMMAND',
  );

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersContext } from './usersContext';

export const SELECTS_USERS_CONTEXT_STORAGE = new InjectionToken<SelectsUsersContextStoragePort>(
  'SELECTS_USERS_CONTEXT_STORAGE',
);

export interface SelectsUsersContextStoragePort {
  select(): Observable<Partial<UsersContext>>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CompanyDetailsQuery } from './company-details.query';

export const GETS_COMPANY_DETAILS_QUERY_PORT = new InjectionToken<GetsCompanyDetailsQueryPort>(
  'GETS_COMPANY_DETAILS_QUERY_PORT',
);

export interface GetsCompanyDetailsQueryPort {
  getCompanyDetails(): Observable<CompanyDetailsQuery>;
}

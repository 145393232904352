<div class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center">
  <div [class.cs-emoji-circle]="emoji" [class.cs-mb-30]="emoji" [ngClass]="emojiBackground">
    {{ emoji }}
  </div>
  <h3 class="cs-title-4 cs-mb-20 text-center">
    {{ 'cbr_pro_agency_settings_replace_card_title' | language }}
  </h3>
  <p class="cs-text-1 cs-mb-30 text-center cs-white-space-pre-line">
    {{ 'cbr_pro_agency_settings_replace_card_description' | language }}
  </p>

  <div class="d-flex justify-content-center cs-mt-40">
    <button class="cs-btn cs-btn-primary" [mat-dialog-close]="true">
      {{ '_confirm' | language }}
    </button>
    <button class="cs-btn cs-btn-error cs-ml-10" [mat-dialog-close]="false">
      {{ 'cbr_pro_agency_settings_no_button' | language }}
    </button>
  </div>
</div>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspaceDto } from './workspace.dto';

export const GETS_ALL_WORKSPACES_DTO = new InjectionToken<GetsAllWorkspacesDtoPort>(
  'GETS_ALL_WORKSPACE_DTO',
);

export interface GetsAllWorkspacesDtoPort {
  getsAll(teamId: string): Observable<WorkspaceDto[]>;
}

<div class="d-flex flex-column align-items-center h-100 cs-p-10">
  <div class="cs-p-10 my-auto cs-text-2">
    <div
      class="cs-card cs-asset-bg-gradient-1 cs-bg-size-contain cs-bg-shade-0 cs-shadow-shade-10 br-15 cs-b-none cs-min-width-700-md cs-max-width-700-md cs-min-width-100-pc-xs cs-ph-100-lg cs-pv-40 cs-ph-60-md"
    >
      <lib-verify-code-form></lib-verify-code-form>

      <div
        class="d-flex justify-content-center align-items-center cs-ph-80-lg cs-ph-20-sm cs-mv-40 position-relative"
      >
        <span class="cs-text-1 cs-c-shade-100 cs-bg-shade-0 position-absolute cs-ph-20">
          {{ '_or' | language }}</span
        >
        <hr class="cs-divider w-100" />
      </div>

      <div class="cs-card-footer cs-p-0 cs-mt-40">
        <div
          class="d-flex flex-column align-items-center justify-content-around cs-ph-80-lg cs-ph-20-sm"
        >
          <lib-sign-up-by-google class="w-100">
            {{ 'signup_social_signup_google' | language }}
          </lib-sign-up-by-google>
          <lib-sign-up-by-facebook class="w-100 cs-mt-40">
            {{ 'signup_social_signup_facebook' | language }}
          </lib-sign-up-by-facebook>
        </div>
      </div>
    </div>
    <a class="cs-link text-center d-block cs-pl-10 cs-mt-15" href="https://cobiro.com"
      >{{ '_back_to' | language }} cobiro.com</a
    >
  </div>
</div>

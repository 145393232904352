import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientQuery } from '../../../../application/ports/primary/client.query';

@Component({
  selector: 'lib-display-client-info',
  templateUrl: './display-client-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayClientInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { client: ClientQuery },
    private _matDialogRef: MatDialogRef<DisplayClientInfoComponent>,
  ) {}

  onClose(): void {
    this._matDialogRef.close();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceUsersComponent } from './workspace-users.component';
import { RouterModule } from '@angular/router';
import { CobiroProWorkspaceUsersControlsComponentModule } from './workspace-users-controls/workspace-users-controls.component-module';
import { CobiroProWorkspaceUsersTableComponentModule } from './workspace-users-table/workspace-users-table.component-module';

@NgModule({
  declarations: [WorkspaceUsersComponent],
  imports: [
    CommonModule,
    CobiroProWorkspaceUsersControlsComponentModule,
    CobiroProWorkspaceUsersTableComponentModule,
    RouterModule.forChild([{ path: '', component: WorkspaceUsersComponent }]),
  ],
  exports: [WorkspaceUsersComponent],
})
export class WorkspaceUsersComponentModule {}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GETS_CARDS_LIST_COMMAND,
  GetsCardsListCommandPort,
} from '../../../../../../../../application/ports/primary/payments/gets-cards-list.command-port';
import {
  GETS_SELECTED_CARD_QUERY,
  GetsSelectedCardQueryPort,
} from '../../../../../../../../application/ports/primary/payments/gets-selected-card.query-port';
import {
  SETS_SELECTED_CARD_COMMAND,
  SetsSelectedCardCommandPort,
} from '../../../../../../../../application/ports/primary/payments/sets-selected-card.command-port';
import { Observable, map } from 'rxjs';
import { CardQuery } from '../../../../../../../../application/ports/primary/payments/card.query';

@Component({
  selector: 'lib-cobiro-pro-card-list',
  templateUrl: './card-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProCardListComponent {
  cards$: Observable<CardQuery[]> = this._getsCardsListCommand.getCards();

  constructor(
    @Inject(SETS_SELECTED_CARD_COMMAND)
    private readonly _setsSelectedCardCommand: SetsSelectedCardCommandPort,
    @Inject(GETS_SELECTED_CARD_QUERY)
    private readonly _getsSelectedCardCommand: GetsSelectedCardQueryPort,
    @Inject(GETS_CARDS_LIST_COMMAND)
    private readonly _getsCardsListCommand: GetsCardsListCommandPort,
  ) {}

  onSavedCardClicked(cardId: string): void {
    this._setsSelectedCardCommand.setCard({ id: cardId });
  }

  isSelected$(id: string): Observable<boolean> {
    return this._getsSelectedCardCommand
      .getCard()
      .pipe(map((selectedCardId: string) => selectedCardId === id));
  }
}

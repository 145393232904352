import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { iif, Observable, of, throwError } from 'rxjs';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { Inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { HasData } from '@cobiro/jsonapi';
import { BillingDataDto } from '../../../../application/ports/secondary/dto/payments/billing-data.dto';
import { GetsBillingDataDtoPort } from '../../../../application/ports/secondary/dto/payments/gets-billing-data-dto.port';

interface BillingDataAttributes {
  details: {
    countryCode?: string;
    vatNumber?: string;
  };
}

@Injectable()
export class HttpGetsBillingDataService implements GetsBillingDataDtoPort {
  constructor(private readonly _gateway: GatewayClient) {}

  getBillingData(workspaceId: string): Observable<BillingDataDto> {
    return this._gateway
      .get<HasData<BillingDataAttributes>>(`v1/sites/workspaces/${workspaceId}/billing-settings`)
      .pipe(
        map(response => ({
          countryCode: response.data.attributes.details?.countryCode ?? null,
          vatNumber: response.data.attributes.details?.vatNumber,
        })),
      );
  }
}

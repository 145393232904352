import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CreateNewSubscriptionDto,
  CreatesNewSubscriptionDtoPort,
  PAYMENT_STATUS,
} from '@app.cobiro.com/payment-plans';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { SELECTED_CLIENT_STORAGE } from '../storage/selected-client.storage';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { SelectedClientDto } from '../../../application/ports/secondary/selected-client.dto';

export enum ERROR_CODES {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

@Injectable()
export class HttpManagesPlanService implements CreatesNewSubscriptionDtoPort {
  private readonly ERRORS: Map<ERROR_CODES, PAYMENT_STATUS> = new Map([
    [ERROR_CODES.INSUFFICIENT_FUNDS, PAYMENT_STATUS.INSUFFICIENT_FUNDS],
  ]);

  constructor(
    private readonly _gateway: GatewayClient,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(SELECTED_CLIENT_STORAGE)
    private readonly _selectedClientStorage: ReactiveSingleValueStorage<SelectedClientDto>,
  ) {}

  createNewSubscription(dto: CreateNewSubscriptionDto): Observable<PAYMENT_STATUS> {
    const { planId, cardId, discountCode } = dto;
    const companyName = this._selectedClientStorage.get()?.companyName;

    if (!companyName) {
      console.error('Missing company name!');
      return throwError(() => new Error('Missing company name'));
    }

    return this._handlePayment(
      this._gateway.post(`v2/payments/teams/${this._teamIdGetter.getTeamId()}/change-site-plan`, {
        data: {
          type: 'change-site-plan',
          attributes: {
            companyName,
            planId,
            paymentSourceId: cardId,
            discountCode: discountCode ?? null,
          },
        },
      }),
    );
  }

  private _handlePayment(paymentObs): Observable<PAYMENT_STATUS> {
    return paymentObs.pipe(
      map(() => PAYMENT_STATUS.SUCCESS),
      catchError(errors => {
        console.error(errors);
        const errorCode = errors[0]?.code;

        if (errorCode) {
          return this._handleErrorWithCode(errorCode);
        }

        return of(PAYMENT_STATUS.DEFAULT_FAILED);
      }),
    );
  }

  private _handleErrorWithCode(code: ERROR_CODES): Observable<PAYMENT_STATUS> {
    try {
      const confirmation = this._mapErrorToConfirmation(code);
      return of(confirmation);
    } catch (e) {
      console.error(e);
      return of(PAYMENT_STATUS.DEFAULT_FAILED);
    }
  }

  private _mapErrorToConfirmation(code: ERROR_CODES): PAYMENT_STATUS {
    const paymentStatus = this.ERRORS.get(code);

    if (!paymentStatus) {
      throw new UnknownBackendCodeError(code);
    }

    return paymentStatus;
  }
}

export class UnknownBackendCodeError extends Error {
  title = 'Unknown backend error code';

  constructor(code: string) {
    super();
    this.message = `Unknown backend error code ${code}`;
  }
}

<div class="cs-bt-1 cs-b-shade-10 cs-mv-40 cs-mh-10"></div>
<div class="cs-min-height-365 d-flex justify-content-center align-items-center">
  <div id="chart-revenue" class="w-50" #chartRevenueContainer>
    <apx-chart
      data-selector="label-manager-chart-result-costs"
      #chartRevenue
      [series]="chartOptionsRevenue.series"
      [chart]="chartOptionsRevenue.chart"
      [legend]="chartOptionsRevenue.legend"
      [labels]="chartOptionsRevenue.labels"
      [dataLabels]="chartOptionsRevenue.dataLabels"
      [title]="chartOptionsRevenue.title"
      [theme]="chartOptionsRevenue.theme"
      [colors]="chartOptionsRevenue.colors"
      [plotOptions]="chartOptionsRevenue.plotOptions"
      [tooltip]="chartOptionsRevenue.tooltip"
      [noData]="chartOptionsRevenue.noData"
    ></apx-chart>
  </div>
  <div id="chart-costs" class="w-50" #chartCostContainer>
    <apx-chart
      data-selector="label-manager-chart-result-revenue"
      #chartCosts
      [series]="chartOptionsCosts.series"
      [chart]="chartOptionsCosts.chart"
      [legend]="chartOptionsCosts.legend"
      [labels]="chartOptionsCosts.labels"
      [dataLabels]="chartOptionsCosts.dataLabels"
      [title]="chartOptionsCosts.title"
      [theme]="chartOptionsCosts.theme"
      [colors]="chartOptionsCosts.colors"
      [plotOptions]="chartOptionsCosts.plotOptions"
      [tooltip]="chartOptionsCosts.tooltip"
      [noData]="chartOptionsCosts.noData"
    ></apx-chart>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { CreateProfileProgressIndicatorComponentModule } from '../../../create-profile-progress-indicator/create-profile-progress-indicator.component-module';
import { CompanyDetailsStepComponent } from './company-details-step.component';

@NgModule({
  imports: [
    CommonModule,
    CreateProfileProgressIndicatorComponentModule,
    LanguageModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatIconModule,
    HuiInputModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  declarations: [CompanyDetailsStepComponent],
  exports: [CompanyDetailsStepComponent],
})
export class CompanyDetailsStepComponentModule {}

import { Inject, Injectable, Optional } from '@angular/core';
import { EVENT_LOG_MONITOR, EventLogMonitor } from './domain/event-log-monitor';
import { APPLICATION_BUS, ApplicationBus, ApplicationEvent } from '@cobiro/eda';

@Injectable()
export class EventLoggerService {
  constructor(
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
    @Optional() @Inject(EVENT_LOG_MONITOR) private _monitors: EventLogMonitor[],
  ) {}

  init() {
    this._applicationBus.onEveryEvent().subscribe(event => {
      this._log(event);
    });
  }

  private _log(event: ApplicationEvent): void {
    if (!this._monitors || !this._monitors.length) {
      return;
    }
    this._monitors.forEach(monitor => {
      const monitoringEventHandler = monitor.eventHandlers.find(eventHandler =>
        event.isEqual(eventHandler.eventClass),
      );
      if (monitoringEventHandler) {
        monitoringEventHandler.handler(event);
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBillingAddressComponent } from './add-billing-address.component';
import { BillingAddressFormComponentModule } from '../../../modals/change-payment-plan-modal/upgrade-plan-step/fill-billing-address/billing-address-form/billing-address-form.component-module';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AddBillingAddressComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, BillingAddressFormComponentModule],
  exports: [AddBillingAddressComponent],
})
export class AddBillingAddressComponentModule {}

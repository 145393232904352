export class SetsLabelManagerConfigCommand {
  constructor(
    public readonly breakEvenROAS: number,
    public readonly lookBackWindow: number,
    public readonly customLabel: string | null,
    public initialConfiguration: boolean,
    public analysisFrequency?: number | null,
  ) {}
  static fromFormValue(form: {
    breakEvenROAS: number;
    lookBackWindow: number;
    customLabel: { value: string | null };
    analysisFrequency: number | null;
  }): SetsLabelManagerConfigCommand {
    const object = new SetsLabelManagerConfigCommand(
      form.breakEvenROAS,
      form.lookBackWindow,
      form.customLabel.value,
      false,
    );
    if (form.analysisFrequency) {
      object.analysisFrequency = form.analysisFrequency;
    }
    return object;
  }
}

import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { DomainError } from '@app.cobiro.com/core/state';
import { RegistersUserUnknownError, UserEmailTakenError } from '@app.cobiro.com/user/core';
import { HasData, HasErrorResponse } from '@cobiro/jsonapi';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SignsUpByEmailDtoPort } from '../../application/ports/secondary/signs-up-by-email.dto-port';
import { RegisterUserRequestAttributes } from './http-registers-user.service';

@Injectable()
export class HttpSignsUpService implements SignsUpByEmailDtoPort {
  private readonly _captchaHeaderKey = 'X-Cobiro-Captcha-Token';
  private readonly _errors = new Map([[UserEmailTakenError.code, new UserEmailTakenError()]]);

  constructor(private readonly _gateway: GatewayClient) {}

  signUpByEmail(
    email: string,
    password: string,
    captchaToken: string,
  ): Observable<{ userId: string }> {
    const body: HasData<RegisterUserRequestAttributes> = {
      data: {
        type: 'users',
        attributes: {
          email,
          password,
          partnerId: '1',
        },
      },
    };

    return this._gateway
      .post<HasData<{ id: string }>>('v2/users', body, {
        additionalHeaders: { [this._captchaHeaderKey]: captchaToken },
      })
      .pipe(
        map(res => ({ userId: res.data.id })),
        catchError((err: HasErrorResponse) => {
          return throwError(this._handleError(err));
        }),
      );
  }

  private _handleError(err: HasErrorResponse): DomainError {
    const errorDetail = err?.error?.errors[0];
    const code = errorDetail?.code;
    const error = this._errors.get(code);

    return error ?? new RegistersUserUnknownError(errorDetail?.detail);
  }
}

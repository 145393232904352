import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import {
  ClosesLMRecommendationQueryPort,
  CLOSES_LM_RECOMMENDATION_QUERY,
} from '../../../../application/ports/primary/closes-lm-recommendation.query-port';
import {
  GetsCurrentLMRecommendationQueryPort,
  GETS_CURRENT_LM_RECOMMENDATION_QUERY,
} from '../../../../application/ports/primary/gets-current-lm-recommendation.query-port';
import { LabelManagerRecommendationQuery } from '../../../../application/ports/primary/label-manager-recomendation.query';

@Component({
  selector: 'lib-cobiro-label-manager-recommendations',
  templateUrl: './label-manager-recommendations.component.html',
  styleUrls: ['./label-manager-recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerRecommendationsComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  @Input() isSimulation: boolean;

  private readonly _messageList: BehaviorSubject<LabelManagerRecommendationQuery[]> =
    new BehaviorSubject(null);
  readonly messageList$ = this._messageList.asObservable();

  constructor(
    @Inject(GETS_CURRENT_LM_RECOMMENDATION_QUERY)
    private readonly _getsCurrentLMRecommendationQueryPort: GetsCurrentLMRecommendationQueryPort,
    @Inject(CLOSES_LM_RECOMMENDATION_QUERY)
    private readonly _closesLMRecommendationQueryPort: ClosesLMRecommendationQueryPort,
  ) {}

  ngOnInit(): void {
    this._getsCurrentLMRecommendationQueryPort
      .getCurrentLMRecommendation(this.isSimulation)
      .pipe(
        tap(res => {
          this._messageList.next(res);
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onCloseBtnClicked(item: LabelManagerRecommendationQuery): void {
    const currentList = this._messageList.getValue();
    const index = currentList.indexOf(item);
    currentList.splice(index, 1);
    this._messageList.next(currentList);
    this._closesLMRecommendationQueryPort.closeLmRecommendation(item);
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

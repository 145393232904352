import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerNonCssIntegrationComponent } from './label-manager-non-css-integration.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { LabelManagerNonCssIntegrationStepperComponent } from './label-manager-non-css-integration-stepper/label-manager-non-css-integration-stepper.component';
import { LabelManagerNonCssIntegrationStepComponent } from './steps/label-manager-non-css-integration-step/label-manager-non-css-integration-step.component';
import { LabelManagerNonCssIntegrationtStepContentDirective } from './steps/label-manager-non-css-integration-step/label-manager-non-css-integration-step-content.directive';
import { IframeStepComponentModule } from './steps/iframe-step/iframe-step.component-module';
import { IntroductionStepComponentModule } from './steps/introduction-step/introduction-step.component-module';
import { LoaderStepComponentModule } from './steps/loader-step/loader-step.component-module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PortalModule } from '@angular/cdk/portal';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { SuccessNonCssLinkingComponent } from './success-non-css-linking/success-non-css-linking.component';

@NgModule({
  declarations: [
    LabelManagerNonCssIntegrationComponent,
    LabelManagerNonCssIntegrationStepperComponent,
    LabelManagerNonCssIntegrationStepComponent,
    LabelManagerNonCssIntegrationtStepContentDirective,
    SuccessNonCssLinkingComponent,
  ],
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    HuiInputModule,
    IframeStepComponentModule,
    LoaderStepComponentModule,
    IntroductionStepComponentModule,
    CdkStepperModule,
    PortalModule,
    HuiStepperModule,
  ],
  exports: [
    LabelManagerNonCssIntegrationComponent,
    LabelManagerNonCssIntegrationStepperComponent,
    LabelManagerNonCssIntegrationStepComponent,
    LabelManagerNonCssIntegrationtStepContentDirective,
  ],
})
export class LabelManagerNonCssIntegrationComponentModule {}

import { ClientDTO } from '../secondary/client.dto';
import { InjectionToken } from '@angular/core';

// TODO (PRO-DEBT): Create Command class
export interface StoresNewClientCommandPort {
  storeNewClient(client: ClientDTO): void;
}

export const STORES_NEW_CLIENT_COMMAND = new InjectionToken<StoresNewClientCommandPort>(
  'STORES_NEW_CLIENT_COMMAND',
);

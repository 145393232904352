import { Injectable } from '@angular/core';
import { HasEventTracking, HasUserTracking, UserInterface } from './tracker.interface';
import { GtmTracker } from './gtm.tracker';

@Injectable({
  providedIn: 'root',
})
export class GtmTrackerService implements HasEventTracking, HasUserTracking {
  constructor(private tracker: GtmTracker) {}

  event(userId: string, code: string, data?: any) {
    this.tracker.track({ event: code, user_id: userId, ...data });
  }

  login(user: UserInterface) {
    this.tracker.track({ event: 'AuthData', user_id: user.id });
  }

  logout() {
    this.tracker.track({ event: 'Logout' });
  }

  resetPassword(user: UserInterface) {
    this.tracker.track({ event: 'ResetPassword', user_id: user.id });
  }

  signUp(user: UserInterface) {
    this.tracker.track({ event: 'Signup', user_id: user.id, user_email: user.email });
  }
}

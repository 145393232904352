import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationInfoDto } from './integration-info.dto';

export const GETS_INTEGRATION_DTO = new InjectionToken<GetsIntegrationDtoPort>(
  'GETS_INTEGRATION_DTO',
);

export interface GetsIntegrationDtoPort {
  getIntegration(siteId: string): Observable<IntegrationInfoDto>;
}

import { Dispatcher } from '@cobiro/eda';
import { UserAdditionalDataChangedEvent } from '@app.cobiro.com/core/events';
import { InjectionToken } from '@angular/core';

export interface UserAdditionalDataChangedDispatcherPort
  extends Dispatcher<UserAdditionalDataChangedEvent> {
  dispatch(event: UserAdditionalDataChangedEvent): void;
}

export const USER_ADDITIONAL_DATA_CHANGED_DISPATCHER =
  new InjectionToken<UserAdditionalDataChangedDispatcherPort>(
    'USER_ADDITIONAL_DATA_CHANGED_DISPATCHER',
  );

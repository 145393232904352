<div class="d-flex flex-column align-items-center h-100">
  <div class="cs-pt-10 my-auto cs-text-2">
    <div
      class="cs-card cs-asset-bg-gradient-1 cs-bg-size-contain cs-shadow-shade-10 cs-mv-auto cs-bg-shade-0 br-15 cs-b-none cs-min-width-700-lg cs-min-width-100-pc-xs cs-ph-100-lg cs-ph-60-md cs-pv-70-xxl cs-pv-30-xl cs-pv-20"
    >
      <div class="cs-ph-80-md cs-ph-20-sm">
        <header
          class="cs-card-header flex-column justify-content-center align-items-center cs-pt-0 cs-m-0"
        >
          <img class="cs-height-30-xs" src="assets/logos/cobiro-logo-with-text.svg" />
          <h2 class="cs-title-3 cs-mt-15">
            {{ '_login' | language }}
          </h2>
        </header>

        <p class="cs-text-2 d-block cs-mt-15 cs-pb-60 text-center">
          {{ 'login_new_member' | language }}
          <a
            class="cs-link"
            data-selector="go-to-sign-up-button"
            [routerLink]="['/auth/register']"
            [libAnalytics]="['sign-in', 'signup-redirect']"
            >{{ '_here' | language }}</a
          >
        </p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="cs-form">
          <div class="cs-control-wrapper">
            <input
              class="cs-input"
              placeholder="Email"
              formControlName="email"
              data-selector="login-email-input"
              data-analytics="login-email-input"
              autofocus
            />
            <div class="cs-min-height-20-xs">
              <p *ngIf="hasError('email')" class="cs-control-error">
                {{ 'login_input_not_email' | language }}
              </p>
            </div>
          </div>
          <div class="cs-control-wrapper cs-mt-10">
            <input
              class="cs-input cs-input-with-icon-right"
              [placeholder]="'_password' | language"
              formControlName="password"
              type="password"
              [type]="passwordVisible ? 'text' : 'password'"
              data-selector="login-password-input"
              data-analytics="login-password-input"
            />
            <div
              class="cs-pr-10 h-100 cs-cursor-pointer"
              (click)="passwordVisible = !passwordVisible"
              data-selector="show-password"
            >
              <mat-icon
                svgIcon="eye-opened"
                tabindex="-1"
                *ngIf="passwordVisible"
                class="cs-input-icon-right"
              >
              </mat-icon>
              <mat-icon
                svgIcon="eye-closed"
                tabindex="-1"
                *ngIf="!passwordVisible"
                class="cs-input-icon-right"
              >
              </mat-icon>
            </div>
          </div>
          <div class="cs-min-height-20-xs">
            <p *ngIf="hasError('password')" class="d-block cs-control-error">
              {{ 'login_input_not_password' | language }}
            </p>
          </div>

          <button
            class="cs-btn cs-btn-primary w-100 d-flex align-items-center justify-content-center cs-mt-20"
            [disabled]="form.invalid"
            [huiLoading]="isLoading$ | async"
            data-selector="login-button"
            [libAnalytics]="['sign-in', 'login']"
          >
            <span class="cs-c-shade-0">
              {{ '_log_in' | language }}
            </span>
          </button>
          <a
            class="d-block text-center cs-link cs-mt-10"
            routerLink="password-recovery"
            [libAnalytics]="['sign-in', 'forgot-password']"
          >
            {{ 'login_forgot_your_password' | language }}
          </a>
        </form>

        <div class="d-flex justify-content-center align-items-center cs-mv-40 position-relative">
          <span class="cs-text-1 cs-c-shade-100 cs-bg-shade-0 position-absolute cs-ph-20">
            {{ '_or' | language }}</span
          >
          <hr class="cs-divider w-100" />
        </div>

        <div class="cs-card-footer cs-p-0 cs-mt-40">
          <div class="w-100 d-flex align-items-center flex-column justify-content-around">
            <lib-sign-up-by-google class="w-100">
              {{ 'signup_social_signup_google' | language }}
            </lib-sign-up-by-google>
            <lib-sign-up-by-facebook class="w-100 cs-mt-30">
              {{ 'signup_social_signup_facebook' | language }}
            </lib-sign-up-by-facebook>
          </div>
        </div>
      </div>
    </div>
    <a
      class="d-block cs-link d-block cs-pl-10 cs-mt-15 text-center"
      href="https://cobiro.com"
      [libAnalytics]="['sign-in', 'back-to-cobiro']"
      >{{ '_back_to' | language }} cobiro.com
    </a>
  </div>
</div>

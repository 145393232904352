import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EditAccessUserCommand } from './edit-access-user.command';

export const EDIT_ACCESS_USER_COMMAND = new InjectionToken<EditAccessUserCommandPort>(
  'EDIT_ACCESS_USER_COMMAND',
);

export interface EditAccessUserCommandPort {
  editAccessUser(command: EditAccessUserCommand): Observable<void>;
}

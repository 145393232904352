import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmIntegrationCancelDialogComponent } from './confirm-integration-cancel-dialog.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ConfirmIntegrationCancelDialogComponent],
  imports: [CommonModule, LanguageModule, MatDialogModule],
  exports: [ConfirmIntegrationCancelDialogComponent],
})
export class ConfirmIntegrationCancelDialogComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspaceDto } from './workspace.dto';

export const GETS_MEMBER_WORKSPACES_DTO = new InjectionToken<GetsMemberWorkspacesDtoPort>(
  'GETS_MEMBER_WORKSPACES_DTO',
);

export interface GetsMemberWorkspacesDtoPort {
  getsMembersWorkspace(teamId: string): Observable<WorkspaceDto[]>;
}

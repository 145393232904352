import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { UserRegisteredEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerUserRegisteredEventMapper implements DataLayerTrackingMapper {
  eventClass = UserRegisteredEvent;

  mapEvent(event: UserRegisteredEvent): DataLayerTrackingData {
    return {
      eventName: '/Hub - Account - Account Created - v2',
      eventData: {
        user_id: event.userId,
      },
    };
  }
}

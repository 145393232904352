import { InjectionToken } from '@angular/core';
import { UpdatesPlanCommand } from './updates-plan.command';
import { Observable } from 'rxjs';

export const UPDATES_PLAN_COMMAND_PORT = new InjectionToken<UpdatesPlanCommandPort>(
  'UPDATES_PLAN_COMMAND_PORT',
);

export interface UpdatesPlanCommandPort {
  updatesPlan(command: UpdatesPlanCommand): Observable<PAYMENT_STATUS>;
}

export enum PAYMENT_STATUS {
  SUCCESS,
  DEFAULT_FAILED,
  INSUFFICIENT_FUNDS,
}

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { map } from 'rxjs';
import {
  GetsIntegrationIdQueryPort,
  GETS_INTEGRATION_ID_QUERY,
} from '../../../../application/ports/primary/gets-integration-id.query-port';

@Component({
  selector: 'lib-label-manager-linking-result',
  templateUrl: './label-manager-linking-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerLinkingResultComponent {
  readonly _link$ = this._getsIntegrationIdQueryPort
    .getIntegrationId()
    .pipe(
      map(
        (integrationId: string) =>
          `https://feeds.cobiro.com/supplemental-feeds/${integrationId}.csv`,
      ),
    );

  constructor(
    @Inject(GETS_INTEGRATION_ID_QUERY)
    private readonly _getsIntegrationIdQueryPort: GetsIntegrationIdQueryPort,
    private readonly _alert: HuiAlert,
  ) {}

  onCopyToClipboardClicked() {
    this._alert.open('success', 'cobiro_pro_label_manager_labeling_result_copied_succesfully');
  }
}

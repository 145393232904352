import { NgModule } from '@angular/core';
import { HttpGetsUserRoleServiceModule } from '../../adapters/secondary/infrastructure/http-gets-user-role.service-module';
import { CHECKS_APP_ACCESS_QUERY } from '../ports/primary/checks-app-access-query.port';
import { GETS_SHUTDOWN_APPLICATIONS_QUERY } from '../ports/primary/gets-shutdown-applications.query-port';
import { SET_USER_PERSONAL_SITES_COMMAND } from '../ports/primary/sets-user-personal-sites.command-port';
import { TeamAccessState } from './team-access.state';

// TODO: Can it be cut from app-module and get data from UserAdditionalDataChangedEvent?
@NgModule({
  imports: [HttpGetsUserRoleServiceModule],
  providers: [
    TeamAccessState,
    { provide: CHECKS_APP_ACCESS_QUERY, useExisting: TeamAccessState },
    { provide: GETS_SHUTDOWN_APPLICATIONS_QUERY, useExisting: TeamAccessState },
    { provide: SET_USER_PERSONAL_SITES_COMMAND, useExisting: TeamAccessState },
  ],
})
export class TeamAccessStateModule {}

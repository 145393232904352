<div
  class="position-relative d-flex flex-column align-items-center cs-bg-shade-0 h-100 cs-ph-40 cs-pb-40"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-invite-user-close-modal"
    matDialogClose
  ></mat-icon>

  <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">🧑‍💻</div>

  <h4 class="cs-title-4 cs-mt-20">
    {{ 'cobiro_pro_users_edit_user' | language : [data.user.name ? data.user.name : 'this user'] }}
  </h4>

  <form class="d-flex flex-column justify-content-between w-100 cs-mt-20" [formGroup]="userForm">
    <div class="cs-control-wrapper">
      <label class="cs-radio-option d-flex" data-selector="dataSelectorName">
        <input type="radio" value="basic" name="role" formControlName="role" />
        <div class="cs-radio-option-content">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="cs-title-7">{{ 'cobiro_pro_users_basic_user' | language }}</p>
              <p class="cs-text-1">{{ 'cobiro_pro_users_basic_user_description' | language }}</p>
            </div>
          </div>
        </div>
      </label>
      <label class="cs-radio-option d-flex" data-selector="dataSelectorName">
        <input type="radio" value="admin" name="role" formControlName="role" />
        <div class="cs-radio-option-content">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="cs-title-7">{{ 'cobiro_pro_users_admin_user' | language }}</p>
              <p class="cs-text-1">{{ 'cobiro_pro_users_admin_user_description' | language }}</p>
            </div>
          </div>
        </div>
      </label>
    </div>

    <div class="cs-mv-20">
      <ng-select
        class="cs-ng-select"
        [items]="workspaces$ | async"
        [multiple]="true"
        [closeOnSelect]="true"
        [searchable]="true"
        bindLabel="name"
        placeholder="Select workspace"
        formControlName="workspaces"
      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" /> {{ item.name }}
        </ng-template>
      </ng-select>
    </div>

    <button
      (click)="onSendInvitationClicked()"
      class="cs-btn cs-btn-primary cs-mt-5"
      data-selector="cbr-pro-send-invitation-submit-btn"
      type="submit"
    >
      {{ '_update' | language }}
    </button>
  </form>
</div>

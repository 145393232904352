import { NgModule } from '@angular/core';
import { UPDATES_USER_DETAILS_DTO } from '../../../application/ports/secondary/updates-user-details.dto-port';
import { HttpUserDetailsService } from './http-user-details.service';

@NgModule({
  providers: [
    HttpUserDetailsService,
    {
      provide: UPDATES_USER_DETAILS_DTO,
      useExisting: HttpUserDetailsService,
    },
  ],
})
export class HttpUserDetailsServiceModule {}

/* eslint-disable max-lines-per-function */
import { DomainEvent } from '../domain/event';

export class PaymentConfirmEvent extends DomainEvent {
  constructor(
    public readonly workspaceId: string,
    public readonly planId: string,
    public readonly billingData: {
      readonly countryCode: string;
      readonly vatNumber: string;
    },
    public readonly discountCode: {
      readonly code: string;
      readonly type: string;
      readonly value: number;
      readonly duration: {
        type: string;
        value?: number;
        unit?: string;
      };
    } | null,
    public readonly planEstimate: {
      readonly nett: number;
      readonly gross: number;
      readonly vatValue: number;
      readonly vatPercentage: number;
      readonly hasValidDiscountCode: boolean;
      readonly grossValueBeforeDiscount: number;
    },
  ) {
    super();
  }
}

import { Injectable } from '@angular/core';
import {
  PlanLoadedEvent,
  PlanSelectedEvent,
  UpgradeFlowEndedEvent,
  UpgradeFlowStartedEvent,
  UserChangedEvent,
  UserSignedOutEvent,
} from '@app.cobiro.com/core/events';
import { EventLogMonitor } from '../../../domain/event-log-monitor';
import { EventLoggerContext } from '../../event-logger.context';

@Injectable()
export class ContextEditorMonitor implements EventLogMonitor {
  eventHandlers = [
    {
      eventClass: UserChangedEvent,
      handler: (event: UserChangedEvent) => {
        this._eventLoggerContext.set('user_id', event.user.id);
        this._eventLoggerContext.set(
          'sign_up_date',
          `${event.user.createdAt.getFullYear()}-${
            event.user.createdAt.getMonth() + 1
          }-${event.user.createdAt.getDate()}`,
        );
      },
    },
    {
      eventClass: UserSignedOutEvent,
      handler: (event: UserSignedOutEvent) => {
        this._eventLoggerContext.clear();
      },
    },
    {
      eventClass: PlanLoadedEvent,
      handler: (event: PlanLoadedEvent) => {
        this._eventLoggerContext.set('current_plan', event.plan);
        this._eventLoggerContext.set(
          'current_plan_period',
          event.plan === 'free' ? null : event.period,
        );
      },
    },
    {
      eventClass: PlanSelectedEvent,
      handler: (event: PlanSelectedEvent) => {
        this._eventLoggerContext.set('upgrade_plan', event.planId.split('-')[0]);
        this._eventLoggerContext.set('upgrade_plan_period', event.planId.split('-')[1]);
      },
    },
    {
      eventClass: UpgradeFlowStartedEvent,
      handler: (event: UpgradeFlowStartedEvent) => {
        this._eventLoggerContext.set('upgrade_flow_version', event.flowVersion);
      },
    },
    {
      eventClass: UpgradeFlowEndedEvent,
      handler: (event: UpgradeFlowEndedEvent) => {
        this._eventLoggerContext.delete('upgrade_plan');
        this._eventLoggerContext.delete('upgrade_plan_period');
        this._eventLoggerContext.delete('upgrade_flow_version');
      },
    },
  ];

  constructor(private _eventLoggerContext: EventLoggerContext) {}
}

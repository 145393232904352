import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { GETS_LABEL_MANAGER_REPORT_DTO_PORT } from '../../../../application/ports/secondary/gets-label-manager-report.dto-port';
import { HttpLabelManagerReportService } from './http-label-manager-report.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpLabelManagerReportService,
    {
      provide: GETS_LABEL_MANAGER_REPORT_DTO_PORT,
      useExisting: HttpLabelManagerReportService,
    },
  ],
})
export class HttpLabelManagerReportServiceModule {}

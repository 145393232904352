import { NgModule } from '@angular/core';
import { HttpTeamsServiceModule } from '../adapters/secondary/infrastructure/http-teams.service-module';
import { TeamsStateModule } from '../application/state/teams.state-module';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CobiroProClearStatesEventHandler } from '../adapters/primary/handlers/cobiro-pro-clear-state.event-handler';

@NgModule({
  imports: [TeamsStateModule, HttpTeamsServiceModule],
  providers: [provideApplicationEventsHandler(CobiroProClearStatesEventHandler)],
})
export class CobiroProRewriteTeamSwitcherProvidersModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const RESETS_DISCOUNT_CODE_COMMAND = new InjectionToken<ResetsDiscountCodeCommandPort>(
  'RESETS_DISCOUNT_CODE_COMMAND',
);

export interface ResetsDiscountCodeCommandPort {
  resetDiscountCode(): Observable<void>;
}

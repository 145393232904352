<div class="d-flex row cs-mb-20">
  <div class="col-md-8">
    <div class="cs-card">
      <form
        data-selector="change-password-form"
        [formGroup]="form"
        (ngSubmit)="onFormSubmitted()"
        class="cs-form"
      >
        <div class="cs-control-wrapper">
          <label class="cs-label">{{ 'user_old_password' | language }}</label>
          <input
            class="cs-input cs-input-with-icon-right"
            autocomplete="off"
            formControlName="oldPassword"
            [type]="inputOldPassword ? 'text' : 'password'"
          />

          <div
            class="cs-pr-10 cs-cursor-pointer cs-form"
            (click)="inputOldPassword = !inputOldPassword"
          >
            <mat-icon
              svgIcon="eye-opened"
              tabindex="-1"
              *ngIf="!inputOldPassword"
              class="cs-input-icon-right"
            >
            </mat-icon>
            <mat-icon
              svgIcon="eye-closed"
              tabindex="-1"
              *ngIf="inputOldPassword"
              class="cs-input-icon-right"
            >
            </mat-icon>
          </div>

          <div class="cs-min-height-20-xs">
            <p
              *ngIf="form.get('oldPassword').invalid && form.get('oldPassword').touched"
              class="cs-control-error"
            >
              {{ 'user_input_not_password' | language }}
            </p>
          </div>
        </div>

        <div class="cs-control-wrapper cs-mt-20">
          <label class="cs-label">{{ 'user_new_password' | language }}</label>
          <lib-password-form
            formControlName="newPassword"
            [disableErrorMessage]="true"
          ></lib-password-form>
          <div class="cs-min-height-20-xs">
            <p
              *ngIf="form.get('newPassword').invalid && form.get('newPassword').touched"
              class="cs-control-error"
            >
              {{ 'user_input_not_password' | language }}
            </p>
          </div>
        </div>

        <div class="d-flex justify-content-end cs-mt-20">
          <button
            data-selector="submit-button"
            [class.is-loading]="isProcessing$ | async"
            type="submit"
            class="cs-btn cs-btn-primary cs-min-width-100-sm"
          >
            {{ '_save' | language }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

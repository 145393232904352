import { Component, ChangeDetectionStrategy, OnDestroy, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import {
  GetsLabelManagerConfigQueryPort,
  GETS_LABEL_MANAGER_CONFIG_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-config.query-port';
import {
  CUSTOM_LABEL_LIST,
  CustomLabelQuery,
  LabelManagerConfigQuery,
} from '../../../../application/ports/primary/label-manager-config.query';
import { SetsLabelManagerConfigCommand } from '../../../../application/ports/primary/sets-label-manager-config.command';
import {
  SetsLabelManagerConfigCommandPort,
  SETS_LABEL_MANAGER_CONFIG_COMMAND_PORT,
} from '../../../../application/ports/primary/sets-label-manager-config.command-port';

@Component({
  selector: 'lib-label-manager-attributes',
  templateUrl: './label-manager-attributes.component.html',
  styleUrls: ['./label-manager-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerAttributesComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();

  readonly labels = CUSTOM_LABEL_LIST;
  readonly configAttributes$: Observable<LabelManagerConfigQuery> =
    this._getsLabelManagerConfigQueryPort.getConfig();

  readonly form = new FormGroup({
    breakEvenROAS: new FormControl<number>(1000, [Validators.required]),
    lookBackWindow: new FormControl<number>(48, [Validators.required]),
    customLabel: new FormControl<CustomLabelQuery | null>(null, [Validators.required]),
    analysisFrequency: new FormControl<number>(null),
  });

  constructor(
    @Inject(GETS_LABEL_MANAGER_CONFIG_QUERY_PORT)
    private readonly _getsLabelManagerConfigQueryPort: GetsLabelManagerConfigQueryPort,
    @Inject(SETS_LABEL_MANAGER_CONFIG_COMMAND_PORT)
    private readonly _setsLabelManagerConfigCommandPort: SetsLabelManagerConfigCommandPort,
  ) {}

  ngOnInit(): void {
    this.handleBuildForm();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }

  private handleBuildForm(): void {
    this.configAttributes$
      .pipe(
        filter(query => !!query),
        takeUntil(this._ngDestroy$),
      )
      .subscribe((configAttributes: LabelManagerConfigQuery) => {
        const { breakEvenROAS, lookBackWindow, customLabel, analysisFrequency } = configAttributes;
        this.form.patchValue({
          breakEvenROAS,
          lookBackWindow,
          customLabel,
          analysisFrequency,
        });
      });
  }

  onFormSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this._setsLabelManagerConfigCommandPort
      .setConfig(SetsLabelManagerConfigCommand.fromFormValue(this.form.getRawValue()))
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }
}

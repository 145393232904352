import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { IntegrationIdChangedEvent } from '../../../application/events/integration-id-changed.event';
import { IntegrationIdChangedDispatcherPort } from '../../../application/ports/secondary/dispatchers/integration-id-changed.dispatcher-port';
import { IntegrationInfo } from '../../../application/ports/secondary/integration-info.dto';

@Injectable()
export class IntegrationIdChangedEventDispatcher implements IntegrationIdChangedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { integration: IntegrationInfo }) {
    this._applicationBus.dispatch(new IntegrationIdChangedEvent(event.integration));
  }
}

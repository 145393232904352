import { Observable } from 'rxjs';
import { TeamSettingsQuery } from './team-settings.query';
import { InjectionToken } from '@angular/core';

export interface GetsTeamSettingsQueryPort {
  getTeamSettings(): Observable<TeamSettingsQuery>;
}

export const GETS_TEAM_SETTINGS_QUERY_PORT = new InjectionToken<GetsTeamSettingsQueryPort>(
  'GETS_TEAM_SETTINGS_QUERY',
);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { VatNumberValidationQuery } from './vat-number-validation.query';

export const GETS_VAT_NUMBER_VALIDATION_QUERY =
  new InjectionToken<GetsVatNumberValidationQueryPort>('GETS_VAT_NUMBER_VALIDATION_QUERY');

export interface GetsVatNumberValidationQueryPort {
  getVatValidation(vatNumber: string): Observable<VatNumberValidationQuery>;
}

import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddsClientSiteDtoPort } from '../../../../application/ports/secondary/dto/clients/adds-client-site.dto-port';
import { ClientSiteDTO } from '../../../../application/ports/secondary/dto/clients/client-site.dto';

export interface UserSiteAttributes {
  domain: string;
  workspaceId: string;
}

interface UserSiteCreatedResponse {
  data: {
    id: number;
  };
}

@Injectable()
export class HttpClientSitesService implements AddsClientSiteDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  add(item: ClientSiteDTO): Observable<string> {
    const payload: HasData<UserSiteAttributes> = {
      data: {
        id: item.id,
        type: 'user-sites',
        attributes: {
          domain: item.url,
          workspaceId: item.workspaceId,
        },
      },
    };

    return this._client
      .post<UserSiteCreatedResponse>('v2/sites/user-sites', payload)
      .pipe(map(res => String(res.data.id)));
  }
}

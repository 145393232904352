import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamQuery } from './team.query';

export const GETS_CURRENT_TEAM_QUERY = new InjectionToken<GetsCurrentTeamQueryPort>(
  'GETS_CURRENT_TEAM_QUERY',
);

export interface GetsCurrentTeamQueryPort {
  getCurrentTeamQuery(): Observable<TeamQuery | null>;
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PRODUCT_LIST } from '../../../../../../application/ports/primary/clients/product-list.query';
import { ClientSubscriptionQuery } from '../../../../../../application/ports/primary/clients/subscription.query';

@Component({
  selector: 'lib-cobiro-pro-client-not-deletable-modal',
  templateUrl: './client-not-deletable-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientNotDeletableModalComponent {
  readonly products: string[] = Object.values(PRODUCT_LIST);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { subscriptions: ClientSubscriptionQuery[] | null },
  ) {}

  isPaidSubscription(product: string) {
    return this.data.subscriptions.some(subscription => subscription.productName === product);
  }
}

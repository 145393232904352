import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ManageClientClickedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/clients';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class ManageClientClickedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ManageClientClickedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  handle([event]: [ManageClientClickedEvent]): void {
    this._router.navigate([
      '/',
      'connect',
      this._teamIdGetter.getTeamId(),
      'clients',
      event.clientId,
      event.productName,
    ]);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SavesSelectedCountryCommand } from './saves-selected-country.command';

export const SAVES_SELECTED_COUNTRY_COMMAND = new InjectionToken<SavesSelectedCountryCommandPort>(
  'SAVES_SELECTED_COUNTRY_COMMAND',
);

export interface SavesSelectedCountryCommandPort {
  saveSelectedCountry(command: SavesSelectedCountryCommand): Observable<void>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const GETS_NON_CSS_INTEGRATION_QUERY = new InjectionToken<GetsNonCssIntegrationQueryPort>(
  'GETS_NON_CSS_INTEGRATION_QUERY',
);

export interface GetsNonCssIntegrationQueryPort {
  getNonCssIntegration(): Observable<boolean>;
}

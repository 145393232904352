import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspaceQuery } from './workspace.query';

export const SWITCHES_WORKSPACE_COMMAND = new InjectionToken<SwitchesWorkspaceCommandPort>(
  'SWITCHES_WORKSPACE_COMMAND',
);

export interface SwitchesWorkspaceCommandPort {
  switchWorkspace(workspace: WorkspaceQuery): Observable<void>;
}

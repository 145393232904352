import { Inject, Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { HasData } from '@cobiro/jsonapi';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';
import { map } from 'rxjs/operators';
import { Details, UpdatesDetails } from '../domain/updates-details';

export interface UpdateDetailsAttributes {
  weekly_summary: boolean;
  email: string;
  first_name: string;
  last_name: string;
  avatar_url: string;
}

export type UpdateDetailsRequestBody = HasData<Partial<UpdateDetailsAttributes>>;

@Injectable()
export class HttpUpdatesDetailsService implements UpdatesDetails {
  constructor(
    private _client: GatewayClient,
    @Inject(GETS_USER_FROM_TOKEN) private _getsUser: GetsUserFromToken,
  ) {}

  update(data: Partial<Details>): Observable<Details> {
    const userId = this._getsUser.get()?.userId;

    if (!userId) {
      throw new Error('User id is missing');
    }

    const body: UpdateDetailsRequestBody = {
      data: {
        type: 'users',
        id: userId,
        attributes: this._hydrateToAttributes(data),
      },
    };

    return this._client
      .patch<HasData<UpdateDetailsAttributes>>(`v1/users/${userId}`, body)
      .pipe(map(response => this._hydrateToDetails(response.data.attributes)));
  }

  private _hydrateToAttributes(data: Partial<Details>): Partial<UpdateDetailsAttributes> {
    return Object.keys(data).reduce((acc, key) => {
      acc[camelCaseToSnakeCase(key)] = data[key];
      return acc;
    }, {});
  }

  private _hydrateToDetails(attributes: UpdateDetailsAttributes): Details {
    return {
      firstName: attributes.first_name,
      lastName: attributes.last_name,
      email: attributes.email,
      weeklySummary: attributes.weekly_summary,
      avatarUrl: attributes.avatar_url,
    };
  }
}

export const snakeToCamelCase = (word: string) => {
  const words = word.split('_');
  const first = words.shift();
  return first + words.map(w => w.charAt(0).toUpperCase() + w.slice(1)).join('');
};

export const camelCaseToSnakeCase = (phrase: string): string => {
  const index = [...phrase].findIndex(p => p.charAt(0) === p.charAt(0).toUpperCase());

  if (index < 0) {
    return phrase;
  }

  const first = phrase.slice(0, index);
  const rest = phrase.slice(index);
  const slicedPhrase = rest.charAt(0).toLowerCase() + rest.slice(1);

  return first + '_' + camelCaseToSnakeCase(slicedPhrase);
};

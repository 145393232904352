import { NgModule } from '@angular/core';
import { DELETES_WORKSPACE_USER_DTO } from '../../../../application/ports/secondary/dto/deletes-workspace-user.dto-port';
import { GETS_ALL_WORKSPACE_USERS_DTO } from '../../../../application/ports/secondary/dto/gets-all-workspace-users.dto-port';
import { HttpWorkspaceUsersService } from './http-workspace-users.service';

@NgModule({
  providers: [
    HttpWorkspaceUsersService,
    { provide: GETS_ALL_WORKSPACE_USERS_DTO, useExisting: HttpWorkspaceUsersService },
    { provide: DELETES_WORKSPACE_USER_DTO, useExisting: HttpWorkspaceUsersService },
  ],
})
export class HttpWorkspaceUsersServiceModule {}

import { CreatePaymentSourceDto } from './create-payment-source.dto';
import { Observable } from 'rxjs';
import { PaymentSourceCreatedDto } from './payment-source-created.dto';
import { InjectionToken } from '@angular/core';

export interface CreatesPaymentSourceDtoPort {
  createPaymentSource(dto: CreatePaymentSourceDto): Observable<PaymentSourceCreatedDto>;
}

export const CREATES_PAYMENT_SOURCE_DTO_PORT = new InjectionToken<CreatesPaymentSourceDtoPort>(
  'CREATES_PAYMENT_SOURCE_DTO_PORT',
);

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EmojiBackgroundTypes } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/emoji-background-types.enum';
import { Emojis } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/emojis.enum';

@Component({
  selector: 'lib-confirm-replace-card-modal',
  templateUrl: './confirm-replace-card-modal.component.html',
  styleUrls: ['./confirm-replace-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmReplaceCardModalComponent {
  emoji = Emojis.CARD;
  emojiBackground = EmojiBackgroundTypes.TERTIARY;
}

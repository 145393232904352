import { NgModule } from '@angular/core';
import { HttpTeamCreatePaymentIntentService } from './http-team-create-payment-intent.service';
import { CREATES_PAYMENT_INTENT_DTO_PORT } from '../../../../application/ports/secondary/dto/payments/creates-payment-intent.dto-port';

@NgModule({
  providers: [
    HttpTeamCreatePaymentIntentService,
    { provide: CREATES_PAYMENT_INTENT_DTO_PORT, useExisting: HttpTeamCreatePaymentIntentService },
  ],
})
export class HttpTeamCreatePaymentIntentServiceModule {}

import { NgModule } from '@angular/core';
import { SENDS_PARTNER_REQUEST_QUERY } from '../ports/primary/sends-partner-request.query-port';
import { PartnerProgramState } from './partner-program.state';

@NgModule({
  providers: [
    PartnerProgramState,
    {
      provide: SENDS_PARTNER_REQUEST_QUERY,
      useExisting: PartnerProgramState,
    },
  ],
})
export class PartnerProgramStateModule {}

/* eslint-disable complexity */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, firstValueFrom, map, take, takeUntil, tap } from 'rxjs';
import {
  GETS_COMPANY_TYPES_QUERY,
  GetsCompanyTypesQueryPort,
} from '../../../../application/ports/primary/gets-company-types.query-port';
import {
  GETS_NUMBER_OF_CLIENTS_QUERY,
  GetsNumberOfClientsQueryPort,
} from '../../../../application/ports/primary/gets-number-of-clients.query-port';
import {
  GETS_NUMBER_OF_EMPLOYEES_QUERY,
  GetsNumberOfEmployeesQueryPort,
} from '../../../../application/ports/primary/gets-number-of-employees.query-port';
import {
  GETS_COMPANY_DETAILS_QUERY_PORT,
  GetsCompanyDetailsQueryPort,
} from '../../../../application/ports/primary/gets-company-details.query-port';
import {
  SAVES_COMPANY_DETAILS_QUERY_PORT,
  SavesCompanyDetailsQueryPort,
} from '../../../../application/ports/primary/saves-company-details.query-port';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
} from '@app.cobiro.com/cobiro-pro/context';
import {
  DELETES_TEAMS_COMMAND_PORT,
  DeletesTeamCommandPort,
} from '../../../../application/ports/primary/deletes-team.command-port';

@Component({
  selector: 'lib-cobiro-company-details',
  templateUrl: './company-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyDetailsComponent implements OnDestroy, OnInit {
  readonly form = this._formBuilder.group({
    id: ['', Validators.required],
    companyName: ['', Validators.required],
    companyType: ['', Validators.required],
    numberOfEmployees: [0],
    numberOfClients: [0],
  });

  readonly companyTypes$ = this._getsCompanyTypesQuery.getAvailableCompanyTypes();
  readonly numberOfClients$ = this._getsNumberOfClientsQuery.getAvailableNumberOfClients();
  readonly numberOfEmployees$ = this._getsNumberOfEmployeesQuery.getAvailableNumberOfEmployees();

  readonly couldBeDeleted$ = this._getsCobiroProContextQueryPort
    .getContext()
    .pipe(map((context: CobiroProContextQuery) => context.selectedTeamUserRole === 'owner'));
  private readonly _destroyed$ = new Subject<void>();

  showNumberOfEmployees = true;

  constructor(
    @Inject(GETS_COMPANY_TYPES_QUERY)
    private readonly _getsCompanyTypesQuery: GetsCompanyTypesQueryPort,
    @Inject(GETS_NUMBER_OF_CLIENTS_QUERY)
    private readonly _getsNumberOfClientsQuery: GetsNumberOfClientsQueryPort,
    @Inject(GETS_NUMBER_OF_EMPLOYEES_QUERY)
    private readonly _getsNumberOfEmployeesQuery: GetsNumberOfEmployeesQueryPort,
    @Inject(GETS_COMPANY_DETAILS_QUERY_PORT)
    private readonly _getsCompanyDetailsQueryPort: GetsCompanyDetailsQueryPort,
    @Inject(SAVES_COMPANY_DETAILS_QUERY_PORT)
    private readonly _savesCompanyDetailsQueryPort: SavesCompanyDetailsQueryPort,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQueryPort: GetsCobiroProContextQueryPort,
    @Inject(DELETES_TEAMS_COMMAND_PORT)
    private readonly _deletesTeamCommandPort: DeletesTeamCommandPort,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _formBuilder: FormBuilder,
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    this._getsCompanyDetailsQueryPort
      .getCompanyDetails()
      .pipe(
        take(1),
        tap(res => {
          this.form.patchValue(res);
        }),
      )
      .subscribe();

    this.form
      .get('companyType')
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe((value: string) => {
        this.showNumberOfEmployees = value !== 'individual';
        this.form.get('numberOfEmployees').setValue(value !== 'individual' ? null : 1);
      });
  }

  hasError(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return control.touched && !!control.errors;
  }

  async onDeleteButtonClicked(): Promise<void> {
    await firstValueFrom(
      this._deletesTeamCommandPort.deleteTeam({
        id: this.form.value.id,
        name: this.form.value.companyName,
      }),
    );
  }

  async onSubmitClicked(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const result = await firstValueFrom(
      this._savesCompanyDetailsQueryPort.savesCompanyDetail(this.form.getRawValue()),
    );

    if (result) {
      this.form.markAsUntouched();
      this.form.markAsPristine();
      this._changeDetectorRef.detectChanges();
    }
  }
}

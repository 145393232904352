import { NgModule } from '@angular/core';
import { CobiroProTeamsLoadedDispatcher } from '../../adapters/secondary/dispatchers/cobiro-pro-teams-loaded.dispatcher';
import { HttpTeamsServiceModule } from '../../adapters/secondary/infrastructure/http-teams.service-module';
import { GETS_ALL_TEAM_QUERY } from '../ports/primary/gets-all-team-query.port';
import { GETS_CURRENT_TEAM_QUERY } from '../ports/primary/gets-current-team-query.port';
import { HAS_TEAM_QUERY } from '../ports/primary/has-team-query.port';
import { LOAD_TEAMS_COMMAND } from '../ports/primary/load-teams-command.port';
import { SET_CURRENT_TEAM_COMMAND } from '../ports/primary/set-current-team-command.port';
import { TEAMS_LOADED_DISPATCHER } from '../ports/secondary/cobiro-pro-teams-loaded.dispatcher-port';
import { TeamsState } from './teams.state';

@NgModule({
  imports: [HttpTeamsServiceModule],
  providers: [
    TeamsState,
    { provide: GETS_ALL_TEAM_QUERY, useExisting: TeamsState },
    { provide: GETS_CURRENT_TEAM_QUERY, useExisting: TeamsState },
    { provide: HAS_TEAM_QUERY, useExisting: TeamsState },
    { provide: SET_CURRENT_TEAM_COMMAND, useExisting: TeamsState },
    {
      provide: LOAD_TEAMS_COMMAND,
      useExisting: TeamsState,
    },
    {
      provide: TEAMS_LOADED_DISPATCHER,
      useClass: CobiroProTeamsLoadedDispatcher,
    },
  ],
})
export class TeamsStateModule {}

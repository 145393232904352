import { DomainError } from '@app.cobiro.com/core/state';

export class CouponInvalidError extends DomainError {
  static readonly code = 'COUPON_INVALID';

  constructor() {
    super('Coupon invalid', CouponInvalidError.code);
  }
}

export class CouponExpiredError extends DomainError {
  static readonly code = 'COUPON_EXPIRED';

  constructor() {
    super('Coupon expired', CouponExpiredError.code);
  }
}

export class CouponNotApplicableError extends DomainError {
  static readonly code = 'COUPON_NOT_APPLICABLE';

  constructor() {
    super('Coupon not applicable', CouponNotApplicableError.code);
  }
}

import { Injectable } from '@angular/core';
import { Environment } from '../../../../../../environments/environment.config';
import { GetsConfig } from './gets-config';

@Injectable()
export class WindowEnvConfig implements GetsConfig {
  static readonly configKey = 'config';

  private get _env(): Environment {
    const browserWindow = window || {};
    return browserWindow[WindowEnvConfig.configKey] || {};
  }

  get<T extends Environment[keyof Environment]>(key: keyof Environment): T {
    if (this._env[key] === undefined) {
      throw new Error(`Cannot find value for key: ${key}`);
    }

    return this._env[key] as T;
  }
}

export const provideWindowConfig = () => (window || {})[WindowEnvConfig.configKey] as Environment;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateShopComponent } from './create-shop.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CreateNewClientFormComponentModule } from '../../../modals/create-new-client-modal/create-new-client-form/create-new-client-form.component-module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CreateShopComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, CreateNewClientFormComponentModule],
  exports: [CreateShopComponent],
})
export class CreateShopComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const CREATES_LM_SIMULATION_QUERY = new InjectionToken<CreatesLMSimulationQueryPort>(
  'CREATES_LM_SIMULATION_QUERY',
);

export interface CreatesLMSimulationQueryPort {
  createLMSimulation(clientId: string): Observable<boolean>;
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Injectable } from '@angular/core';
import { WorkspaceQuantityChangedEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class CobiroProWorkspaceQuantityChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [WorkspaceQuantityChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  handle([event]: [WorkspaceQuantityChangedEvent]): void {
    return;
  }
}

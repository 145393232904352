import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeletesClientCommand } from './deletes-client.command';

export const DELETES_CLIENT_COMMAND = new InjectionToken<DeletesClientCommandPort>(
  'DELETES_CLIENT_COMMAND',
);

export interface DeletesClientCommandPort {
  deleteClient(command: DeletesClientCommand): Observable<void>;
}

import { NgModule } from '@angular/core';
import { CANCEL_USER_DELETION_QUERY } from '../ports/primary/cancel-user-deletion.query-port';
import { DELETES_USER_QUERY } from '../ports/primary/deletes-user.query-port';
import { DISABLES_EMAIL_NOTIFICATION_QUERY } from '../ports/primary/disables-email-notification.query-port';
import { UserDeletionState } from './user-deletion.state';

@NgModule({
  providers: [
    UserDeletionState,
    { provide: DELETES_USER_QUERY, useExisting: UserDeletionState },
    { provide: CANCEL_USER_DELETION_QUERY, useExisting: UserDeletionState },
    { provide: DISABLES_EMAIL_NOTIFICATION_QUERY, useExisting: UserDeletionState },
  ],
})
export class UserDeletionStateModule {}

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  GetsGoogleAdsLinkingStatusQueryPort,
  GETS_GOOGLE_ADS_LINKING_STATUS_QUERY_PORT,
} from '../../../../application/ports/primary/gets-google-ads-linking-status.query-port';

@Component({
  selector: 'lib-label-manager-status',
  templateUrl: './label-manager-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerStatusComponent {
  readonly isLinked = this._getsGoogleAdsLinkingStatusQueryPort.getStatus();

  constructor(
    @Inject(GETS_GOOGLE_ADS_LINKING_STATUS_QUERY_PORT)
    private readonly _getsGoogleAdsLinkingStatusQueryPort: GetsGoogleAdsLinkingStatusQueryPort,
  ) {}
}

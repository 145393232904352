import { Injectable } from '@angular/core';
import { State } from '@app.cobiro.com/core/state';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';

export class CardsStateModel {
  selectedId: string | null;
}

export const DEFAULT_CARDS_STATE: CardsStateModel = {
  selectedId: null,
};

// TODO: move it to FeatureModule
@Injectable()
export class CardsState extends State<CardsStateModel> {
  constructor() {
    super(DEFAULT_CARDS_STATE);
  }

  get selectedId$(): Observable<string> {
    return this.state$.pipe(
      distinctUntilKeyChanged('selectedId'),
      map(state => state.selectedId),
    );
  }

  isSelected(cardId: string): Observable<boolean> {
    return this.selectedId$.pipe(map(selectedId => selectedId === cardId));
  }

  select(id: string) {
    this.patchState({ selectedId: id });
  }
}

<h4 class="align-self-left cs-title-4 cs-mb-10">
  {{ 'cobiro_pro_label_manager_labeling_result' | language }}
</h4>
<p
  class="cs-text-1"
  [innerHTML]="'cobiro_pro_label_manager_labeling_result_description' | language"
></p>
<p
  class="cs-text-1 cs-mt-10"
  [innerHTML]="'cobiro_pro_label_manager_labeling_result_description_2' | language"
></p>
<div
  *ngIf="_link$ | async as link"
  class="cs-terminal d-flex justify-content-between flex-sm-row flex-column-reverse cs-bg-shade-10 cs-mt-20 position-relative cs-width-100-pc-xs"
>
  <code
    data-selector="label-manager-labeling-result-link"
    class="cs-terminal-line cs-text-1 cs-mt-0-sm cs-mt-10"
    >{{ link }}</code
  >
  <div class="cs-top-10 cs-right-10 d-flex align-items-start flex-row">
    <button
      data-selector="label-manager-labeling-result-copy-btn"
      class="cs-btn cs-btn-sm cs-btn-primary cs-white-space-nowrap"
      [cdkCopyToClipboard]="link"
      (click)="onCopyToClipboardClicked()"
    >
      {{ '_copy_link' | language }}
      <mat-icon svgIcon="copy" class="cs-stroke-shade-0 cs-ml-10"></mat-icon>
    </button>
    <a
      target="_blank"
      href="https://merchants.google.com/"
      data-selector="label-manager-labeling-result-merchant-center-btn"
      class="cs-btn cs-btn-sm cs-btn-stroked cs-ml-10 cs-white-space-nowrap"
    >
      {{ 'cobiro_pro_label_manager_merchant_center' | language }}
    </a>
  </div>
</div>

import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'hui-control-error',
  templateUrl: './hui-text-error.component.html',
  styleUrls: ['./hui-text-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HuiTextErrorComponent {
  textError: string | null;

  @Input() set text(value: string | null) {
    if (value !== this.textError) {
      this.textError = value;
      this._changeDetectorRef.detectChanges();
    }
  }

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}
}

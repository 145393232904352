import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UpgradesPlanCommand } from './upgrades-plan-client.command';

export const UPGRADES_PLAN_COMMAND = new InjectionToken<UpgradesPlanCommandPort>(
  'UPGRADES_PLAN_COMMAND',
);

export interface UpgradesPlanCommandPort {
  upgradePlan(command: UpgradesPlanCommand): Observable<void>;
}

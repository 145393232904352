import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentIntentDto } from './payment-intent.dto';

export interface CreatesPaymentIntentDtoPort {
  createPaymentIntent(teamId: string): Observable<PaymentIntentDto>;
}

export const CREATES_PAYMENT_INTENT_DTO_PORT = new InjectionToken<CreatesPaymentIntentDtoPort>(
  'CREATE_PAYMENT_INTENT_DTO_PORT',
);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsInstalledAppsIdsDtoPort {
  getAll(siteId: string): Observable<string[]>;
}

export const GETS_INSTALLED_APPS_IDS_DTO_PORT = new InjectionToken<GetsInstalledAppsIdsDtoPort>(
  'GETS_INSTALLED_APPS_IDS_DTO_PORT',
);

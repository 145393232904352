import { Component } from '@angular/core';
import { UpgradePlanProcess } from '../../upgrade-plan-flow/upgrade-plan.process';
import { PaymentConfirmationQuery } from '../../../../core/query/payment-confirmation.query';
import { Observable } from 'rxjs';
import { PAYMENT_STATUS } from '../../../../core/domain/payment-status.enum';

@Component({
  selector: 'lib-confirmation-step',
  templateUrl: './confirmation-step.component.html',
})
export class ConfirmationStepComponent {
  confirmations = PAYMENT_STATUS;
  confirmation$: Observable<PaymentConfirmationQuery> = this._process.confirmation$;

  constructor(private _process: UpgradePlanProcess) {}

  goBack() {
    this._process.previousStep();
  }

  endProcess() {
    this._process.end(true);
  }
}

import { InjectionToken } from '@angular/core';

export const APP_FF_KEYS_CONFIG = new InjectionToken<Map<string, string>>('APP_FF_KEYS_CONFIG', {
  providedIn: 'any',
  factory: () =>
    new Map<string, string>([
      ['facebook', 'facebook_fake_door_test'],
      // ['google_css'], impossible to recognize old and new dynamically
    ]),
});

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CreateProfileProgressIndicatorComponentModule } from '../../../create-profile-progress-indicator/create-profile-progress-indicator.component-module';
import { CompanyBaseInfoStepComponent } from './company-base-info-step.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LanguageModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    CreateProfileProgressIndicatorComponentModule,
  ],
  declarations: [CompanyBaseInfoStepComponent],
  exports: [CompanyBaseInfoStepComponent],
})
export class CompanyBaseInfoStepComponentModule {}

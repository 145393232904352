import { NgModule } from '@angular/core';
import { TeamSwitcherState } from './team-switcher.state';
import { SETS_TEAM_SWITCHER_ITEMS_COMMAND } from '../ports/primary/sets-team-switcher-items-command.port';
import { GETS_TEAM_SWITCHER_ITEMS_QUERY } from '../ports/primary/gets-team-switcher-items.query';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { ProTeamsSwitcherTeamsLoadedEventHandler } from '../../adapters/primary/event-handlers/pro-teams-switcher-teams-loaded.event-handler';
import { INCREMENTS_TEAM_MEMBERS_COUNT_COMMAND } from '../ports/primary/increments-team-members-count-command.port';
import { ProTeamSwitcherTeamMemberInvitedEventHandler } from '../../adapters/primary/event-handlers/pro-team-switcher-team-member-invited.event-handler';

@NgModule({
  providers: [
    TeamSwitcherState,
    {
      provide: SETS_TEAM_SWITCHER_ITEMS_COMMAND,
      useExisting: TeamSwitcherState,
    },
    {
      provide: GETS_TEAM_SWITCHER_ITEMS_QUERY,
      useExisting: TeamSwitcherState,
    },
    {
      provide: INCREMENTS_TEAM_MEMBERS_COUNT_COMMAND,
      useExisting: TeamSwitcherState,
    },
    provideApplicationEventsHandler(ProTeamsSwitcherTeamsLoadedEventHandler),
    provideApplicationEventsHandler(ProTeamSwitcherTeamMemberInvitedEventHandler),
  ],
})
export class CobiroProTeamSwitcherStateModule {}

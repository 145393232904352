export class IntegrationStatusQuery {
  constructor(public readonly status: 'missing' | 'pending' | 'success' | 'paused') {}

  isMissing(): boolean {
    return this.status === 'missing';
  }

  isPaused(): boolean {
    return this.status === 'paused';
  }

  isPending(): boolean {
    return this.status === 'pending';
  }

  isSuccess(): boolean {
    return this.status === 'success';
  }
}

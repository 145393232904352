import { QuestionDto } from '../secondary/question.dto';

export class QuestionQuery {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly body: string,
    public isOpened?: boolean,
  ) {}

  static fromDTO(dto: QuestionDto): QuestionQuery {
    return new QuestionQuery(dto.id, dto.title, dto.body);
  }
}

<div
  class="cs-max-width-500-xs w-100 d-flex flex-column align-items-center justify-content-center cs-p-40"
>
  <img class="w-100" src="/assets/images/404/404.svg" alt="" />

  <button
    class="cs-btn cs-btn-primary cs-width-120-xs cs-mt-40"
    data-analytics="not-found-back"
    routerLink="/sites"
  >
    {{ '_go_back' | language }}
  </button>
</div>

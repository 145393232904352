import { Injectable } from '@angular/core';
import { UpgradeRequestedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { PaymentPlansState } from '../../../application/state/payment-plans.state';

@Injectable()
export class PaymentPlanRequestedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UpgradeRequestedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _paymentPlansState: PaymentPlansState) {}

  handle([event]: [UpgradeRequestedEvent]): void {
    this._paymentPlansState.startUpgradeFlow(event.featureId);
  }
}

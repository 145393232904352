import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import {
  SETS_LABEL_MANAGER_REPORT_FOR_RECOMMENDATION_COMMAND_PORT,
  SetsLabelManagerReportForRecommendationCommandPort,
} from '../../../application/ports/primary/sets-label-manager-report-for-recommendation.command-port';
import { LabelManagerSetReportEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class LabelManagerSetReportEventHandler implements ApplicationEventsHandler {
  eventsClasses = [LabelManagerSetReportEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_LABEL_MANAGER_REPORT_FOR_RECOMMENDATION_COMMAND_PORT)
    private readonly _setsLabelManagerReportForRecommendationCommandPort: SetsLabelManagerReportForRecommendationCommandPort,
  ) {}

  handle([event]: [LabelManagerSetReportEvent]) {
    this._setsLabelManagerReportForRecommendationCommandPort.setReport(event.report).pipe(take(1));
  }
}

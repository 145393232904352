import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { LanguagePipe } from './adapter/primary/language.pipe';
import { GETS_LANGUAGE } from './application/port/gets-language';
import { LanguageService } from './adapter/secondary/language.service';
import { UPDATES_DICTIONARY, UpdatesDictionary } from './application/port/updates-dictionary';
import { FEATURE_LANGUAGES } from './application/port/feature-languages';
import { Languages, LANGUAGES } from './application/port/languages';

@NgModule({
  declarations: [LanguagePipe],
  exports: [LanguagePipe],
})
export class LanguageModule {
  constructor(
    @Optional() @Inject(UPDATES_DICTIONARY) private handler: UpdatesDictionary,
    @Optional() @Inject(FEATURE_LANGUAGES) private languages: Languages[],
  ) {
    if (handler && languages && languages.length > 0) {
      handler.loadExtraDictionary(languages.flat());
    }
  }

  static forRoot(languages): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
      providers: [
        {
          provide: GETS_LANGUAGE,
          useClass: LanguageService,
        },
        {
          provide: LANGUAGES,
          useValue: languages,
        },
      ],
    };
  }

  static forFeature(languages): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
      providers: [
        {
          provide: UPDATES_DICTIONARY,
          useExisting: GETS_LANGUAGE,
        },
        {
          provide: FEATURE_LANGUAGES,
          useValue: languages,
          multi: true,
        },
      ],
    };
  }
}

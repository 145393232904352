import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { LinkIntegrationComponent } from './link-integration.component';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [LinkIntegrationComponent],
  exports: [LinkIntegrationComponent],
})
export class LinkIntegrationComponentModule {}

import { NgModule } from '@angular/core';
import { AppRegistryModule } from '@app.cobiro.com/app-registry';
import { ClientModalsModule } from '../../adapters/primary/ui/modals/client-modals.module';
import { ClientAddedDispatcher } from '../../adapters/secondary/dispatchers/client-added.dispatcher';
import { ManagesClientCommandHandler } from '../commands/manages-client.command-handler';
import { ABORTS_EDIT_CLIENT_COMMAND } from '../ports/primary/aborts-edit-client-command.port';
import { CREATE_CLIENT_COMMAND } from '../ports/primary/create-client-command.port';
import { DELETES_CLIENT_COMMAND } from '../ports/primary/deletes-client.command-port';
import { DISPLAYS_CLIENT_INFO_COMMAND } from '../ports/primary/displays-client-info.command-port';
import { EDITS_CLIENT_COMMAND } from '../ports/primary/edits-client-command.port';
import { GETS_CURRENT_CLIENT_LOADING_STATE_QUERY } from '../ports/primary/gets-current-client-loading-state.query-port';
import { GETS_EDIT_CLIENT_QUERY } from '../ports/primary/gets-edit-client-query.port';
import { IS_EDIT_ACTIVE_QUERY } from '../ports/primary/is-edit-active-query.port';
import { MANAGES_CLIENT_COMMAND } from '../ports/primary/manages-client-command.port';
import { SAVES_CLIENT_COMMAND } from '../ports/primary/saves-client-command.port';
import { SELECTS_CLIENT_COMMAND } from '../ports/primary/selects-client-command';
import { STORES_CLIENTS_LIST_COMMAND } from '../ports/primary/stores-clients-list.command-port';
import { CLIENT_ADDED_DISPATCHER } from '../ports/secondary/client-added.dispatcher-port';
import { ClientsState } from './clients.state';
import { RedirectToAppCommandModule } from './redirect-to-app.command-module';

@NgModule({
  imports: [RedirectToAppCommandModule, AppRegistryModule, ClientModalsModule],
  providers: [
    ClientsState,
    { provide: CREATE_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: EDITS_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: GETS_EDIT_CLIENT_QUERY, useExisting: ClientsState },
    { provide: IS_EDIT_ACTIVE_QUERY, useExisting: ClientsState },
    { provide: ABORTS_EDIT_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: SAVES_CLIENT_COMMAND, useExisting: ClientsState },
    {
      provide: SELECTS_CLIENT_COMMAND,
      useExisting: ClientsState,
    },
    {
      provide: MANAGES_CLIENT_COMMAND,
      useClass: ManagesClientCommandHandler,
    },
    { provide: GETS_CURRENT_CLIENT_LOADING_STATE_QUERY, useExisting: ClientsState },
    { provide: STORES_CLIENTS_LIST_COMMAND, useExisting: ClientsState, multi: true },
    { provide: CLIENT_ADDED_DISPATCHER, useClass: ClientAddedDispatcher },
    { provide: DELETES_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: DISPLAYS_CLIENT_INFO_COMMAND, useExisting: ClientsState },
  ],
})
export class ClientsStateModule {}

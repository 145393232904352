import { FactoryProvider, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagsState } from './core/application/feature-flags.state';
import { FeatureFlagGuard } from './guards/feature-flag.guard';
import { FeatureFlagsDirective } from './directives/feature-flags.directive';
import { HasFeaturePipe } from './pipes/has-feature.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FeatureFlagsDirective, HasFeaturePipe],
  exports: [FeatureFlagsDirective, HasFeaturePipe],
  providers: [FeatureFlagGuard],
})
export class FeatureFlagsModule {
  static forRoot(serviceFactoryProvider: FactoryProvider): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [serviceFactoryProvider, FeatureFlagsState],
    };
  }
}

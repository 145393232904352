import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserAlreadyVerifiedEvent } from '@app.cobiro.com/user/register';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class UserAlreadyVerifiedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserAlreadyVerifiedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router) {}

  handle([event]: [UserAlreadyVerifiedEvent]): void {
    this._router.navigate(['/auth/register/create-profile']);
  }
}

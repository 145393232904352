import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Logouts } from '../domain/logouts';
import { HasData } from '@cobiro/jsonapi';
import { GatewayClient } from '@app.cobiro.com/common/gateway';

// It could be in auth lib
@Injectable()
export class HttpLogoutsService implements Logouts {
  constructor(private _client: GatewayClient) {}

  logout(): Observable<null> {
    const body: HasData<{}> = {
      data: {
        type: 'logout',
        attributes: {},
      },
    };

    return this._client.post<null>('v1/users/logout', body);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface LoadsTeamSettingsCommandPort {
  loadTeamsSettings(): Observable<void>;
}

export const LOADS_TEAMS_SETTINGS_COMMAND_PORT = new InjectionToken<LoadsTeamSettingsCommandPort>(
  'LOAD_TEAMS_SETTINGS_COMMAND',
);

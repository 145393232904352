import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { CreatesPaymentSourceDtoPort } from '../../../../application/ports/secondary/creates-payment-source.dto-port';
import { CreatePaymentSourceDto } from '../../../../application/ports/secondary/create-payment-source.dto';
import { Observable } from 'rxjs';
import { HasData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { PaymentSourceCreatedDto } from '../../../../application/ports/secondary/payment-source-created.dto';
import { ReplacesPaymentSourceDtoPort } from '../../../../application/ports/secondary/replaces-payment-source.dto-port';

interface CreatePaymentSourceRequestAttributes {
  workspaceId: string;
  paymentIntentId: string;
}

interface CreatePaymentSourceResponseAttributes {
  brand: string;
  last4Digits: string;
}

@Injectable()
export class HttpTeamCreatePaymentSourceService
  implements CreatesPaymentSourceDtoPort, ReplacesPaymentSourceDtoPort
{
  constructor(private readonly _gateway: GatewayClient) {}

  createPaymentSource(createDto: CreatePaymentSourceDto): Observable<PaymentSourceCreatedDto> {
    const requestBody: HasData<CreatePaymentSourceRequestAttributes> = {
      data: {
        type: 'workspace-cards',
        attributes: {
          paymentIntentId: createDto.paymentIntentId,
          workspaceId: createDto.workspaceId,
        },
      },
    };

    return this._gateway
      .post<HasData<CreatePaymentSourceResponseAttributes>>(
        `v1/payments/workspaces/${createDto.workspaceId}/workspace-cards`,
        requestBody,
      )
      .pipe(
        map(response => ({
          id: response.data.id,
          cardBrand: response.data.attributes.brand,
          lastDigits: response.data.attributes.last4Digits,
        })),
      );
  }

  replacePaymentSource(createDto: CreatePaymentSourceDto): Observable<PaymentSourceCreatedDto> {
    const requestBody: HasData<CreatePaymentSourceRequestAttributes> = {
      data: {
        type: 'workspace-cards',
        attributes: {
          paymentIntentId: createDto.paymentIntentId,
          workspaceId: createDto.workspaceId,
        },
      },
    };

    return this._gateway
      .put<HasData<CreatePaymentSourceResponseAttributes>>(
        `v1/payments/workspaces/${createDto.workspaceId}/workspace-cards`,
        requestBody,
      )
      .pipe(
        map(response => ({
          id: response.data.id,
          cardBrand: response.data.attributes.brand,
          lastDigits: response.data.attributes.last4Digits,
        })),
      );
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage, LocalStorageInterface } from '@app.cobiro.com/core/storage';
import { Observable } from 'rxjs';

const blacklistedEndpoints = ['/v1/login', '/v1/register', '/v1/users/social-logins'];

const validApis = [
  'https://api.test-cobiro.com',
  'https://api.staging-cobiro.com',
  'https://dev-app.staging-cobiro.com',
  'https://api.cobiro.com',
  'https://hub.test-cobiro.com',
  'https://hub.staging-cobiro.com',
  'https://hub.cobiro.com',
  'https://facebook.services.k8s.test-cobiro.com',
  'http://localhost',
  'https://gateway.test-cobiro.com',
  'https://gateway.staging-cobiro.com',
  'https://gateway.cobiro.com',
  'http://localhost:3333',
  'https://app.staging-cobiro.com',
  'https://app.cobiro.com',
  'https://autoscout24-ng.staging-cobiro.com',
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  authStorage: LocalStorageInterface;

  private excludedUrlsRegex: RegExp[];
  private excludedUrls = ['.svg'];

  constructor(private localStorage: LocalStorage) {
    this.authStorage = this.localStorage.init('auth');
    this.excludedUrlsRegex = this.excludedUrls.map(urlPattern => new RegExp(urlPattern, 'i')) || [];
  }

  /**
   * Token interceptor which puts the token on every request if it has a valid base api and not a blacklisted path
   * @param req is the http request that is intercepted
   * @param next is a object containing a .handle() callback and the modified request
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const passThrough = !!this.excludedUrlsRegex.find(regex => regex.test(req.url));

    if (passThrough) {
      return next.handle(req);
    }

    const urlObj = new URL(req.url);

    const validUrl = validApis.includes(urlObj.origin);
    const validEndpoint = !blacklistedEndpoints.includes(urlObj.pathname);
    if (validUrl && validEndpoint) {
      const authObj = this.authStorage.get();

      const accessToken = authObj && authObj.access_token;
      const tokenType = authObj && authObj.token_type;
      const updatedRequest = req.clone({
        setHeaders: { Authorization: `${tokenType} ${accessToken}` },
      });

      return next.handle(updatedRequest);
    } else {
      return next.handle(req);
    }
  }
}

import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageNotificationQuery } from '../../query/message-notification.query';

@Component({
  selector: 'lib-message-notification-modal',
  templateUrl: './message-notification-modal.component.html',
  styleUrls: ['./message-notification-modal.component.scss'],
})
export class MessageNotificationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<MessageNotificationModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public modalData: MessageNotificationQuery,
  ) {}
}

import { ApplicationEvent } from '@cobiro/eda';

export class SignedInEvent extends ApplicationEvent {
  constructor(
    public readonly userId: number,
    public readonly tokenType: string,
    public readonly accessToken: string,
    public readonly refreshToken: string,
    public readonly redirect = true,
  ) {
    super();
  }
}

import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { GETS_COUNTRIES_DTO } from '../../../../application/ports/secondary/gets-countries.dto-port';
import { HttpGetsCountriesService } from './http-gets-countries.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpGetsCountriesService,
    {
      provide: GETS_COUNTRIES_DTO,
      useExisting: HttpGetsCountriesService,
    },
  ],
})
export class HttpGetsCountriesServiceModule {}

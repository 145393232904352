import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';
import { RecaptchaComponent } from 'ng-recaptcha';
import { UserRegisterState } from '../../application/user-register.state';
import { ENV_CONFIG } from '@app.cobiro.com/core/utils';
import { AnimationEvent } from '@angular/animations';
import { PasswordFormComponent } from '@app.cobiro.com/user/shared';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-user-register-container',
  templateUrl: './user-register-container.component.html',
  host: {
    class: 'd-block',
  },
})
export class UserRegisterContainerComponent implements AfterViewInit {
  private readonly _captchaResolving = new BehaviorSubject<boolean>(false);
  public readonly siteKey = this._config.get('recaptcha');

  @ViewChild('passwordFormComponent') public passwordForm: PasswordFormComponent;
  @ViewChild('stepper') public stepper: HuiStepperComponent;
  @ViewChild('recaptchaRef') recaptcha: RecaptchaComponent;

  passwordControl = new UntypedFormControl();
  constructor(private _state: UserRegisterState, @Inject(ENV_CONFIG) private readonly _config) {}

  get isLoading$(): Observable<boolean> {
    return this._state.isRegisterProcessing$;
  }

  get captchaResolving$(): Observable<boolean> {
    return this._captchaResolving.asObservable();
  }

  onContinueButtonClicked(): void {
    this._resolveCaptcha();
  }

  ngAfterViewInit(): void {
    this._state.start(this.stepper);
  }

  onAnimationDone($event: AnimationEvent): void {
    if ($event.toState === 'current' && this.stepper.selectedIndex === 1) {
      this.passwordForm.focusInput();
    }
  }

  onPasswordEnterPressed(): void {
    if (this.passwordControl.valid) {
      this._resolveCaptcha();
    }
  }

  onCaptchaResolved(token: string) {
    this._captchaResolving.next(false);
    this._state.setCaptcha(token);
    this._state.setPassword(this.passwordControl.value);
  }

  private _resolveCaptcha() {
    this._captchaResolving.next(true);
    this.recaptcha.execute();
  }
}

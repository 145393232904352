import { DomainError } from '@app.cobiro.com/core/state';

export class UserDeleteWrongPasswordError extends DomainError {
  static readonly code = 'WRONG_PASSWORD';

  constructor() {
    super('User delete - Wrong password provided', UserDeleteWrongPasswordError.code);
  }
}

export class UserDeleteCannotRemoveProAccountError extends DomainError {
  static readonly code = 'PRO_ACCOUNT';

  constructor() {
    super(
      'User delete - Cobiro Pro owners cannot remove their account',
      UserDeleteCannotRemoveProAccountError.code,
    );
  }
}

export class UserDeleteUnknownError extends DomainError {
  static readonly code = 'UNKNOWN_ERROR';

  constructor() {
    super('User delete - Unknown error', UserDeleteUnknownError.code);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnoucementDTO } from './annoucement.dto';

export interface GetsAnnoucementDtoPort {
  getAnnoucements(): Observable<AnnoucementDTO[]>;
}

export const GETS_ANNOUCEMENT_DTO = new InjectionToken<GetsAnnoucementDtoPort>(
  'GETS_ANNOUCEMENT_DTO',
);

/* eslint-disable max-lines-per-function */
export class ClientPlanQuery {
  constructor(public readonly label: string) {}
}

export class ClientSubscriptionQuery {
  constructor(
    public readonly productName: string,
    public readonly planType: ClientPlanQuery | null,
    public readonly subscriptionId: string,
  ) {}
  static fromDTO(dto: {
    productName: string;
    planType: string;
    subscriptionId: string;
  }): ClientSubscriptionQuery {
    return new ClientSubscriptionQuery(
      dto.productName,
      dto.planType ? new ClientPlanQuery(dto.planType) : null,
      dto.subscriptionId,
    );
  }
}

export interface ClientDTO {
  readonly id: string;
  readonly siteId: string;
  readonly sitePublicId: string;
  readonly teamId: string;
  readonly workspaceId: string;
  readonly cssIntegrationStatus: 'paused' | 'active' | 'pending' | null;
  readonly merchantSiteId: string | null;
  readonly companyName: string;
  readonly contactPerson: string;
  readonly contactEmail: string;
  readonly contactPhone: string;
  readonly createdAt?: string;
  readonly url: string;
  readonly avatar: string;
  readonly source: string;
  readonly plan: string;
  readonly installedApps: string[];
  readonly archived: boolean;
  readonly subscriptions:
    | {
        productName: string;
        planType: string;
        subscriptionId: string;
      }[]
    | null;
  readonly productStatuses: Record<'css' | 'label-manager', 'pending' | 'active' | 'paused' | null>;
  readonly cssDomainId: string;
}

export class ClientListItemQuery {
  constructor(
    public readonly id: string,
    public readonly teamId: string,
    public readonly workspaceId: string,
    public readonly merchantSiteId: string | null,
    public readonly siteId: string | null,
    public readonly sitePublicId: string,
    public readonly companyName: string,
    public readonly contactPerson: string,
    public readonly contactEmail: string,
    public readonly contactPhone: string,
    public readonly createdAt: Date,
    public readonly url: string,
    public readonly avatar: string,
    public readonly productStatuses: Record<
      'css' | 'label-manager',
      'pending' | 'active' | 'paused' | null
    >,
    public readonly subscriptions: ClientSubscriptionQuery[] | null,
    public readonly cssDomainId: string,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  static fromClientDTO(dto: ClientDTO): ClientListItemQuery {
    return new ClientListItemQuery(
      dto.id,
      dto.teamId,
      dto.workspaceId,
      dto.merchantSiteId,
      dto.siteId,
      dto.sitePublicId,
      dto.companyName,
      dto.contactPerson,
      dto.contactEmail,
      dto.contactPhone,
      new Date(dto.createdAt),
      dto.url,
      dto.avatar,
      dto.productStatuses,
      dto.subscriptions
        ? dto.subscriptions.map(subscription => ClientSubscriptionQuery.fromDTO(subscription))
        : null,
      dto.cssDomainId,
    );
  }

  hasPaidPlan(productName?: string): boolean {
    if (productName) {
      return this.subscriptions?.some(product => product.productName === productName);
    }
    return this.subscriptions?.length > 0;
  }

  getPlanForProduct(productName: string): ClientPlanQuery {
    return this.subscriptions.find(product => product.productName === productName)?.planType;
  }

  getStatusForProduct(productName: string): string {
    const status = this.productStatuses[productName];
    return status ? status : 'none';
  }
}

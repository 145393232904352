import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  SignInCompletedEvent,
  SignInFailedEvent,
  SignInStartedEvent,
  UnverifiedUserSignedInEvent,
  UserAdditionalDataLoadRequestedEvent,
} from '@app.cobiro.com/core/events';
import { DomainError } from '@app.cobiro.com/core/state';
import {
  APPLICATION_BUS,
  ApplicationEvent,
  ApplicationEventsHandler,
  Dispatcher,
  ongoingEventsOrchestrationStrategy,
} from '@cobiro/eda';
import { JsonResponseError } from '@cobiro/jsonapi';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { UserState } from '../application/user.state';
import { AuthData } from '../domain/logins';

export class NotVerifiedUserError extends DomainError {
  constructor() {
    super('Email is not verified', 'USER_NOT_VERIFIED');
  }
}

@Injectable()
export class SignInStartedEventHandler implements ApplicationEventsHandler {
  public static readonly USER_NOT_VERIFIED_KEY = 'Email is not verified';

  eventsClasses = [SignInStartedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  private _errors = new Map<string, Error>([
    [SignInStartedEventHandler.USER_NOT_VERIFIED_KEY, new NotVerifiedUserError()],
  ]);

  constructor(
    private _userState: UserState,
    @Inject(APPLICATION_BUS) private _applicationBus: Dispatcher<ApplicationEvent>,
    private _router: Router,
  ) {}

  handle([event]: [SignInStartedEvent]): void {
    this._userState
      .login(event.email, event.password)
      .pipe(
        take(1),
        catchError((err: { errors: JsonResponseError[] }) => {
          const detail = err.errors[0].detail;
          const error = this._errors.get(detail);

          if (error) {
            this._applicationBus.dispatch(
              new UnverifiedUserSignedInEvent(event.email, event.password),
            );
          }

          return throwError(new Error(detail));
        }),
      )
      .subscribe(
        (login: AuthData) => {
          this._applicationBus.dispatch(new UserAdditionalDataLoadRequestedEvent());
          this._applicationBus.dispatch(new SignInCompletedEvent(String(login.id)));
        },
        (error: Error) => {
          this._applicationBus.dispatch(new SignInFailedEvent(error.message));
        },
      );
  }
}

import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { CancelsClientSubscriptionCSSDto } from '../../../../application/ports/secondary/dto/clients/cancels-client-subscription-css.dto';
import { CancelsClientSubscriptionCSSDtoPort } from '../../../../application/ports/secondary/dto/clients/cancels-client-subscription-css.dto-port';

@Injectable()
export class HttpCancelsClientSubscriptionCSSService
  implements CancelsClientSubscriptionCSSDtoPort
{
  constructor(private readonly _gateway: GatewayClient) {}

  cancel({
    siteId,
    endOfTerm,
    reasonCode,
    comment,
  }: CancelsClientSubscriptionCSSDto): Observable<void> {
    return this._gateway.post<void>(`v1/payments/${siteId}/cancel-subscription`, {
      data: {
        type: 'cancel-subscription',
        attributes: {
          endOfTerm,
          reasonCode,
          cancellationComment: comment,
        },
      },
    });
  }
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserProfileUpdatedEvent } from '@app.cobiro.com/core/events';
import { UserState } from '../application/user.state';
import { Injectable } from '@angular/core';

@Injectable()
export class UserProfileUpdatedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserProfileUpdatedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private _userState: UserState) {}

  handle([event]: [UserProfileUpdatedEvent]): void {
    this._userState.updateUserData({
      firstName: event.profileData.firstName,
      lastName: event.profileData.lastName,
      avatar: event.profileData.avatar,
      phoneNumber: event.profileData.phoneNumber,
      country: event.profileData.country,
    });
  }
}

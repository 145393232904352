export const INSTALLED_APPS_PATHS = new Map<string, string[]>([
  ['brex', ['builder']],
  ['ecommerce', ['ecommerce', 'products']],
  ['google_search', ['app', 'google-search']],
  ['google_css', ['app', 'google-css']],
  ['domain', ['domains']],
]);

export const NO_INSTALLED_APPS_PATHS = new Map<string, string[]>([
  ['ecommerce', ['ecommerce', 'products']],
  ['google_search', ['store', 'app', 'google-search']],
  ['google_css', ['store', 'app', 'google-css']],
  ['domain', ['domains']],
]);

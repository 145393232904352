import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordChangedEvent, SignInCompletedEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class UserPasswordChangedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [PasswordChangedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router, private readonly _alert: HuiAlert) {}

  handle([_]: [SignInCompletedEvent]): void {
    this._router
      .navigate(['auth/login'])
      .then(() => this._alert.open('success', 'login_password_changed'));
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationCreateData } from './creates-integration.dto-port';

export const UPDATES_INTEGRATION_DTO = new InjectionToken<UpdatesIntegrationDtoPort>(
  'UPDATES_INTEGRATION_DTO',
);

export interface IntegrationUpdateDataDto extends IntegrationCreateData {
  id: number;
  merchantId: string | null;
  integrationType: 'owned' | 'switched' | 'non-css';
  cssDomainId: string;
}

export interface UpdatesIntegrationDtoPort {
  updateIntegration(data: IntegrationUpdateDataDto): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerReportSegmentData } from './label-manager-report.query';

export const GETS_LABEL_MANAGER_SEGMENTS_QUERY_PORT =
  new InjectionToken<GetsLabelManagerSegmentsQueryPort>('GETS_LABEL_MANAGER_SEGMENTS_QUERY_PORT');

export interface GetsLabelManagerSegmentsQueryPort {
  getSegments(): Observable<LabelManagerReportSegmentData>;
}

/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { SetsLabelManagerConfigDtoPort } from '../../../../application/ports/secondary/sets-label-manager-config.dto-port';
import { GetsLabelManagerConfigDtoPort } from '../../../../application/ports/secondary/gets-label-manager-config.dto-port';
import { GetsLabelManagerConfigDto } from '../../../../application/ports/secondary/gets-label-manager-config.dto';
import { LabelManagerConfigDto } from '../../../../application/ports/secondary/label-manager-config.dto';
import { SetsLabelManagerConfigDto } from '../../../../application/ports/secondary/sets-label-manager-config.dto';
import { HasData } from '@cobiro/jsonapi';
import { GetsLabelManagerLatestConfigDtoPort } from '../../../../application/ports/secondary/gets-label-manager-latest-config.dto-port';
import { GetsLabelManagerLatestConfigDto } from '../../../../application/ports/secondary/gets-label-manager-latest-config.dto';
import { HttpHeaders } from '@angular/common/http';

export interface LabelManagerConfigAttributes {
  breakEvenROAS: number;
  lookBackWindow: number;
  customLabel:
    | 'custom_label_0'
    | 'custom_label_1'
    | 'custom_label_2'
    | 'custom_label_3'
    | 'custom_label_4';
  analysisFrequency: number | null;
}

@Injectable()
export class HttpLabelManagerConfigService
  implements
    GetsLabelManagerConfigDtoPort,
    SetsLabelManagerConfigDtoPort,
    GetsLabelManagerLatestConfigDtoPort
{
  constructor(private readonly _client: GatewayClient) {}

  getLatestConfig(dto: GetsLabelManagerLatestConfigDto): Observable<LabelManagerConfigDto | null> {
    return this._client
      .get<HasData<LabelManagerConfigAttributes>>(
        `v1/google-css/label-manager/${dto.siteId}/latest-config`,
      )
      .pipe(
        map(({ data }: HasData<LabelManagerConfigAttributes>) =>
          data
            ? {
                id: data.id,
                breakEvenROAS: data.attributes.breakEvenROAS,
                lookBackWindow: data.attributes.lookBackWindow,
                customLabel: data.attributes.customLabel,
                analysisFrequency: data.attributes.analysisFrequency || null,
              }
            : null,
        ),
      );
  }

  getConfig(dto: GetsLabelManagerConfigDto): Observable<LabelManagerConfigDto> {
    const headers = { 'X-COBIRO-CACHE': Date.now().toString() };
    return this._client
      .get<HasData<LabelManagerConfigAttributes>>(
        `v1/google-css/${dto.integrationId}/labelizer-config`,
        {
          additionalHeaders: headers,
        },
      )
      .pipe(
        map(({ data }: HasData<LabelManagerConfigAttributes>) => ({
          id: data.id,
          breakEvenROAS: data.attributes.breakEvenROAS,
          lookBackWindow: data.attributes.lookBackWindow,
          customLabel: data.attributes.customLabel,
          analysisFrequency: data.attributes.analysisFrequency || null,
        })),
      );
  }

  setConfig(dto: SetsLabelManagerConfigDto): Observable<boolean> {
    // return of(true);
    const payload = {
      data: {
        id: dto.integrationId,
        type: 'labelizer-config',
        attributes: {
          breakEvenROAS: dto.config.breakEvenROAS,
          lookBackWindow: dto.config.lookBackWindow,
          customLabel: dto.config.customLabel,
          analysisFrequency: dto.config.analysisFrequency,
          initialConfiguration: dto.config.initialConfiguration,
        },
      },
    };
    return this._client
      .put(`v1/google-css/${dto.integrationId}/labelizer-config`, payload)
      .pipe(map(() => true));
  }
}

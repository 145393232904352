import { InjectionToken } from '@angular/core';
import { SetsClientsListSortCommand } from './sets-clients-list-sort.command';

export interface SetsClientsListSortCommandPort {
  setSort(command: SetsClientsListSortCommand): void;
}

export const SETS_CLIENTS_LIST_SORT_COMMAND = new InjectionToken<SetsClientsListSortCommandPort>(
  'SETS_CLIENTS_LIST_SORT_COMMAND',
);

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CompanyDetailsDto } from './company-details.dto';

export const DELETES_COMPANY_DTO_PORT = new InjectionToken<DeletesCompanyDtoPort>(
  'DELETES_COMPANY_DTO_PORT',
);

export interface DeletesCompanyDtoPort {
  deleteCompany(id: string): Observable<boolean>;
}

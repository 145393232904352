import { AbstractControl, ValidatorFn } from '@angular/forms';

export const cardExpirationValidator: ValidatorFn = (control: AbstractControl) => {
  const today = {
    month: new Date().getMonth() + 1,
    year: Number(String(new Date().getFullYear()).substring(2)),
  };

  if (control.value?.length === 5) {
    const [month, year] = [Number(control.value.slice(0, 2)), Number(control.value.slice(3))];
    return (year === today.year && month < today.month) || year < today.year
      ? { cardExpired: true }
      : null;
  }
  return null;
};

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsAgencyAccessCommandPort {
  getIframeLink(): Observable<string>;
}

export const GETS_AGENCY_ACCESS_COMMAND_PORT = new InjectionToken<GetsAgencyAccessCommandPort>(
  'GETS_AGENCY_ACCESS_COMMAND_PORT',
);

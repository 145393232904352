export enum DISCOUNT_DURATION_TYPES {
  period = 'period',
  forever = 'forever',
  oneTime = 'oneTime',
}

export enum DISCOUNT_DURATION_PERIOD_UNITS {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export class Discount {
  constructor(
    public readonly code: string,
    public readonly type: 'percentage' | 'flat',
    public readonly value: number,
    public readonly duration: DiscountDuration,
  ) {}
}

export interface DiscountDuration {
  type: DISCOUNT_DURATION_TYPES;
  value?: number;
  unit?: DISCOUNT_DURATION_PERIOD_UNITS;
}

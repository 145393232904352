import { NgModule } from '@angular/core';
import { CREATES_PAYMENT_SOURCE_DTO_PORT } from '../../../../application/ports/secondary/creates-payment-source.dto-port';
import { REPLACES_PAYMENT_SOURCE_DTO_PORT } from '../../../../application/ports/secondary/replaces-payment-source.dto-port';
import { HttpTeamCreatePaymentSourceService } from './http-team-create-payment-source.service';

@NgModule({
  providers: [
    HttpTeamCreatePaymentSourceService,
    { provide: CREATES_PAYMENT_SOURCE_DTO_PORT, useExisting: HttpTeamCreatePaymentSourceService },
    { provide: REPLACES_PAYMENT_SOURCE_DTO_PORT, useExisting: HttpTeamCreatePaymentSourceService },
  ],
})
export class HttpTeamCreatePaymentSourceServiceModule {}

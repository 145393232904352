import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CreateProfileProgressIndicatorComponent } from './create-profile-progress-indicator.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CreateProfileProgressIndicatorComponent],
  exports: [CreateProfileProgressIndicatorComponent],
})
export class CreateProfileProgressIndicatorComponentModule {}

import { Inject, Injectable } from '@angular/core';
import { GetsTeamBillingQueryPort } from '../../ports/primary/gets-team-billing.query-port';
import { HasPaymentMethodsQueryPort } from '../../ports/primary/has-payment-methods.query-port';
import { LoadsTeamBillingCommandPort } from '../../ports/primary/loads-team-billing.command-port';
import { BILLING_DATA_STORAGE } from '../../../adapters/secondary/storage/billing-data.storage';
import { InMemoryReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { BillingDataDto } from '../../ports/secondary/billing-data.dto';
import { GETS_CARDS, GetsCards } from '@app.cobiro.com/payment-plans';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { TeamBillingQuery } from '../../ports/primary/team-billing.query';
import {
  GETS_BILLING_DATA_DTO_PORT,
  GetsBillingDataDtoPort,
} from '../../ports/secondary/gets-billing-data-dto.port';
import { GetsPaymentFlowProcessingQueryPort } from '../../ports/primary/gets-payment-flow-processing.query-port';

@Injectable()
export class PaymentPlansTeamBillingState
  implements
    GetsTeamBillingQueryPort,
    HasPaymentMethodsQueryPort,
    LoadsTeamBillingCommandPort,
    GetsPaymentFlowProcessingQueryPort
{
  private readonly _processing$ = new BehaviorSubject<boolean>(false);
  private readonly _hasPaymentMethods$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(BILLING_DATA_STORAGE)
    private readonly _billingStorage: InMemoryReactiveSingleValueStorage<BillingDataDto>,
    @Inject(GETS_CARDS) private readonly _getsCard: GetsCards,
    @Inject(GETS_BILLING_DATA_DTO_PORT) private _getsBillingDataDtoPort: GetsBillingDataDtoPort,
  ) {}

  loadTeamBilling(): void {
    this._processing$.next(true);
    zip(this._getsBillingDataDtoPort.getBillingData(), this._getsCard.get())
      .pipe(
        tap(([billingData, cards]) => {
          this._billingStorage.save(billingData);
          this._hasPaymentMethods$.next(cards.length > 0);
        }),
        finalize(() => {
          this._processing$.next(false);
        }),
      )
      .subscribe();
  }

  getTeamBilling(): Observable<TeamBillingQuery> {
    return this._billingStorage.select().pipe(
      filter(billing => !!billing),
      map(data => ({ countryCode: data.countryCode, vatNumber: data.vatNumber })),
    );
  }

  hasPaymentMethods(): Observable<boolean> {
    return this._hasPaymentMethods$.asObservable();
  }

  getPaymentFlowProcessing(): Observable<boolean> {
    return this._processing$.asObservable();
  }
}

import { SORT_ORDER } from './sort-order.enum';

export class SortVO {
  public constructor(private readonly _value: [string, SORT_ORDER]) {
    if (
      _value.length > 2 ||
      typeof _value[0] !== 'string' ||
      _value[0] === '' ||
      (_value[1] !== SORT_ORDER.DESC && _value[1] !== SORT_ORDER.ASC)
    ) {
      throw new Error('Invalid sort argument');
    }
  }

  toString(): string {
    return this._value.join(':');
  }

  value(): [string, SORT_ORDER] {
    return this._value;
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ProductMarketingQuery } from './product-marketing.query';

export interface GetsProductMarketingListQuery {
  getAllProductsMarketing(product: string): Observable<ProductMarketingQuery>;
}

export const GETS_PRODUCT_MARKETING_LIST_QUERY = new InjectionToken<GetsProductMarketingListQuery>(
  'GETS_PRODUCT_MARKETING_LIST_QUERY',
);

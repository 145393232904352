import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { EmailConsentQuery } from './email-consent.query';

export interface GetsEmailConsentsQueryPort {
  getEmailConsent(): Observable<EmailConsentQuery[]>;
}

export const GETS_EMAIL_CONSENTS_QUERY_PORT = new InjectionToken<GetsEmailConsentsQueryPort>(
  'GETS_EMAIL_CONSENTS_QUERY_PORT',
);

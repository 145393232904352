/* eslint-disable complexity */
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  GetsUsersListQueryPort,
  GETS_USERS_LIST_QUERY,
} from '../../../../../application/ports/primary/gets-users-list.query-port';
import {
  LoadUsersCommandPort,
  LOAD_USERS_COMMAND,
} from '../../../../../application/ports/primary/load-users.command-port';
import { UserQuery } from '../../../../../application/ports/primary/user.query';
import {
  RESENDS_INVITATION_COMMAND,
  ResendsInvitationCommandPort,
} from '../../../../../application/ports/primary/resends-invitation.command-port';
import { ResendsInvitationCommand } from '../../../../../application/ports/primary/resends-invitation.command';

@Component({
  selector: 'lib-cobiro-pro-user-management-table',
  templateUrl: './user-management-table.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProUserManagementTableComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  readonly columns = [
    'cobiro_pro_users_table_user_name',
    'cobiro_pro_users_table_email',
    'cobiro_pro_users_table_role',
    'cobiro_pro_users_table_workspaces',
    // 'cobiro_pro_users_table_access',
    'cobiro_pro_users_table_delete',
  ];
  readonly users$: Observable<UserQuery[]> = this._getsUserListQuery.getUserListQuery();

  constructor(
    @Inject(LOAD_USERS_COMMAND)
    private readonly _loadUsersCommand: LoadUsersCommandPort,
    @Inject(GETS_USERS_LIST_QUERY)
    private readonly _getsUserListQuery: GetsUsersListQueryPort,
    @Inject(RESENDS_INVITATION_COMMAND)
    private readonly _resendsInvitationCommand: ResendsInvitationCommandPort,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this._loadUsersCommand.loadUsers().pipe(takeUntil(this._ngDestroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }

  onDeleteButtonClicked(user: UserQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        delete: user.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  onEditButtonClicked(user: UserQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        edit: user.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  onResendInvitationClicked(user: UserQuery): void {
    this._resendsInvitationCommand
      .resendInvitation(new ResendsInvitationCommand(user.id))
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }
}

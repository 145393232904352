import { NgModule } from '@angular/core';
import { GETS_CURRENT_PLAN_QUERY_PORT } from '../../../application/ports/primary/gets-current-plan.query-port';
import { GetsCurrentPlanQueryGetter } from './gets-current-plan.query-getter';

@NgModule({
  providers: [
    GetsCurrentPlanQueryGetter,
    { provide: GETS_CURRENT_PLAN_QUERY_PORT, useExisting: GetsCurrentPlanQueryGetter },
  ],
})
export class GetsCurrentPlanQueryGetterModule {}

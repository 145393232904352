import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsLabelManagerLatestConfigDto } from './gets-label-manager-latest-config.dto';
import { LabelManagerConfigDto } from './label-manager-config.dto';

export const GETS_LABEL_MANAGER_LATEST_CONFIG_DTO_PORT =
  new InjectionToken<GetsLabelManagerLatestConfigDtoPort>(
    'GETS_LABEL_MANAGER_LATEST_CONFIG_DTO_PORT',
  );

export interface GetsLabelManagerLatestConfigDtoPort {
  getLatestConfig(dto: GetsLabelManagerLatestConfigDto): Observable<LabelManagerConfigDto | null>;
}

import { NgModule } from '@angular/core';
import { PasswordFormComponent } from './password-form.component';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [CommonModule, LanguageModule, ReactiveFormsModule, MatIconModule, MatInputModule],
  exports: [PasswordFormComponent],
  declarations: [PasswordFormComponent],
})
export class PasswordFormModule {}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { APPLICATION_BUS, Dispatcher, ApplicationEvent } from '@cobiro/eda';
import { map, Observable, switchMap, take, tap } from 'rxjs';
import { CurrentWorkspaceChangedEvent } from '../../../application/events/current-workspace-changed.event';
import {
  SELECTS_WORKSPACES_CONTEXT_STORAGE,
  SelectsWorkspacesContextStoragePort,
} from '../../../application/ports/secondary/context/selects-workspaces-context.storage-port';
import { WorkspacesContext } from '../../../application/ports/secondary/context/workspaces-context';
import { WorkspaceDto } from '../../../application/ports/secondary/dto/workspace.dto';

@Injectable()
export class LoadCurrentWorkspaceResolver implements Resolve<WorkspaceDto> {
  constructor(
    @Inject(APPLICATION_BUS) private _applicationBus: Dispatcher<ApplicationEvent>,
    @Inject(SELECTS_WORKSPACES_CONTEXT_STORAGE)
    private readonly _selectsWorkspacesContextStoragePort: SelectsWorkspacesContextStoragePort,
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot): Observable<WorkspaceDto> {
    return this._selectsWorkspacesContextStoragePort.select().pipe(
      take(1),
      map((context: WorkspacesContext) => {
        const currentWorkspace = context.list.find(item => item.id === snapshot.paramMap.get('id'));
        this._applicationBus.dispatch(
          new CurrentWorkspaceChangedEvent(currentWorkspace as WorkspaceDto),
        );
        return currentWorkspace;
      }),
    );
  }
}

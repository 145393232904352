import { PaymentPlan, PaymentPlanData, PaymentPlanPeriod } from './payment-plan';
import { PRICES_DATA } from './payment-plan-prices.vo.stub';

export const makePaymentData: (name: string) => PaymentPlanData = (name: string) => {
  return {
    name,
    goal: 'Get better',
    features: [
      { id: 'website', value: 'Custom', additionalInformation: false },
      { id: 'webshop', value: 'Custom', additionalInformation: false },
      { id: 'googleAds', value: 'Unlimited', additionalInformation: true },
      { id: 'domains', value: 'Premium Domains', additionalInformation: false },
      { id: 'account', value: 'Custom', additionalInformation: false },
    ],
    prices: PRICES_DATA.values,
  };
};

export const makePaymentPlan = (name: string, selectedPlan = PaymentPlanPeriod.MONTHLY) => {
  return PaymentPlan.fromPlanData(makePaymentData(name), selectedPlan);
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerLinkingResultComponent } from './label-manager-linking-result.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LabelManagerLinkingResultComponent],
  imports: [CommonModule, LanguageModule, ClipboardModule, MatIconModule],
  exports: [LabelManagerLinkingResultComponent],
})
export class LabelManagerLinkingResultComponentModule {}

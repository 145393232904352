import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscountDto } from '../../secondary/dto/payments/discount.dto';

export interface GetsDiscountCommandPort {
  getDiscount(): Observable<DiscountDto>;
}

export const GETS_DISCOUNT_COMMAND = new InjectionToken<GetsDiscountCommandPort>(
  'GETS_DISCOUNT_COMMAND',
);

/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { UUID } from '@app.cobiro.com/core/events';
import { HasData } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CancelsIntegrationDtoPort } from '../../../../application/ports/secondary/cancels-integration.dto-port';
import {
  CreatesIntegrationDtoPort,
  IntegrationCreateData,
} from '../../../../application/ports/secondary/creates-integration.dto-port';
import { GetsIntegrationErrorDtoPort } from '../../../../application/ports/secondary/gets-integration-error.dto-port';
import { GetsIntegrationLinkStatusDtoPort } from '../../../../application/ports/secondary/gets-integration-link-status.dto-port';
import { GetsIntegrationDtoPort } from '../../../../application/ports/secondary/gets-integration.dto-port';
import { IntegrationErrorDto } from '../../../../application/ports/secondary/integration-error.dto';
import { IntegrationDto } from '../../../../application/ports/secondary/integration.dto';
import { LinksIntegrationDtoPort } from '../../../../application/ports/secondary/links-integration.dto-port';
import { SwitchCssDomainDto } from '../../../../application/ports/secondary/switches-css-domain.dto';
import { SwitchesIntegrationDto } from '../../../../application/ports/secondary/switches-integration.dto';
import { SwitchesIntegrationDtoPort } from '../../../../application/ports/secondary/switches-integration.dto-port';
import {
  IntegrationUpdateDataDto,
  UpdatesIntegrationDtoPort,
} from '../../../../application/ports/secondary/updates-integration.dto-port';
import { UpgradesIntegrationDto } from '../../../../application/ports/secondary/upgrades-integration.dto';
import { UpgradesIntegrationDtoPort } from '../../../../application/ports/secondary/upgrades-integration.dto-port';

@Injectable()
export class HttpIntegrationService
  implements
    CancelsIntegrationDtoPort,
    CreatesIntegrationDtoPort,
    GetsIntegrationDtoPort,
    GetsIntegrationErrorDtoPort,
    GetsIntegrationLinkStatusDtoPort,
    LinksIntegrationDtoPort,
    SwitchesIntegrationDtoPort,
    UpdatesIntegrationDtoPort,
    UpgradesIntegrationDtoPort,
    SwitchesIntegrationDtoPort
{
  constructor(private readonly _gatewayClient: GatewayClient) {}

  upgradeIntegration(dto: UpgradesIntegrationDto): Observable<boolean> {
    const body = {
      data: {
        type: 'non-css-integration',
        attributes: {
          integrationId: dto.integrationId,
        },
      },
    };

    return this._gatewayClient
      .post(`v1/google-css/${dto.integrationId}/upgrade`, body)
      .pipe(map(() => true));
  }

  cancelIntegration(siteId: string): Observable<void> {
    return this._gatewayClient
      .delete(`v1/google-css/comlyn-integration/${siteId}`)
      .pipe(map(() => void 0));
  }

  createIntegration(data: IntegrationCreateData): Observable<boolean> {
    const body: HasData<IntegrationCreateData> = {
      data: {
        id: new UUID().value,
        type: 'comlyn-integration',
        attributes: data,
      },
    };
    return this._gatewayClient.post('v1/google-css/comlyn-integration', body).pipe(map(() => true));
  }

  getIntegration(siteId: string): Observable<IntegrationDto> {
    return this._gatewayClient
      .get<
        HasData<{
          adsId: string;
          integrationId: string;
          websiteName: string;
          email: string;
          merchantId: string | null;
          isPaused: boolean;
          status: 'pending' | 'success' | 'missing';
          integrationType: 'owned' | 'switched';
          cssDomainId: string;
        }>
      >(`v1/google-css/comlyn-integration/${siteId}`)
      .pipe(
        map(response => ({
          id: +response.data.id,
          adsId: response.data.attributes.adsId,
          integrationId: response.data.attributes.integrationId,
          email: response.data.attributes.email,
          businessName: response.data.attributes.websiteName,
          merchantId: response.data.attributes.merchantId,
          isPaused: response.data.attributes.isPaused,
          status: response.data.attributes.status,
          integrationType: response.data.attributes.integrationType,
          cssDomainId: response.data.attributes.cssDomainId,
        })),
      );
  }

  getIntegrationError(siteId: string): Observable<IntegrationErrorDto> {
    return this._gatewayClient
      .get<HasData<{ code: string }>>(`v1/google-css/comlyn-integration/${siteId}/error`)
      .pipe(map(response => ({ code: response?.data?.attributes?.code ?? null })));
  }

  getIntegrationLinkStatus(siteId: string): Observable<{ isLinked: boolean }> {
    return this._gatewayClient
      .get<HasData<{ isLinked: boolean }>>(
        `v1/google-css/comlyn-integration/${siteId}/ads-account-link-status`,
      )
      .pipe(map(response => ({ isLinked: response.data.attributes.isLinked })));
  }

  linkIntegration(siteId: string): Observable<void> {
    return this._gatewayClient.post<void>(
      `v1/google-css/comlyn-integration/${siteId}/link-ads-account`,
      {},
    );
  }

  switchAccount(dto: SwitchesIntegrationDto): Observable<void> {
    const body: HasData<SwitchesIntegrationDto> = {
      data: {
        type: 'switch-integration',
        attributes: {
          siteId: dto.siteId,
          adsId: dto.adsId,
          websiteName: dto.websiteName,
          managerEmail: dto.managerEmail,
          merchantId: dto.merchantId,
          cssDomainId: dto.cssDomainId,
        },
      },
    };

    return this._gatewayClient.post<void>(`v1/google-css/switch-integration`, body);
  }

  updateIntegration(data: IntegrationUpdateDataDto): Observable<void> {
    const body: HasData<
      IntegrationCreateData & {
        merchantId: string | null;
        integrationType: 'owned' | 'switched' | 'non-css';
      }
    > = {
      data: {
        id: `${data.id}`,
        type: 'comlyn-integration',
        attributes: {
          adsId: data.adsId,
          siteId: data.siteId,
          websiteName: data.websiteName,
          managerEmail: data.managerEmail,
          merchantId: data.merchantId,
          integrationType: data.integrationType,
          cssDomainId: data.cssDomainId,
        },
      },
    };

    return this._gatewayClient
      .put(`v2/google-css/comlyn-integration/${data.siteId}`, body)
      .pipe(map(() => void 0));
  }
  switchCssDomain(dto: SwitchCssDomainDto): Observable<void> {
    const body: HasData<{
      siteId: string;
      cssDomainId: string;
    }> = {
      data: {
        type: 'switch-integration',
        attributes: {
          siteId: dto.siteId,
          cssDomainId: dto.cssDomainId,
        },
      },
    };
    return this._gatewayClient
      .post('v1/google-css/local-switch-integration', body)
      .pipe(map(() => void 0));
  }
}

import { NgModule } from '@angular/core';
import { InMemoryService } from './in-memory.service';
import { GETS_COBIRO_PRO_AVAILABLE_TODOS_DTO } from '../../application/ports/secondary/gets-available-todos.dto-port';
import { GETS_PRODUCT_LIST_DTO } from '../../application/ports/secondary/gets-product-list.dto-port';
import { GETS_PRODUCT_MARKETING_LIST_DTO } from '../../application/ports/secondary/gets-product-marketing-list.dto-port';

@NgModule({
  providers: [
    InMemoryService,
    {
      provide: GETS_COBIRO_PRO_AVAILABLE_TODOS_DTO,
      useExisting: InMemoryService,
    },
    {
      provide: GETS_PRODUCT_LIST_DTO,
      useExisting: InMemoryService,
    },
    {
      provide: GETS_PRODUCT_MARKETING_LIST_DTO,
      useExisting: InMemoryService,
    },
  ],
})
export class InMemoryServiceModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMetadataQuery } from './app-metadata.query';

export const GETS_COBIRO_CSS_META_QUERY_PORT = new InjectionToken<GetsCobiroCssMetaQueryPort>(
  'GETS_COBIRO_CSS_META_QUERY_PORT',
);

export interface GetsCobiroCssMetaQueryPort {
  getAppMeta(): Observable<AppMetadataQuery>;
}

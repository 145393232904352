/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { InitsLMSimulationDtoPort } from '../../../../application/ports/secondary/inits-lm-simulation.dto-port';
import { InitsLMSimulationDto } from '../../../../application/ports/secondary/inits-lm-simulation.dto';

@Injectable()
export class HttpLabelManagerSimulationService implements InitsLMSimulationDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  initSimulation(dto: InitsLMSimulationDto): Observable<boolean> {
    const payload = {
      data: {
        type: 'supplemental-feed',
        id: dto.integrationId,
      },
    };
    return this._client.post<boolean>(
      `v1/google-css/${dto.integrationId}/labelizer-simulation`,
      payload,
    );
  }
}

<div class="d-flex justify-content-end cs-mt-10">
  <button
    (click)="onEditIntegrationDetailsClicked()"
    class="cs-btn cs-mr-10"
    data-selector="css-enter-integration-edit-mode-btn"
    type="button"
  >
    {{ 'cobiro_css_edit_integration_btn' | language }}
  </button>
  <button
    *ngIf="(integrationQuery$ | async)?.integrationType !== 'switched'"
    (click)="onCancelIntegrationClicked()"
    class="cs-btn cs-btn-error"
    data-selector="css-cancel-integration-btn"
    type="button"
  >
    {{ 'cobiro_css_cancel_integration_btn' | language }}
  </button>
</div>

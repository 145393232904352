import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage, LocalStorageInterface } from '@app.cobiro.com/core/storage';

// In Webstorm search, this component will be shown as 'unused' - that is not true.
// It is used for Google account linking in Google Search, Google CSS and Google Shopping
// TODO: DO NOT REMOVE
@Component({
  selector: 'lib-redirect-google',
  template: '',
})
export class RedirectGoogleComponent implements OnInit {
  googleLinkStorage: LocalStorageInterface;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorage,
    private router: Router,
  ) {
    this.googleLinkStorage = this.localStorageService.init('google-link');
  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParams;
    this.googleLinkStorage.set(params);
    this.router.navigate([decodeURIComponent(params.state)], {
      queryParams: { utm_nooverride: 1, code: params.code, error: params.error },
    });
  }
}

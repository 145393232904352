import { NgModule } from '@angular/core';
import { RESENDS_CODE_DTO } from '../../application/ports/secondary/resends-code.dto-port';
import { HttpResendsCodeService } from './http-resends-code.service';

@NgModule({
  providers: [
    HttpResendsCodeService,
    {
      provide: RESENDS_CODE_DTO,
      useExisting: HttpResendsCodeService,
    },
  ],
})
export class HttpResendsCodeServiceModule {}

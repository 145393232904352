import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SetupProgressIndicatorComponent } from './setup-progress-indicator.component';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [SetupProgressIndicatorComponent],
  exports: [SetupProgressIndicatorComponent],
})
export class SetupProgressIndicatorComponentModule {}

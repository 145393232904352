import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { CobiroProTeamMemberInvitedEvent } from '@app.cobiro.com/core/events';
import {
  INCREMENTS_TEAM_MEMBERS_COUNT_COMMAND,
  IncrementsTeamMembersCountCommandPort,
} from '../../../application/ports/primary/increments-team-members-count-command.port';

@Injectable()
export class ProTeamSwitcherTeamMemberInvitedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProTeamMemberInvitedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(INCREMENTS_TEAM_MEMBERS_COUNT_COMMAND)
    private readonly _incrementsTeamMembersCountCommandPort: IncrementsTeamMembersCountCommandPort,
  ) {}

  handle([event]: [CobiroProTeamMemberInvitedEvent]): void {
    this._incrementsTeamMembersCountCommandPort.incrementTeamMembersCount(event.teamId);
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GetsProductMarketingListQuery,
  GETS_PRODUCT_MARKETING_LIST_QUERY,
} from '../../../../../../lib/application/ports/primary/gets-product-marketing-list.query';
import {
  OpenSetupFlowCommandPort,
  OPEN_SETUP_FLOW_COMMAND,
} from '../../../../../../lib/application/ports/primary/open-setup-flow.command-port';
import { ProductMarketingQuery } from '../../../../../../lib/application/ports/primary/product-marketing.query';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-cobiro-pro-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInfoComponent implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  readonly product$: Observable<ProductMarketingQuery | null> =
    this._getsProductMarketingListQuery.getAllProductsMarketing(this.data.product);

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { product: string },
    @Inject(GETS_PRODUCT_MARKETING_LIST_QUERY)
    private readonly _getsProductMarketingListQuery: GetsProductMarketingListQuery,
    @Inject(OPEN_SETUP_FLOW_COMMAND)
    private readonly _openSetupFlowCommandPort: OpenSetupFlowCommandPort,
    private readonly _dialogRef: MatDialogRef<ProductInfoComponent>,
  ) {}

  goToProduct(product: string): void {
    this._openSetupFlowCommandPort
      .openSetupFlow(product)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this._dialogRef.close());
  }

  onFormClosed(): void {
    this._dialogRef.close();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}

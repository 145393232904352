import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const SHOW_START_PAGE_QUERY_PORT = new InjectionToken<ShowStartPageQueryPort>(
  'SHOW_START_PAGE_QUERY_PORT',
);

export interface ShowStartPageQueryPort {
  showStartPage(): Observable<boolean>;
}

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { GETS_LANGUAGE, GetsLanguage } from '../../application/port/gets-language';

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  constructor(@Inject(GETS_LANGUAGE) private _languageService: GetsLanguage) {}

  transform(value: string, args?: string | number | (number | string)[]): any {
    return value && this._languageService.get(value, args);
  }
}

import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CobiroProClientSelectedEvent, UUID } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  concatMap,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DisplayClientInfoComponent } from '../../adapters/primary/ui/display-client-info/display-client-info.component';
// eslint-disable-next-line max-len
import { ClientDeleteConfirmationModalComponent } from '../../adapters/primary/ui/modals/client-delete-confirmation-modal/client-delete-confirmation-modal.component';
// eslint-disable-next-line max-len
import { ClientNotDeletableModalComponent } from '../../adapters/primary/ui/modals/client-not-deletable-modal/client-not-deletable-modal.component';
import { ClientsListLoadedEvent } from '../events/clients-list-loaded.event';
import { AbortsEditClientCommandPort } from '../ports/primary/aborts-edit-client-command.port';
import { ClientLoadingStateQuery } from '../ports/primary/client-loading-state.query';
import { ClientQuery } from '../ports/primary/client.query';
import { CreateClientCommandPort } from '../ports/primary/create-client-command.port';
import { CreateClientCommand } from '../ports/primary/create-client.command';
import { DeletesClientCommand } from '../ports/primary/deletes-client.command';
import { DeletesClientCommandPort } from '../ports/primary/deletes-client.command-port';
import { DisplaysClientInfoCommand } from '../ports/primary/displays-client-info.command';
import { DisplaysClientInfoCommandPort } from '../ports/primary/displays-client-info.command-port';
import { EditClientCommand } from '../ports/primary/edit-client.command';
import { EditsClientCommandPort } from '../ports/primary/edits-client-command.port';
import { GetsCurrentClientLoadingStateQueryPort } from '../ports/primary/gets-current-client-loading-state.query-port';
import { GetsEditClientQueryPort } from '../ports/primary/gets-edit-client-query.port';
import { IsEditActiveQueryPort } from '../ports/primary/is-edit-active-query.port';
import {
  LOAD_CLIENTS_COMMAND,
  LoadClientsCommandPort,
} from '../ports/primary/load-clients-command.port';
import { SaveClientCommand } from '../ports/primary/save-client.command';
import { SavesClientCommandPort } from '../ports/primary/saves-client-command.port';
import {
  SelectClientData,
  SelectsClientCommandPort,
} from '../ports/primary/selects-client-command';
import { StoresClientsListCommandPort } from '../ports/primary/stores-clients-list.command-port';
import { ADDS_CLIENT_DTO, AddsClientDtoPort } from '../ports/secondary/adds-client.dto-port';
import {
  ADDS_CLIENT_SITE_DTO,
  AddsClientSiteDtoPort,
} from '../ports/secondary/adds-client-site.dto-port';
import {
  CLIENT_ADDED_DISPATCHER,
  ClientAddedDispatcherPort,
} from '../ports/secondary/client-added.dispatcher-port';
import { ClientDTO } from '../ports/secondary/client.dto';
import {
  DELETES_CLIENT_DTO,
  DeletesClientDtoPort,
} from '../ports/secondary/deletes-client.dto-port';
import { SETS_CLIENT_DTO, SetsClientDtoPort } from '../ports/secondary/sets-client.dto-port';

@Injectable()
export class ClientsState
  implements
    CreateClientCommandPort,
    EditsClientCommandPort,
    GetsEditClientQueryPort,
    IsEditActiveQueryPort,
    AbortsEditClientCommandPort,
    SavesClientCommandPort,
    SelectsClientCommandPort,
    StoresClientsListCommandPort,
    GetsCurrentClientLoadingStateQueryPort,
    DeletesClientCommandPort,
    DisplaysClientInfoCommandPort
{
  // TODO: We can extend loading state with real state like: FETCHING, CREATING, IDLE
  // to handle more cases, for now it is just isLoading: boolean

  private _teamIdSubject$: Subject<string> = new ReplaySubject();
  private _allClientsSubject$: ReplaySubject<ClientDTO[]> = new ReplaySubject<ClientDTO[]>(1);
  private _currentClientIdSubject$: Subject<string> = new BehaviorSubject(null);
  private _loadingStateSubject$: Subject<boolean> = new BehaviorSubject(false);
  private _currentCliendId$ = this._currentClientIdSubject$.asObservable();
  private _teamId$ = this._teamIdSubject$.asObservable();
  private _currentClientDeleting$ = new BehaviorSubject<string | null>(null);

  constructor(
    @Inject(ADDS_CLIENT_DTO) private _addsClient: AddsClientDtoPort,
    @Inject(SETS_CLIENT_DTO) private _setsClient: SetsClientDtoPort,
    @Inject(ADDS_CLIENT_SITE_DTO) private _addsClientSite: AddsClientSiteDtoPort,
    @Inject(APPLICATION_BUS)
    private _dispatcher: Dispatcher<CobiroProClientSelectedEvent | ClientsListLoadedEvent>,
    @Inject(DELETES_CLIENT_DTO) private _deletesClient: DeletesClientDtoPort,
    @Inject(LOAD_CLIENTS_COMMAND) private _loadClientsCommand: LoadClientsCommandPort,
    @Inject(CLIENT_ADDED_DISPATCHER) private _clientAddedDispatcher: ClientAddedDispatcherPort,
    private _alert: HuiAlert,
    private _matDialog: MatDialog,
  ) {}

  createClient(command: CreateClientCommand): void {
    const clientId = new UUID().value; // client side generated id for client
    const sitePublicId = new UUID().value; // client side generated id for site
    this._loadingStateSubject$.next(true);
    this._teamId$
      .pipe(
        take(1),
        concatMap(teamId => {
          return this._addsClient
            .add({
              id: clientId,
              siteId: sitePublicId,
              teamId,
              companyName: command.companyName,
              contactPerson: command.contactPerson,
              contactEmail: command.contactEmail,
              contactPhone: command.contactPhone,
              url: command.url,
              avatar: command.avatar,
              source: command.createBrexSite ? 'b-rex' : 'outside-cobiro',
              archived: false,
            })
            .pipe(
              tap(() =>
                this._alert.open('success', 'cobiro_pro_client_create_form_submit_success'),
              ),
              map(() => teamId),
            );
        }),
        switchMap(teamId =>
          !command.createBrexSite ? this._addSiteWithPublicId(sitePublicId, command.url) : of(null),
        ),
        withLatestFrom(this._teamId$),
        tap(([siteId, teamId]) =>
          this._addClientToList(clientId, sitePublicId, siteId, teamId, command),
        ),
      )
      .subscribe();
  }

  displayClientInfo({ id }: DisplaysClientInfoCommand): Observable<void> {
    return this._allClientsSubject$.pipe(
      map(clients => clients.find(client => client.id === id)),
      filter(Boolean),
      map(clientDto => ClientQuery.fromClientDTO(clientDto)),
      switchMap(clientQuery =>
        this._matDialog
          .open(DisplayClientInfoComponent, {
            data: {
              client: clientQuery,
            },
            panelClass: 'cs-mat-dialog-right',
            height: '100%',
            width: '440px',
            scrollStrategy: new NoopScrollStrategy(),
            autoFocus: false,
            position: { right: '0', top: '0' },
          })
          .afterOpened(),
      ),
    );
  }

  getEditClientQuery(): Observable<ClientQuery> {
    return combineLatest([this._allClientsSubject$, this._currentCliendId$]).pipe(
      map(([clientDTOs, clientToEdit]) => {
        const clientDTO = clientDTOs.find(dto => dto.id === clientToEdit);
        if (!clientDTO) {
          return undefined;
        }

        return ClientQuery.fromClientDTO(clientDTO);
      }),
    );
  }

  getCurrentClientLoadingStateQuery(): Observable<ClientLoadingStateQuery> {
    return this._loadingStateSubject$
      .asObservable()
      .pipe(map(data => new ClientLoadingStateQuery(data)));
  }

  isEditActive(): Observable<boolean> {
    return this._currentCliendId$.pipe(map(id => !!id));
  }

  editClient(command: EditClientCommand): void {
    this._currentClientIdSubject$.next(command.id);
  }

  abortEditClient(): void {
    this._currentClientIdSubject$.next(null);
  }

  saveClient(command: SaveClientCommand): void {
    this._setsClient
      .set(command)
      .pipe(
        tap(() => {
          this.abortEditClient();
          this._loadingStateSubject$.next(true);
          this._alert.open('success', 'cobiro_pro_client_edit_form_submit_success');
        }),
        switchMap(() => this._loadClientsCommand.loadClients({ teamId: command.teamId })),
      )
      .subscribe(() => {});
  }

  storeClientsList(clients: ClientDTO[], teamId: string) {
    this._teamIdSubject$.next(teamId);
    this._setClientList(clients);
  }

  selectClient(selectClientData: SelectClientData) {
    this._teamId$
      .pipe(
        take(1),
        filter(teamId => !!teamId),
      )
      .subscribe(teamId => {
        this._dispatcher.dispatch(
          new CobiroProClientSelectedEvent({
            id: selectClientData.id,
            teamId,
            siteId: selectClientData.siteId,
            name: selectClientData.name,
            avatar: selectClientData.avatar,
            sitePublicId: selectClientData.sitePublicId,
          }),
        );
      });
  }

  deleteClient(command: DeletesClientCommand): Observable<void> {
    return command.plan === 'free'
      ? this._startDeleteClientProcess({ id: command.id, plan: command.plan })
      : this._showNotDeletableClientModal();
  }

  private _startDeleteClientProcess(command: DeletesClientCommand): Observable<void> {
    return this._matDialog
      .open(ClientDeleteConfirmationModalComponent)
      .afterClosed()
      .pipe(
        concatMap(result =>
          result ? this._deleteClient({ id: command.id, plan: command.plan }) : of(void 0),
        ),
      );
  }

  private _addSiteWithPublicId(sitePublicId: string, url: string): Observable<string> {
    return this._addsClientSite.add({ id: sitePublicId, url: url });
  }

  // TODO: refactor arguments with application layer object
  private _addClientToList(
    id: string,
    sitePublicId: string,
    siteId: string | null,
    teamId: string,
    createCommand: CreateClientCommand,
  ) {
    const newClient: ClientDTO = {
      id,
      siteId,
      sitePublicId,
      cssIntegrationStatus: null,
      merchantSiteId: null,
      companyName: createCommand.companyName,
      url: createCommand.url,
      teamId: teamId,
      contactPerson: createCommand.contactPerson,
      contactEmail: createCommand.contactEmail,
      contactPhone: createCommand.contactPhone,
      avatar: createCommand.avatar,
      source: createCommand.createBrexSite ? 'b-rex' : 'outside',
      plan: 'free',
      installedApps: [],
      archived: false,
    };
    this._allClientsSubject$.pipe(take(1)).subscribe(clients => {
      this._setClientList([...clients, newClient]);
    });

    this._clientAddedDispatcher.dispatch(newClient);
  }

  private _setClientList(clientList: ClientDTO[]) {
    this._allClientsSubject$.next(clientList);
    this._loadingStateSubject$.next(false);
  }

  private _showNotDeletableClientModal(): Observable<void> {
    return this._matDialog
      .open(ClientNotDeletableModalComponent)
      .afterClosed()
      .pipe(map(() => void 0));
  }

  private _deleteClient(command: DeletesClientCommand): Observable<void> {
    this._currentClientDeleting$.next(command.id);

    return this._deletesClient.deleteClient({ id: command.id, plan: command.plan }).pipe(
      finalize(() => this._currentClientDeleting$.next(null)),
      tap({
        next: () => this._alert.open('success', 'cobiro_pro_client_deleted_success'),
        error: () => this._alert.open('error', 'cobiro_pro_client_deleted_failed'),
      }),
      switchMap(() => this._allClientsSubject$.pipe(take(1))),
      tap((clients: ClientDTO[]) => {
        let clientTeamId: string;

        const updatedClients: ClientDTO[] = clients.map(client => {
          if (client.id === command.id) {
            clientTeamId = client.teamId;
            return { ...client, archived: true };
          }

          return client;
        });
        this._allClientsSubject$.next([...updatedClients]);
        this._dispatcher.dispatch(new ClientsListLoadedEvent(updatedClients, clientTeamId));
      }),
      map(() => void 0),
    );
  }
}

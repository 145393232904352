import { Inject, Injectable } from '@angular/core';
import { GetsCountriesDtoPort } from '../../../../application/ports/secondary/dto/settings/gets-countries.dto-port';
import { CountryDto } from '../../../../application/ports/secondary/dto/settings/country.dto';
import { Observable } from 'rxjs';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HttpGetsCountriesService implements GetsCountriesDtoPort {
  private readonly _baseUrl;
  constructor(
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
    private readonly _httpClient: HttpClient,
  ) {
    this._baseUrl = this._getsConfig.get('cdn');
  }

  getCountries(): Observable<CountryDto[]> {
    return this._httpClient.get<CountryDto[]>(`${this._baseUrl}/v1/contents/countries/index.json`);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent } from './user-management.component';
import { CobiroProUserManagementControlsComponentModule } from './user-management-controls/user-management-controls.component-module';
import { CobiroProUserManagementTableComponentModule } from './user-management-table/user-management-table.component-module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UserManagementComponent],
  imports: [
    CobiroProUserManagementTableComponentModule,
    CobiroProUserManagementControlsComponentModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: UserManagementComponent }]),
  ],
  exports: [UserManagementComponent],
})
export class UserManagementComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesIntegrationCommand } from './creates-integration.command';

export const CREATES_INTEGRATION_COMMAND = new InjectionToken<CreatesIntegrationCommandPort>(
  'CREATES_INTEGRATION_COMMAND',
);

export interface CreatesIntegrationCommandPort {
  createIntegration(command: CreatesIntegrationCommand): Observable<void>;
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Injectable } from '@angular/core';
import { UserAuthorizedEvent } from '@app.cobiro.com/core/events';
import { UserState } from '../application/user.state';

@Injectable()
export class UserAuthorizedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserAuthorizedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _userState: UserState) {}

  handle([event]: [UserAuthorizedEvent]) {
    this._userState.setAuthTokens(event.accessToken, event.refreshToken);
  }
}

import { NgModule } from '@angular/core';
import { GETS_CSS_MERCHANT_IDS_DTO_PORT } from '../../../../application/ports/secondary/gets-css-merchant-ids.dto-port';
import { HttpCssMerchantIdsService } from './http-css-merchant-ids.service';

@NgModule({
  providers: [
    HttpCssMerchantIdsService,
    { provide: GETS_CSS_MERCHANT_IDS_DTO_PORT, useExisting: HttpCssMerchantIdsService },
  ],
})
export class HttpCssMerchantIdsServiceModule {}

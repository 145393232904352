import { NgModule } from '@angular/core';
import { HttpTeamsService } from './http-teams.service';
import { ADDS_TEAM_DTO } from '../../../application/ports/secondary/adds-team-dto.port';
import { GETS_ALL_TEAMS_DTO } from '../../../application/ports/secondary/gets-all-team-dto.port';

@NgModule({
  declarations: [],
  providers: [
    HttpTeamsService,
    { provide: ADDS_TEAM_DTO, useExisting: HttpTeamsService },
    { provide: GETS_ALL_TEAMS_DTO, useExisting: HttpTeamsService },
  ],
  exports: [],
})
export class HttpTeamsServiceModule {}

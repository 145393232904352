import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GOOGLE_MAPS_KEY } from '../google-maps-key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GoogleMapsGeocodeQuery } from '../../../primary/google-maps-geocode.query';
import { GoogleApiMapsResponse, GoogleMapsLocation } from '../google-maps-api-interfaces';
import { GetsGoogleMapGeocodeQueryAdapter } from '../../../gets-google-map-geocode.adapter';

@Injectable()
export class GoogleMapsGetsGeocodeService implements GetsGoogleMapGeocodeQueryAdapter {
  private readonly _url: string; // TODO: Write test

  constructor(private _http: HttpClient, @Inject(GOOGLE_MAPS_KEY) private _mapKey: string) {
    this._url = 'https://maps.googleapis.com/maps/api/geocode/json';
  }

  getGeocode(address: string): Observable<GoogleMapsGeocodeQuery> {
    return this._http
      .get<GoogleApiMapsResponse>(`${this._url}?address=${address}&key=${this._mapKey}`)
      .pipe(
        map(response => response?.results?.shift()),
        map(
          (response: GoogleMapsLocation | undefined) =>
            new GoogleMapsGeocodeQuery(
              response.geometry.location.lat,
              response.geometry.location.lng,
              response.geometry.bounds,
            ),
        ),
      );
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './delete-workspace-user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteWorkspaceUserModalComponent implements OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: { workspaceName: string; userName: string; route: ActivatedRoute },
    private readonly _router: Router,
  ) {}

  ngOnDestroy(): void {
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }
}

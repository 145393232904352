import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectedWorkspaceQuery } from './selected-workspace.query';

export const GETS_SELECTED_WORKSPACE_QUERY = new InjectionToken<GetsSelectedWorkspaceQueryPort>(
  'GETS_SELECTED_WORKSPACE_QUERY',
);

export interface GetsSelectedWorkspaceQueryPort {
  getSelectedWorkspace(): Observable<SelectedWorkspaceQuery>;
}

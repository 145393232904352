import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const HAS_SUBSCRIPTIONS_QUERY = new InjectionToken<HasSubscriptionsQueryPort>(
  'HAS_SUBSCRIPTIONS_QUERY',
);

export interface HasSubscriptionsQueryPort {
  hasSubscriptions(): Observable<boolean>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeletesTeamCommand } from './deletes-team.command';

export interface DeletesTeamCommandPort {
  deleteTeam(command: DeletesTeamCommand): Observable<boolean>;
}

export const DELETES_TEAMS_COMMAND_PORT = new InjectionToken<DeletesTeamCommandPort>(
  'DELETES_TEAMS_COMMAND_PORT',
);

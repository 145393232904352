import { MessageNotification } from '../domain/messages/message-notification';

export class MessageNotificationQuery {
  constructor(
    public readonly id: string,
    public readonly date: Date,
    public readonly fields: { name: string; value: string }[],
    public readonly isRead: boolean,
  ) {}

  static fromMessageNotification(notification: MessageNotification): MessageNotificationQuery {
    return new MessageNotificationQuery(
      notification.id,
      notification.date,
      notification.fields.map(field => ({ name: field.name, value: field.value })),
      notification.isRead,
    );
  }
}

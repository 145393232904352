import { NgModule } from '@angular/core';
import { PATCHES_WORKSPACES_CONTEXT_STORAGE } from '../../../application/ports/secondary/context/patches-workspaces-context.storage-port';
import { SELECTS_WORKSPACES_CONTEXT_STORAGE } from '../../../application/ports/secondary/context/selects-workspaces-context.storage-port';
import { InMemoryWorkspacesContextStorage } from './in-memory-workspaces-context.storage';
@NgModule({
  providers: [
    InMemoryWorkspacesContextStorage,
    { provide: SELECTS_WORKSPACES_CONTEXT_STORAGE, useExisting: InMemoryWorkspacesContextStorage },
    { provide: PATCHES_WORKSPACES_CONTEXT_STORAGE, useExisting: InMemoryWorkspacesContextStorage },
  ],
})
export class InMemoryWorkspacesContextStorageModule {}

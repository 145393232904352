<button
  data-selector="social-login-facebook-button"
  class="cs-btn cs-btn-social cs-btn-social-facebook w-100 d-flex justify-content-center"
  [libAnalytics]="['social', 'facebook']"
  *libFeatureFlags="['sign_up_by_facebook']"
  (click)="onSignUpByFacebookClicked()"
  [huiLoading]="isLoading$ | async"
  type="button"
>
  <img class="social-logo" src="assets/logos/facebook-logo-white.svg" alt="Facebook" />
  <span class="cs-c-shade-0"><ng-content></ng-content></span>
</button>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CompletesFirstStepCommand } from './completes-first-step.command';

export const COMPLETES_FIRST_STEP_COMMAND = new InjectionToken<CompletesFirstStepCommandPort>(
  'COMPLETES_FIRST_STEP_COMMAND',
);

export interface CompletesFirstStepCommandPort {
  completeFirstStep(command: CompletesFirstStepCommand): Observable<void>;
}

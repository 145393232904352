import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_INTEGRATION_ID_QUERY = new InjectionToken<GetsIntegrationIdQueryPort>(
  'GETS_INTEGRATION_ID_QUERY',
);

export interface GetsIntegrationIdQueryPort {
  getIntegrationId(): Observable<string>;
}

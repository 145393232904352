import { Inject, Injectable } from '@angular/core';
import { GetsCurrentTeamQueryPort, TeamQuery } from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { Observable } from 'rxjs';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { map } from 'rxjs/operators';

@Injectable()
export class GetsCurrentTeamService implements GetsCurrentTeamQueryPort {
  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
  ) {}

  getCurrentTeamQuery(): Observable<TeamQuery> {
    return this._getsCobiroProContextQuery
      .getContext()
      .pipe(
        map((context: CobiroProContextQuery) =>
          context.selectedTeamId
            ? new TeamQuery(context.selectedTeamId, context.selectedTeamName)
            : undefined,
        ),
      );
  }
}

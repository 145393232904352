import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmReplaceCardModalComponent } from './confirm-replace-card-modal.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ConfirmReplaceCardModalComponent],
  imports: [CommonModule, LanguageModule, MatDialogModule],
  exports: [ConfirmReplaceCardModalComponent],
})
export class ConfirmReplaceCardModalModule {}

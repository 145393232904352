import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { WorkspaceUsersHeaderComponent } from './workspace-users-header.component';

@NgModule({
  declarations: [WorkspaceUsersHeaderComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, RouterModule],
  exports: [WorkspaceUsersHeaderComponent],
})
export class WorkspaceUsersHeaderComponentModule {}

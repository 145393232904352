import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetsLabelManagerConfigDto } from './sets-label-manager-config.dto';

export const SETS_LABEL_MANAGER_CONFIG_DTO_PORT = new InjectionToken<SetsLabelManagerConfigDtoPort>(
  'SETS_LABEL_MANAGER_CONFIG_DTO_PORT',
);

export interface SetsLabelManagerConfigDtoPort {
  setConfig(dto: SetsLabelManagerConfigDto): Observable<boolean>;
}

import { filter, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UpgradeRequestedEvent } from '@app.cobiro.com/core/events';
import { Feature } from '../domain/feature';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';

@Injectable()
export class FeaturesState {
  private _features = new ReplaySubject<Map<string, Feature>>(1);

  hasFeature$(featureId: string, usage?: number): Observable<boolean> {
    return this._features.asObservable().pipe(
      filter(userFeatures => userFeatures.size > 0),
      map(userFeatures => {
        if (!userFeatures.has(featureId)) {
          return false;
        }
        return userFeatures.get(featureId).checkIfUsageIsBelowLimit(usage);
      }),
    );
  }

  constructor(@Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus) {}

  changePlan(featureId: string) {
    this._applicationBus.dispatch(new UpgradeRequestedEvent(featureId));
  }

  setFeatures(features: Map<string, number | null>): void {
    this._features.next(
      new Map(
        Array.from(features.entries()).map(featureObject => [
          featureObject[0],
          new Feature(featureObject[0], featureObject[1]),
        ]),
      ),
    );
  }
}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { GetsAnnoucementQueryPort } from '../ports/primary/gets-annoucement.query-port';
import { AnnoucementQuery } from '../ports/primary/annoucement.query';
import {
  GetsAnnoucementDtoPort,
  GETS_ANNOUCEMENT_DTO,
} from '../ports/secondary/gets-annoucement.dto-port';
import {
  SetsAnnoucementsVisibilityDtoPort,
  SETS_ANNOUCEMENT_VISIBILITY_DTO,
} from '../ports/secondary/sets-annoucement-visibility.dto-port';
import {
  GETS_ANNOUCEMENT_VISIBILITY_DTO,
  GetsAnnoucementsVisibilityDtoPort,
} from '../ports/secondary/gets-annoucement-visibility.dto-port';
import { AnnoucementDTO } from '../ports/secondary/annoucement.dto';
import { SetsAnnoucementsVisibilityQueryPort } from '../ports/primary/sets-annoucement-visibility.query-port';

@Injectable()
export class AnnoucementState
  implements GetsAnnoucementQueryPort, SetsAnnoucementsVisibilityQueryPort
{
  private annoucements$: BehaviorSubject<AnnoucementDTO[] | null> = new BehaviorSubject<
    AnnoucementDTO[] | null
  >(null);

  constructor(
    @Inject(GETS_ANNOUCEMENT_DTO)
    private readonly _getsAnnoucementDtoPort: GetsAnnoucementDtoPort,
    @Inject(GETS_ANNOUCEMENT_VISIBILITY_DTO)
    private readonly _getsAnnoucementsVisibilityDtoPort: GetsAnnoucementsVisibilityDtoPort,
    @Inject(SETS_ANNOUCEMENT_VISIBILITY_DTO)
    private readonly _setsAnnoucementsVisibilityDtoPort: SetsAnnoucementsVisibilityDtoPort,
  ) {
    this._getAnnoucements().subscribe();
  }

  setAnnoucementsVisibility(id: string): Observable<boolean> {
    this._setsAnnoucementsVisibilityDtoPort.setAnnoucementsVisibility({
      annoucementId: id,
    });
    return this._getAnnoucements().pipe(map(() => void 0));
  }

  getAnnoucements(): Observable<AnnoucementQuery[]> {
    return this.annoucements$.asObservable();
  }

  private _getAnnoucements(): Observable<AnnoucementQuery[]> {
    return this._getsAnnoucementDtoPort.getAnnoucements().pipe(
      take(1),
      tap(annoucements => {
        const visibility = this._getsAnnoucementsVisibilityDtoPort.getAnnoucementsVisibility();
        this.annoucements$.next(
          annoucements
            .filter(
              annoucement =>
                annoucement.enabled &&
                !visibility.some(visible => visible.annoucementId === annoucement.id),
            )
            .map(annoucement => AnnoucementQuery.fromDto(annoucement)),
        );
      }),
    );
  }
}

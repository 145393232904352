import { NgModule } from '@angular/core';
import { GETS_PRODUCT_MARKETING_LIST_DTO } from '../../../../application/ports/secondary/dto/setup/gets-product-marketing-list.dto-port';
import { InMemoryProductService } from './in-memory-product.service';

@NgModule({
  providers: [
    InMemoryProductService,
    {
      provide: GETS_PRODUCT_MARKETING_LIST_DTO,
      useExisting: InMemoryProductService,
    },
  ],
})
export class InMemoryProductServiceModule {}

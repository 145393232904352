import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { AppRecommendationComponent } from './app-recommendation.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatIconModule],
  declarations: [AppRecommendationComponent],
  exports: [AppRecommendationComponent],
})
export class AppRecommendationComponentModule {}

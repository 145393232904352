import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyNameQuery } from './company-name.query';

export const GETS_CURRENT_COMPANY_NAME_QUERY = new InjectionToken<GetsCurrentCompanyNameQueryPort>(
  'GETS_CURRENT_COMPANY_NAME_QUERY',
);

export interface GetsCurrentCompanyNameQueryPort {
  getCurrentCompanyName(): Observable<CompanyNameQuery>;
}

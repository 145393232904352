import { ChartOptions } from './chart-option.query';

export const RESULT_CHART_OPTION: Partial<ChartOptions> = {
  series: [],
  grid: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    height: 350,
    width: '100%',
    type: 'bar',
    stacked: true,
    stackType: '100%',
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    events: {
      mounted: chart => {
        chart.windowResizeHandler();
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'all',
      columnWidth: '50%',
      dataLabels: {
        hideOverflowingLabels: false,
        position: 'top',
      },
      colors: {
        backgroundBarColors: ['#E2E8F0'],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 10,
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -50,
    style: {
      fontSize: '12px',
      colors: ['black'],
      fontFamily: '"Poppins", sans-serif !important',
      fontWeight: '400',
    },
  },
  xaxis: {
    categories: ['overIndex', 'index', 'nearIndex', 'noIndex', 'underIndex'],
    position: 'bottom',
  },
  yaxis: {
    show: false,
  },
  fill: {
    colors: ['#0063FF', '#E2E8F0'],
  },
  legend: {
    show: false,
  },
  noData: {
    text: 'There is no analyzed data yet',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: 'black',
      fontSize: '18px',
      fontFamily: '"Poppins", sans-serif !important',
    },
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
      },
    },
  },
};

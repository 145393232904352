import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GETS_QUESTIONS_QUERY,
  GetsQuestionsQueryPort,
} from '../../../../application/ports/primary/gets-questions.query-port';
import { QuestionQuery } from '../../../../application/ports/primary/question.query';

@Component({
  selector: 'lib-cobiro-pro-users-workspaces-faq',
  templateUrl: './users-workspaces-faq.component.html',
  styleUrls: ['./users-workspaces-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersWorkspacesFaqComponent {
  readonly questions$: Observable<QuestionQuery[]> = this._getsQuestionsQueryPort.getQuestions({
    productName: 'faq_ws',
  });

  constructor(
    @Inject(GETS_QUESTIONS_QUERY)
    private readonly _getsQuestionsQueryPort: GetsQuestionsQueryPort,
  ) {}
}

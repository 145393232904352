import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  ENABLES_PRO_MODE_COMMAND,
  EnablesProModeCommandPort,
} from '../../../application/ports/primary/enables-pro-mode.command-port';

@Injectable()
export class EnableProModeResolver {
  constructor(
    @Inject(ENABLES_PRO_MODE_COMMAND)
    private readonly _enablesProModeCommand: EnablesProModeCommandPort,
  ) {}

  resolve(): Observable<boolean> {
    return this._enablesProModeCommand.enableProMode();
  }
}

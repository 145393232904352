/**
 * Utility class for identifying user keyboard interaction
 */
export class KeyboardEventIdentifier {
  private static readonly _navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  private static readonly _shortcutKeys = ['a', 'c', 'v', 'x'];

  static isNavigationKey(e: KeyboardEvent): boolean {
    return this._navigationKeys.includes(e.key);
  }

  static isWindowsShortcut(e: KeyboardEvent): boolean {
    return this._isShortcutKey(e.key) && e.ctrlKey;
  }

  static isMacShortcut(e: KeyboardEvent): boolean {
    return this._isShortcutKey(e.key) && e.metaKey;
  }

  private static _isShortcutKey(key: string): boolean {
    return this._shortcutKeys.includes(key);
  }
}

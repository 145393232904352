import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { KeyboardEventIdentifier } from '@app.cobiro.com/shared/hui/utils/keyboard-event-identifier';

@Directive({
  selector: '[libDigitsInput]',
})
export class DigitsInputDirective {
  @Input() spaceAllowed = false;

  @Output() digitsPasted = new EventEmitter<string>();

  inputElement: HTMLInputElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      KeyboardEventIdentifier.isNavigationKey(e) ||
      KeyboardEventIdentifier.isWindowsShortcut(e) ||
      KeyboardEventIdentifier.isMacShortcut(e)
    ) {
      return;
    }

    if (this._isSpace(e.key) || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string

    document.execCommand('insertText', false, pastedInput);
    this.digitsPasted.emit(pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }

  private _isSpace(char: string) {
    return this.spaceAllowed && char === ' ';
  }
}

import { GetsCurrentPlanQueryPort } from '../../../application/ports/primary/gets-current-plan.query-port';
import { CurrentPlanQuery } from '../../../application/ports/primary/current-plan.query';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { CURRENT_PLAN_TYPE_STORAGE } from '../../../domain/storage/current-plan-type.storage';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { map } from 'rxjs/operators';

@Injectable()
export class GetsCurrentPlanQueryGetter implements GetsCurrentPlanQueryPort {
  constructor(
    @Inject(CURRENT_PLAN_TYPE_STORAGE)
    private _currentPlanTypeStorage: ReactiveSingleValueStorage<string>,
  ) {}

  get(): Observable<CurrentPlanQuery> {
    return this._currentPlanTypeStorage
      .select()
      .pipe(map(planName => CurrentPlanQuery.create(planName)));
  }
}

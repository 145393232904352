import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ResendsInvitationCommand } from './resends-invitation.command';

export const RESENDS_INVITATION_COMMAND = new InjectionToken<ResendsInvitationCommandPort>(
  'RESENDS_INVITATION_COMMAND',
);

export interface ResendsInvitationCommandPort {
  resendInvitation(command: ResendsInvitationCommand): Observable<void>;
}

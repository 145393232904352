import { NgModule } from '@angular/core';
import { LoadIntegrationResolver } from '../../../../cobiro-css/src/lib/adapters/primary/resolvers/load-integration.resolver';
import { HttpAppsCdnServiceModule } from '../adapters/secondary/infrastructure/http-apps-cdn/http-apps-cdn.service-module';
import { HttpClientsProductServiceModule } from '../adapters/secondary/infrastructure/http-clients-products/http-clients-products.service-module';
import { HttpComlynIntegrationServiceModule } from '../adapters/secondary/infrastructure/http-comlyn-integration/http-comlyn-integration.service-module';
import { HttpInstalledAppsServiceModule } from '../adapters/secondary/infrastructure/http-installed-apps/http-installed-apps.service-module';
import { HttpLabelManagerConfigServiceModule } from '../adapters/secondary/infrastructure/http-label-manager-config/http-label-manager-config.service-module';
import { HttpLabelManagerReportServiceModule } from '../adapters/secondary/infrastructure/http-label-manager-report/http-label-manager-report.service-module';
import { HttpLabelManagerSimulationServiceModule } from '../adapters/secondary/infrastructure/http-label-manager-simulation/http-label-manager-simulation.service-module';
import { LabelManagerIntegrationStateModule } from '../application/state/label-manager-integration/label-manager-integration.state-module';
import { LabelManagerRecommendationStateModule } from '../application/state/label-manager-recommendation/label-manager-recommendation.state-module';
import { LabelManagerSetConfigEventHandler } from '../adapters/primary/handlers/label-manager-config-changed.event-handler';
import { LabelManagerSetReportEventHandler } from '../adapters/primary/handlers/label-manager-report-changed.event-handler';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { LabelManagerNonCssIntegrationStateModule } from '../application/state/label-manager-non-css-integration/label-manager-non-css-integration.state-module';
import { HttpClientsServiceModule } from '../adapters/secondary/infrastructure/http-clients/http-clients.service-module';

@NgModule({
  imports: [
    LabelManagerIntegrationStateModule,
    LabelManagerRecommendationStateModule,
    LabelManagerNonCssIntegrationStateModule,
    HttpInstalledAppsServiceModule,
    HttpComlynIntegrationServiceModule,
    HttpAppsCdnServiceModule,
    HttpLabelManagerConfigServiceModule,
    HttpLabelManagerReportServiceModule,
    HttpClientsProductServiceModule,
    HttpLabelManagerSimulationServiceModule,
    HttpClientsServiceModule,
  ],
  providers: [
    LoadIntegrationResolver,
    provideApplicationEventsHandler(LabelManagerSetConfigEventHandler),
    provideApplicationEventsHandler(LabelManagerSetReportEventHandler),
  ],
})
export class CobiroProRewriteLabelManagerProvidersModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { Discount } from './discount';

export enum DISCOUNT_CODE_ERROR_MESSAGES {
  invalid = 'invalid',
  expired = 'expired',
}

export class DiscountCodeCheckResult {
  constructor(
    public readonly discount?: Discount,
    public readonly errorMessage?: DISCOUNT_CODE_ERROR_MESSAGES,
  ) {}
}

export interface ChecksDiscountCode {
  checkCode(code: string, planId: string): Observable<DiscountCodeCheckResult>;
}

export const CHECKS_DISCOUNT_CODE = new InjectionToken<ChecksDiscountCode>('CHECKS_DISCOUNT_CODE');

import { CobiroProContextDto } from './cobiro-pro-context.dto';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface SavesCobiroProContextDtoPort {
  saveContext(context: CobiroProContextDto): Observable<boolean>;
}

export const SAVES_COBIRO_PRO_CONTEXT_DTO = new InjectionToken<SavesCobiroProContextDtoPort>(
  'SAVES_COBIRO_PRO_CONTEXT_DTO',
);

<div>
  <div class="d-flex justify-content-between align-items-center w-100 cs-mb-20">
    <h4 class="cs-title-4">
      {{ 'cobiro_pro_label_manager_linking_status_title' | language }}
    </h4>
    <span
      *ngIf="isLinked | async; else isPaused"
      data-selector="label-manager-active-status"
      class="cs-badge status-badge cs-pv-9 cs-text-2 br-5 d-inline-block cs-badge-success"
      >{{ 'cobiro_pro_css_integration_status_active' | language }}</span
    >

    <ng-template #isPaused>
      <span
        data-selector="label-manager-paused-status"
        class="cs-badge status-badge cs-pv-9 cs-text-2 br-5 d-inline-block cs-badge-error cs-ml-20"
        >{{ 'cobiro_pro_css_integration_status_not_linked' | language }}</span
      >
    </ng-template>
  </div>
  <p class="cs-text-1 flex-1">
    {{ 'cobiro_pro_label_manager_linking_status_description' | language }}
  </p>
</div>

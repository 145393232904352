import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { HttpTeamSettingsService } from './http-team-settings.service';
import { GETS_TEAM_SETTINGS_DTO_PORT } from '../../../../application/ports/secondary/dto/settings/gets-team-settings.dto-port';
import { SAVES_TEAM_SETTINGS_DTO_PORT } from '../../../../application/ports/secondary/dto/settings/saves-team-settings.dto-port';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpTeamSettingsService,
    { provide: GETS_TEAM_SETTINGS_DTO_PORT, useExisting: HttpTeamSettingsService },
    { provide: SAVES_TEAM_SETTINGS_DTO_PORT, useExisting: HttpTeamSettingsService },
  ],
})
export class HttpTeamSettingsServiceModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export class Settings {
  constructor(public readonly weeklySummary: boolean) {}
}

export interface GetsSettings {
  get(): Observable<Settings>;
}

export const GETS_SETTINGS = new InjectionToken<GetsSettings>('GETS_SETTINGS');

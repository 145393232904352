/* eslint-disable max-lines-per-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  CallPaymentMethodCommandPort,
  CALL_PAYMENT_METHOD_COMMAND,
} from '../../../../../../../../application/ports/primary/payments/calls-payment-methods-event.command-port';
import {
  GETS_CARD_VALIDATION_QUERY_PORT,
  GetsCardValidationQueryPort,
} from '../../../../../../../../application/ports/primary/payments/gets-card-validation-error.query-port';
import { cardExpirationValidator } from '@app.cobiro.com/shared/validators';
import { distinctUntilChanged, Subject, take, takeUntil } from 'rxjs';
import { AddPaymentMethodCommand } from '../../../../../../../../application/ports/primary/payments/adds-payment-method.command';

@Component({
  selector: 'lib-cobiro-pro-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodFormComponent implements OnInit, OnDestroy {
  @Output() formGroupChange = new EventEmitter<AddPaymentMethodCommand>();
  @Output() validityChanged = new EventEmitter<boolean>();
  private _ngDestroy$ = new Subject<void>();

  readonly form = this._fb.group({
    cardOwner: ['', Validators.required],
    cardNumber: ['', Validators.required],
    cvv: ['', Validators.required],
    expirationDate: ['', [Validators.required, cardExpirationValidator]],
  });

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    @Inject(GETS_CARD_VALIDATION_QUERY_PORT)
    private readonly _getsCardValidationErrorQuery: GetsCardValidationQueryPort,
    @Inject(CALL_PAYMENT_METHOD_COMMAND)
    private readonly _callPaymentMethodCommand: CallPaymentMethodCommandPort,
  ) {}

  ngOnInit(): void {
    this._callPaymentMethodCommand.dispatchEvent().pipe(take(1)).subscribe();

    this._getsCardValidationErrorQuery
      .getErrors()
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe((errorFieldName: string) => {
        const control = this.form.get(errorFieldName);
        if (control) {
          control.setErrors({ ...control.errors, invalidField: true });
          this.form.updateValueAndValidity();
          this._changeDetectorRef.detectChanges();
        }
      });

    this.form.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this._ngDestroy$))
      .subscribe(value => this.validityChanged.emit(value === 'VALID'));

    this.form.valueChanges.subscribe(() => {
      const { cardOwner, cardNumber, cvv, expirationDate } = this.form.value;
      const [expiryMonth, expiryYear] =
        expirationDate !== null ? expirationDate.match(/.{2}/g) : [null, null];

      return this.formGroupChange.emit({
        cardOwner,
        cvv,
        number: cardNumber,
        expiryYear,
        expiryMonth,
      });
    });
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const SETS_IS_INVITED_USER_QUERY = new InjectionToken<SetsIsInvitedUserQueryPort>(
  'SETS_IS_INVITED_USER_QUERY',
);

export interface SetsIsInvitedUserQueryPort {
  setIsInvitedUser(value: boolean): Observable<void>;
}

import { NgModule } from '@angular/core';
import { HuiLoadingDirective } from './hui-loading.directive';
import { HuiLoadingPipe } from './hui-loading.pipe';

const components = [HuiLoadingPipe, HuiLoadingDirective];

@NgModule({
  declarations: components,
  exports: components,
})
export class HuiLoadingModule {}

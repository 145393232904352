import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsLabelManagerConfigDto } from './gets-label-manager-config.dto';
import { LabelManagerConfigDto } from './label-manager-config.dto';

export const GETS_LABEL_MANAGER_CONFIG_DTO_PORT = new InjectionToken<GetsLabelManagerConfigDtoPort>(
  'GETS_LABEL_MANAGER_CONFIG_DTO_PORT',
);

export interface GetsLabelManagerConfigDtoPort {
  getConfig(dto: GetsLabelManagerConfigDto): Observable<LabelManagerConfigDto>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface ConfirmReplaceCardMessageCommandPort {
  open(): Observable<boolean>;
}

export const CONFIRM_REPLACE_CARD_MESSAGE_COMMAND_PORT =
  new InjectionToken<ConfirmReplaceCardMessageCommandPort>(
    'CONFIRM_REPLACE_CARD_MESSAGE_COMMAND_PORT',
  );

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { BASE_URL } from '@app.cobiro.com/core/utils';
import { RecoversPassword } from '../domain/recovers-password';
import { HasData } from '@cobiro/jsonapi';

export interface ResetsPasswordAttributes {
  email: string;
}

@Injectable()
export class HttpRecoversPasswordService implements RecoversPassword {
  private _headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(private _client: HttpClient, @Inject(BASE_URL) private _baseUrl: string) {}

  recover(email: string): Observable<boolean> {
    const body: HasData<ResetsPasswordAttributes> = {
      data: {
        type: 'password-reset',
        attributes: {
          email: email,
        },
      },
    };

    return this._client
      .post(this._baseUrl + '/v1/password-reset', body, {
        headers: this._headers,
      })
      .pipe(map(() => true));
  }
}

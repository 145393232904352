<ng-container *ngIf="selectedPlan$ | async as selectedPlan">
  <ng-container *ngIf="planEstimate$ | async as planEstimate; else summaryWithoutVat">
    <ng-container
      [ngTemplateOutlet]="summary"
      [ngTemplateOutletContext]="{
        nettPrice: planEstimate.nett,
        grossPrice: planEstimate.gross,
        vatValue: planEstimate.vatValue,
        vatPercentage: planEstimate.vatPercentage,
        originalGrossPrice: planEstimate.originalGrossPrice
      }"
    ></ng-container>
  </ng-container>
  <ng-template #summaryWithoutVat>
    <ng-container
      [ngTemplateOutlet]="summary"
      [ngTemplateOutletContext]="{
        nettPrice: selectedPlan.price,
        grossPrice: getPrice(selectedPlan.price, discount$ | async),
        vatValue: null,
        vatPercentage: null,
        originalGrossPrice: (discount$ | async) ? selectedPlan.price : null
      }"
    ></ng-container>
  </ng-template>
</ng-container>

<ng-template
  #summary
  let-nettPrice="nettPrice"
  let-grossPrice="grossPrice"
  let-vatPercentage="vatPercentage"
  let-vatValue="vatValue"
  let-originalGrossPrice="originalGrossPrice"
>
  <div class="cs-bg-shade-4 cs-pv-20 cs-ph-15 cs-mt-30 br-5">
    <div class="d-flex justify-content-between">
      <p class="cs-text-1">{{ '_subtotal' | language }}</p>
      <p class="cs-text-1">${{ nettPrice }}</p>
    </div>
    <div class="d-flex justify-content-between cs-mt-10">
      <p class="cs-text-1">
        {{
          vatPercentage !== null
            ? ('payment_plans_vat_percentage' | language: vatPercentage)
            : ('payment_plans_vat' | language)
        }}
      </p>
      <p class="cs-text-1">
        {{ vatValue !== null ? '$' + vatValue : ('payment_plans_select_country' | language) }}
      </p>
    </div>
    <ng-container *ngIf="discount$ | async as discount">
      <ng-container
        [ngTemplateOutlet]="discountDisplay"
        [ngTemplateOutletContext]="{ discount: discount }"
      ></ng-container>
    </ng-container>
    <div class="d-flex justify-content-between cs-mt-5">
      <p class="cs-title-6">
        {{ 'payment_plans_total_incl_vat' | language }}
      </p>
      <div>
        <p class="cs-title-6">
          <span
            *ngIf="originalGrossPrice"
            class="cs-text-1 cs-c-shade-100 text-decoration-line-through cs-mr-10"
          >
            ${{ originalGrossPrice }}
          </span>
          ${{ grossPrice }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #discountDisplay let-discount="discount">
  <div class="d-flex justify-content-between align-items-center cs-mt-20">
    <div>
      <p class="cs-text-2">{{ 'payment_plans_discount_code' | language }}: {{ discount.code }}</p>
      <small class="cs-text-3">
        ({{ 'payment_plans_discount_code_applies_for' | language }}:
        <span *ngIf="discount.duration.type === 'period'">
          {{ discount.duration.value }}
          {{
            'payment_plans_discount_duration_period_' + discount.duration.unit.toLowerCase()
              | language
          }}
        </span>
        <span *ngIf="discount.duration.type !== 'period'">
          {{ 'payment_plans_discount_duration_' + discount.duration.type.toLowerCase() | language }}
        </span>
        )
      </small>
    </div>
    <p class="cs-text-2">-{{ getDiscountValue(discount) }}</p>
  </div>
</ng-template>

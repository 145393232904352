import { NgModule } from '@angular/core';
import { ClientsListState } from './clients-list.state';
import { GETS_ALL_CLIENT_QUERY } from '../ports/primary/gets-all-client-query.port';
import { LOAD_CLIENTS_COMMAND } from '../ports/primary/load-clients-command.port';
import { STORES_CLIENTS_LIST_COMMAND } from '../ports/primary/stores-clients-list.command-port';
import { GETS_PAGINATED_CLIENTS_QUERY } from '../ports/primary/gets-paginated-clients.query-port';
import { GETS_CLIENTS_LIST_PAGINATION_QUERY } from '../ports/primary/gets-clients-list-pagination.query-port';
import { SETS_CLIENTS_LIST_PAGE_COMMAND } from '../ports/primary/sets-clients-list-page.command-port';
import { RESETS_CLIENTS_LIST_PAGINATION_COMMAND } from '../ports/primary/resets-clients-list-pagination.command-port';
import { CLIENTS_LIST_LOADED_DISPATCHER } from '../ports/secondary/clients-list-loaded.dispatcher-port';
import { ClientsListLoadedEventDispatcher } from '../../adapters/secondary/dispatchers/clients-list-loaded.event-dispatcher';
import { SETS_CLIENTS_LIST_SEARCH_PHRASE_COMMAND_PORT } from '../ports/primary/sets-search-phrase.command-port';
import { STORES_NEW_CLIENT_COMMAND } from '../ports/primary/stores-new-client.command-port';
import { GETS_CLIENT_SERVICES_QUERY } from '../ports/primary/gets-client-services.query-port';
import { SETS_CLIENTS_LIST_FILTER_COMMAND } from '../ports/primary/sets-clients-list-filter.command-port';
import { SETS_CLIENTS_LIST_SORT_COMMAND } from '../ports/primary/sets-clients-list-sort.command-port';

@NgModule({
  providers: [
    ClientsListState,
    { provide: GETS_ALL_CLIENT_QUERY, useExisting: ClientsListState },
    { provide: LOAD_CLIENTS_COMMAND, useExisting: ClientsListState },
    { provide: GETS_PAGINATED_CLIENTS_QUERY, useExisting: ClientsListState },
    { provide: STORES_CLIENTS_LIST_COMMAND, useExisting: ClientsListState, multi: true },
    { provide: GETS_CLIENTS_LIST_PAGINATION_QUERY, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_PAGE_COMMAND, useExisting: ClientsListState },
    { provide: RESETS_CLIENTS_LIST_PAGINATION_COMMAND, useExisting: ClientsListState },
    { provide: CLIENTS_LIST_LOADED_DISPATCHER, useClass: ClientsListLoadedEventDispatcher },
    { provide: SETS_CLIENTS_LIST_SEARCH_PHRASE_COMMAND_PORT, useExisting: ClientsListState },
    { provide: STORES_NEW_CLIENT_COMMAND, useExisting: ClientsListState, multi: true },
    { provide: GETS_CLIENT_SERVICES_QUERY, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_FILTER_COMMAND, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_SORT_COMMAND, useExisting: ClientsListState },
  ],
})
export class ClientsListStateModule {}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export const WRONG_ADS_ID_ERROR_KEY = 'wrongAdsId';

export const googleAdsIdValidator: ValidatorFn = (control: AbstractControl) => {
  const value = control.value;

  if (!value) {
    return null;
  }

  if (value.includes('-')) {
    const [firstBlock, secondBlock, thirdBlock] = value.split('-');

    if (!secondBlock || !thirdBlock) {
      return { [WRONG_ADS_ID_ERROR_KEY]: true };
    }

    if (firstBlock.length === 3 && secondBlock.length === 3 && thirdBlock.length === 4) {
      return null;
    }

    return { [WRONG_ADS_ID_ERROR_KEY]: true };
  }

  return value.length === 10 ? null : { [WRONG_ADS_ID_ERROR_KEY]: true };
};

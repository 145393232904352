import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IsAllSelectedLatestClientsCommandPort {
  isAllSelected(): Observable<boolean>;
}

export const IS_ALL_SELECTED_LATEST_CLIENTS_COMMAND =
  new InjectionToken<IsAllSelectedLatestClientsCommandPort>(
    'IS_ALL_SELECTED_LATEST_CLIENTS_COMMAND',
  );

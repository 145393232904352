import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface EnablesProModeCommandPort {
  enableProMode(): Observable<boolean>;
}

export const ENABLES_PRO_MODE_COMMAND = new InjectionToken<EnablesProModeCommandPort>(
  'ENABLES_PRO_MODE_COMMAND',
);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesAgencyWithWorkspaceCommand } from './creates-agency-with-workspace.command';

export const CREATES_AGENCY_WITH_WORKSPACE_COMMAND =
  new InjectionToken<CreatesAgencyWithWorkspaceCommandPort>(
    'CREATES_AGENCY_WITH_WORKSPACE_COMMAND',
  );

export interface CreatesAgencyWithWorkspaceCommandPort {
  createAgencyWithWorkspace(command: CreatesAgencyWithWorkspaceCommand): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { StartsIntegrationProcessCommand } from './starts-integration-process.command';

export const STARTS_INTEGRATION_PROCESS_COMMAND =
  new InjectionToken<StartsIntegrationProcessCommandPort>('STARTS_INTEGRATION_PROCESS_COMMAND');

export interface StartsIntegrationProcessCommandPort {
  startIntegration(command: StartsIntegrationProcessCommand): Observable<boolean>;
}

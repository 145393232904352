import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerDashboardComponent } from './label-manager-dashboard.component';
import { RouterModule } from '@angular/router';
import { LabelManagerStartPageModule } from '../label-manager-start-page/label-manager-start-page.component.module';
import { LabelManagerIntegrationComponentModule } from '../label-manager-integration/label-manager-integration.component-module';

@NgModule({
  declarations: [LabelManagerDashboardComponent],
  imports: [
    LabelManagerIntegrationComponentModule,
    LabelManagerStartPageModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: LabelManagerDashboardComponent }]),
  ],
  exports: [LabelManagerDashboardComponent],
})
export class LabelManagerDashboardComponentModule {}

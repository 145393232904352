import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface ShowOverviewSectionQueryPort {
  showOverviewSection(): Observable<boolean>;
}

export const SHOW_OVERVIEW_SECTION_QUERY_PORT = new InjectionToken<ShowOverviewSectionQueryPort>(
  'SHOW_OVERVIEW_SECTION_QUERY_PORT',
);

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { WorkspaceQuery } from './workspace.query';

export const GETS_CURRENT_WORKSPACE_QUERY = new InjectionToken<GetsCurrentWorkspaceQueryPort>(
  'GETS_CURRENT_WORKSPACE_QUERY',
);

export interface GetsCurrentWorkspaceQueryPort {
  getCurrentWorkspace(): Observable<WorkspaceQuery>;
}

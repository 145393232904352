import { Inject, Injectable } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { SavesUserLatestLoginRedirectDtoPort } from '../application/ports/secondary/saves-user-latest-login-redirect.dto-port';
import { GetsUserLatestLoginRedirectDtoPort } from '../application/ports/secondary/gets-user-latest-login-redirect.dto-port';
import { UserLatestLoginRedirectDto } from './user-latest-login-redirect.dto';

@Injectable()
export class LocalStorageUserLatestLoginRedirectService
  implements SavesUserLatestLoginRedirectDtoPort, GetsUserLatestLoginRedirectDtoPort
{
  private readonly _key = 'lib-user-latest-login-redirect';
  constructor(@Inject(STORAGE) private readonly _storage: Storage) {}

  getUserLatestLogin(): UserLatestLoginRedirectDto | null {
    return this._storage.getItem(this._key) && JSON.parse(this._storage.getItem(this._key));
  }

  saveUserLatestLoginRedirect(dto: UserLatestLoginRedirectDto): void {
    this._storage.setItem(this._key, JSON.stringify(dto));
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientSubscriptionDto } from './subscription.dto';
import { GetsClientSubsciptionsDto } from './gets-client-subscriptions.dto';

export const GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT = new InjectionToken<GetsClientSubsciptionsDtoPort>(
  'GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT',
);

export interface GetsClientSubsciptionsDtoPort {
  getSubscriptions(dto: GetsClientSubsciptionsDto): Observable<ClientSubscriptionDto[]>;
}

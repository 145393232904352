import { NgModule } from '@angular/core';
import { CANCELS_CLIENT_SUBSCRIPTION_CSS_DTO } from '../../../../application/ports/secondary/dto/clients/cancels-client-subscription-css.dto-port';
import { HttpCancelsClientSubscriptionCSSService } from './http-cancels-client-subscription.service';

@NgModule({
  providers: [
    HttpCancelsClientSubscriptionCSSService,
    {
      provide: CANCELS_CLIENT_SUBSCRIPTION_CSS_DTO,
      useExisting: HttpCancelsClientSubscriptionCSSService,
    },
  ],
})
export class HttpCancelsClientSubscriptionCSSServiceModule {}

import { Injectable } from '@angular/core';
import { CreatesPaymentIntentDtoPort } from '../../../../application/ports/secondary/dto/payments/creates-payment-intent.dto-port';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { PaymentIntentDto } from '../../../../application/ports/secondary/dto/payments/payment-intent.dto';
import { HasData, JsonData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';

interface CreatePaymentIntentResponseAttributes {
  id: string;
  type: string;
  status: string;
  amount: number;
  currencyCode: string;
  gatewayAccountId: string;
  gateway: string;
}

interface CreatePaymentIntentAttributes {
  workspaceId: string;
}

@Injectable()
export class HttpTeamCreatePaymentIntentService implements CreatesPaymentIntentDtoPort {
  constructor(private readonly _gateway: GatewayClient) {}

  createPaymentIntent(workspaceId: string): Observable<PaymentIntentDto> {
    const payload: HasData<CreatePaymentIntentAttributes> = {
      data: {
        type: 'workspace-payment-intents',
        attributes: {
          workspaceId,
        },
      },
    };

    return this._gateway
      .post<HasData<CreatePaymentIntentResponseAttributes>>(
        `v1/payments/workspaces/${workspaceId}/workspace-payment-intents`,
        payload,
      )
      .pipe(
        map((res: HasData<CreatePaymentIntentResponseAttributes>) => this._mapAttributes(res.data)),
      );
  }

  private _mapAttributes(data: JsonData<CreatePaymentIntentResponseAttributes>): PaymentIntentDto {
    return {
      id: data.id,
      type: data.attributes.type,
      status: data.attributes.status,
      amount: data.attributes.amount,
      currencyCode: data.attributes.currencyCode,
      gatewayAccountId: data.attributes.gatewayAccountId,
      gateway: data.attributes.gateway,
    };
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientServicesDto } from './client-services.dto';

export interface GetsOneClientServicesDtoPort {
  getOne(siteId: string, sitePublicId: string): Observable<ClientServicesDto>;
}

export const GETS_ONE_CLIENT_SERVICES_DTO = new InjectionToken<GetsOneClientServicesDtoPort>(
  'GETS_ONE_CLIENT_SERVICES_DTO',
);

import { GetsAvailableTodosDtoPort } from '../../application/ports/secondary/gets-available-todos.dto-port';
import { TodoDTO } from '../../application/ports/secondary/todo.dto';
import { Injectable } from '@angular/core';
import { GetsProductListDtoPort } from '../../application/ports/secondary/gets-product-list.dto-port';
import { ProductDTO } from '../../application/ports/secondary/product.dto';
import { ProductMarketingDTO } from '../../application/ports/secondary/product-marketing.dto';
import { GetsProductMarketingListDtoPort } from '../../application/ports/secondary/gets-product-marketing-list.dto-port';

@Injectable()
export class InMemoryService
  implements GetsAvailableTodosDtoPort, GetsProductListDtoPort, GetsProductMarketingListDtoPort
{
  getProducts(): ProductDTO[] {
    return COBIRO_PRO_PRODUCT_LIST;
  }

  getProductsMarketing(product: string): ProductMarketingDTO {
    return COBIRO_PRO_PRODUCT_MARKETING_LIST.find(item => item.product === product);
  }

  getAvailableTodos(): TodoDTO[] {
    return COBIRO_PRO_HOME_TODOS_CONFIG;
  }
}

export const COBIRO_PRO_PRODUCT_LIST: ProductDTO[] = [
  {
    id: '1',
    name: 'css',
    img: '/assets/apps/icon/setup_css.svg',
  },
  {
    id: '2',
    name: 'label-manager',
    img: '/assets/apps/icon/setup_label-manager.svg',
  },
];

export const COBIRO_PRO_PRODUCT_MARKETING_LIST: ProductMarketingDTO[] = [
  {
    product: 'css',
    img: '/assets/apps/store/css/marketing_css.jpg',
    advantages: [
      'cobiro_pro_setup_css_advantage_1',
      'cobiro_pro_setup_css_advantage_2',
      'cobiro_pro_setup_css_advantage_3',
      'cobiro_pro_setup_css_advantage_4',
    ],
  },
  {
    product: 'label-manager',
    img: '/assets/apps/store/label-manager/marketing_label-manager.jpg',
    advantages: [
      'cobiro_pro_setup_label-manager_advantage_1',
      'cobiro_pro_setup_label-manager_advantage_2',
      'cobiro_pro_setup_label-manager_advantage_3',
      'cobiro_pro_setup_label-manager_advantage_4',
    ],
  },
];

export const COBIRO_PRO_HOME_TODOS_CONFIG: TodoDTO[] = [
  {
    id: '1',
    title: 'cobiro_pro_home_lets_get_started_item_5_title',
    description: 'cobiro_pro_home_lets_get_started_item_5_text',
    redirectUrl: 'clients',
    isCompleted: false,
    queryParams: { add: true },
  },
  {
    id: '2',
    title: 'cobiro_pro_home_lets_get_started_item_2_title',
    description: 'cobiro_pro_home_lets_get_started_item_2_text',
    redirectUrl: 'settings',
    isCompleted: false,
  },
  {
    id: '3',
    title: 'cobiro_pro_home_lets_get_started_item_3_title',
    description: 'cobiro_pro_home_lets_get_started_item_3_text',
    redirectUrl: 'members',
    isCompleted: false,
    queryParams: { invite: true },
  },
];

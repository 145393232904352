import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { StorageBasedTeamIdGetter, TEAM_ID_GETTER } from './team-id.getter';

// TODO: move to core
@NgModule({
  providers: [
    {
      provide: STORAGE,
      useValue: localStorage,
    },
    {
      provide: TEAM_ID_GETTER,
      useClass: StorageBasedTeamIdGetter,
    },
  ],
  imports: [CommonModule],
})
export class TeamIdGetterModule {}

import { NgModule } from '@angular/core';
import { PATCHES_CLIENTS_CONTEXT_STORAGE } from '../../../../application/ports/secondary/context/patches-clients-context.storage-port';
import { SELECTS_CLIENTS_CONTEXT_STORAGE } from '../../../../application/ports/secondary/context/selects-clients-context.storage-port';
import { InMemoryClientsContextStorage } from './in-memory-clients-context.storage';
@NgModule({
  providers: [
    InMemoryClientsContextStorage,
    { provide: SELECTS_CLIENTS_CONTEXT_STORAGE, useExisting: InMemoryClientsContextStorage },
    { provide: PATCHES_CLIENTS_CONTEXT_STORAGE, useExisting: InMemoryClientsContextStorage },
  ],
})
export class InMemoryClientsContextStorageModule {}

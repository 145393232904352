import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelsClientSubscriptionCSSDto } from './cancels-client-subscription-css.dto';

export const CANCELS_CLIENT_SUBSCRIPTION_CSS_DTO =
  new InjectionToken<CancelsClientSubscriptionCSSDtoPort>('CANCELS_CLIENT_SUBSCRIPTION_CSS_DTO');

export interface CancelsClientSubscriptionCSSDtoPort {
  cancel(dto: CancelsClientSubscriptionCSSDto): Observable<void>;
}

import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { ClientDTO } from '../../../application/ports/secondary/dto/clients/client.dto';
import { ClientsListChangedDispatcherPort } from '../../../application/ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientsListChangedEvent } from '../../../application/events/clients-list-changed.event';

@Injectable()
export class ClientsListChangedEventDispatcher implements ClientsListChangedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { updatedClients: ClientDTO[] }) {
    this._applicationBus.dispatch(new ClientsListChangedEvent(event.updatedClients));
  }
}

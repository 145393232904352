import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface GetsCardValidationQueryPort {
  getErrors(): Observable<string>;
}

export const GETS_CARD_VALIDATION_QUERY_PORT = new InjectionToken<GetsCardValidationQueryPort>(
  'GETS_CARD_VALIDATION_QUERY_PORT',
);

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { WorkspaceDto } from '../secondary/dto/workspace.dto';

export interface GetsMemberWorkspacesListByTeamQuery {
  getMemberWorkspaceListByTeam(teamId: string): Observable<WorkspaceDto[]>;
}

export const GETS_MEMBER_WORKSPACES_LIST_BY_TEAM_QUERY =
  new InjectionToken<GetsMemberWorkspacesListByTeamQuery>(
    'GETS_MEMBER_WORKSPACES_LIST_BY_TEAM_QUERY',
  );

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable, Inject } from '@angular/core';
import {
  GetsUserMenuOptionsQueryPort,
  UserMenuOptionQuery,
} from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { EMPTY, map, Observable, of } from 'rxjs';
import {
  GetsCobiroProContextQueryPort,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  CobiroProContextQuery,
} from '@app.cobiro.com/cobiro-pro/context';

@Injectable()
export class GetsUserMenuOptionsService implements GetsUserMenuOptionsQueryPort {
  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQueryPort: GetsCobiroProContextQueryPort,
  ) {}

  getUserMenuOptions(): Observable<UserMenuOptionQuery[]> {
    return this._getsCobiroProContextQueryPort.getContext().pipe(
      map((res: CobiroProContextQuery) => {
        return Array.of(
          {
            routerLink: ['/my-profile'],
            translationKey: 'nav_top_bar_user_menu_my_profile',
          },
          ...(res.selectedTeamUserRole !== 'basic_user'
            ? [
                {
                  routerLink: [`/connect/${res.selectedTeamId}/company-settings/members`],
                  translationKey: 'nav_top_bar_user_menu_company_settings',
                },
              ]
            : []),
          {
            routerLink: ['/user/logout'],
            translationKey: 'nav_top_bar_user_menu_logout',
          },
        );
      }),
    );
  }
}

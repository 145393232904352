import { PlanEstimate } from './plan-estimate';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { EstimatePlanDto } from './estimate-plan.dto';

export interface GetsPlanEstimate {
  get(dto: EstimatePlanDto): Observable<PlanEstimate>;
}

export const GETS_PLAN_ESTIMATE = new InjectionToken<GetsPlanEstimate>('GETS_PLAN_ESTIMATE');

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from '../secondary/dto/user.dto';

export const LOAD_LATEST_USERS_COMMAND = new InjectionToken<LoadLatestUsersCommandPort>(
  'LOAD_LATEST_USERS_COMMAND',
);

export interface LoadLatestUsersCommandPort {
  loadUsers(): Observable<UserDTO[]>;
}

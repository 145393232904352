import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsLabelManagerReportDto } from './gets-label-manager-report.dto';
import { LabelManagerReportDto } from './label-manager-report.dto';

export const GETS_LABEL_MANAGER_REPORT_DTO_PORT = new InjectionToken<GetsLabelManagerReportDtoPort>(
  'GETS_LABEL_MANAGER_REPORT_DTO_PORT',
);

export interface GetsLabelManagerReportDtoPort {
  getReport(dto: GetsLabelManagerReportDto): Observable<LabelManagerReportDto>;
}

import { of, Subject } from 'rxjs';

export const UPGRADE_PLAN_PROCESS_STUB = {
  init() {},
  nextStep() {},
  previousStep() {},
  end() {},
  cardProcessingInProgress$: new Subject(),
  discountCodeProcessingInProgress$: new Subject(),
  showCardForm() {},
  checkDiscountCode() {
    return of(true);
  },
  clearDiscountCode() {},
};

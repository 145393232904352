import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationErrorQuery } from './integration-error.query';

export const GETS_INTEGRATION_ERROR_QUERY = new InjectionToken<GetsIntegrationErrorQueryPort>(
  'GETS_INTEGRATION_ERROR_QUERY',
);

export interface GetsIntegrationErrorQueryPort {
  getIntegrationErrorQuery(): Observable<IntegrationErrorQuery | null>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeStepComponent } from './iframe-step.component';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [IframeStepComponent],
  exports: [IframeStepComponent],
})
export class IframeStepComponentModule {}

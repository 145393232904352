import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SignsUpByFacebookCommand } from '../../application/ports/primary/signs-up-by-facebook.command';
import {
  SIGNS_UP_BY_FACEBOOK_COMMAND,
  SignsUpByFacebookCommandPort,
} from '../../application/ports/primary/signs-up-by-facebook.command-port';

@Component({
  selector: 'lib-sign-up-by-facebook',
  templateUrl: './sign-up-by-facebook.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SignUpByFacebookComponent {
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(SIGNS_UP_BY_FACEBOOK_COMMAND)
    private readonly _signsUpByFacebookCommand: SignsUpByFacebookCommandPort,
    private _router: Router,
  ) {}

  onSignUpByFacebookClicked(): void {
    this.isLoading$.next(true);
    this._signsUpByFacebookCommand
      .signUpByFacebook(new SignsUpByFacebookCommand(this._router.url))
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe();
  }
}

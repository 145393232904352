import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import {
  GetsQuestionsQueryPort,
  GETS_QUESTIONS_QUERY,
} from '../../../../application/ports/primary/gets-questions.query-port';
import { QuestionQuery } from '../../../../application/ports/primary/question.query';

@Component({
  selector: 'lib-cobiro-pro-css-faq',
  templateUrl: './css-faq.component.html',
  styleUrls: ['./css-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CssFaqComponent {
  readonly questions$: Observable<QuestionQuery[]> = this._getsQuestionsQueryPort.getQuestions({
    productName: 'faq_css',
  });

  constructor(
    @Inject(GETS_QUESTIONS_QUERY)
    private readonly _getsQuestionsQueryPort: GetsQuestionsQueryPort,
  ) {}
}

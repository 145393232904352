import { Observable } from 'rxjs';
import { TeamSettingsDto } from './team-settings.dto';
import { InjectionToken } from '@angular/core';
import { GetsTeamSettingsDto } from './gets-team-settings.dto';

export const GETS_TEAM_SETTINGS_DTO_PORT = new InjectionToken<GetsTeamSettingsDtoPort>(
  'GETS_TEAM_SETTINGS_DTO_PORT',
);

export interface GetsTeamSettingsDtoPort {
  getTeamSettings(dto: GetsTeamSettingsDto): Observable<TeamSettingsDto | null>;
}

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ViewAllMembersClickedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/users';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class ViewAllMembersClickedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ViewAllMembersClickedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  handle([event]: [ViewAllMembersClickedEvent]): void {
    this._router.navigate(['/', 'connect', this._teamIdGetter.getTeamId(), 'members']);
  }
}

import { NgModule } from '@angular/core';
import { ClientModalsModule } from '../../../adapters/primary/ui/modals/client-modals.module';
import { PaymentFlowStartedEventDispatcher } from '../../../adapters/secondary/dispatchers/payment-flow-started.event-dispatcher';
import { ADDS_PAYMENT_METHOD_COMMAND } from '../../ports/primary/payments/adds-payment-method.command-port';
import { APPLIES_DISCOUNT_CODE_COMMAND } from '../../ports/primary/payments/applies-discount-code.command-port';
import { CALL_PAYMENT_METHOD_COMMAND } from '../../ports/primary/payments/calls-payment-methods-event.command-port';
import { CREATES_NEW_SUBSCRIPTION_COMMAND } from '../../ports/primary/payments/creates-new-subscription.command-port';
import { GETS_CARD_VALIDATION_QUERY_PORT } from '../../ports/primary/payments/gets-card-validation-error.query-port';
import { GETS_CARDS_LIST_COMMAND } from '../../ports/primary/payments/gets-cards-list.command-port';
import { GETS_DISCOUNT_COMMAND } from '../../ports/primary/payments/gets-discount.command-port';
import { GETS_PAYMENT_PLANS_QUERY } from '../../ports/primary/payments/gets-payment-plans.query-port';
import { GETS_PAYMENT_SOURCES_QUERY_PORT } from '../../ports/primary/payments/gets-payment-sources.query-port';
import { GETS_PLAN_ESTIMATE_QUERY } from '../../ports/primary/payments/gets-plan-estimate.query-port';
import { GETS_SELECTED_CARD_QUERY } from '../../ports/primary/payments/gets-selected-card.query-port';
import { GETS_SELECTED_PLAN_QUERY } from '../../ports/primary/payments/gets-selected-plan.query-port';
import { RESETS_DISCOUNT_CODE_COMMAND } from '../../ports/primary/payments/resets-discount-code.command-port';
import { SETS_SELECTED_CARD_COMMAND } from '../../ports/primary/payments/sets-selected-card.command-port';
import { SETS_SELECTED_PLAN_COMMAND } from '../../ports/primary/payments/sets-selected-plan.command-port';
import { UPGRADES_PLAN_COMMAND } from '../../ports/primary/payments/upgrades-plan-client.command-port';
import { PAYMENT_FLOW_STARTED_DISPATCHER } from '../../ports/secondary/dispatchers/payment-flow-started.dispatcher-port ';
import { PaymentsState } from './payments.state';

@NgModule({
  imports: [ClientModalsModule],
  providers: [
    PaymentsState,
    { provide: UPGRADES_PLAN_COMMAND, useExisting: PaymentsState },
    { provide: GETS_PAYMENT_PLANS_QUERY, useExisting: PaymentsState },
    { provide: SETS_SELECTED_CARD_COMMAND, useExisting: PaymentsState },
    { provide: GETS_SELECTED_CARD_QUERY, useExisting: PaymentsState },
    { provide: GETS_CARDS_LIST_COMMAND, useExisting: PaymentsState },
    { provide: APPLIES_DISCOUNT_CODE_COMMAND, useExisting: PaymentsState },
    { provide: RESETS_DISCOUNT_CODE_COMMAND, useExisting: PaymentsState },
    { provide: GETS_DISCOUNT_COMMAND, useExisting: PaymentsState },
    { provide: GETS_PLAN_ESTIMATE_QUERY, useExisting: PaymentsState },
    { provide: GETS_PAYMENT_SOURCES_QUERY_PORT, useExisting: PaymentsState },
    { provide: SETS_SELECTED_PLAN_COMMAND, useExisting: PaymentsState },
    { provide: GETS_SELECTED_PLAN_QUERY, useExisting: PaymentsState },
    { provide: CREATES_NEW_SUBSCRIPTION_COMMAND, useExisting: PaymentsState },
    { provide: ADDS_PAYMENT_METHOD_COMMAND, useExisting: PaymentsState },
    { provide: GETS_CARD_VALIDATION_QUERY_PORT, useExisting: PaymentsState },
    { provide: CALL_PAYMENT_METHOD_COMMAND, useExisting: PaymentsState },
    { provide: PAYMENT_FLOW_STARTED_DISPATCHER, useClass: PaymentFlowStartedEventDispatcher },
  ],
})
export class PaymentsStateModule {}

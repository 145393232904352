import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PlanEstimateParamsDto } from './plan-estimate-params.dto';
import { PlanEstimateDto } from './plan-estimate.dto';

export interface GetsPlanEstimateDtoPort {
  getPlanEstimate(planEstimateParams: PlanEstimateParamsDto): Observable<PlanEstimateDto>;
}

export const GETS_PLAN_ESTIMATE_DTO = new InjectionToken<GetsPlanEstimateDtoPort>(
  'GETS_PLAN_ESTIMATE_DTO',
);

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CancelsIntegrationCommand } from '../../../../application/ports/primary/cancels-integration.command';
import {
  CANCELS_INTEGRATION_COMMAND,
  CancelsIntegrationCommandPort,
} from '../../../../application/ports/primary/cancels-integration.command-port';
// eslint-disable-next-line max-len
import { EntersIntegrationDetailsEditModeCommand } from '../../../../application/ports/primary/enters-integration-details-edit-mode.command';
import {
  EntersIntegrationDetailsEditModeCommandPort,
  ENTERS_INTEGRATION_DETAILS_EDIT_MODE_COMMAND,
} from '../../../../application/ports/primary/enters-integration-details-edit-mode.command-port';
import {
  GETS_INTEGRATION_QUERY,
  GetsIntegrationQueryPort,
} from '../../../../application/ports/primary/gets-integration.query-port';

@Component({
  selector: 'lib-integration-action-buttons',
  templateUrl: './integration-action-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationActionButtonsComponent {
  readonly integrationQuery$ = this._getsIntegrationQuery.getIntegrationQuery();

  constructor(
    @Inject(CANCELS_INTEGRATION_COMMAND)
    private readonly _cancelsIntegrationCommand: CancelsIntegrationCommandPort,
    @Inject(ENTERS_INTEGRATION_DETAILS_EDIT_MODE_COMMAND)
    private readonly _entersIntegrationDetailsEditModeCommand: EntersIntegrationDetailsEditModeCommandPort,
    @Inject(GETS_INTEGRATION_QUERY)
    private readonly _getsIntegrationQuery: GetsIntegrationQueryPort,
  ) {}

  async onCancelIntegrationClicked(): Promise<void> {
    await firstValueFrom(
      this._cancelsIntegrationCommand.cancelIntegration(new CancelsIntegrationCommand()),
    );
  }

  async onEditIntegrationDetailsClicked(): Promise<void> {
    await firstValueFrom(
      this._entersIntegrationDetailsEditModeCommand.enterEditMode(
        new EntersIntegrationDetailsEditModeCommand(),
      ),
    );
  }
}

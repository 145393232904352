<form [formGroup]="form" autocomplete="off">
  <div class="cs-control-wrapper cs-mb-20">
    <label class="cs-label">
      <span>
        {{ '_company_name' | language }}
      </span>
      <span class="cs-c-error">*</span>
    </label>
    <input
      class="cs-input"
      formControlName="agencyName"
      data-selector="team-settings-form-agency-name"
    />
    <p
      *ngIf="form.controls.agencyName.invalid && form.controls.agencyName.touched"
      class="cs-control-error"
      data-selector="team-settings-form-agency-name-required-validation-message"
    >
      {{ '_this_field_is_required' | language }}
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-20">
    <label class="cs-label">
      <span>
        {{ '_billing_email' | language }}
      </span>
      <span class="cs-c-error">*</span>
    </label>
    <input
      class="cs-input"
      type="email"
      formControlName="contactEmail"
      data-selector="team-settings-form-contact-email"
    />
    <p
      *ngIf="form.controls.contactEmail.invalid && form.controls.contactEmail.touched"
      class="cs-control-error"
      data-selector="team-settings-invalid-country-error"
    >
      {{
        (form.controls.contactEmail.hasError('pattern')
          ? '_validation_error_email'
          : '_this_field_is_required'
        ) | language
      }}
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-20">
    <label class="cs-label">
      <span>
        {{ '_country' | language }}
      </span>
      <span class="cs-c-error">*</span>
    </label>
    <input
      type="text"
      class="cs-input"
      formControlName="country"
      [matAutocomplete]="countries"
      data-selector="team-settings-form-country"
    />
    <mat-autocomplete #countries="matAutocomplete" [displayWith]="displayCountryName">
      <mat-option *ngFor="let country of countries$ | async" [value]="country">
        {{ country.name }}
      </mat-option>
    </mat-autocomplete>
    <p
      *ngIf="form.controls.country.invalid && form.controls.country.touched"
      class="cs-control-error"
      data-selector="team-settings-form-country-invalid-message"
    >
      {{
        (form.controls.country.hasError('invalidCountry')
          ? '_invalid_country'
          : '_this_field_is_required'
        ) | language
      }}
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-20">
    <label class="cs-label">
      <span>
        {{ '_vat_number' | language }}
      </span>
      <span class="cs-c-error">*</span>
      <mat-icon
        class="cs-size-xs"
        svgIcon="info"
        matTooltipClass="cs-mat-tooltip"
        [matTooltip]="'_invalid_vat_number_tooltip' | language"
      ></mat-icon>
    </label>

    <div class="d-flex cs-mb-10 cs-ml-2">
      <mat-checkbox
        formControlName="vatNumberToBeProvided"
        class="cs-mat-checkbox cs-mat-checkbox-shade-10 cs-mat-checkbox-without-ripple"
        data-selector="team-settings-form-provide-no-vat-number-checkbox"
      >
        <span class="cs-title-8">{{
          'cbr_pro_agency_settings_i_dont_want_to_provide_vat' | language
        }}</span>
      </mat-checkbox>
    </div>

    <div class="d-flex" *ngIf="!form.get('vatNumberToBeProvided').value">
      <input
        class="cs-input cs-mr-10 flex-1"
        data-selector="team-settings-form-vat-country"
        disabled="true"
        [value]="form.get('country').value?.code ?? ''"
      />
      <input
        class="cs-input vat-number-input"
        formControlName="vatNumber"
        data-selector="team-settings-form-vat-number"
      />
    </div>
    <p
      *ngIf="form.controls.vatNumber.invalid && form.controls.vatNumber.touched"
      class="cs-control-error"
      data-selector="team-settings-form-vat-number-invalid-validation-message"
    >
      {{ 'vatNumber' | teamSettingsFormErrorKey : form.get('vatNumber').errors | language }}
    </p>
    <p
      *ngIf="form.controls.vatNumber.valid && form.controls.vatNumber.touched && showSuccessMessage"
      class="cs-text-2 cs-c-success position-absolute cs-pt-3 cs-success-message"
      data-selector="team-settings-form-vat-number-valid-message"
    >
      {{ '_valid_vat_number' | language }}
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-20">
    <label class="cs-label">
      <span>
        {{ '_address_line_1' | language }}
      </span>
      <span class="cs-c-error">*</span>
    </label>
    <input
      class="cs-input"
      formControlName="addressLine1"
      data-selector="team-settings-form-address-line-1"
    />
    <p
      *ngIf="form.controls.addressLine1.invalid && form.controls.addressLine1.touched"
      class="cs-control-error"
      data-selector="team-settings-form-address-line-1-required-validation-message"
    >
      {{ '_this_field_is_required' | language }}
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-20">
    <label class="cs-label">{{ '_address_line_2' | language }}</label>
    <input
      class="cs-input"
      formControlName="addressLine2"
      data-selector="team-settings-form-address-line-2"
    />
  </div>

  <div class="d-flex">
    <div class="cs-control-wrapper cs-mb-20 cs-width-50-pc-xs cs-mr-5">
      <label class="cs-label">
        <span>
          {{ '_zip_code' | language }}
        </span>
        <span class="cs-c-error">*</span>
      </label>
      <input
        class="cs-input"
        formControlName="zipCode"
        data-selector="team-settings-form-zip-code"
      />
      <p
        *ngIf="form.controls.zipCode.invalid && form.controls.zipCode.touched"
        class="cs-control-error"
        data-selector="team-settings-form-zip-code-validation-message"
      >
        {{ 'zipCode' | teamSettingsFormErrorKey : form.get('zipCode').errors | language }}
      </p>
    </div>

    <div class="cs-control-wrapper cs-mb-20 cs-width-50-pc-xs cs-ml-5">
      <label class="cs-label">
        <span>
          {{ '_city' | language }}
        </span>
        <span class="cs-c-error">*</span>
      </label>
      <input class="cs-input" formControlName="city" data-selector="team-settings-form-city" />
      <p
        *ngIf="form.controls.city.invalid && form.controls.city.touched"
        class="cs-control-error"
        data-selector="team-settings-form-city-required-validation-message"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>
  </div>
</form>

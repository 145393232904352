import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_CREATING_INTEGRATION_QUERY = new InjectionToken<IsCreatingIntegrationQueryPort>(
  'IS_CREATING_INTEGRATION_QUERY',
);

export interface IsCreatingIntegrationQueryPort {
  isCreating(): Observable<boolean>;
}

import { NgModule } from '@angular/core';
import { DELETES_USER_DTO } from '../../../../application/ports/secondary/deletes-user.dto-port';
import { HttpDeletesUserService } from './http-deletes-user.service';

@NgModule({
  providers: [
    HttpDeletesUserService,
    {
      provide: DELETES_USER_DTO,
      useClass: HttpDeletesUserService,
    },
  ],
})
export class HttpDeletesUserServiceModule {}

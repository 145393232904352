import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetsSelectedCountryCommand } from './sets-selected-country.command';

export const SETS_SELECTED_COUNTRY_COMMAND = new InjectionToken<SetsSelectedCountryCommandPort>(
  'SETS_SELECTED_COUNTRY_COMMAND',
);

export interface SetsSelectedCountryCommandPort {
  setSelectedCountry(command: SetsSelectedCountryCommand): Observable<void>;
}

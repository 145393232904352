import { DataLayerTrackingMapper } from '../../../domain/data-layer-tracking-mapper';
import { CobiroProCardsInterestRegisteredEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../../domain/data-layer-tracking-data';

export class DataLayerCobiroProCardsInterestRegisteredEventMapper
  implements DataLayerTrackingMapper
{
  eventClass = CobiroProCardsInterestRegisteredEvent;

  mapEvent(event: CobiroProCardsInterestRegisteredEvent): DataLayerTrackingData {
    return {
      eventName: '/Cobiro Pro - Cards notify',
    };
  }
}

export interface DatadogRumDriverConfig {
  applicationId: string;
  clientToken: string;
  site: string;
  service: string;
  env: string;
  sampleRate: number;
  trackInteractions: boolean;
}

export const makeDatadogConfig = (env: string): DatadogRumDriverConfig => ({
  applicationId: 'ae36c777-dedd-42b9-bbc5-f8be9326c7a0',
  clientToken: 'pubf921e543983a3436c40bad4c80cb3a65',
  site: 'datadoghq.com',
  service: 'app.cobiro.com',
  env,
  sampleRate: 100,
  trackInteractions: true,
});

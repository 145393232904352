import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UPDATE_ERROR_FIELDS } from '../../../domain/update-error-fields';

export interface GetsBillingUpdateErrorQueryPort {
  getBillingUpdateError(): Observable<UPDATE_ERROR_FIELDS>;
}

export const GETS_BILLING_UPDATE_ERROR_QUERY_PORT =
  new InjectionToken<GetsBillingUpdateErrorQueryPort>('GETS_BILLING_UPDATE_ERROR_QUERY');

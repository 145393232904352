import { NgModule } from '@angular/core';
import { GET_USER_TEAMS_DTO } from '../../application/ports/secondary/gets-user-teams-dto.port';
import { HttpGetsUserTeamsService } from './http-gets-user-teams.service';

@NgModule({
  providers: [
    HttpGetsUserTeamsService,
    {
      provide: GET_USER_TEAMS_DTO,
      useExisting: HttpGetsUserTeamsService,
    },
  ],
})
export class HttpGetsUserTeamsServiceModule {}

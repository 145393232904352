import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { HasDataCollection } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { ClientShortcutDto } from '../../../application/ports/secondary/client-shortcuts.dto';
import { GetsAllShortcutsForOneClientDtoPort } from '../../../application/ports/secondary/gets-all-shortcuts-for-one-client.dto-port';

interface ShortcutAttributes {
  url: string;
  imageUrl: string;
  title: string;
}

@Injectable()
export class HttpClientShortcutsService implements GetsAllShortcutsForOneClientDtoPort {
  constructor(private _gateway: GatewayClient) {}

  getAll(siteId: string): Observable<ClientShortcutDto[]> {
    return this._gateway
      .get<HasDataCollection<ShortcutAttributes>>(`v1/sites/${siteId}/shortcuts`)
      .pipe(map(res => res.data.map(d => d.attributes)));
  }
}

import { TeamSettingsDto } from '../../secondary/dto/settings/team-settings.dto';

export class TeamSettingsQuery {
  constructor(
    public readonly agencyName: string,
    public readonly contactEmail: string,
    public readonly addressLine1: string | null,
    public readonly addressLine2: string | null,
    public readonly zipCode: string | null,
    public readonly city: string | null,
    public readonly countryCode: string | null,
    public readonly vatNumber: string | null,
    readonly avatar?: string | null,
  ) {}

  static fromDto(dto: TeamSettingsDto): TeamSettingsQuery {
    return new TeamSettingsQuery(
      dto.agencyName,
      dto.contactEmail,
      dto.addressLine1,
      dto.addressLine2,
      dto.zipCode,
      dto.city,
      dto.countryCode,
      dto.vatNumber,
      dto.avatar || null,
    );
  }
  isProvided(): boolean {
    const { agencyName, contactEmail, countryCode, ...restProps } = this;
    return Object.values(restProps).every(x => x === '' || x === null || x === undefined);
  }
}

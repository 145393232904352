import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserSignedUpEvent } from '@app.cobiro.com/user/register';
import { UserRegisterState } from '@app.cobiro.com/user/core';

@Injectable()
export class UserSignedUpEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserSignedUpEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  // TODO WJ improve UserRegisterState - should use command port here
  constructor(
    private readonly _router: Router,
    private readonly _userRegisterState: UserRegisterState,
  ) {}

  handle([event]: [UserSignedUpEvent]): void {
    this._router
      .navigate(['auth', 'register', 'verify'], {
        queryParams: { userId: event.userId },
      })
      .then(() => {
        this._userRegisterState.updateUser({ email: event.email });
      });
  }
}

<div class="cs-p-40">
  <h2 class="cs-title-4 text-center">
    {{ 'cobiro_pro_client_delete' | language }}
  </h2>

  <p class="cs-mt-40">
    {{ 'cobiro_pro_client_cannot_be_deleted' | language }}
    <a class="cs-link" href="https://support.cobiro.com/hc/en-us"> Cobiro Support </a>.
  </p>

  <div class="d-flex justify-content-center cs-mt-40">
    <button class="cs-btn cs-btn-stroked" mat-dialog-close>
      {{ '_continue' | language }}
    </button>
  </div>
</div>

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UpgradesIntegrationDto } from './upgrades-integration.dto';

export const UPGRADES_INTEGRATION_DTO = new InjectionToken<UpgradesIntegrationDtoPort>(
  'UPGRADES_INTEGRATION_DTO',
);

export interface UpgradesIntegrationDtoPort {
  upgradeIntegration(dto: UpgradesIntegrationDto): Observable<boolean>;
}

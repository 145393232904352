import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const CHANGES_PASSWORD_QUERY = new InjectionToken<ChangesPasswordQueryPort>(
  'CHANGES_PASSWORD_QUERY',
);

export interface ChangesPasswordQueryPort {
  changePassword(oldPassword: string, newPassword: string): Observable<boolean>;
}

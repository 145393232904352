import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProUpgradePlanComponent } from './upgrade-plan.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { CobiroProCardListComponent } from './card-list/card-list.component';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { CobiroProDiscountCodeComponent } from './discout-code/discount-code.component';
import { CobiroProPaymentSummaryComponent } from './payment-summary/payment-summary.component';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [
    CobiroProUpgradePlanComponent,
    CobiroProCardListComponent,
    CobiroProDiscountCodeComponent,
    CobiroProPaymentSummaryComponent,
  ],
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    ReactiveFormsModule,
    HuiLoaderContainerModule,
    HuiLoadingModule,
    SharedAnalyticsModule,
    FeatureFlagsModule,
  ],
  exports: [
    CobiroProCardListComponent,
    CobiroProUpgradePlanComponent,
    CobiroProDiscountCodeComponent,
    CobiroProPaymentSummaryComponent,
  ],
})
export class CobiroProUpradePlanModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IsLastStepVisibleQuery } from './is-last-step-visible.query';

export const GETS_IS_LAST_STEP_VISIBLE_QUERY = new InjectionToken<GetsIsLastStepVisibleQueryPort>(
  'GETS_IS_LAST_STEP_VISIBLE_QUERY',
);

export interface GetsIsLastStepVisibleQueryPort {
  getIsLastStepVisible(): Observable<IsLastStepVisibleQuery>;
}

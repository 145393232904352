import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HasData } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { removeNullProperties } from '@app.cobiro.com/core/utils';
import { GetsPlanEstimateDtoPort } from '../../../../application/ports/secondary/dto/payments/gets-plan-estimate.dto-port';
import { PlanEstimateParamsDto } from '../../../../application/ports/secondary/dto/payments/plan-estimate-params.dto';
import { PlanEstimateDto } from '../../../../application/ports/secondary/dto/payments/plan-estimate.dto';

export interface PlanEstimateAttributes {
  items: PlanEstimateItemAttributes[];
  total: PlanEstimateTotalAttributes;
}

export interface PlanEstimateItemAttributes {
  id: string;
  name: string;
  type: string;
  nettoValue: number;
  grossValue: number;
  currency: string;
  taxValue: number;
  taxPercentage: number;
  grossValueBeforeDiscount: number;
}

export interface PlanEstimateTotalAttributes {
  nettoValue: number;
  grossValue: number;
  currency: string;
  taxValue: number;
  taxPercentage: number;
  grossValueBeforeDiscount: number;
}

@Injectable()
export class HttpGetsPlanEstimateService implements GetsPlanEstimateDtoPort {
  constructor(private readonly _client: GatewayClient) {}
  getPlanEstimate(planEstimateParams: PlanEstimateParamsDto): Observable<PlanEstimateDto> {
    const { planId, countryCode, discountCode, vatNumber } = planEstimateParams;
    const paramsObj = removeNullProperties({ discountCode, vatNumber });
    const params = new HttpParams(paramsObj ? { fromObject: paramsObj } : {});

    return this._client
      .get<HasData<PlanEstimateAttributes>>(
        `v1/payments/plans/${planId}/${countryCode}/price-estimations`,
        {
          params,
        },
      )
      .pipe(
        map(response => PlanEstimateDto.fromPlanEstimateAttributes(response.data.attributes.total)),
      );
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GETS_INTEGRATION_QUERY,
  GetsIntegrationQueryPort,
} from '../../../../application/ports/primary/gets-integration.query-port';

@Component({
  selector: 'lib-integration-paused',
  templateUrl: './integration-paused.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationPausedComponent {
  readonly integration$ = this._getsIntegrationQuery.getIntegrationQuery();

  constructor(
    @Inject(GETS_INTEGRATION_QUERY)
    private readonly _getsIntegrationQuery: GetsIntegrationQueryPort,
  ) {}
}

import { ReactiveSingleValueStorage } from './reactive-single-value.storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * This concept was created to be a bridge helping us to transition from state-oriented architecture to CQS
 */
export class InMemoryReactiveSingleValueStorage<T> implements ReactiveSingleValueStorage<T> {
  private _storage = new BehaviorSubject<T>(null);

  get(): T | null {
    return this._storage.getValue();
  }
  select(): Observable<T | null> {
    return this._storage.asObservable().pipe(shareReplay(1));
  }
  save(value: T): void {
    this._storage.next(value);
  }
  clear(): void {
    this._storage.next(null);
  }
}

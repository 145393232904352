import { NgModule } from '@angular/core';
import { CHANGES_PASSWORD_QUERY } from '../ports/primary/changes-password.query-port';
import { PasswordChangeState } from './password-change.state';

@NgModule({
  providers: [
    PasswordChangeState,
    { provide: CHANGES_PASSWORD_QUERY, useExisting: PasswordChangeState },
  ],
})
export class PasswordChangeStateModule {}

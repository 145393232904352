import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const HAS_SUBSCRIPTIONS_DTO = new InjectionToken<HasSubscriptionsDtoPort>(
  'HAS_SUBSCRIPTIONS_DTO',
);

export interface HasSubscriptionsDtoPort {
  hasSubscriptions(workspaceId: string): Observable<boolean>;
}

import { Observable } from 'rxjs';
import { TeamBillingQuery } from './team-billing.query';
import { InjectionToken } from '@angular/core';

export interface GetsTeamBillingQueryPort {
  getTeamBilling(): Observable<TeamBillingQuery>;
}

export const GETS_TEAM_BILLING_QUERY_PORT = new InjectionToken<GetsTeamBillingQueryPort>(
  'GETS_TEAM_BILLING_QUERY_PORT',
);

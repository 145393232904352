import { NgModule } from '@angular/core';
import { GETS_USER_ROLE_DTO } from '../../../application/ports/secondary/gets-user-role-dto.port';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { HttpClientModule } from '@angular/common/http';
import { HttpGetsUserRoleService } from './http-gets-user-role.service';

@NgModule({
  imports: [HttpClientModule, GatewayModule],
  providers: [
    HttpGetsUserRoleService,
    {
      provide: GETS_USER_ROLE_DTO,
      useExisting: HttpGetsUserRoleService,
    },
  ],
})
export class HttpGetsUserRoleServiceModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const IS_START_PAGE_SHOWN_QUERY = new InjectionToken<IsStartPageShownQueryPort>(
  'IS_START_PAGE_SHOWN_QUERY',
);

export interface IsStartPageShownQueryPort {
  isStartPageShown(clientId: string): Observable<boolean>;
}

import { Inject, Injectable } from '@angular/core';
import { SavesLatestCobiroProLoginDtoPort } from '../../../application/ports/secondary/saves-latest-cobiro-pro-login.dto-port';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { LatestCobiroProLoginDto } from '../../../application/ports/secondary/latest-cobiro-pro-login.dto';
import { GetsLatestCobiroProLoginDtoPort } from '../../../application/ports/secondary/gets-latest-cobiro-pro-login.dto-port';
import { DeleteLatestCobiroProLoginDtoPort } from '../../../application/ports/secondary/delete-latest-cobiro-pro-login.dto-port';

@Injectable()
export class LocalstorageLatestCobiroProLoginService
  implements
    SavesLatestCobiroProLoginDtoPort,
    GetsLatestCobiroProLoginDtoPort,
    DeleteLatestCobiroProLoginDtoPort
{
  private readonly _storageKey = 'lib-pro-latest-login';
  constructor(@Inject(STORAGE) private readonly _storage: Storage) {}

  saveLatestCobiroProLogin(dto: LatestCobiroProLoginDto): void {
    this._storage.setItem(this._storageKey, JSON.stringify(dto));
  }

  getLatestCobiroProLogin(): LatestCobiroProLoginDto | null {
    const storageItem = this._storage.getItem(this._storageKey);
    return storageItem ? JSON.parse(this._storage.getItem(this._storageKey)) : null;
  }

  deleteLatestLogin(): void {
    this._storage.removeItem(this._storageKey);
  }
}

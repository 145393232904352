import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { UserAdditionalDataChangedEvent } from '@app.cobiro.com/core/events';
import { UserAdditionalDataChangedDispatcherPort } from '../../../application/ports/secondary/user-additional-data-changed.dispatcher-port';

@Injectable()
export class ApplicationBusUserAdditionalDataChangedDispatcher
  implements UserAdditionalDataChangedDispatcherPort
{
  constructor(
    @Inject(APPLICATION_BUS) private _dispatcher: Dispatcher<UserAdditionalDataChangedEvent>,
  ) {}
  dispatch(event: UserAdditionalDataChangedEvent) {
    this._dispatcher.dispatch(event);
  }
}

import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { catchError, map } from 'rxjs/operators';
import {
  HasUserId,
  RegistersUser,
  RegistersUserUnknownError,
  UserEmailTakenError,
} from '@app.cobiro.com/user/core';
import { DomainError } from '@app.cobiro.com/core/state';
import { HasData, HasErrorResponse } from '@cobiro/jsonapi';

export interface RegisterUserRequestAttributes {
  email: string;
  password: string;
  partnerId: string;
  utmInterest?: string;
}

@Injectable()
export class HttpRegistersUserService implements RegistersUser {
  private _errors = new Map([[UserEmailTakenError.code, new UserEmailTakenError()]]);
  private readonly _captchaHeaderKey = 'X-Cobiro-Captcha-Token';

  constructor(
    private readonly _client: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  register(
    email: string,
    password: string,
    captchaToken?: string,
    utmInterest?: string,
  ): Observable<HasUserId> {
    const body: HasData<RegisterUserRequestAttributes> = {
      data: {
        type: 'users',
        attributes: {
          email,
          password,
          partnerId: '1',
        },
      },
    };

    if (utmInterest) {
      body.data.attributes.utmInterest = utmInterest;
    }

    const headers = new HttpHeaders({
      ...JSON_API_HEADERS,
      [this._captchaHeaderKey]: captchaToken,
    });

    return this._client
      .post<HasData<never>>(this._getsConfig.get('gateway') + '/v2/users', body, {
        headers,
      })
      .pipe(
        map(response => ({ userId: response.data.id })),
        catchError((err: HasErrorResponse) => {
          return throwError(this._handleError(err));
        }),
      );
  }

  private _handleError(err: HasErrorResponse): DomainError {
    const errorDetail = err?.error?.errors[0];
    const code = errorDetail?.code;
    const error = this._errors.get(code);

    return error ?? new RegistersUserUnknownError(errorDetail?.detail);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { SignedInEvent } from '../../../../core/events/src/lib/user/signed-in/signed-in.event';
import { UserState } from '../application/user.state';

/* tslint:disable:no-console */
@Injectable()
export class SignedInEventHandler implements ApplicationEventsHandler {
  eventsClasses = [SignedInEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  private readonly _redirectToMap = new Map<string, string>([['connect', 'connect']]);
  private readonly _stopListening$ = new Subject<void>();

  constructor(
    private _userState: UserState,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  async handle([event]: SignedInEvent[]): Promise<void> {
    const { userId, refreshToken, tokenType, accessToken } = event;

    console.log('[SignedInEventHandler] got data for:', userId, tokenType);
    console.log(
      '[SignedInEventHandler] current params:',
      this._route.snapshot.queryParams,
      this._route.snapshot.params,
    );

    this._userState.setAuth(userId.toString(), {
      refreshToken,
      tokenType,
      accessToken,
    });

    console.log('[SignedInEventHandler] should redirect?', event.redirect);

    if (event.redirect) {
      console.log('[SignedInEventHandler] getting params');

      const params = await firstValueFrom(this._route.queryParamMap);
      console.log('[SignedInEventHandler] got params:', params);
      console.log('[SignedInEventHandler] starting navigation to', this._getNavigationUrl(params));

      this.debugForRouterEvents();
      await this._router.navigate(this._getNavigationUrl(params));
      this.finishDebugForRouterEvents();

      console.log('[SignedInEventHandler] navigation finished');
    }
  }

  private _getNavigationUrl(params: ParamMap): string[] {
    if (params.has('siteId')) {
      return ['/site', params.get('siteId'), 'builder'];
    } else if (params.has('redirectTo')) {
      return [this._redirectToMap.get(params.get('redirectTo'))];
    } else {
      return ['connect'];
    }
  }

  private debugForRouterEvents(): void {
    this._router.events.pipe(takeUntil(this._stopListening$)).subscribe(console.log);
  }

  private finishDebugForRouterEvents(): void {
    this._stopListening$.next();
  }
}

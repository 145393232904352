<div class="cs-control-wrapper">
  <input
    [type]="inputPassword ? 'password' : 'text'"
    [formControl]="passwordFormControl"
    autocomplete="off"
    class="cs-input cs-input-with-icon-right"
    [placeholder]="placeholder"
    data-selector="register-password-input"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown.enter)="onEnterPressed($event)"
    #inputElement
  />
  <div
    class="password-validator-hint cs-width-240-xs cs-bg-shade-100 cs-c-shade-0 d-flex flex-column justify-content-center position-absolute align-items-center cs-p-30 br-5"
  >
    <p class="cs-text-2 cs-c-shade-0 w-100 cs-mb-5">
      {{ 'user_password_input_make_sure' | language }}
    </p>
    <ul class="w-100 cs-list cs-list-none">
      <li
        class="cs-text-2 cs-c-shade-0 d-flex align-items-center cs-mb-5"
        *ngFor="let hint of validationHints"
      >
        <mat-icon
          [inline]="true"
          class="cs-mr-5"
          [class.cs-c-success]="
            passwordFormControl.value &&
            (!passwordFormControl.errors || !passwordFormControl?.errors[hint.errorName])
          "
          >check_circle</mat-icon
        >
        {{ hint.text | language: hint.translationParams }}
      </li>
    </ul>
  </div>

  <div class="cs-pr-10 h-100 cs-cursor-pointer" (click)="inputPassword = !inputPassword">
    <mat-icon svgIcon="eye-opened" tabindex="-1" *ngIf="inputPassword" class="cs-input-icon-right">
    </mat-icon>
    <mat-icon svgIcon="eye-closed" tabindex="-1" *ngIf="!inputPassword" class="cs-input-icon-right">
    </mat-icon>
  </div>
</div>
<div class="cs-min-height-20-xs" *ngIf="!disableErrorMessage">
  <p *ngIf="passwordFormControl.touched && passwordFormControl.invalid" class="cs-control-error">
    {{ 'user_input_not_password' | language }}
  </p>
</div>

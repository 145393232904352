import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadsIntegrationCommand } from './loads-integration.command';

export const LOADS_INTEGRATION_COMMAND = new InjectionToken<LoadsIntegrationCommandPort>(
  'LOADS_INTEGRATION_COMMAND',
);

export interface LoadsIntegrationCommandPort {
  loadIntegration(command: LoadsIntegrationCommand): Observable<void>;
}

import { NgModule } from '@angular/core';
import { HttpClientServicesService } from './http-client-services.service';
import { GETS_ONE_CLIENT_SERVICES_DTO } from '../../../application/ports/secondary/gets-one-client-services.dto-port';
import { GETS_ALL_CLIENTS_SERVICES_DTO } from '../../../application/ports/secondary/gets-all-clients-services.dto-port';

@NgModule({
  providers: [
    HttpClientServicesService,
    { provide: GETS_ONE_CLIENT_SERVICES_DTO, useExisting: HttpClientServicesService },
    { provide: GETS_ALL_CLIENTS_SERVICES_DTO, useExisting: HttpClientServicesService },
  ],
})
export class HttpClientServicesServiceModule {}

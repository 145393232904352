import { ApplicationEvent } from '@cobiro/eda';

export class LabelManagerCloseRecommendationEvent extends ApplicationEvent {
  constructor(
    public readonly id: string,
    public readonly integrationId: string,
    public readonly userId: string,
    public readonly timestamp: number,
    public readonly analysisTimestamp: string,
  ) {
    super();
  }
}

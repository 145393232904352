import { Inject, Injectable } from '@angular/core';
import { UserDTO } from '../../../application/ports/secondary/dto/user.dto';
import { UsersListChangedDispatcherPort } from '../../../application/ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import { UsersListChangedEvent } from '../../../application/events/users-list-changed.event';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';

@Injectable()
export class UsersListChangedEventDispatcher implements UsersListChangedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { updatedUsers: UserDTO[] }) {
    this._applicationBus.dispatch(new UsersListChangedEvent(event.updatedUsers));
  }
}

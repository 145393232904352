import { InjectionToken } from '@angular/core';
import { SetsSelectedCardCommand } from './sets-selected-card.command';

export interface SetsSelectedCardCommandPort {
  setCard(command: SetsSelectedCardCommand): void;
}

export const SETS_SELECTED_CARD_COMMAND = new InjectionToken<SetsSelectedCardCommandPort>(
  'SETS_SELECTED_CARD_COMMAND',
);

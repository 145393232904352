import { Inject, Injectable } from '@angular/core';
import { GetsClientsListPaginationQueryPort } from '../ports/primary/gets-clients-list-pagination.query-port';
import { SetsClientsListPageCommandPort } from '../ports/primary/sets-clients-list-page.command-port';
import { ResetsClientsListPaginationCommandPort } from '../ports/primary/resets-clients-list-pagination.command-port';
import { GetsPaginatedClientsQueryPort } from '../ports/primary/gets-paginated-clients.query-port';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { ClientDTO } from '../ports/secondary/client.dto';
import { FrontendPagination, PaginationQuery, SORT_ORDER } from '@app.cobiro.com/core/pagination';
import {
  GETS_ALL_CLIENT_DTO,
  GetsAllClientDtoPort,
} from '../ports/secondary/gets-all-client.dto-port';
import { GetsAllClientQueryPort } from '../ports/primary/gets-all-client-query.port';
import { filter, map, take, tap } from 'rxjs/operators';
import { LoadClientsCommand } from '../ports/primary/load-clients.command';
import {
  CLIENTS_LIST_LOADED_DISPATCHER,
  ClientsListLoadedDispatcherPort,
} from '../ports/secondary/clients-list-loaded.dispatcher-port';
import { ClientListItemQuery } from '../ports/primary/client-list-item.query';
import { LoadClientsCommandPort } from '../ports/primary/load-clients-command.port';
import { StoresClientsListCommandPort } from '../ports/primary/stores-clients-list.command-port';
import { StoresNewClientCommandPort } from '../ports/primary/stores-new-client.command-port';
import { SetsSearchPhraseCommandPort } from '../ports/primary/sets-search-phrase.command-port';
import {
  GETS_ONE_CLIENT_SERVICES_DTO,
  GetsOneClientServicesDtoPort,
} from '../ports/secondary/gets-one-client-services.dto-port';
import { ClientServicesQuery } from '../ports/primary/client-service.query';
import { GetsClientServicesQueryPort } from '../ports/primary/gets-client-services.query-port';
import { SetsClientsListFilterCommandPort } from '../ports/primary/sets-clients-list-filter.command-port';
import { SetsClientsListSortCommandPort } from '../ports/primary/sets-clients-list-sort.command-port';
import { SetsClientsListSortCommand } from '../ports/primary/sets-clients-list-sort.command';
import { ClientShortcutDto } from '../ports/secondary/client-shortcuts.dto';
import {
  GETS_ALL_SHORTCUTS_FOR_ONE_CLIENT_DTO_PORT,
  GetsAllShortcutsForOneClientDtoPort,
} from '../ports/secondary/gets-all-shortcuts-for-one-client.dto-port';

// TODO (PRO-DEBT): Refactor to a smaller state uses a injected storage provider which keeps the list with
// client dtos and pagination + make command handlers with those storages for commands that affect pagination.
@Injectable()
export class ClientsListState
  implements
    GetsAllClientQueryPort,
    GetsClientsListPaginationQueryPort,
    SetsClientsListPageCommandPort,
    ResetsClientsListPaginationCommandPort,
    GetsPaginatedClientsQueryPort,
    LoadClientsCommandPort,
    StoresClientsListCommandPort,
    StoresNewClientCommandPort,
    SetsSearchPhraseCommandPort,
    GetsClientServicesQueryPort,
    SetsClientsListFilterCommandPort,
    SetsClientsListSortCommandPort
{
  private _allClientsSubject$: ReplaySubject<ClientDTO[]> = new ReplaySubject<ClientDTO[]>(1);
  private _clientServices = new Map<string, BehaviorSubject<string[]>>();
  private _clientsShortcuts = new Map<string, BehaviorSubject<ClientShortcutDto[]>>();
  private readonly _allClientsPagination: FrontendPagination<ClientDTO> =
    FrontendPagination.fromRaw(
      this._allClientsSubject$.asObservable(),
      0,
      10,
      ['companyName', SORT_ORDER.ASC],
      {},
    );

  constructor(
    @Inject(GETS_ALL_CLIENT_DTO) private _getAllClientDTO: GetsAllClientDtoPort,
    @Inject(CLIENTS_LIST_LOADED_DISPATCHER)
    private _clientsListLoadedDispatcher: ClientsListLoadedDispatcherPort,
    @Inject(GETS_ONE_CLIENT_SERVICES_DTO)
    private _getsOneClientServices: GetsOneClientServicesDtoPort,
    @Inject(GETS_ALL_SHORTCUTS_FOR_ONE_CLIENT_DTO_PORT)
    private _getsAllShortcutsForOneClient: GetsAllShortcutsForOneClientDtoPort,
  ) {}

  getAllClientQuery(): Observable<ClientListItemQuery[]> {
    return this._allClientsSubject$
      .asObservable()
      .pipe(filter(clients => !!clients))
      .pipe(
        map(clients => {
          return this._mapClientsDtoToListItemQuery(clients);
        }),
      );
  }

  getsPaginatedClients(): Observable<ClientListItemQuery[]> {
    return this._allClientsPagination
      .getPaginatedList()
      .pipe(map(clients => this._mapClientsDtoToListItemQuery(clients)));
  }

  loadClients(command: LoadClientsCommand): Observable<ClientDTO[]> {
    return this._getAllClientDTO.getAll({ teamId: command.teamId }).pipe(
      tap(clients => {
        this._clientsListLoadedDispatcher.dispatch({ clients, teamId: command.teamId });
      }),
    );
  }

  storeClientsList(clients: ClientDTO[]): void {
    this._allClientsSubject$.next(clients);
    this._clientServices = new Map<string, BehaviorSubject<string[]>>(
      clients.map(client => [client.sitePublicId, new BehaviorSubject<string[]>([])]),
    );
    this._clientsShortcuts = new Map<string, BehaviorSubject<ClientShortcutDto[]>>(
      clients.map(client => [client.sitePublicId, new BehaviorSubject<ClientShortcutDto[]>([])]),
    );
  }

  getClientListPagination(): Observable<PaginationQuery> {
    return this._allClientsPagination.getPaginationQuery();
  }

  setClientsListPage(pageIndex: number): void {
    this._allClientsPagination.setPage(pageIndex);
  }

  resetClientListPagination(): void {
    this._allClientsPagination.reset();
  }

  getTotalClients(): Observable<number> {
    return this._allClientsPagination.total;
  }

  storeNewClient(client: ClientDTO): void {
    this._allClientsSubject$.pipe(take(1)).subscribe(clients => {
      this._allClientsSubject$.next([...clients, client]);
    });
    this._clientServices.set(client.sitePublicId, new BehaviorSubject<string[]>([]));
    this._clientsShortcuts.set(client.sitePublicId, new BehaviorSubject<ClientShortcutDto[]>([]));
  }

  setSearchPhrase(phrase: string): void {
    this._allClientsPagination.setPage(0);
    this._allClientsPagination.setSearch(phrase);
  }

  setFilter(key: keyof ClientDTO, value: any): void {
    this._allClientsPagination.setPage(0);
    this._allClientsPagination.setFilter(key, value);
  }

  setSort(command: SetsClientsListSortCommand): void {
    this._allClientsPagination.setPage(0);
    this._allClientsPagination.setSorting(
      command.key as keyof ClientDTO,
      command.direction.toUpperCase() as SORT_ORDER,
    );
  }

  getClientServices(siteId: string, sitePublicId: string): Observable<ClientServicesQuery> {
    if (siteId === null) {
      return of(new ClientServicesQuery([], sitePublicId));
    }
    if (!this._clientServices.get(sitePublicId).value.length) {
      this._fetchClientServices(siteId, sitePublicId).pipe(take(1)).subscribe();
    }
    if (!this._clientsShortcuts.get(sitePublicId).value.length) {
      this._fetchClientShortcuts(siteId, sitePublicId).pipe(take(1)).subscribe();
    }
    return this._getClientServicesQuery(sitePublicId);
  }

  private _getClientServicesQuery(sitePublicId: string): Observable<ClientServicesQuery> {
    return combineLatest([
      this._clientServices.get(sitePublicId).asObservable(),
      this._clientsShortcuts.get(sitePublicId).asObservable(),
      this._allClientsSubject$,
    ]).pipe(
      map(([services, shortcuts, clients]) =>
        ClientServicesQuery.fromRaw(
          services,
          shortcuts,
          clients.find(client => client.sitePublicId === sitePublicId)?.source,
          sitePublicId,
        ),
      ),
    );
  }

  private _fetchClientServices(siteId: string, sitePublicId: string): Observable<string[]> {
    return this._getsOneClientServices.getOne(siteId, sitePublicId).pipe(
      map(clientServicesDto => clientServicesDto.services),
      tap(services => {
        this._clientServices.get(sitePublicId).next(services);
      }),
    );
  }

  private _fetchClientShortcuts(
    siteId: string,
    sitePublicId: string,
  ): Observable<ClientShortcutDto[]> {
    return this._getsAllShortcutsForOneClient.getAll(siteId).pipe(
      tap(clientShortcutDtos => {
        this._clientsShortcuts.get(sitePublicId).next(clientShortcutDtos);
      }),
    );
  }

  private _mapClientsDtoToListItemQuery(clients: ClientDTO[]): ClientListItemQuery[] {
    return clients.map(clientDto => ClientListItemQuery.fromClientDTO(clientDto));
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  GetsSelectedPlanQueryPort,
  GETS_SELECTED_PLAN_QUERY,
} from '../../../../../../../../application/ports/primary/payments/gets-selected-plan.query-port';
import {
  PaymentPlanOptionQuery,
  PaymentPlanPeriod,
} from '../../../../../../../../application/ports/primary/payments/payment-plan-options.query';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import {
  GetsPaymentPlansQueryPort,
  GETS_PAYMENT_PLANS_QUERY,
} from '../../../../../../../../application/ports/primary/payments/gets-payment-plans.query-port';

import {
  GetsCurrentClientQueryPort,
  GETS_CURRENT_CLIENT_QUERY,
} from '../../../../../../../../application/ports/primary/setup/gets-current-client.query-port';

@Component({
  selector: 'lib-cobiro-pro-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionListComponent implements OnChanges {
  productName: string;
  bestValue: string;

  @Input() set product(value: string) {
    this.productName = value;
  }
  @Output() private savedPlanClicked = new EventEmitter<string>();
  upgradablePlans$: Observable<PaymentPlanOptionQuery[]>;
  readonly selectedPaymentPlan$: Observable<string> = this._getsSelectedPlan.getPlan();
  private readonly _bestValue = new BehaviorSubject<string | null>(null);
  readonly bestValue$ = this._bestValue.asObservable();

  constructor(
    @Inject(GETS_PAYMENT_PLANS_QUERY)
    private readonly _getsPaymentPlans: GetsPaymentPlansQueryPort,
    @Inject(GETS_SELECTED_PLAN_QUERY)
    private readonly _getsSelectedPlan: GetsSelectedPlanQueryPort,
    @Inject(GETS_CURRENT_CLIENT_QUERY)
    private readonly _getsCurrentClientQueryPort: GetsCurrentClientQueryPort,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      this.upgradablePlans$ = this._getsCurrentClientQueryPort.getCurrentClient().pipe(
        switchMap(client =>
          this._getsPaymentPlans
            .getPaymentPlans({
              productName: this.productName,
              clientId: client.id,
            })
            .pipe(
              tap(res => {
                this._bestValue.next(res.find(plan => plan.period === PaymentPlanPeriod.YEARLY).id);
              }),
            ),
        ),
      );
    }
  }

  goToProduct(planId: string): void {
    this.savedPlanClicked.emit(planId);
  }
}

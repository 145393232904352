import { DomainError } from '@app.cobiro.com/core/state';

export class PasswordConfirmationVO {
  public readonly value: string;

  constructor(password: string, passwordConfirmation: string) {
    if (password !== passwordConfirmation) {
      throw new InvalidPasswordError();
    }

    this.value = password;
  }
}

export class InvalidPasswordError extends DomainError {
  static readonly id = 'INVALID_PASSWORD_ERROR';

  constructor() {
    super('Passwords do not match', 'INVALID_PASSWORD_ERROR');
  }
}

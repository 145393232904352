<section class="cs-container h-100">
  <div class="d-flex justify-content-center flex-column align-items-center h-100">
    <div
      class="cs-card cs-shadow-shade-10 br-15 cs-b-none cs-asset-bg-gradient-1 cs-bg-size-contain cs-bg-shade-0 cs-min-width-700-lg cs-min-width-100-pc cs-max-width-700-xs cs-pv-40 cs-ph-80-lg cs-ph-20-sm"
    >
      <header
        class="cs-card-header flex-column justify-content-center align-items-center cs-pt-0 cs-m-0"
      >
        <img class="cs-height-30-xs" src="assets/logos/cobiro-logo-with-text.svg" alt="Cobiro" />
        <h2 class="cs-title-3 text-center cs-mt-15 cs-mb-40">
          {{ 'login_set_up_new_password' | language }}
        </h2>
      </header>

      <lib-password-form [placeholder]="'_password' | language" [formControl]="control('password')">
        <p class="cs-text-1 cs-c-shade-100 cs-mb-5">{{ 'login_new_password' | language }}</p>
      </lib-password-form>

      <form class="cs-form cs-mt-20" [formGroup]="form">
        <div class="cs-control-wrapper">
          <input
            [type]="inputPasswordConfirmed ? 'password' : 'text'"
            formControlName="passwordConfirmation"
            class="cs-input cs-input-with-icon-right"
            [placeholder]="'login_retype_password' | language"
            data-selector="register-password-input"
          />

          <div (click)="inputPasswordConfirmed = !inputPasswordConfirmed">
            <mat-icon
              svgIcon="eye-opened"
              tabindex="-1"
              *ngIf="inputPasswordConfirmed"
              class="cs-input-icon-right"
            >
            </mat-icon>
            <mat-icon
              svgIcon="eye-closed"
              tabindex="-1"
              *ngIf="!inputPasswordConfirmed"
              class="cs-input-icon-right"
            >
            </mat-icon>
          </div>
        </div>

        <div class="cs-height-20-xs">
          <p *ngIf="passwordsNotSame" class="cs-control-error">
            {{ 'login_password_not_match' | language }}
          </p>
        </div>

        <button
          class="cs-btn cs-btn-primary w-100 d-flex align-items-center justify-content-center cs-mt-40"
          data-analytics="user-login-continue-password"
          [disabled]="
            control('password').invalid ||
            control('passwordConfirmation').value !== control('password').value
          "
          type="submit"
          [huiLoading]="isLoading$ | async"
          data-selector="continue-password"
          (click)="onContinueButtonClicked()"
        >
          <span class="cs-c-shade-0">
            {{ '_continue' | language }}
          </span>
        </button>
      </form>
    </div>
    <a class="cs-link d-block cs-pl-10 cs-mt-15" href="https://cobiro.com"
      >{{ '_back_to' | language }} cobiro.com</a
    >
  </div>
</section>

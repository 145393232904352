/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  catchError,
  concatMap,
  map,
  mergeAll,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs';
import { GetsCompanyTypesQueryPort } from '../ports/primary/gets-company-types.query-port';
import { GetsNumberOfClientsQueryPort } from '../ports/primary/gets-number-of-clients.query-port';
import { GetsNumberOfEmployeesQueryPort } from '../ports/primary/gets-number-of-employees.query-port';
import { CompanyTypeQuery } from '../ports/primary/company-type.query';
import { NumberOfClientsQuery } from '../ports/primary/number-of-clients.query';
import { NumberOfEmployeesQuery } from '../ports/primary/number-of-employees.query';
import { GetsCompanyDetailsQueryPort } from '../ports/primary/gets-company-details.query-port';
import { CompanyDetailsQuery } from '../ports/primary/company-details.query';
import {
  SAVES_COMPANY_DETAILS_DTO_PORT,
  SavesCompanyDetailsDtoPort,
} from '../ports/secondary/saves-company-details.dto-port';
import {
  GETS_COMPANY_DETAILS_DTO_PORT,
  GetsCompanyDetailsDtoPort,
} from '../ports/secondary/gets-company-details.dto-port';
import { SavesCompanyDetailsQueryPort } from '../ports/primary/saves-company-details.query-port';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
  TEAM_ID_GETTER,
  TeamIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  CobiroProCompanyDeletedEvent,
  CobiroProTeamsLoadedEvent,
} from '@app.cobiro.com/core/events';
import { GETS_ALL_TEAMS_DTO, GetsAllTeamsDtoPort } from '../ports/secondary/gets-all-team-dto.port';
import { GetsAllTeamQueryPort } from '../ports/primary/gets-all-team.query-port';
import { TeamQuery } from '../ports/primary/team.query';
import { HasSubscriptionsQueryPort } from '../ports/primary/has-subscriptions.query-port';
import {
  HAS_SUBSCRIPTIONS_DTO,
  HasSubscriptionsDtoPort,
} from '../ports/secondary/has-subscriptions.dto-port';
import {
  GETS_ALL_WORKSPACES_DTO,
  GetsAllWorkspacesDtoPort,
} from '../ports/secondary/gets-all-workspaces.dto-port';
import { WorkspaceDto } from '../ports/secondary/workspace.dto';
import { DeletesTeamCommandPort } from '../ports/primary/deletes-team.command-port';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTeamModalComponent } from '../../adapters/primary/ui/delete-team-modal/delete-team-modal.component';
import { DeletesTeamCommand } from '../ports/primary/deletes-team.command';
import {
  DELETES_COMPANY_DTO_PORT,
  DeletesCompanyDtoPort,
} from '../ports/secondary/deletes-company.dto-port';

enum CompanyType {
  Agency = 'agency',
  Individual = 'individual',
  EcommerceMerchant = 'ecommerce_merchant',
}

@Injectable()
export class CompanyDetailsState
  implements
    GetsCompanyTypesQueryPort,
    GetsNumberOfClientsQueryPort,
    GetsNumberOfEmployeesQueryPort,
    SavesCompanyDetailsQueryPort,
    GetsCompanyDetailsQueryPort,
    GetsAllTeamQueryPort,
    HasSubscriptionsQueryPort,
    DeletesTeamCommandPort
{
  constructor(
    @Inject(DELETES_COMPANY_DTO_PORT)
    private readonly _deletesCompanyDtoPort: DeletesCompanyDtoPort,
    @Inject(SAVES_COMPANY_DETAILS_DTO_PORT)
    private readonly _savesCompanyDetailsDtoPort: SavesCompanyDetailsDtoPort,
    @Inject(GETS_COMPANY_DETAILS_DTO_PORT)
    private readonly _getsCompanyDetailsDtoPort: GetsCompanyDetailsDtoPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(GETS_ALL_TEAMS_DTO)
    private readonly _getsAllTeamsDtoPort: GetsAllTeamsDtoPort,
    @Inject(HAS_SUBSCRIPTIONS_DTO)
    private readonly _hasSubscriptionsDtoPort: HasSubscriptionsDtoPort,
    @Inject(GETS_ALL_WORKSPACES_DTO)
    private readonly _getsAllWorkspacesDtoPort: GetsAllWorkspacesDtoPort,
    private readonly _alert: HuiAlert,
    private readonly _matDialog: MatDialog,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
  ) {}

  deleteTeam(command: DeletesTeamCommand): Observable<boolean> {
    return this._matDialog
      .open(DeleteTeamModalComponent, {
        minWidth: '400px',
        maxWidth: '700px',
        panelClass: 'cs-mat-dialog',
        data: {
          id: command.id,
          name: command.name,
        },
      })
      .afterClosed()
      .pipe(concatMap(result => (result ? this._deleteTeam(command.id) : of(false))));
  }

  private _deleteTeam(id: string): Observable<boolean> {
    return this._deletesCompanyDtoPort.deleteCompany(id).pipe(
      tap({
        next: () => {
          this._applicationBus.dispatch(new CobiroProCompanyDeletedEvent(id));
          this._alert.open('success', 'cobiro_pro_company_deleted_success');
        },
        error: () => this._alert.open('error', 'cobiro_pro_company_deleted_failed'),
      }),
    );
  }

  hasSubscriptions(): Observable<boolean> {
    return this._getsAllWorkspacesDtoPort.getsAll(this._teamIdGetter.getTeamId()).pipe(
      mergeAll(),
      mergeMap((workspace: WorkspaceDto) =>
        this._hasSubscriptionsDtoPort.hasSubscriptions(workspace.id),
      ),
      toArray(),
      map((res: boolean[]) => res.some(item => item === true)),
    );
  }

  getAllTeamQuery(): Observable<TeamQuery[]> {
    return this._getsAllTeamsDtoPort.getsAll({});
  }

  savesCompanyDetail(dto: CompanyDetailsQuery): Observable<boolean> {
    return this._savesCompanyDetailsDtoPort.savesCompanyDetail(dto).pipe(
      switchMap(() => this._getsCobiroProContextQuery.getContext()),
      take(1),
      switchMap((context: CobiroProContextQuery) =>
        this._setsSelectedTeamCommand.setSelectedTeam(
          context.selectedTeamId,
          dto.companyName,
          context.selectedTeamUserRole,
          context.selectedTeamAvatar,
        ),
      ),
      map(() => true),
      tap({
        next: () =>
          this._alert.open('success', 'cobiro_pro_workspace_settings_form_submit_success'),
        error: () => this._alert.open('error', 'cobiro_pro_workspace_settings_form_submit_error'),
      }),
      catchError(() => of(false)),
    );
  }

  getCompanyDetails(): Observable<CompanyDetailsQuery> {
    const id = this._teamIdGetter.getTeamId();
    return this._getsCompanyDetailsDtoPort.getCompanyDetails(id);
  }

  getAvailableCompanyTypes(): Observable<CompanyTypeQuery[]> {
    return of([
      new CompanyTypeQuery('cobiro_pro_company_details_type_agency', CompanyType.Agency),
      new CompanyTypeQuery('cobiro_pro_company_details_type_freelancer', CompanyType.Individual),
      new CompanyTypeQuery(
        'cobiro_pro_company_details_type_merchant',
        CompanyType.EcommerceMerchant,
      ),
    ]);
  }

  getAvailableNumberOfClients(): Observable<NumberOfClientsQuery[]> {
    return of([
      new NumberOfClientsQuery('1', 1),
      new NumberOfClientsQuery('2-5', 5),
      new NumberOfClientsQuery('6-10', 10),
      new NumberOfClientsQuery('11-20', 20),
      new NumberOfClientsQuery('> 21', 21),
    ]);
  }

  getAvailableNumberOfEmployees(): Observable<NumberOfEmployeesQuery[]> {
    return of([
      new NumberOfEmployeesQuery('1-10', 10),
      new NumberOfEmployeesQuery('11-25', 25),
      new NumberOfEmployeesQuery('26-50', 50),
      new NumberOfEmployeesQuery('51-100', 100),
    ]);
  }
}

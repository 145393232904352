import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Card, CardHolder } from './card';
import { CobiroPaymentConfirmation } from './cobiro-payment-confirmation';

export interface Checkout {
  processCardPayment: (
    card: Card,
    productId: string,
    cardHolder: CardHolder,
    countryCode: string,
    discountCode?: string,
  ) => Observable<CobiroPaymentConfirmation>;
}

export const CHECKOUT = new InjectionToken<Checkout>('CHECKOUT');

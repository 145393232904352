import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

interface HeadersLike {
  [name: string]: string | string[];
}

/**
 * Describes base of the endpoint url
 * example https://gateway.cobiro.com
 */
export const BASE_URL = new InjectionToken<string>('BASE_URL');

/**
 * The Http headers to send with all requests
 */
export const HEADERS = new InjectionToken<HeadersLike>('HEADERS');

/**
 * Additional _client options to send with request
 */
export interface GatewayClientOptions {
  params?: HttpParams;
  withCredentials?: boolean;
  additionalHeaders?: { [name: string]: string };
}

export interface PartialHttpOptions {
  headers?: HttpHeaders | HeadersLike;
  params?: HttpParams | HeadersLike;
}

@Injectable()
export class GatewayClient {
  constructor(
    private _httpClient: HttpClient,
    @Inject(BASE_URL) private _baseUrl: string,
    @Inject(HEADERS) private _headers: HeadersLike,
  ) {}

  get<T>(url: string, options?: GatewayClientOptions): Observable<T> {
    return this._httpClient.get<T>(this._makeUrl(url), this._makeHttpOptions(options));
  }

  post<T>(url: string, body: any | null, options?: GatewayClientOptions): Observable<T> {
    return this._httpClient.post<T>(this._makeUrl(url), body, this._makeHttpOptions(options));
  }

  put<T>(url: string, body: any | null, options?: GatewayClientOptions): Observable<T> {
    return this._httpClient.put<T>(this._makeUrl(url), body, this._makeHttpOptions(options));
  }

  patch<T>(url: string, body: any | null, options?: GatewayClientOptions): Observable<T> {
    return this._httpClient.patch<T>(this._makeUrl(url), body, this._makeHttpOptions(options));
  }

  delete<T>(url: string, options?: GatewayClientOptions): Observable<T> {
    return this._httpClient.delete<T>(this._makeUrl(url), this._makeHttpOptions(options));
  }

  private _makeUrl(url: string): string {
    return this._baseUrl + '/' + url;
  }

  private _makeHttpOptions(options?: GatewayClientOptions): PartialHttpOptions {
    const additionalHeaders = options?.additionalHeaders || {};
    return {
      headers: new HttpHeaders({ ...this._headers, ...additionalHeaders }),
      params: options?.params ? options.params : undefined,
    };
  }
}

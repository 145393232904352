import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  SavesTeamSettingsCommand,
  SAVES_TEAM_SETTINGS_COMMAND,
} from '../../../../../../application/ports/primary/settings/saves-team-settings.command-port';
import { TeamSettingsQuery } from '../../../../../../application/ports/primary/settings/team-settings.query';
import {
  GoesToPreviousStepCommandPort,
  GOES_TO_PREVIOUS_STEP_COMMAND,
} from '../../../../../../application/ports/primary/setup/goes-to-previous-step.command-port';
import { BehaviorSubject, map, take } from 'rxjs';
import {
  GoesToNextStepCommandPort,
  GOES_TO_NEXT_STEP_COMMAND,
} from '../../../../../../application/ports/primary/setup/goes-to-next-step.command-port';

@Component({
  selector: 'lib-cobiro-pro-add-billing-address',
  templateUrl: './add-billing-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBillingAddressComponent {
  private _isValidForm$ = new BehaviorSubject<boolean>(false);
  private _isProcessing$ = new BehaviorSubject<boolean>(false);
  private _billingAddress$ = new BehaviorSubject<TeamSettingsQuery>(null);

  readonly isInvalid$ = this._isValidForm$.asObservable().pipe(map(res => !res));
  readonly isProcessing$ = this._isProcessing$.asObservable();

  constructor(
    @Inject(GOES_TO_NEXT_STEP_COMMAND)
    private readonly _goesToNextStepCommandPort: GoesToNextStepCommandPort,
    @Inject(GOES_TO_PREVIOUS_STEP_COMMAND)
    private readonly _goesToPreviousStepCommandPort: GoesToPreviousStepCommandPort,
    @Inject(SAVES_TEAM_SETTINGS_COMMAND)
    private readonly _savesWorkspaceSettingsCommand: SavesTeamSettingsCommand,
  ) {}

  onFormGroupChange(form: TeamSettingsQuery): void {
    this._billingAddress$.next(form);
  }

  onValidityChange(isValid: boolean): void {
    this._isValidForm$.next(isValid);
  }

  onFormSubmit(): void {
    this._isProcessing$.next(true);
    this._savesWorkspaceSettingsCommand
      .saveTeamSettings(this._billingAddress$.getValue())
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this._goesToNextStepCommandPort.goToNextStep();
        }
        this._isProcessing$.next(false);
      });
  }

  goToPreviusStep(): void {
    this._goesToPreviousStepCommandPort.goToPreviousStep();
  }
}

import { InjectionToken } from '@angular/core';

export const BASE_URL = new InjectionToken<string>('BASE_URL');
export const WINDOW = new InjectionToken<Window>('WINDOW');
export const FILE_READER = new InjectionToken<FileReader>('FILE_READER');
export const provideFileReader = () => {
  return {
    provide: FILE_READER,
    useFactory: () => new FileReader(),
  };
};

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserDetailQuery } from '../primary/user-details.query';

export const GETS_USER_DETAILS_DTO = new InjectionToken<GetsUserDetailsDtoPort>(
  'GETS_USER_DETAILS_DTO',
);

export interface GetsUserDetailsDtoPort {
  get(userId: string): Observable<UserDetailQuery>;
}

import { BehaviorSubject, combineLatest, fromEvent, interval, Observable, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { NotificationListElementQuery } from '../query/notification-list-element.query';
import { Notification, NOTIFICATION_TYPES } from '../domain/notification';
import { filter, map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';
import { MARKS_AS_READ, MarksAsRead } from '../domain/marks-as-read';
import { GETS_NOTIFICATIONS, GetsNotifications } from '../domain/gets-notifications';
import { MatDialog } from '@angular/material/dialog';
import { MessageNotificationModalComponent } from '../ui/message-notification-modal/message-notification-modal.component';
import { MessageNotificationQuery } from '../query/message-notification.query';
import { DOCUMENT } from '@angular/common';
import { FORM_TYPES, MessageNotification } from '../domain/messages/message-notification';

export interface HandlesNotifications {
  siteId$: Observable<string>;
  notificationsList$: Observable<NotificationListElementQuery[]>;
  unreadNotificationsCount$: Observable<number>;
  openNotification(id: string): void;
  fetchNotifications(): void;
  stopFetchingNotifications(): void;
  setSiteId(siteId: string): void;
}

@Injectable()
export class NotificationsState implements HandlesNotifications {
  private readonly _siteId = new BehaviorSubject<string | null>(null);
  private readonly _notifications = new BehaviorSubject<Notification[]>([]);
  private readonly _pollingInterval = 60000;
  private readonly _cancelPolling$ = new Subject<void>();
  private readonly _isTabActive$: Observable<boolean> = fromEvent(
    this._document,
    'visibilitychange',
  ).pipe(
    map(() => this._document.visibilityState),
    startWith('visible'),
    map(status => status === 'visible'),
  );

  constructor(
    @Inject(MARKS_AS_READ) private _marksAsRead: MarksAsRead,
    @Inject(GETS_NOTIFICATIONS) private _getsNotifications: GetsNotifications,
    private _dialog: MatDialog,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  get siteId$() {
    return this._siteId.asObservable();
  }

  get notificationsList$() {
    return this._notifications.asObservable().pipe(
      map(notifications =>
        notifications.map(notification =>
          NotificationListElementQuery.fromNotification(notification),
        ),
      ),
      shareReplay(1),
    );
  }

  get unreadNotificationsCount$() {
    return this._notifications.asObservable().pipe(
      map(notifications => notifications.filter(notification => !notification.isRead).length),
      shareReplay(1),
    );
  }

  openNotification(id: string) {
    const notification = this._notifications.getValue().find(notif => notif.id === id);
    if (!notification.isRead) {
      this._notifications.next(
        this._notifications
          .getValue()
          .map(notif => (notif.id === notification.id ? notification : notif)),
      );

      notification.markAsRead();
      this._notifications.next(
        this._notifications
          .getValue()
          .map(notif => (notif.id === notification.id ? notification : notif)),
      );
      this._marksAsRead
        .markAsRead(notification as MessageNotification)
        .pipe(take(1))
        .subscribe();
    }

    switch (notification.type) {
      case NOTIFICATION_TYPES.MESSAGE:
        this._dialog.open(MessageNotificationModalComponent, {
          data: MessageNotificationQuery.fromMessageNotification(
            notification as MessageNotification,
          ),
        });
        break;
    }
  }

  fetchNotifications(): void {
    this._cancelPolling$.next();
    this._notifications.next([]);
    this._loadNotifications();
    combineLatest([this._isTabActive$, interval(this._pollingInterval)])
      .pipe(
        filter(([isActive]) => {
          return isActive;
        }),
        takeUntil(this._cancelPolling$),
      )
      .subscribe(() => {
        this._loadNotifications();
      });
  }

  setSiteId(siteId: string): void {
    this._siteId.next(siteId);
  }

  stopFetchingNotifications(): void {
    this._siteId.next(null);
    this._cancelPolling$.next();
  }

  private _loadNotifications(): void {
    this._getsNotifications
      .getNotifications()
      .pipe(take(1))
      .subscribe(notifications => {
        this._notifications.next(notifications);
      });
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const IS_SAVE_IN_PROGRESS_COMMAND = new InjectionToken<IsSaveInProgressCommandPort>(
  'IS_SAVE_IN_PROGRESS_QUERY',
);

export interface IsSaveInProgressCommandPort {
  isInProgress(): Observable<boolean>;
}

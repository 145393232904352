import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserDetailsQuery } from './user-details.query';

export interface GetsUserDetailsQueryPort {
  getUserDetails(): Observable<UserDetailsQuery>;
}

export const GETS_USER_DETAILS_QUERY_PORT = new InjectionToken<GetsUserDetailsQueryPort>(
  'GETS_USER_DETAILS_QUERY_PORT',
);

import { Observable } from 'rxjs';
import { HasDataCollection } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GetsUserTeamsDtoPort } from '../../application/ports/secondary/gets-user-teams-dto.port';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { UserTeamDto } from '../../application/ports/secondary/user-team.dto';

interface TeamAttributes {
  name: string;
  role: string;
  counter: number;
  details: {
    avatar: string;
  };
}

@Injectable()
export class HttpGetsUserTeamsService implements GetsUserTeamsDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  getUserTeams(): Observable<UserTeamDto[]> {
    return this._client.get<HasDataCollection<TeamAttributes>>('v1/sites/teams').pipe(
      map(dataCollection => {
        const userTeamsIds: string[] = [];
        const userTeams = dataCollection.data.map(team => {
          userTeamsIds.push(team.id);
          return {
            id: team.id,
            name: team.attributes.name,
            avatar: team.attributes.details.avatar,
            role: team.attributes.role,
            membersCount: team.attributes.counter,
          };
        });

        return [...new Set(userTeamsIds)]
          .map(userTeamId => userTeams.find(team => team.id === userTeamId))
          .filter(Boolean);
      }),
    );
  }
}

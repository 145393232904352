import { InjectionToken } from '@angular/core';
import { LabelManagerRecommendationQuery } from './label-manager-recomendation.query';

export const CLOSES_LM_RECOMMENDATION_QUERY = new InjectionToken<ClosesLMRecommendationQueryPort>(
  'CLOSES_LM_RECOMMENDATION_QUERY',
);

export interface ClosesLMRecommendationQueryPort {
  closeLmRecommendation(value: LabelManagerRecommendationQuery): void;
}

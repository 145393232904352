import { InjectionToken } from '@angular/core';
import { ManagesClientCommand } from './manages-client.command';

export const MANAGES_CLIENT_COMMAND = new InjectionToken<ManagesClientCommandPort>(
  'MANAGES_CLIENT_COMMAND',
);

export interface ManagesClientCommandPort {
  manageClient(command: ManagesClientCommand): void;
}

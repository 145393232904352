import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CancelSubscriptionModalComponent } from './cancel-subscription-modal.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatDialogModule, MatFormFieldModule, MatSelectModule],
  declarations: [CancelSubscriptionModalComponent],
  exports: [CancelSubscriptionModalComponent],
})
export class CancelSubscriptionModalComponentModule {}

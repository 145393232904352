import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingAddressFormComponent } from './billing-address-form.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TeamSettingsFormErrorKeyPipe } from '../../../../../../pipes/team-settings-form-error-key.pipe';

@NgModule({
  declarations: [BillingAddressFormComponent, TeamSettingsFormErrorKeyPipe],
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatAutocompleteModule,
  ],
  exports: [BillingAddressFormComponent],
})
export class BillingAddressFormComponentModule {}

export interface ServiceQuery {
  id: string;
  name: string;
  active: boolean;
  imageUrl: string | null;
  shortcutUrl?: string;
}

export interface Shortcut {
  readonly title: string;
  readonly url: string;
  readonly imageUrl: string | null;
}

export class ClientServicesQuery {
  constructor(public readonly services: ServiceQuery[], public readonly sitePublicId: string) {}

  static fromRaw(
    activeServices: string[],
    shortcuts: Shortcut[],
    source: string,
    sitePublicId: string,
  ): ClientServicesQuery {
    const services: ServiceQuery[] = [
      {
        id: 'googlesearch',
        name: 'Google Search Ads',
        active: activeServices.includes('googlesearch'),
        imageUrl: ClientServicesQuery._getAppIconUrl('googlesearch'),
      },
      {
        id: 'cobirocss',
        name: 'Google Shopping CSS',
        active: activeServices.includes('cobirocss'),
        imageUrl: ClientServicesQuery._getAppIconUrl('cobirocss'),
      },
      {
        id: 'domains',
        name: 'Domains',
        active: activeServices.includes('domains'),
        imageUrl: ClientServicesQuery._getAppIconUrl('domains'),
      },
    ];

    if (source === 'b-rex') {
      services.push({
        id: 'websitebuilder',
        name: 'Website builder',
        active: activeServices.includes('websitebuilder'),
        imageUrl: ClientServicesQuery._getAppIconUrl('websitebuilder'),
      });
      services.push({
        id: 'ecommerce',
        name: 'Online store',
        active: activeServices.includes('ecommerce'),
        imageUrl: ClientServicesQuery._getAppIconUrl('ecommerce'),
      });
    }

    shortcuts.forEach(shortcut =>
      services.push({
        id: null,
        name: shortcut.title,
        active: true,
        imageUrl: shortcut.imageUrl,
        shortcutUrl: shortcut.url,
      }),
    );

    return new ClientServicesQuery(
      services
        .filter(service => (service.id === null ? true : activeServices.includes(service.id)))
        .filter(service => !ClientServicesQuery._getShutdownApps().includes(service.id)),
      sitePublicId,
    );
  }

  get activeServices(): ServiceQuery[] {
    return this.services.filter(service => service.active);
  }

  get hasServices(): boolean {
    return !!this.services.length;
  }

  private static _getAppIconUrl(appId: string): string {
    return `assets/app-icons/app-${appId}.svg`;
  }

  private static _getShutdownApps(): string[] {
    return ['websitebuilder', 'ecommerce'];
  }
}

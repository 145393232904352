import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EditsIntegrationCommand } from './edits-integration.command';

export const EDITS_INTEGRATION_COMMAND = new InjectionToken<EditsIntegrationCommandPort>(
  'EDITS_INTEGRATION_COMMAND',
);

export interface EditsIntegrationCommandPort {
  editIntegration(command: EditsIntegrationCommand): Observable<void>;
}

import { NgModule } from '@angular/core';
import { SIGNS_UP_BY_EMAIL_DTO } from '../../application/ports/secondary/signs-up-by-email.dto-port';
import { HttpSignsUpService } from './http-signs-up.service';

@NgModule({
  providers: [
    HttpSignsUpService,
    { provide: SIGNS_UP_BY_EMAIL_DTO, useExisting: HttpSignsUpService },
  ],
})
export class HttpSignsUpServiceModule {}

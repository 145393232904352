import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_SWITCHED_INTEGRATION_PANEL_QUERY =
  new InjectionToken<GetsSwitchedIntegrationPanelQueryPort>(
    'GETS_SWITCHED_INTEGRATION_PANEL_QUERY',
  );

export interface GetsSwitchedIntegrationPanelQueryPort {
  isSwitchedExpanded(): Observable<boolean>;
}

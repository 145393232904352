import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface SetsSelectedWorkspaceCommandPort {
  setSelectedWorkspace(
    workspaceId: string | null,
    workspaceName: string | null,
    avatar: string | null,
    membersCount: number | null,
  ): Observable<boolean>;
}

export const SETS_SELECTED_WORKSPACE_COMMAND = new InjectionToken<SetsSelectedWorkspaceCommandPort>(
  'SETS_SELECTED_WORKSPACE_COMMAND',
);

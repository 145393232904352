import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionListComponent } from './subscription-list.component';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SubscriptionListComponent],
  imports: [CommonModule, HuiLoaderContainerModule, LanguageModule, MatIconModule],
  exports: [SubscriptionListComponent],
})
export class SubscriptionListComponentModule {}

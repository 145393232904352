import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesWorkspaceCommand } from './creates-workspace.command';

export const CREATES_WORKSPACE_COMMAND = new InjectionToken<CreatesWorkspaceCommandPort>(
  'CREATES_WORKSPACE_COMMAND',
);

export interface CreatesWorkspaceCommandPort {
  createWorkspace(command: CreatesWorkspaceCommand): Observable<void>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProUserListControlsComponent } from './user-list-controls.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CobiroProUserListControlsComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, ReactiveFormsModule],
  exports: [CobiroProUserListControlsComponent],
})
export class CobiroProUserListControlsComponentModule {}

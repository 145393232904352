import { Injectable } from '@angular/core';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutState {
  private readonly _hasWarning$ = new BehaviorSubject<boolean>(false);
  hasWarning$ = this._hasWarning$.asObservable();

  constructor(private readonly _featureFlagState: FeatureFlagsState) {
    this._featureFlagState
      .hasFlags(['cobiro_new_ui_release'])
      .pipe(take(1))
      .subscribe((res: boolean) => this._hasWarning$.next(res));
  }
}

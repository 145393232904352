import { ValidationErrors } from '@angular/forms';
import { debounceTime, Observable, of, Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetsVatNumberValidationQueryPort } from '../../../../application/ports/primary/gets-vat-number-validation.query-port';
import { VatNumberValidationQuery } from '../../../../application/ports/primary/vat-number-validation.query';

export class VatNumberVeisValidator {
  private readonly _validation$ = new Subject<ValidationErrors | null>();
  private readonly _valueToCheck$ = new Subject<string | null>();
  private readonly _onDestroy$ = new Subject<void>();

  constructor(private readonly _getsVatNumberValidationQuery: GetsVatNumberValidationQueryPort) {
    this._valueToCheck$
      .asObservable()
      .pipe(
        debounceTime(300),
        switchMap(vatNumber => {
          if (!vatNumber) {
            return of(new VatNumberValidationQuery(false, 'required'));
          }

          if (vatNumber.length < 4) {
            return of(new VatNumberValidationQuery(false, 'invalidField'));
          }

          return this._getsVatNumberValidationQuery.getVatValidation(vatNumber);
        }),
        takeUntil(this._onDestroy$),
      )
      .subscribe(query => {
        if (query.isValid) {
          this._validation$.next(null);
          return;
        }

        this._validation$.next({ [query.errorKey]: true });
      });
  }

  validate = (vatNumber: string): Observable<ValidationErrors | null> => {
    this._valueToCheck$.next(vatNumber);
    return this._validation$.asObservable();
  };

  unregister(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

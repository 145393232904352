import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface OpenSetupFlowCommandPort {
  openSetupFlow(product: string): Observable<void>;
}

export const OPEN_SETUP_FLOW_COMMAND = new InjectionToken<OpenSetupFlowCommandPort>(
  'OPEN_SETUP_FLOW_COMMAND',
);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EditsWorkspaceDto } from './edits-workspace-name.dto';

export const EDITS_WORKSPACE_NAME_DTO = new InjectionToken<EditsWorkspaceNameDtoPort>(
  'EDITS_WORKSPACE_NAME_DTO',
);

export interface EditsWorkspaceNameDtoPort {
  editWorkspaceName(dto: EditsWorkspaceDto): Observable<void>;
}

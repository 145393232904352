import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientLoadingStateQuery } from './client-loading-state.query';

export const GETS_CURRENT_CLIENT_LOADING_STATE_QUERY =
  new InjectionToken<GetsCurrentClientLoadingStateQueryPort>(
    'GETS_CURRENT_CLIENT_LOADING_STATE_QUERY',
  );

export interface GetsCurrentClientLoadingStateQueryPort {
  getCurrentClientLoadingStateQuery(): Observable<ClientLoadingStateQuery>;
}

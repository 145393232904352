import { NgModule } from '@angular/core';
import { VERIFIES_USER_COMMAND } from '@app.cobiro.com/user/core';
import { VerifyCodeState } from './verify-code.state';

@NgModule({
  providers: [
    VerifyCodeState,
    {
      provide: VERIFIES_USER_COMMAND,
      useExisting: VerifyCodeState,
    },
  ],
})
export class VerifyCodeStateModule {}

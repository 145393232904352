import { ClientDTO } from '../../secondary/dto/clients/client.dto';
import { ClientQuery } from './client.query';
import { ClientPlanQuery } from './plan.query';
import { ClientSubscriptionQuery } from './subscription.query';

export class ClientListItemQuery implements ClientQuery {
  constructor(
    public readonly id: string,
    public readonly teamId: string,
    public readonly workspaceId: string,
    public readonly merchantSiteId: string | null,
    public readonly siteId: string | null,
    public readonly sitePublicId: string,
    public readonly companyName: string,
    public readonly contactPerson: string,
    public readonly contactEmail: string,
    public readonly contactPhone: string,
    public readonly createdAt: Date,
    public readonly url: string,
    public readonly avatar: string,
    public readonly productStatuses: Record<
      'css' | 'label-manager',
      'pending' | 'active' | 'paused' | null
    >,
    public readonly subscriptions: ClientSubscriptionQuery[] | null,
    public readonly cssDomainId: string,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  static fromClientDTO(dto: ClientDTO): ClientListItemQuery {
    return new ClientListItemQuery(
      dto.id,
      dto.teamId,
      dto.workspaceId,
      dto.merchantSiteId,
      dto.siteId,
      dto.sitePublicId,
      dto.companyName,
      dto.contactPerson,
      dto.contactEmail,
      dto.contactPhone,
      new Date(dto.createdAt),
      dto.url,
      dto.avatar,
      dto.productStatuses,
      dto.subscriptions
        ? dto.subscriptions.map(subscription => ClientSubscriptionQuery.fromDTO(subscription))
        : null,
      dto.cssDomainId,
    );
  }

  hasPaidPlan(productName?: string): boolean {
    if (productName) {
      return this.subscriptions?.some(product => product.productName === productName);
    }
    return this.subscriptions?.length > 0;
  }

  getPlanForProduct(productName: string): ClientPlanQuery {
    return this.subscriptions.find(product => product.productName === productName)?.planType;
  }

  getStatusForProduct(productName: string): string {
    const status = this.productStatuses[productName];
    return status ? status : 'none';
  }
}

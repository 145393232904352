import { ContextChangedDispatcherPort } from '../../../application/ports/secondary/context-changed.dispatcher-port';
import { CobiroProContextChangedEvent } from '@app.cobiro.com/core/events';
import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';

@Injectable()
export class ApplicationBusContextChangedDispatcher implements ContextChangedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus) {}
  dispatch(event: CobiroProContextChangedEvent) {
    this._applicationBus.dispatch(event);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ChecksDiscountCodeParamsDto } from './checks-discount-code-params.dto';
import { DiscountCodeCheckResult } from './checks-discount-code-result.dto';

export const CHECKS_DISCOUNT_CODE_DTO = new InjectionToken<ChecksDiscountCodeDtoPort>(
  'CHECKS_DISCOUNT_CODE_DTO',
);

export interface ChecksDiscountCodeDtoPort {
  checkDiscountCode(dto: ChecksDiscountCodeParamsDto): Observable<DiscountCodeCheckResult>;
}

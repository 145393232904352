export const CANCEL_REASON_MAP = {
  ['site_cancel_subscription_popup_select_reason_product_didnt_do_what_i_expected']:
    'product_didnt_do_what_i_expected',
  ['site_cancel_subscription_popup_select_reason_collaboration_with_client_ended']:
    'collaboration_with_client_ended',
  ['site_cancel_subscription_popup_select_reason_i_found_cheaper']: 'i_found_a_cheaper_product',
  ['site_cancel_subscription_popup_select_didnt_use_product_enough']: 'didnt_use_product_enough',
  ['site_cancel_subscription_popup_select_subscription_moved_or_changed']:
    'subscription_moved_or_changed',
  ['site_cancel_subscription_popup_select_client_suspended_by_google']:
    'client_suspended_by_google',
  ['site_cancel_subscription_popup_select_reason_switched_to_another_css']:
    'switched_to_another_css',
  ['site_cancel_subscription_popup_select_reason_other']: 'other_reason',
};

export class CancelReasonQuery {
  constructor(public readonly label: string) {}
}

import { Inject, Injectable } from '@angular/core';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { Observable, of } from 'rxjs';
import { SELECTED_CLIENT_STORAGE } from '../../adapters/secondary/storage/selected-client.storage';
import { SaveSelectedClientCommand } from '../ports/primary/save-selected-client.command';
import { SavesSelectedClientCommandPort } from '../ports/primary/saves-selected-client.command-port';
import { SelectedClientDto } from '../ports/secondary/selected-client.dto';

@Injectable()
export class SaveSelectedClientCommandHandler implements SavesSelectedClientCommandPort {
  constructor(
    @Inject(SELECTED_CLIENT_STORAGE)
    private readonly _selectedClientStorage: ReactiveSingleValueStorage<SelectedClientDto>,
  ) {}

  saveSelectedClient(command: SaveSelectedClientCommand): Observable<void> {
    this._selectedClientStorage.save({ companyName: command.companyName });
    return of(void 0);
  }
}

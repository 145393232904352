export class IntegrationQuery {
  constructor(
    public readonly cssDomainId: string,
    public readonly adsId: string,
    public readonly integrationId: string,
    public readonly businessName: string,
    public readonly email: string,
    public readonly merchantId: string | null,
    public readonly integrationType: 'owned' | 'switched' | 'non-css',
  ) {}
}

import { ViewportScroller } from '@angular/common';

export default class CustomContainerScroller extends ViewportScroller {
  _scrollElement: HTMLElement | null;

  get scrollElement(): HTMLElement {
    if (!this._scrollElement) {
      this._scrollElement = document.getElementById(this.scrollContainerId);
    }
    return this._scrollElement;
  }

  constructor(private document: Document, private scrollContainerId: string) {
    super();
  }

  getScrollPosition(): [number, number] {
    return [0, this.scrollElement.scrollTop];
  }

  scrollToPosition(position: [number, number]): void {
    this.scrollElement.scrollTop = position[1];
  }

  scrollToAnchor(anchor: string): void {}

  setOffset(offset: [number, number] | (() => [number, number])): void {}

  setHistoryScrollRestoration(scrollRestoration: 'auto' | 'manual'): void {}
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT =
  new InjectionToken<IsLabelManagerReportLoadingQueryPort>(
    'IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT',
  );

export interface IsLabelManagerReportLoadingQueryPort {
  isLoading(): Observable<boolean>;
}

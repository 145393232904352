/* eslint-disable max-lines-per-function */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorage, LocalStorageInterface } from '@app.cobiro.com/core/storage';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { map, Observable } from 'rxjs';
import { AnnoucementVisibilityDTO } from '../../application/ports/secondary/annoucement-visibility.dto';
import { AnnoucementDTO } from '../../application/ports/secondary/annoucement.dto';
import { GetsAnnoucementsVisibilityDtoPort } from '../../application/ports/secondary/gets-annoucement-visibility.dto-port';
import { GetsAnnoucementDtoPort } from '../../application/ports/secondary/gets-annoucement.dto-port';
import { SetsAnnoucementsVisibilityDtoPort } from '../../application/ports/secondary/sets-annoucement-visibility.dto-port';

@Injectable()
export class HttpGetsAnnoucementsService
  implements
    GetsAnnoucementDtoPort,
    GetsAnnoucementsVisibilityDtoPort,
    SetsAnnoucementsVisibilityDtoPort
{
  annoucementStorage: LocalStorageInterface;
  headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(
    private readonly _http: HttpClient,
    private readonly _localStorage: LocalStorage,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {
    this.annoucementStorage = this._localStorage.init('non-visible-annoucement');
  }

  setAnnoucementsVisibility(dto: AnnoucementVisibilityDTO): void {
    const visibilityConfig = this.annoucementStorage.get() || [];
    visibilityConfig.push(dto);
    this.annoucementStorage.set(visibilityConfig);
  }

  getAnnoucementsVisibility(): AnnoucementVisibilityDTO[] {
    return this.annoucementStorage.get() ?? [];
  }

  getAnnoucements(): Observable<AnnoucementDTO[]> {
    return this._http
      .get<{ data: AnnoucementDTO[] }>(
        `${this._getsConfig.get(
          'publicCdn',
        )}/json/announcement-widget-content.json?timestamp=${new Date().valueOf()}`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        map((annoucementsData: { data: AnnoucementDTO[] }) =>
          annoucementsData.data.map(annoucement => ({
            id: annoucement.id,
            enabled: annoucement.enabled,
            title: annoucement.title,
            description: annoucement.description,
            readMoreLink: annoucement.readMoreLink,
          })),
        ),
      );
  }
}

import { ClientsListLoadedDispatcherPort } from '../../../application/ports/secondary/clients-list-loaded.dispatcher-port';
import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { ClientsListLoadedEvent } from '../../../application/events/clients-list-loaded.event';
import { ClientDTO } from '../../../application/ports/secondary/client.dto';

// TODO (PRO-DEBT): Prefix name with ApplicationBus
@Injectable()
export class ClientsListLoadedEventDispatcher implements ClientsListLoadedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { clients: ClientDTO[]; teamId: string }) {
    this._applicationBus.dispatch(new ClientsListLoadedEvent(event.clients, event.teamId));
  }
}

<button
  data-selector="social-login-google-button"
  class="cs-btn cs-btn-social cs-btn-social-google w-100 d-flex justify-content-center"
  [libAnalytics]="['social', 'google']"
  *libFeatureFlags="['sign_up_by_google']"
  (click)="onSignUpByGoogleClicked()"
  [huiLoading]="isLoading$ | async"
  type="button"
>
  <img class="social-logo" src="assets/logos/google-logo-main.svg" />
  <span class="cs-c-shade-100"><ng-content></ng-content></span>
</button>

import { InjectionToken } from '@angular/core';

export interface SavesUserLatestLoginRedirectCommandPort {
  saveUserLatestLoginRedirect({ redirectUrl }: { redirectUrl: string }): void;
}

export const SAVE_USER_LATEST_LOGIN_REDIRECT_COMMAND =
  new InjectionToken<SavesUserLatestLoginRedirectCommandPort>(
    'SAVE_USER_LATEST_LOGIN_REDIRECT_COMMAND',
  );

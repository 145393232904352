import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CancelSubscriptionAtEndOfTermSuccessModalComponent } from './cancel-subscription-at-end-of-term-success-modal.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatIconModule],
  declarations: [CancelSubscriptionAtEndOfTermSuccessModalComponent],
  exports: [CancelSubscriptionAtEndOfTermSuccessModalComponent],
})
export class CancelSubscriptionAtEndOfTermSuccessModalComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { NumberOfEmployeesQuery } from './number-of-employees.query';

export const GETS_NUMBER_OF_EMPLOYEES_QUERY = new InjectionToken<GetsNumberOfEmployeesQueryPort>(
  'GETS_NUMBER_OF_EMPLOYEES_QUERY',
);

export interface GetsNumberOfEmployeesQueryPort {
  getAvailableNumberOfEmployees(): Observable<NumberOfEmployeesQuery[]>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersWorkspacesFaqComponent } from './users-workspaces-faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [UsersWorkspacesFaqComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatExpansionModule,
    HuiLoaderContainerModule,
    RouterModule.forChild([{ path: '', component: UsersWorkspacesFaqComponent }]),
  ],
  exports: [UsersWorkspacesFaqComponent],
})
export class UsersWorkspacesFaqComponentModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TeamSwitcherControllerComponentModule } from '../team-switcher-controller/team-switcher-controller.component-module';
import { UserMenuComponentModule } from '../user-menu/user-menu.component-module';
import { TopBarTitleDirective } from './top-bar-title.directive';
import { TopBarComponent } from './top-bar.component';

@NgModule({
  imports: [
    CommonModule,
    UserMenuComponentModule,
    MatIconModule,
    TeamSwitcherControllerComponentModule,
  ],
  declarations: [TopBarComponent, TopBarTitleDirective],
  exports: [TopBarComponent, TopBarTitleDirective],
})
export class TopBarComponentModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserQuery } from './user.query';

export const GETS_USERS_LIST_QUERY = new InjectionToken<GetsUsersListQueryPort>(
  'GETS_USERS_LIST_QUERY',
);

export interface GetsUsersListQueryPort {
  getUserListQuery(): Observable<UserQuery[]>;
}

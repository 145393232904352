import { NgModule } from '@angular/core';
import { InMemoryReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { EmojiBackgroundTypes } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/emoji-background-types.enum';
import { Emojis } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/emojis.enum';
import { HuiSimpleModalWithEmojiComponent } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/hui-simple-modal-with-emoji.component';
import { ConfirmReplaceCardModalModule } from '../../adapters/primary/ui/confirm-replace-card-modal/confirm-replace-card-modal.component-module';
import { ADDS_PAYMENT_METHOD_COMMAND } from '../ports/primary/adds-payment-method.command-port';
import { CONFIRM_REPLACE_CARD_MESSAGE_COMMAND_PORT } from '../ports/primary/confirm-replace-card-message-command.port';
import { GETS_ADD_PAYMENT_METHOD_SUCCEED_QUERY } from '../ports/primary/gets-add-payment-method-succeed.query-port';
import { GETS_CARD_VALIDATION_QUERY_PORT } from '../ports/primary/gets-card-validation-error.query-port';
import { GETS_PAYMENT_METHOD_PROCESSING_QUERY } from '../ports/primary/gets-payment-method-processing.query-port';
import { GETS_PAYMENT_SOURCES_LOADING_QUERY_PORT } from '../ports/primary/gets-payment-sources-loading.query-port';
import { GETS_PAYMENT_SOURCES_QUERY_PORT } from '../ports/primary/gets-payment-sources.query-port';
import { LOADS_PAYMENT_SOURCES_COMMAND_PORT } from '../ports/primary/loads-payment-sources.command-port';
import { REPLACES_PAYMENT_METHOD_COMMAND } from '../ports/primary/replaces-payment-method.command-port';
import { PAYMENT_SOURCE_STORAGE_DTO_PORT } from '../ports/secondary/payment-source-storage.dto-port';
import { PaymentMethodsState } from './payment-methods.state';

@NgModule({
  imports: [ConfirmReplaceCardModalModule],
  providers: [
    PaymentMethodsState,
    {
      provide: ADDS_PAYMENT_METHOD_COMMAND,
      useExisting: PaymentMethodsState,
    },
    {
      provide: GETS_PAYMENT_METHOD_PROCESSING_QUERY,
      useExisting: PaymentMethodsState,
    },
    {
      provide: GETS_ADD_PAYMENT_METHOD_SUCCEED_QUERY,
      useExisting: PaymentMethodsState,
    },
    {
      provide: PAYMENT_SOURCE_STORAGE_DTO_PORT,
      useClass: InMemoryReactiveSingleValueStorage,
    },
    {
      provide: GETS_PAYMENT_SOURCES_QUERY_PORT,
      useExisting: PaymentMethodsState,
    },
    {
      provide: GETS_PAYMENT_SOURCES_LOADING_QUERY_PORT,
      useExisting: PaymentMethodsState,
    },
    {
      provide: LOADS_PAYMENT_SOURCES_COMMAND_PORT,
      useExisting: PaymentMethodsState,
    },
    {
      provide: GETS_CARD_VALIDATION_QUERY_PORT,
      useExisting: PaymentMethodsState,
    },

    {
      provide: CONFIRM_REPLACE_CARD_MESSAGE_COMMAND_PORT,
      useExisting: PaymentMethodsState,
    },
    {
      provide: REPLACES_PAYMENT_METHOD_COMMAND,
      useExisting: PaymentMethodsState,
    },
  ],
})
export class PaymentMethodsStateModule {}

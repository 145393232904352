import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerRevenueAndCostsComponent } from './label-manager-revenue-and-costs.component';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  declarations: [LabelManagerRevenueAndCostsComponent],
  imports: [CommonModule, NgApexchartsModule],
  exports: [LabelManagerRevenueAndCostsComponent],
})
export class LabelManagerRevenueAndCostsComponentModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CreatesNonCssIntegrationQuery } from './creates-non-css-integration.query';

export const CREATES_NON_CSS_INTEGRATION_QUERY =
  new InjectionToken<CreatesNonCssIntegrationQueryPort>('CREATES_NON_CSS_INTEGRATION_QUERY');

export interface CreatesNonCssIntegrationQueryPort {
  createIntegration(query: CreatesNonCssIntegrationQuery): Observable<boolean>;
}

<div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
  <div
    class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
  >
    <div
      *ngIf="iframeLink$ | async as iframeLink"
      class="d-flex flex-column justify-content-center align-items-center cs-mt-20 w-100 h-100"
    >
      <iframe [src]="iframeLink" frameborder="0" style="height: 700px; width: 650px"> </iframe>
    </div>
  </div>
  <div class="d-flex justify-content-between cs-mt-20">
    <a
      class="cs-btn cs-btn-flat"
      href="https://support.cobiro.com/hc/en-us/requests/new"
      target="_blank"
    >
      {{ 'cobiro_pro_label_manager_config_window_help_button' | language }}
    </a>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { LocalStorage, LocalStorageInterface } from '@app.cobiro.com/core/storage';
import { REFRESHES_TOKEN, RefreshesToken, Token } from '../../domain/refreshes-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authStorage: LocalStorageInterface;

  constructor(
    private localStorage: LocalStorage,
    @Inject(REFRESHES_TOKEN) private _tokenRefresher: RefreshesToken,
  ) {
    this.authStorage = this.localStorage.init('auth');
  }

  refreshToken(): Observable<Token> {
    const authData = this.authStorage.get();
    if (!authData) {
      return throwError('No data in local storage');
    }
    if (authData.refreshing) {
      return throwError('Token already refreshing from other tab');
    }
    const token = authData?.access_token;
    if (!token) {
      return throwError('No token in local storage');
    }

    let expirationUnixTime = 0;
    try {
      expirationUnixTime = jwtDecode<any>(token).exp;
    } catch (e) {
      return throwError('Token is invalid');
    }

    const getUnixTime = () => Math.floor(Date.now() / 1000);

    if (expirationUnixTime < getUnixTime()) {
      this.setAuthRefreshStorage(true);
      return this._tokenRefresher.refresh(authData.refresh_token).pipe(
        tap((res: Token) => {
          this.setAuthStorage(res.accessToken, res.refreshToken, res.tokenType);
        }),
      );
    } else {
      return throwError('Token is not expired');
    }
  }

  /**
   * Sets refresh state to the local storage to prevent simultaneous refresh requests from multiple tabs
   * @param refreshing - true if token refresh requested
   */
  private setAuthRefreshStorage(refreshing: boolean) {
    this.authStorage.set({
      ...this.authStorage.get(),
      refreshing,
    });
  }

  /**
   * Sets authentication data to local storage
   * @param accessToken - Authentication token
   * @param refreshToken - Refresh token
   * @param tokenType - type (Bearer)
   */
  private setAuthStorage(accessToken: string, refreshToken: string, tokenType: string) {
    this.authStorage.set({
      access_token: accessToken,
      refresh_token: refreshToken,
      token_type: tokenType,
      refreshing: false,
    });
  }
}

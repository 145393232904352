import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerConfigQuery } from './label-manager-config.query';

export const GETS_LABEL_MANAGER_CONFIG_QUERY_PORT =
  new InjectionToken<GetsLabelManagerConfigQueryPort>('GETS_LABEL_MANAGER_CONFIG_QUERY_PORT');

export interface GetsLabelManagerConfigQueryPort {
  getConfig(): Observable<LabelManagerConfigQuery>;
}

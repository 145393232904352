export class FilterVO {
  private readonly _value: string | string[] | { from: number; to: number };

  constructor(_value: string | string[] | { from: number | string; to: number | string }) {
    this._validate(_value);
    this._value = this._isRange(_value) ? { from: +_value.from, to: +_value.to } : _value;
  }

  valueOf() {
    return this._value;
  }

  private _validate(_value: string | string[] | { from: number | string; to: number | string }) {
    if (
      typeof _value !== 'string' &&
      !Array.isArray(_value) &&
      (isNaN(+_value.to) || isNaN(+_value.from) || +_value.to < +_value.from)
    ) {
      throw new Error('Invalid filter argument');
    }
  }

  private _isRange(value): value is { from: number | string; to: number | string } {
    return !isNaN(value.from) && !isNaN(value.to);
  }
}

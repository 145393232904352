import { PaymentPlanPriceVO, PlanTypeCollection } from './payment-plan-prices.vo';
import { PaymentPlanFeature } from './payment-plan-feature';

export enum PaymentPlanPeriod {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export enum PaymentPlanCurrency {
  USD = '$',
}

export class PaymentPlanId {
  public readonly value: string;
  private _typeMapper: PlanTypeCollection<string> = {
    [PaymentPlanPeriod.MONTHLY]: 'monthly',
    [PaymentPlanPeriod.YEARLY]: 'annual',
  };
  constructor(name: string, paymentPlan: PaymentPlanPeriod) {
    this.value = `${name}-${this._typeMapper[paymentPlan]}`.toLocaleLowerCase();
  }
}

// TODO: intra object
export interface PaymentPlanData {
  name: string;
  goal: string;
  features: PaymentPlanFeature[];
  prices: PlanTypeCollection<number>;
}

// TODO - move currency to PaymentPlan
export class PaymentPlan {
  constructor(
    private _id: PaymentPlanId,
    public readonly name: string,
    public readonly goal: string,
    public readonly features: PaymentPlanFeature[],
    public readonly pricedPeriods: PlanTypeCollection<number>,
  ) {}

  static fromPlanData(planData: PaymentPlanData, paymentPlanType: PaymentPlanPeriod) {
    const id = new PaymentPlanId(planData.name, paymentPlanType);
    return new PaymentPlan(id, planData.name, planData.goal, planData.features, planData.prices);
  }

  getPriceForPeriod(period: PaymentPlanPeriod): PaymentPlanPriceVO {
    return new PaymentPlanPriceVO(this.pricedPeriods, period, PaymentPlanCurrency.USD);
  }

  get id(): string {
    return this._id.value;
  }

  isFree(): boolean {
    return this.name.toLowerCase() === 'free';
  }
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import {
  SetsCurrentProductQueryPort,
  SETS_CURRENT_PRODUCT_QUERY,
} from '../../../application/ports/primary/setup/sets-current-product.query-port';
import {
  SetsCurrentClientQueryPort,
  SETS_CURRENT_CLIENT_QUERY,
} from '../../../application/ports/primary/setup/sets-current-client.query-port';
import {
  SETS_SELECTED_CLIENT_COMMAND,
  SetsSelectedClientCommandPort,
} from '../../../application/ports/primary/clients/sets-selected-client-command.port';
import { PaymentFlowStartedEvent } from '@app.cobiro.com/core/events';
import { ClientDTO } from '../../../application/ports/secondary/dto/clients/client.dto';
import { tap, take } from 'rxjs';
import {
  SelectsClientsContextStoragePort,
  SELECTS_CLIENTS_CONTEXT_STORAGE,
} from '../../../application/ports/secondary/context/selects-clients-context.storage-port';
import { ClientsContext } from '../../../application/ports/secondary/context/clientsContext';

@Injectable()
export class PaymentFlowStartedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [PaymentFlowStartedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_CURRENT_CLIENT_QUERY)
    private readonly _setsCurrentClientQueryPort: SetsCurrentClientQueryPort,
    @Inject(SETS_CURRENT_PRODUCT_QUERY)
    private readonly _setsCurrentProductQueryPort: SetsCurrentProductQueryPort,
    @Inject(SELECTS_CLIENTS_CONTEXT_STORAGE)
    private readonly _selectsClientsContextStorage: SelectsClientsContextStoragePort,
  ) {}

  handle([event]: [PaymentFlowStartedEvent]): void {
    this._selectsClientsContextStorage.select().subscribe((context: ClientsContext) => {
      const client = context.list.find(client => client.id === event.clientId);
      this._setsCurrentClientQueryPort.setCurrentClient(client);
      this._setsCurrentProductQueryPort.setCurrentProduct(event.product);
    });
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  GETS_AGENCY_ACCESS_COMMAND_PORT,
  GetsAgencyAccessCommandPort,
} from '../../../../../../../application/ports/primary/gets-agency-access-link.command-port';
import { DomSanitizer } from '@angular/platform-browser';
import { map, tap } from 'rxjs';
import {
  GOES_TO_NEXT_STEP_COMMAND,
  GoesToNextStepCommandPort,
} from '../../../../../../../application/ports/primary/goes-to-next-step.command-port';

@Component({
  selector: 'lib-cobiro-pro-iframe-step',
  templateUrl: './iframe-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeStepComponent implements OnInit {
  readonly iframeLink$ = this._getAgencyAccessCommandPort
    .getIframeLink()
    .pipe(map((link: string) => this.sanitizer.bypassSecurityTrustResourceUrl(link)));

  constructor(
    private readonly sanitizer: DomSanitizer,
    @Inject(GOES_TO_NEXT_STEP_COMMAND)
    private readonly _goesToNextStepCommandPort: GoesToNextStepCommandPort,
    @Inject(GETS_AGENCY_ACCESS_COMMAND_PORT)
    private readonly _getAgencyAccessCommandPort: GetsAgencyAccessCommandPort,
  ) {}

  ngOnInit(): void {
    window.document.addEventListener(
      'linkingFinished',
      () => this._goesToNextStepCommandPort.goToNextStep(),
      false,
    );
  }
}

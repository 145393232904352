import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltersCountriesCommand } from './filters-countries.command';

export const FILTERS_COUNTRIES_COMMAND = new InjectionToken<FiltersCountriesCommandPort>(
  'FILTERS_COUNTRIES_COMMAND',
);

export interface FiltersCountriesCommandPort {
  filterCountries(command: FiltersCountriesCommand): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { InitsSetupFlowCommand } from './inits-setup-flow.command';

export const INITS_SETUP_FLOW_COMMAND = new InjectionToken<InitsSetupFlowCommandPort>(
  'INITS_SETUP_FLOW_COMMAND',
);

export interface InitsSetupFlowCommandPort {
  initSetupFlow(command: InitsSetupFlowCommand): Observable<void>;
}

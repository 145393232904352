import { NgModule } from '@angular/core';
import { HttpTeamsService } from './http-teams.service';
import { GETS_ALL_TEAMS_DTO } from '../../../../application/ports/secondary/gets-all-team-dto.port';

@NgModule({
  declarations: [],
  providers: [HttpTeamsService, { provide: GETS_ALL_TEAMS_DTO, useExisting: HttpTeamsService }],
  exports: [],
})
export class HttpTeamsServiceModule {}

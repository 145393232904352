import { NgModule } from '@angular/core';
import { GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT } from '../../../../application/ports/secondary/gets-client-subscriptions.dto-port';
import { HttpClientsProductService } from './http-clients-products.service';

@NgModule({
  providers: [
    HttpClientsProductService,
    { provide: GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT, useExisting: HttpClientsProductService },
  ],
})
export class HttpClientsProductServiceModule {}

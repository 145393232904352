import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[huiLoading]',
})
export class HuiLoadingDirective {
  private _cssClassesForHtmlElement = new Map([['BUTTON', ['is-loading']]]);
  private readonly _DEFAULT_CLASSES = ['cs-spinner', 'cs-spinner-xl'];

  private _isLoading: boolean;
  @Input('huiLoading')
  set isLoading(val) {
    this._isLoading = val;
    this.toggleLoadingClass(val);
  }

  private _classes: string[] = null;
  @Input()
  set huiLoadingClasses(classes: string[]) {
    this.removeClasses();
    this._classes = classes;
    this.toggleLoadingClass(this._isLoading);
  }

  constructor(private readonly _elementRef: ElementRef, private readonly _renderer2: Renderer2) {}

  private toggleLoadingClass(isLoading: boolean) {
    isLoading ? this.addClasses() : this.removeClasses();
  }

  private addClasses(): void {
    this._cssClasses.forEach(cssClass =>
      this._renderer2.addClass(this._elementRef.nativeElement, cssClass),
    );
  }

  private removeClasses(): void {
    this._cssClasses.forEach(cssClass =>
      this._renderer2.removeClass(this._elementRef.nativeElement, cssClass),
    );
  }

  private get _cssClasses(): string[] {
    return (
      this._classes ||
      this._cssClassesForHtmlElement.get(this._elementRef.nativeElement.tagName) ||
      this._DEFAULT_CLASSES
    );
  }
}

/* eslint-disable complexity */
import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  GetsCurrentProductQueryPort,
  GETS_CURRENT_PRODUCT_QUERY,
} from '../../../application/ports/primary/setup/gets-current-product.query-port';
import { TeamIdGetter, TEAM_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import {
  SetsCurrentProductQueryPort,
  SETS_CURRENT_PRODUCT_QUERY,
} from '../../../application/ports/primary/setup/sets-current-product.query-port';

@Injectable()
export class HasProductSelectedGuard {
  constructor(
    @Inject(GETS_CURRENT_PRODUCT_QUERY)
    private readonly _getsCurrentProductQueryPort: GetsCurrentProductQueryPort,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
    @Inject(SETS_CURRENT_PRODUCT_QUERY)
    private readonly _setsCurrentProductQueryPort: SetsCurrentProductQueryPort,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._getsCurrentProductQueryPort.getCurrentProduct().pipe(
      take(1),
      map((product: string) => {
        const extras = this._router.getCurrentNavigation().extras.state;
        if (!product && !extras) {
          return this._router.createUrlTree([`/connect/${this._teamIdGetter.getTeamId()}/home`]);
        }
        if (!product) {
          this._setsCurrentProductQueryPort.setCurrentProduct(extras.product);
        }
        return true;
      }),
    );
  }
}

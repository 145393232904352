/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserDTO } from '../../../../application/ports/secondary/dto/user.dto';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { GetsAllWorkspaceUsersDtoPort } from '../../../../application/ports/secondary/dto/gets-all-workspace-users.dto-port';
import { GetsAllWorkspaceUsersDto } from '../../../../application/ports/secondary/dto/gets-all-workspace-users.dto';
import { DeletesWorkspaceUserDtoPort } from '../../../../application/ports/secondary/dto/deletes-workspace-user.dto-port';

export interface UserAttributes {
  teamId?: string;
  email: string;
  role: 'basic_user' | 'owner' | 'admin';
  name?: string;
  avatarUrl?: string;
  createdAt?: string;
  registered?: boolean;
  workspaces: string[];
}

@Injectable()
export class HttpWorkspaceUsersService
  implements GetsAllWorkspaceUsersDtoPort, DeletesWorkspaceUserDtoPort
{
  constructor(private readonly _client: GatewayClient) {}
  deleteWorkspaceUser(dto: Pick<UserDTO, 'id' | 'teamId' | 'workspaces'>): Observable<void> {
    const payload: HasData<{ workspaceIds: string[] }> = {
      data: {
        type: 'member-workspaces',
        attributes: {
          workspaceIds: dto.workspaces,
        },
      },
    };

    return this._client.post(
      `v1/sites/teams/${dto.teamId}/members/${dto.id}/delete-workspace-access`,
      payload,
    );
  }

  getAllWorkspaceUsers(dto: GetsAllWorkspaceUsersDto): Observable<UserDTO[]> {
    return this._client
      .get<HasDataCollection<UserAttributes>>(
        `v1/sites/teams/${dto.teamId}/workspaces/${dto.workspaceId}/members`,
      )
      .pipe(
        map((users: HasDataCollection<UserAttributes>) => {
          return users.data.map(data => {
            return {
              id: data.id,
              teamId: data.attributes.teamId,
              email: data.attributes.email,
              role: data.attributes.role,
              name: data.attributes.name,
              avatar: data.attributes.avatarUrl,
              createdAt: data.attributes.createdAt,
              registered: data.attributes.registered,
              workspaces: data.attributes.workspaces,
            };
          });
        }),
        catchError(() => of([])),
      );
  }
}

import { NgModule } from '@angular/core';
import { COMPLETES_FIRST_STEP_COMMAND } from '../../ports/primary/setup/completes-first-step.command-port';
import { GETS_CURRENT_CLIENT_QUERY } from '../../ports/primary/setup/gets-current-client.query-port';
import { GETS_CURRENT_INDEX_QUERY } from '../../ports/primary/setup/gets-current-index.query-port';
import { GETS_CURRENT_PRODUCT_QUERY } from '../../ports/primary/setup/gets-current-product.query-port';
import { GETS_PRODUCT_MARKETING_LIST_QUERY } from '../../ports/primary/setup/gets-product-marketing-list.query';
import { GETS_STEPS_CONFIGURATION_QUERY } from '../../ports/primary/setup/gets-steps-configuration.query-port';
import { GOES_TO_NEXT_STEP_COMMAND } from '../../ports/primary/setup/goes-to-next-step.command-port';
import { GOES_TO_PREVIOUS_STEP_COMMAND } from '../../ports/primary/setup/goes-to-previous-step.command-port';
import { INITS_SETUP_FLOW_COMMAND } from '../../ports/primary/setup/inits-setup-flow.command-port';
import { SETS_CURRENT_CLIENT_QUERY } from '../../ports/primary/setup/sets-current-client.query-port';
import { SETS_CURRENT_PRODUCT_QUERY } from '../../ports/primary/setup/sets-current-product.query-port';
import { SetupFlowState } from './setup-flow.state';

@NgModule({
  providers: [
    SetupFlowState,
    { provide: GETS_CURRENT_INDEX_QUERY, useExisting: SetupFlowState },
    { provide: INITS_SETUP_FLOW_COMMAND, useExisting: SetupFlowState },
    { provide: COMPLETES_FIRST_STEP_COMMAND, useExisting: SetupFlowState },
    { provide: GETS_CURRENT_PRODUCT_QUERY, useExisting: SetupFlowState },
    { provide: GOES_TO_PREVIOUS_STEP_COMMAND, useExisting: SetupFlowState },
    { provide: GOES_TO_NEXT_STEP_COMMAND, useExisting: SetupFlowState },
    { provide: GETS_STEPS_CONFIGURATION_QUERY, useExisting: SetupFlowState },
    { provide: GETS_CURRENT_CLIENT_QUERY, useExisting: SetupFlowState },
    { provide: SETS_CURRENT_PRODUCT_QUERY, useExisting: SetupFlowState },
    { provide: SETS_CURRENT_CLIENT_QUERY, useExisting: SetupFlowState },
    { provide: GETS_PRODUCT_MARKETING_LIST_QUERY, useExisting: SetupFlowState },
  ],
})
export class SetupFlowStateModule {}

import { ApplicationEvent } from '@cobiro/eda';

interface LabelManagerConfigDto {
  id: string;
  breakEvenROAS: number;
  lookBackWindow: number;
  customLabel:
    | 'custom_label_0'
    | 'custom_label_1'
    | 'custom_label_2'
    | 'custom_label_3'
    | 'custom_label_4'
    | null;
  analysisFrequency: number | null;
  initialConfiguration?: boolean;
}

export class LabelManagerSetConfigEvent extends ApplicationEvent {
  constructor(public readonly config: LabelManagerConfigDto) {
    super();
  }
}

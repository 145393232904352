import { NgModule } from '@angular/core';
import { HttpGetsUserTeamsServiceModule } from './adapters/secondary/http-gets-user-teams.service-module';
import { UserAdditionalDataStateModule } from './application/state/user-additional-data.state-module';
import { CommonModule } from '@angular/common';
import { LoadUserAdditionalDataResolverModule } from './adapters/primary/load-user-additional-data.resolver-module';
// eslint-disable-next-line max-len
import { ApplicationBusUserAdditionalDataChangedDispatcherModule } from './adapters/secondary/dispatchers/application-bus-user-additional-data-changed.dispatcher-module';

@NgModule({
  imports: [
    CommonModule,
    UserAdditionalDataStateModule,
    HttpGetsUserTeamsServiceModule,
    LoadUserAdditionalDataResolverModule,
    ApplicationBusUserAdditionalDataChangedDispatcherModule,
  ],
})
export class UserAdditionalDataModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IsAllSelectedClientsCommandPort {
  isAllSelected(): Observable<boolean>;
}

export const IS_ALL_SELECTED_CLIENTS_COMMAND = new InjectionToken<IsAllSelectedClientsCommandPort>(
  'IS_ALL_SELECTED_CLIENTS_COMMAND',
);

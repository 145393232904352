import { ApplicationEvent } from '@cobiro/eda';

export class SocialSignedInEvent extends ApplicationEvent {
  constructor(
    public readonly intent: string,
    public readonly provider: string,
    public readonly userId: string,
  ) {
    super();
  }
}

<ng-container *huiLoaderContainer="app$ | async as app">
  <section class="d-flex flex-column flex-sm-row align-items-center cs-mb-15 cs-mb-40-md cs-mh-10">
    <img class="cs-width-60-xs cs-height-60-xs" [src]="app.icon" />
    <article class="flex-1 d-flex flex-column justify-content-center cs-ml-30 cs-mb-10 cs-mb-0-sm">
      <h1 class="cs-mt-0 cs-title-3 text-center text-sm-left">
        {{ app.name | language }}
      </h1>
      <p class="cs-text-2 text-center text-sm-left">{{ app.subtitle | language }}</p>
    </article>
    <div>
      <button
        class="cs-btn cs-btn-primary"
        data-selector="store-details-button"
        [class.is-loading]="buttonLoading"
        [libFeatureId]="app.identifier.toUpperCase() + '_START'"
        (click)="runAdditionalAppFlowBeforeStart()"
      >
        {{ '_subscribe' | language }}
      </button>
    </div>
  </section>
  <!--   TODO: Double border for Google CSS image - fix   -->
  <section class="cs-mb-20 cs-mb-40-md cs-mh-10" *ngIf="app.image">
    <img
      class="d-block d-md-none content-loading-image-height w-100 br-10 cs-b-1 cs-b-shade-15"
      [src]="app.image.mobile"
      alt="store app preview"
    />
    <img
      class="d-none d-md-block content-loading-image-height w-100 br-10 cs-b-1 cs-b-shade-15"
      [src]="app.image.desktop"
      alt="store app preview"
    />
  </section>
  <section class="flex-column-reverse flex-md-row d-flex justify-content-between cs-mh-10">
    <!--    TODO: Styling this part is impossible with FE-based CSS - tempalte is hard-coded on BE by app.content    -->
    <article
      class="store-app-details-content cs-max-width-625-xs w-100 cs-mr-30"
      [innerHTML]="(app.content | language) || ''"
    ></article>
    <aside
      class="store-app-details-sidebar flex-column-reverse flex-md-column d-flex w-100 cs-max-width-360-xs"
    >
      <section
        class="store-app-details-sidebar-endorsements cs-mb-20 cs-mb-40-md"
        *ngIf="app.endorsements?.length"
      >
        <article
          class="cs-p-15 cs-p-20-md d-block d-flex align-items-md-start cs-b-1 cs-b-shade-10 br-10"
          *ngFor="let endorsement of app.endorsements"
        >
          <img
            class="cs-width-40-cs cs-height-40-xs cs-mr-5 cs-mr-20-md"
            [src]="endorsement.icon"
          />
          <span class="flex-1">
            <h5 class="cs-title-8 cs-mt-0 cs-mr-0 cs-mb-5 cs-ml-0">
              {{ endorsement.title | language }}
            </h5>
            <p class="cs-text-2 cs-m-0">
              {{ endorsement.description | language }}
            </p>
            <div *ngIf="endorsement.additionalImages?.length > 0" class="d-flex flex-wrap cs-mt-20">
              <img
                *ngFor="let image of endorsement.additionalImages"
                [src]="image"
                class="cs-height-32-xs w-auto cs-b-1 cs-b-shade-6 cs-mr-5 cs-mb-5"
              />
            </div>
          </span>
        </article>
      </section>
    </aside>
  </section>
</ng-container>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyTypeQuery } from './company-type.query';

export const GETS_COMPANY_TYPES_QUERY = new InjectionToken<GetsCompanyTypesQueryPort>(
  'GETS_COMPANY_TYPES_QUERY',
);

export interface GetsCompanyTypesQueryPort {
  getAvailableCompanyTypes(): Observable<CompanyTypeQuery[]>;
}

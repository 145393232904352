import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CardQuery } from './card.query';

export interface GetsCardsListCommandPort {
  getCards(): Observable<CardQuery[]>;
}

export const GETS_CARDS_LIST_COMMAND = new InjectionToken<GetsCardsListCommandPort>(
  'GETS_CARDS_LIST_COMMAND',
);

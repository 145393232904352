import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingDataCollectedEvent, UserRegisteredEvent } from '@app.cobiro.com/core/events';
import { DomainError, Stepper } from '@app.cobiro.com/core/state';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert/hui-alert';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { BehaviorSubject, mapTo, Observable, tap } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { SignedInEvent } from '../../../../../core/events/src/lib/user/signed-in/signed-in.event';
import {
  REGISTERS_USER,
  RegistersUser,
  RegistersUserUnknownError,
  UserEmailTakenError,
} from '../domain/registers-user';
import { MANAGES_USER, ManagesUser } from '../domain/storage';
import { UserCredentials } from '../domain/user-credentials';
import { ResendsCodeCommandPort } from './ports/primary/resends-code.command-port';
import { VerifiesUserCommand } from './ports/primary/verifies-user.command';
import { VerifiesUserCommandPort } from './ports/primary/verifies-user.command-port';
import { RESENDS_CODE_DTO, ResendsCodeDtoPort } from './ports/secondary/resends-code.dto-port';
import { VERIFIES_USER_DTO, VerifiesUserDtoPort } from './ports/secondary/verifies-user.dto-port';

export interface UserRegisterSteps {
  isRegisterProcessing$: Observable<boolean>;
  isCodeResending$: Observable<boolean>;

  setCaptcha: (token: string) => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  start: (stepper: Stepper) => void;
}

export class RegistrationMissingPayloadPropertyError extends Error {
  constructor(propertyName: string) {
    super();
    this.message = `Missing payload property - ${propertyName}`;
  }
}

export class UserAlreadyVerifiedError extends DomainError {
  public static code = 'USER_ALREADY_VERIFIED';

  constructor() {
    super('User already verified', UserAlreadyVerifiedError.code);
  }
}

export class UserVerifyUnknownError extends DomainError {
  public static code = 'USER_VERIFIED_UNKNOWN_ERROR';

  constructor() {
    super('Verification unknown error', UserVerifyUnknownError.code);
  }
}

@Injectable()
export class UserRegisterState
  implements ResendsCodeCommandPort, UserRegisterSteps, VerifiesUserCommandPort
{
  private readonly KEY = 'user-credentials';

  private _stepper: Stepper;
  private readonly _isRegisterProcessing$ = new BehaviorSubject(false);
  private readonly _isCodeResending$ = new BehaviorSubject(false);

  private readonly _errors = new Map<string, string>([
    [UserEmailTakenError.code, 'register_email_taken_error'],
    [RegistersUserUnknownError.code, '_unknown_error'],
    [UserAlreadyVerifiedError.code, 'login_user_already_verified'],
  ]);

  constructor(
    @Inject(MANAGES_USER) private readonly _managesUser: ManagesUser<UserCredentials>,
    @Inject(REGISTERS_USER) private readonly _registersUser: RegistersUser,
    @Inject(VERIFIES_USER_DTO) private readonly _verifiesUserDto: VerifiesUserDtoPort,
    @Inject(RESENDS_CODE_DTO) private readonly _resendsCodeDto: ResendsCodeDtoPort,
    @Inject(STORAGE) private _storage: Storage,
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
    private _router: Router,
    private _alert: HuiAlert,
  ) {}

  get isRegisterProcessing$(): Observable<boolean> {
    return this._isRegisterProcessing$.asObservable();
  }

  get isCodeResending$(): Observable<boolean> {
    return this._isCodeResending$.asObservable();
  }

  setCaptcha(captchaToken: string) {
    if (!captchaToken) {
      throw new RegistrationMissingPayloadPropertyError('captchaToken');
    }

    this.updateUser({ captchaToken });
  }

  setEmail(email: string): void {
    if (!email) {
      throw new RegistrationMissingPayloadPropertyError('email');
    }

    this.updateUser({ email });
    this._stepper.next();
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up step email continue'));
  }

  setPassword(password: string): void {
    this._isRegisterProcessing$.next(true);

    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up step password continue'));

    if (!password) {
      throw new RegistrationMissingPayloadPropertyError('password');
    }

    this.updateUser({ password });
    const credentials = this._getCredentials();

    this._registersUser
      .register(credentials.email, password, credentials.captchaToken, this._getUtmInterest())
      .pipe(
        take(1),
        finalize(() => this._isRegisterProcessing$.next(false)),
      )
      .subscribe(
        user => {
          this._applicationBus.dispatch(new UserRegisteredEvent(user.userId, credentials.email));
          this._alert.open('success', 'signup_successful');
          this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up success - v2'));
        },
        (err: DomainError) => {
          if (err.name === UserEmailTakenError.code) {
            this._stepper.previous();
          }
          this._handleDomainError(err);
        },
      );
  }

  resendCode(): Observable<void> {
    this._isCodeResending$.next(true);

    const email = this._getCredentials().email;

    return this._resendsCodeDto.resend(email).pipe(
      tap({
        next: () => {
          this._alert.open('success', 'signup_verification_code_resend_success');
        },
        error: () => {
          this._alert.open('error', 'signup_verification_code_resend_fail');
        },
      }),
      mapTo(void 0),
      finalize(() => this._isCodeResending$.next(false)),
    );
  }

  setCredentials(email: string, password: string): void {
    this.updateUser({ email, password });
  }

  verifyUser({ userId, pin }: VerifiesUserCommand): Observable<void> {
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up step verify continue'));

    return this._verifiesUserDto.verify(userId, pin).pipe(
      take(1),
      tap({
        next: auth => {
          if (auth) {
            this._applicationBus.dispatch(
              new TrackingDataCollectedEvent('Sign up step verify success'),
            );
            this._applicationBus.dispatch(
              new SignedInEvent(auth.userId, auth.tokenType, auth.accessToken, auth.refreshToken),
            );
          }
        },
        error: e => {
          if (e && e.name === UserAlreadyVerifiedError.code) {
            this._router.navigate(['/auth/login']);
          }
          this._handleDomainError(e);
        },
      }),
      mapTo(void 0),
    );
  }

  start(stepper: Stepper) {
    this._stepper = stepper;
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up form open'));
  }

  private _getCredentials(): UserCredentials {
    return this._managesUser.find(this.KEY);
  }

  // TODO WJ fix boundaries
  updateUser(obj: Partial<UserCredentials>): void {
    const current: UserCredentials = this._managesUser.find(this.KEY) || {
      email: null,
      password: null,
      captchaToken: null,
    };
    this._managesUser.save(this.KEY, { ...current, ...obj });
  }

  private _getUtmInterest(): string | null {
    const utm = this._storage.getItem('cobiro-utm');
    if (!utm) {
      return null;
    }
    return JSON.parse(utm)?.utmInterest;
  }

  private _handleDomainError(error: DomainError | null) {
    const messageKey = this._errors.get(error.name)?.toLowerCase() ?? '_something_went_wrong';
    this._alert.open('error', messageKey);
  }
}

import { NgModule } from '@angular/core';
import { GETS_PAYMENT_PLANS_DTO } from '../../../../application/ports/secondary/dto/payments/gets-payment-plans.dto-port';
import { HttpGetsPaymentPlanService } from './http-gets-payment-plan.service';

@NgModule({
  providers: [
    HttpGetsPaymentPlanService,
    { provide: GETS_PAYMENT_PLANS_DTO, useExisting: HttpGetsPaymentPlanService },
  ],
})
export class HttpGetsPaymentPlanServiceModule {}

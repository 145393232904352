import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { EmailConsentDto } from './email-consent.dto';

export interface GetsEmailConsentsDtoPort {
  getEmailConsent(): Observable<EmailConsentDto[]>;
}

export const GETS_EMAIL_CONSENTS_DTO_PORT = new InjectionToken<GetsEmailConsentsDtoPort>(
  'GETS_EMAIL_CONSENTS_DTO_PORT',
);

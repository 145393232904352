import { AppMetadataDto } from '../secondary/app-metadata.dto';

/* eslint-disable max-lines-per-function */
export class AppMetadataQuery {
  constructor(
    public readonly identifier: string,
    public readonly icon: string,
    public readonly name: string,
    public readonly subtitle: string,
    public readonly price: number,
    public readonly image: {
      desktop: string;
      mobile: string;
    },
    public readonly endorsements: {
      title: string;
      description: string;
      icon: string;
      additionalImages: string[];
    }[],
    public readonly provider: string,
    public readonly help: {
      support?: string;
      faq?: string;
    },
    public readonly content: string,
    public readonly button?: {
      icon: string;
      text: string;
    },
  ) {}
  static fromDto(dto: AppMetadataDto): AppMetadataQuery {
    return new AppMetadataQuery(
      dto.identifier,
      dto.icon,
      dto.name,
      dto.subtitle,
      dto.price,
      dto.store.details.image,
      dto.store.details.endorsements,
      dto.provider,
      dto.help,
      dto.store.details.content,
      dto.store.details.button,
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { CobiroProOnboardingListComponent } from './onboarding-list.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, LanguageModule, MatIconModule, RouterModule],
  declarations: [CobiroProOnboardingListComponent],
  exports: [CobiroProOnboardingListComponent],
})
export class CobiroProOnboardingListComponentModule {}

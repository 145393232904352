import { ModuleWithProviders, NgModule } from '@angular/core';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

@NgModule({
  imports: [GuidedTourModule],
  exports: [GuidedTourModule],
})
export class TourGuideModule {
  static forRoot(): ModuleWithProviders<TourGuideModule> {
    return {
      ngModule: TourGuideModule,
      providers: [GuidedTourService],
    };
  }
}

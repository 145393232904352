import { Inject, Injectable } from '@angular/core';
import { WorkspaceIdGetter, WORKSPACE_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import { PartnerProgramRequestEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { map, Observable, of, tap } from 'rxjs';
import { SendsPartnerRequestQuery } from '../ports/primary/sends-partner-request.query';
import { SendsPartnerRequestQueryPort } from '../ports/primary/sends-partner-request.query-port';

@Injectable()
export class PartnerProgramState implements SendsPartnerRequestQueryPort {
  constructor(
    private readonly _alert: HuiAlert,
    @Inject(WORKSPACE_ID_GETTER) private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
  ) {}

  sendRequest(query: SendsPartnerRequestQuery): Observable<boolean> {
    return of(
      this._applicationBus.dispatch(
        new PartnerProgramRequestEvent(
          query.userName,
          query.agencyName,
          query.url,
          this._workspaceIdGetter.getWorkspaceId(),
        ),
      ),
    ).pipe(
      map(() => true),
      tap({
        next: () => this._alert.open('success', 'cobiro_pro_partner_program_send_request_success'),
        error: () => this._alert.open('error', '_something_went_wrong'),
      }),
    );
  }
}

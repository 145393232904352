import { Inject, Injectable } from '@angular/core';
import { SignedInEvent, TrackingDataCollectedEvent } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import {
  UserAlreadyVerifiedError,
  VERIFIES_USER_DTO,
  VerifiesUserCommand,
  VerifiesUserCommandPort,
  VerifiesUserDtoPort,
} from '@app.cobiro.com/user/core';
import { UserAlreadyVerifiedEvent, UserVerifiedEvent } from '@app.cobiro.com/user/register';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import { mapTo, Observable, take, tap } from 'rxjs';

@Injectable()
export class VerifyCodeState implements VerifiesUserCommandPort {
  constructor(
    @Inject(APPLICATION_BUS) private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    @Inject(VERIFIES_USER_DTO) private readonly _verifiesUserDto: VerifiesUserDtoPort,
    private readonly _alert: HuiAlert,
  ) {}

  verifyUser({ userId, pin }: VerifiesUserCommand): Observable<void> {
    return this._verifiesUserDto.verify(userId, pin).pipe(
      take(1),
      tap({
        next: auth => {
          if (auth) {
            this._applicationBus.dispatch(
              new TrackingDataCollectedEvent('Sign up step verify success'),
            );
            this._applicationBus.dispatch(
              new SignedInEvent(
                auth.userId,
                auth.tokenType,
                auth.accessToken,
                auth.refreshToken,
                false,
              ),
            );
            this._applicationBus.dispatch(new UserVerifiedEvent());
            this._alert.open('success', 'cobiro_pro_user_verified');
          }
        },
        error: e => {
          if (e && e.name === UserAlreadyVerifiedError.code) {
            this._applicationBus.dispatch(new UserAlreadyVerifiedEvent());
          }

          this._alert.open(
            'error',
            e && e.name === UserAlreadyVerifiedError.code
              ? e.message
              : 'social_default_fail_message',
          );
        },
      }),
      mapTo(void 0),
    );
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerConfigDto } from '../secondary/label-manager-config.dto';

export const SETS_LABEL_MANAGER_CONFIG_FOR_RECOMMENDATION_COMMAND_PORT =
  new InjectionToken<SetsLabelManagerConfigForRecommendationCommandPort>(
    'SETS_LABEL_MANAGER_CONFIG_FOR_RECOMMENDATION_COMMAND_PORT',
  );

export interface SetsLabelManagerConfigForRecommendationCommandPort {
  setConfig(config: LabelManagerConfigDto): Observable<boolean>;
}

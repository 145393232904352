import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientDTO } from '../../secondary/dto/clients/client.dto';
import { AddClientCommand } from './add-client.query';

export const ADD_CLIENT_QUERY = new InjectionToken<AddClientQueryPort>('ADD_CLIENT_QUERY');

export interface AddClientQueryPort {
  addClient(query: AddClientCommand): Observable<ClientDTO>;
}

/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDeleteUserModalComponentModule } from './confirm-delete-user-modal/confirm-delete-user-modal.component-module';
import { InviteUserModalComponentModule } from './invite-user-modal/invite-user-modal.component-module';
import { AddUserModalComponentModule } from './add-user-modal/add-user-modal.component-module';
import { DeleteWorkspaceUserModalComponentModule } from './delete-workspace-user-modal/delete-workspace-user-modal.component-module';
import { DeleteTeamUserModalComponentModule } from './delete-team-user-modal/delete-team-user-modal.component-module';
import { CreateUserModalComponentModule } from './create-user-modal/create-user-modal.component-module';
import { EditAccessModalComponentModule } from './edit-access-modal/edit-access-modal.component-module';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    ConfirmDeleteUserModalComponentModule,
    InviteUserModalComponentModule,
    AddUserModalComponentModule,
    DeleteWorkspaceUserModalComponentModule,
    DeleteTeamUserModalComponentModule,
    CreateUserModalComponentModule,
    EditAccessModalComponentModule,
  ],
})
export class UserModalsModule {}

import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { GETS_LABEL_MANAGER_CONFIG_DTO_PORT } from '../../../../application/ports/secondary/gets-label-manager-config.dto-port';
import { GETS_LABEL_MANAGER_LATEST_CONFIG_DTO_PORT } from '../../../../application/ports/secondary/gets-label-manager-latest-config.dto-port';
import { SETS_LABEL_MANAGER_CONFIG_DTO_PORT } from '../../../../application/ports/secondary/sets-label-manager-config.dto-port';
import { HttpLabelManagerConfigService } from './http-label-manager-config.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpLabelManagerConfigService,
    {
      provide: GETS_LABEL_MANAGER_CONFIG_DTO_PORT,
      useExisting: HttpLabelManagerConfigService,
    },
    {
      provide: SETS_LABEL_MANAGER_CONFIG_DTO_PORT,
      useExisting: HttpLabelManagerConfigService,
    },
    {
      provide: GETS_LABEL_MANAGER_LATEST_CONFIG_DTO_PORT,
      useExisting: HttpLabelManagerConfigService,
    },
  ],
})
export class HttpLabelManagerConfigServiceModule {}

<ng-container *ngIf="(processing$ | async) === false">
  <ng-container *ngIf="(canProcessWithPayment$ | async) === false; else process">
    <lib-payment-not-set [redirect]="settingsRedirectUrl$ | async"></lib-payment-not-set>
  </ng-container>
  <ng-template #process>
    <lib-upgrade-plan-flow-container>
      <lib-select-card-step cardDetailsStep></lib-select-card-step>
      <lib-confirmation-step confirmationStep></lib-confirmation-step>
    </lib-upgrade-plan-flow-container>
  </ng-template>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupClientComponent } from './setup-client.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { ChoosePlanComponentModule } from './steps/choose-plan/choose-plan.component-module';
import { ConfirmPaymentComponentModule } from './steps/confirm-payment/confirm-payment.component-module';
import { CreateShopComponentModule } from './steps/create-shop/create-shop.component-module';
import { AddBillingAddressComponentModule } from './steps/add-billing-address/add-billing-address.component-module';
import { AddPaymentCardComponentModule } from './steps/add-payment-card/add-payment-card.component-module';
import { CobiroProSetupClientStepperComponent } from './setup-client-stepper/setup-client-stepper.component';
import { SetupClientStepContentDirective } from './steps/setup-client-step/setup-client-step-content.directive';
import { CobiroProSetupClientStepComponent } from './steps/setup-client-step/setup-client-step.component';
import { PortalModule } from '@angular/cdk/portal';
import { SetupProgressIndicatorComponentModule } from './setup-progress-indicator/setup-progress-indicator.component-module';

@NgModule({
  declarations: [
    SetupClientComponent,
    CobiroProSetupClientStepperComponent,
    CobiroProSetupClientStepComponent,
    SetupClientStepContentDirective,
  ],
  imports: [
    CommonModule,
    CdkStepperModule,
    HuiStepperModule,
    PortalModule,
    CreateShopComponentModule,
    ChoosePlanComponentModule,
    AddPaymentCardComponentModule,
    AddBillingAddressComponentModule,
    ConfirmPaymentComponentModule,
    SetupProgressIndicatorComponentModule,
  ],
  exports: [SetupClientComponent],
})
export class SetupClientComponentModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroCssStartPageComponent } from './cobiro-css-start-page.component';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';

@NgModule({
  declarations: [CobiroCssStartPageComponent],
  imports: [CommonModule, HuiLoaderContainerModule, FeatureFlagsModule, LanguageModule],
  exports: [CobiroCssStartPageComponent],
})
export class CobiroCssStartPageComponentModule {}

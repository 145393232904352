import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProCloseAccountComponent } from './close-account.component';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CobiroProCloseAccountComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', component: CobiroProCloseAccountComponent }]),
    LanguageModule,
    ReactiveFormsModule,
  ],
  exports: [CobiroProCloseAccountComponent],
})
export class CobiroProCloseAccountModule {}

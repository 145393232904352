import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus, Dispatcher } from '@cobiro/eda';
import { CobiroProTeamUpdatedEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class ApplicationBusTeamUpdatedDispatcher implements Dispatcher<CobiroProTeamUpdatedEvent> {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: CobiroProTeamUpdatedEvent) {
    this._applicationBus.dispatch(event);
  }
}

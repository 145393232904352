import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const SAVES_EMAIL_CONSENT_DTO_PORT = new InjectionToken<SavesEmailConsentDtoPort>(
  'SAVES_EMAIL_CONSENT_DTO_PORT',
);

export interface SavesEmailConsentDtoPort {
  saveEmailConsent(channel: string, consent: boolean): Observable<boolean>;
}

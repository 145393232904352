import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { DiscountCodeComponent } from './discount-code.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';

@NgModule({
  declarations: [DiscountCodeComponent],
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    HuiLoadingModule,
    SharedAnalyticsModule,
    FeatureFlagsModule,
  ],
  exports: [DiscountCodeComponent],
})
export class DiscountCodeComponentModule {}

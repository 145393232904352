import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { InitsLMSimulationDto } from './inits-lm-simulation.dto';

export const INITS_LM_SIMULATION_DTO = new InjectionToken<InitsLMSimulationDtoPort>(
  'INITS_LM_SIMULATION_DTO',
);

export interface InitsLMSimulationDtoPort {
  initSimulation(dto: InitsLMSimulationDto): Observable<boolean>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface SavesTeamSettingsCommand {
  saveTeamSettings(settings: {
    agencyName: string;
    contactEmail: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    countryCode: string;
    vatNumber: string;
  }): Observable<boolean>;
}

export const SAVES_TEAM_SETTINGS_COMMAND = new InjectionToken<SavesTeamSettingsCommand>(
  'SAVES_TEAM_SETTINGS_COMMAND',
);

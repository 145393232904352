import { InjectionToken } from '@angular/core';
import { InMemoryStorage } from '@app.cobiro.com/core/storage';
import { UserCredentials } from './user-credentials';

export interface ManagesUser<T> {
  find(id: string): T | undefined;
  save(id, value: T): void;
}

export const MANAGES_USER = new InjectionToken<ManagesUser<UserCredentials>>('MANAGES_EMAIL', {
  factory: () => new InMemoryStorage<UserCredentials>(),
});

import { AnnoucementDTO } from '../secondary/annoucement.dto';

export class AnnoucementQuery {
  constructor(
    readonly id: string,
    readonly enabled: boolean,
    readonly title: string,
    readonly description: string,
    readonly readMoreLink: string,
  ) {}

  static fromDto(annoucementDto: AnnoucementDTO): AnnoucementQuery {
    return new AnnoucementQuery(
      annoucementDto.id,
      annoucementDto.enabled,
      annoucementDto.title,
      annoucementDto.description,
      annoucementDto.readMoreLink,
    );
  }
}

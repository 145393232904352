import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientDTO } from './client.dto';

export const ADDS_CLIENT_DTO = new InjectionToken<AddsClientDtoPort>('ADDS_CLIENT_DTO');

export interface AddsClientDtoPort {
  add(
    item: Omit<
      ClientDTO,
      | 'cssIntegrationStatus'
      | 'merchantSiteId'
      | 'sitePublicId'
      | 'plan'
      | 'installedApps'
      | 'cssDomainId'
    >,
  ): Observable<boolean>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export class TeamSwitcherItemQuery {
  navigationUrl: string;
  constructor(
    readonly id: string,
    readonly name: string,
    readonly avatar: string,
    readonly role: string,
    readonly membersCount: number,
  ) {
    this.navigationUrl = `/pro/${this.id}`;
  }
}

export interface GetsTeamSwitcherItemsQuery {
  getTeamSwitcherItemsQuery(): Observable<TeamSwitcherItemQuery[]>;
}

export const GETS_TEAM_SWITCHER_ITEMS_QUERY = new InjectionToken<GetsTeamSwitcherItemsQuery>(
  'GETS_WORKSPACE_MANAGER_TEAMS_QUERY',
);

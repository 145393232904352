import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SURVEY_TRACKING_MAP } from './domain/survey-tracking-map';
import { LAUNCHES_SURVEY } from './domain/launches-survey';
import { TypeformLaunchesSurveyService } from './infrastracture/typeform-launches-survey.service';
import { provideEventLogMonitor } from '../event-logger/domain/event-log-monitor';
import { SurveyMonitor } from './application/survey.monitor';
import { TYPEFORM_SURVEY_TRACKING_MAP } from './infrastracture/typeform-survey-tracking-map';

@NgModule({
  providers: [
    {
      provide: SURVEY_TRACKING_MAP,
      useValue: TYPEFORM_SURVEY_TRACKING_MAP,
    },
    {
      provide: LAUNCHES_SURVEY,
      useClass: TypeformLaunchesSurveyService,
    },
    provideEventLogMonitor(SurveyMonitor),
  ],
  imports: [CommonModule],
})
export class SurveyMonitorModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EditWorkspaceCommand } from './edits-workspace.command';

export const EDIT_WORKSPACE_COMMAND = new InjectionToken<EditWorkspaceCommandPort>(
  'EDIT_WORKSPACE_COMMAND',
);

export interface EditWorkspaceCommandPort {
  editWorkspace(command: EditWorkspaceCommand): Observable<void>;
}

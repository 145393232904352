import { NgModule } from '@angular/core';
import { LocalstorageLatestCobiroProLoginService } from './localstorage-latest-cobiro-pro-login.service';
import { SAVE_LATEST_COBIRO_PRO_LOGIN_DTO } from '../../../application/ports/secondary/saves-latest-cobiro-pro-login.dto-port';
import { GET_LATEST_COBIRO_PRO_LOGIN_DTO } from '../../../application/ports/secondary/gets-latest-cobiro-pro-login.dto-port';
import { DELETE_LATEST_COBIRO_PRO_LOGIN_PORT } from '../../../application/ports/secondary/delete-latest-cobiro-pro-login.dto-port';

@NgModule({
  providers: [
    LocalstorageLatestCobiroProLoginService,
    {
      provide: SAVE_LATEST_COBIRO_PRO_LOGIN_DTO,
      useExisting: LocalstorageLatestCobiroProLoginService,
    },
    {
      provide: GET_LATEST_COBIRO_PRO_LOGIN_DTO,
      useExisting: LocalstorageLatestCobiroProLoginService,
    },
    {
      provide: DELETE_LATEST_COBIRO_PRO_LOGIN_PORT,
      useExisting: LocalstorageLatestCobiroProLoginService,
    },
  ],
})
export class LocalstorageLatestCobiroProLoginServiceModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CardsState } from '../../application/cards.state';
import { map } from 'rxjs/operators';
import { CardQuery } from './card.query';
import { GETS_CARDS, GetsCards } from '../../domain/gets-cards';

@Component({
  selector: 'lib-card-list',
  templateUrl: './card-list.component.html',
})
export class CardListComponent implements OnInit {
  cards$: Observable<CardQuery[]>;

  constructor(
    private cardsState: CardsState,
    @Inject(GETS_CARDS) private _cardsGetter: GetsCards,
  ) {}

  ngOnInit(): void {
    this.cards$ = this._cardsGetter
      .get()
      .pipe(map(cards => cards.map(cardInfo => CardQuery.fromCardInfo(cardInfo))));
  }

  onSavedCardClicked(id: string): void {
    this.cardsState.select(id);
  }

  // TODO: selected list
  isSelected$(id: string): Observable<boolean> {
    return this.cardsState.isSelected(id);
  }
}

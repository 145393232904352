import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'hui-default-loader',
  templateUrl: 'hui-default-loader.component.html',
  styleUrls: ['hui-default-loader.component.scss'],
})
export class HuiDefaultLoaderComponent {
  constructor(private _element: ElementRef, private _renderer: Renderer2) {}
  private defaultPaddingClass = 'cs-p-50';

  public setPaddingEnabled(enabled: boolean) {
    enabled
      ? this.addClasses([this.defaultPaddingClass])
      : this.removeClasses([this.defaultPaddingClass]);
  }

  public addClasses(classes: string[]) {
    classes.forEach(cssClass => {
      this._renderer.addClass(this._element.nativeElement.querySelector('.wrapper'), cssClass);
    });
  }

  public removeClasses(classes: string[]) {
    classes.forEach(cssClass => {
      this._renderer.removeClass(this._element.nativeElement.querySelector('.wrapper'), cssClass);
    });
  }
}

import { Injectable } from '@angular/core';
import { SignInFailedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserLoginState } from '../application/state/user-login.state';

@Injectable()
export class UserSignInFailedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [SignInFailedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _userLoginState: UserLoginState) {}

  handle([event]: [SignInFailedEvent]): void {
    this._userLoginState.finishLoginWithError(event.errorMessage);
  }
}

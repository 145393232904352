import { Storage } from '../storage';

// Todo (3): Include siteId context and get rid of removeAll method usage

/**
 * @deprecated use InMemoryStorage with InjectionToken
 * Create InjectionToken in domain e.g.:
 * export const ELEMENTS_STORAGE = new InjectionToken<Storage<Element>>('ELEMENTS_STORAGE');
 *
 * And provide e.g.:
 * { provide: ELEMENTS_STORAGE, useFactory: () => new InMemoryStorage<Element>() },
 *
 * Stub with:
 * { provide: ELEMENTS_STORAGE, useClass: InMemoryStorageStub }
 */
export abstract class AbstractInMemoryStorage<T> implements Storage<T> {
  protected dictionary: Map<string, T> = new Map<string, T>();

  save(id: string, value: T) {
    this.dictionary.set(id, value);
  }

  getKeys(): string[] {
    return [...this.dictionary.keys()];
  }

  find(id: string): T | undefined {
    return this.dictionary.get(id);
  }

  has(id: string): boolean {
    return this.dictionary.has(id);
  }

  findAllBy(property: string, value: any): T[] {
    return [...this.dictionary.values()].filter(element => element[property] === value);
  }

  findAll(): T[] {
    return [...this.dictionary.values()];
  }

  remove(id: string): void {
    this.dictionary.delete(id);
  }

  removeAll(): void {
    this.dictionary = new Map();
  }
}

export class InMemoryStorage<T> extends AbstractInMemoryStorage<T> {}

import { CdkStepper } from '@angular/cdk/stepper';
import { Inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  combineLatest,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import { CardsState } from '../../../cards/application/cards.state';
import { GETS_CARDS, GetsCards } from '../../../cards/domain/gets-cards';
import {
  UPDATES_PLAN_COMMAND_PORT,
  UpdatesPlanCommandPort,
} from '../../../core/application/ports/primary/updates-plan.command-port';
import { CurrentPlanState } from '../../../core/application/state/current-plan.state';
import { DiscountCodeState } from '../../../core/application/state/discount-code.state';
import { PaymentPlanPurchaseState } from '../../../core/application/state/payment-plan-purchase.state';
import { PaymentPlansState } from '../../../core/application/state/payment-plans.state';
import { PlanEstimateState } from '../../../core/application/state/plan-estimate.state';
import { BillingData } from '../../../core/domain/billing-data';
import {
  CREATE_NEW_SUBSCRIPTION_PRE_HOOK,
  CreateNewSubscriptionPreHook,
} from '../../../core/domain/create-new-subscription-pre-hook';
import { PaymentPlanId, PaymentPlanPeriod } from '../../../core/domain/payment-plan';
import { PAYMENT_STATUS } from '../../../core/domain/payment-status.enum';
import { PaymentConfirmationQuery } from '../../../core/query/payment-confirmation.query';
import { CardForm } from './card-form';
import { EstimateData } from './estimate-data';
import { PlanSelectionQuery } from './upgrade-plan-modal-data';

export class MissingPayloadPropertyError extends Error {
  constructor(propertyName: string) {
    super();
    this.message = 'Missing payload property - ' + propertyName;
    this.name = 'UPGRADE_PLAN_MISSING_PROPERTY_ERROR';
  }
}

export enum UPGRADE_PLAN_STEPS {
  PLAN_SELECTION = 'planSelection',
  CARD_INPUT = 'cardInput',
  CARD_SELECTION = 'cardSelection',
}

@Injectable()
export class UpgradePlanProcess {
  private _stepper: CdkStepper;
  private _dialog: MatDialogRef<unknown>;
  private _confirmationSubject = new ReplaySubject<PaymentConfirmationQuery>(1);
  public confirmation$ = this._confirmationSubject.asObservable();
  public readonly cardProcessingInProgress$ = new Subject<boolean>();
  public readonly discountCodeProcessingInProgress$ = new Subject<boolean>();
  private _displayCardForm = new BehaviorSubject(false);
  public readonly displayCardForm$ = this._displayCardForm.asObservable();
  public readonly hasSelectedCard$ = this._cardsState.selectedId$.pipe(
    map(selectedId => !!selectedId),
  );
  readonly hasPlanSelected$ = this._paymentPlansState.selectedPlan$.pipe(
    map(selectedPlan => !!selectedPlan),
  );

  constructor(
    private _cardsState: CardsState,
    @Inject(GETS_CARDS) private _cardsGetter: GetsCards,
    private _paymentPlansState: PaymentPlansState,
    private _currentPlanState: CurrentPlanState,
    private _discountCodeState: DiscountCodeState,
    @Inject(CREATE_NEW_SUBSCRIPTION_PRE_HOOK)
    private _createNewSubscriptionPreHook: CreateNewSubscriptionPreHook,
    // TODO: it should be abstraction
    private _paymentPlanPurchaseState: PaymentPlanPurchaseState,
    @Inject(UPDATES_PLAN_COMMAND_PORT) private _updatesPlanCommand: UpdatesPlanCommandPort,
    private _planEstimateState: PlanEstimateState,
  ) {}

  init(
    stepper: CdkStepper,
    dialog: MatDialogRef<unknown>,
    planSelection?: PlanSelectionQuery,
  ): void {
    this._stepper = stepper;
    this._dialog = dialog;

    // if there's selected plan go to second step instantly
    if (planSelection) {
      this._handlePlanSelection({ planName: planSelection.name, planPeriod: planSelection.period });
    }

    this._hasCards().subscribe(hasCards => this._displayCardForm.next(!hasCards));
  }

  private _hasCards(): Observable<boolean> {
    return this._cardsGetter.get().pipe(map(cards => !!cards && cards.length > 0));
  }

  nextStep(
    step: UPGRADE_PLAN_STEPS,
    payload: {
      url?: string;
      planName?: string;
      planPeriod?: string;
      card?: CardForm;
      countryCode?: string;
      billingData?: BillingData;
    },
  ): void {
    switch (step) {
      case UPGRADE_PLAN_STEPS.PLAN_SELECTION:
        this._handlePlanSelection(payload);
        break;
      case UPGRADE_PLAN_STEPS.CARD_INPUT:
        this._processCard(payload);
        break;
      case UPGRADE_PLAN_STEPS.CARD_SELECTION:
        this._processSelectedCard(payload.countryCode, payload.billingData);
        break;
    }
  }

  private _paymentSucceeded(): void {
    this._stepper.next();
    this._currentPlanState.loadPlan().subscribe();
  }

  private _paymentFailed(): void {
    this._stepper.next();
  }

  private _handlePlanSelection(payload: { planPeriod?: string; planName?: string }): void {
    if (!payload.planPeriod) {
      throw new MissingPayloadPropertyError('planPeriod');
    }
    if (!payload.planName) {
      throw new MissingPayloadPropertyError('planName');
    }

    const planId = new PaymentPlanId(payload.planName, payload.planPeriod as PaymentPlanPeriod);

    this._handleComponentsFlow({ planId: planId.value });
  }

  private _handleComponentsFlow(payload: { planId?: string }): void {
    if (!payload.planId) {
      throw new MissingPayloadPropertyError('planId');
    }

    this._paymentPlansState.changeSelectedPlan(payload.planId);
    return this._stepper.next();
  }

  end(success = false): void {
    this._paymentPlansState.clear();
    this._discountCodeState.clear();
    this._planEstimateState.clear();
    this._dialog.close(success);
  }

  previousStep(): void {
    const isOnCardsDetailsStep = this._stepper.selectedIndex === 1;

    combineLatest([this._hasCards(), this.displayCardForm$])
      .pipe(take(1))
      .subscribe(([hasCards, displayCardForm]) => {
        if (hasCards && displayCardForm && isOnCardsDetailsStep) {
          this._displayCardForm.next(false);
        } else {
          this._stepper.previous();
        }
      });
  }

  showCardForm(): void {
    this._displayCardForm.next(true);
  }

  checkDiscountCode(code: string): Observable<boolean> {
    this.discountCodeProcessingInProgress$.next(true);
    return this._discountCodeState.checkDiscountCode(code).pipe(
      tap(() => {
        this.discountCodeProcessingInProgress$.next(false);
        this._planEstimateState.refreshEstimate();
      }),
    );
  }

  clearDiscountCode(): void {
    this._discountCodeState.clear();
    this._planEstimateState.refreshEstimate();
  }

  estimate(data: EstimateData): void {
    this._planEstimateState.estimate({ countryCode: data.countryCode, vatNumber: data.vatNumber });
  }

  private _processSelectedCard(countryCode: string, billingData: BillingData | null): void {
    this.cardProcessingInProgress$.next(true);

    this._handlePaymentResult(
      this._cardsState.selectedId$.pipe(
        concatMap(selectedCardId =>
          selectedCardId
            ? this._paymentPlanPurchaseState
                .createNewSubscription(
                  selectedCardId,
                  this._createNewSubscriptionPreHook.execute({ countryCode, billingData }),
                )
                .pipe(
                  catchError(() => {
                    this.cardProcessingInProgress$.next(false);
                    return NEVER;
                  }),
                )
            : throwError('Missing selected card id'),
        ),
      ),
    );
  }

  private _processCard(payload: {
    card?: CardForm;
    countryCode?: string;
    billingData?: BillingData;
  }): void {
    if (!payload.card) {
      throw new MissingPayloadPropertyError('card');
    }
    if (!payload.countryCode) {
      throw new MissingPayloadPropertyError('countryCode');
    }

    this.cardProcessingInProgress$.next(true);

    this._handlePaymentResult(
      this._updatesPlanCommand.updatesPlan({
        cardNumber: payload.card.number,
        expiryMonth: payload.card.expiryMonth,
        expiryYear: payload.card.expiryYear,
        cvv: payload.card.cvv,
        cardHolderName: payload.card.name,
        countryCode: payload.countryCode,
        billingData: payload.billingData,
      }),
    );
  }

  private _handlePaymentResult(obs: Observable<PAYMENT_STATUS>): void {
    obs
      .pipe(
        catchError(error => {
          console.error(error);
          return of(PAYMENT_STATUS.DEFAULT_FAILED);
        }),
        take(1),
      )
      .subscribe((paymentStatus: PAYMENT_STATUS) => {
        const query = new PaymentConfirmationQuery(paymentStatus);
        this._confirmationSubject.next(query);
        this._finishPayment(query);
      });
  }

  private _finishPayment(paymentStatusQuery: PaymentConfirmationQuery): void {
    this.cardProcessingInProgress$.next(false);

    paymentStatusQuery.isSuccess ? this._paymentSucceeded() : this._paymentFailed();
  }
}

import { Client, createInstance, setLogLevel } from '@optimizely/optimizely-sdk';
import { FactoryProvider, InjectionToken } from '@angular/core';
import { LogLevel } from '@optimizely/js-sdk-logging';
import { OptimizelyFeatureFlagsService } from '../infrastructure/optimizely/optimizely-feature-flags.service';
import { GETS_FEATURE_FLAGS } from './gets-feature.flags';

export const optimizelyFactoryProvider = (
  baseUrl: string,
  sdkKey: string,
  logLevel: LogLevel,
): Client => {
  setLogLevel(logLevel);

  return createInstance({
    sdkKey,
    datafileOptions: {
      urlTemplate: `${baseUrl}/%s.json?v=${new Date().getTime()}`,
    },
  });
};

export const makeOptimizelyGetsFeatureFlags = (url: string, key: string): FactoryProvider => ({
  provide: GETS_FEATURE_FLAGS,
  useFactory: () =>
    new OptimizelyFeatureFlagsService(optimizelyFactoryProvider(url, key, LogLevel.ERROR)),
});

export const OPTIMIZELY_CLIENT = new InjectionToken<Client>('OptimizelyClient');

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerAttributesComponent } from './label-manager-attributes.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';

@NgModule({
  declarations: [LabelManagerAttributesComponent],
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    NgxSliderModule,
    MatSelectModule,
    FeatureFlagsModule,
  ],
  exports: [LabelManagerAttributesComponent],
})
export class LabelManagerAttributesComponentModule {}

import { SORT_ORDER } from '../../../domain/sort-order.enum';
import { PaginationDTO, RawFilterValue } from '../pagination.dto';

export class PaginationQuery {
  constructor(
    readonly pageIndex: number,
    public readonly pageSize: number,
    public readonly sort: [string, SORT_ORDER],
    public readonly filters?: { [key: string]: RawFilterValue },
    public readonly search?: string,
  ) {}

  static fromDomain(pagination: PaginationDTO): PaginationQuery {
    return new PaginationQuery(
      pagination.pageIndex,
      pagination.pageSize,
      pagination.sort,
      pagination.filter,
      pagination.search,
    );
  }
}

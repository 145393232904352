import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GoesToPersonalModeCommandPort {
  goToPersonalMode(): Observable<boolean>;
}

export const GOES_TO_PERSONAL_MODE_COMMAND = new InjectionToken<GoesToPersonalModeCommandPort>(
  'GOES_TO_PERSONAL_MODE_COMMAND',
);

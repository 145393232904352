<lib-cobiro-pro-label-manager-non-css-stepper #stepper>
  <lib-cobiro-pro-label-manager-non-css-integration-step aria-label="step1" [editable]="false">
    <ng-template libLabelManagerNonCssIntegrationStepContent>
      <lib-cobiro-pro-introduction-step></lib-cobiro-pro-introduction-step>
    </ng-template>
  </lib-cobiro-pro-label-manager-non-css-integration-step>

  <lib-cobiro-pro-label-manager-non-css-integration-step aria-label="step2" [editable]="false">
    <ng-template libLabelManagerNonCssIntegrationStepContent>
      <lib-cobiro-pro-iframe-step></lib-cobiro-pro-iframe-step>
    </ng-template>
  </lib-cobiro-pro-label-manager-non-css-integration-step>

  <lib-cobiro-pro-label-manager-non-css-integration-step aria-label="step3" [editable]="false">
    <ng-template libLabelManagerNonCssIntegrationStepContent>
      <lib-cobiro-pro-loader-step
        (closeWindow)="onCloseModalClicked()"
      ></lib-cobiro-pro-loader-step>
    </ng-template>
  </lib-cobiro-pro-label-manager-non-css-integration-step>
</lib-cobiro-pro-label-manager-non-css-stepper>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerReportQuery } from './label-manager-report.query';

export const SETS_LABEL_MANAGER_REPORT_FOR_RECOMMENDATION_COMMAND_PORT =
  new InjectionToken<SetsLabelManagerReportForRecommendationCommandPort>(
    'SETS_LABEL_MANAGER_REPORT_FOR_RECOMMENDATION_COMMAND_PORT',
  );

export interface SetsLabelManagerReportForRecommendationCommandPort {
  setReport(report: LabelManagerReportQuery): Observable<boolean>;
}

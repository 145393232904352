<h1 class="cs-title-3">{{ 'payment_plans_upgrade_plan' | language }}</h1>
<div>
  <div class="cs-max-height-500-xs overflow-y-auto">
    <lib-card-list></lib-card-list>
  </div>
</div>
<lib-discount-code></lib-discount-code>
<lib-payment-summary></lib-payment-summary>
<div>
  <button
    class="cs-btn cs-btn-primary w-100 cs-mt-40"
    [disabled]="(hasSelectedCard$ | async) === false || (cardProcessingInProgress$ | async)"
    [class.is-loading]="cardProcessingInProgress$ | async"
    data-selector="existing-card-submit-button"
    data-analytics="payment-plans-card-subscribe"
    (click)="onSubscribeClicked()"
  >
    {{ 'payment_plans_start_subscription' | language }}
  </button>
</div>

<ng-container *ngIf="integrationQuery$ | async as integrationQuery">
  <ng-container
    *ngIf="
      integrationQuery.integrationType === 'owned';
      then ownedTypeIntegration;
      else switchedTypeIntegration
    "
  ></ng-container>

  <p class="cs-text-3 cs-mt-10" data-selector="integration-id-info">
    Integration ID: {{ integrationQuery.integrationId }}
  </p>
</ng-container>

<ng-template #ownedTypeIntegration>
  <div data-selector="owned-integration-summary">
    <h4 class="cs-title-4">
      {{ 'google_css_integration_step_2_owned_title' | language }}
    </h4>
    <p class="cs-mt-10">
      {{ 'google_css_integration_step_2_owned_subtitle' | language }}
    </p>
    <ol class="cs-list cs-mt-20">
      <li class="cs-list-item">
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_1_owned_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li
            class="cs-list-item"
            [innerHTML]="
              'google_css_integration_confirm_guide_list_1_owned_description_1' | language
            "
          ></li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_1_owned_description_2' | language }}
          </li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_1_owned_description_3' | language }}
          </li>
        </ul>
      </li>
      <li class="cs-list-item">
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_2_owned_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li
            class="cs-list-item"
            [innerHTML]="
              'google_css_integration_confirm_guide_list_2_owned_description_1' | language
            "
          ></li>
          <li
            class="cs-list-item"
            [innerHTML]="
              'google_css_integration_confirm_guide_list_2_owned_description_2' | language
            "
          ></li>
        </ul>
      </li>

      <li>
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_3_owned_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_3_owned_description_1' | language }}
          </li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_3_owned_description_2' | language }}
          </li>
        </ul>
      </li>
    </ol>
  </div>
</ng-template>

<ng-template #switchedTypeIntegration>
  <div data-selector="switched-integration-summary">
    <h4 class="cs-title-4">
      {{ 'google_css_integration_step_2_switched_title' | language }}
    </h4>
    <p class="cs-mt-10">
      {{ 'google_css_integration_step_2_switched_subtitle' | language }}
    </p>
    <ol class="cs-list cs-mt-20">
      <li class="cs-list-item">
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_1_switched_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_1_switched_description_1' | language }}
          </li>
        </ul>
      </li>
      <li class="cs-list-item">
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_2_switched_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li
            class="cs-list-item"
            [innerHTML]="
              'google_css_integration_confirm_guide_list_2_switched_description_1' | language
            "
          ></li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_2_switched_description_2' | language }}
          </li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_2_switched_description_3' | language }}
          </li>
        </ul>
      </li>

      <li>
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_3_switched_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_3_switched_description_1' | language }}
          </li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_3_switched_description_2' | language }}
          </li>
        </ul>
      </li>

      <li>
        <span class="cs-title-7">
          {{ 'google_css_integration_confirm_guide_list_4_switched_title' | language }}
        </span>
        <ul class="cs-list cs-list-style-type-disc cs-ml-20">
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_4_switched_description_1' | language }}
          </li>
          <li class="cs-list-item">
            {{ 'google_css_integration_confirm_guide_list_4_switched_description_2' | language }}
          </li>
        </ul>
      </li>
    </ol>
  </div>
</ng-template>

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ProductInfoQuery } from './product-info.query';
import { GetsProductInfoQuery } from './gets-product-info.query';

export interface GetsProductInfoQueryPort {
  getProductInfo(command: GetsProductInfoQuery): Observable<ProductInfoQuery>;
}

export const GETS_PRODUCT_INFO_QUERY = new InjectionToken<GetsProductInfoQueryPort>(
  'GETS_PRODUCT_INFO_QUERY',
);

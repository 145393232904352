/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import {
  CobiroProContextQuery,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { CobiroProTeamUpdatedEvent, PaymentBillingEvent } from '@app.cobiro.com/core/events';
import {
  GetsTeamSettingsDtoPort,
  GETS_TEAM_SETTINGS_DTO_PORT,
} from '../../ports/secondary/dto/settings/gets-team-settings.dto-port';
import {
  SavesTeamSettingsDtoPort,
  SAVES_TEAM_SETTINGS_DTO_PORT,
} from '../../ports/secondary/dto/settings/saves-team-settings.dto-port';
import {
  GetsCountriesDtoPort,
  GETS_COUNTRIES_DTO,
} from '../../ports/secondary/dto/settings/gets-countries.dto-port';
import {
  GetsVatNumberValidationDtoPort,
  GETS_VAT_NUMBER_VALIDATION_DTO,
} from '../../ports/secondary/dto/settings/gets-vat-number-validation.dto-port';
import {
  SaveTeamSettingsErrorDto,
  SaveTeamSettingsErrorTypes,
} from '../../ports/secondary/dto/settings/save-team-settings-error.dto';
import { TeamSettingsDto } from '../../ports/secondary/dto/settings/team-settings.dto';
import { CountryDto } from '../../ports/secondary/dto/settings/country.dto';
import { TeamSettingsQuery } from '../../ports/primary/settings/team-settings.query';
import { CountryQuery } from '../../ports/primary/settings/country.query';
import { SetsSelectedCountryCommand } from '../../ports/primary/settings/sets-selected-country.command';
import { GetsTeamSettingsQueryPort } from '../../ports/primary/settings/gets-team-settings.query-port';
import { SavesTeamSettingsCommand } from '../../ports/primary/settings/saves-team-settings.command-port';
import { GetsCountriesQueryPort } from '../../ports/primary/settings/gets-countries.query-port';
import { SetsSelectedCountryCommandPort } from '../../ports/primary/settings/sets-selected-country.command-port';
import { GetsVatNumberValidationQueryPort } from '../../ports/primary/settings/gets-vat-number-validation.query-port';
import { GetsSaveTeamCommandErrorQueryPort } from '../../ports/primary/settings/gets-save-team-command-error.query-port';
import { VatNumberValidationQuery } from '../../ports/primary/settings/vat-number-validation.query';
import { GetsUserDetails, GETS_USER_DETAILS } from '@app.cobiro.com/user/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { CallPaymentBillingCommandPort } from '../../ports/primary/settings/calls-payment-billing-event.command-port';

const COUNTRY_CODES_TO_PASS_VAT_VALIDATION = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

@Injectable()
export class TeamSettingsState
  implements
    GetsTeamSettingsQueryPort,
    SavesTeamSettingsCommand,
    GetsCountriesQueryPort,
    GetsSaveTeamCommandErrorQueryPort,
    GetsVatNumberValidationQueryPort,
    SetsSelectedCountryCommandPort,
    CallPaymentBillingCommandPort
{
  constructor(
    @Inject(GETS_TEAM_SETTINGS_DTO_PORT)
    private readonly _getsTeamSettingsDto: GetsTeamSettingsDtoPort,
    @Inject(SAVES_TEAM_SETTINGS_DTO_PORT)
    private readonly _savesTeamSettingsDto: SavesTeamSettingsDtoPort,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(GETS_COUNTRIES_DTO)
    private readonly _getCountriesDto: GetsCountriesDtoPort,
    private readonly _alert: HuiAlert,
    @Inject(GETS_VAT_NUMBER_VALIDATION_DTO)
    private readonly _getsVatNumberValidationDto: GetsVatNumberValidationDtoPort,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(GETS_USER_DETAILS)
    private readonly _getUserDetails: GetsUserDetails,
    @Inject(STORAGE)
    private readonly _storage: Storage,
  ) {
    this._getCountriesDto.getCountries().subscribe(countries => {
      this._countries$.next(countries);
    });
  }

  private readonly _teamSettingsErrorsMap: Map<SaveTeamSettingsErrorTypes, string> = new Map([
    [SaveTeamSettingsErrorTypes.VAT_NUMBER, 'vatNumber'],
    [SaveTeamSettingsErrorTypes.ZIP_CODE, 'zipCode'],
  ]);
  private readonly _errorFields$ = new Subject<string[]>();
  private readonly _teamSettings$ = new BehaviorSubject<TeamSettingsDto | null>(null);
  private readonly _countries$ = new BehaviorSubject<CountryDto[] | null>(null);
  private readonly _selectedCountry$ = new BehaviorSubject<string>('');

  dispatchEvent(): Observable<void> {
    const teamSettingsDto = this._teamSettings$.getValue();
    return of(
      this._applicationBus.dispatch(
        new PaymentBillingEvent(
          this._getSiteId(),
          this._workspaceIdGetter.getWorkspaceId(),
          teamSettingsDto.agencyName,
          teamSettingsDto.contactEmail,
          teamSettingsDto.addressLine1,
          teamSettingsDto.addressLine2,
          teamSettingsDto.zipCode,
          teamSettingsDto.city,
          teamSettingsDto.countryCode,
          teamSettingsDto.vatNumber,
          teamSettingsDto.avatar,
        ),
      ),
    );
  }

  getTeamSettings(): Observable<TeamSettingsQuery> {
    return this._getsTeamSettingsDto
      .getTeamSettings({ workspaceId: this._workspaceIdGetter.getWorkspaceId() })
      .pipe(
        switchMap((teamSettingsDto: TeamSettingsDto | null) =>
          !teamSettingsDto
            ? combineLatest([
                this._getsCobiroProContextQuery.getContext(),
                this._getUserDetails.getDetails(),
              ]).pipe(
                map(([context, userDetails]) => {
                  return {
                    agencyName: context.selectedTeamName || null,
                    contactEmail: userDetails.email || null,
                    addressLine1: null,
                    addressLine2: null,
                    zipCode: null,
                    city: null,
                    countryCode: userDetails.country || null,
                    vatNumber: null,
                    avatar: null,
                  };
                }),
              )
            : of(teamSettingsDto),
        ),
        tap((teamSettingsDto: TeamSettingsDto) => {
          this._selectedCountry$.next(teamSettingsDto.countryCode);
          this._teamSettings$.next(teamSettingsDto);
        }),
        map(workspaceSettingsDto => TeamSettingsQuery.fromDto(workspaceSettingsDto)),
      );
  }

  getSaveTeamCommandErrorQuery(): Observable<string[]> {
    return this._errorFields$.asObservable();
  }

  saveTeamSettings(settings: TeamSettingsQuery): Observable<boolean> {
    return this._savesTeamSettingsDto
      .saveTeamSettings({
        settings: { ...settings },
        workspaceId: this._workspaceIdGetter.getWorkspaceId(),
      })
      .pipe(
        switchMap(() => this._getsCobiroProContextQuery.getContext()),
        take(1),
        switchMap((context: CobiroProContextQuery) =>
          this._setsSelectedTeamCommand.setSelectedTeam(
            context.selectedTeamId,
            settings.agencyName,
            context.selectedTeamUserRole,
            context.selectedTeamAvatar,
          ),
        ),
        tap({
          next: () =>
            this._alert.open('success', 'cobiro_pro_workspace_settings_form_submit_success'),
          error: () => this._alert.open('error', 'cobiro_pro_workspace_settings_form_submit_error'),
        }),
        catchError((errors: SaveTeamSettingsErrorDto[]) => {
          const failedFieldNames = errors.map(error => this._teamSettingsErrorsMap.get(error.type));
          this._errorFields$.next(failedFieldNames);
          return of(false);
        }),
      );
  }

  getCountriesQuery(): Observable<CountryQuery[]> {
    return this._countries$.asObservable().pipe(
      map(countries => countries && countries.map(({ name, code }) => ({ name, code }))),
      shareReplay(1),
    );
  }

  getVatValidation(vatNumber: string): Observable<VatNumberValidationQuery> {
    if (!this._selectedCountry$.getValue()) {
      return of(new VatNumberValidationQuery(false));
    }
    if (
      !COUNTRY_CODES_TO_PASS_VAT_VALIDATION.includes(
        this._selectedCountry$.getValue().toUpperCase(),
      )
    ) {
      return of(new VatNumberValidationQuery(true));
    }

    if (!vatNumber) {
      return of(new VatNumberValidationQuery(false, 'required'));
    }

    if (vatNumber.length < 4) {
      return of(new VatNumberValidationQuery(false, 'invalidField'));
    }

    return this._getsVatNumberValidationDto
      .getVatValidation(this._selectedCountry$.getValue(), vatNumber)
      .pipe(map(dto => new VatNumberValidationQuery(dto.isValid, 'invalidField')));
  }

  setSelectedCountry(command: SetsSelectedCountryCommand): Observable<void> {
    return of(this._selectedCountry$.next(command.countryCode));
  }

  private _getSiteId(): string {
    return String(this._storage.getItem('cobiro-pro-current-client'));
  }
}

import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { CobiroProTeamCreatedEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class CobiroProTeamCreatedDispatcher implements Dispatcher<CobiroProTeamCreatedEvent> {
  constructor(
    @Inject(APPLICATION_BUS)
    private readonly _dispatcher: Dispatcher<CobiroProTeamCreatedEvent>,
  ) {}

  dispatch(event: CobiroProTeamCreatedEvent) {
    this._dispatcher.dispatch(event);
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UpgradesIntegrationQuery } from './upgrades-integration.query';

export const UPGRADES_INTEGRATION_QUERY = new InjectionToken<UpgradesIntegrationQueryPort>(
  'UPGRADES_INTEGRATION_QUERY',
);

export interface UpgradesIntegrationQueryPort {
  upgradeIntegration(query: UpgradesIntegrationQuery): Observable<boolean>;
}

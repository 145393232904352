import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetsPaymentSourcesDtoPort } from '../../../../application/ports/secondary/gets-payment-sources.dto-port';
import { GetsVatNumberValidationDtoPort } from '../../../../application/ports/secondary/gets-vat-number-validation.dto-port';
import { PaymentSourceDto } from '../../../../application/ports/secondary/payment-source.dto';
import { VatNumberValidationDto } from '../../../../application/ports/secondary/vat-number-validation.dto';

interface PaymentSourceAttributes {
  details: {
    brand: string;
    ownerName: string;
    last4Digits: string;
  };
}

@Injectable()
export class HttpPaymentsService
  implements GetsPaymentSourcesDtoPort, GetsVatNumberValidationDtoPort
{
  constructor(private readonly _gateway: GatewayClient) {}

  getPaymentSources(workspacesId: string): Observable<PaymentSourceDto[]> {
    return this._gateway
      .get<HasDataCollection<PaymentSourceAttributes>>(
        `v1/payments/workspaces/${workspacesId}/payment-sources`,
      )
      .pipe(
        map(response =>
          response.data.map(item => ({
            id: item.id,
            lastDigits: item.attributes.details.last4Digits,
            brand: item.attributes.details.brand,
            cardOwner: item.attributes.details.ownerName,
          })),
        ),
      );
  }

  getVatValidation(countryCode: string, vatNumber: string): Observable<VatNumberValidationDto> {
    const body: HasData<{ country: string; number: string }> = {
      data: {
        type: 'vat-validation',
        attributes: {
          country: countryCode,
          number: vatNumber,
        },
      },
    };

    return this._gateway.post<void>('v1/payments/vat-validation', body).pipe(
      map(() => ({ isValid: true })),
      catchError(e => of({ isValid: e.status > 500 })),
    );
  }
}

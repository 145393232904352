import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  APPLIES_DISCOUNT_CODE_COMMAND,
  AppliesDiscountCodeCommandPort,
} from '../../../../../../../../application/ports/primary/payments/applies-discount-code.command-port';
import {
  GETS_DISCOUNT_COMMAND,
  GetsDiscountCommandPort,
} from '../../../../../../../../application/ports/primary/payments/gets-discount.command-port';
import { DiscountDto } from '../../../../../../../../application/ports/secondary/dto/payments/discount.dto';

import { Observable, take } from 'rxjs';
import {
  ResetsDiscountCodeCommandPort,
  RESETS_DISCOUNT_CODE_COMMAND,
} from '../../../../../../../../application/ports/primary/payments/resets-discount-code.command-port';

@Component({
  selector: 'lib-cobiro-pro-discount-code',
  templateUrl: './discount-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProDiscountCodeComponent implements OnInit {
  readonly discount$: Observable<DiscountDto> = this._getsDiscount.getDiscount();
  readonly discountCodeForm: UntypedFormGroup = this._fb.group({
    discountCode: ['', [Validators.required]],
  });

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _fb: UntypedFormBuilder,
    @Inject(GETS_DISCOUNT_COMMAND)
    private readonly _getsDiscount: GetsDiscountCommandPort,
    @Inject(APPLIES_DISCOUNT_CODE_COMMAND)
    private readonly _appliesDiscountCodeCommand: AppliesDiscountCodeCommandPort,
    @Inject(RESETS_DISCOUNT_CODE_COMMAND)
    private readonly _resetsDiscountCodeCommand: ResetsDiscountCodeCommandPort,
  ) {}
  ngOnInit(): void {
    this.discount$.pipe(take(1)).subscribe((discount: DiscountDto) => {
      if (discount) {
        this.discountCodeForm.patchValue({ discountCode: discount.code });
        this.discountCodeForm.get('discountCode').disable();
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  onDiscountRemove() {
    this._resetsDiscountCodeCommand.resetDiscountCode();
    this.discountCodeForm.reset();
    this.discountCodeForm.get('discountCode').enable();
  }

  onDiscountApply() {
    this._appliesDiscountCodeCommand
      .applyDiscountCode({ code: this.discountCodeForm.get('discountCode').value })
      .subscribe(errorCode => {
        if (errorCode) {
          this.discountCodeForm.get('discountCode').setErrors({ invalidCode: errorCode });
        } else {
          this.discountCodeForm.get('discountCode').disable();
        }
        this._changeDetectorRef.markForCheck();
      });
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelSubscriptionLMCommand } from './cancel-subscription-lm-client.command copy';

export const CANCEL_SUBSCRIPTION_LM_COMMAND = new InjectionToken<CancelSubscriptionLMCommandPort>(
  'CANCEL_SUBSCRIPTION_LM_COMMAND',
);

export interface CancelSubscriptionLMCommandPort {
  cancelSubscriptionLM(command: CancelSubscriptionLMCommand): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnoucementVisibilityDTO } from './annoucement-visibility.dto';

export interface SetsAnnoucementsVisibilityDtoPort {
  setAnnoucementsVisibility(dto: AnnoucementVisibilityDTO): void;
}

export const SETS_ANNOUCEMENT_VISIBILITY_DTO =
  new InjectionToken<SetsAnnoucementsVisibilityDtoPort>('SETS_ANNOUCEMENT_VISIBILITY_DTO');

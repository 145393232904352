import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserDetailQuery } from './user-details.query';

export const GETS_USER_DETAILS_QUERY = new InjectionToken<GetsUserDetailsQueryPort>(
  'GETS_USER_DETAILS_QUERY',
);

export interface GetsUserDetailsQueryPort {
  get(): Observable<UserDetailQuery>;
}

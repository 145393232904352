import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientDTO } from '../../secondary/dto/clients/client.dto';

export const GETS_CURRENT_CLIENT_QUERY = new InjectionToken<GetsCurrentClientQueryPort>(
  'GETS_CURRENT_CLIENT_QUERY',
);

export interface GetsCurrentClientQueryPort {
  getCurrentClient(): Observable<ClientDTO>;
}

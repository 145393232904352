import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapComponent } from './google-map.component';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule } from '@angular/common/http';
import { GOOGLE_MAPS_KEY } from './adapters/secondary/infrastructure/google-maps-key';
import { GoogleMapsLoaderService } from './adapters/secondary/infrastructure/google-maps-loader-service/google-maps-loader.service';
import { GETS_GOOGLE_MAP_GEOCODE } from './adapters/gets-google-map-geocode.adapter';
import { GoogleMapsGetsGeocodeService } from './adapters/secondary/infrastructure/google-maps-geocode-service/google-maps-gets-geocode.service';

export function mapLoaderFactory(loader: GoogleMapsLoaderService) {
  return () => loader.load();
}

@NgModule({
  imports: [CommonModule, GoogleMapsModule, HttpClientJsonpModule],
  declarations: [GoogleMapComponent],
  providers: [
    GoogleMapsLoaderService,
    { provide: GETS_GOOGLE_MAP_GEOCODE, useClass: GoogleMapsGetsGeocodeService },
  ],
  exports: [GoogleMapComponent],
})
export class GoogleMapModule {
  static forRoot(apiKey: string): ModuleWithProviders<GoogleMapModule> {
    return {
      ngModule: GoogleMapModule,
      providers: [
        {
          provide: GOOGLE_MAPS_KEY,
          useValue: apiKey,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: mapLoaderFactory,
          deps: [GoogleMapsLoaderService],
          multi: true,
        },
      ],
    };
  }
}

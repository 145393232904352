import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetsQuestionsQueryPort,
  GETS_QUESTIONS_QUERY,
} from '../../../../application/ports/primary/gets-questions.query-port';
import { QuestionQuery } from '../../../../application/ports/primary/question.query';

@Component({
  selector: 'lib-cobiro-pro-label-manager-faq',
  templateUrl: './label-manager-faq.component.html',
  styleUrls: ['./label-manager-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerFaqComponent {
  readonly questions$: Observable<QuestionQuery[]> = this._getsQuestionsQueryPort.getQuestions({
    productName: 'faq_lm',
  });

  constructor(
    @Inject(GETS_QUESTIONS_QUERY)
    private readonly _getsQuestionsQueryPort: GetsQuestionsQueryPort,
  ) {}
}

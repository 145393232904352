/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  Component,
  ViewChild,
  Inject,
  OnDestroy,
  ViewEncapsulation,
  Input,
  ElementRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { GetsLanguage, GETS_LANGUAGE } from '@app.cobiro.com/common/language';
import { ChartComponent } from 'ng-apexcharts';
import {
  BehaviorSubject,
  map,
  Subject,
  take,
  takeUntil,
  tap,
  filter,
  debounceTime,
  animationFrameScheduler,
  skip,
} from 'rxjs';
import {
  CleansLMReportDateQueryPort,
  CLEANS_LM_REPORT_DATE_QUERY,
} from '../../../../application/ports/primary/cleans-lm-report-date.query-port';
import {
  GetsLabelManagerErrorMessageQueryPort,
  GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-error-message.query-port';
import {
  GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY,
  GetsLabelManagerLoaderMessageQueryPort,
} from '../../../../application/ports/primary/gets-label-manager-loader-message.query-port';
import {
  GetsLabelManagerReportQueryPort,
  GETS_LABEL_MANAGER_REPORT_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-report.query-port';
import {
  IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT,
  IsLabelManagerReportLoadingQueryPort,
} from '../../../../application/ports/primary/is-label-manager-report-loading.query-port';
import { LabelManagerReportDateData } from '../../../../application/ports/primary/label-manager-report.query';
import {
  SetsCurrentLMReportQueryPort,
  SETS_CURRENT_LM_REPORT_QUERY,
} from '../../../../application/ports/primary/sets-current-lm-report.query-port';
import { ChartOptions } from '../../../../application/ports/primary/chart-option.query';
import { RESULT_CHART_OPTION } from '../../../../application/ports/primary/lm-bar-chart-option.query.stub';
import {
  LOADS_LABEL_MANAGER_REPORT_QUERY_PORT,
  LoadsLabelManagerReportQueryPort,
} from '../../../../application/ports/primary/loads-label-manager-report.query-port';
import {
  IS_RECOMMENDATION_LIST_EMPTY_COMMAND_PORT,
  IsRecommendationListEmptyCommandPort,
} from '../../../../application/ports/primary/is-recommendations-list-empty.command-port';

@Component({
  selector: 'lib-label-manager-results',
  templateUrl: './label-manager-results.component.html',
  styleUrls: ['./label-manager-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerResultsComponent implements AfterViewInit, OnDestroy {
  private readonly _ngDestroy$ = new Subject<void>();

  @Input() isSimulation: boolean;
  @Input() defaultValues?: LabelManagerReportDateData;

  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chartContainer') chartContainer: ElementRef;

  readonly chartOptions: Partial<ChartOptions>;

  private readonly _comparisonData$: BehaviorSubject<
    { label: string; value: string; percentage: string }[] | null
  > = new BehaviorSubject(null);

  private readonly _lastUpdateDate$: BehaviorSubject<Date | null> = new BehaviorSubject(null);
  private readonly _totalProducts$: BehaviorSubject<number | null> = new BehaviorSubject(null);
  private readonly dataReport$ = this._getsLabelManagerReportQueryPort.getReport();

  readonly comparisonData$ = this._comparisonData$.asObservable();
  readonly lastUpdateDate$ = this._lastUpdateDate$.asObservable();
  readonly totalProducts$ = this._totalProducts$.asObservable();
  readonly dates$ = this.dataReport$.pipe(
    filter(res => !!res),
    map(res => res.data.map(res => res.date)),
  );
  readonly isLoading$ = this._isLabelManagerReportLoadingQueryPort.isLoading();
  readonly loaderMessage$ = this._getsLabelManagerLoaderMessageQueryPort.getMessage();
  readonly errorMessage$ = this._getsLabelManagerErrorMessageQueryPort.getErrorMessage();

  constructor(
    @Inject(GETS_LANGUAGE)
    private readonly _languageService: GetsLanguage,
    @Inject(GETS_LABEL_MANAGER_REPORT_QUERY_PORT)
    private readonly _getsLabelManagerReportQueryPort: GetsLabelManagerReportQueryPort,
    @Inject(GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY)
    private readonly _getsLabelManagerLoaderMessageQueryPort: GetsLabelManagerLoaderMessageQueryPort,
    @Inject(IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT)
    private readonly _isLabelManagerReportLoadingQueryPort: IsLabelManagerReportLoadingQueryPort,
    @Inject(CLEANS_LM_REPORT_DATE_QUERY)
    private readonly _cleansLMReportDateQueryPort: CleansLMReportDateQueryPort,
    @Inject(GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT)
    private readonly _getsLabelManagerErrorMessageQueryPort: GetsLabelManagerErrorMessageQueryPort,
    @Inject(SETS_CURRENT_LM_REPORT_QUERY)
    private readonly _setsCurrentLMReportQueryPort: SetsCurrentLMReportQueryPort,
    @Inject(LOADS_LABEL_MANAGER_REPORT_QUERY_PORT)
    private readonly _loadsLabelManagerReportQueryPort: LoadsLabelManagerReportQueryPort,
    @Inject(IS_RECOMMENDATION_LIST_EMPTY_COMMAND_PORT)
    private readonly _isRecommendationListEmptyCommandPort: IsRecommendationListEmptyCommandPort,
  ) {
    this.chartOptions = {
      ...RESULT_CHART_OPTION,
      xaxis: {
        categories: ['overIndex', 'index', 'nearIndex', 'noIndex', 'underIndex'],
        labels: {
          formatter: value => {
            return this._languageService.get(`cobiro_pro_label_manager_${value}`);
          },
        },
      },
      dataLabels: {
        formatter: (val: number, opt) => {
          const total = this._totalProducts$.getValue();
          return opt['seriesIndex'] === 0
            ? ''
            : `${(((100 - val) * total) / 100).toFixed(0)} (${(100 - val).toFixed(2)}%)`;
        },
      },
    };
  }

  ngAfterViewInit(): void {
    this.reloadChartData();
    this._isRecommendationListEmptyCommandPort
      .isEmpty()
      .pipe(skip(1))
      .subscribe(() => {
        if (this.chart) {
          this.chart.render();
        }
      });
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
    this._cleansLMReportDateQueryPort.cleanLMReportDate();
  }

  loadData() {
    this._loadsLabelManagerReportQueryPort.loadReport(this.isSimulation).pipe(take(1)).subscribe();
  }

  getComparisonData(data: LabelManagerReportDateData[], index: number): void {
    if (data.length < 2 || index === data.length - 1) {
      this._comparisonData$.next(null);
      return;
    } else {
      const arr: { label: string; value: string; percentage: string }[] = [];

      data[index].dataLabels.forEach((item, i) => {
        const currentValue = data[index].chartSeries[0].data[i];
        const previousValue = data[index + 1].chartSeries[0].data[i];

        const value = currentValue - previousValue;

        const currentPercentage =
          data[index].totalProducts > 0 ? (100 * currentValue) / data[index].totalProducts : 0;
        const previousPercentage =
          data[index + 1].totalProducts > 0
            ? (100 * previousValue) / data[index + 1].totalProducts
            : 0;

        const percentage = currentPercentage - previousPercentage;

        arr.splice(i, 0, {
          label: item,
          value: `${value > 0 ? '+' : ''}${value}`,
          percentage: `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`,
        });
      });
      this._comparisonData$.next(arr);
    }
  }

  isPositiveResult(data: { label: string; value: string }): boolean {
    switch (data.label) {
      case 'overIndex':
        return parseInt(data.value) >= 0;
      case 'index':
        return parseInt(data.value) >= 0;
      case 'nearIndex':
        return parseInt(data.value) >= 0;
      case 'noIndex':
        return parseInt(data.value) < 0;
      case 'underIndex':
        return parseInt(data.value) < 0;
    }
  }

  updateChartData(data: LabelManagerReportDateData, isLatest = true): void {
    this._setsCurrentLMReportQueryPort.setCurrentLMReport(data);
    if (data.totalProducts !== 0) {
      this._totalProducts$.next(data.totalProducts);
      this._lastUpdateDate$.next(data.date);
      this.chart.updateOptions({
        ...this.chartOptions,
        xaxis: {
          categories: data.dataLabels,
        },
        fill: {
          colors: [isLatest ? '#0063FF' : '#707070', '#E2E8F0'],
        },
      });
      this.chart.updateSeries(data.chartSeries);
    } else {
      this._lastUpdateDate$.next(data.date);
      this._totalProducts$.next(0);
      this.chart.updateSeries([]);
    }
  }

  reloadChartData(): void {
    this.dataReport$
      .pipe(
        debounceTime(100, animationFrameScheduler),
        tap(res => {
          if (res === null || res.data.length === 0) {
            this._lastUpdateDate$.next(this.defaultValues ? this.defaultValues.date : null);
            this._totalProducts$.next(this.defaultValues ? this.defaultValues.totalProducts : null);
            this.chart.updateSeries(this.defaultValues ? this.defaultValues.chartSeries : []);
            return;
          }
          if (res.data.length > 0) {
            this.updateChartData(res.data[0]);
            this.getComparisonData(res.data, 0);
          } else {
            this._lastUpdateDate$.next(null);
            this._totalProducts$.next(null);
            this.chart.updateSeries([]);
          }
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onDateChanged(date: Date): void {
    this.dataReport$
      .pipe(
        take(1),
        debounceTime(100, animationFrameScheduler),
        tap(res => {
          const index = res.data.findIndex(
            data => data.date.getTime() === new Date(date).getTime(),
          );
          if (index !== -1) {
            this.updateChartData(res.data[index], index === 0);
            this.getComparisonData(res.data, index);
          }
        }),
      )
      .subscribe();
  }
}

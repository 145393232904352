import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChoosePlanComponent } from './choose-plan.component';
import { SubscriptionListComponentModule } from '../../../modals/change-payment-plan-modal/upgrade-plan-step/select-plan/subscription-list/subscription-list.component-module';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  declarations: [ChoosePlanComponent],
  imports: [CommonModule, LanguageModule, SubscriptionListComponentModule],
  exports: [ChoosePlanComponent],
})
export class ChoosePlanComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsSelectedCardQueryPort {
  getCard(): Observable<string>;
}

export const GETS_SELECTED_CARD_QUERY = new InjectionToken<GetsSelectedCardQueryPort>(
  'GETS_SELECTED_CARD_QUERY',
);

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CompanyDetailsDto } from './company-details.dto';

export const SAVES_COMPANY_DETAILS_DTO_PORT = new InjectionToken<SavesCompanyDetailsDtoPort>(
  'SAVES_COMPANY_DETAILS_DTO_PORT',
);

export interface SavesCompanyDetailsDtoPort {
  savesCompanyDetail(dto: CompanyDetailsDto): Observable<void>;
}

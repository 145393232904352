import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { IntegrationIdChangedEventHandler } from '../adapters/primary/handlers/integration-id-changed.event-handler';
import { LoadIntegrationResolver } from '../adapters/primary/resolvers/load-integration.resolver';
import { HttpAppsCdnServiceModule } from '../adapters/secondary/infrastructure/http-apps-cdn/http-apps-cdn.service-module';
import { HttpClientsProductServiceModule } from '../adapters/secondary/infrastructure/http-clients-products/http-clients-products.service-module';
import { HttpCssMerchantIdsServiceModule } from '../adapters/secondary/infrastructure/http-css-merchant-ids/http-css-merchant-ids.service-module';
import { HttpInstalledAppsServiceModule } from '../adapters/secondary/infrastructure/http-installed-apps/http-installed-apps.service-module';
import { HttpIntegrationServiceModule } from '../adapters/secondary/infrastructure/http-integration/http-integration.service-module';
import { InMemoryIntegrationContextStorageModule } from '../adapters/secondary/infrastructure/in-memory-integration-context/in-memory-integration-context.storage-module';
import { GoogleCssIntegrationStateModule } from '../application/state/google-css-integration.state-module';

@NgModule({
  imports: [
    GoogleCssIntegrationStateModule,
    HttpAppsCdnServiceModule,
    HttpIntegrationServiceModule,
    HttpInstalledAppsServiceModule,
    InMemoryIntegrationContextStorageModule,
    HttpClientsProductServiceModule,
    HttpCssMerchantIdsServiceModule,
  ],
  providers: [
    LoadIntegrationResolver,
    provideApplicationEventsHandler(IntegrationIdChangedEventHandler),
  ],
})
export class CobiroProRewriteCobiroCssProvidersModule {}

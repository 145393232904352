import { DomainEvent } from '../domain/event';

export class PlanPaymentCompletedEvent extends DomainEvent {
  constructor(
    public readonly success: boolean,
    public readonly planId: string,
    public readonly planPrice: number,
    public readonly planName: string,
    public readonly planPeriod: string,
    public readonly transactionTotal: number,
    public readonly transactionTax: number,
    public readonly transactionId: string,
  ) {
    super();
  }
}

import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { CompanyBaseInfoStepComponentModule } from './steps/company-base-info-step/company-base-info-step.component-module';
import { CompanyDetailsStepComponentModule } from './steps/company-details-step/company-details-step.component-module';
import { CreateAgencyWithWorkspaceStepComponentModule } from './steps/create-agency-with-workspace-step/create-agency-with-workspace-step.component-module';
import { WorkspaceInfoStepComponentModule } from './steps/workspace-info-step/workspace-info-step.component-module';
import { CreateProfileFlowComponent } from './create-profile-flow.component';
import { TopBarComponentModule } from '@app.cobiro.com/cobiro-pro-rewrite/layout';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    CdkStepperModule,
    HuiStepperModule,
    TopBarComponentModule,
    CompanyBaseInfoStepComponentModule,
    CompanyDetailsStepComponentModule,
    CreateAgencyWithWorkspaceStepComponentModule,
    CreateAgencyWithWorkspaceStepComponentModule,
    WorkspaceInfoStepComponentModule,
  ],
  declarations: [CreateProfileFlowComponent],
  exports: [CreateProfileFlowComponent],
})
export class CreateProfileFlowComponentModule {}

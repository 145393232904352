import { Inject, Injectable } from '@angular/core';
import { PaymentHandler } from '@app.cobiro.com/payment-plans';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { UpgradePlanPresetCardsFlowComponent } from '../adapters/primary/ui/upgrade-plan-preset-cards-flow/upgrade-plan-preset-cards-flow.component';

@Injectable()
export class CobiroProPaymentHandler implements PaymentHandler {
  constructor(@Inject(TEAM_ID_GETTER) private _teamIdGetter: TeamIdGetter) {}

  component = UpgradePlanPresetCardsFlowComponent;

  conditionFn(): boolean {
    return !!this._teamIdGetter.getTeamId();
  }
}

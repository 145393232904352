import { GetsUserRoleDtoPort } from '../../../application/ports/secondary/gets-user-role-dto.port';
import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { USER_ROLE } from '../../../application/ports/secondary/team.dto';
import { map, switchMap } from 'rxjs/operators';
import {
  GETS_COBIRO_PRO_CONTEXT_DTO,
  GetsCobiroProContextDtoPort,
} from '@app.cobiro.com/cobiro-pro/context';

interface ClientAttributes {
  siteId: number;
}

// I'm leaving it untested because it will be removed soon
@Injectable()
export class HttpGetsUserRoleService implements GetsUserRoleDtoPort {
  constructor(
    private _client: GatewayClient,
    @Inject(GETS_COBIRO_PRO_CONTEXT_DTO)
    private _getsCobiroProContextDto: GetsCobiroProContextDtoPort,
  ) {}
  getsUserRole(siteId: string): Observable<USER_ROLE | null> {
    return this._getsCobiroProContextDto.getContext().pipe(
      switchMap(context => {
        return context.selectedTeam?.id
          ? this._fetchUserRole(
              siteId,
              context.selectedTeam.id,
              context.selectedTeam.userRole as USER_ROLE,
            )
          : of(USER_ROLE.regular); // We are assuming that it's a regular user)
      }),
    );
  }

  private _fetchUserRole(
    siteId: string,
    currentTeamId: string,
    currentTeamRole: USER_ROLE,
  ): Observable<USER_ROLE | null> {
    return this._client
      .get<HasDataCollection<ClientAttributes>>(`v1/sites/teams/${currentTeamId}/clients`)
      .pipe(
        map(response => {
          const client = response.data.find(
            responseClient => responseClient.attributes.siteId === Number(siteId),
          );
          return client ? currentTeamRole : null;
        }),
      );
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { LayoutState } from './layout.state';

@Component({
  selector: 'lib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'layout d-flex flex-column',
  },
})
export class LayoutComponent {
  readonly hasWarning$ = this.layoutState.hasWarning$;

  constructor(private readonly layoutState: LayoutState) {}
}

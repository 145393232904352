import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { PAYMENT_STATUS } from './payment-status.enum';
import { CreateNewSubscriptionDto } from './create-new-subscription.dto';

export const CREATES_NEW_SUBSCRIPTION_DTO_PORT = new InjectionToken<CreatesNewSubscriptionDtoPort>(
  'CREATES_NEW_SUBSCRIPTION_DTO_PORT',
);

export interface CreatesNewSubscriptionDtoPort {
  createNewSubscription(dto: CreateNewSubscriptionDto): Observable<PAYMENT_STATUS>;
}

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { APP_BASE_HREF, CommonModule, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  AuthModule,
  RolesService,
  SignedInEventHandler,
  SignInStartedEventHandler,
  TokenInterceptor,
  UnauthenticatedInterceptor,
  UserAuthorizedEventHandler,
  UserProfileUpdatedEventHandler,
  UserState,
  UserVerifiedAccountEventHandler,
  WrongContentTypeInterceptor,
} from '@app.cobiro.com/auth';
import { CobiroProRewriteClientProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/clients';
import { CobiroProRewriteCobiroCssProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/cobiro-css';
import { CobiroProRewriteFaqSectionProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/faq-section';
import { CobiroProRewriteHomeProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/home';
import { CobiroProRewriteLabelManagerProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/label-manager';
import { CobiroProRewriteMyProfileProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/my-profile';
import { CobiroProRewritePartnerProgramProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/partner-program';
import { CobiroProRewriteSettingsProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/settings';
import { CobiroProRewriteTeamSwitcherProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/team-switcher';
import { CobiroProRewriteUserProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/users';
import { CobiroProRewriteWorkspacesProvidersModule } from '@app.cobiro.com/cobiro-pro-rewrite/workspaces';
import { TeamAccessStateModule, TeamsStateModule } from '@app.cobiro.com/cobiro-pro-team';
import { CobiroProTeamSwitcherStateModule } from '@app.cobiro.com/cobiro-pro-teams-switcher';
import { ClientProvidersModule } from '@app.cobiro.com/cobiro-pro/client';
import { CobiroProContextModule } from '@app.cobiro.com/cobiro-pro/context';
import { CobiroProPaymentPlansModule } from '@app.cobiro.com/cobiro-pro/payment-plans';
import { CobiroProUpgradeWidgetStateModule } from '@app.cobiro.com/cobiro-pro/upgrade-widget';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { appInit } from '@app.cobiro.com/core/app-init';
import { ErrorHandlerInterface, LocalErrorHandler } from '@app.cobiro.com/core/error-handling';
import { CoreLoggingModule } from '@app.cobiro.com/core/logging';
import { CorePaymentsFeaturesModule } from '@app.cobiro.com/core/payments-features';
import { STORAGE } from '@app.cobiro.com/core/storage';
import {
  ConfigModule,
  ENV_CONFIG,
  GetsConfig,
  provideWindowConfig,
  WINDOW,
  WindowEnvConfig,
} from '@app.cobiro.com/core/utils';
import { CoreUtmModule } from '@app.cobiro.com/core/utm';
import { GoogleCountriesModule } from '@app.cobiro.com/google/countries';
import { GoogleLocationsModule } from '@app.cobiro.com/google/locations';
import { GoogleMapModule, RedirectGoogleComponentModule } from '@app.cobiro.com/google/shared';
import { LoggingModule } from '@app.cobiro.com/logging';
import { CoreNotificationsModule } from '@app.cobiro.com/notifications';
import { DigitsInputModule } from '@app.cobiro.com/shared/hui/digits-input/digits-input.module';
import { IconsRegistryModule } from '@app.cobiro.com/shared/hui/icons-registry';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { SharedLayoutModule } from '@app.cobiro.com/shared/layout';
import { NotFoundModule } from '@app.cobiro.com/shared/not-found';
import { AppPreloaderModule } from '@app.cobiro.com/shared/ui-state';
import { TourGuideModule } from '@app.cobiro.com/tour-guide';
import {
  TrackingDataCollectedEventHandler,
  TrackingService,
  UserAssignedToTrackingEventHandler,
} from '@app.cobiro.com/tracking';
import { UserAdditionalDataModule } from '@app.cobiro.com/user/additional-data';
import { UserCompleteProfileHandlersModule } from '@app.cobiro.com/user/complete-profile';
import { GETS_USER_FROM_TOKEN, UserCoreModule } from '@app.cobiro.com/user/core';
import { UserImpersonationModule } from '@app.cobiro.com/user/impersonation';
import { UserLoginModule, UserSignedOutEventHandler } from '@app.cobiro.com/user/login';
import {
  CreateProfileStateModule,
  HttpCreatesProfileServiceModule,
  HttpUserDetailsServiceModule,
  UnverifiedUserSignedInEventHandler,
  UserIsInvitedEventHandler,
  UserRegisterModule,
} from '@app.cobiro.com/user/register';
import { UserSettingsModule } from '@app.cobiro.com/user/settings';
import { CoreSocialSignUpModule } from '@app.cobiro.com/user/social-sign-up';
import { ApplicationBusModule, provideApplicationEventsHandler } from '@cobiro/eda';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import {
  FeatureFlagsModule,
  FeatureFlagsState,
  makeOptimizelyGetsFeatureFlags,
} from '@cobiro/ng-feature-flags';
import { RolesModule } from '@cobiro/roles';
import * as Sentry from '@sentry/angular-ivy';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';
import { Environment } from '../../../../environments/environment.config';
import { LanguageService } from '../../../../libs/common/language/src/lib/adapter/secondary/language.service';
import { AddNewShopClickedEventHandler } from '../adapters/event-handlers/add-new-shop-clicked.event-handler';
import { AgencyWithWorkspaceCreatedEventHandler } from '../adapters/event-handlers/agency-with-workspace-created.event-handler';
import { InviteUserClickedEventHandler } from '../adapters/event-handlers/invite-user-clicked.event-handler';
import { ManageClientClickedEventHandler } from '../adapters/event-handlers/manage-client-clicked.event-handler';
import { OpenSetupFlowClickedEventHandler } from '../adapters/event-handlers/open-setup-flow-clicked.event-handler';
import { TeamSelectedEventHandler } from '../adapters/event-handlers/team-selected.event-handler';
import { ToDoClickedEventHandler } from '../adapters/event-handlers/todo-clicked.event-handler';
import { UpgradePlanClickedEventHandler } from '../adapters/event-handlers/upgrade-plan-clicked.event-handler';
import { UserAlreadyVerifiedEventHandler } from '../adapters/event-handlers/user-already-verified.event-handler';
import { UserChangedEventHandler } from '../adapters/event-handlers/user-changed.event-handler';
import { UserSignedUpEventHandler } from '../adapters/event-handlers/user-signed-up.event-handler';
import { UserVerifiedEventHandler } from '../adapters/event-handlers/user-verified.event-handler';
import { ViewAllClientsClickedEventHandler } from '../adapters/event-handlers/view-all-clients-clicked.event-handler';
import { ViewAllMembersClickedEventHandler } from '../adapters/event-handlers/view-all-members-clicked.event-handler';
import { GetsCurrentTeamService } from '../adapters/primary/gets-current-team.service';
import { GetsSideBarOptionsService } from '../adapters/primary/gets-side-bar-options.service';
import { GetsUserDetailsService } from '../adapters/primary/gets-user-details.service';
import { GetsUserMenuOptionsService } from '../adapters/primary/gets-user-menu-options.service';
import { Languages } from '../assets/languages/languages';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import CustomContainerScroller from './custom-container-scroller';
import { SocialLoginModule } from '@abacritt/angularx-social-login';

const environment: Environment = provideWindowConfig();

// TODO: Replace with Data Dog Error Handler if needed
export function getErrorHandler(_config: GetsConfig): ErrorHandlerInterface {
  return new LocalErrorHandler();
}

const ANGULAR_CDK_MODULES = [OverlayModule, PortalModule];

const ANGULAR_BASIC_MODULES = [
  CommonModule,
  BrowserAnimationsModule,
  BrowserModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const ANGULAR_MATERIAL_MODULES = [
  MatDatepickerModule,
  MatDialogModule,
  MatNativeDateModule,
  MatSelectModule,
];

const APPLICATION_GUARDS = [];

const APPLICATION_EVENT_HANDLERS = [
  provideApplicationEventsHandler(SignInStartedEventHandler),
  provideApplicationEventsHandler(UserSignedOutEventHandler),
  provideApplicationEventsHandler(UserVerifiedAccountEventHandler),
  provideApplicationEventsHandler(UnverifiedUserSignedInEventHandler),
  provideApplicationEventsHandler(TrackingDataCollectedEventHandler),
  provideApplicationEventsHandler(OpenSetupFlowClickedEventHandler),
  provideApplicationEventsHandler(AddNewShopClickedEventHandler),
  provideApplicationEventsHandler(ManageClientClickedEventHandler),
  provideApplicationEventsHandler(InviteUserClickedEventHandler),
  provideApplicationEventsHandler(ToDoClickedEventHandler),
  provideApplicationEventsHandler(UpgradePlanClickedEventHandler),
  provideApplicationEventsHandler(ViewAllClientsClickedEventHandler),
  provideApplicationEventsHandler(ViewAllMembersClickedEventHandler),
  provideApplicationEventsHandler(TeamSelectedEventHandler),
  provideApplicationEventsHandler(AgencyWithWorkspaceCreatedEventHandler),
  provideApplicationEventsHandler(UserChangedEventHandler),
  provideApplicationEventsHandler(UserSignedUpEventHandler),
  provideApplicationEventsHandler(UserVerifiedEventHandler),
  provideApplicationEventsHandler(UserAlreadyVerifiedEventHandler),
  provideApplicationEventsHandler(SignedInEventHandler),
  provideApplicationEventsHandler(UserAssignedToTrackingEventHandler),
  provideApplicationEventsHandler(UserAuthorizedEventHandler),
  provideApplicationEventsHandler(UserProfileUpdatedEventHandler),
  provideApplicationEventsHandler(UserIsInvitedEventHandler),
];

const APPLICATION_ADAPTERS = [
  GetsSideBarOptionsService,
  GetsUserDetailsService,
  GetsUserMenuOptionsService,
  GetsCurrentTeamService,
];

const COBIRO_CORE_MODULES = [
  CoreLoggingModule,
  CoreUtmModule,
  CorePaymentsFeaturesModule.forRoot(),
  ConfigModule,
];

const USER_MODULES = [
  UserCoreModule.forChild(),
  UserImpersonationModule.forRoot(),
  UserLoginModule,
  UserRegisterModule,
  CoreSocialSignUpModule.forRoot(),
  UserCompleteProfileHandlersModule.forRoot(),
  UserSettingsModule,
  UserAdditionalDataModule,
  CreateProfileStateModule,
  HttpCreatesProfileServiceModule,
  HttpUserDetailsServiceModule,
  SocialLoginModule,
];

const COBIRO_PRO_MODULES = [
  CobiroProPaymentPlansModule,
  CobiroProUpgradeWidgetStateModule,
  CobiroProTeamSwitcherStateModule,
  CobiroProContextModule,
  TeamsStateModule, // FIXME: Mateusz COBIRO PRO: Move it Cobiro Pro layout when Layout and Cobiro pro is decoupled,
  TeamAccessStateModule,
  ClientProvidersModule,
];

const COBIRO_PRO_REWRITE_MODULES = [
  CobiroProRewriteClientProvidersModule,
  CobiroProRewriteUserProvidersModule,
  CobiroProRewriteHomeProvidersModule,
  CobiroProRewriteSettingsProvidersModule,
  CobiroProRewriteWorkspacesProvidersModule,
  CobiroProRewritePartnerProgramProvidersModule,
  CobiroProRewriteMyProfileProvidersModule,
  CobiroProRewriteTeamSwitcherProvidersModule,
  CobiroProRewriteCobiroCssProvidersModule,
  CobiroProRewriteLabelManagerProvidersModule,
  CobiroProRewriteFaqSectionProvidersModule,
];

const HUI_MODULES = [
  IconsRegistryModule,
  HuiLoadingModule,
  DigitsInputModule,
  HuiInputModule.forRoot(LanguageService),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    ...ANGULAR_CDK_MODULES,
    ...ANGULAR_BASIC_MODULES,
    ...ANGULAR_MATERIAL_MODULES,
    ...COBIRO_CORE_MODULES,
    ...USER_MODULES,
    ...COBIRO_PRO_REWRITE_MODULES,
    ...COBIRO_PRO_MODULES,
    ...HUI_MODULES,
    GoogleMapModule.forRoot(environment.google.maps),
    RedirectGoogleComponentModule,
    FeatureFlagsModule.forRoot(
      makeOptimizelyGetsFeatureFlags(environment.optimizely.url, environment.optimizely.key),
    ),
    GatewayModule.forRoot(environment.gateway, JSON_API_HEADERS),
    ApplicationBusModule.forRoot(),
    TourGuideModule.forRoot(),
    LottieModule.forRoot({ player: () => player }),
    LanguageModule.forRoot(Languages),
    AppRoutingModule,
    LoggingModule,
    SharedLayoutModule,
    GoogleLocationsModule,
    GoogleCountriesModule,
    AppPreloaderModule,
    CoreNotificationsModule,
    AuthModule,
    RolesModule.forRoot({
      useFactory: (rolesService: RolesService) => {
        return {
          getAll: () => rolesService.getAll(),
        };
      },
      inject: [RolesService],
    }),
    NotFoundModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    ...APPLICATION_EVENT_HANDLERS,
    ...APPLICATION_GUARDS,
    ...APPLICATION_ADAPTERS,
    { provide: WINDOW, useValue: window },
    { provide: STORAGE, useValue: localStorage },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: ENV_CONFIG, useClass: WindowEnvConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [FeatureFlagsState, UserState, TrackingService, STORAGE, GETS_USER_FROM_TOKEN],
    },
    {
      provide: ErrorHandler,
      useFactory: config => getErrorHandler(config),
      deps: [WindowEnvConfig],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WrongContentTypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ViewportScroller,
      useFactory: () => {
        return new CustomContainerScroller(document, 'layout-body-scroll-container');
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

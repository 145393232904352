import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';
import { Observable } from 'rxjs';
import { RecoverPasswordState } from '../../application/state/recover-password.state';

@Component({
  selector: 'lib-password-recovery-page',
  templateUrl: './password-recovery-page.component.html',
  host: {
    class: 'cs-height-inherit-xs',
  },
})
export class PasswordRecoveryPageComponent implements AfterViewInit {
  @ViewChild('stepper') public _stepper: HuiStepperComponent;

  public readonly form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  isLoading$: Observable<boolean> = this._state.isLoading$;

  inputPassword = true;

  constructor(private _state: RecoverPasswordState) {}

  control(name: string) {
    return this.form.get(name);
  }

  hasError(controlName: string) {
    const { invalid, touched } = this.form.get(controlName);
    return invalid && touched;
  }

  onSubmit() {
    if (this.form.valid) {
      const { email } = this.form.getRawValue();
      this._state.recover(email);
    }
  }

  onResendClicked() {
    this._stepper.previous();
  }

  ngAfterViewInit() {
    this._state.init(this._stepper);
  }
}

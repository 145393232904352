import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { DomainError } from '@app.cobiro.com/core/state';

export enum SIGN_UP_PROVIDERS {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

export class SocialAuth {
  constructor(
    public readonly userId: number,
    public readonly tokenType: string,
    public readonly accessToken: string,
    public readonly refreshToken: string,
  ) {}
}

export class SocialMissingEmailError extends DomainError {
  static readonly code = 'SOCIAL_MISSING_EMAIL';

  constructor() {
    super('Social missing email error', 'SOCIAL_MISSING_EMAIL');
  }
}

export class SocialUnexpectedError extends DomainError {
  static readonly code = 'SOCIAL_UNEXPECTED_ERROR';

  constructor() {
    super('Social unexpected error', 'SOCIAL_UNEXPECTED_ERROR');
  }
}

export interface SignsUpBy {
  signUpBy(provider: SIGN_UP_PROVIDERS, oauthToken: string): Observable<SocialAuth>;
}

export const SIGNS_UP_BY = new InjectionToken<SignsUpBy>('SIGNS_UP_BY');

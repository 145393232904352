import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationContext } from './integration.context';

export const SELECTS_INTEGRATION_CONTEXT = new InjectionToken<SelectsIntegrationContextPort>(
  'SELECTS_INTEGRATION_CONTEXT',
);

export interface SelectsIntegrationContextPort {
  select(): Observable<Partial<IntegrationContext>>;
}

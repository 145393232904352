import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { DomainError } from '@app.cobiro.com/core/state';

export interface HasUserId {
  userId: string;
}

export interface RegistersUser {
  register(
    email: string,
    password: string,
    captchaToken: string,
    utmInterest?: string,
  ): Observable<HasUserId | never>;
}

export const REGISTERS_USER = new InjectionToken<RegistersUser>('REGISTERS_USER');

export class UserEmailTakenError extends DomainError {
  static readonly code = 'USER_EXISTS';

  constructor() {
    super('Email address already registered', 'USER_EXISTS');
  }
}

export class RegistersUserUnknownError extends DomainError {
  static readonly code = 'UNKNOWN_ERROR';

  constructor(error: string = 'Unknown error') {
    super(error, 'UNKNOWN_ERROR');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { BASE_URL } from '@app.cobiro.com/core/utils';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { DigitsInputModule } from '@app.cobiro.com/shared/hui/digits-input/digits-input.module';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { PasswordFormModule } from '@app.cobiro.com/user/shared';
import { ApplicationBusModule } from '@cobiro/eda';
import { RecaptchaModule } from 'ng-recaptcha';
import { WindowEnvConfig } from '../../../../core/utils/src/lib/config/window-config.service';
import { HttpResendsCodeServiceModule } from './adapters/secondary/http-resends-code.service-module';
import { HttpVerifyCodeServiceModule } from './adapters/secondary/http-verify-code.service-module';
import { StorageGetsUserFromToken } from './adapters/storage-gets-user-from-token.service';
import { RESENDS_CODE_COMMAND } from './application/ports/primary/resends-code.command-port';
import { VERIFIES_USER_COMMAND } from './application/ports/primary/verifies-user.command-port';
import { UserRegisterState } from './application/user-register.state';
import { GETS_USER_DETAILS } from './domain/gets-user-details';
import { GETS_USER_FROM_TOKEN } from './domain/gets-user-from-token';
import { RegisterEmailFormComponent } from './ui/forms/register-email-form/register-email-form.component';
import { VerifyCodeFormComponentModule } from './ui/forms/verify-code-form/verify-code-form.component-module';
import { UserRegisterContainerComponent } from './ui/user-register-container/user-register-container.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    HuiLoadingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SharedAnalyticsModule,
    MatIconModule,
    ApplicationBusModule,
    RecaptchaModule,
    HuiStepperModule,
    RouterModule,
    PasswordFormModule,
    DigitsInputModule,
    VerifyCodeFormComponentModule,

    HttpVerifyCodeServiceModule,
    HttpResendsCodeServiceModule,
  ],
  providers: [
    { provide: STORAGE, useValue: localStorage },
    { provide: GETS_USER_FROM_TOKEN, useClass: StorageGetsUserFromToken },
    { provide: GETS_USER_DETAILS, useClass: StorageGetsUserFromToken },
    {
      provide: BASE_URL,
      useFactory: (envConfig: WindowEnvConfig) => envConfig.get('hub'),
      deps: [WindowEnvConfig],
    },
  ],
  declarations: [UserRegisterContainerComponent, RegisterEmailFormComponent],
  exports: [UserRegisterContainerComponent],
})
export class UserCoreModule {
  static forChild() {
    return {
      ngModule: UserCoreModule,
      providers: [
        UserRegisterState,
        {
          provide: RESENDS_CODE_COMMAND,
          useExisting: UserRegisterState,
        },
        {
          provide: VERIFIES_USER_COMMAND,
          useExisting: UserRegisterState,
        },
      ],
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { ClientDashboardNavbarComponent } from './client-dashboard-navbar.component';

@NgModule({
  imports: [CommonModule, FeatureFlagsModule, LanguageModule, RouterModule],
  declarations: [ClientDashboardNavbarComponent],
  exports: [ClientDashboardNavbarComponent],
})
export class ClientDashboardNavbarComponentModule {}

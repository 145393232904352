import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const GETS_ALL_CLIENTS_PLANS_QUERY = new InjectionToken<GetsAllClientsPlansQueryPort>(
  'GETS_ALL_CLIENTS_PLANS_QUERY',
);

export interface GetsAllClientsPlansQueryPort {
  getAllClientsPlans(): Observable<string[]>;
}

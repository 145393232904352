import { InjectionToken } from '@angular/core';

export interface NavigateToSelectedTeamHomeCommandPort {
  navigateHome(): void;
}

export const NAVIGATE_TO_SELECTED_TEAM_HOME_COMMAND =
  new InjectionToken<NavigateToSelectedTeamHomeCommandPort>(
    'NAVIGATE_TO_SELECTED_TEAM_HOME_COMMAND',
  );

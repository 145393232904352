import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[libKeyboardBlock]',
})
export class HuiKeyboardBlockDirective {
  private _whitelistedKeysSet = new Set();
  @Input() set keysWhitelist(keys: string[]) {
    this._whitelistedKeysSet = new Set(keys);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._whitelistedKeysSet.has(event.key)) {
      return;
    }
    event.stopPropagation();
  }
}

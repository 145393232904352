import { PAYMENT_STATUS } from './payment-status.enum';

export class CobiroPaymentConfirmation {
  get isSuccess() {
    return this.value === PAYMENT_STATUS.SUCCESS;
  }

  constructor(public value: PAYMENT_STATUS, public cobiroIntentId: string) {
    this.value = value;
    this.cobiroIntentId = cobiroIntentId;
  }
}

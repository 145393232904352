import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessedImage } from '../image/images.model';

@Injectable({ providedIn: 'root' })
export class ProcessMediaService {
  processImage(file: File): Observable<ProcessedImage> {
    this._validateFormat(file.type, /image-*/);

    return new Observable<ProcessedImage>(subscriber => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        subscriber.next(new ProcessedImage(e.target.result.toString()));
        subscriber.complete();
      };
      reader.readAsDataURL(file);
    });
  }

  processIcon(file: File): Observable<string> {
    this._validateFormat(file.type, /svg-*/);

    return new Observable<string>(subscriber => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        subscriber.next(e.target.result.toString());
        subscriber.complete();
      };
      reader.readAsText(file);
    });
  }

  private _validateFormat(fileType: string, pattern: RegExp): void {
    if (!fileType.match(pattern)) {
      throw new InvalidMediaFormatError();
    }
  }
}

export class InvalidMediaFormatError implements Error {
  message = 'Provided media format is invalid.';
  name = 'INVALID_MEDIA_FORMAT';
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HuiCardExpireDateDirective } from './hui-card-expire-date.directive';

@NgModule({
  declarations: [HuiCardExpireDateDirective],
  imports: [CommonModule],
  exports: [HuiCardExpireDateDirective],
})
export class HuiCardExpireDateModule {}

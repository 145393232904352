import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LeavesCompanyDetailsStepCommand } from './leaves-company-details-step.command';

export const LEAVES_COMPANY_DETAILS_STEP_COMMAND =
  new InjectionToken<LeavesCompanyDetailsStepCommandPort>('LEAVES_COMPANY_DETAILS_STEP_COMMAND');

export interface LeavesCompanyDetailsStepCommandPort {
  leaveCompanyDetailsStep(command: LeavesCompanyDetailsStepCommand): Observable<void>;
}

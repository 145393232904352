import { ClientDTO } from './client.dto';
import { InjectionToken } from '@angular/core';

export interface ClientAddedDispatcherPort {
  dispatch(clientDto: ClientDTO): void;
}

export const CLIENT_ADDED_DISPATCHER = new InjectionToken<ClientAddedDispatcherPort>(
  'CLIENT_ADDED_DISPATCHER',
);

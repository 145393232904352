import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordState } from '../../application/state/change-password.state';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lib-password-change-page',
  templateUrl: './password-change-page.component.html',
  host: {
    class: 'cs-height-inherit-xs',
  },
})
export class PasswordChangePageComponent {
  isLoading$: Observable<boolean> = this._state.isLoading$;

  public form = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required]),
    passwordConfirmation: new UntypedFormControl('', [Validators.required]),
  });
  public inputPasswordConfirmed = true;

  constructor(private _state: ChangePasswordState) {}

  get passwordsNotSame(): boolean {
    return (
      this.control('passwordConfirmation').touched &&
      this.control('passwordConfirmation').value !== this.control('password').value
    );
  }

  control(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  hasError(controlName: string): boolean {
    return this.form.get(controlName).invalid && this.form.get(controlName).touched;
  }

  onContinueButtonClicked(): void {
    const passwordControl = this.control('password');
    if (passwordControl.valid) {
      this._state.change(passwordControl.value, this.control('passwordConfirmation').value);
    }
  }
}

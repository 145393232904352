import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CurrentPlanQuery, UpgradeablePlanQuery } from '../../upgrade-plan-flow/upgrade-plan.query';
import { filter, map, pluck, take, takeUntil, tap } from 'rxjs/operators';
import {
  UPGRADE_PLAN_STEPS,
  UpgradePlanProcess,
} from '../../upgrade-plan-flow/upgrade-plan.process';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PaymentPlansState } from '../../../../core/application/state/payment-plans.state';
import { USER_PLAN } from '../../../../core/application/ports/primary/payment-plan-cards-mapper';
import {
  GETS_CURRENT_PLAN_QUERY_PORT,
  GetsCurrentPlanQueryPort,
} from '../../../../core/application/ports/primary/gets-current-plan.query-port';

@Component({
  selector: 'lib-plan-selection-step',
  templateUrl: './plan-selection-step.component.html',
  styleUrls: ['./plan-selection-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanSelectionStepComponent implements OnDestroy {
  private readonly _step = UPGRADE_PLAN_STEPS.PLAN_SELECTION;
  private readonly destroyed$ = new Subject<void>();

  currentPlan$: Observable<CurrentPlanQuery> = combineLatest([
    this._currentPlanQueryPort.get(),
    this._paymentPlansState.paymentPlans$,
  ]).pipe(
    map(([currentPlanQuery, paymentPlans]) => {
      const currentPlan = paymentPlans.find(
        paymentPlan => paymentPlan.id.split('-')[0] === currentPlanQuery.name,
      );
      return new CurrentPlanQuery(currentPlanQuery.name, currentPlan?.pricedPeriods);
    }),
  );

  upgradablePlans$: Observable<UpgradeablePlanQuery[]> = combineLatest([
    this._paymentPlansState.plansForFeature$,
    this.currentPlan$,
  ]).pipe(
    map(([paymentPlans, currentPlanQuery]) => {
      return paymentPlans
        .filter(
          paymentPlan => paymentPlan.pricedPeriods.monthly > currentPlanQuery.pricePerMonth.monthly,
        )
        .map(paymentPlan => {
          return UpgradeablePlanQuery.fromPaymentPlan(paymentPlan);
        });
    }),
  );

  currentlySelectedPeriod$ = this._paymentPlansState.selectedPlanPeriod$;

  planForm = new UntypedFormControl('', Validators.required);

  constructor(
    private _paymentPlansState: PaymentPlansState,
    @Inject(GETS_CURRENT_PLAN_QUERY_PORT) private _currentPlanQueryPort: GetsCurrentPlanQueryPort,
    private _process: UpgradePlanProcess,
  ) {
    this._paymentPlansState.selectedPlan$
      .pipe(
        filter(plan => !!plan),
        pluck('name'),
        takeUntil(this.destroyed$),
      )
      .subscribe(name => {
        this.planForm.patchValue(name);
      });

    this.handleFormChange();
    this.selectFirstAvailablePlan();
  }

  handlePeriodChange() {
    this.currentlySelectedPeriod$.pipe(take(1)).subscribe(plan => {
      this._paymentPlansState.changePlanPeriod(plan === 'monthly' ? 'yearly' : 'monthly');
    });
  }

  handlePlanNameChange(planName: USER_PLAN) {
    this._paymentPlansState.changePlanName(planName);
  }

  handleFormChange() {
    this.planForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        tap((planName: USER_PLAN) => this.handlePlanNameChange(planName)),
      )
      .subscribe();
  }

  selectFirstAvailablePlan() {
    this.upgradablePlans$
      .pipe(
        take(1),
        tap(plan => this.planForm.setValue(plan[0]?.name)),
      )
      .subscribe();
  }

  selectPlan() {
    this.currentlySelectedPeriod$.pipe(take(1)).subscribe(planPeriod => {
      this._process.nextStep(this._step, {
        planName: this.planForm.value,
        planPeriod: planPeriod,
      });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

<div class="cs-emoji-circle cs-emoji-circle-primary cs-mb-20">💻</div>
<h5 class="cs-title-7 cs-mb-20">{{ 'cobiro_pro_home_support_title' | language }}</h5>
<p class="cs-text-1 cs-mb-40">{{ 'cobiro_pro_home_support_text' | language }}</p>

<a
  data-selector="label-manager-support-button"
  class="cs-btn cs-btn-flat"
  href="https://support.cobiro.com/hc/en-us/requests/new"
  target="_blank"
>
  {{ '_get_support' | language }}
</a>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  CreatesNonCssIntegrationQueryPort,
  CREATES_NON_CSS_INTEGRATION_QUERY,
} from '../../../../../application/ports/primary/creates-non-css-integration.query-port';
import { Subject, firstValueFrom, take, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelManagerNonCssIntegrationStepperComponent } from './label-manager-non-css-integration-stepper/label-manager-non-css-integration-stepper.component';
import {
  INITS_NON_CSS_INTEGRATION_COMMAND,
  InitsNonCssIntegrationCommandPort,
} from 'libs/cobiro-pro-rewrite/label-manager/src/lib/application/ports/primary/inits-non-css-integration.command-port';
import { InitsNonCssIntegrationCommand } from 'libs/cobiro-pro-rewrite/label-manager/src/lib/application/ports/primary/inits-non-css-integration.command';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-label-manager-non-css-integration',
  templateUrl: './label-manager-non-css-integration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerNonCssIntegrationComponent implements OnDestroy, AfterViewInit {
  private readonly _destroyed$ = new Subject<void>();

  @ViewChild('stepper')
  stepper!: LabelManagerNonCssIntegrationStepperComponent;

  constructor(
    private _matDialogRef: MatDialogRef<LabelManagerNonCssIntegrationComponent>,
    @Inject(INITS_NON_CSS_INTEGRATION_COMMAND)
    private readonly _initsNonCssIntegrationCommand: InitsNonCssIntegrationCommandPort,
    @Inject(MAT_DIALOG_DATA) public data: { clientId: string },
  ) {}

  async ngAfterViewInit(): Promise<void> {
    await firstValueFrom(
      this._initsNonCssIntegrationCommand.initNonCssIntegration(
        new InitsNonCssIntegrationCommand(this.stepper, this.data.clientId),
      ),
    );
  }

  onCloseModalClicked(): void {
    this._matDialogRef.close();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}

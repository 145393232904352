import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryQuery } from './country.query';

export const GETS_FILTERED_COUNTRIES_QUERY = new InjectionToken<GetsFilteredCountriesQueryPort>(
  'GETS_FILTERED_COUNTRIES_QUERY',
);

export interface GetsFilteredCountriesQueryPort {
  getFilteredCountries(): Observable<CountryQuery[]>;
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  CREATE_NEW_SUBSCRIPTION_PRE_HOOK,
  CreateNewSubscriptionPreHook,
  CREATES_NEW_SUBSCRIPTION_DTO_PORT,
  GETS_CARDS,
  GETS_CREATE_NEW_SUBSCRIPTION_UPDATE_ERROR_QUERY_PORT,
  PaymentPlanPurchaseState,
  UPDATES_PLAN_COMMAND_PORT,
  UPDATES_PLAN_DTO_PORT,
  UpdatesPlanDtoPort,
  UpgradePlanProcess,
} from '@app.cobiro.com/payment-plans';
import { HttpGetsPaymentSourcesService } from '../../../secondary/infrastructure/http-gets-payment-sources.service';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
} from '@app.cobiro.com/cobiro-pro/context';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BILLING_DATA_STORAGE } from '../../../secondary/storage/billing-data.storage';
import { InMemoryReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { PaymentPlansTeamBillingState } from '../../../../application/state/payment-plans-team-billing/payment-plans-team-billing.state';
import {
  GETS_TEAM_BILLING_QUERY_PORT,
  GetsTeamBillingQueryPort,
} from '../../../../application/ports/primary/gets-team-billing.query-port';
import {
  LOADS_TEAM_BILLING_COMMAND_PORT,
  LoadsTeamBillingCommandPort,
} from '../../../../application/ports/primary/loads-team-billing.command-port';
import {
  HAS_PAYMENT_METHODS_QUERY_PORT,
  HasPaymentMethodsQueryPort,
} from '../../../../application/ports/primary/has-payment-methods.query-port';
import { TeamBillingQuery } from '../../../../application/ports/primary/team-billing.query';
import {
  GETS_PAYMENT_FLOW_PROCESSING_QUERY_PORT,
  GetsPaymentFlowProcessingQueryPort,
} from '../../../../application/ports/primary/gets-payment-flow-processing.query-port';
import { HttpManagesPlanService } from '../../../secondary/infrastructure/http-manages-plan.service';
import { PaymentUpdatePlanPurchaseState } from '../../../../application/state/payment-update-plan-purchase/payment-update-plan-purchase.state';

@Component({
  selector: 'lib-upgrade-plan-preset-cards-flow',
  templateUrl: './upgrade-plan-preset-cards-flow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PaymentPlanPurchaseState,
    PaymentUpdatePlanPurchaseState,
    UpgradePlanProcess,
    PaymentPlansTeamBillingState,
    { provide: CREATES_NEW_SUBSCRIPTION_DTO_PORT, useClass: HttpManagesPlanService },
    {
      provide: GETS_CARDS,
      useClass: HttpGetsPaymentSourcesService,
    },
    {
      provide: GETS_CREATE_NEW_SUBSCRIPTION_UPDATE_ERROR_QUERY_PORT,
      useExisting: PaymentPlanPurchaseState,
    },
    { provide: UPDATES_PLAN_DTO_PORT, useValue: { update: () => of(0) } as UpdatesPlanDtoPort },
    {
      provide: BILLING_DATA_STORAGE,
      useClass: InMemoryReactiveSingleValueStorage,
    },
    {
      provide: CREATE_NEW_SUBSCRIPTION_PRE_HOOK,
      useValue: {
        // eslint-disable-next-line no-empty-pattern
        execute({}) {
          return of(true);
        },
      } as CreateNewSubscriptionPreHook,
    },
    {
      provide: GETS_TEAM_BILLING_QUERY_PORT,
      useExisting: PaymentPlansTeamBillingState,
    },
    {
      provide: LOADS_TEAM_BILLING_COMMAND_PORT,
      useExisting: PaymentPlansTeamBillingState,
    },
    {
      provide: HAS_PAYMENT_METHODS_QUERY_PORT,
      useExisting: PaymentPlansTeamBillingState,
    },
    {
      provide: GETS_PAYMENT_FLOW_PROCESSING_QUERY_PORT,
      useExisting: PaymentPlansTeamBillingState,
    },
    {
      provide: UPDATES_PLAN_COMMAND_PORT,
      useExisting: PaymentUpdatePlanPurchaseState,
    },
  ],
})
export class UpgradePlanPresetCardsFlowComponent implements OnInit, OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  private readonly _billingData$: Observable<TeamBillingQuery> =
    this._getsTeamBillingQueryPort.getTeamBilling();
  private readonly _hasPaymentMethods$: Observable<boolean> =
    this._hasPaymentMethodsQueryPort.hasPaymentMethods();
  private readonly _teamId$: Observable<string> = this._cobiroProContextQuery
    .getContext()
    .pipe(map(context => context?.selectedTeamId));
  readonly processing$ = this._getsPaymentFlowProcessingQueryPort.getPaymentFlowProcessing();
  readonly canProcessWithPayment$ = combineLatest([
    this._billingData$,
    this._hasPaymentMethods$,
  ]).pipe(
    map(([billingData, hasPaymentMethods]) => !!billingData.countryCode && hasPaymentMethods),
  );

  readonly settingsRedirectUrl$: Observable<string[]> = combineLatest([
    this._teamId$,
    this._billingData$,
  ]).pipe(
    filter(([teamId, billingData]) => !!(teamId && billingData)),
    map(([teamId, billingData]) =>
      billingData.countryCode
        ? this._getPaymentMethodsRedirect(teamId)
        : this._getTeamBillingRedirect(teamId),
    ),
  );

  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _cobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(GETS_TEAM_BILLING_QUERY_PORT)
    private readonly _getsTeamBillingQueryPort: GetsTeamBillingQueryPort,
    @Inject(LOADS_TEAM_BILLING_COMMAND_PORT)
    private readonly _loadsTeamBillingCommandPort: LoadsTeamBillingCommandPort,
    @Inject(HAS_PAYMENT_METHODS_QUERY_PORT)
    private readonly _hasPaymentMethodsQueryPort: HasPaymentMethodsQueryPort,
    @Inject(GETS_PAYMENT_FLOW_PROCESSING_QUERY_PORT)
    private readonly _getsPaymentFlowProcessingQueryPort: GetsPaymentFlowProcessingQueryPort,
    private readonly _upgradePlanProcessState: UpgradePlanProcess,
  ) {}

  ngOnInit(): void {
    this._loadsTeamBillingCommandPort.loadTeamBilling();
    combineLatest([this._upgradePlanProcessState.hasPlanSelected$, this.canProcessWithPayment$])
      .pipe(
        filter(([planSelected, canProcessWithPayment]) => planSelected && canProcessWithPayment),
        withLatestFrom(this._billingData$),
        tap(([_, billingData]) => {
          this._upgradePlanProcessState.estimate({
            countryCode: billingData.countryCode,
            vatNumber: billingData.vatNumber,
          });
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _getPaymentMethodsRedirect(teamId: string): string[] {
    return ['/pro', teamId, 'settings', 'payment-methods'];
  }
  private _getTeamBillingRedirect(teamId: string): string[] {
    return ['/pro', teamId, 'settings'];
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SwitchesIntegrationDto } from './switches-integration.dto';

export const SWITCHES_INTEGRATION_DTO = new InjectionToken<SwitchesIntegrationDtoPort>(
  'SWITCHES_INTEGRATION_DTO',
);

export interface SwitchesIntegrationDtoPort {
  switchAccount(dto: SwitchesIntegrationDto): Observable<void>;
}

export const ROLE_LABEL_WITH_COLOR_MAP = {
  owner: {
    label: 'cobiro_pro_role_owner',
    colorClass: 'cs-badge-warning',
  },
  admin: {
    label: 'cobiro_pro_role_admin',
    colorClass: 'cs-badge-success',
  },
  basic_user: {
    label: 'cobiro_pro_role_basic_user',
    colorClass: 'cs-badge-primary',
  },
};

export class UserRoleQuery {
  private constructor(
    public readonly role: string,
    public readonly label: string,
    public readonly colorClass: string,
  ) {}

  static fromDTO(role: 'owner' | 'admin' | 'basic_user'): UserRoleQuery {
    const { label, colorClass } = ROLE_LABEL_WITH_COLOR_MAP[role];
    return new UserRoleQuery(role, label, colorClass);
  }
}

import { Injectable } from '@angular/core';
import { LocalStorage, LocalStorageInterface } from '@app.cobiro.com/core/storage';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth } from '../application/user.state';

export interface AuthData {
  access_token: string;
  refresh_token: string;
  token_type: string;
  refreshing: boolean;
}

const mapDataToAuth = (data: AuthData): Auth => {
  if (!data) return null;

  return {
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    tokenType: data.token_type,
    refreshing: data.refreshing,
  };
};

@Injectable({
  providedIn: 'root',
})
export class AuthStorage {
  private authStorage: LocalStorageInterface;
  private authStorageSubject = new BehaviorSubject<AuthData>(null);

  constructor(private localStorage: LocalStorage) {
    this.authStorage = this.localStorage.init('auth');
    const authData = this.authStorage?.get();
    if (authData) {
      this.authStorageSubject.next(authData);
    }
  }

  getAuth(): Observable<Auth> {
    return of(this.authStorage.get()).pipe(map(mapDataToAuth));
  }

  setAuth(auth: Auth, refreshing: boolean = false) {
    const authData: AuthData = {
      access_token: auth.accessToken,
      refresh_token: auth.refreshToken,
      token_type: auth.tokenType,
      refreshing: auth.refreshing || refreshing,
    };
    this.authStorage.set(authData);
    this.authStorageSubject.next(authData);
  }

  updateToken(token: string, refreshToken?: string) {
    const data = this.authStorage.get();
    const authData: AuthData = {
      token_type: 'Bearer',
      access_token: token,
      refresh_token: refreshToken || data?.refresh_token,
      refreshing: false,
    };
    this.authStorage.set(authData);
    this.authStorageSubject.next(authData);
  }
}

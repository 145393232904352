import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMetadataQuery } from './app-metadata.query';

export const GETS_LABEL_MANAGER_META_QUERY_PORT = new InjectionToken<GetsLabelManagerMetaQueryPort>(
  'GETS_LABEL_MANAGER_META_QUERY_PORT',
);

export interface GetsLabelManagerMetaQueryPort {
  getAppMeta(): Observable<AppMetadataQuery>;
}

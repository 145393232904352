import { Observable } from 'rxjs';
import { PaymentSourceCreatedDto } from './payment-source-created.dto';
import { InjectionToken } from '@angular/core';
import { ReplacesPaymentSourceDto } from './replaces-payment-source.dto';

export interface ReplacesPaymentSourceDtoPort {
  replacePaymentSource(dto: ReplacesPaymentSourceDto): Observable<PaymentSourceCreatedDto>;
}

export const REPLACES_PAYMENT_SOURCE_DTO_PORT = new InjectionToken<ReplacesPaymentSourceDtoPort>(
  'REPLACES_PAYMENT_SOURCE_DTO_PORT',
);

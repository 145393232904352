import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const LINKS_INTEGRATION_DTO = new InjectionToken<LinksIntegrationDtoPort>(
  'LINKS_INTEGRATION_DTO',
);

export interface LinksIntegrationDtoPort {
  linkIntegration(siteId: string): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsInstalledAppsQueryPort {
  getInstalledApps(): Observable<string[]>;
}

export const GETS_INSTALLED_APPS_QUERY_PORT = new InjectionToken<GetsInstalledAppsQueryPort>(
  'GETS_INSTALLED_APPS_QUERY_PORT',
);

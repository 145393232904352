<div
  class="d-flex flex-column align-items-center text-left cs-p-40 pl-3 pr-3 cs-pb-20 cs-bg-shade-0 cs-shadow-shade-40 cs-b-1 cs-b-shade-10 br-5 position-relative"
  data-selector="cancel-subscription-wrapper"
>
  <h2 class="cs-title-4 cs-mb-10">
    {{ 'shop_cancel_subscription_popup_title_' + data.productName | language }}
  </h2>

  <p *ngIf="data.productName === 'css'" class="cs-text-2 cs-mt-20">
    {{ 'shop_cancel_subscription_popup_title_desc' | language }}
  </p>

  <section class="cs-mv-30 w-100">
    <div class="cs-control-wrapper">
      <label class="cs-label">{{
        'shop_cancel_subscription_popup_cancellation_date' | language
      }}</label>
      <select
        (change)="onCancelAtEndOfTermChanged($event.target.value)"
        class="cs-input cs-select-arrow"
      >
        <option [value]="1">
          {{ 'site_cancel_subscription_popup_at_the_end_of_term_option' | language }}
        </option>
        <option [value]="0">
          {{ 'site_cancel_subscription_popup_immediately_option' | language }}
        </option>
      </select>
    </div>
  </section>

  <section class="cs-mb-30 w-100">
    <div class="cs-control-wrapper">
      <label class="cs-label">{{
        'site_cancel_subscription_popup_select_reason_title' | language
      }}</label>
      <select
        [value]="selectedCancelReason"
        (change)="onCancelReasonSelect($event.target.value)"
        class="cs-input cs-select-arrow"
      >
        <option disabled selected value="">-</option>
        <option
          *ngFor="let reason of cancelReasons$ | async; let index = i"
          [value]="reason.label"
          [attr.data-selector]="'cancel-subscription-reason-' + index"
        >
          {{ reason.label | language }}
        </option>
      </select>
    </div>
  </section>

  <section class="cs-mb-30 w-100" *ngIf="isCommentAreaShown">
    <div class="cs-control-wrapper">
      <label class="cs-label">{{ 'shop_cancel_subscription_popup_comment' | language }}</label>
      <p class="cs-text-2 cs-mb-10">
        {{ 'shop_cancel_subscription_popup_comment_desc' | language }}
      </p>
      <textarea
        maxlength="1000"
        [value]="comment"
        (input)="comment = $event.target.value"
        class="cs-textarea cs-min-height-120-xs"
      ></textarea>
    </div>
  </section>

  <button
    type="submit"
    class="cs-btn cs-btn-error w-100"
    data-selector="cancel-subscription-submit-button"
    (click)="onCancelSubscription()"
    [disabled]="!selectedCancelReason"
  >
    {{ 'site_cancel_subscription_popup_submit_button' | language }}
  </button>
</div>

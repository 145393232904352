import { SORT_ORDER } from './sort-order.enum';
import { NonNegativeVO } from './non-negative.vo';
import { SortVO } from './sort.vo';
import { FilterVO } from './filter.vo';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationQuery } from '../application/ports/primary/pagination.query';
import { RawFilterValue } from '../application/ports/pagination.dto';

export class FrontendPagination<T> {
  private _paginationQuery$ = new ReplaySubject<PaginationQuery>(1);
  private readonly _defaultSorting: SortVO;
  public readonly total: Observable<number>;
  constructor(
    private readonly _list$: Observable<T[]>,
    private _pageIndex: NonNegativeVO,
    private _pageSize: NonNegativeVO,
    private _sort: SortVO,
    private _filters?: Map<string, FilterVO>,
    private _search?: string,
    private readonly _searchProperty?: keyof T,
  ) {
    this._paginationUpdated();
    this._defaultSorting = _sort;
    this.total = this._getProcessedList().pipe(map(list => list.length));
  }

  static fromRaw<T>(
    list: Observable<T[]>,
    index: number,
    pageSize: number,
    sort: [string, SORT_ORDER],
    filter: { [key: string]: any },
    search?: string,
    searchProperty?: keyof T,
  ): FrontendPagination<T> {
    return new FrontendPagination<T>(
      list,
      new NonNegativeVO(index),
      new NonNegativeVO(pageSize),
      new SortVO(sort),
      new Map(Object.keys(filter).map(k => [k, new FilterVO(filter[k])])),
      search,
      searchProperty,
    );
  }

  getPaginatedList(): Observable<T[]> {
    return this._getProcessedList().pipe(
      map(list => {
        return this._extractSelectedPageElements(list);
      }),
    );
  }

  getPaginationQuery(): Observable<PaginationQuery> {
    return this._paginationQuery$.asObservable();
  }

  getAmountOfPages(): Observable<number> {
    return this._getProcessedList().pipe(
      map(list => {
        return Math.ceil(list.length / this._pageSize.valueOf());
      }),
    );
  }

  setPage(index: number): void {
    this._pageIndex = new NonNegativeVO(index);
    this._paginationUpdated();
  }

  setSorting(propertyName: keyof T, sortOrder: SORT_ORDER): void {
    this._sort = new SortVO([propertyName as string, sortOrder]);
    this._paginationUpdated();
  }

  setFilter(propertyName: keyof T, propertyValue: string | string[] | null): void {
    if (this._filters.has(propertyName as string) && propertyValue === null) {
      this._filters.delete(propertyName as string);
      this._paginationUpdated();
      return;
    }
    this._filters.set(propertyName as string, new FilterVO(propertyValue));
    this._paginationUpdated();
  }

  setSearch(searchedPhrase: string): void {
    this._search = searchedPhrase;
    this._paginationUpdated();
  }

  reset(): void {
    this._search = '';
    this._pageIndex = new NonNegativeVO(0);
    this._filters = new Map<string, FilterVO>();
    this._sort = this._defaultSorting;
    this._paginationUpdated();
  }

  private _getProcessedList(): Observable<T[]> {
    return combineLatest([this._list$, this._paginationQuery$]).pipe(
      map(([list]) => list),
      map(list => this._filterList(list)),
      map(list => this._sortList(list)),
      map(list => this._searchPhrase(list)),
    );
  }

  private _filterList(list: T[]): T[] {
    return list.filter(element => {
      return Array.from(this._filters.keys()).reduce((previousValue, propertyName) => {
        return String(element[propertyName]) === this._filters.get(propertyName).valueOf();
      }, true);
    });
  }

  private _sortList(list: T[]): T[] {
    const sort = this._sort.value();
    return list.sort((a, b) => {
      const endOfAlphabet = (sort[1] === SORT_ORDER.ASC ? a : b)[sort[0]].toUpperCase();
      const beginningOfAlphabet = (sort[1] === SORT_ORDER.ASC ? b : a)[sort[0]].toUpperCase();

      if (endOfAlphabet < beginningOfAlphabet) {
        return -1;
      }
      if (endOfAlphabet > beginningOfAlphabet) {
        return 1;
      }
      return 0;
    });
  }

  private _extractSelectedPageElements(list: T[]): T[] {
    const startingIndex = this._pageIndex.valueOf() * this._pageSize.valueOf();
    const endIndex =
      startingIndex + this._pageSize.valueOf() > list.length
        ? list.length
        : startingIndex + this._pageSize.valueOf();

    return list.slice(startingIndex, endIndex);
  }

  private _searchPhrase(list: T[]): T[] {
    if (!this._search) {
      return list;
    }
    if (this._searchProperty) {
      return list.filter(listElement =>
        String(listElement[this._searchProperty])
          .toLowerCase()
          .includes(this._search.toLowerCase()),
      );
    }
    return list.filter(listElement => {
      for (const key in listElement) {
        if (String(listElement[key]).toLowerCase().includes(this._search.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

  private _paginationUpdated() {
    const filters: { [key: string]: RawFilterValue } = {};
    Array.from(this._filters.entries()).forEach(([key, value]) => {
      filters[key] = value.valueOf();
    });
    const query = new PaginationQuery(
      this._pageIndex.valueOf(),
      this._pageSize.valueOf(),
      this._sort.value(),
      filters,
      this._search,
    );
    this._paginationQuery$.next(query);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupModalComponent } from './setup-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChooseProductComponentModule } from '../choose-product/choose-product.component-module';

@NgModule({
  declarations: [SetupModalComponent],
  imports: [CommonModule, MatDialogModule, ChooseProductComponentModule],
  exports: [SetupModalComponent],
})
export class SetupModalComponentModule {}

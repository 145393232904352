import { Inject, Injectable } from '@angular/core';
import {
  AppDTO,
  GETS_APPS_DTO_PORT,
  GetsApplicationsDtoPort,
} from './port/secondary/gets-applications-dto.port';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { GetsAvailableAppsQueryPort } from './port/primary/gets-available-apps.query-port';
import { concatMap, filter, map, switchMap, take, toArray } from 'rxjs/operators';
import { AvailableAppQuery } from './port/primary/available-app.query';
import { APP_FF_KEYS_CONFIG } from './app-feature-flag-keys.config';

@Injectable()
export class AppRegistryState implements GetsAvailableAppsQueryPort {
  private _appAvailability = new BehaviorSubject(new Map<string, boolean>());

  constructor(
    @Inject(GETS_APPS_DTO_PORT) private _getsAppDtoPort: GetsApplicationsDtoPort,
    @Inject(APP_FF_KEYS_CONFIG) private _featureKeyConfigMap: Map<string, string>,
    private _featureFlagState: FeatureFlagsState,
  ) {
    this._init();
  }

  private _init(): void {
    this._getsAppDtoPort
      .getAll()
      .pipe(
        switchMap((apps: AppDTO[]) =>
          from(apps).pipe(
            concatMap((app: AppDTO) =>
              this._featureKeyConfigMap.has(app.identifier)
                ? this._checkAppAvailability(app)
                : of({
                    id: app.identifier,
                    available: true,
                  }),
            ),
            toArray(),
          ),
        ),
      )
      .subscribe((results: { id: string; available: boolean }[]) => {
        this._appAvailability.next(new Map(results.map(app => [app.id, app.available])));
      });
  }

  private _checkAppAvailability(app: AppDTO): Observable<{ id: string; available: boolean }> {
    return this._featureFlagState
      .hasFlags([this._featureKeyConfigMap.get(app.identifier) as string])
      .pipe(
        take(1),
        map(result => ({
          id: app.identifier,
          available: result,
        })),
      );
  }

  getAllAvailableApps(): Observable<AvailableAppQuery[]> {
    return this._appAvailability.pipe(
      filter(dic => dic.size > 0),
      take(1),
      map(dic => Array.from(dic.entries())),
      map(entries => entries.map(([id, status]) => new AvailableAppQuery(id, status))),
    );
  }

  isAppAvailable(id: string): Observable<boolean> {
    return this._appAvailability.pipe(
      filter(dic => dic.size > 0),
      take(1),
      map(dic => !!dic.get(id)),
    );
  }
}

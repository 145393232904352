import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GETS_INTEGRATION_QUERY,
  GetsIntegrationQueryPort,
} from '../../../../application/ports/primary/gets-integration.query-port';

@Component({
  selector: 'lib-switch-in-progress',
  templateUrl: './switch-in-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./switch-in-progress.component.scss'],
})
export class SwitchInProgressComponent {
  readonly integrationQuery$ = this._getsIntegrationQueryPort.getIntegrationQuery();

  constructor(
    @Inject(GETS_INTEGRATION_QUERY)
    private _getsIntegrationQueryPort: GetsIntegrationQueryPort,
  ) {}
}

import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { GETS_FEATURE_FLAGS, GetsFeatureFlags } from './gets-feature.flags';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsState {
  private featureFlags: ReplaySubject<Set<string>> = new ReplaySubject(1);

  constructor(@Inject(GETS_FEATURE_FLAGS) private getsFeatureFlags: GetsFeatureFlags) {}

  init(userId: string, userEmail: string): Observable<boolean> {
    const userContext = {
      id: userId,
      email: userEmail,
    };
    return this.getsFeatureFlags.getAll(userContext).pipe(
      take(1),
      tap((featureFlags: Set<string>) => {
        this.featureFlags.next(featureFlags);
      }),
      map(() => true),
    );
  }

  /**
   * @param flags
   * @param strict - to ensure all feature flags are turned on
   */
  public hasFlags(flags: string[], strict = false): Observable<boolean> {
    if (strict) {
      return this.featureFlags.asObservable().pipe(
        map((featureFlags: Set<string>) => {
          const uniqueFeatureFlags = [...new Set(flags)];
          return uniqueFeatureFlags.every(flag => featureFlags.has(flag) !== undefined);
        }),
      );
    }

    return this.featureFlags
      .asObservable()
      .pipe(
        map(
          (featureFlags: Set<string>) =>
            [...new Set(flags)].find(flag => !featureFlags.has(flag)) === undefined,
        ),
      );
  }
}

import { NgModule } from '@angular/core';
import { CHANGES_PASSWORD_DTO } from '../../../../application/ports/secondary/changes-password.dto-port';
import { HttpChangesPasswordService } from './http-changes-password.service';

@NgModule({
  providers: [
    HttpChangesPasswordService,
    {
      provide: CHANGES_PASSWORD_DTO,
      useClass: HttpChangesPasswordService,
    },
  ],
})
export class HttpChangesPasswordServiceModule {}

import { NgModule } from '@angular/core';
import { CHECKS_DISCOUNT_CODE_DTO } from '../../../../application/ports/secondary/dto/payments/checks-discount-code.dto-port';
import { HttpChecksDiscountCodeService } from './http-checks-discount-code.service';

@NgModule({
  providers: [
    HttpChecksDiscountCodeService,
    { provide: CHECKS_DISCOUNT_CODE_DTO, useExisting: HttpChecksDiscountCodeService },
  ],
})
export class HttpChecksDiscountCodeServiceModule {}

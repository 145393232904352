import { InjectionToken } from '@angular/core';

export interface OpensNoTeamsBillingMessageCommandPort {
  open(): void;
}

export const OPENS_NO_TEAMS_BILLING_MESSAGE_COMMAND_PORT =
  new InjectionToken<OpensNoTeamsBillingMessageCommandPort>(
    'OPENS_NO_TEAMS_BILLING_INFO_COMMAND_PORT',
  );

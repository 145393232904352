import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeletesTeamUserCommand } from './deletes-team-user.command';

export const DELETE_TEAM_USER_COMMAND = new InjectionToken<DeletesTeamUserCommandPort>(
  'DELETE_TEAM_USER_COMMAND',
);

export interface DeletesTeamUserCommandPort {
  deleteTeamUser(command: DeletesTeamUserCommand): Observable<void>;
}

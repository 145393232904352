import { ApplicationEvent } from '@cobiro/eda';

export class CobiroProWorkspaceSwitchedEvent extends ApplicationEvent {
  constructor(
    readonly workspaceId: string,
    readonly workspaceName: string,
    readonly avatar: string,
    readonly membersCount: number,
    readonly clientCount: number,
  ) {
    super();
  }
}

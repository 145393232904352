import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsCompanyDetailsDtoPort } from '../../../../application/ports/secondary/gets-company-details.dto-port';
import { SavesCompanyDetailsDtoPort } from '../../../../application/ports/secondary/saves-company-details.dto-port';
import { CompanyDetailsDto } from '../../../../application/ports/secondary/company-details.dto';
import { DeletesCompanyDtoPort } from '../../../../application/ports/secondary/deletes-company.dto-port';

interface CompanyDetailsAttributes {
  name: string;
  role?: string;
  details: Omit<CompanyDetailsDto, 'id' | 'companyName'>;
}

@Injectable()
export class HttpCompanyDetailsService
  implements GetsCompanyDetailsDtoPort, SavesCompanyDetailsDtoPort, DeletesCompanyDtoPort
{
  constructor(private readonly _gateway: GatewayClient) {}

  deleteCompany(id: string): Observable<boolean> {
    return this._gateway.delete(`v1/sites/teams/${id}`).pipe(map(() => true));
  }

  getCompanyDetails(id: string): Observable<CompanyDetailsDto> {
    return this._gateway.get<HasData<CompanyDetailsAttributes>>(`v1/sites/teams/${id}`).pipe(
      map(response => ({
        id: response.data.id,
        companyName: response.data.attributes.name,
        companyType: response.data.attributes.details.companyType,
        numberOfEmployees: response.data.attributes.details.numberOfEmployees,
        numberOfClients: response.data.attributes.details.numberOfClients,
      })),
    );
  }
  savesCompanyDetail(dto: CompanyDetailsDto): Observable<void> {
    const body: HasData<CompanyDetailsAttributes> = {
      data: {
        id: dto.id,
        type: 'teams',
        attributes: {
          name: dto.companyName,
          details: {
            companyType: dto.companyType,
            numberOfEmployees: dto.numberOfEmployees,
            numberOfClients: dto.numberOfClients,
          },
        },
      },
    };

    return this._gateway.patch<void>(`v2/sites/teams/${dto.id}`, body);
  }
}

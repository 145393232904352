import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationStatusQuery } from './integration-status.query';

export const GETS_INTEGRATION_STATUS_QUERY = new InjectionToken<GetsIntegrationStatusQueryPort>(
  'GETS_INTEGRATION_STATUS_QUERY',
);

export interface GetsIntegrationStatusQueryPort {
  getIntegrationStatus(): Observable<IntegrationStatusQuery>;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { UserImpersonationModule } from '@app.cobiro.com/user/impersonation';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { AttentionMessageWidgetModule } from './attention-message-widget/attention-message-widget.component-module';
import { LayoutComponent } from './layout.component';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    LanguageModule,
    RouterModule,
    SharedAnalyticsModule,
    FeatureFlagsModule,
    UserImpersonationModule,
    AttentionMessageWidgetModule,
    HuiLoadingModule,
    MatIconModule,
  ],
  exports: [LayoutComponent],
})
export class SharedLayoutModule {}

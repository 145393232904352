import { Inject, Injectable } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { ENV_CONFIG, GetsConfig, WINDOW } from '@app.cobiro.com/core/utils';
import { get } from 'lodash';
import { PhraseConfigEnv } from '../../../../../../../environments/environment.config';
import { GetsLanguage } from '../../application/port/gets-language';
import { LANGUAGES } from '../../application/port/languages';
import { UpdatesDictionary } from '../../application/port/updates-dictionary';

@Injectable()
export class LanguageService implements GetsLanguage, UpdatesDictionary {
  current: string;
  dictionary: Record<string, string>;

  private defaultDictionary: Record<string, string>;
  private defaultLanguage: string;
  private placeholder: string;
  private supportedLanguages: any[];
  private contextEditorEnabled: boolean;

  constructor(
    @Inject(STORAGE) private readonly _storage: Storage,
    @Inject(LANGUAGES) private readonly _languages,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
    @Inject(WINDOW) private readonly _window: Window,
  ) {
    if (this._getsConfig.get<PhraseConfigEnv>('phrase').contextEditor.available) {
      this.contextEditorEnabled = !!this._window.sessionStorage.getItem('phrase');
    }

    if (_languages) {
      this.updateLanguageSettings(this._languages);
    }
    this.placeholder = '%';
  }

  /**
   * Gets the translation for a given key
   * @param key Key to be searched in the dictionary
   * @param words Eventual variables to be substituted into the string
   */
  get(key, words?: string | number | (number | string)[]): string {
    if (this.contextEditorEnabled) {
      return `{{__phrase_${key}__}}`;
    } else {
      const value = this.getValue(key);
      return words !== undefined && words !== null ? this.replaceVariables(value, words) : value;
    }
  }

  /**
   * Returns dictionary based on the one that is being passed
   * @param language  Language code e.g. 'en'
   * @returns Dictionary for the selected language
   */
  getDictionary(language: string): Record<string, string> {
    if (this.supports(language)) {
      return this._concatDictionaries(language);
    }
    return this._concatDictionaries('en');
  }

  private _concatDictionaries(language: string) {
    return this.supportedLanguages
      .filter(l => l.code === language)
      .map(d => d.dictionary)
      .reduce((acc, next) => ({ ...acc, ...next }), {});
  }

  /**
   * Reads the stored language code if the cookie exists (and checks if it's supported),
   * otherwise sets the cookie to default language and returns it
   * @returns Language code stored in cookies, or the default if none is set
   */
  getStoredLanguage(): string {
    let language = this._storage.getItem('cbr_language');
    if (!language || !this.supports(language)) {
      language = this.defaultLanguage;
      this._storage.setItem('cbr_language', language);
    }
    return language;
  }

  /**
   * Finds value assigned to key in current dictionary, if not found then returns the english version
   * @param key key to be searched in the dictionary
   * @returns Value associated with key/default dictionary key/key
   */
  getValue(key: string): string {
    if (get(this.dictionary, key)) {
      return get(this.dictionary, key).toString();
    } else if (get(this.defaultDictionary, key)) {
      return get(this.defaultDictionary, key).toString();
    }
    return key;
  }

  /**
   * Language checks if language code is included in list of supported language
   * @param language  Language code e.g. 'en'
   * @returns If language is supported
   */
  supports(language: string): boolean {
    const supported = this.supportedLanguages.find(l => l.code === language);
    return !!supported;
  }

  /**
   * Replaces words into sentences when piped in as variables.
   * E.g. {{ "Hello %0" | language: world }} will substitute %0 with 'world'
   * @param sentence Base sentence that has been extracted from the dictionary
   * @param words Words that need to be inserted in the sentence
   */
  replaceVariables(sentence: string, words: string | number | (number | string)[] = ''): string {
    let base: string = sentence;
    const values: string[] = [].concat(words);
    values.forEach((e, i) => {
      const regexp = new RegExp(this.placeholder.concat(String(i)), 'g');
      base = base.replace(regexp, e === undefined ? '' : e);
    });
    return base;
  }

  /**
   * Updates current language and dictionary to the one that is being passed
   * @param language Language code e.g. 'en'
   */
  set(language: string) {
    if (this.supports(language)) {
      this.current = language;
      this._storage.setItem('cbr_language', this.current);
      this.dictionary = this.getDictionary(this.current);
    }
  }

  /**
   * Updates the languages supported, default language, and current language
   * @param languages: Keycode of the new language
   */
  updateLanguageSettings(languages: { code: string; dictionary: any; default: boolean }[]) {
    this.supportedLanguages = languages;
    const defaultLanguage = languages.find(language => language.default);
    this.set(defaultLanguage.code);
  }

  loadExtraDictionary(
    languages: { code: string; dictionary: Record<string, string>; default: boolean }[],
  ): void {
    this.updateLanguageSettings([...this.supportedLanguages, ...languages]);
  }
}

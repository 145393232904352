import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { BASE_URL } from '@app.cobiro.com/core/utils';
import { ChangesPassword } from '../domain/changes-password';
import { HasData } from '@cobiro/jsonapi';

export interface ChangePasswordAttributes {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

@Injectable()
export class HttpChangesPasswordService implements ChangesPassword {
  private _headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(private _client: HttpClient, @Inject(BASE_URL) private _baseUrl: string) {}

  change(
    email: string,
    password: string,
    passwordConfirmation: string,
    token: string,
  ): Observable<boolean> {
    const body: HasData<ChangePasswordAttributes> = {
      data: {
        type: 'password-reset-change',
        attributes: {
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
          token: token,
        },
      },
    };

    return this._client
      .post(this._baseUrl + '/v1/password-reset-change', body, {
        headers: this._headers,
      })
      .pipe(map(() => true));
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientSiteDTO } from './client-site.dto';

export const ADDS_CLIENT_SITE_DTO = new InjectionToken<AddsClientSiteDtoPort>(
  'ADDS_CLIENT_SITE_DTO',
);

export interface AddsClientSiteDtoPort {
  add(item: ClientSiteDTO): Observable<string>;
}

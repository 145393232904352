import { InjectionToken } from '@angular/core';
import { ClientDTO } from '../../secondary/dto/clients/client.dto';

export const SETS_CURRENT_CLIENT_QUERY = new InjectionToken<SetsCurrentClientQueryPort>(
  'SETS_CURRENT_CLIENT_QUERY',
);

export interface SetsCurrentClientQueryPort {
  setCurrentClient(client: ClientDTO): void;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_GOOGLE_ADS_LINKING_STATUS_QUERY_PORT =
  new InjectionToken<GetsGoogleAdsLinkingStatusQueryPort>(
    'GETS_GOOGLE_ADS_LINKING_STATUS_QUERY_PORT',
  );

export interface GetsGoogleAdsLinkingStatusQueryPort {
  getStatus(): Observable<boolean>;
}

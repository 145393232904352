import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { WorkspaceQuery } from './workspace.query';

export interface GetsWorkspacesQuery {
  getWorkspacesQuery(): Observable<WorkspaceQuery[]>;
}

export const GETS_WORKSPACES_QUERY = new InjectionToken<GetsWorkspacesQuery>(
  'GETS_WORKSPACES_QUERY',
);

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProTeamUpdatedEvent } from '@app.cobiro.com/core/events';
import {
  UPDATE_TEAM_COMMAND_PORT,
  UpdatesTeamCommandPort,
} from '../../application/ports/primary/updates-team.command-port';

@Injectable()
export class TeamUpdatedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProTeamUpdatedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(UPDATE_TEAM_COMMAND_PORT) private readonly _updateTeamCommand: UpdatesTeamCommandPort,
  ) {}

  handle([event]: [CobiroProTeamUpdatedEvent]) {
    this._updateTeamCommand.updateTeam(event.id, event.name, event.avatar);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingDataDto } from './billing-data.dto';

export const GETS_BILLING_DATA_DTO_PORT = new InjectionToken<GetsBillingDataDtoPort>(
  'CHECKS_BILLING_DATA_DTO_PORT',
);

export interface GetsBillingDataDtoPort {
  getBillingData(): Observable<BillingDataDto>;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiAlertModule } from '@app.cobiro.com/shared/hui/alert';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { UserCoreModule } from '@app.cobiro.com/user/core';
import { PasswordFormModule } from '@app.cobiro.com/user/shared';
import { SocialSignUpModule } from '@app.cobiro.com/user/social-sign-up';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { CoreUserLoginModule } from './core-user-login.module';
import { LoginPageComponent } from './ui/login-page/login-page.component';
import { PasswordChangePageComponent } from './ui/password-change-page/password-change-page.component';
import { PasswordRecoveryPageComponent } from './ui/password-recovery-page/password-recovery-page.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    FeatureFlagsModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    HuiLoadingModule,
    HuiAlertModule,
    CoreUserLoginModule,
    HuiStepperModule,
    UserCoreModule,
    SharedAnalyticsModule,
    SocialSignUpModule,
    PasswordFormModule,
  ],
  declarations: [LoginPageComponent, PasswordRecoveryPageComponent, PasswordChangePageComponent],
  exports: [LoginPageComponent, PasswordRecoveryPageComponent, PasswordChangePageComponent],
})
export class UserLoginModule {}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  TEAM_ID_GETTER,
  TeamIdGetter,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { FrontendPagination, PaginationQuery, SORT_ORDER } from '@app.cobiro.com/core/pagination';
import { ComparableSelectionModel } from '@app.cobiro.com/shared/hui/comparable-selection-model/comparable-selection-model';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { ClientListItemQuery } from '../../ports/primary/clients/client-list-item.query';
import { ToggleClientCommandPort } from '../../ports/primary/clients/toggle-client.command-port';
import { GetsClientsListPaginationQueryPort } from '../../ports/primary/clients/gets-clients-list-pagination.query-port';
import { GetsClientsListSelectionQueryPort } from '../../ports/primary/clients/gets-clients-list-selection.query-port';
import { GetsPaginatedClientsQueryPort } from '../../ports/primary/clients/gets-paginated-clients.query-port';
import { IsAllSelectedClientsCommandPort } from '../../ports/primary/clients/is-all-selected-clients.command-port';
import { LoadClientsCommandPort } from '../../ports/primary/clients/load-clients.command-port';
import { ResetsClientsListPaginationCommandPort } from '../../ports/primary/clients/resets-clients-list-pagination.command-port';
import { SetsClientsListFilterCommandPort } from '../../ports/primary/clients/sets-clients-list-filter.command-port';
import { SetsClientsListPageCommandPort } from '../../ports/primary/clients/sets-clients-list-page.command-port';
import { SetsClientsListSortCommand } from '../../ports/primary/clients/sets-clients-list-sort.command';
import { SetsClientsListSortCommandPort } from '../../ports/primary/clients/sets-clients-list-sort.command-port';
import { SetsSearchPhraseCommandPort } from '../../ports/primary/clients/sets-search-phrase.command-port';
import { ToggleAllClientsCommandPort } from '../../ports/primary/clients/toggle-all-clients.command-port';
import { ClientsContext } from '../../ports/secondary/context/clientsContext';
import {
  SELECTS_CLIENTS_CONTEXT_STORAGE,
  SelectsClientsContextStoragePort,
} from '../../ports/secondary/context/selects-clients-context.storage-port';
import {
  CLIENTS_LIST_CHANGED_DISPATCHER,
  ClientsListChangedDispatcherPort,
} from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientDTO } from '../../ports/secondary/dto/clients/client.dto';
import {
  GETS_ALL_CLIENT_DTO,
  GetsAllClientDtoPort,
} from '../../ports/secondary/dto/clients/gets-all-client.dto-port';
import { SetsClientsListFilterCommand } from '../../ports/primary/clients/sets-clients-list-filter.command';
import { SetsSearchPhraseCommand } from '../../ports/primary/clients/sets-search-phrase.command';
import {
  GetsAllClientsSubsciptionsDtoPort,
  GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT,
} from '../../ports/secondary/dto/clients/gets-all-clients-subscriptions.dto-port';
import { ClientSubscriptionDto } from '../../ports/secondary/dto/clients/clients-subscription.dto';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';

@Injectable()
export class ClientsListState
  implements
    GetsPaginatedClientsQueryPort,
    GetsClientsListPaginationQueryPort,
    LoadClientsCommandPort,
    GetsClientsListSelectionQueryPort,
    IsAllSelectedClientsCommandPort,
    ToggleAllClientsCommandPort,
    ToggleClientCommandPort,
    SetsClientsListSortCommandPort,
    SetsClientsListPageCommandPort,
    ResetsClientsListPaginationCommandPort,
    SetsClientsListFilterCommandPort,
    SetsSearchPhraseCommandPort
{
  private readonly _selection$ = new BehaviorSubject<ComparableSelectionModel<ClientListItemQuery>>(
    new ComparableSelectionModel<ClientListItemQuery>(
      true,
      [],
      true,
      (a: ClientListItemQuery, b: ClientListItemQuery) => a.id === b.id,
    ),
  );
  private readonly _pagination: FrontendPagination<ClientDTO> = FrontendPagination.fromRaw(
    this._selectsClientsContextStorage
      .select()
      .pipe(map(clientsContext => clientsContext.list.filter(clientList => !clientList.archived))),
    0,
    20,
    ['companyName', SORT_ORDER.ASC],
    {},
  );

  constructor(
    @Inject(GETS_ALL_CLIENT_DTO)
    private readonly _getAllClientDTO: GetsAllClientDtoPort,
    @Inject(SELECTS_CLIENTS_CONTEXT_STORAGE)
    private readonly _selectsClientsContextStorage: SelectsClientsContextStoragePort,
    @Inject(CLIENTS_LIST_CHANGED_DISPATCHER)
    private readonly _clientsListChangedDispatcher: ClientsListChangedDispatcherPort,
    @Inject(WORKSPACE_ID_GETTER) private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT)
    private readonly _getsAllClientsSubsciptionsDtoPort: GetsAllClientsSubsciptionsDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
  ) {
    this._applicationBus
      .on(CobiroProWorkspaceSwitchedEvent)
      .pipe(switchMap(() => this.loadClients()))
      .subscribe();
  }

  getsPaginatedClients(): Observable<ClientListItemQuery[]> {
    return this._pagination.getPaginatedList().pipe(
      map(clients => {
        return this._mapClientsDtoToListItemQuery(clients);
      }),
    );
  }

  getClientListPagination(): Observable<PaginationQuery> {
    return this._pagination.getPaginationQuery();
  }

  getTotalClients(): Observable<number> {
    return this._pagination.total;
  }

  getClientListSelection(): Observable<ComparableSelectionModel<ClientListItemQuery>> {
    return this._selection$.asObservable();
  }

  isAllSelected(): Observable<boolean> {
    return combineLatest([this._selection$, this._selectsClientsContextStorage.select()]).pipe(
      take(1),
      map(
        ([selection, clients]: [ComparableSelectionModel<ClientListItemQuery>, ClientsContext]) => {
          const numRows = clients.list
            .map(client => ClientListItemQuery.fromClientDTO(client))
            .filter(client => !client.hasPaidPlan()).length;
          const numSelected = selection.selected.length;
          return numRows === numSelected;
        },
      ),
    );
  }

  setSort(command: SetsClientsListSortCommand): void {
    this._pagination.setPage(0);
    this._pagination.setSorting(
      command.key as keyof ClientDTO,
      command.direction.toUpperCase() as SORT_ORDER,
    );
  }

  toggleRow(client: ClientListItemQuery): Observable<void> {
    const selection: ComparableSelectionModel<ClientListItemQuery> = this._selection$.getValue();
    selection.toggle(client);
    this._selection$.next(selection);
    return of(void 0);
  }

  toggleAllRows(): Observable<boolean> {
    return combineLatest([
      this.isAllSelected(),
      this._selectsClientsContextStorage.select(),
      this._selection$,
    ]).pipe(
      take(1),
      map(
        ([isAllSelected, clientsList, selection]: [
          boolean,
          ClientsContext,
          ComparableSelectionModel<ClientListItemQuery>,
        ]) => {
          isAllSelected
            ? selection.clear()
            : clientsList.list
                .map(client => ClientListItemQuery.fromClientDTO(client))
                .filter(client => !client.hasPaidPlan())
                .forEach(client => selection.select(client));
          this._selection$.next(selection);
          return true;
        },
      ),
    );
  }

  loadClients(): Observable<ClientDTO[]> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    const teamId = this._teamIdGetter.getTeamId();
    return combineLatest([
      this._getAllClientDTO.getAll({ workspaceId: workspaceId, teamId: teamId }),
      this._getsAllClientsSubsciptionsDtoPort.getAllSubscriptions({ workspaceId: workspaceId }),
    ]).pipe(
      map(([clients, clientSubscriptions]: [ClientDTO[], ClientSubscriptionDto[]]) =>
        clients.map(client => {
          const subscriptions = clientSubscriptions.find(dto => dto.clientId === client.id)
            ? clientSubscriptions.find(dto => dto.clientId === client.id).subscriptions
            : [];
          return {
            ...client,
            ...{
              subscriptions: subscriptions,
              productStatuses: {
                css: client.cssIntegrationStatus,
                'label-manager': subscriptions?.find(subs => subs.productName === 'label-manager')
                  ? ('active' as const)
                  : null,
              },
            },
          };
        }),
      ),
      tap(clients => this._clientsListChangedDispatcher.dispatch({ updatedClients: clients })),
    );
  }

  setClientsListPage(pageIndex: number): void {
    this._pagination.setPage(pageIndex);
  }

  resetClientListPagination(): void {
    this._pagination.reset();
  }

  setFilter(command: SetsClientsListFilterCommand): Observable<void> {
    this._pagination.setPage(0);
    this._pagination.setFilter(command.key, command.value);
    return of(void 0);
  }

  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void> {
    this._pagination.setPage(0);
    this._pagination.setSearch(command.phrase);
    return of(void 0);
  }

  // getAllClientsPlans(): Observable<string[]> {
  //   return this._selectsClientsContextStorage
  //     .select()
  //     .pipe(map(context => Array.from(new Set(context.list.map(client => client.plan)))));
  // }

  private _mapClientsDtoToListItemQuery(clients: ClientDTO[]): ClientListItemQuery[] {
    return clients.map(clientDto => ClientListItemQuery.fromClientDTO(clientDto));
  }
}

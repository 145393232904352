import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsPaymentPlansQuery } from './gets-payment-plans.query';
import { PaymentPlanOptionQuery } from './payment-plan-options.query';

export const GETS_PAYMENT_PLANS_QUERY = new InjectionToken<GetsPaymentPlansQueryPort>(
  'GETS_PAYMENT_PLANS_QUERY',
);

export interface GetsPaymentPlansQueryPort {
  getPaymentPlans(command: GetsPaymentPlansQuery): Observable<PaymentPlanOptionQuery[]>;
}

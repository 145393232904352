import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { StorageBasedWorkspaceIdGetter, WORKSPACE_ID_GETTER } from './workspace-id.getter';

// TODO: move to core
@NgModule({
  providers: [
    {
      provide: STORAGE,
      useValue: localStorage,
    },
    {
      provide: WORKSPACE_ID_GETTER,
      useClass: StorageBasedWorkspaceIdGetter,
    },
  ],
  imports: [CommonModule],
})
export class WorkspaceIdGetterModule {}

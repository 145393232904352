import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetGoogleAdsLinkingComponent } from './set-google-ads-linking.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  declarations: [SetGoogleAdsLinkingComponent],
  imports: [CommonModule, LanguageModule, MatCheckboxModule],
  exports: [SetGoogleAdsLinkingComponent],
})
export class SetGoogleAdsLinkingComponentModule {}

import { InjectionToken } from '@angular/core';

export const defaultInputErrors = {
  required: () => `This field is required`,
  minlength: ({ requiredLength }) => `Input must be at least ${requiredLength} characters long`,
  maxlength: ({ requiredLength }) => `Input must be up to ${requiredLength} characters long`,
  min: ({ requiredLength }) => `Input must be at least ${requiredLength}`,
  max: ({ requiredLength }) => `Input must be up to ${requiredLength}`,
  pattern: () => `This field is invalid`,
  email: () => `Please enter a valid email address`,
};

interface InputErrors {
  [prop: string]: (...inputErrorFields: any) => string;
}

export const INPUT_ERRORS = new InjectionToken<InputErrors>('INPUT_ERRORS', {
  factory: () => defaultInputErrors,
});

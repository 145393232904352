import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import {
  GETS_NON_CSS_INTEGRATION_QUERY,
  GetsNonCssIntegrationQueryPort,
} from '../../../../../../../application/ports/primary/gets-non-css-integration.query-port';
import { take } from 'rxjs';
import {
  SAVES_LABEL_MANAGER_CONFIG_COMMAND_PORT,
  SavesLabelManagerConfigCommandPort,
} from '../../../../../../../application/ports/primary/saves-label-manager-config.command-port';

@Component({
  selector: 'lib-cobiro-pro-loader-step',
  templateUrl: './loader-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderStepComponent implements OnInit {
  @Output() closeWindow = new EventEmitter<boolean>(false);

  constructor(
    @Inject(GETS_NON_CSS_INTEGRATION_QUERY)
    private readonly _getsNonCssIntegrationQueryPort: GetsNonCssIntegrationQueryPort,
    @Inject(SAVES_LABEL_MANAGER_CONFIG_COMMAND_PORT)
    private readonly _savesLabelManagerConfigCommandPort: SavesLabelManagerConfigCommandPort,
  ) {}

  ngOnInit(): void {
    this._getsNonCssIntegrationQueryPort
      .getNonCssIntegration()
      .pipe(take(1))
      .subscribe(res => {
        res ? this.closeWindow.emit() : null;
      });
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './create-workspace-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateWorkspaceModalComponent implements OnDestroy {
  readonly workspaceForm: UntypedFormGroup = this._fb.group({
    name: ['', [Validators.required]],
  });

  constructor(
    private readonly _fb: UntypedFormBuilder,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { route: ActivatedRoute; shouldBeRedirected: boolean },
  ) {}

  ngOnDestroy(): void {
    if (!this.data.shouldBeRedirected) {
      this._router.navigate(['.'], { relativeTo: this.data.route });
    }
  }
}

import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CurrentPlanDto,
  GetsCurrentPlanDtoPort,
} from '../../../application/ports/secondary/gets-current-plan.dto-port';

interface UserPlanAttributes {
  displayName: string;
  planType: string;
  name: string;
}

@Injectable()
export class HttpCurrentPlanService implements GetsCurrentPlanDtoPort {
  constructor(private _client: GatewayClient) {}

  get(): Observable<CurrentPlanDto> {
    return this._client.get<HasData<UserPlanAttributes>>(`v1/payments/cobiro/1/my-plan`).pipe(
      map(response => ({
        planName: response.data.attributes.name.toLowerCase(),
        planPeriod: response.data.attributes.planType.toLowerCase(),
      })),
    );
  }
}

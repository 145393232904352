import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomainError } from '@app.cobiro.com/core/state';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HasData, HasErrorResponse, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VerifiesUserDtoPort } from '../../application/ports/secondary/verifies-user.dto-port';
import {
  UserAlreadyVerifiedError,
  UserVerifyUnknownError,
} from '../../application/user-register.state';
import { Auth } from '../../domain/auth';

export interface VerifyCodeAttributes {
  userId: number;
  pin: string;
}

export interface UserVerificationResponseAttributes {
  userId: number;
  tokenType: string;
  expiresIn: string;
  accessToken: string;
  refreshToken: string;
}

@Injectable()
export class HttpVerifyCodeService implements VerifiesUserDtoPort {
  private readonly _errors: Map<string, DomainError> = new Map([
    [UserAlreadyVerifiedError.code, new UserAlreadyVerifiedError()],
  ]);
  private readonly _headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(
    private readonly _client: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  verify(userId: number, pin: string): Observable<Auth> {
    const body: HasData<VerifyCodeAttributes> = {
      data: {
        type: 'users-verifications',
        attributes: {
          userId,
          pin,
        },
      },
    };

    return this._client
      .post<HasData<UserVerificationResponseAttributes>>(
        this._getsConfig.get('gateway') + '/v1/users/verify',
        body,
        {
          headers: this._headers,
        },
      )
      .pipe(
        map(response => {
          const { attributes } = response.data;
          return new Auth(
            attributes.userId,
            attributes.tokenType,
            attributes.accessToken,
            attributes.refreshToken,
          );
        }),
        catchError((error: HasErrorResponse) => {
          const httpError = error.error;
          const code = httpError.errors[0]?.code;

          if (!code) {
            return throwError(new UserVerifyUnknownError());
          }

          return throwError(this._errors.get(code) ?? new UserVerifyUnknownError());
        }),
      );
  }
}

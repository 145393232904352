import { HttpClientModule } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { ICONS, Icons } from './icons-registry';
import { CUSTOM_ICONS_REGISTRY } from './custom-icons-registry';
import { FEATURES_ICONS_MAP } from './features-icons-registry';
import { COBIRO_ICONS_MAP } from './cobiro-icons-registry';

export function createIconicIconPath(name: string) {
  return `/assets/svg-icons/iconic/icon-${name}.svg`;
}

// TODO: Add your icons here. THE ONLY icons that should be added are from Iconic: https://iconic.app/
// If there are any icons that do NOT match Iconic, add them to CUSTOM - provide by the Design Team only
export const ICONIC_GENERAL_ICONS_REGISTRY = new Map([
  ['alert', createIconicIconPath('alert')],
  ['alert-triangle', createIconicIconPath('alert-triangle')],
  ['align-center-horizontal', createIconicIconPath('align-center-horizontal')],
  ['align-left', createIconicIconPath('align-left')],
  ['align-right', createIconicIconPath('align-right')],
  ['align-top', createIconicIconPath('align-top')],
  ['bank', createIconicIconPath('bank')],
  ['battery-empty', createIconicIconPath('battery-empty')],
  ['battery-low', createIconicIconPath('battery-low')],
  ['battery-medium', createIconicIconPath('battery-medium')],
  ['battery-charging', createIconicIconPath('battery-charging')],
  ['battery-full', createIconicIconPath('battery-full')],
  ['bell', createIconicIconPath('bell')],
  ['bell-crossed', createIconicIconPath('bell-crossed')],
  ['bell-snoozed', createIconicIconPath('bell-snoozed')],
  ['bell-off', createIconicIconPath('bell-off')],
  ['bell-ringing', createIconicIconPath('bell-ringing')],
  ['bookmark', createIconicIconPath('bookmark')],
  ['calendar-pick', createIconicIconPath('calendar-pick')],
  ['charity', createIconicIconPath('charity')],
  ['checkmark', createIconicIconPath('checkmark')],
  ['check-green', createIconicIconPath('check-green')],
  ['checkmark-white', createIconicIconPath('checkmark-white')],
  ['checkmark-circle', createIconicIconPath('checkmark-circle')],
  ['checkmark-square', createIconicIconPath('checkmark-square')],
  ['click', createIconicIconPath('click')],
  ['clock', createIconicIconPath('clock')],
  ['close', createIconicIconPath('close')],
  ['code', createIconicIconPath('code')],
  ['cursor', createIconicIconPath('cursor')],
  ['dashboard', createIconicIconPath('dashboard')],
  ['device-desktop_brex_ui_redesign', createIconicIconPath('device-desktop_brex_ui_redesign')], // B-rex: device-desktop
  ['device-mobile_brex_ui_redesign', createIconicIconPath('device-mobile_brex_ui_redesign')], // B-rex: device-mobile
  ['device-tablet_brex_ui_redesign', createIconicIconPath('device-tablet_brex_ui_redesign')], // B-rex: device-tablet
  ['eye-close', createIconicIconPath('eye-close')],
  ['eye-open', createIconicIconPath('eye-open')],
  ['flag', createIconicIconPath('flag')],
  ['filter', createIconicIconPath('filter')],
  ['fire', createIconicIconPath('fire')],
  ['forbid', createIconicIconPath('forbid')],
  ['fullscreen', createIconicIconPath('fullscreen')],
  ['ghost', createIconicIconPath('ghost')],
  ['grid', createIconicIconPath('grid')],
  ['hashtag', createIconicIconPath('hashtag')],
  ['hamburger-menu', createIconicIconPath('hamburger-menu')],
  ['headphones', createIconicIconPath('headphones')],
  ['heart', createIconicIconPath('heart')],
  ['home', createIconicIconPath('home')],
  ['hourglass', createIconicIconPath('hourglass')],
  ['information', createIconicIconPath('information')],
  ['key', createIconicIconPath('key')],
  ['language', createIconicIconPath('language')],
  ['line-height', createIconicIconPath('line-height')],
  ['link', createIconicIconPath('link')],
  ['lock-locked', createIconicIconPath('lock-locked')],
  ['lock-unlocked', createIconicIconPath('lock-unlocked')],
  ['nine-dots', createIconicIconPath('nine-dots')],
  ['page-add', createIconicIconPath('page-add')],
  ['paintbrush', createIconicIconPath('paintbrush')],
  ['percentage', createIconicIconPath('percentage')],
  ['pin', createIconicIconPath('pin')],
  ['plus', createIconicIconPath('plus')],
  ['plus-circle', createIconicIconPath('plus-circle')],
  ['save', createIconicIconPath('save')],
  ['search', createIconicIconPath('search')],
  ['send-to-back', createIconicIconPath('send-to-back')],
  ['send-to-front', createIconicIconPath('send-to-front')],
  ['settings', createIconicIconPath('settings')],
  ['spades', createIconicIconPath('spades')],
  ['sparkles', createIconicIconPath('sparkles')],
  ['text', createIconicIconPath('text')],
  ['text-align-center', createIconicIconPath('text-align-center')],
  ['text-align-justified', createIconicIconPath('text-align-justified')],
  ['text-align-left', createIconicIconPath('text-align-left')],
  ['text-align-right', createIconicIconPath('text-align-right')],
  ['three-dots-horizontal', createIconicIconPath('three-dots-horizontal')],
  ['three-dots-vertical', createIconicIconPath('three-dots-vertical')],
  ['tooltip-question-mark', createIconicIconPath('tooltip-question-mark')],
  ['truck', createIconicIconPath('truck')],
  ['timer', createIconicIconPath('timer')],
  ['unlink', createIconicIconPath('unlink')],
  ['user', createIconicIconPath('user')],
  ['user-checked', createIconicIconPath('user-checked')],
  ['users', createIconicIconPath('users')],
  ['zoom-in', createIconicIconPath('zoom-in')],
  ['zoom-out', createIconicIconPath('zoom-out')],
]);

export const ICONIC_AUTOMOTIVE_ICONS_REGISTRY = new Map([
  ['car', createIconicIconPath('car')],
  ['car-catalyst', createIconicIconPath('car-catalyst')],
  ['car-door', createIconicIconPath('car-door')],
  ['car-gearbox-lever', createIconicIconPath('car-gearbox-lever')],
  ['car-speedometer', createIconicIconPath('car-speedometer')],
]);

export const ICONIC_ARROWS_ICONS_REGISTRY = new Map([
  ['arrow-left', createIconicIconPath('arrow-left')],
  ['arrow-right', createIconicIconPath('arrow-right')],
  ['chevron-down', createIconicIconPath('chevron-down')],
  ['chevron-left', createIconicIconPath('chevron-left')],
  ['chevron-right', createIconicIconPath('chevron-right')],
  ['chevron-up', createIconicIconPath('chevron-up')],
  ['location', createIconicIconPath('location')],
  ['no-location', createIconicIconPath('no-location')],
  ['log-in', createIconicIconPath('log-in')],
  ['log-out', createIconicIconPath('log-out')],
  ['navigation', createIconicIconPath('navigation')],
  ['refresh', createIconicIconPath('refresh')],
  ['reply', createIconicIconPath('reply')],
  ['rotate-left', createIconicIconPath('rotate-left')],
  ['rotate-right', createIconicIconPath('rotate-right')],
  ['switch-horizontally', createIconicIconPath('switch-horizontally')],
  ['switch-vertically', createIconicIconPath('switch-vertically')],
]);

export const ICONIC_COMPANY_ICONS_REGISTRY = new Map([
  ['discord', createIconicIconPath('discord')],
  ['dropbox', createIconicIconPath('dropbox')],
  ['facebook', createIconicIconPath('facebook')],
  ['figma', createIconicIconPath('figma')],
  ['google', createIconicIconPath('google')],
  ['instagram', createIconicIconPath('instagram')],
  ['linkedin', createIconicIconPath('linkedin')],
  ['salesforce', createIconicIconPath('salesforce')],
  ['snapchat', createIconicIconPath('snapchat')],
  ['tiktok', createIconicIconPath('tiktok')],
  ['twitter', createIconicIconPath('twitter')],
]);

export const ICONIC_GIT_ICONS_REGISTRY = new Map([
  ['git-branch', createIconicIconPath('git-branch')],
  ['git-commit', createIconicIconPath('git-commit')],
  ['git-diff', createIconicIconPath('git-diff')],
  ['git-fork', createIconicIconPath('git-fork')],
  ['git-merge', createIconicIconPath('git-merge')],
  ['git-pull-request', createIconicIconPath('git-pull-request')],
]);

export const ICONIC_MEDIA_ICONS_REGISTRY = new Map([
  ['camera', createIconicIconPath('camera')],
  ['camera-off', createIconicIconPath('camera-off')],
  ['microphone', createIconicIconPath('microphone')],
  ['microphone-muted', createIconicIconPath('microphone-muted')],
  ['pause', createIconicIconPath('pause')],
  ['play', createIconicIconPath('play')],
  ['play-video', createIconicIconPath('play-video')],
  ['stop', createIconicIconPath('stop')],
]);

export const ICONIC_FILES_ICONS_REGISTRY = new Map([
  ['download-cloud', createIconicIconPath('download-cloud')],
  ['file', createIconicIconPath('file')],
  ['file-download', createIconicIconPath('file-download')],
  ['file-minus', createIconicIconPath('file-minus')],
  ['file-plus', createIconicIconPath('file-plus')],
  ['folder', createIconicIconPath('folder')],
  ['folder-checked', createIconicIconPath('folder-checked')],
  ['folder-minus', createIconicIconPath('folder-minus')],
  ['folder-plus', createIconicIconPath('folder-plus')],
  ['photo', createIconicIconPath('photo')],
  ['photo-blocked', createIconicIconPath('photo-blocked')],
  ['photo-crossed', createIconicIconPath('photo-crossed')],
  ['photo-plus', createIconicIconPath('photo-plus')],
  ['upload', createIconicIconPath('upload')],
  ['upload-cloud', createIconicIconPath('upload-cloud')],
]);

export const ICONIC_COMMUNICATION_ICONS_REGISTRY = new Map([
  ['broadcast', createIconicIconPath('broadcast')],
  ['bluetooth', createIconicIconPath('bluetooth')],
  ['livestream', createIconicIconPath('livestream')],
  ['rss-feed', createIconicIconPath('rss-feed')],
  ['speech-bubble-a', createIconicIconPath('speech-bubble-a')],
  ['speech-bubble-empty', createIconicIconPath('speech-bubble-empty')],
  ['speech-bubble-three-dots', createIconicIconPath('speech-bubble-three-dots')],
  ['speech-bubble-warning', createIconicIconPath('speech-bubble-warning')],
  ['speech-bubble-round-empty', createIconicIconPath('speech-bubble-round-empty')],
  ['speech-bubble-round-minus', createIconicIconPath('speech-bubble-round-minus')],
  ['speech-bubble-round-plus', createIconicIconPath('speech-bubble-round-plus')],
  ['wifi', createIconicIconPath('wifi')],
  ['no-wifi', createIconicIconPath('no-wifi')],
]);

export const ICONIC_BUSINESS_ICONS_REGISTRY = new Map([
  ['announcement', createIconicIconPath('announcement')],
  ['banknote', createIconicIconPath('banknote')],
  ['bitcoin', createIconicIconPath('bitcoin')],
  ['briefcase', createIconicIconPath('briefcase')],
  ['chart', createIconicIconPath('chart')],
  ['credit-card', createIconicIconPath('credit-card')],
  ['dollar', createIconicIconPath('dollar')],
  ['lightning', createIconicIconPath('lightning')],
  ['pie-chart', createIconicIconPath('pie-chart')],
  ['pulse', createIconicIconPath('pulse')],
  ['shop', createIconicIconPath('shop')],
  ['shopping-bag', createIconicIconPath('shopping-bag')],
  ['shopping-cart', createIconicIconPath('shopping-cart')],
  ['shopping-tag', createIconicIconPath('shopping-tag')],
  ['shopping-tag-plus', createIconicIconPath('shopping-tag-plus')],
  ['star', createIconicIconPath('star')],
  ['target', createIconicIconPath('target')],
  ['trending-up', createIconicIconPath('trending-up')],
  ['trending-down', createIconicIconPath('trending-down')],
  ['trophy', createIconicIconPath('trophy')],
  ['wallet', createIconicIconPath('wallet')],
]);

export const ICONIC_TECHNOLOGY_ICONS_REGISTRY = new Map([
  ['browser', createIconicIconPath('browser')],
  ['bug', createIconicIconPath('bug')],
  ['cloud', createIconicIconPath('cloud')],
  ['cpu', createIconicIconPath('cpu')],
  ['database', createIconicIconPath('database')],
  ['database-blocked', createIconicIconPath('database-blocked')],
  ['database-minus', createIconicIconPath('database-minus')],
  ['database-plus', createIconicIconPath('database-plus')],
  ['globe', createIconicIconPath('globe')],
  ['lightbulb', createIconicIconPath('lightbulb')],
  ['lightbulb-off', createIconicIconPath('lightbulb-off')],
  ['power', createIconicIconPath('power')],
]);

export const ICONIC_OFFICE_ICONS_REGISTRY = new Map([
  ['at-sign', createIconicIconPath('at-sign')],
  ['box', createIconicIconPath('box')],
  ['copy', createIconicIconPath('copy')],
  ['inbox', createIconicIconPath('inbox')],
  ['mail-closed', createIconicIconPath('mail-closed')],
  ['mail-open', createIconicIconPath('mail-open')],
  ['paperclip', createIconicIconPath('paperclip')],
  ['pdf', createIconicIconPath('pdf')],
  ['pencil', createIconicIconPath('pencil')],
  ['print', createIconicIconPath('print')],
  ['trash', createIconicIconPath('trash')],
  ['trash-hollow', createIconicIconPath('trash-hollow')],
]);

export const ICONS_REGISTRY_MAP = new Map([
  ...ICONIC_GENERAL_ICONS_REGISTRY,
  ...ICONIC_AUTOMOTIVE_ICONS_REGISTRY,
  ...ICONIC_ARROWS_ICONS_REGISTRY,
  ...ICONIC_COMPANY_ICONS_REGISTRY,
  ...ICONIC_GIT_ICONS_REGISTRY,
  ...ICONIC_MEDIA_ICONS_REGISTRY,
  ...ICONIC_FILES_ICONS_REGISTRY,
  ...ICONIC_COMMUNICATION_ICONS_REGISTRY,
  ...ICONIC_BUSINESS_ICONS_REGISTRY,
  ...ICONIC_TECHNOLOGY_ICONS_REGISTRY,
  ...ICONIC_OFFICE_ICONS_REGISTRY,
  ...CUSTOM_ICONS_REGISTRY,
  ...FEATURES_ICONS_MAP,
  ...COBIRO_ICONS_MAP,
]);

// If you add new icons to the registry, they will be visible only once they are present on S3 / after deploy
// Icons are to be added here: projects/cobiro/src/assets/svg-icons/ -> DO NOT REPLACE ALREADY-EXISTING ICONS!
@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    {
      provide: ICONS,
      useValue: ICONS_REGISTRY_MAP,
    },
  ],
})
export class IconsRegistryModule {
  constructor(
    @Inject(ICONS) private readonly _icons: Icons,
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _registry: MatIconRegistry,
    private readonly _sanitizer: DomSanitizer,
  ) {
    for (const [name, path] of _icons) {
      this._registry.addSvgIcon(
        name,
        _sanitizer.bypassSecurityTrustResourceUrl(this._window.location.origin + path),
      );
    }
  }
}

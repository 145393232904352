import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HasData } from '@cobiro/jsonapi';
import { RefreshesToken, Token } from '../domain/refreshes-token';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';

export interface RefreshTokenAttributes {
  refresh_token: string;
}

export interface TokenAttributes {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  token_type: string;
}

@Injectable()
export class HttpRefreshesTokenService implements RefreshesToken {
  constructor(private readonly _client: GatewayClient) {}

  refresh(refreshToken: string): Observable<Token> {
    const body: HasData<RefreshTokenAttributes> = {
      data: {
        type: 'login',
        attributes: {
          refresh_token: refreshToken,
        },
      },
    };

    return this._client.post<HasData<TokenAttributes>>('v1/users/refresh', body).pipe(
      map(response => response.data.attributes),
      map(
        attributes =>
          new Token(
            attributes.access_token,
            attributes.refresh_token,
            attributes.expires_in,
            attributes.token_type,
          ),
      ),
    );
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HuiSimpleModalWithEmojiData } from './hui-simple-modal-with-emoji-data';

@Component({
  selector: 'hui-simple-modal-with-emoji',
  templateUrl: './hui-simple-modal-with-emoji.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HuiSimpleModalWithEmojiComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: HuiSimpleModalWithEmojiData) {}
}

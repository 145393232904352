import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CobiroProContextQuery } from './cobiro-pro-context.query';

export const GETS_COBIRO_PRO_CONTEXT_QUERY = new InjectionToken<GetsCobiroProContextQueryPort>(
  'GETS_COBIRO_PRO_CONTEXT_QUERY',
);

export interface GetsCobiroProContextQueryPort {
  getContext(): Observable<CobiroProContextQuery>;
}

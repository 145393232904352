import { NgModule } from '@angular/core';
import { TeamPaymentMethodsComponent } from './team-payment-methods.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CommonModule } from '@angular/common';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { ReactiveFormsModule } from '@angular/forms';
import { HuiCardExpireDateModule } from '@app.cobiro.com/shared/hui/expire-date/hui-card-expire-date.module';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { HasTeamBillingGuard } from '../../guards/has-team-billing.guard';

@NgModule({
  declarations: [TeamPaymentMethodsComponent],
  imports: [
    CommonModule,
    LanguageModule,
    FeatureFlagsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    RouterModule.forChild([
      { path: '', canActivate: [HasTeamBillingGuard], component: TeamPaymentMethodsComponent },
    ]),
    HuiCardExpireDateModule,
    NgxMaskModule,
    HuiLoaderContainerModule,
  ],
})
export class TeamPaymentMethodsComponentModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserDTO } from './user.dto';
import { GetsAllWorkspaceUsersDto } from './gets-all-workspace-users.dto';

export const GETS_ALL_WORKSPACE_USERS_DTO = new InjectionToken<GetsAllWorkspaceUsersDtoPort>(
  'GETS_ALL_WORKSPACE_USERS_DTO',
);

export interface GetsAllWorkspaceUsersDtoPort {
  getAllWorkspaceUsers(dto: GetsAllWorkspaceUsersDto): Observable<UserDTO[]>;
}

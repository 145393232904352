import { NgModule } from '@angular/core';
import { VERIFIES_USER_DTO } from '../../application/ports/secondary/verifies-user.dto-port';
import { HttpVerifyCodeService } from './http-verify-code.service';

@NgModule({
  providers: [
    HttpVerifyCodeService,
    {
      provide: VERIFIES_USER_DTO,
      useExisting: HttpVerifyCodeService,
    },
  ],
})
export class HttpVerifyCodeServiceModule {}

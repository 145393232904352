import { InjectionToken } from '@angular/core';
// TODO (PRO-DEBT): Rename the file to selects-client.command-port.ts

// TODO (PRO-DEBT): Use interface, rename to SelectClientCommand and move to separate file
export type SelectClientData = {
  readonly id: string;
  readonly siteId: string;
  readonly name: string;
  readonly avatar: string;
  readonly sitePublicId: string;
};

export interface SelectsClientCommandPort {
  selectClient(selectClientData: SelectClientData);
}

export const SELECTS_CLIENT_COMMAND = new InjectionToken<SelectsClientCommandPort>(
  'SELECT_CLIENT_COMMAND',
);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateUserModalComponent } from './create-user-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [CreateUserModalComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgSelectModule,
    FormsModule,
  ],
  exports: [CreateUserModalComponent],
})
export class CreateUserModalComponentModule {}

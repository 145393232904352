import { Injectable } from '@angular/core';
import { HasData } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { ChangesPasswordDtoPort } from '../../../../application/ports/secondary/changes-password.dto-port';

interface ChangesPasswordAttributes {
  oldPassword: string;
  newPassword: string;
}

@Injectable()
export class HttpChangesPasswordService implements ChangesPasswordDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  change(oldPassword: string, newPassword: string): Observable<boolean> {
    const body: HasData<ChangesPasswordAttributes> = {
      data: {
        type: 'change-password',
        attributes: {
          oldPassword,
          newPassword,
        },
      },
    };
    return this._client.post(`v1/users/change-password`, body).pipe(map(() => true));
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TeamIdGetter, TEAM_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import {
  GetsProductMarketingListQuery,
  GETS_PRODUCT_MARKETING_LIST_QUERY,
} from '../../../../../application/ports/primary/setup/gets-product-marketing-list.query';
import { ProductMarketingQuery } from '../../../../../application/ports/primary/setup/product-marketing.query';
import { Observable, Subject, tap } from 'rxjs';

@Component({
  selector: 'lib-cobiro-pro-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInfoComponent implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  readonly product$: Observable<ProductMarketingQuery | null> =
    this._getsProductMarketingListQuery.getAllProductsMarketing(this.data.product);

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { product: string },
    @Inject(GETS_PRODUCT_MARKETING_LIST_QUERY)
    private readonly _getsProductMarketingListQuery: GetsProductMarketingListQuery,
    private readonly _dialogRef: MatDialogRef<ProductInfoComponent>,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  goToProduct(): void {
    this._dialogRef.close();
    this._router.navigate(['/', 'connect', this._teamIdGetter.getTeamId(), 'clients', 'setup']);
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}

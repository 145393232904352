import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface UserDetailsDTO {
  firstName: string;
  lastName: string;
  country: string;
  email: string;
}
export interface GetsUserDetails {
  getDetails(): Observable<UserDetailsDTO>;
}

export const GETS_USER_DETAILS = new InjectionToken<GetsUserDetails>('GETS_USER_DETAILS');

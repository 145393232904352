import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface ShowProductInfoCommandPort {
  showProductInfoModal(product: string): Observable<boolean>;
}

export const SHOW_PRODUCT_INFO_COMMAND_PORT = new InjectionToken<ShowProductInfoCommandPort>(
  'SHOW_PRODUCT_INFO_COMMAND_PORT',
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { IntegrationAccountFormComponentModule } from '../integration-account-form/integration-account-form.component-module';
import { LinkIntegrationComponentModule } from '../link-integration/link-integration.component-module';
import { IntegrationSucceededComponent } from './integration-succeeded.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    IntegrationAccountFormComponentModule,
    MatExpansionModule,
    LinkIntegrationComponentModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  declarations: [IntegrationSucceededComponent],
  exports: [IntegrationSucceededComponent],
})
export class IntegrationSucceededComponentModule {}

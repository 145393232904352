/* eslint-disable complexity */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmailValidator } from '@app.cobiro.com/shared/validators';
import { EMPTY, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import {
  GetsWorkspacesListQuery,
  GETS_WORKSPACES_LIST_QUERY,
} from '../../../../../application/ports/primary/gets-workspace-list.query';

const maybeRequiredValidator =
  (inputName: string, requiredWhen: (form: AbstractControl) => boolean): ValidatorFn =>
  (form: AbstractControl): ValidationErrors | null => {
    const targetInput = form.get(inputName);
    if (targetInput) {
      const isRequired = requiredWhen(form);
      if (isRequired != targetInput.hasValidator(Validators.required)) {
        if (isRequired) {
          targetInput.addValidators(Validators.required);
        } else {
          targetInput.removeValidators(Validators.required);
        }
        targetInput.updateValueAndValidity({ onlySelf: true });
      }
    }
    return null;
  };

@Component({
  selector: 'lib-cobiro-pro-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUserModalComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  readonly workspaces$ = this._getsWorkspacesListQuery.getWorkspaceListQuery();
  readonly userForm: FormGroup = this._fb.group(
    {
      role: ['basic_user', [Validators.required]],
      email: ['', [Validators.required, isEmailValidator]],
      comment: [''],
      workspaces: [null],
    },
    {
      validators: [
        maybeRequiredValidator('workspaces', form => form.get('role').value === 'basic_user'),
      ],
    },
  );

  constructor(
    private readonly _fb: FormBuilder,
    @Inject(GETS_WORKSPACES_LIST_QUERY)
    private readonly _getsWorkspacesListQuery: GetsWorkspacesListQuery,
    @Inject(MAT_DIALOG_DATA) readonly data: { route: ActivatedRoute },
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.userForm
      .get('role')
      .valueChanges.pipe(
        switchMap(res =>
          res === 'admin'
            ? this.workspaces$.pipe(
                takeUntil(this._ngDestroy$),
                tap(workspaces => this.userForm.patchValue({ workspaces: workspaces })),
              )
            : of(this.userForm.patchValue({ workspaces: [] })),
        ),
      )
      .subscribe();
  }

  maybeRequiredValidator =
    (inputName: string, requiredWhen: (form: AbstractControl) => boolean): ValidatorFn =>
    (form: AbstractControl): ValidationErrors | null => {
      const targetInput = form.get(inputName);
      if (targetInput) {
        const isRequired = requiredWhen(form);
        if (isRequired != targetInput.hasValidator(Validators.required)) {
          if (isRequired) {
            targetInput.addValidators(Validators.required);
          } else {
            targetInput.removeValidators(Validators.required);
          }
          targetInput.updateValueAndValidity({ onlySelf: true });
        }
      }
      return null;
    };

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }
}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Country } from '../domain/country';
import { GETS_COUNTRIES, GetsCountries } from '../domain/gets-countries';

@Injectable()
export class CountriesState {
  private readonly _countries$: Observable<Country[]>;

  constructor(@Inject(GETS_COUNTRIES) private _getsCountries: GetsCountries) {
    this._countries$ = this._getsCountries.getAll().pipe(shareReplay(1));
  }

  getCountries(): Observable<Country[]> {
    return this._countries$;
  }
}

import { NgModule } from '@angular/core';
import { REDIRECTS_TO_APP_COMMAND } from '../ports/primary/redirect-to-app.command-port';
import { RedirectToAppCommandService } from './redirect-to-app.command-service';

@NgModule({
  providers: [
    {
      provide: REDIRECTS_TO_APP_COMMAND,
      useClass: RedirectToAppCommandService,
    },
  ],
})
export class RedirectToAppCommandModule {}

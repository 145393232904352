import { ApplicationEvent } from '@cobiro/eda';

export class PartnerProgramRequestEvent extends ApplicationEvent {
  constructor(
    public readonly userName: string,
    public readonly agencyName: string,
    public readonly url: string,
    public readonly workspaceId: string,
  ) {
    super();
  }
}

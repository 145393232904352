import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthData, LoginsUser } from '../domain/logins';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData, HasErrorsResponse } from '@cobiro/jsonapi';

export interface AuthAttributes {
  email: string;
  password: string;
  group: string;
  source: string;
  partnerId: string;
  first_name: string;
  last_name: string;
  weekly_summary: boolean;
  access_token: string;
  refresh_token: string;
  expires_in: string;
  token_type: string;
}

@Injectable()
export class HttpLoginsService implements LoginsUser {
  constructor(private _client: GatewayClient) {}

  login(email: string, password: string): Observable<AuthData> {
    return this._client
      .post<HasData<AuthAttributes>>(`v1/users/login`, {
        data: {
          type: 'login',
          attributes: { email, password },
        },
      })
      .pipe(
        catchError((err: HttpErrorResponse): Observable<string> => {
          return throwError(err?.error as HasErrorsResponse);
        }),
        map(this.mapLoginModelToLogin),
      );
  }

  private mapLoginModelToLogin(loginModel: HasData<AuthAttributes>): AuthData {
    const data = loginModel.data;
    const attributes = data.attributes;
    return {
      id: data.id,
      accessToken: attributes.access_token,
      refreshToken: attributes.refresh_token,
      expiresIn: +attributes.expires_in,
      tokenType: attributes.token_type,
    };
  }
}

import { NgModule } from '@angular/core';
import { TeamSettingsState } from './team-settings.state';
import { SETS_SELECTED_COUNTRY_COMMAND } from '../../ports/primary/settings/sets-selected-country.command-port';
import { GETS_COUNTRIES_QUERY } from '../../ports/primary/settings/gets-countries.query-port';
import { GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT } from '../../ports/primary/settings/gets-save-team-command-error.query-port';
import { GETS_TEAM_SETTINGS_QUERY_PORT } from '../../ports/primary/settings/gets-team-settings.query-port';
import { GETS_VAT_NUMBER_VALIDATION_QUERY } from '../../ports/primary/settings/gets-vat-number-validation.query-port';
import { SAVES_TEAM_SETTINGS_COMMAND } from '../../ports/primary/settings/saves-team-settings.command-port';
import { CALL_PAYMENT_BILLING_COMMAND } from '../../ports/primary/settings/calls-payment-billing-event.command-port';

@NgModule({
  providers: [
    TeamSettingsState,
    { provide: GETS_TEAM_SETTINGS_QUERY_PORT, useExisting: TeamSettingsState },
    { provide: SAVES_TEAM_SETTINGS_COMMAND, useExisting: TeamSettingsState },
    {
      provide: GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT,
      useExisting: TeamSettingsState,
    },
    {
      provide: GETS_COUNTRIES_QUERY,
      useExisting: TeamSettingsState,
    },
    {
      provide: GETS_VAT_NUMBER_VALIDATION_QUERY,
      useExisting: TeamSettingsState,
    },
    {
      provide: SETS_SELECTED_COUNTRY_COMMAND,
      useExisting: TeamSettingsState,
    },
    {
      provide: CALL_PAYMENT_BILLING_COMMAND,
      useExisting: TeamSettingsState,
    },
  ],
})
export class TeamSettingsStateModule {}

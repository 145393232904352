<div
  class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center cs-max-width-500-xs"
>
  <div class="cs-emoji-circle cs-emoji-circle-tertiary">❌</div>
  <h4 class="cs-title-4 cs-mt-20 cs-mb-10 text-center">
    {{ 'cobiro_pro_workspace_delete_workspace_title' | language }}
  </h4>
  <p class="cs-text-1 text-center cs-mt-10 cs-mb-40">
    {{ 'cobiro_pro_workspace_delete_workspace_description' | language : data.name }}
  </p>
  <div
    *ngIf="{
      isCurrent: isCurrent$ | async,
      hasSubscriptions: hasSubscriptions$ | async
    } as warnings"
  >
    <div *ngIf="warnings.isCurrent" class="cs-alert cs-alert-error">
      <div class="d-flex">
        <mat-icon class="cs-min-width-32-xs" svgIcon="alert-triangle"></mat-icon>
        <p class="cs-c-error">
          {{ 'cobiro_pro_workspace_delete_tooltip' | language : data.name }}
        </p>
      </div>
    </div>
    <div *ngIf="!warnings.isCurrent && warnings.hasSubscriptions" class="cs-alert cs-alert-error">
      <div class="d-flex">
        <mat-icon class="cs-min-width-32-xs" svgIcon="alert-triangle"></mat-icon>
        <p class="cs-c-error">
          {{ 'cobiro_pro_workspace_delete_workspace_warning' | language : data.name }}
        </p>
      </div>
    </div>

    <div
      *ngIf="!warnings.isCurrent && !warnings.hasSubscriptions"
      class="d-flex justify-content-center"
    >
      <button
        class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-stroked"
        data-selector="confirmation-popup-no-btn"
        [matDialogClose]="false"
      >
        {{ '_cancel' | language }}
      </button>
      <button class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-error" [matDialogClose]="true">
        {{ '_remove' | language }}
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </div>

  <!-- <ng-template #hasSubscription>
    <div class="d-flex justify-content-center">
      <button
        class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-stroked"
        data-selector="confirmation-popup-no-btn"
        [matDialogClose]="false"
      >
        {{ '_cancel' | language }}
      </button>
      <button class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-error" [matDialogClose]="true">
        {{ '_remove' | language }}
        <mat-icon svgIcon="trash"></mat-icon>
      </button>
    </div>
  </ng-template> -->
</div>

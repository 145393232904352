import { NgModule } from '@angular/core';
import { CREATES_AGENCY_WITH_WORKSPACE_DTO } from '../../application/ports/secondary/creates-agency-with-workspace.dto-port';
import { GETS_COUNTRIES_DTO } from '../../application/ports/secondary/gets-countries.dto-port';
import { HttpCreatesProfileService } from './http-creates-profile.service';

@NgModule({
  providers: [
    HttpCreatesProfileService,
    { provide: CREATES_AGENCY_WITH_WORKSPACE_DTO, useExisting: HttpCreatesProfileService },
    { provide: GETS_COUNTRIES_DTO, useExisting: HttpCreatesProfileService },
  ],
})
export class HttpCreatesProfileServiceModule {}

import { NgModule } from '@angular/core';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login/socialauth.service';
import { FacebookEnv, GoogleEnv } from '../../../../../environments/environment.config';
import { WindowEnvConfig } from '../../../../core/utils/src/lib/config/window-config.service';
import { SIGNS_UP_BY_FACEBOOK_COMMAND } from './application/ports/primary/signs-up-by-facebook.command-port';
import { SIGNS_UP_BY_GOOGLE_COMMAND } from './application/ports/primary/signs-up-by-google.command-port';
import { SocialLoginState } from './application/social-login.state';
import { SIGNS_UP_BY } from './domain/signs-up-by';
import { HttpSignUpByService } from './infrastructure/http-sign-up-by.service';

@NgModule({})
export class CoreSocialSignUpModule {
  static forRoot() {
    return {
      ngModule: CoreSocialSignUpModule,
      providers: [
        SocialLoginState,
        {
          provide: SIGNS_UP_BY,
          useClass: HttpSignUpByService,
        },
        {
          provide: SIGNS_UP_BY_GOOGLE_COMMAND,
          useExisting: SocialLoginState,
        },
        {
          provide: SIGNS_UP_BY_FACEBOOK_COMMAND,
          useExisting: SocialLoginState,
        },
        {
          provide: 'SocialAuthServiceConfig',
          useFactory: (config: WindowEnvConfig) => {
            const googleId = config.get<GoogleEnv>('google').gapiClientId;
            const facebookId = config.get<FacebookEnv>('facebook').appId;

            return {
              autoLogin: false,
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider(googleId),
                },
                {
                  id: FacebookLoginProvider.PROVIDER_ID,
                  provider: new FacebookLoginProvider(facebookId),
                },
              ],
              onError: err => {
                console.error(err);
              },
            } as SocialAuthServiceConfig;
          },
          deps: [WindowEnvConfig],
        },
        SocialAuthService,
      ],
    };
  }
}

import { InjectionToken } from '@angular/core';
import { CreateClientCommand } from './create-client.command';

export const CREATE_CLIENT_COMMAND = new InjectionToken<CreateClientCommandPort>(
  'CREATE_CLIENT_COMMAND',
);

export interface CreateClientCommandPort {
  createClient(command: CreateClientCommand): void;
}

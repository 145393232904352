import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

export interface CreateClientForm {
  companyName: string;
  url: string;
}

@Component({
  selector: 'lib-cobiro-pro-create-new-client-form',
  templateUrl: './create-new-client-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewClientFormComponent implements OnInit, OnDestroy {
  @Output() private formGroupChange = new EventEmitter<CreateClientForm>();
  @Output() private validityChanged = new EventEmitter<boolean>();

  private _ngDestroy$ = new Subject<void>();

  readonly clientForm: FormGroup = this._fb.group({
    companyName: new FormControl<string>(null, [Validators.required]),
    url: new FormControl<string>(null, [Validators.required, Validators.maxLength(200)]),
  });

  constructor(private readonly _fb: FormBuilder) {}

  ngOnInit(): void {
    this.clientForm.valueChanges.subscribe(() =>
      this.formGroupChange.emit(this.clientForm.getRawValue()),
    );
    this.clientForm.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this._ngDestroy$))
      .subscribe(res => this.validityChanged.emit(res === 'VALID'));
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CssMerchantDto } from './css-merchant.dto';
import { GetsCssMerchantIdsDto } from './gets-css-merchant-ids.dto';

export interface GetsCssMerchantIdsDtoPort {
  getCssMerchantIds(dto: GetsCssMerchantIdsDto): Observable<CssMerchantDto[]>;
}

export const GETS_CSS_MERCHANT_IDS_DTO_PORT = new InjectionToken<GetsCssMerchantIdsDtoPort>(
  'GETS_CSS_MERCHANT_IDS_DTO_PORT',
);

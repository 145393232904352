import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HuiKeyboardBlockDirective } from './hui-keyboard-block.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HuiKeyboardBlockDirective],
  exports: [HuiKeyboardBlockDirective],
})
export class HuiKeyboardBlockModule {}

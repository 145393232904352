import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserImpersonationState } from '../../application/user-impersonation.state';

@Component({
  selector: 'lib-impersonation-widget',
  templateUrl: './impersonation-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpersonationWidgetComponent {
  user$ = this._state.impersonatedUser$;
  constructor(private readonly _state: UserImpersonationState) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CobiroProOverviewWorkspaceComponent } from './overview-workspace.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatIconModule, RouterModule],
  declarations: [CobiroProOverviewWorkspaceComponent],
  exports: [CobiroProOverviewWorkspaceComponent],
})
export class CobiroProOverviewWorkspaceComponentModule {}

<div
  class="position-relative d-flex flex-column cs-p-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
    <img
      class="align-self-center cs-width-60-xs cs-height-60-xs cs-mb-10"
      src="/assets/apps/icon/google_css.svg"
    />
    <div
      class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
    >
      <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
        <h5 class="align-self-center cs-title-5 cs-mb-10">
          {{ 'cobiro_pro_label_manager_non_css_integration_title' | language }}
        </h5>
      </div>
    </div>
    <div class="d-flex align-items-start position-relative">
      <div [style.display]="selected ? 'block' : 'none'">
        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GoogleLocation } from '../domain/google-location';
import { HttpLocationsApiService } from '../infrastructure/http-locations-api.service';

@Injectable()
export class GoogleLocationsState {
  private locationsCacheMap: Map<string, GoogleLocation> = new Map();

  constructor(private locationsApi: HttpLocationsApiService) {}

  searchLocation(googleId: string): Observable<GoogleLocation> {
    const cachedLocation = this.locationsCacheMap.get(googleId);
    if (cachedLocation) {
      return of(cachedLocation);
    }
    return this.locationsApi
      .getLocation(googleId)
      .pipe(tap(location => this.locationsCacheMap.set(googleId, location)));
  }

  searchLocationsByName(
    canonicalName: string,
    pageSize: number = 10,
    countryCodesFilter?: string[],
  ): Observable<GoogleLocation[]> {
    return this.locationsApi
      .getLocationsByName(canonicalName, pageSize, countryCodesFilter)
      .pipe(
        tap(locations =>
          locations.forEach(location => this.locationsCacheMap.set(String(location.id), location)),
        ),
      );
  }

  getLocationByNameAndCountry(name: string, countryCode: string): Observable<GoogleLocation> {
    return this.locationsApi.getLocationsByName(name, 1, [countryCode]).pipe(
      map((locations: GoogleLocation[]) => locations[0]),
      tap((location: GoogleLocation) => {
        if (location) {
          this.locationsCacheMap.set(String(location.id), location);
        }
      }),
    );
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnoucementQuery } from './annoucement.query';

export interface GetsAnnoucementQueryPort {
  getAnnoucements(): Observable<AnnoucementQuery[]>;
}

export const GETS_ANNOUCEMENT_QUERY = new InjectionToken<GetsAnnoucementQueryPort>(
  'GETS_ANNOUCEMENT_QUERY',
);

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, pluck, shareReplay, takeUntil } from 'rxjs/operators';

@Injectable()
/**
 * @deprecated We don't want to use this pattern anymore.
 */
export abstract class State<T extends object> implements OnDestroy {
  private readonly _stateSubject: BehaviorSubject<T>;
  public readonly state$: Observable<T>;

  protected constructor(private defaultValue: T) {
    this._stateSubject = new BehaviorSubject<T>(defaultValue);
    this.state$ = this._stateSubject.asObservable().pipe(takeUntil(this.destroy$), shareReplay(1));
  }

  protected destroy$ = new Subject<void>();

  protected getState(): T {
    return this._stateSubject.getValue();
  }

  protected patchState(newStateValue: Partial<T>): void {
    this._stateSubject.next({ ...this._stateSubject.getValue(), ...newStateValue });
  }

  // TODO fix overload
  pluckState<K1 extends keyof T>(
    [k1]: [K1],
    distinct?: (x: T[K1], y: T[K1]) => boolean,
  ): Observable<T[K1]>;

  pluckState<K1 extends keyof T, K2 extends keyof T[K1]>(
    [k1, k2]: [K1, K2],
    distinct?: (x: T[K1][K2], y: T[K1][K2]) => boolean,
  ): Observable<T[K1][K2]>;

  pluckState<K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(
    [k1, k2, k3]: [K1, K2, K3],
    distinct?: (x: T[K1][K2][K3], y: T[K1][K2][K3]) => boolean,
  ): Observable<T[K1][K2]>;

  pluckState<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
  >(
    [k1, k2, k3, k4]: [K1, K2, K3, K4],
    distinct?: (x: T[K1][K2][K3][K4], y: T[K1][K2][K3][K4]) => boolean,
  ): Observable<T[K1][K2][K3][K4]>;

  pluckState<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
  >(
    keys: [K1] | [K1, K2] | [K1, K2, K3] | [K1, K2, K3, K4],
    distinct?: (x: T, y: T) => boolean,
  ): Observable<T | T[K1] | T[K1][K2] | T[K1][K2][K3] | T[K1][K2][K3][K4]> {
    return this.state$.pipe(
      pluck(...(keys as string[])),
      distinctUntilChanged(distinct),
      shareReplay(1),
    );
  }

  ngOnDestroy() {
    this.patchState(this.defaultValue);
    this.destroy$.next();
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ShowsSimulationOptionQuery } from './shows-simulation-option.query';

export interface ShowsSimulationOptionQueryPort {
  showsSimulationOption(query: ShowsSimulationOptionQuery): Observable<boolean>;
}

export const SHOWS_SIMULATION_OPTION_QUERY_PORT =
  new InjectionToken<ShowsSimulationOptionQueryPort>('SHOWS_SIMULATION_OPTION_QUERY_PORT');

<section
  *ngIf="integration$ | async as query"
  class="cs-container d-flex flex-column align-items-start position-relative"
>
  <div class="d-flex flex-column flex-sm-row">
    <div class="cs-card cs-min-height-440-xs">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="cs-title-4">{{ 'google_css_integration_pause_title' | language }}</h4>

        <span
          class="cs-badge status-badge cs-pv-9 cs-text-2 br-5 d-inline-block cs-badge-error cs-ml-20"
          >{{ 'cobiro_pro_css_integration_status_paused' | language }}</span
        >
      </div>

      <p class="cs-text-1 cs-mt-10">
        {{ 'google_css_integration_pause_upgrade_description' | language }}
      </p>

      <p
        *ngIf="query.integrationType === 'switched'"
        class="cs-text-1 cs-mt-10"
        data-selector="approve-link-request"
        [innerHTML]="'google_css_integration_pause_switched_description' | language"
      ></p>

      <p class="cs-text-3 cs-mt-10" data-selector="integration-id-info">
        Integration ID: {{ query.integrationId }}
      </p>
    </div>

    <aside
      class="cs-card cs-max-width-300-sm cs-p-20 cs-mt-20 cs-mt-0-sm cs-ml-20-sm"
      data-selector="support-sidebar"
    >
      <h4 class="cs-title-5 cs-mt-10">
        {{ 'google_css_integration_help_title' | language }}
      </h4>
      <p class="cs-text-2 cs-mt-10">
        {{ 'google_css_integration_help_description' | language }}
      </p>
      <p
        class="cs-text-2 cs-mt-30"
        [innerHTML]="'google_css_integration_help_read_fq' | language"
      ></p>
    </aside>
  </div>
  <div class="w-100">
    <p class="cs-text-2 text-center cs-mt-10">{{ '_copyright_cobiro' | language }}</p>
  </div>
</section>

<div
  class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
    <h5 class="align-self-center cs-title-5 cs-mb-10">
      {{ 'cobiro_pro_label_manager_config_window_title' | language }}
    </h5>
    <p class="align-self-center text-center cs-max-width-440-xs cs-text-1">
      {{ 'cobiro_pro_label_manager_config_window_subtitle' | language }}
    </p>
    <h6 class="cs-title-6 cs-mt-40 cs-mb-10">
      {{ 'cobiro_pro_label_manager_config_window_link_description' | language }}
    </h6>
    <div class="d-flex flex-column">
      <ul class="cs-list cs-list-none cs-br-10 cs-c-hover">
        <li
          *ngFor="let step of steps; let i = index"
          class="cs-list-item cs-b-1 cs-b-shade-15 cs-bg-shade-0 cs-bg-hover-shade-4 br-5 d-flex align-items-start cs-p-20 cs-mh-0 cs-mt-0 cs-mb-10 cs-transition-regular-ease cs-min-height-80-xs cs-cursor-pointer"
        >
          <div class="d-flex align-self-stretch mt-2">
            <div class="circle">
              <p class="cs-text-2">{{ i + 1 }}</p>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex flex-column align-items-center">
              <div class="d-flex flex-column cs-mh-20">
                <h5 class="cs-title-7">
                  {{ step.title | language }}
                </h5>
                <p class="cs-text-1" [innerHTML]="step.subtitle | language"></p>
              </div>
              <div *ngIf="step.checkbox" class="d-flex w-100 cs-mt-10 cs-mb-10 cs-ml-2">
                <mat-checkbox
                  class="cs-mat-checkbox cs-mat-checkbox-shade-10 cs-mat-checkbox-without-ripple"
                  [data-selector]="step.checkbox.dataSelector"
                  (change)="step.checkbox.action()"
                >
                  <span class="cs-title-8">{{ step.checkbox.label | language }}</span>
                </mat-checkbox>
                <div class="cs-control-error cs-mt-15" *ngIf="step.checkbox.errorCondition | async">
                  {{ step.checkbox.errorText | language }}
                </div>
              </div>
            </div>
            <div class="my-auto" *ngIf="step.button">
              <button
                data-selector="label-manager-config_link_button"
                (click)="step.button.action()"
                class="cs-btn cs-btn-sm cs-btn-primary link-button cs-white-space-nowrap"
                type="button"
              >
                {{ step.button.label | language }}
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-between cs-mt-20 w-100">
      <button
        data-selector="label-manager-config-continue-button"
        (click)="goToConfig()"
        [disabled]="!((_isApproved | async) && (_isLinkSended | async))"
        class="cs-btn cs-btn-primary w-100"
      >
        {{ '_continue' | language }}
      </button>
    </div>
  </div>
</div>

function createCobiroIconPath(name: string) {
  return `/assets/svg-icons/cobiro/icon-${name}.svg`;
}

// Meant for Cobiro-related icons ONLY
export const COBIRO_ICONS_MAP = new Map([
  ['cobiro-logo', createCobiroIconPath('cobiro-logo')],
  ['cobiro-logo-beta', createCobiroIconPath('cobiro-logo-beta')],
  ['cobiro-logo-with-text', createCobiroIconPath('cobiro-logo-with-text')],
]);

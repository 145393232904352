import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitesUserCommand } from './invites-user.command';

export const INVITES_USER_COMMAND = new InjectionToken<InvitesUserCommandPort>(
  'INVITES_USER_COMMAND',
);

export interface InvitesUserCommandPort {
  inviteUser(command: InvitesUserCommand): Observable<void>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CobiroProFillPaymentMethodComponent } from './fill-payment-method.component';
import { PaymentMethodFormComponentModule } from './payment-method-form/payment-method-form.component-module';

@NgModule({
  declarations: [CobiroProFillPaymentMethodComponent],
  imports: [CommonModule, LanguageModule, PaymentMethodFormComponentModule],
  exports: [CobiroProFillPaymentMethodComponent],
})
export class CobiroProFillPaymentMethodComponentModule {}

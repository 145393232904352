/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CancelSubscriptionAtEndOfTermSuccessModalComponentModule } from './cancel-subscription-modals/cancel-subscription-at-end-of-term-success-modal/cancel-subscription-at-end-of-term-success-modal.component-module';
import { CancelSubscriptionImmediatelySuccessModalComponentModule } from './cancel-subscription-modals/cancel-subscription-immediately-success-modal/cancel-subscription-immediately-success-modal.component-module';
import { CancelSubscriptionModalComponentModule } from './cancel-subscription-modals/cancel-subscription-modal/cancel-subscription-modal.component-module';
import { ChangePaymentPlanModalComponentModule } from './change-payment-plan-modal/change-payment-plan-modal.component-module';
import { ClientDeleteConfirmationModalComponent } from './delete-client-modals/client-delete-confirmation-modal/client-delete-confirmation-modal.component';
import { ClientNotDeletableModalComponent } from './delete-client-modals/client-not-deletable-modal/client-not-deletable-modal.component';
import { CreateNewClientModalComponentModule } from './create-new-client-modal/create-new-client-modal.component-module';
import { EditClientModalComponentModule } from './edit-client-modal/edit-client-modal.component-module';
import { ProductInfoComponentModule } from './product-info/product-info.component-module';

@NgModule({
  declarations: [ClientDeleteConfirmationModalComponent, ClientNotDeletableModalComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    CancelSubscriptionModalComponentModule,
    CancelSubscriptionAtEndOfTermSuccessModalComponentModule,
    CancelSubscriptionImmediatelySuccessModalComponentModule,
    CreateNewClientModalComponentModule,
    EditClientModalComponentModule,
    ChangePaymentPlanModalComponentModule,
    ProductInfoComponentModule,
  ],
})
export class ClientModalsModule {}

<header class="d-flex flex-column justify-content-center align-items-center">
  <h2 class="cs-title-3 cs-mt-15" data-selector="signup-verify-email-title">
    {{ 'signup_verify_code_email' | language }}
  </h2>
  <small class="cs-text-2 text-center cs-mt-20 cs-mb-40">
    {{ 'signup_verify_code' | language }}
    {{ '_click' | language }}
    <a
      class="cs-link"
      data-selector="register-resend-code"
      data-analytics="user-register-resend-code"
      (click)="onResendClicked()"
    >
      {{ '_here' | language }}
    </a>
    {{ 'signup_verify_code_to_resend' | language }}
  </small>
</header>

<form class="cs-form cs-ph-80-lg cs-ph-20-sm">
  <div class="cs-mt-15">
    <input
      libDigitsInput
      (digitsPasted)="submitCode()"
      (keydown.enter)="onEnterPressed($event)"
      maxlength="5"
      class="cs-input form-control-large text-center"
      [formControl]="codeControl"
      data-selector="register-input-code"
    />
  </div>

  <button
    class="cs-btn cs-btn-primary w-100 d-flex align-items-center justify-content-center cs-mt-40"
    [disabled]="codeControl.invalid"
    [huiLoading]="isProcessing"
    (click)="submitCode()"
    type="button"
    data-selector="register-submit-verify-code"
    [libAnalytics]="['sign-up-step-3', 'submit']"
  >
    {{ '_submit' | language }}
  </button>
</form>

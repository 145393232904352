import {
  Directive,
  ElementRef,
  Inject,
  Optional,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  Component,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors } from '@angular/forms';
import { HuiTextErrorComponent } from '../../components/text-error/hui-text-error.component';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HuiInputErrorContainerDirective } from './hui-input-error-container.directive';
import { GETS_LANGUAGE } from '@app.cobiro.com/common/language';
import { INPUT_ERRORS } from '@app.cobiro.com/shared/hui/input/directives/input-error/defult-input-errors';

// TODO: Change the styling of this component later on to match Bifrost - it can be reused nicely
@Directive({
  selector: '[formControl], [formControlName]',
})
export class HuiInputErrorDirective implements OnInit, OnDestroy {
  @Input() huiInputError: string;
  private _onDestroyed$ = new Subject<void>();
  private _textErrorComponentRef: ComponentRef<HuiTextErrorComponent>;
  private _containerError: ViewContainerRef;
  private readonly _domElement;

  constructor(
    @Inject(INPUT_ERRORS) private readonly _inputErrors,
    @Inject(GETS_LANGUAGE) private readonly _getLanguage,
    @Optional() private readonly _inputErrorContainer: HuiInputErrorContainerDirective,
    private readonly _controlDir: NgControl,
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._domElement = this._elementRef.nativeElement;
    this._containerError = _inputErrorContainer
      ? _inputErrorContainer.viewContainerRef
      : this._viewContainerRef;
  }
  @HostListener('blur', ['$event.target'])
  onFocus() {
    if (!this.control.value && this._domElement.attributes.huiinputerror) {
      const text = this._getDefaultError(this.control.errors) || this.huiInputError;
      this._addErrorMessage(text);
    } else {
      this._addErrorMessage(null);
    }
  }
  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntil(this._onDestroyed$),
        tap(() => {
          if (this.control.errors && this._domElement.attributes.huiinputerror) {
            const text = this._getDefaultError(this.control.errors) || this.huiInputError;
            this._addErrorMessage(text);
          } else {
            this._addErrorMessage(null);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroyed$.next();
    this._onDestroyed$.complete();
  }

  get control(): AbstractControl {
    return this._controlDir.control;
  }

  private _getDefaultError(controlErrors: ValidationErrors): string | undefined {
    const firstKey = Object.keys(controlErrors)[0];
    const hasError = this._inputErrors[firstKey];

    if (hasError) {
      const text = hasError(controlErrors[firstKey]);
      if (this._getLanguage) {
        const parameters =
          controlErrors[firstKey]?.requiredLength ||
          controlErrors[firstKey]?.min ||
          controlErrors[firstKey]?.max;
        return this._getLanguage.get(text, `${parameters}`);
      }
      return text;
    }
    return undefined;
  }

  private _addErrorMessage(text: string | null): void {
    if (this.prependInputCase(text)) {
      return;
    }

    if (
      !this._textErrorComponentRef &&
      !this._domElement.parentElement.querySelector('hui-control-error')
    ) {
      const factory = this._componentFactoryResolver.resolveComponentFactory(HuiTextErrorComponent);
      this._textErrorComponentRef = this._containerError.createComponent(factory);
    }
    if (this._textErrorComponentRef?.instance) {
      this._textErrorComponentRef.instance.text = text;
    }
  }

  prependInputCase(text: string) {
    const wrapperElement = this._domElement.parentElement.parentElement;

    if (
      this._domElement.classList.contains('cs-input-with-prepended-box') &&
      !wrapperElement?.querySelector('.hui-error') &&
      text
    ) {
      const factory = this._componentFactoryResolver.resolveComponentFactory(EmptyHolderComponent);
      this._containerError.createComponent(factory);

      const error = this._renderer.createElement('div');
      this._renderer.addClass(error, 'hui-error');
      this._renderer.createText(text);

      error.appendChild(this._renderer.createText(text));

      this._domElement.parentElement.insertAdjacentElement('afterend', error);

      return true;
    }

    if (
      wrapperElement?.querySelector('.hui-error')?.classList[0] === 'hui-error' &&
      wrapperElement?.querySelector('div.hui-error') &&
      text === null
    ) {
      this._renderer.removeChild(wrapperElement, wrapperElement?.querySelector('.hui-error'));
      return true;
    }

    if (wrapperElement?.querySelector('div.hui-error') && text) {
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'empty-holder-component',
  template: '',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .hui-error {
        font-size: 12px;
        line-height: 18px;
        padding-top: 3px;
        color: #dc1c03;
        position: absolute;
        animation: dropIn 300ms ease-in, fadeIn 300ms ease-in;
      }
    `,
  ],
})
export class EmptyHolderComponent {}

import { NgModule } from '@angular/core';
import { ClientsListChangedEventDispatcher } from '../../../adapters/secondary/dispatchers/clients-list-changed.event-dispatcher';
import { GETS_ALL_CLIENTS_PLANS_QUERY } from '../../ports/primary/clients/gets-all-clients-plans.query-port';
import { GETS_CLIENTS_LIST_PAGINATION_QUERY } from '../../ports/primary/clients/gets-clients-list-pagination.query-port';
import { GETS_CLIENTS_LIST_SELECTION_QUERY } from '../../ports/primary/clients/gets-clients-list-selection.query-port';
import { GETS_PAGINATED_CLIENTS_QUERY } from '../../ports/primary/clients/gets-paginated-clients.query-port';
import { IS_ALL_SELECTED_CLIENTS_COMMAND } from '../../ports/primary/clients/is-all-selected-clients.command-port';
import { LOAD_CLIENTS_COMMAND } from '../../ports/primary/clients/load-clients.command-port';
import { RESETS_CLIENTS_LIST_PAGINATION_COMMAND } from '../../ports/primary/clients/resets-clients-list-pagination.command-port';
import { SETS_CLIENTS_LIST_FILTER_COMMAND } from '../../ports/primary/clients/sets-clients-list-filter.command-port';
import { SETS_CLIENTS_LIST_PAGE_COMMAND } from '../../ports/primary/clients/sets-clients-list-page.command-port';
import { SETS_CLIENTS_LIST_SORT_COMMAND } from '../../ports/primary/clients/sets-clients-list-sort.command-port';
import { SETS_CLIENTS_LIST_SEARCH_PHRASE_COMMAND } from '../../ports/primary/clients/sets-search-phrase.command-port';
import { TOGGLE_ALL_CLIENTS_COMMAND } from '../../ports/primary/clients/toggle-all-clients.command-port';
import { TOGGLE_CLIENT_COMMAND } from '../../ports/primary/clients/toggle-client.command-port';
import { CLIENTS_LIST_CHANGED_DISPATCHER } from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientsListState } from './clients-list.state';

@NgModule({
  providers: [
    ClientsListState,
    { provide: LOAD_CLIENTS_COMMAND, useExisting: ClientsListState },
    { provide: GETS_PAGINATED_CLIENTS_QUERY, useExisting: ClientsListState },
    { provide: GETS_CLIENTS_LIST_PAGINATION_QUERY, useExisting: ClientsListState },
    { provide: GETS_CLIENTS_LIST_SELECTION_QUERY, useExisting: ClientsListState },
    { provide: IS_ALL_SELECTED_CLIENTS_COMMAND, useExisting: ClientsListState },
    { provide: TOGGLE_ALL_CLIENTS_COMMAND, useExisting: ClientsListState },
    { provide: TOGGLE_CLIENT_COMMAND, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_SORT_COMMAND, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_PAGE_COMMAND, useExisting: ClientsListState },
    { provide: RESETS_CLIENTS_LIST_PAGINATION_COMMAND, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_FILTER_COMMAND, useExisting: ClientsListState },
    { provide: SETS_CLIENTS_LIST_SEARCH_PHRASE_COMMAND, useExisting: ClientsListState },
    { provide: GETS_ALL_CLIENTS_PLANS_QUERY, useExisting: ClientsListState },
    { provide: CLIENTS_LIST_CHANGED_DISPATCHER, useClass: ClientsListChangedEventDispatcher },
  ],
})
export class ClientsListStateModule {}

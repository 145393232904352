import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GOOGLE_MAPS_KEY } from '../google-maps-key';

@Injectable()
export class GoogleMapsLoaderService {
  constructor(private _http: HttpClient, @Inject(GOOGLE_MAPS_KEY) private _mapsKey: string) {}

  load(): Observable<boolean> {
    return this._http
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${this._mapsKey}`, 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }
}

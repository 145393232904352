import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesWorkspaceDto } from './creates-workspace.dto';
import { WorkspaceDto } from './workspace.dto';

export const CREATES_WORKSPACE_DTO = new InjectionToken<CreatesWorkspaceDtoPort>(
  'CREATES_WORKSPACE_DTO',
);

export interface CreatesWorkspaceDtoPort {
  createWorkspace(dto: CreatesWorkspaceDto): Observable<Pick<WorkspaceDto, 'id' | 'name'>>;
}

<div
  class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center w-100 bg-white"
>
  <div class="cs-emoji-circle cs-emoji-circle-secondary cs-mb-30">💳</div>
  <h3 class="cs-title-4 cs-mb-20 text-center">
    {{ 'cobiro_pro_payment_no_set-title' | language }}
  </h3>
  <p class="cs-text-1 cs-mb-30 text-center cs-white-space-pre-line">
    {{ 'cobiro_pro_payment_no_set-content' | language }}
  </p>
  <a [routerLink]="redirect">
    <button
      class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-primary"
      data-selector="hui-simple-confirmation-popup-btn"
      matDialogClose
    >
      {{ 'cobiro_pro_payment_no_set-action-text' | language }}
    </button>
  </a>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GETS_CANCEL_REASON_QUERY,
  GetsCancelReasonQueryPort,
} from '../../../../../../application/ports/primary/clients/gets-cancel-reason.query-port';

@Component({
  selector: 'lib-cobiro-pro-cancel-subscription-modal',
  templateUrl: './cancel-subscription-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelSubscriptionModalComponent {
  endOfTerm = true;
  selectedCancelReason = '';
  comment = '';
  isCommentAreaShown = false;

  readonly cancelReasons$ = this.getsCancelReasonQuery.getCancelReasonList();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(GETS_CANCEL_REASON_QUERY)
    private readonly getsCancelReasonQuery: GetsCancelReasonQueryPort,
    private readonly dialogRef: MatDialogRef<CancelSubscriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { productName: string },
  ) {}

  onCancelAtEndOfTermChanged(value: string): void {
    this.endOfTerm = Boolean(parseInt(value));
    this.changeDetectorRef.markForCheck();
  }

  onCancelReasonSelect(value: string): void {
    this.selectedCancelReason = value;
    this.isCommentAreaShown = [
      'site_cancel_subscription_popup_select_reason_other',
      'site_cancel_subscription_popup_select_reason_product_didnt_do_what_i_expected',
      'site_cancel_subscription_popup_select_didnt_use_product_enough',
    ].includes(value);
    this.changeDetectorRef.markForCheck();
  }

  onCancelSubscription(): void {
    if (!this.selectedCancelReason) {
      return;
    }

    this.dialogRef.close({
      endOfTerm: this.endOfTerm,
      reason: this.selectedCancelReason,
      comment: this.comment,
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UPDATES_DETAILS } from './domain/updates-details';
import { HttpUpdatesDetailsService } from './infrastructure/http-updates-details.service';
import { GETS_SETTINGS } from './domain/gets-settings';
import { HttpGetsSettingsService } from './infrastructure/http-gets-settings.service';
import { GETS_DETAILS } from './domain/gets-details';
import { HttpGetsDetailsService } from './infrastructure/http-gets-details.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: UPDATES_DETAILS,
      useClass: HttpUpdatesDetailsService,
    },
    {
      provide: GETS_DETAILS,
      useClass: HttpGetsDetailsService,
    },
    {
      provide: GETS_SETTINGS,
      useClass: HttpGetsSettingsService,
    },
  ],
})
export class UserSettingsModule {}

import { Injectable } from '@angular/core';
import { TrackingDataCollectedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { TrackingService } from '../tracking.service';

@Injectable()
export class TrackingDataCollectedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [TrackingDataCollectedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _trackingService: TrackingService) {}

  handle([event]: [TrackingDataCollectedEvent]): void {
    this._trackingService.event(event.code, event.data);
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CompleteProfileUserChangedEventHandler } from './adapters/user-changed/complete-profile-user-changed.event-handler';
import { CompleteProfileState } from './application/complete-profile.state';

@NgModule({
  providers: [CompleteProfileState],
})
export class UserCompleteProfileHandlersModule {
  static forRoot(): ModuleWithProviders<UserCompleteProfileHandlersModule> {
    return {
      ngModule: UserCompleteProfileHandlersModule,
      providers: [provideApplicationEventsHandler(CompleteProfileUserChangedEventHandler)],
    };
  }
}

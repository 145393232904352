<form
  [formGroup]="discountCodeForm"
  *libFeatureFlags="['payment_plans_discount_codes']"
  class="cs-form"
>
  <div class="cs-mt-30">
    <label class="cs-label">
      {{ 'payment_plans_coupon_code' | language }}
    </label>

    <div class="d-flex">
      <input
        class="cs-input"
        type="text"
        [placeholder]="'payment_plans_discount_code_placeholder' | language"
        formControlName="discountCode"
        data-selector="payments-discount-code-input"
      />
      <button
        class="cs-btn cs-btn-flat cs-ml-10 cs-width-240-xs"
        *ngIf="!discountCodeForm.get('isValid').value"
        (click)="onDiscountApply()"
        type="submit"
        [huiLoading]="discountCodeProcessingInProgress$ | async"
        [disabled]="
          (discountCodeProcessingInProgress$ | async) || !discountCodeForm.get('discountCode').value
        "
        [libAnalytics]="['payment-modal', 'apply-discount-code']"
        data-selector="payments-discount-apply"
      >
        {{ 'payment_plans_apply' | language }}
      </button>
      <button
        class="cs-btn cs-btn-flat cs-ml-10 cs-width-240-xs"
        *ngIf="discountCodeForm.get('isValid').value"
        (click)="onDiscountRemove()"
        [libAnalytics]="['payment-modal', 'remove-discount-code']"
        data-selector="payments-discount-remove"
      >
        {{ 'payment_plans_remove_discount_code' | language }}
      </button>
    </div>
  </div>
</form>

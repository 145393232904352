import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementWidgetComponent } from './announcement-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AnnouncementWidgetComponent],
  imports: [CommonModule, MatIconModule, RouterModule],
  exports: [AnnouncementWidgetComponent],
})
export class AnnouncementWidgetComponentModule {}

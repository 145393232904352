import { NgModule } from '@angular/core';
import { GETS_CURRENT_PLAN_DTO_PORT } from '../../../application/ports/secondary/gets-current-plan.dto-port';
import { HttpCurrentPlanService } from './http-current-plan.service';

@NgModule({
  providers: [
    HttpCurrentPlanService,
    { provide: GETS_CURRENT_PLAN_DTO_PORT, useExisting: HttpCurrentPlanService },
  ],
})
export class HttpCurrentPlanServiceModule {}

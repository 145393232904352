import { NgModule } from '@angular/core';
import { NotAllowedModalComponent } from '../ui/not-allowed-modal/not-allowed-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CommonModule } from '@angular/common';
import { CheckAccessToAppGuard } from './check-access-to-app.guard';

@NgModule({
  imports: [MatDialogModule, MatIconModule, LanguageModule, CommonModule],
  providers: [CheckAccessToAppGuard],
  declarations: [NotAllowedModalComponent],
})
export class CheckAccessToAppGuardModule {}

import { Component } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import {
  PaymentPlansState,
  UPGRADE_PLAN_STEPS,
  UpgradePlanProcess,
} from '@app.cobiro.com/payment-plans';

@Component({
  selector: 'lib-select-card-step',
  templateUrl: './select-card-step.component.html',
  styleUrls: ['./select-card-step.component.scss'],
})
export class SelectCardStepComponent {
  cardProcessingInProgress$: Observable<boolean> =
    this._process.cardProcessingInProgress$.asObservable();
  hasSelectedCard$: Observable<boolean> = this._process.hasSelectedCard$;

  constructor(
    private _paymentPlansState: PaymentPlansState,
    private _process: UpgradePlanProcess,
  ) {}

  async onSubscribeClicked(): Promise<void> {
    const selectedPlan = await firstValueFrom(this._paymentPlansState.selectedPlan$);
    this._process.nextStep(UPGRADE_PLAN_STEPS.CARD_SELECTION, {
      planName: selectedPlan.name,
      planPeriod: selectedPlan.period,
    });
  }
}

import { DomainEvent } from '../domain/event';

export class PaymentBillingEvent extends DomainEvent {
  constructor(
    public readonly siteId: string | null,
    public readonly workspaceId: string,
    public readonly agencyName: string,
    public readonly contactEmail: string,
    public readonly addressLine1: string,
    public readonly addressLine2: string | null,
    public readonly zipCode: string,
    public readonly city: string,
    public readonly countryCode: string,
    public readonly vatNumber: string | null,
    public readonly avatar: string | null,
  ) {
    super();
  }
}

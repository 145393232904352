import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HuiFilePickerDirective } from './hui-file-picker.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HuiFilePickerDirective],
  exports: [HuiFilePickerDirective],
})
export class HuiFilePickerModule {}

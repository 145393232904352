import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IntegrationCreateData {
  siteId: string;
  websiteName: string;
  adsId: string;
  managerEmail: string;
  cssDomainId: string;
}

export const CREATES_INTEGRATION_DTO = new InjectionToken<CreatesIntegrationDtoPort>(
  'CREATES_INTEGRATION_DTO',
);

export interface CreatesIntegrationDtoPort {
  createIntegration(data: IntegrationCreateData): Observable<boolean>;
}

import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedImageUploadModule } from '@app.cobiro.com/shared/image-upload';
import { ChangePaymentPlanModalComponent } from './change-payment-plan-modal.component';
import { CobiroProFillBillingAddressComponentModule } from './upgrade-plan-step/fill-billing-address/fill-billing-address.component-module';
import { CobiroProFillPaymentMethodComponentModule } from './upgrade-plan-step/fill-payment-method/fill-payment-method.component-module';
import { CobiroProSelectPlanModule } from './upgrade-plan-step/select-plan/select-plan.component-module';
import { StepContentDirective } from './upgrade-plan-step/upgrade-plan-step-content.directive';
import { CobiroProUpgradePlanStepComponent } from './upgrade-plan-step/upgrade-plan-step.component';
import { CobiroProUpradePlanModule } from './upgrade-plan-step/upgrade-plan/upgrade-plan.component-module';
import { CobiroProUpgradePlanStepperComponent } from './upgrade-plan-stepper/upgrade-plan-stepper.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    SharedImageUploadModule,
    CdkStepperModule,
    PortalModule,
    CobiroProSelectPlanModule,
    CobiroProUpradePlanModule,
    CobiroProFillBillingAddressComponentModule,
    CobiroProFillPaymentMethodComponentModule,
  ],
  declarations: [
    ChangePaymentPlanModalComponent,
    CobiroProUpgradePlanStepperComponent,
    CobiroProUpgradePlanStepComponent,
    StepContentDirective,
  ],
  exports: [
    ChangePaymentPlanModalComponent,
    CobiroProUpgradePlanStepperComponent,
    CobiroProUpgradePlanStepComponent,
    StepContentDirective,
  ],
})
export class ChangePaymentPlanModalComponentModule {}

<div>
  <form [formGroup]="form" (submit)="onFormSubmitted()" class="cs-form">
    <div class="d-flex row cs-mb-20">
      <div class="col-md-8">
        <div class="cs-card">
          <ng-container *huiLoaderContainer="details$ | async as details">
            <div class="cs-mb-30">
              <label class="cs-label">{{ '_email' | language }}</label>
              <div class="cs-input d-flex align-items-center cs-b-1 cs-b-shade-10 opacity-50">
                {{ details.email }}
              </div>
            </div>

            <div class="cs-mb-30">
              <label class="cs-label"
                ><span>{{ '_first_name' | language }} </span>
                <span class="cs-c-error">*</span></label
              >
              <input
                class="cs-input"
                type="text"
                formControlName="firstName"
                data-selector="first-name-input"
                huiInputError="firstName"
              />
            </div>

            <div class="cs-mb-30">
              <label class="cs-label"
                ><span>
                  {{ '_last_name' | language }}
                </span>
                <span class="cs-c-error">*</span></label
              >
              <input
                class="cs-input"
                type="text"
                formControlName="lastName"
                data-selector="last-name-input"
                huiInputError="lastName"
              />
            </div>

            <div class="cs-control-wrapper cs-mb-30">
              <label class="cs-label">
                <span>
                  {{ '_country' | language }}
                </span>
                <span class="cs-c-error">*</span>
              </label>
              <input
                type="text"
                class="cs-input"
                formControlName="country"
                [matAutocomplete]="countries"
                data-selector="team-settings-form-country"
              />
              <mat-autocomplete #countries="matAutocomplete" [displayWith]="displayCountryName">
                <mat-option *ngFor="let country of countries$ | async" [value]="country">
                  {{ country.name }}
                </mat-option>
              </mat-autocomplete>
              <p
                *ngIf="form.controls.country.invalid && form.controls.country.touched"
                class="cs-control-error"
              >
                {{
                  (form.controls.country.hasError('invalidCountry')
                    ? '_invalid_country'
                    : '_this_field_is_required'
                  ) | language
                }}
              </p>
            </div>

            <div class="cs-control-wrapper cs-mb-40">
              <label class="cs-label" for="phone-number-input">
                {{ '_phone_number' | language }}
              </label>
              <input
                class="cs-input"
                data-selector="profile-phone-number-input"
                id="phone-number-input"
                formControlName="phoneNumber"
              />
            </div>
          </ng-container>

          <div class="buttons d-flex justify-content-end">
            <div class="profile-save-button">
              <button
                class="cs-btn cs-btn-primary cs-width-100-lg"
                type="submit"
                [disabled]="form.invalid || form.pristine"
                [class.is-loading]="isProcessing$ | async"
                data-selector="settings-save-button"
                data-analytics="profilesettings-save"
              >
                {{ '_save' | language }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="cs-card">
          <ng-container *huiLoaderContainer="details$ | async as details">
            <lib-shared-image-upload (imageUrl)="onImageChanged($event)" #uploader>
              <ng-container recommendedSize>1024x1024px</ng-container>
              <ng-container imagePreview>
                <img
                  *ngIf="avatar$ | async as avatar"
                  [src]="avatar"
                  (load)="uploader.previewLoaded = true"
                  class="cs-width-120-xs cs-height-120-xs border-radius-preview cs-br-50-pc"
                />
              </ng-container>
            </lib-shared-image-upload>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>

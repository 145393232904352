import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AddPaymentMethodCommand } from './adds-payment-method.command';

export interface AddsPaymentMethodCommandPort {
  addPaymentMethod(command: AddPaymentMethodCommand): Observable<boolean>;
}

export const ADDS_PAYMENT_METHOD_COMMAND = new InjectionToken<AddsPaymentMethodCommandPort>(
  'ADD_PAYMENT_METHOD_COMMAND',
);

export class AccountStrategist {
  private readonly _strategists = new Map([
    ['free', 'None'],
    ['starter', 'None'],
    ['professional', '30min / month'], // Deprecated
    ['business', '30min / month'],
    ['premium', '1h / month'],
  ]);
  public readonly value: string;

  constructor(planId: string) {
    this.value = this._strategists.get(planId);

    if (!this.value) {
      throw Error(`There is no account strategist for plan ${planId}`);
    }
  }
}

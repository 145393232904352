import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LabelManagerReportQuery } from './label-manager-report.query';

export const GETS_LABEL_MANAGER_REPORT_QUERY_PORT =
  new InjectionToken<GetsLabelManagerReportQueryPort>('GETS_LABEL_MANAGER_REPORT_QUERY_PORT');

export interface GetsLabelManagerReportQueryPort {
  getReport(): Observable<LabelManagerReportQuery>;
}

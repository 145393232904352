import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SideBarOptionQuery } from './side-bar-option.query';
import { UserDetailsQuery } from './user-details.query';

export const GETS_SIDE_BAR_OPTIONS_QUERY = new InjectionToken<GetsSideBarOptionsQueryPort>(
  'GETS_SIDE_BAR_OPTIONS_QUERY',
);

export interface GetsSideBarOptionsQueryPort {
  getSideBarOptions(user: UserDetailsQuery): Observable<SideBarOptionQuery[]>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FormStatusQuery } from './form-status.query';

export const GETS_FORM_STATUS_QUERY = new InjectionToken<GetsFormStatusQueryPort>(
  'GETS_FORM_STATUS_QUERY',
);

export interface GetsFormStatusQueryPort {
  getFormStatusQuery(): Observable<FormStatusQuery>;
}

import { NgModule } from '@angular/core';
import { CreateWorkspaceModalComponentModule } from '../../adapters/primary/ui/modals/create-workspace-modal/create-workspace-modal.component-module';
import { DeleteWorkspaceModalComponentModule } from '../../adapters/primary/ui/modals/delete-workspace-modal/delete-workspace-modal.component-module';
import { EditWorkspaceModalComponentModule } from '../../adapters/primary/ui/modals/edit-workspace-modal/edit-workspace-modal.component-module';
import { CREATES_WORKSPACE_COMMAND } from '../ports/primary/creates-workspace.command-port';
import { DELETE_WORKSPACE_COMMAND } from '../ports/primary/deletes-workspace.command-port';
import { EDIT_WORKSPACE_COMMAND } from '../ports/primary/edits-workspace.command-port';
import { GETS_MEMBER_WORKSPACES_LIST_QUERY } from '../ports/primary/gets-member-workspace-list.query-port';
import { GETS_SELECTED_WORKSPACE_QUERY } from '../ports/primary/gets-selected-workspace.query-port';
import { GETS_WORKSPACES_LIST_QUERY } from '../ports/primary/gets-workspace-list.query';
import { GETS_WORKSPACES_QUERY } from '../ports/primary/gets-workspaces.query';
import { HAS_SUBSCRIPTIONS_QUERY } from '../ports/primary/has-subscriptions.query-port';
import { LOADS_WORKSPACES_QUERY } from '../ports/primary/loads-workspaces.query-port';
import { SETS_WORKSPACE_LIST_SEARCH_PHRASE_COMMAND_PORT } from '../ports/primary/sets-search-phrase.command-port';
import { SWITCHES_WORKSPACE_COMMAND } from '../ports/primary/switches-workspace.command-port';
import { WorkspacesState } from './workspaces.state';
import { IS_CURRENT_WORKSPACE_COMMAND } from '../ports/primary/is-current-workspace.command-port';
import { GETS_ALL_TEAM_QUERY } from '../ports/primary/gets-all-teams.query-port';
import { GETS_MEMBER_WORKSPACES_LIST_BY_TEAM_QUERY } from '../ports/primary/gets-member-workspace-list-by-team.query-port';

@NgModule({
  imports: [
    CreateWorkspaceModalComponentModule,
    EditWorkspaceModalComponentModule,
    DeleteWorkspaceModalComponentModule,
  ],
  providers: [
    WorkspacesState,
    {
      provide: GETS_SELECTED_WORKSPACE_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: GETS_WORKSPACES_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: LOADS_WORKSPACES_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: SETS_WORKSPACE_LIST_SEARCH_PHRASE_COMMAND_PORT,
      useExisting: WorkspacesState,
    },
    {
      provide: GETS_WORKSPACES_LIST_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: CREATES_WORKSPACE_COMMAND,
      useExisting: WorkspacesState,
    },
    {
      provide: EDIT_WORKSPACE_COMMAND,
      useExisting: WorkspacesState,
    },
    {
      provide: DELETE_WORKSPACE_COMMAND,
      useExisting: WorkspacesState,
    },
    {
      provide: HAS_SUBSCRIPTIONS_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: GETS_MEMBER_WORKSPACES_LIST_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: SWITCHES_WORKSPACE_COMMAND,
      useExisting: WorkspacesState,
    },
    {
      provide: IS_CURRENT_WORKSPACE_COMMAND,
      useExisting: WorkspacesState,
    },
    {
      provide: GETS_ALL_TEAM_QUERY,
      useExisting: WorkspacesState,
    },
    {
      provide: GETS_MEMBER_WORKSPACES_LIST_BY_TEAM_QUERY,
      useExisting: WorkspacesState,
    },
  ],
})
export class WorkspacesStateModule {}

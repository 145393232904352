import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { GtmTrackerService } from './tracker/gtm-tracker.service';

interface UserInterface {
  id: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private userId: string;

  constructor(
    private gtmTracking: GtmTrackerService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  assignUser(id: string) {
    this.userId = id;
  }

  signUp(user: UserInterface) {
    this.gtmTracking.signUp(user);
  }

  login(user: UserInterface) {
    this.gtmTracking.login(user);
  }

  logout() {
    this.gtmTracking.logout();
  }

  resetPassword(user: UserInterface) {
    this.gtmTracking.resetPassword(user);
  }

  event(code: string, data?: any) {
    this.gtmTracking.event(this.userId, code, data);
  }

  trackLink(id: string, url: string): string {
    return this.trackAnalyticsLink(url);
  }

  private trackAnalyticsLink(url: string): string {
    // tslint:disable-next-line
    return this.window['ga']?.('linker:decorate', url) || url;
  }
}

import { PaymentPlanPriceVO } from './payment-plan-prices.vo';
import { PaymentPlanPeriod } from './payment-plan';

const DEFAULT_PRICE_VALUE = 10;

export const PAYMENT_PLAN_DEFAULT_PRICE = DEFAULT_PRICE_VALUE;

export const PRICES_DATA = {
  values: {
    [PaymentPlanPeriod.MONTHLY]: DEFAULT_PRICE_VALUE,
    [PaymentPlanPeriod.YEARLY]: 100,
  },
  currency: 'USD',
};

export const makePaymentPlanPrice = (selectedPlan: PaymentPlanPeriod) =>
  new PaymentPlanPriceVO(PRICES_DATA.values, selectedPlan, PRICES_DATA.currency);

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'hui-stepper',
  templateUrl: './hui-stepper.component.html',
  styleUrls: ['./hui-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: HuiStepperComponent }],
  // Animation taken from mat-stepper
  animations: [
    trigger('horizontalStepTransition', [
      state('previous', style({ transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden' })),
      state('current', style({ transform: 'none', visibility: 'inherit' })),
      state('next', style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden' })),
      transition('* => *', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')),
    ]),
  ],
})
export class HuiStepperComponent extends CdkStepper {
  private static readonly ANIMATION_DELAY = 200;
  @Input() isAnimated = true;

  // TODO: think of better solution
  @Input() set disableAnimationWithDelay(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.isAnimated = false;
      }, HuiStepperComponent.ANIMATION_DELAY);
    }
  }
  @Output() animationDone = new EventEmitter<AnimationEvent>();

  get isFirstStep(): boolean {
    return this.selectedIndex === 0;
  }

  public goToStep(index: number) {
    if (index >= 0 && index < this.steps.length) {
      this.steps.get(index).select();
    }
  }

  onAnimationEnded($event: AnimationEvent) {
    this.animationDone.emit($event);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CobiroProUserManagementTableComponent } from './user-management-table.component';

@NgModule({
  declarations: [CobiroProUserManagementTableComponent],
  imports: [
    RouterModule,
    CommonModule,
    HuiLoaderContainerModule,
    LanguageModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    FeatureFlagsModule,
    HuiAvatarModule,
    MatTooltipModule,
  ],
  exports: [CobiroProUserManagementTableComponent],
})
export class CobiroProUserManagementTableComponentModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ClientsContext } from '../../../../application/ports/secondary/context/clientsContext';
import { PatchesClientsContextStoragePort } from '../../../../application/ports/secondary/context/patches-clients-context.storage-port';
import { SelectsClientsContextStoragePort } from '../../../../application/ports/secondary/context/selects-clients-context.storage-port';

@Injectable()
export class InMemoryClientsContextStorage
  implements SelectsClientsContextStoragePort, PatchesClientsContextStoragePort
{
  private _subject$: BehaviorSubject<Partial<ClientsContext>> = new BehaviorSubject<
    Partial<ClientsContext>
  >({ list: [], selectedClient: null, isCssSubscriptions: false, isLMSubscriptions: false });

  select(): Observable<Partial<ClientsContext>> {
    return this._subject$
      .asObservable()
      .pipe(filter(context => !!context && !!Object.keys(context).length));
  }

  patch(state: Partial<ClientsContext>): Observable<void> {
    return of(this._subject$.next({ ...this._subject$.value, ...state }));
  }
}

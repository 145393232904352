<div
  class="position-relative d-flex flex-column cs-ph-40 cs-pb-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-edit-client-close-modal"
    (click)="onFormClosed()"
  ></mat-icon>

  <form
    class="cs-form d-flex flex-column w-100 h-100 justify-content-between"
    [formGroup]="clientForm"
  >
    <div class="d-flex flex-column">
      <h3 class="cs-title-4 cs-mb-15">{{ 'cobiro_pro_shops_edit_shop' | language }}</h3>

      <div class="cs-mb-25">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_shop_form_shop_name' | language }}
          <span class="cs-c-error">{{ '_required' | language }}</span>
        </label>
        <input
          formControlName="companyName"
          class="cs-input"
          type="text"
          [placeholder]="'cobiro_pro_rewrite_shop_form_shop_name_placeholder' | language"
        />
        <p
          *ngIf="clientForm.controls.companyName.invalid && clientForm.controls.companyName.touched"
          class="cs-control-error"
        >
          {{ '_this_field_is_required' | language }}
        </p>
      </div>
      <!-- 
      <div class="cs-mb-25">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_rewrite_client_form_contact_email' | language }}
        </label>
        <input
          formControlName="contactEmail"
          class="cs-input"
          type="text"
          [placeholder]="'cobiro_pro_rewrite_client_form_contact_email_placeholder' | language"
        />
        <p
          *ngIf="
            clientForm.controls.contactEmail.invalid && clientForm.controls.contactEmail.touched
          "
          class="cs-control-error"
        >
          {{ '_validation_error_email' | language }}
        </p>
      </div>

      <div class="cs-mb-25">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_client_form_contact_person' | language }}
        </label>
        <input
          formControlName="contactPerson"
          class="cs-input"
          type="text"
          [placeholder]="'cobiro_pro_rewrite_client_form_contact_person_placeholder' | language"
        />
      </div>

      <div class="cs-mb-25">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_rewrite_client_form_contact_phone' | language }}
        </label>
        <input
          formControlName="contactPhone"
          class="cs-input"
          type="tel"
          [placeholder]="'cobiro_pro_rewrite_client_form_contact_phone_placeholder' | language"
        />
        <p
          *ngIf="
            clientForm.controls.contactPhone.invalid && clientForm.controls.contactPhone.touched
          "
          class="cs-control-error"
        >
          {{ '_validation_error_phone' | language }}
        </p>
      </div>

      <div>
        <label class="cs-label">{{ 'cobiro_pro_rewrite_avatar' | language }}</label>
        <lib-shared-image-upload
          (imageUrl)="onFileUploaded($event)"
          class="uploader-component cs-mb-20"
          #uploader
        >
          <ng-container recommendedSize>1024x1024px</ng-container>
          <ng-container imagePreview>
            <img
              *ngIf="imageUrl$ | async as image"
              class="cs-height-120-xs border-radius-preview cs-br-50-pc"
              [src]="image"
              (load)="uploader.previewLoaded = true"
            />
          </ng-container>
        </lib-shared-image-upload>
      </div> -->
    </div>
  </form>

  <button
    [disabled]="clientForm.invalid"
    (click)="onFormSubmitted()"
    class="cs-btn cs-btn-primary w-100"
    type="button"
    data-selector="cobiro-pro-edit-client-edit-btn"
    data-analytics="cobiro-pro-edit-client-edit-btn"
  >
    {{ '_save_changes' | language }}
  </button>
</div>

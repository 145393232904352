import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientQuery } from './client.query';

export const GETS_EDIT_CLIENT_QUERY = new InjectionToken<GetsEditClientQueryPort>(
  'GETS_EDIT_CLIENT_QUERY',
);

export interface GetsEditClientQueryPort {
  getEditClientQuery(): Observable<ClientQuery>;
}

/* eslint-disable complexity */
import { IntegrationErrorDto } from '../secondary/integration-error.dto';

const GOOGLE_CSS_INTEGRATION_ERROR_MAP = {
  ['EMAIL_IS_NOT_LINKED_TO_ELIGIBLE_GOOGLE_ACCOUNT']: {
    title: 'google_css_integration_error_title_1',
    message: 'google_css_integration_error_message_1',
  },
  ['EMAIL_IS_INVALID']: {
    title: 'google_css_integration_error_title_2',
    message: 'google_css_integration_error_message_2',
  },
  ['GOOGLE_ADS_ACCOUNT_NOT_FOUND']: {
    title: 'google_css_integration_error_title_3',
    message: 'google_css_integration_error_message_3',
  },
  ['DUPLICATE_CHILD_FOUND']: {
    title: 'google_css_integration_error_title_4',
    message: 'google_css_integration_error_message_4',
  },
  ['EMAIL_IS_ASSOCIATED_WITH_TOO_MANY_ACCOUNTS']: {
    title: 'google_css_integration_error_title_5',
    message: 'google_css_integration_error_message_5',
  },
  ['UNEXPECTED_ERROR']: {
    title: 'google_css_integration_error_title_6',
    message: 'google_css_integration_error_message_6',
  },
  ['INTEGRATION_IN_TEMPORARY_STATE']: {
    title: 'INTEGRATION_IN_TEMPORARY_STATE',
    message: 'INTEGRATION_IN_TEMPORARY_STATE',
  },
};

export class IntegrationErrorQuery {
  constructor(public readonly title: string, public readonly message: string) {}
  static fromDTO(dto: IntegrationErrorDto): IntegrationErrorQuery {
    return new IntegrationErrorQuery(
      GOOGLE_CSS_INTEGRATION_ERROR_MAP[dto.code]?.title ?? 'Unknown error',
      GOOGLE_CSS_INTEGRATION_ERROR_MAP[dto.code]?.message ?? 'Unknown error message',
    );
  }
}

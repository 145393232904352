import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PlanEstimateQuery } from './plan-estimate.query';

export interface GetsPlanEstimateQueryPort {
  getPlanEstimate(): Observable<PlanEstimateQuery>;
}

export const GETS_PLAN_ESTIMATE_QUERY = new InjectionToken<GetsPlanEstimateQueryPort>(
  'GETS_PLAN_ESTIMATE_QUERY',
);

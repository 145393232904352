import { NgModule } from '@angular/core';
import { HttpWorkspacesService } from './http-workspaces.service';
import { HAS_SUBSCRIPTIONS_DTO } from '../../../../application/ports/secondary/has-subscriptions.dto-port';
import { GETS_ALL_WORKSPACES_DTO } from '../../../../application/ports/secondary/gets-all-workspaces.dto-port';

@NgModule({
  providers: [
    HttpWorkspacesService,
    { provide: GETS_ALL_WORKSPACES_DTO, useExisting: HttpWorkspacesService },
    { provide: HAS_SUBSCRIPTIONS_DTO, useExisting: HttpWorkspacesService },
  ],
})
export class HttpWorkspacesServiceModule {}

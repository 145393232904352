/* eslint-disable max-lines-per-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { cardExpirationValidator } from '@app.cobiro.com/shared/validators';
import { UIState } from '@app.cobiro.com/shared/ui-state';
import {
  ADDS_PAYMENT_METHOD_COMMAND,
  AddsPaymentMethodCommandPort,
} from '../../../../application/ports/primary/adds-payment-method.command-port';
import {
  GETS_PAYMENT_METHOD_PROCESSING_QUERY,
  GetsPaymentMethodProcessingQueryPort,
} from '../../../../application/ports/primary/gets-payment-method-processing.query-port';
import {
  GETS_ADD_PAYMENT_METHOD_SUCCEED_QUERY,
  GetsAddPaymentMethodSucceedQueryPort,
} from '../../../../application/ports/primary/gets-add-payment-method-succeed.query-port';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  LOADS_PAYMENT_SOURCES_COMMAND_PORT,
  LoadsPaymentSourcesCommandPort,
} from '../../../../application/ports/primary/loads-payment-sources.command-port';
import {
  GETS_PAYMENT_SOURCES_LOADING_QUERY_PORT,
  GetsPaymentSourcesLoadingQueryPort,
} from '../../../../application/ports/primary/gets-payment-sources-loading.query-port';
import { PaymentSourceQuery } from '../../../../application/ports/primary/payment-source.query';
import {
  GETS_PAYMENT_SOURCES_QUERY_PORT,
  GetsPaymentSourcesQueryPort,
} from '../../../../application/ports/primary/gets-payment-sources.query-port';
import { HasId } from '@app.cobiro.com/core/utils';
import {
  GetsCardValidationQueryPort,
  GETS_CARD_VALIDATION_QUERY_PORT,
} from '../../../../application/ports/primary/gets-card-validation-error.query-port';
import {
  ConfirmReplaceCardMessageCommandPort,
  CONFIRM_REPLACE_CARD_MESSAGE_COMMAND_PORT,
} from '../../../../application/ports/primary/confirm-replace-card-message-command.port';
import {
  ReplacesPaymentMethodCommandPort,
  REPLACES_PAYMENT_METHOD_COMMAND,
} from '../../../../application/ports/primary/replaces-payment-method.command-port';

const IS_ADDING_PAYMENT_METHOD_STATE = new InjectionToken<UIState<boolean>>(
  'IS_ADDING_PAYMENT_METHOD',
  {
    factory: () => new UIState<boolean>(false),
  },
);

const IS_REPLACING_PAYMENT_METHOD_STATE = new InjectionToken<UIState<boolean>>(
  'IS_REPLACING_PAYMENT_METHOD',
  {
    factory: () => new UIState<boolean>(false),
  },
);
@Component({
  selector: 'lib-team-payment-methods',
  templateUrl: './team-payment-methods.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamPaymentMethodsComponent implements OnInit, OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  readonly cardForm = this._fb.group({
    cardOwner: ['', Validators.required],
    cardNumber: ['', Validators.required],
    cvv: ['', Validators.required],
    expirationDate: ['', [Validators.required, cardExpirationValidator]],
  });
  readonly isAddingPaymentMethod$: Observable<boolean> = this._isAddingPaymentMethodState.select();
  readonly isReplacingPaymentMethod$: Observable<boolean> =
    this._isReplacingPaymentMethodState.select();
  readonly isProcessing$ = this._getPaymentMethodProcessing.getPaymentMethodProcessingQuery();
  readonly isPaymentSourcesListLoading$ =
    this._getsPaymentSourcesLoadingQueryPort.getPaymentSourcesLoadingQuery();
  readonly paymentSourcesQuery$: Observable<PaymentSourceQuery[]> =
    this._getsPaymentSourcesQueryPort.getPaymentSourcesQuery();

  constructor(
    @Inject(IS_ADDING_PAYMENT_METHOD_STATE)
    private readonly _isAddingPaymentMethodState: UIState<boolean>,
    @Inject(IS_REPLACING_PAYMENT_METHOD_STATE)
    private readonly _isReplacingPaymentMethodState: UIState<boolean>,
    @Inject(ADDS_PAYMENT_METHOD_COMMAND)
    private readonly _addPaymentMethodCommand: AddsPaymentMethodCommandPort,
    @Inject(REPLACES_PAYMENT_METHOD_COMMAND)
    private readonly _replacePaymentMethodCommand: ReplacesPaymentMethodCommandPort,
    @Inject(GETS_PAYMENT_METHOD_PROCESSING_QUERY)
    private readonly _getPaymentMethodProcessing: GetsPaymentMethodProcessingQueryPort,
    @Inject(GETS_ADD_PAYMENT_METHOD_SUCCEED_QUERY)
    private readonly _getsAddPaymentMethodSucceedQueryPort: GetsAddPaymentMethodSucceedQueryPort,
    @Inject(LOADS_PAYMENT_SOURCES_COMMAND_PORT)
    private readonly _loadPaymentSourcesCommandPort: LoadsPaymentSourcesCommandPort,
    @Inject(GETS_PAYMENT_SOURCES_LOADING_QUERY_PORT)
    private readonly _getsPaymentSourcesLoadingQueryPort: GetsPaymentSourcesLoadingQueryPort,
    @Inject(GETS_PAYMENT_SOURCES_QUERY_PORT)
    private readonly _getsPaymentSourcesQueryPort: GetsPaymentSourcesQueryPort,
    @Inject(GETS_CARD_VALIDATION_QUERY_PORT)
    private readonly _getsCardValidationErrorQuery: GetsCardValidationQueryPort,
    @Inject(CONFIRM_REPLACE_CARD_MESSAGE_COMMAND_PORT)
    private readonly _confirmReplaceCardMessageCommandPort: ConfirmReplaceCardMessageCommandPort,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _huiAlert: HuiAlert,
    private readonly _fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this._isReplacingPaymentMethodState.set(false);
    this._loadPaymentSourcesCommandPort.loadPaymentSources();

    this._getsCardValidationErrorQuery
      .getErrors()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((errorFieldName: string) => {
        const control = this.cardForm.get(errorFieldName);
        if (control) {
          control.setErrors({ ...control.errors, invalidField: true });
        }
        this.cardForm.updateValueAndValidity();
        this._changeDetectorRef.detectChanges();
      });

    this._getsAddPaymentMethodSucceedQueryPort
      .getAddPaymentMethodSucceedQuery()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._huiAlert.open('success', 'cobiro_pro_settings_add_payment_method_succeed');
        this.cardForm.reset();
        this._isAddingPaymentMethodState.set(false);
        this._isReplacingPaymentMethodState.set(false);
      });
  }

  hasError(controlName: string, error: string): boolean {
    const errors = this._getControl(controlName).errors;
    return errors && errors[error] && this._getControl(controlName).touched;
  }

  onAddCardClicked(): void {
    this._isAddingPaymentMethodState.set(true);
  }
  onReplaceCardClicked(): void {
    this._isReplacingPaymentMethodState.set(true);
  }

  onCardSubmit(): void {
    const { cardOwner, cardNumber, cvv, expirationDate } = this.cardForm.value;
    const [expiryMonth, expiryYear] = expirationDate.match(/.{2}/g);
    this.isReplacingPaymentMethod$
      .pipe(
        take(1),
        switchMap((isReplacingPaymentMethod: boolean) =>
          isReplacingPaymentMethod
            ? this._confirmReplaceCardMessageCommandPort.open().pipe(
                switchMap((res: boolean) =>
                  res
                    ? this._replacePaymentMethodCommand.replacePaymentMethod({
                        cardOwner,
                        cvv,
                        number: cardNumber,
                        expiryYear,
                        expiryMonth,
                      })
                    : new Observable(observer => {
                        this.cardForm.reset();
                        this._isReplacingPaymentMethodState.set(false);
                        observer.next(true);
                        observer.complete();
                      }),
                ),
              )
            : this._addPaymentMethodCommand.addPaymentMethod({
                cardOwner,
                cvv,
                number: cardNumber,
                expiryYear,
                expiryMonth,
              }),
        ),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  trackById(item: HasId) {
    return item.id;
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _getControl(controlName: string): AbstractControl {
    return this.cardForm.get(controlName);
  }
}

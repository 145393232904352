import { Injectable } from '@angular/core';
import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { GetsUserDetailsService } from '../primary/gets-user-details.service';

@Injectable()
export class UserChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _userDetailsService: GetsUserDetailsService) {}

  handle([event]: [UserChangedEvent]): void {
    this._userDetailsService.userDetails$.next(event.user);
  }
}

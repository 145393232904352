import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LeavesWorkspaceInfoStepCommand } from './leaves-workspace-info-step.command';

export const LEAVES_WORKSPACE_INFO_STEP_COMMAND =
  new InjectionToken<LeavesWorkspaceInfoStepCommandPort>('LEAVES_WORKSPACE_INFO_STEP_COMMAND');

export interface LeavesWorkspaceInfoStepCommandPort {
  leaveWorkspaceInfoStep(command: LeavesWorkspaceInfoStepCommand): Observable<void>;
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  SetCurrentTeamCommandPort,
  SET_CURRENT_TEAM_COMMAND,
} from '../../../application/ports/primary/set-current-team-command.port';

@Injectable()
export class SetCurrentTeamResolver {
  constructor(
    @Inject(SET_CURRENT_TEAM_COMMAND) private _setCurrentTeam: SetCurrentTeamCommandPort,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    this._setCurrentTeam.setCurrentTeam({ teamId: route.params.teamId });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IntegrationContext } from '../../../../application/ports/secondary/integration.context';
import { PatchesIntegrationContextPort } from '../../../../application/ports/secondary/patches-integration.context-port';
import { SelectsIntegrationContextPort } from '../../../../application/ports/secondary/selects-integration.context-port';

@Injectable()
export class InMemoryIntegrationContextStorage
  implements PatchesIntegrationContextPort, SelectsIntegrationContextPort
{
  private _subject$: Subject<Partial<IntegrationContext>> = new BehaviorSubject<
    Partial<IntegrationContext>
  >({});

  patch(state: Partial<IntegrationContext>): Observable<void> {
    return this._subject$.pipe(
      take(1),
      map(context => this._subject$.next({ ...context, ...state })),
    );
  }

  select(): Observable<Partial<IntegrationContext>> {
    return this._subject$
      .asObservable()
      .pipe(filter(context => !!context && !!Object.keys(context).length));
  }
}

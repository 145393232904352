import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const CANCELS_INTEGRATION_DTO = new InjectionToken<CancelsIntegrationDtoPort>(
  'CANCELS_INTEGRATION_DTO',
);

export interface CancelsIntegrationDtoPort {
  cancelIntegration(siteId: string): Observable<void>;
}

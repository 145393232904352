import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SubmitsWorkspaceInfoStepCommand } from './submits-workspace-info-step.command';

export const SUBMITS_WORKSPACE_INFO_STEP_COMMAND =
  new InjectionToken<SubmitsWorkspaceInfoStepCommandPort>('SUBMITS_WORKSPACE_INFO_STEP_COMMAND');

export interface SubmitsWorkspaceInfoStepCommandPort {
  submitWorkspaceInfoStep(command: SubmitsWorkspaceInfoStepCommand): Observable<void>;
}

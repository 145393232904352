<div class="d-flex flex-column align-items-center text-center">
  <div
    class="cs-p-40 pl-3 pr-3 cs-pb-20 cs-bg-shade-0 cs-shadow-shade-40 cs-b-1 cs-b-shade-10 br-5 position-relative"
  >
    <div class="d-flex justify-content-center">
      <div class="cs-emoji-circle cs-bg-success-30 cs-b-success-10 cs-title-2 cs-mb-20">✅</div>
    </div>
    <h2 class="cs-title-4 cs-mb-10">{{ 'site_cancel_subscription_success_title' | language }}</h2>

    <div class="d-flex cs-mb-40">
      <p class="cs-text-2">
        {{ 'site_cancel_subscription_immediately_success_message' | language }}
      </p>
    </div>
  </div>
</div>

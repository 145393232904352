import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { GETS_ROLES, GetsRoles } from './core/application/gets-roles';
import { RolesState } from './core/application/roles.state';
import { RolesDirective } from './directives/roles.directive';
import { RolesGuard } from './guards/roles.guard';

type RolesModuleConfig = GetsRoles;

interface RolesModuleAsyncOptions {
  useFactory: (
    ...args: unknown[]
  ) => RolesModuleConfig | Promise<RolesModuleConfig> | Observable<RolesModuleConfig>;
  inject: unknown[];
}

@NgModule({
  imports: [CommonModule],
  declarations: [RolesDirective],
  exports: [RolesDirective],
  providers: [RolesGuard],
})
export class RolesModule {
  static forRoot(args: RolesModuleAsyncOptions): ModuleWithProviders<RolesModule> {
    return {
      ngModule: RolesModule,
      providers: [
        {
          provide: GETS_ROLES,
          useFactory: args.useFactory,
          deps: args.inject,
        },
        RolesState,
      ],
    };
  }
}

import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { CobiroProClientSelectedEvent } from '@app.cobiro.com/core/events';
import {
  SAVES_SELECTED_CLIENT_COMMAND_PORT,
  SavesSelectedClientCommandPort,
} from '../../../application/ports/primary/saves-selected-client.command-port';

@Injectable()
export class CobiroProPaymentPlansClientSelectedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProClientSelectedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SAVES_SELECTED_CLIENT_COMMAND_PORT)
    private readonly _savesSelectedClient: SavesSelectedClientCommandPort,
  ) {}

  handle([event]: [CobiroProClientSelectedEvent]): void {
    this._savesSelectedClient.saveSelectedClient({ companyName: event.payload.name }).subscribe();
  }
}

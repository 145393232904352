import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CreateProfileProgressIndicatorComponentModule } from '../../../create-profile-progress-indicator/create-profile-progress-indicator.component-module';
import { CreateAgencyWithWorkspaceStepComponent } from './create-agency-with-workspace-step.component';

@NgModule({
  imports: [CommonModule, LanguageModule, CreateProfileProgressIndicatorComponentModule],
  declarations: [CreateAgencyWithWorkspaceStepComponent],
  exports: [CreateAgencyWithWorkspaceStepComponent],
})
export class CreateAgencyWithWorkspaceStepComponentModule {}

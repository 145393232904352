/* eslint-disable complexity */
import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-cobiro-pro-label-manager-non-css-stepper',
  templateUrl: './label-manager-non-css-integration-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: LabelManagerNonCssIntegrationStepperComponent }],
})
export class LabelManagerNonCssIntegrationStepperComponent extends CdkStepper {}

<div
  class="position-relative d-flex flex-column align-items-center cs-bg-shade-0 h-100 cs-ph-40 cs-pb-40"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-invite-user-close-modal"
    matDialogClose
  ></mat-icon>

  <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">📨</div>

  <h4 class="cs-title-4 cs-mt-20">{{ 'cbr_pro_invite_user_title' | language }}</h4>
  <p class="cs-text-1 text-center cs-mt-10">
    {{ 'cbr_pro_invite_user_description' | language }}
  </p>

  <form
    class="d-flex flex-column justify-content-between w-100 cs-mt-20 h-100"
    [formGroup]="userForm"
  >
    <div class="cs-control-wrapper">
      <label class="cs-label">{{ '_email_only' | language }}</label>
      <input
        data-selector="cbr-pro-user-invite-email-input"
        class="cs-input"
        type="text"
        formControlName="email"
      />
      <p
        class="cs-control-error"
        data-selector="cobiro-pro-invite-user-required-error"
        *ngIf="
          userForm.get('email').invalid &&
          userForm.get('email').touched &&
          userForm.get('email').hasError('required')
        "
      >
        {{ '_field_is_required' | language }}
      </p>

      <p
        class="cs-control-error"
        data-selector="cobiro-pro-invite-user-pattern-error"
        *ngIf="userForm.get('email').hasError('pattern')"
      >
        {{ 'cbr_pro_email_input_error' | language }}
      </p>

      <p
        class="cs-control-error"
        data-selector="cobiro-pro-invite-user-email-taken-error"
        *ngIf="userForm.get('email').hasError('emailAlreadyExists')"
      >
        {{ '_email_address_already_taken' | language }}
      </p>
    </div>

    <button
      class="cs-btn cs-btn-primary cs-mt-40"
      data-selector="cbr-pro-invite-user-submit-btn"
      type="submit"
      [disabled]="userForm.invalid"
      [matDialogClose]="userForm.value"
    >
      {{ 'cobiro_pro_users_invite' | language }}
    </button>
  </form>
</div>

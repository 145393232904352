import { Observable } from 'rxjs';
import { PaymentSourceDto } from './payment-source.dto';
import { InjectionToken } from '@angular/core';

export interface GetsPaymentSourcesDtoPort {
  getPaymentSources(teamId: string): Observable<PaymentSourceDto[]>;
}

export const GETS_PAYMENTS_SOURCES_DTO_PORT = new InjectionToken<GetsPaymentSourcesDtoPort>(
  'GETS_PAYMENTS_SOURCES_DTO_PORT',
);

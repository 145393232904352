import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-payment-not-set',
  templateUrl: './payment-not-set.component.html',
  styleUrls: ['./payment-not-set.component.scss'],
})
export class PaymentNotSetComponent {
  @Input()
  redirect: string[];
}

import { NgModule } from '@angular/core';
import { ApplicationBusModule, provideApplicationEventsHandler } from '@cobiro/eda';
import { SaveUserLatestLoginRedirectResolverModule } from './adapters/resolvers/save-user-latest-login-redirect.resolver-module';
import { UserPasswordChangedEventHandler } from './adapters/user-password-changed.event-handler';
import { UserSignInCompletedEventHandler } from './adapters/user-sign-in-completed.event-handler';
import { UserSignInFailedEventHandler } from './adapters/user-sign-in-failed.event-handler';
import { SAVE_USER_LATEST_LOGIN_REDIRECT_COMMAND } from './application/ports/primary/saves-user-latest-login-redirect.command-port';
import { GET_USER_LATEST_LOGIN_REDIRECT_DTO } from './application/ports/secondary/gets-user-latest-login-redirect.dto-port';
import { SAVE_USER_LATEST_LOGIN_REDIRECT_DTO } from './application/ports/secondary/saves-user-latest-login-redirect.dto-port';
import { ChangePasswordState } from './application/state/change-password.state';
import { RecoverPasswordState } from './application/state/recover-password.state';
import { UserLoginState } from './application/state/user-login.state';
import { CHANGES_PASSWORD } from './domain/changes-password';
import { LOGOUTS } from './domain/logouts';
import { RECOVERS_PASSWORD } from './domain/recovers-password';
import { HttpChangesPasswordService } from './infrastructure/http-changes-password.service';
import { HttpLogoutsService } from './infrastructure/http-logouts.service';
import { HttpRecoversPasswordService } from './infrastructure/http-recovers-password.service';
import { LocalStorageUserLatestLoginRedirectService } from './infrastructure/local-storage-user-latest-login-redirect.service';

@NgModule({
  imports: [ApplicationBusModule, SaveUserLatestLoginRedirectResolverModule],
  providers: [
    UserLoginState,
    RecoverPasswordState,
    LocalStorageUserLatestLoginRedirectService,
    ChangePasswordState,
    { provide: SAVE_USER_LATEST_LOGIN_REDIRECT_COMMAND, useExisting: UserLoginState },
    {
      provide: SAVE_USER_LATEST_LOGIN_REDIRECT_DTO,
      useExisting: LocalStorageUserLatestLoginRedirectService,
    },
    {
      provide: GET_USER_LATEST_LOGIN_REDIRECT_DTO,
      useExisting: LocalStorageUserLatestLoginRedirectService,
    },
    { provide: RECOVERS_PASSWORD, useClass: HttpRecoversPasswordService },
    { provide: CHANGES_PASSWORD, useClass: HttpChangesPasswordService },
    { provide: LOGOUTS, useClass: HttpLogoutsService },
    provideApplicationEventsHandler(UserSignInCompletedEventHandler),
    provideApplicationEventsHandler(UserSignInFailedEventHandler),
    provideApplicationEventsHandler(UserPasswordChangedEventHandler),
  ],
})
export class CoreUserLoginModule {}

<ng-container *ngIf="integrationLinkStatus$ | async as status">
  <ng-container
    *ngIf="status.isLinkButtonVisible; then initiateLinkingTemp; else successfullyLinkedTemp"
  ></ng-container>
</ng-container>

<ng-template #initiateLinkingTemp>
  <p class="cs-text-1" [innerHTML]="'cobiro_css_initiate_linking_description' | language"></p>

  <button
    class="cs-btn cs-btn-primary cs-mt-20"
    data-selector="css-initiate-linking-button"
    type="button"
    [disabled]="isClicked"
    (click)="onInitiateLinkingClicked()"
  >
    {{ 'cobiro_css_initiate_linking_btn' | language }}
  </button>
</ng-template>

<ng-template #successfullyLinkedTemp>
  <p class="cs-text-1" [innerHTML]="'cobiro_css_linking_done' | language"></p>
</ng-template>

import { PlanEstimateDto } from '../../secondary/dto/payments/plan-estimate.dto';

export class PlanEstimateQuery {
  constructor(
    public readonly nett: number,
    public readonly gross: number,
    public readonly vatValue: number,
    public readonly vatPercentage: number,
    public readonly originalGrossPrice: number | null,
  ) {}

  static fromDto(dto: PlanEstimateDto): PlanEstimateQuery {
    return new PlanEstimateQuery(
      dto.nett,
      dto.gross,
      dto.vatValue,
      dto.vatPercentage,
      dto.hasValidDiscountCode ? dto.grossValueBeforeDiscount : null,
    );
  }
}

import { InjectionToken } from '@angular/core';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';

export const INITS_STEPPER_COMMAND = new InjectionToken<InitsStepperCommandPort>(
  'INITS_STEPPER_COMMAND',
);

export interface InitsStepperCommandPort {
  init(stepper: HuiStepperComponent): void;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserDetailDto, UserDetailDtoPartial } from './user-details.dto';
import { UserDetailQuery } from '../primary/user-details.query';

export const UPDATES_USER_DETAILS_DTO = new InjectionToken<UpdatesUserDetailsDtoPort>(
  'UPDATES_USER_DETAILS_DTO',
);

export interface UpdatesUserDetailsDtoPort {
  update(dto: UserDetailDtoPartial, userId: string): Observable<UserDetailQuery>;
}

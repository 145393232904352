import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface SetsSelectedTeamCommandPort {
  setSelectedTeam(
    teamId: string,
    teamName: string,
    userRole: string,
    avatar: string,
  ): Observable<boolean>;
}

export const SETS_SELECTED_TEAM_COMMAND = new InjectionToken<SetsSelectedTeamCommandPort>(
  'SETS_SELECTED_TEAM_COMMAND',
);

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { UpgradeFlowStartedEvent } from '@app.cobiro.com/core/events';

export class DataLayerUpgradeFlowStartedEventMapper implements DataLayerTrackingMapper {
  eventClass = UpgradeFlowStartedEvent;
  mapEvent(event: UpgradeFlowStartedEvent) {
    return {
      eventName: '/Payment - Paywall started',
      eventData: { context: event.featureId, upgrade_flow_version: event.flowVersion },
    };
  }
}

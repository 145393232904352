import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './edit-workspace-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditWorkspaceModalComponent implements OnDestroy, OnInit {
  readonly workspaceForm: UntypedFormGroup = this._fb.group({
    name: ['', [Validators.required]],
  });

  constructor(
    private readonly _fb: UntypedFormBuilder,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { route: ActivatedRoute; workspaceName: string },
  ) {}

  ngOnInit(): void {
    this.workspaceForm.patchValue({ name: this.data.workspaceName });
  }

  ngOnDestroy(): void {
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }
}

<div class="d-flex flex-column h-100 justify-content-between safari-scroll cs-min-height-625-xs">
  <div>
    <h1 class="cs-title-3">{{ '_select_site' | language }} {{ 'payment' | language }}</h1>

    <p class="cs-text-2 cs-mt-10 cs-mb-30">
      {{ 'payment_plans_upgrade_site_description' | language }}
    </p>

    <div class="d-flex align-items-center cs-mb-30">
      <span class="cs-text-2">{{ 'payment_plans_period_pay_monthly' | language }}</span>
      <span
        *ngIf="currentlySelectedPeriod$ | async as currentlySelectedPeriod"
        class="cs-toggle cs-mh-10"
        [class.active]="currentlySelectedPeriod === 'yearly'"
        (click)="handlePeriodChange()"
      ></span>
      <span class="cs-text-2">{{ 'payment_plans_period_pay_yearly' | language }}</span>
      <span class="cs-c-primary d-flex align-items-center cs-title-8">
        <img
          class="arrow-icon cs-mv-0 cs-mh-5"
          src="assets/payment-plans/icons/icon-arrow-blue.svg"
        />
        {{ 'payment_plans_save' | language }} 20%
      </span>
    </div>

    <ng-container *ngIf="currentlySelectedPeriod$ | async as currentlySelectedPeriod">
      <label
        *ngFor="let plan of upgradablePlans$ | async"
        class="cs-radio-option"
        [class.active]="plan.name === planForm.value"
      >
        <input type="radio" name="plan" [formControl]="planForm" [value]="plan.name" />
        <div class="cs-radio-option-content">
          <ng-container
            [ngTemplateOutlet]="
              currentlySelectedPeriod === 'yearly' ? yearlyPayment : monthlyPayment
            "
            [ngTemplateOutletContext]="{ plan: plan }"
          >
          </ng-container>
        </div>
      </label>
    </ng-container>
  </div>
  <button
    class="cs-btn cs-btn-primary w-100 cs-mt-20"
    (click)="selectPlan()"
    [disabled]="planForm.invalid"
    data-selector="plan-selection-next-step-button"
    data-analytics="payment-plans-plan-selection-next-step"
  >
    {{ 'payment_plans_continue_to_payment' | language }}
  </button>
</div>

<ng-template #monthlyPayment let-plan="plan">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <p class="cs-title-7">{{ plan.name }}</p>
      <p class="cs-text-3">
        {{ '_billed_monthly' | language }}
      </p>
    </div>

    <p class="cs-title-5">
      {{ plan.pricePerMonth.monthly }}
    </p>
  </div>
</ng-template>

<ng-template #yearlyPayment let-plan="plan">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <p class="cs-title-7">{{ plan.name }}</p>
      <p class="cs-text-3 text-transform-lowercase">
        {{ plan.pricePerMonth.yearly }} /{{ 'payment_plans_per_month' | language }}
        {{ '_billed_yearly' | language }}
      </p>
    </div>
    <p class="plan-saving cs-bg-primary-10 cs-c-primary cs-pv-5 cs-ph-10 cs-title-9 br-5">
      {{ plan.savingsPerYearInAnnualPayment }} {{ 'payment_plans_period_saved_yearly' | language }}
    </p>
    <p class="cs-title-5">
      {{ plan.pricePerYear.yearly }}
    </p>
  </div>
</ng-template>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface ToggleAllClientsCommandPort {
  toggleAllRows(): Observable<boolean>;
}

export const TOGGLE_ALL_CLIENTS_COMMAND = new InjectionToken<ToggleAllClientsCommandPort>(
  'TOGGLE_ALL_CLIENTS_COMMAND',
);

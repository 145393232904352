import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { PartnerProgramRequestEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerPartnerProgramRequestEventMapper implements DataLayerTrackingMapper {
  eventClass = PartnerProgramRequestEvent;

  mapEvent(event: PartnerProgramRequestEvent): DataLayerTrackingData {
    return {
      eventName: 'partner-programme-application',
      eventData: {
        form_name: event.userName,
        form_company_name: event.agencyName,
        form_company_url: event.url,
        workspace_id: event.workspaceId,
      },
    };
  }
}

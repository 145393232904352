/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CssMerchantDto } from '../../../../application/ports/secondary/css-merchant.dto';
import { GetsCssMerchantIdsDto } from '../../../../application/ports/secondary/gets-css-merchant-ids.dto';
import { GetsCssMerchantIdsDtoPort } from '../../../../application/ports/secondary/gets-css-merchant-ids.dto-port';

export interface CssDomainIdAttributes {
  id: string;
  type: 'css-domain';
  attributes: CssMerchantDto;
}

@Injectable()
export class HttpCssMerchantIdsService implements GetsCssMerchantIdsDtoPort {
  constructor(private readonly _gatewayClient: GatewayClient) {}
  getCssMerchantIds(dto: GetsCssMerchantIdsDto): Observable<CssMerchantDto[]> {
    return this._gatewayClient
      .get<HasDataCollection<CssMerchantDto>>(`v1/google-css/teams/${dto.teamId}/css-domains`)
      .pipe(
        map(res =>
          res.data.map(cssDomain => ({
            cssDomainId: cssDomain.attributes.cssDomainId,
            cssDomainName: cssDomain.attributes.cssDomainName,
          })),
        ),
      );
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_INTEGRATION_TYPE_QUERY = new InjectionToken<GetsIntegrationTypeQueryPort>(
  'GETS_INTEGRATION_TYPE_QUERY',
);

export interface GetsIntegrationTypeQueryPort {
  getIntegrationType(): Observable<string>;
}

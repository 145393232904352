import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TEAM_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { InMemoryReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import {
  CardsModule,
  ConfirmationStepComponentModule,
  DiscountCodeComponentModule,
  paymentHandlerProvider,
  PaymentPlansCoreModule,
  PaymentSummaryComponentModule,
  UpgradePlanFlowModule,
} from '@app.cobiro.com/payment-plans';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { languages } from '../assets/languages/languages';
import { CobiroProPaymentPlansClientSelectedEventHandler } from './adapters/primary/event-handlers/cobiro-pro-payment-plans-client-selected.event-handler';
import { PaymentNotSetComponent } from './adapters/primary/ui/payment-not-set/payment-not-set.component';
import { SelectCardStepComponent } from './adapters/primary/ui/select-card-step/select-card-step.component';
import { UpgradePlanPresetCardsFlowComponent } from './adapters/primary/ui/upgrade-plan-preset-cards-flow/upgrade-plan-preset-cards-flow.component';
import { HttpGetsBillingDataService } from './adapters/secondary/infrastructure/http-gets-billing-data.service';
import { SELECTED_CLIENT_STORAGE } from './adapters/secondary/storage/selected-client.storage';
import { CobiroProPaymentHandler } from './application/cobiro-pro-payment-handler';
import { SaveSelectedClientCommandHandler } from './application/command-handlers/save-selected-client.command-handler';
import { SAVES_SELECTED_CLIENT_COMMAND_PORT } from './application/ports/primary/saves-selected-client.command-port';
import { GETS_BILLING_DATA_DTO_PORT } from './application/ports/secondary/gets-billing-data-dto.port';

@NgModule({
  imports: [
    CommonModule,
    PaymentPlansCoreModule,
    UpgradePlanFlowModule,
    LanguageModule.forFeature(languages),
    CardsModule,
    ConfirmationStepComponentModule,
    DiscountCodeComponentModule,
    PaymentSummaryComponentModule,
    RouterModule,
    MatDialogModule,
  ],
  declarations: [
    UpgradePlanPresetCardsFlowComponent,
    SelectCardStepComponent,
    PaymentNotSetComponent,
  ],
  providers: [
    paymentHandlerProvider(CobiroProPaymentHandler, [TEAM_ID_GETTER]),
    provideApplicationEventsHandler(CobiroProPaymentPlansClientSelectedEventHandler),
    {
      provide: GETS_BILLING_DATA_DTO_PORT,
      useClass: HttpGetsBillingDataService,
    },
    {
      provide: SELECTED_CLIENT_STORAGE,
      useClass: InMemoryReactiveSingleValueStorage,
    },
    {
      provide: SAVES_SELECTED_CLIENT_COMMAND_PORT,
      useClass: SaveSelectedClientCommandHandler,
    },
  ],
  exports: [UpgradePlanPresetCardsFlowComponent, SelectCardStepComponent],
})
export class CobiroProPaymentPlansModule {}

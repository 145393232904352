import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { Observable } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { CountryDto } from '../../application/ports/secondary/country.dto';
import { CreatesAgencyWithWorkspaceDtoPort } from '../../application/ports/secondary/creates-agency-with-workspace.dto-port';
import { GetsCountriesDtoPort } from '../../application/ports/secondary/gets-countries.dto-port';

@Injectable()
export class HttpCreatesProfileService
  implements CreatesAgencyWithWorkspaceDtoPort, GetsCountriesDtoPort
{
  private readonly _baseUrl!: string;

  constructor(
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
    private readonly _gateway: GatewayClient,
    private readonly _httpClient: HttpClient,
  ) {
    this._baseUrl = this._getsConfig.get('cdn') as string;
  }

  createAgencyWithWorkspace(args: {
    workspaceName: string;
    companyType: string;
    numberOfEmployees: number | null;
    numberOfClients: number | null;
    owner: {
      firstName: string;
      lastName: string;
      companyName: string;
      country: string;
      phoneNumber: string | null;
    };
  }): Observable<void> {
    return this._gateway.post<void>(`v2/sites/teams`, {
      data: {
        id: uuidV4(),
        type: 'teams',
        attributes: {
          workspaceName: args.workspaceName,
          companyType: args.companyType, // accepted values: agency,individual,ecommerce_merchant
          numberOfEmployees: args.numberOfEmployees,
          numberOfClients: args.numberOfClients,
          owner: args.owner,
        },
      },
    });
  }

  getAllCountries(): Observable<CountryDto[]> {
    return this._httpClient.get<CountryDto[]>(`${this._baseUrl}/v1/contents/countries/index.json`);
  }
}

import { NgModule } from '@angular/core';
import { SET_UPGRADE_WIDGET_VISIBLE_COMMAND } from './application/primary/set-upgrade-widget-visible.command-port';
import { UIState } from '@app.cobiro.com/shared/ui-state';
import { GET_UPGRADE_WIDGET_VISIBLE_QUERY } from './application/primary/get-upgrade-widget-visible.query-port';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { UpgradeWidgetUserAdditionalDataChangedEventHandler } from './adapters/primary/event-handlers/upgrade-widget-user-additional-data-changed.event-handler';

@NgModule({
  providers: [
    { provide: SET_UPGRADE_WIDGET_VISIBLE_COMMAND, useFactory: () => new UIState<boolean>(false) },
    {
      provide: GET_UPGRADE_WIDGET_VISIBLE_QUERY,
      useFactory: (uiState: UIState<boolean>) => ({
        get: () => uiState.select(),
      }),
      deps: [SET_UPGRADE_WIDGET_VISIBLE_COMMAND],
    },
    provideApplicationEventsHandler(UpgradeWidgetUserAdditionalDataChangedEventHandler),
  ],
})
export class CobiroProUpgradeWidgetStateModule {}

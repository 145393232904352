import { NgModule } from '@angular/core';
import { UserIsInvitedEventDispatcher } from '../../adapters/secondary/dispatchers/user-is-invited.event-dispatcher';
import { CREATES_AGENCY_WITH_WORKSPACE_COMMAND } from '../ports/primary/creates-agency-with-workspace.command-port';
import { FILTERS_COUNTRIES_COMMAND } from '../ports/primary/filters-countries.command-port';
import { GETS_COMPANY_TYPES_QUERY } from '../ports/primary/gets-company-types.query-port';
import { GETS_CREATE_PROFILE_PROGRESS_QUERY } from '../ports/primary/gets-create-profile-progress.query-port';
import { GETS_CURRENT_COMPANY_NAME_QUERY } from '../ports/primary/gets-current-company-name.query-port';
import { GETS_FILTERED_COUNTRIES_QUERY } from '../ports/primary/gets-filtered-countries.query-port';
import { GETS_IS_LAST_STEP_VISIBLE_QUERY } from '../ports/primary/gets-is-last-step-visible.query-port';
import { GETS_IS_VALID_COUNTRY_QUERY } from '../ports/primary/gets-is-valid-country.query-port';
import { GETS_NUMBER_OF_CLIENTS_QUERY } from '../ports/primary/gets-number-of-clients.query-port';
import { GETS_NUMBER_OF_EMPLOYEES_QUERY } from '../ports/primary/gets-number-of-employees.query-port';
import { INITS_CREATE_PROFILE_FLOW_COMMAND } from '../ports/primary/inits-create-profile-flow.command-port';
import { IS_INVITED_USER_QUERY } from '../ports/primary/is-invited-user.query-port';
import { LEAVES_COMPANY_DETAILS_STEP_COMMAND } from '../ports/primary/leaves-company-details-step.command-port';
import { LEAVES_WORKSPACE_INFO_STEP_COMMAND } from '../ports/primary/leaves-workspace-info-step.command-port';
import { SETS_IS_INVITED_USER_QUERY } from '../ports/primary/sets-is-invited-user.query-port';
import { SKIPS_COMPANY_DETAILS_STEP_COMMAND } from '../ports/primary/skips-company-details-step.command-port';
import { SUBMITS_COMPANY_BASE_INFO_STEP_COMMAND } from '../ports/primary/submits-company-base-info-step.command-port';
import { SUBMITS_COMPANY_DETAILS_STEP_COMMAND } from '../ports/primary/submits-company-details-step.command-port';
import { SUBMITS_WORKSPACE_INFO_STEP_COMMAND } from '../ports/primary/submits-workspace-info-step.command-port';
import { USER_IS_INVITED_DISPATCHER } from '../ports/primary/user-is-invited.dispatcher-port';
import { CreateProfileState } from './create-profile.state';

@NgModule({
  providers: [
    CreateProfileState,
    { provide: CREATES_AGENCY_WITH_WORKSPACE_COMMAND, useExisting: CreateProfileState },
    { provide: FILTERS_COUNTRIES_COMMAND, useExisting: CreateProfileState },
    { provide: GETS_COMPANY_TYPES_QUERY, useExisting: CreateProfileState },
    { provide: GETS_CREATE_PROFILE_PROGRESS_QUERY, useExisting: CreateProfileState },
    { provide: GETS_CURRENT_COMPANY_NAME_QUERY, useExisting: CreateProfileState },
    { provide: GETS_FILTERED_COUNTRIES_QUERY, useExisting: CreateProfileState },
    { provide: GETS_IS_LAST_STEP_VISIBLE_QUERY, useExisting: CreateProfileState },
    { provide: GETS_IS_VALID_COUNTRY_QUERY, useExisting: CreateProfileState },
    { provide: GETS_NUMBER_OF_CLIENTS_QUERY, useExisting: CreateProfileState },
    { provide: GETS_NUMBER_OF_EMPLOYEES_QUERY, useExisting: CreateProfileState },
    { provide: INITS_CREATE_PROFILE_FLOW_COMMAND, useExisting: CreateProfileState },
    { provide: LEAVES_COMPANY_DETAILS_STEP_COMMAND, useExisting: CreateProfileState },
    { provide: LEAVES_WORKSPACE_INFO_STEP_COMMAND, useExisting: CreateProfileState },
    { provide: SKIPS_COMPANY_DETAILS_STEP_COMMAND, useExisting: CreateProfileState },
    { provide: SUBMITS_COMPANY_BASE_INFO_STEP_COMMAND, useExisting: CreateProfileState },
    { provide: SUBMITS_COMPANY_DETAILS_STEP_COMMAND, useExisting: CreateProfileState },
    { provide: SUBMITS_WORKSPACE_INFO_STEP_COMMAND, useExisting: CreateProfileState },
    { provide: SETS_IS_INVITED_USER_QUERY, useExisting: CreateProfileState },
    { provide: IS_INVITED_USER_QUERY, useExisting: CreateProfileState },
    { provide: USER_IS_INVITED_DISPATCHER, useClass: UserIsInvitedEventDispatcher },
  ],
})
export class CreateProfileStateModule {}

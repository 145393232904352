import { ApplicationEvent } from '@cobiro/eda';

export class SignInStartedEvent extends ApplicationEvent {
  constructor(
    public readonly email: string,
    public readonly password: string,
    public readonly version: 'v1' | 'v2' = 'v1',
  ) {
    super();
  }
}

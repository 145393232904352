import { InjectionToken } from '@angular/core';
import { LabelManagerReportDateData } from './label-manager-report.query';

export const SETS_CURRENT_LM_REPORT_QUERY = new InjectionToken<SetsCurrentLMReportQueryPort>(
  'SETS_CURRENT_LM_REPORT_QUERY',
);

export interface SetsCurrentLMReportQueryPort {
  setCurrentLMReport(value: LabelManagerReportDateData): void;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelSubscriptionCSSCommand } from './cancel-subscription-css-client.command';

export const CANCEL_SUBSCRIPTION_CSS_COMMAND = new InjectionToken<CancelSubscriptionCSSCommandPort>(
  'CANCEL_SUBSCRIPTION_CSS_COMMAND',
);

export interface CancelSubscriptionCSSCommandPort {
  cancelSubscriptionCSS(command: CancelSubscriptionCSSCommand): Observable<void>;
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { WorkspaceSelectedEvent } from '@app.cobiro.com/core/events';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { Router } from '@angular/router';

@Injectable()
export class CobiroProWorkspaceSelectedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [WorkspaceSelectedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  handle([event]: [WorkspaceSelectedEvent]): void {
    this._router.navigate(['/', 'connect', this._teamIdGetter.getTeamId(), 'home']);
  }
}

import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { RECOVERS_PASSWORD, RecoversPassword } from '../../domain/recovers-password';
import { InitsStepper, Stepper } from '@app.cobiro.com/core/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';

@Injectable()
export class RecoverPasswordState implements InitsStepper {
  private _isLoading = new BehaviorSubject(false);

  private _stepper: Stepper;

  constructor(
    @Inject(RECOVERS_PASSWORD) private _recoversPassword: RecoversPassword,
    private _alert: HuiAlert,
  ) {}

  get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  recover(email: string): void {
    this._isLoading.next(true);
    this._recoversPassword
      .recover(email)
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response) {
            this._stepper.next();
            this._alert.open('success', 'password_recovery_page_send');
          } else {
            this._alert.open('error', 'password_recovery_wrong_email');
          }
          this._isLoading.next(false);
        },
        error: err => {
          if (err.status) {
            this._alert.open('error', 'password_recovery_many_attempts');
            this._isLoading.next(false);
          }
        },
      });
  }

  init(stepper: Stepper): void {
    this._stepper = stepper;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';
import { HuiSimpleModalWithEmojiComponent } from './hui-simple-modal-with-emoji.component';

@NgModule({
  declarations: [HuiSimpleModalWithEmojiComponent],
  imports: [CommonModule, LanguageModule, MatDialogModule],
  exports: [HuiSimpleModalWithEmojiComponent],
})
export class HuiSimpleModalWithEmojiModule {}

import { Injectable } from '@angular/core';

@Injectable()
export class EventLoggerContext {
  private _context = new Map<string, string | number | boolean>();

  set(key: string, value: string | number | boolean): void {
    this._context.set(key, value);
  }

  clear() {
    this._context = new Map();
  }

  get(): { [key: string]: string | number | boolean } {
    const obj: { [key: string]: string | number | boolean } = {};
    this._context.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  delete(key: string): void {
    this._context.delete(key);
  }
}

import { NgModule } from '@angular/core';
import { PATCHES_INTEGRATION_CONTEXT } from '../../../../application/ports/secondary/patches-integration.context-port';
import { SELECTS_INTEGRATION_CONTEXT } from '../../../../application/ports/secondary/selects-integration.context-port';
import { InMemoryIntegrationContextStorage } from './in-memory-integration-context.storage';

@NgModule({
  providers: [
    InMemoryIntegrationContextStorage,
    {
      provide: PATCHES_INTEGRATION_CONTEXT,
      useExisting: InMemoryIntegrationContextStorage,
    },
    {
      provide: SELECTS_INTEGRATION_CONTEXT,
      useExisting: InMemoryIntegrationContextStorage,
    },
  ],
})
export class InMemoryIntegrationContextStorageModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialAuth } from '../../../domain/signs-up-by';
import { SignsUpByFacebookCommand } from './signs-up-by-facebook.command';

export const SIGNS_UP_BY_FACEBOOK_COMMAND = new InjectionToken<SignsUpByFacebookCommandPort>(
  'SIGNS_UP_BY_FACEBOOK_COMMAND',
);

export interface SignsUpByFacebookCommandPort {
  signUpByFacebook(command: SignsUpByFacebookCommand): Observable<SocialAuth | null>;
}

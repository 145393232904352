import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProClientsListTableComponent } from './clients-list-table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { ClientProductInfoModule } from '../client-product-info/client-product-info.component-module';

@NgModule({
  declarations: [CobiroProClientsListTableComponent],
  imports: [
    RouterModule,
    CommonModule,
    HuiLoaderContainerModule,
    MatTableModule,
    LanguageModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    HuiAvatarModule,
    MatPaginatorModule,
    MatSortModule,
    ClientProductInfoModule,
  ],
  exports: [CobiroProClientsListTableComponent],
})
export class CobiroProClientListTableComponentModule {}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Injectable } from '@angular/core';
import { UserAssignedToTrackingEvent } from '@app.cobiro.com/core/events';
import { TrackingService } from '../tracking.service';

@Injectable()
export class UserAssignedToTrackingEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserAssignedToTrackingEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _trackingService: TrackingService) {}

  handle([event]: [UserAssignedToTrackingEvent]) {
    this._trackingService.assignUser(event.userId);
  }
}

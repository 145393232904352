import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientsContext } from './clientsContext';

export const SELECTS_CLIENTS_CONTEXT_STORAGE = new InjectionToken<SelectsClientsContextStoragePort>(
  'SELECTS_CLIENTS_CONTEXT_STORAGE',
);

export interface SelectsClientsContextStoragePort {
  select(): Observable<Partial<ClientsContext>>;
}

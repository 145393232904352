import { NgModule } from '@angular/core';
import { GETS_PAYMENT_SOURCES_DTO } from '../../../../application/ports/secondary/dto/payments/gets-payment-sources.dto-port';
import { GETS_VAT_NUMBER_VALIDATION_DTO } from '../../../../application/ports/secondary/dto/settings/gets-vat-number-validation.dto-port';
import { HttpGetsPaymentSourcesService } from './http-gets-payment-sources.service';

@NgModule({
  providers: [
    HttpGetsPaymentSourcesService,
    { provide: GETS_PAYMENT_SOURCES_DTO, useExisting: HttpGetsPaymentSourcesService },
    { provide: GETS_VAT_NUMBER_VALIDATION_DTO, useExisting: HttpGetsPaymentSourcesService },
  ],
})
export class HttpGetsPaymentSourcesServiceModule {}

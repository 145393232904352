import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProClearStatesEvent, WorkspaceSelectedEvent } from '@app.cobiro.com/core/events';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { Router } from '@angular/router';

@Injectable()
export class CobiroProClearStatesEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProClearStatesEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  handle([event]: [CobiroProClearStatesEvent]): void {
    return;
  }
}

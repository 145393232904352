import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentPlansState } from '../../../application/state/payment-plans.state';
import { CurrentPlanState } from '../../../application/state/current-plan.state';
import { concatMap } from 'rxjs/operators';

// TODO: Add tests
@Injectable()
export class PaymentPlansLoadedGuard {
  constructor(
    private _paymentPlansState: PaymentPlansState,
    private _currentPlanState: CurrentPlanState,
  ) {}
  canActivate(): Observable<boolean> {
    return this._currentPlanState
      .loadPlan()
      .pipe(concatMap(() => this._paymentPlansState.initState()));
  }
}

import { NgModule } from '@angular/core';
import { GETS_ANNOUCEMENT_QUERY } from '../ports/primary/gets-annoucement.query-port';
import { SETS_ANNOUCEMENT_VISIBILITY_QUERY } from '../ports/primary/sets-annoucement-visibility.query-port';
import { AnnoucementState } from './annoucement.state';

@NgModule({
  providers: [
    AnnoucementState,
    { provide: GETS_ANNOUCEMENT_QUERY, useExisting: AnnoucementState },
    { provide: SETS_ANNOUCEMENT_VISIBILITY_QUERY, useExisting: AnnoucementState },
  ],
})
export class AnnoucementStateModule {}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmailValidator } from '@app.cobiro.com/shared/validators';
import { ChecksUserInvitationCommand } from '../../../../../application/ports/primary/checks-user-invitation.command';
import {
  GETS_USERS_LIST_QUERY,
  GetsUsersListQueryPort,
} from '../../../../../application/ports/primary/gets-users-list.query-port';
import {
  LOAD_USERS_COMMAND,
  LoadUsersCommandPort,
} from '../../../../../application/ports/primary/load-users.command-port';
import { UserQuery } from '../../../../../application/ports/primary/user.query';
import {
  Observable,
  take,
  map,
  takeUntil,
  Subject,
  switchMap,
  combineLatest,
  catchError,
  of,
} from 'rxjs';
import {
  CHECKS_USER_INVITATION_COMMAND,
  ChecksUserInvitationCommandPort,
} from '../../../../../application/ports/primary/checks-user-invitation.command-port';
import {
  GetsCurrentWorkspaceQueryPort,
  GETS_CURRENT_WORKSPACE_QUERY,
} from '../../../../../application/ports/primary/gets-current-workspace.query-port';
import {
  CobiroProContextQuery,
  GetsCobiroProContextQueryPort,
  GETS_COBIRO_PRO_CONTEXT_QUERY,
} from '@app.cobiro.com/cobiro-pro/context';
import {
  AddsAccessCommandPort,
  ADDS_ACCESS_COMMAND,
} from '../../../../../application/ports/primary/adds-access.command-port';
import {
  SendsInvitationCommandPort,
  SENDS_INVITATION_COMMAND,
} from '../../../../../application/ports/primary/sends-invitation.command-port';
import { WorkspaceQuery } from '../../../../../application/ports/primary/workspace.query';
import {
  GETS_TEAM_USERS_LIST_QUERY,
  GetsTeamUsersListQueryPort,
} from 'libs/cobiro-pro-rewrite/users/src/lib/application/ports/primary/gets-team-user-list.query-port';

@Component({
  selector: 'lib-cobiro-pro-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserModalComponent implements OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  readonly hideTabs$ = this._getsCobiroProContextQueryPort
    .getContext()
    .pipe(map((res: CobiroProContextQuery) => res.selectedTeamUserRole !== 'basic_user'));
  readonly workspace$ = this._getsCurrentWorkspaceQueryPort.getCurrentWorkspace();
  readonly users$: Observable<UserQuery[]> = combineLatest([
    this._getsTeamUserListQuery.getUserList(),
    this._getsCurrentWorkspaceQueryPort.getCurrentWorkspace(),
  ]).pipe(
    map(([users, workspace]: [UserQuery[], WorkspaceQuery]) =>
      users.filter(user => !user.workspaces.some(id => workspace.id === id)),
    ),
  );
  readonly newUserForm: FormGroup = this._fb.group({
    email: [
      '',
      [Validators.required, isEmailValidator],
      [this.isUserAlreadyInvited(this._checksUserInvitationCommand)],
    ],
    comment: [''],
  });
  readonly currentUserForm: FormGroup = this._fb.group({
    userId: ['', [Validators.required]],
    comment: [''],
  });

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _router: Router,
    private _matDialogRef: MatDialogRef<AddUserModalComponent>,
    @Inject(CHECKS_USER_INVITATION_COMMAND)
    private readonly _checksUserInvitationCommand: ChecksUserInvitationCommandPort,
    @Inject(GETS_CURRENT_WORKSPACE_QUERY)
    private readonly _getsCurrentWorkspaceQueryPort: GetsCurrentWorkspaceQueryPort,
    @Inject(GETS_TEAM_USERS_LIST_QUERY)
    private readonly _getsTeamUserListQuery: GetsTeamUsersListQueryPort,
    @Inject(MAT_DIALOG_DATA) readonly data: { route: ActivatedRoute },
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQueryPort: GetsCobiroProContextQueryPort,
    @Inject(ADDS_ACCESS_COMMAND)
    private readonly _addsAccessCommandPort: AddsAccessCommandPort,
    @Inject(SENDS_INVITATION_COMMAND)
    private readonly _sendsInvitationCommandPort: SendsInvitationCommandPort,
  ) {}

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }

  onInviteBtnClickedCurrentUser(): void {
    this.workspace$
      .pipe(
        switchMap(workspace =>
          this._addsAccessCommandPort.addAccess({
            memberId: this.currentUserForm.getRawValue().userId,
            workspaceIds: Array.of(workspace.id),
          }),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe(() => this._matDialogRef.close());
  }

  onInviteBtnClickedNewUser(): void {
    this.workspace$
      .pipe(
        switchMap(workspace =>
          this._sendsInvitationCommandPort
            .sendInvitation({
              userEmail: this.newUserForm.getRawValue().email,
              userRole: 'basic_user',
              workspaceIds: Array.of(workspace.id),
            })
            .pipe(catchError(() => of(undefined))),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe(() => this._matDialogRef.close());
  }

  isUserAlreadyInvited(
    checksUserInvitationCommand: ChecksUserInvitationCommandPort,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      checksUserInvitationCommand
        .checksUserInvitation(new ChecksUserInvitationCommand(control.value))
        .pipe(
          take(1),
          map((res: boolean) => (res ? { emailAlreadyExists: true } : null)),
        );
  }
}

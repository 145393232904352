import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationQuery } from './integration.query';

export const GETS_INTEGRATION_QUERY = new InjectionToken<GetsIntegrationQueryPort>(
  'GETS_INTEGRATION_QUERY',
);

export interface GetsIntegrationQueryPort {
  getIntegrationQuery(): Observable<IntegrationQuery>;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { PasswordFormModule } from '@app.cobiro.com/user/shared';
import { SocialSignUpModule } from '@app.cobiro.com/user/social-sign-up';
import { RecaptchaModule } from 'ng-recaptcha';
import { SignUpFormComponent } from './sign-up-form.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    SocialSignUpModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    HuiInputModule,
    SharedAnalyticsModule,
    PasswordFormModule,
    RecaptchaModule,
    HuiLoadingModule,
  ],
  declarations: [SignUpFormComponent],
  exports: [SignUpFormComponent],
})
export class SignUpFormComponentModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RolesService } from './adapters/roles.service';
import { TokenLoginGuard } from './adapters/token-login.guard';
import { UserState } from './application/user.state';
import { LOGINS } from './domain/logins';
import { REFRESHES_TOKEN } from './domain/refreshes-token';
import { HttpLoginsService } from './infrastructure/http-logins.service';
import { HttpRefreshesTokenService } from './infrastructure/http-refreshes-token.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TokenLoginGuard,
    UserState,
    RolesService,
    {
      provide: LOGINS,
      useClass: HttpLoginsService,
    },
    { provide: REFRESHES_TOKEN, useClass: HttpRefreshesTokenService },
  ],
})
export class AuthModule {}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GETS_INTEGRATION_LINK_STATUS_QUERY,
  GetsIntegrationLinkStatusQueryPort,
} from '../../../../application/ports/primary/gets-integration-link-status.query-port';
import { LinksIntegrationCommand } from '../../../../application/ports/primary/links-integration.command';
import {
  LINKS_INTEGRATION_COMMAND,
  LinksIntegrationCommandPort,
} from '../../../../application/ports/primary/links-integration.command-port';

@Component({
  selector: 'lib-link-integration',
  templateUrl: './link-integration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkIntegrationComponent {
  readonly integrationLinkStatus$ = this._getsIntegrationLinkStatusQuery.getIntegrationLinkStatus();

  isClicked = false;

  constructor(
    @Inject(GETS_INTEGRATION_LINK_STATUS_QUERY)
    private readonly _getsIntegrationLinkStatusQuery: GetsIntegrationLinkStatusQueryPort,
    @Inject(LINKS_INTEGRATION_COMMAND)
    private readonly _linksIntegrationCommand: LinksIntegrationCommandPort,
  ) {}

  onInitiateLinkingClicked(): void {
    this.isClicked = true;

    this._linksIntegrationCommand.linkIntegration(new LinksIntegrationCommand()).subscribe({
      error: () => {
        this.isClicked = false;
      },
    });
  }
}

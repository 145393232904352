import { ClientAddedDispatcherPort } from '../../../application/ports/secondary/client-added.dispatcher-port';
import { ClientDTO } from '../../../application/ports/secondary/client.dto';
import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { ClientAddedEvent } from '../../../application/events/client-added.event';

// TODO (PRO-DEBT): Prefix name with ApplicationBus
@Injectable()
export class ClientAddedDispatcher implements ClientAddedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus) {}
  dispatch(clientDto: ClientDTO) {
    this._applicationBus.dispatch(new ClientAddedEvent(clientDto));
  }
}

import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CompleteProfileState {
  private _isGuestUser = new BehaviorSubject<boolean>(true);

  get isGuestUser$(): Observable<boolean> {
    return this._isGuestUser.asObservable();
  }

  setGuestUser(isGuestUser: boolean): void {
    this._isGuestUser.next(isGuestUser);
  }
}

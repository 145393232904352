import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { CobiroProTeamsLoadedEvent } from '@app.cobiro.com/core/events';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CobiroProTeamsLoadedDispatcher implements Dispatcher<CobiroProTeamsLoadedEvent> {
  constructor(
    @Inject(APPLICATION_BUS)
    private readonly _dispatcher: Dispatcher<CobiroProTeamsLoadedEvent>,
  ) {}

  dispatch(event: CobiroProTeamsLoadedEvent) {
    this._dispatcher.dispatch(event);
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  GETS_INTEGRATION_ERROR_QUERY,
  GetsIntegrationErrorQueryPort,
} from '../../../../application/ports/primary/gets-integration-error.query-port';

@Component({
  selector: 'lib-integration-error',
  templateUrl: './integration-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationErrorComponent {
  readonly error$ = this._getsIntegrationErrorQuery.getIntegrationErrorQuery();

  constructor(
    public sanitize: DomSanitizer,
    @Inject(GETS_INTEGRATION_ERROR_QUERY)
    private _getsIntegrationErrorQuery: GetsIntegrationErrorQueryPort,
  ) {}
}

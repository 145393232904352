import { SurveyTrackingMap } from '../domain/survey-tracking-map';
import { ApplicationEvent, Class } from '@cobiro/eda';
import { UserChangedEvent } from '@app.cobiro.com/core/events';

export const TYPEFORM_SURVEY_TRACKING_MAP: SurveyTrackingMap = new Map<
  Class<ApplicationEvent>,
  string
>([
  // for now only for demo purposes:
  [UserChangedEvent, 'zcA1jKCl'],
]);

<form [formGroup]="form" (ngSubmit)="onFormSubmit()" autocomplete="off">
  <div class="cs-mb-40">
    <p class="cs-title-6 cs-mb-10">
      {{ 'cobiro_pro_label_manager_config_window_attr_breakEvenROAS_title' | language }}
    </p>
    <p
      class="cs-text-2 cs-mb-10"
      [innerHTML]="
        'cobiro_pro_label_manager_config_window_attr_breakEvenROAS_description' | language
      "
    ></p>
    <ngx-slider
      class="cs-ngx-slider cs-ngx-slider-with-bubble-underline cs-ngx-slider-primary"
      [options]="{ floor: 0, ceil: 2000, step: 25, showSelectionBar: true }"
      formControlName="breakEvenROAS"
      ngDefaultControl
    >
    </ngx-slider>
  </div>
  <div class="cs-mb-40">
    <p class="cs-title-6 cs-mb-10">
      {{ 'cobiro_pro_label_manager_config_window_attr_lookBackWindow_title' | language }}
    </p>
    <p
      class="cs-text-2 cs-mb-10"
      [innerHTML]="
        'cobiro_pro_label_manager_config_window_attr_lookBackWindow_description' | language
      "
    ></p>
    <ngx-slider
      class="cs-ngx-slider cs-ngx-slider-with-bubble-underline cs-ngx-slider-primary"
      [options]="{ floor: 7, ceil: 90, showSelectionBar: true }"
      formControlName="lookBackWindow"
      ngDefaultControl
    >
    </ngx-slider>
  </div>
  <div class="cs-mb-40">
    <p class="cs-title-6 cs-mb-10">
      {{ 'cobiro_pro_label_manager_config_window_attr_customLabel_title' | language }}
    </p>
    <p
      class="cs-text-2 cs-mb-10"
      [innerHTML]="'cobiro_pro_label_manager_config_window_attr_customLabel_description' | language"
    ></p>
    <div class="cs-control-wrapper">
      <select
        data-selector="custom-label"
        formControlName="customLabel"
        class="cs-input cs-select-arrow custom-label-select"
      >
        <option value="" disabled selected>
          {{ 'cobiro_pro_label_manager_config_window_attr_customLabel_placeholder' | language }}
        </option>
        <option data-selector="custom-label-items" *ngFor="let label of labels" [ngValue]="label">
          {{ label.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="cs-mb-40" *libFeatureFlags="['label_manager_-_analysis_frequency']">
    <p class="cs-title-6 cs-mb-10">
      {{ 'cobiro_pro_label_manager_config_window_attr_analysisFrequency_title' | language }}
    </p>
    <p
      class="cs-text-2 cs-mb-10"
      [innerHTML]="
        'cobiro_pro_label_manager_config_window_attr_analysisFrequency_description' | language
      "
    ></p>
    <div class="cs-control-wrapper">
      <select
        data-selector="analysis-frequency"
        formControlName="analysisFrequency"
        class="cs-input cs-select-arrow analysis-frequency-select"
      >
        <option data-selector="analysis-frequency-item-1-day" [ngValue]="1">
          1 {{ '_day' | language }}
        </option>
        <option data-selector="analysis-frequency-item-3-day" [ngValue]="3">
          3 {{ '_days' | language }}
        </option>
        <option data-selector="analysis-frequency-item-7-day" [ngValue]="7">
          7 {{ '_days' | language }}
        </option>
      </select>
    </div>
  </div>
  <div class="d-flex justify-content-between cs-mt-20 w-100">
    <button
      data-selector="label-manager-attr-continue-button"
      type="submit"
      class="cs-btn cs-btn-primary w-100"
    >
      {{ '_save_changes' | language }}
    </button>
  </div>
</form>

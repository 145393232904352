import { Dispatcher } from '@cobiro/eda';
import { CobiroProContextChangedEvent } from '@app.cobiro.com/core/events';
import { InjectionToken } from '@angular/core';

export interface ContextChangedDispatcherPort extends Dispatcher<CobiroProContextChangedEvent> {
  dispatch(event: CobiroProContextChangedEvent);
}

export const CONTEXT_CHANGED_DISPATCHER = new InjectionToken<ContextChangedDispatcherPort>(
  'CONTEXT_CHANGED_DISPATCHER',
);

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { Injectable } from '@angular/core';
import { PaymentPlanSelectedEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class DataLayerPaymentPlanSelectedEventMapper implements DataLayerTrackingMapper {
  eventClass = PaymentPlanSelectedEvent;
  mapEvent(event: PaymentPlanSelectedEvent) {
    return {
      eventName: `/Payment - Select CSS plan`,
      eventData: {
        workspaceId: event.workspaceId,
      },
    };
  }
}

import { v4 as uuidV4 } from 'uuid';

export class InvalidUUID extends Error {
  message = 'Provided value is not a valid UUID v4 nor v5';
  name = 'INVALID_UUID';
}

export class UUID {
  private readonly _value: string;
  private uuid4RegExp = new RegExp(
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4-5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  );

  constructor(value?: string) {
    if (value && !this.uuid4RegExp.test(value)) {
      throw new InvalidUUID();
    }
    this._value = value || uuidV4();
  }

  get value(): string {
    return this._value;
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CreatesNonCssIntegrationDto } from './creates-non-css-integration.dto';

export const CREATES_NON_CSS_INTEGRATION_DTO = new InjectionToken<CreatesNonCssIntegrationDtoPort>(
  'CREATES_NON_CSS_INTEGRATION_DTO',
);

export interface CreatesNonCssIntegrationDtoPort {
  createIntegration(dto: CreatesNonCssIntegrationDto): Observable<boolean>;
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GoesToNextStepCommandPort,
  GOES_TO_NEXT_STEP_COMMAND,
} from '../../../../../../application/ports/primary/setup/goes-to-next-step.command-port';
import {
  SetsSelectedPlanCommandPort,
  SETS_SELECTED_PLAN_COMMAND,
} from '../../../../../../application/ports/primary/payments/sets-selected-plan.command-port';
import {
  GetsCurrentProductQueryPort,
  GETS_CURRENT_PRODUCT_QUERY,
} from '../../../../../../application/ports/primary/setup/gets-current-product.query-port';
import { tap } from 'rxjs';

@Component({
  selector: 'lib-cobiro-pro-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoosePlanComponent {
  productName$ = this._getsCurrentProductQueryPort.getCurrentProduct();

  constructor(
    @Inject(GETS_CURRENT_PRODUCT_QUERY)
    private readonly _getsCurrentProductQueryPort: GetsCurrentProductQueryPort,
    @Inject(SETS_SELECTED_PLAN_COMMAND)
    private readonly _setsSelectedPlan: SetsSelectedPlanCommandPort,
    @Inject(GOES_TO_NEXT_STEP_COMMAND)
    private readonly _goesToNextStepCommandPort: GoesToNextStepCommandPort,
  ) {}

  onSavedPlanClicked(planId: string): void {
    this._setsSelectedPlan.setPlan({ id: planId });
    this._goesToNextStepCommandPort.goToNextStep();
  }
}

<h6 class="cs-title-7 cs-mt-20 cs-mb-0">{{ 'cobiro_pro_payment_methods_title' | language }}</h6>
<p class="cs-text-2">{{ 'cobiro_pro_payment_methods_subtitle' | language }}</p>

<div *ngIf="cards$ | async as cards; else loading">
  <label
    *ngFor="let card of cards"
    class="cs-radio-option cs-label"
    data-selector="savedCardButton"
    (click)="onSavedCardClicked(card.id)"
  >
    <input type="radio" name="plan" [value]="card.id" [checked]="isSelected$(card.id) | async" />
    <div class="cs-radio-option-content">
      <div class="d-flex justify-content-between align-items-center">
        <div data-selector="saved-card-content">
          <p class="cs-title-7">{{ card.name }}</p>
          <p class="cs-text-2">{{ card.maskedNumber }}</p>
        </div>
        <img [src]="card.logo" class="cs-width-30-xs" alt="Card Logo" />
      </div>
    </div>
  </label>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center cs-pv-20">
    <span class="cs-spinner"></span>
  </div>
</ng-template>

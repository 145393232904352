import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { DigitsInputModule } from '@app.cobiro.com/shared/hui/digits-input/digits-input.module';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { VerifyCodeFormComponent } from './verify-code-form.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    DigitsInputModule,
    ReactiveFormsModule,
    HuiLoadingModule,
    SharedAnalyticsModule,
  ],
  declarations: [VerifyCodeFormComponent],
  exports: [VerifyCodeFormComponent],
})
export class VerifyCodeFormComponentModule {}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { ClientsListChangedEvent } from '../../../application/events/clients-list-changed.event';
import { Inject, Injectable } from '@angular/core';
import {
  PatchesClientsContextStoragePort,
  PATCHES_CLIENTS_CONTEXT_STORAGE,
} from '../../../application/ports/secondary/context/patches-clients-context.storage-port';
import { take } from 'rxjs';
import { PRODUCT_LIST } from '../../../application/ports/primary/clients/product-list.query';

@Injectable()
export class ClientsListChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ClientsListChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(PATCHES_CLIENTS_CONTEXT_STORAGE)
    private readonly _patchesClientsContextStorage: PatchesClientsContextStoragePort,
  ) {}

  handle([event]: [ClientsListChangedEvent]) {
    this._patchesClientsContextStorage
      .patch({
        list: [...event.updatedClients],
        isCssSubscriptions: event.updatedClients.some(client =>
          client.subscriptions.some(subs => subs.productName === PRODUCT_LIST.css),
        ),
        isLMSubscriptions: event.updatedClients.some(client =>
          client.subscriptions.some(subs => subs.productName === PRODUCT_LIST.labelManager),
        ),
      })
      .pipe(take(1));
  }
}

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { catchError, map } from 'rxjs/operators';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { CardDto } from '../../../../application/ports/secondary/dto/payments/card.dto';
import { GetsPaymentSourcesDtoPort } from '../../../../application/ports/secondary/dto/payments/gets-payment-sources.dto-port';
import { VatNumberValidationDto } from '../../../../application/ports/secondary/dto/settings/vat-number-validation.dto';

interface PaymentSourceAttributes {
  details: {
    brand: string;
    ownerName: string;
    last4Digits: string;
    expirationMonth: string;
    expirationYear: string;
  };
}

@Injectable()
export class HttpGetsPaymentSourcesService implements GetsPaymentSourcesDtoPort {
  constructor(private readonly _gateway: GatewayClient) {}

  get(workspacesId: string): Observable<CardDto[]> {
    return this._gateway
      .get<HasDataCollection<PaymentSourceAttributes>>(
        `v1/payments/workspaces/${workspacesId}/payment-sources`,
      )
      .pipe(
        map(response =>
          response.data.map(item => ({
            id: item.id,
            lastDigits: item.attributes.details.last4Digits,
            brand: item.attributes.details.brand,
            name: item.attributes.details.ownerName,
            expiryMonth: item.attributes.details.expirationMonth,
            expiryYear: item.attributes.details.expirationYear,
          })),
        ),
      );
  }

  getVatValidation(countryCode: string, vatNumber: string): Observable<VatNumberValidationDto> {
    const body: HasData<{ country: string; number: string }> = {
      data: {
        type: 'vat-validation',
        attributes: {
          country: countryCode,
          number: vatNumber,
        },
      },
    };

    return this._gateway.post<void>('v1/payments/vat-validation', body).pipe(
      map(() => ({ isValid: true })),
      catchError(e => of({ isValid: e.status >= 500 })),
    );
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import {
  HasSubscriptionsQueryPort,
  HAS_SUBSCRIPTIONS_QUERY,
} from '../../../../../application/ports/primary/has-subscriptions.query-port';
import {
  IS_CURRENT_WORKSPACE_COMMAND,
  IsCurrentWorkspaceCommandPort,
} from '../../../../../application/ports/primary/is-current-workspace.command-port';
import { boolean } from '@pact-foundation/pact/src/dsl/matchers';

@Component({
  templateUrl: './delete-workspace-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteWorkspaceModalComponent implements OnDestroy {
  readonly hasSubscriptions$: Observable<boolean> =
    this._hasSubscriptionsQueryPort.hasSubscriptions(this.data.id);
  readonly isCurrent$: Observable<boolean> = this._isCurrentWorkspaceCommandPort.isCurrentWorkspace(
    this.data.id,
  );

  constructor(
    private readonly _router: Router,
    @Inject(IS_CURRENT_WORKSPACE_COMMAND)
    private readonly _isCurrentWorkspaceCommandPort: IsCurrentWorkspaceCommandPort,
    @Inject(HAS_SUBSCRIPTIONS_QUERY)
    private readonly _hasSubscriptionsQueryPort: HasSubscriptionsQueryPort,
    @Inject(MAT_DIALOG_DATA) readonly data: { id: string; name: string; route: ActivatedRoute },
  ) {}

  ngOnDestroy(): void {
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }
}

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';

export interface TeamIdGetter {
  getTeamId(): string | null;
}

export const TEAM_ID_GETTER = new InjectionToken<TeamIdGetter>('TEAM_ID_GETTER');

@Injectable()
export class StorageBasedTeamIdGetter implements TeamIdGetter {
  constructor(@Inject(STORAGE) private _localStorage: Storage) {}

  getTeamId(): string | null {
    const teamId = JSON.parse(this._localStorage.getItem('lib-pro-context'))?.selectedTeam?.id;

    return teamId ?? null;
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { WorkspaceQuery } from './workspace.query';

export interface GetsWorkspacesListQuery {
  getWorkspaceListQuery(): Observable<WorkspaceQuery[]>;
}

export const GETS_WORKSPACES_LIST_QUERY = new InjectionToken<GetsWorkspacesListQuery>(
  'GETS_WORKSPACES_LIST_QUERY',
);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeletesWorkspaceCommand } from './deletes-workspace.command';

export const DELETE_WORKSPACE_COMMAND = new InjectionToken<DeleteWorkspaceCommandPort>(
  'DELETE_WORKSPACE_COMMAND',
);

export interface DeleteWorkspaceCommandPort {
  deleteWorkspace(command: DeletesWorkspaceCommand): Observable<void>;
}

<div
  class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center cs-max-width-500-xs"
>
  <div class="cs-emoji-circle cs-emoji-circle-secondary">❌</div>
  <h3 class="cs-title-4 cs-mb-10 text-center cs-mt-20">
    {{ 'cbr_pro_delete_team_member_confirm_message' | language }}
  </h3>
  <div class="d-flex justify-content-center">
    <button
      class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-stroked"
      data-selector="confirmation-popup-no-btn"
      [matDialogClose]="false"
    >
      {{ '_no' | language }}
    </button>
    <button class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-error" [matDialogClose]="true">
      {{ '_yes' | language }}
    </button>
  </div>
</div>

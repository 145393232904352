import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsLoadingStateCommandPort {
  getLoadingState(): Observable<boolean>;
}

export const GETS_LOADING_STATE_COMMAND = new InjectionToken<GetsLoadingStateCommandPort>(
  'GETS_LOADING_STATE_COMMAND',
);

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMetadataQuery } from '../../../../application/ports/primary/app-metadata.query';
import {
  GETS_COBIRO_CSS_META_QUERY_PORT,
  GetsCobiroCssMetaQueryPort,
} from '../../../../application/ports/primary/gets-cobiro-css-meta.query-port';

@Component({
  selector: 'lib-cobiro-pro-cobiro-css-start-page',
  templateUrl: './cobiro-css-start-page.component.html',
  styleUrls: ['./cobiro-css-start-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroCssStartPageComponent {
  @Output()
  startIntegration = new EventEmitter<void>();

  readonly app$: Observable<AppMetadataQuery> = this._getCobiroCssMetaQueryPort.getAppMeta();

  constructor(
    @Inject(GETS_COBIRO_CSS_META_QUERY_PORT)
    private readonly _getCobiroCssMetaQueryPort: GetsCobiroCssMetaQueryPort,
  ) {}

  runAdditionalAppFlowBeforeStart(): void {
    this.startIntegration.emit();
  }
}

import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
  FETCHES_COBIRO_PRO_CONTEXT_COMMAND,
  FetchesCobiroProContextCommandPort,
} from '../../../application/ports/primary/fetches-cobiro-pro-context.command-port';

@Injectable()
export class FetchCobiroProContextResolver {
  constructor(
    @Inject(FETCHES_COBIRO_PRO_CONTEXT_COMMAND)
    private readonly _fetchesCobiroProContextCommand: FetchesCobiroProContextCommandPort,
  ) {}

  resolve(): Observable<boolean> {
    return this._fetchesCobiroProContextCommand.fetchContext();
  }
}

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { LabelManagerCloseRecommendationEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerCloseLmRecommendationEventMapper implements DataLayerTrackingMapper {
  eventClass = LabelManagerCloseRecommendationEvent;

  mapEvent(event: LabelManagerCloseRecommendationEvent): DataLayerTrackingData {
    return {
      eventName: 'gtm.click',
      eventData: {
        recommendation_id: event.id,
        integration_id: event.integrationId,
        analysis_timestamp: event.analysisTimestamp,
        user_id: event.userId,
        close_at: event.timestamp,
      },
    };
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateNewSubscriptionDto } from './create-new-subscription.dto';
import { PAYMENT_STATUS } from './payment-status.enum';

export const CREATES_NEW_SUBSCRIPTION_DTO = new InjectionToken<CreatesNewSubscriptionDtoPort>(
  'CREATES_NEW_SUBSCRIPTION_DTO',
);

export interface CreatesNewSubscriptionDtoPort {
  createNewSubscription(dto: CreateNewSubscriptionDto): Observable<PAYMENT_STATUS>;
}

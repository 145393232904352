import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { UserQuery } from './user.query';

export const GETS_LATEST_USERS_QUERY = new InjectionToken<GetsLatestUsersQueryPort>(
  'GETS_LATEST_USERS_QUERY',
);

export interface GetsLatestUsersQueryPort {
  getLatestUsersQuery(): Observable<UserQuery[]>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SwitchesCssDomainCommand } from './switches-css-domain.command';

export const SWITCHES_CSS_DOMAIN_COMMAND = new InjectionToken<SwitchesCssDomainCommandPort>(
  'SWITCHES_CSS_DOMAIN_COMMAND',
);

export interface SwitchesCssDomainCommandPort {
  switchCssDomain(command: SwitchesCssDomainCommand): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_EDITING_INTEGRATION_QUERY = new InjectionToken<IsEditingIntegrationQueryPort>(
  'IS_EDITING_INTEGRATION_QUERY',
);

export interface IsEditingIntegrationQueryPort {
  isEditing(): Observable<boolean>;
}

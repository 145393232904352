import { ComponentType } from '@angular/cdk/portal';

export class StepConfigurationQuery<T> {
  constructor(
    readonly title: string,
    readonly ariaLabel: string,
    private readonly editable: boolean,
    private readonly completed: boolean,
    readonly shown: boolean,
    private readonly component: ComponentType<T>,
  ) {}
}

import { Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { FeaturesState } from '../application/features.state';
import { FeaturesLoadedEvent } from '../events/features-loaded.event';

@Injectable()
export class FeaturesLoadedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [FeaturesLoadedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _featuresState: FeaturesState) {}

  handle([event]: [FeaturesLoadedEvent]): void {
    this._featuresState.setFeatures(event.features);
  }
}

import { Class } from '@app.cobiro.com/core/utils';
import { InjectionToken } from '@angular/core';
import { DataLayerTrackingData } from './data-layer-tracking-data';
import { ApplicationEvent } from '@cobiro/eda';

export interface DataLayerTrackingMapper {
  eventClass: Class<ApplicationEvent>;
  mapEvent: (event: ApplicationEvent) => DataLayerTrackingData;
}

export const DATA_LAYER_TRACKING_MAPPER = new InjectionToken<DataLayerTrackingMapper>(
  'DATA_LAYER_TRACKING_MAPPER',
);

export const provideDataLayerTrackingMapper = (mapper: Class<DataLayerTrackingMapper>) => {
  return {
    provide: DATA_LAYER_TRACKING_MAPPER,
    useClass: mapper,
    multi: true,
  };
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedImageUploadModule } from '@app.cobiro.com/shared/image-upload';
import { CreateNewClientModalComponent } from './create-new-client-modal.component';
import { CreateNewClientFormComponentModule } from './create-new-client-form/create-new-client-form.component-module';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    MatIconModule,
    SharedImageUploadModule,
    CreateNewClientFormComponentModule,
  ],
  declarations: [CreateNewClientModalComponent],
  exports: [CreateNewClientModalComponent],
})
export class CreateNewClientModalComponentModule {}

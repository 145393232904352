import { NgModule } from '@angular/core';
import { DELETE_WORKSPACE_USER_COMMAND } from '../ports/primary/deletes-workspace-user.command-port';
import { GETS_CURRENT_WORKSPACE_QUERY } from '../ports/primary/gets-current-workspace.query-port';
import { GETS_WORKSPACES_LIST_QUERY } from '../ports/primary/gets-workspace-list.query';
import { GETS_WORKSPACE_USERS_LIST_QUERY } from '../ports/primary/gets-workspace-users-list.query-port';
import { LOAD_WORKSPACE_USERS_COMMAND } from '../ports/primary/load-workspace-users.command-port';
import { SETS_CONTEXT_WORKSPACE_QUERY } from '../ports/primary/sets-context-workspace.query-port';
import { SETS_CURRENT_WORKSPACE_QUERY } from '../ports/primary/sets-current-workspace.query-port';
import { SETS_USERS_LIST_SEARCH_PHRASE_WORKSPACE_COMMAND_PORT } from '../ports/primary/sets-search-phrase-workspace-users.command-port';
import { WorkspaceUsersState } from './workspace-users.state';
import { ADDS_ACCESS_COMMAND } from '../ports/primary/adds-access.command-port';
import { SENDS_INVITATION_COMMAND } from '../ports/primary/sends-invitation.command-port';

@NgModule({
  providers: [
    WorkspaceUsersState,
    { provide: GETS_CURRENT_WORKSPACE_QUERY, useExisting: WorkspaceUsersState },
    { provide: SETS_CURRENT_WORKSPACE_QUERY, useExisting: WorkspaceUsersState },
    {
      provide: LOAD_WORKSPACE_USERS_COMMAND,
      useExisting: WorkspaceUsersState,
    },
    {
      provide: GETS_WORKSPACE_USERS_LIST_QUERY,
      useExisting: WorkspaceUsersState,
    },
    {
      provide: SETS_USERS_LIST_SEARCH_PHRASE_WORKSPACE_COMMAND_PORT,
      useExisting: WorkspaceUsersState,
    },
    {
      provide: DELETE_WORKSPACE_USER_COMMAND,
      useExisting: WorkspaceUsersState,
    },
    {
      provide: SETS_CONTEXT_WORKSPACE_QUERY,
      useExisting: WorkspaceUsersState,
    },
    {
      provide: GETS_WORKSPACES_LIST_QUERY,
      useExisting: WorkspaceUsersState,
    },
    { provide: ADDS_ACCESS_COMMAND, useExisting: WorkspaceUsersState },
    { provide: SENDS_INVITATION_COMMAND, useExisting: WorkspaceUsersState },
  ],
})
export class WorkspaceUsersStateModule {}

<div
  class="position-relative d-flex flex-column cs-p-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
    <img
      class="align-self-center cs-width-60-xs cs-height-60-xs cs-mb-10"
      src="/assets/apps/icon/google_css.svg"
    />
    <div
      class="position-relative d-flex flex-column justify-content-between align-items-center cs-bg-shade-0"
    >
      <div class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
        <h5 class="align-self-center cs-title-5 cs-mb-10">
          {{ 'cobiro_pro_label_manager_no_integration_title' | language }}
        </h5>
        <p class="align-self-center text-center cs-width-440-sm cs-text-1">
          {{ 'cobiro_pro_label_manager_no_integration_subtitle' | language }}
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-between cs-mt-20 w-100">
      <a
        class="cs-btn cs-btn-flat w-100"
        href="https://support.cobiro.com/hc/en-us/requests/new"
        target="_blank"
      >
        {{ 'cobiro_pro_label_manager_config_window_help_button' | language }}
      </a>
    </div>
  </div>
</div>

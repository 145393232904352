/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { PaymentPlanDto } from '../../secondary/dto/payments/payment-plan.dto';
import { ProductType } from '../clients/product-list.query';

export enum PaymentPlanCurrency {
  USD = '$',
}

export type PlanTypeCollection<T> = {
  [key in PaymentPlanPeriod]: T;
};

export enum PaymentPlanPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export class PaymentPlanId {
  public readonly value: string;
  private _typeMapper: PlanTypeCollection<string> = {
    [PaymentPlanPeriod.MONTHLY]: 'monthly',
    [PaymentPlanPeriod.YEARLY]: 'annual',
  };
  constructor(name: string, paymentPlan: PaymentPlanPeriod) {
    this.value = `${name}-${this._typeMapper[paymentPlan]}`.toLocaleLowerCase();
  }
}

export class PaymentPlanOptionQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly description: string,
    public readonly period: string,
    public price: string,
    public advantages: string[],
    public discount?: string,
    public priceBeforeDiscount?: string,
  ) {}

  static fromDto(
    dto: PaymentPlanDto,
    period: PaymentPlanPeriod,
    isBundlePrice: boolean,
  ): PaymentPlanOptionQuery {
    const prices = isBundlePrice ? dto.bundledPrices : dto.prices;
    const advantages = dto.advantages.map(advantage => advantage.value);
    advantages.unshift(dto.paymentSchedule[period], dto.paymentDiscount[period]);
    const newPaymentPlanOption = new PaymentPlanOptionQuery(
      `${new PaymentPlanId(dto.name, period).value}${isBundlePrice ? '-bundled' : ''}`,
      `payment_plans_period_${period}_title`,
      `payment_plans_period_pay_${period}_description`,
      period,
      `${PaymentPlanCurrency.USD}${prices[period]}`,
      advantages.filter(item => item !== ''),
    );
    if (period === PaymentPlanPeriod.YEARLY) {
      newPaymentPlanOption.discount = `${Math.floor(
        ((prices.monthly - prices.yearly) / prices.monthly) * 100,
      )}%`;
      newPaymentPlanOption.priceBeforeDiscount = `${PaymentPlanCurrency.USD}${prices.monthly * 12}`;
    }
    return newPaymentPlanOption;
  }
  static extractPlanName(planId: string): string {
    return planId.split('-')[0];
  }
}

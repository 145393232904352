import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SwitchesIntegrationCommand } from './switches-integration.command';

export const SWITCHES_INTEGRATION_COMMAND = new InjectionToken<SwitchesIntegrationCommandPort>(
  'SWITCHES_INTEGRATION_COMMAND',
);

export interface SwitchesIntegrationCommandPort {
  switchIntegration(command: SwitchesIntegrationCommand): Observable<void>;
}

import { ChecksAppAccessQueryPort } from '../ports/primary/checks-app-access-query.port';
import { Observable, of } from 'rxjs';
import { GetsShutdownApplicationsQueryPort } from '../ports/primary/gets-shutdown-applications.query-port';
import { ShutdownApplicationsQuery } from '../ports/primary/shutdown-applications.query';
import {
  GETS_USER_ROLE_DTO,
  GetsUserRoleDtoPort,
} from '../ports/secondary/gets-user-role-dto.port';
import { Inject, Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { USER_ROLE } from '../ports/secondary/team.dto';
import { SetsUserPersonalSitesCommandPort } from '../ports/primary/sets-user-personal-sites.command-port';

@Injectable()
export class TeamAccessState
  implements
    ChecksAppAccessQueryPort,
    GetsShutdownApplicationsQueryPort,
    SetsUserPersonalSitesCommandPort
{
  private _userTeamRoles: Map<string, USER_ROLE> = new Map();
  private _personalSites: Set<string> = new Set();
  private readonly _featureFlagPrefix = 'member_user_has_';

  constructor(
    @Inject(GETS_USER_ROLE_DTO) private _getsUserRole: GetsUserRoleDtoPort,
    private _featureFlagsState: FeatureFlagsState,
  ) {}

  checkAppAccess(appIdentifier: string, siteId: string): Observable<boolean> {
    if (this._personalSites.has(siteId)) {
      return of(true);
    }

    return (
      this._userTeamRoles.has(siteId)
        ? of(this._userTeamRoles.get(siteId))
        : this._fetchRole(siteId)
    ).pipe(
      switchMap(userRole => {
        if (userRole === null) {
          return of(false);
        }

        return userRole === USER_ROLE.admin
          ? this._featureFlagsState.hasFlags([this._featureFlagPrefix + appIdentifier])
          : of(true);
      }),
    );
  }

  private _fetchRole(siteId: string): Observable<USER_ROLE> {
    return this._getsUserRole.getsUserRole(siteId).pipe(
      tap(userRole => {
        this._userTeamRoles.set(siteId, userRole);
      }),
    );
  }

  getShutdownApplications(): Observable<ShutdownApplicationsQuery> {
    return of(new ShutdownApplicationsQuery(['website-builder', 'google-search', 'zapier']));
  }

  setUserSites(sites: string[]): void {
    this._personalSites = new Set(sites);
  }
}

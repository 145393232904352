import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { Injectable } from '@angular/core';
import { PaymentBillingEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class DataLayerPaymentBillingEventMapper implements DataLayerTrackingMapper {
  eventClass = PaymentBillingEvent;
  mapEvent(event: PaymentBillingEvent) {
    return {
      eventName: `/Payment - Billing`,
      eventData: {
        siteId: event.siteId,
        workspaceId: event.workspaceId,
        agencyName: event.agencyName,
        contactEmail: event.contactEmail,
        addressLine1: event.addressLine1,
        addressLine2: event.addressLine2,
        zipCode: event.zipCode,
        city: event.city,
        countryCode: event.countryCode,
        vatNumber: event.vatNumber,
        avatar: event.avatar,
      },
    };
  }
}

import { UnverifiedUserSignedInEvent } from '@app.cobiro.com/core/events';
import { Injectable } from '@angular/core';
import { UserRegisterState } from '@app.cobiro.com/user/core';
import { Router } from '@angular/router';
import {
  ApplicationEventHandler,
  ApplicationEventsHandler,
  ongoingEventsOrchestrationStrategy,
} from '@cobiro/eda';

@Injectable()
export class UnverifiedUserSignedInEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UnverifiedUserSignedInEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    private readonly _router: Router,
    private readonly _userRegisterState: UserRegisterState,
  ) {}

  handle([event]: [UnverifiedUserSignedInEvent]): void {
    this._router.navigate(['/auth/register/verify']).then(() => {
      this._userRegisterState.setCredentials(event.email, event.password);
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClientDeleteConfirmationModalComponent } from './client-delete-confirmation-modal/client-delete-confirmation-modal.component';
import { ClientNotDeletableModalComponent } from './client-not-deletable-modal/client-not-deletable-modal.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ClientDeleteConfirmationModalComponent, ClientNotDeletableModalComponent],
  imports: [CommonModule, LanguageModule, MatDialogModule],
})
export class ClientModalsModule {}

import { ApplicationEvent } from '@cobiro/eda';

export class CobiroProContextChangedEvent extends ApplicationEvent {
  constructor(
    public readonly context: {
      proModeEnabled: boolean;
      selectedTeam: { id: string; name: string; userRole: string; avatar: string | null };
      selectedWorkspace: {
        id: string;
        name: string;
        avatar: string | null;
        membersCount: number | null;
      };
    },
  ) {
    super();
  }
}

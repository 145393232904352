import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_APPS_DTO_PORT = new InjectionToken('GETS_APPS_DTO_PORT');

export interface GetsApplicationsDtoPort {
  getAll(): Observable<AppDTO[]>;
}

export interface AppDTO {
  identifier: string;
  name: string;
}

/* eslint-disable max-lines-per-function */
import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { Injectable } from '@angular/core';
import { PaymentConfirmEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class DataLayerPaymentConfirmEventMapper implements DataLayerTrackingMapper {
  eventClass = PaymentConfirmEvent;
  mapEvent(event: PaymentConfirmEvent) {
    return {
      eventName: `/Payment - Confirm`,
      eventData: {
        workspaceId: event.workspaceId,
        planId: event.planId,
        billingData: {
          countryCode: event.billingData.countryCode,
          vatNumber: event.billingData.vatNumber,
        },
        discountCode: event.discountCode
          ? {
              code: event.discountCode.code,
              type: event.discountCode.type,
              value: event.discountCode.value,
              duration: event.discountCode.duration,
            }
          : null,
        planEstimate: {
          nett: event.planEstimate.nett,
          gross: event.planEstimate.gross,
          vatValue: event.planEstimate.vatValue,
          vatPercentage: event.planEstimate.vatPercentage,
          hasValidDiscountCode: event.planEstimate.hasValidDiscountCode,
          grossValueBeforeDiscount: event.planEstimate.grossValueBeforeDiscount,
        },
      },
    };
  }
}

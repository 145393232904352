import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeletesWorkspaceDto } from './deletes-workspace.dto';

export const DELETES_WORKSPACE_DTO = new InjectionToken<DeletesWorkspaceDtoPort>(
  'DELETES_WORKSPACE_DTO',
);

export interface DeletesWorkspaceDtoPort {
  deleteWorkspace(dto: DeletesWorkspaceDto): Observable<void>;
}

import { NgModule } from '@angular/core';
import { ContextState } from './context.state';
import { GETS_COBIRO_PRO_CONTEXT_QUERY } from '../ports/primary/gets-cobiro-pro-context.query-port';
import { ENABLES_PRO_MODE_COMMAND } from '../ports/primary/enables-pro-mode.command-port';
import { GOES_TO_PERSONAL_MODE_COMMAND } from '../ports/primary/goes-to-personal-mode.command-port';
import { SETS_SELECTED_TEAM_COMMAND } from '../ports/primary/sets-selected-team.command-port';
import { FETCHES_COBIRO_PRO_CONTEXT_COMMAND } from '../ports/primary/fetches-cobiro-pro-context.command-port';
import { NAVIGATE_TO_SELECTED_TEAM_HOME_COMMAND } from '../ports/primary/navigate-to-selected-team-home.command-port';
import { CLEAR_CONTEXT_COMMAND } from '../ports/primary/clears-context.command-port';
import { SET_LATEST_COBIRO_PRO_LOGIN_COMMAND } from '../ports/primary/sets-latest-cobiro-pro-login.command-port';
import { VERIFY_LATEST_LOGIN_COMMAND } from '../ports/primary/verify-latest-login.command-port';
import { DISABLES_PRO_MODE_COMMAND } from '../ports/primary/disables-pro-mode.command-port';
import { SETS_SELECTED_WORKSPACE_COMMAND } from '../ports/primary/sets-selected-workspace.command-port';

@NgModule({
  providers: [
    ContextState,
    { provide: GETS_COBIRO_PRO_CONTEXT_QUERY, useExisting: ContextState },
    { provide: ENABLES_PRO_MODE_COMMAND, useExisting: ContextState },
    { provide: DISABLES_PRO_MODE_COMMAND, useExisting: ContextState },
    { provide: GOES_TO_PERSONAL_MODE_COMMAND, useExisting: ContextState },
    { provide: SETS_SELECTED_TEAM_COMMAND, useExisting: ContextState },
    { provide: SETS_SELECTED_WORKSPACE_COMMAND, useExisting: ContextState },
    { provide: FETCHES_COBIRO_PRO_CONTEXT_COMMAND, useExisting: ContextState },
    { provide: NAVIGATE_TO_SELECTED_TEAM_HOME_COMMAND, useExisting: ContextState },
    { provide: CLEAR_CONTEXT_COMMAND, useExisting: ContextState },
    {
      provide: SET_LATEST_COBIRO_PRO_LOGIN_COMMAND,
      useExisting: ContextState,
    },
    {
      provide: VERIFY_LATEST_LOGIN_COMMAND,
      useExisting: ContextState,
    },
  ],
})
export class ContextStateModule {}

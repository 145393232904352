import { Injectable } from '@angular/core';
import { RedirectsToAppCommand } from '../ports/primary/redirect-to-app.command-port';
import { Router } from '@angular/router';

@Injectable()
export class RedirectToAppCommandService implements RedirectsToAppCommand {
  private readonly _pathsMap = new Map<string, string[]>([
    ['websitebuilder', ['builder']],
    ['ecommerce', ['ecommerce', 'products']],
    ['cobirocss', ['app', 'google-css']],
    ['domains', ['domains']],
  ]);

  private readonly _notInstalledAppPath = new Map<string, string[]>([
    ['ecommerce', ['ecommerce', 'products']],
    ['cobirocss', ['store', 'app', 'google-css']],
    ['domains', ['domains']],
  ]);

  constructor(private readonly _router: Router) {}

  redirectToApp(
    siteId: string | null,
    appId: string,
    sitePublicId: string,
    appInstalled: boolean,
  ): void {
    if (!siteId) {
      this._router.navigate(['/create-site'], { queryParams: { siteId: sitePublicId } });
      return;
    }

    const redirectPath = appInstalled
      ? this._pathsMap.get(appId)
      : this._notInstalledAppPath.get(appId);

    this._router.navigate(['site', siteId, ...redirectPath]);
  }
}

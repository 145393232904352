import { Injectable } from '@angular/core';
import { ManagesClientCommandPort } from '../ports/primary/manages-client-command.port';
import { Router } from '@angular/router';
import { ManagesClientCommand } from '../ports/primary/manages-client.command';

@Injectable()
export class ManagesClientCommandHandler implements ManagesClientCommandPort {
  constructor(private readonly _router: Router) {}

  manageClient({ siteId, sitePublicId }: ManagesClientCommand): void {
    if (!siteId) {
      this._router.navigate(['create-site'], {
        queryParams: { siteId: sitePublicId, websiteCreationContext: 'COBIRO_PRO' },
      });
      return;
    }

    this._router.navigate(['site', siteId]);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HasDataCollection, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsPaymentPlans } from '../../domain/gets-payment-plans';
import { PaymentPlanData } from '../../domain/payment-plan';
import { PlanTypeCollection } from '../../domain/payment-plan-prices.vo';

export interface PaymentPlanDataAttributes {
  name: string;
  goal: string;
  prices: { [key: string]: number };
  features: { id: string; value: string; additionalInformation?: boolean }[];
}

@Injectable()
export class HttpGetsPaymentPlanService implements GetsPaymentPlans {
  readonly headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(
    private readonly _http: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  getPaymentPlans(): Observable<PaymentPlanData[]> {
    return this._http
      .get<HasDataCollection<PaymentPlanDataAttributes>>(
        `${this._getsConfig.get(
          'publicCdn',
        )}/json/v2/payment-plans.json?timestamp=${new Date().valueOf()}`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        map(response =>
          response.data.map(({ attributes }) => ({
            name: attributes.name,
            goal: attributes.goal,
            features: attributes.features.map(feature => ({
              id: feature.id,
              value: feature.value,
              additionalInformation: feature.additionalInformation || false,
            })),
            prices: attributes.prices as PlanTypeCollection<number>,
          })),
        ),
      );
  }
}

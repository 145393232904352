import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DisplaysClientInfoCommand } from './displays-client-info.command';

export const DISPLAYS_CLIENT_INFO_COMMAND = new InjectionToken<DisplaysClientInfoCommandPort>(
  'DISPLAYS_CLIENT_INFO_COMMAND',
);

export interface DisplaysClientInfoCommandPort {
  displayClientInfo(command: DisplaysClientInfoCommand): Observable<void>;
}

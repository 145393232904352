import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataLayerMonitorModule } from './data-layer-monitor/data-layer-monitor.module';
import { SurveyMonitorModule } from './survey-monitor/survey-monitor.module';
import { EventLoggerService } from './event-logger/event-logger.service';
import { EventLoggerContext } from './event-logger/application/event-logger.context';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { TRACKS_IN_DATA_LAYER } from './event-logger/domain/tracks-in-data-layer';
import { WindowTracksInDataLayerService } from './event-logger/infrastructure/window-tracks-in-data-layer.service';
import { provideEventLogMonitor } from './event-logger/domain/event-log-monitor';
import { ContextEditorMonitor } from './event-logger/application/monitors/context-setter/context-editor.monitor';

@NgModule({
  imports: [CommonModule, DataLayerMonitorModule, SurveyMonitorModule],
  providers: [
    EventLoggerService,
    EventLoggerContext,
    {
      provide: WINDOW,
      useValue: window,
    },
    {
      provide: TRACKS_IN_DATA_LAYER,
      useClass: WindowTracksInDataLayerService,
    },
    provideEventLogMonitor(ContextEditorMonitor),
  ],
})
export class LoggingModule {
  constructor(private readonly _eventLoggerService: EventLoggerService) {
    this._eventLoggerService.init();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUserModalComponent } from './add-user-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [AddUserModalComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [AddUserModalComponent],
})
export class AddUserModalComponentModule {}

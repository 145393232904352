/* eslint-disable complexity */
import { TemplatePortal } from '@angular/cdk/portal';
import { CdkStep } from '@angular/cdk/stepper';
import {
  AfterContentInit,
  Component,
  ContentChild,
  forwardRef,
  Inject,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { map, startWith, Subscription, switchMap } from 'rxjs';
import { LabelManagerNonCssIntegrationStepperComponent } from '../../label-manager-non-css-integration-stepper/label-manager-non-css-integration-stepper.component';
import { LabelManagerNonCssIntegrationtStepContentDirective } from './label-manager-non-css-integration-step-content.directive';

@Component({
  selector: 'lib-cobiro-pro-label-manager-non-css-integration-step',
  templateUrl: './label-manager-non-css-integration-step.component.html',
  providers: [{ provide: CdkStep, useExisting: LabelManagerNonCssIntegrationStepComponent }],
})
export class LabelManagerNonCssIntegrationStepComponent
  extends CdkStep
  implements AfterContentInit, OnDestroy
{
  private _isSelected = Subscription.EMPTY;
  _portal!: TemplatePortal;

  @ContentChild(LabelManagerNonCssIntegrationtStepContentDirective, { static: false })
  _lazyContent!: LabelManagerNonCssIntegrationtStepContentDirective;

  constructor(
    @Inject(forwardRef(() => LabelManagerNonCssIntegrationStepperComponent))
    stepper: LabelManagerNonCssIntegrationStepperComponent,
    private _viewContainerRef: ViewContainerRef,
  ) {
    super(stepper);
  }

  ngAfterContentInit(): void {
    this._isSelected = this._stepper.steps.changes
      .pipe(
        switchMap(() => {
          return this._stepper.selectionChange.pipe(
            map(event => event.selectedStep === this),
            startWith(this._stepper.selected === this),
          );
        }),
      )
      .subscribe(isSelected => {
        if (isSelected && this._lazyContent && !this._portal) {
          this._portal = new TemplatePortal(this._lazyContent._template, this._viewContainerRef);
        }
      });
  }

  ngOnDestroy() {
    this._isSelected.unsubscribe();
  }
}

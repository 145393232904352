import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_CURRENT_INDEX_QUERY = new InjectionToken<GetsCurrentIndexQueryPort>(
  'GETS_CURRENT_INDEX_QUERY',
);

export interface GetsCurrentIndexQueryPort {
  getCurrentIndex(): Observable<number>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const CHANGES_PASSWORD_DTO = new InjectionToken<ChangesPasswordDtoPort>(
  'CHANGES_PASSWORD_DTO',
);

export interface ChangesPasswordDtoPort {
  change(oldPassword: string, newPassword: string): Observable<boolean>;
}

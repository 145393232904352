import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GetsSelectedPlanQueryPort {
  getPlan(): Observable<string>;
}

export const GETS_SELECTED_PLAN_QUERY = new InjectionToken<GetsSelectedPlanQueryPort>(
  'GETS_SELECTED_PLAN_QUERY',
);

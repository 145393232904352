import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig, WINDOW } from '@app.cobiro.com/core/utils';
import { DatadogRumDriverConfig, makeDatadogConfig } from './rum.config';

export interface DatadogRumDriver {
  init(config: DatadogRumDriverConfig): void;
}

@Injectable({
  providedIn: 'root',
})
export class RumResolver {
  constructor(
    @Inject(WINDOW) private _window: { DD_RUM: DatadogRumDriver },
    @Inject(ENV_CONFIG) private _getsConfig: GetsConfig,
  ) {}

  resolve(): boolean {
    if (
      ['production', 'staging'].includes(this._getsConfig.get('name') as string) &&
      Object.keys(this._window).includes('DD_RUM')
    ) {
      this._window.DD_RUM.init(makeDatadogConfig(this._getsConfig.get('name') as string));
    }
    return true;
  }
}

import { Inject, Injectable } from '@angular/core';
import {
  SignedInEvent,
  SocialSignedInEvent,
  TrackingDataCollectedEvent,
} from '@app.cobiro.com/core/events';
import { DomainError } from '@app.cobiro.com/core/state';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { UserEmailTakenError } from '@app.cobiro.com/user/core';
import {
  SIGN_UP_PROVIDERS,
  SIGNS_UP_BY,
  SignsUpBy,
  SignsUpByFacebookCommand,
  SignsUpByFacebookCommandPort,
  SignsUpByGoogleCommand,
  SignsUpByGoogleCommandPort,
  SocialAuth,
  SocialMissingEmailError,
  SocialUnexpectedError,
} from '@app.cobiro.com/user/social-sign-up';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { from, mapTo, Observable, of, take, tap } from 'rxjs';
import { catchError, concatMap, filter } from 'rxjs/operators';
import { UserSignedUpEvent } from '../events/user-signed-up.event';
import { UserVerifiedEvent } from '../events/user-verified.event';
import { SignsUpByEmailCommand } from '../ports/primary/signs-up-by-email.command';
import { SignsUpByEmailCommandPort } from '../ports/primary/signs-up-by-email.command-port';
import {
  SIGNS_UP_BY_EMAIL_DTO,
  SignsUpByEmailDtoPort,
} from '../ports/secondary/signs-up-by-email.dto-port';

@Injectable()
export class SignUpState
  implements SignsUpByEmailCommandPort, SignsUpByFacebookCommandPort, SignsUpByGoogleCommandPort
{
  private readonly _errors = new Map<string, string>([
    [SocialMissingEmailError.code, 'facebook_email_unable'],
    [SocialUnexpectedError.code, 'social_default_fail_message'],
  ]);

  constructor(
    @Inject(APPLICATION_BUS) private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    @Inject(SIGNS_UP_BY_EMAIL_DTO) private readonly _signsUpByEmailDto: SignsUpByEmailDtoPort,
    @Inject(SIGNS_UP_BY) private readonly _signsUpByDto: SignsUpBy,
    private readonly _alert: HuiAlert,
    private readonly _socialAuth: SocialAuthService,
  ) {}

  signUpByEmail({ email, password, captchaToken }: SignsUpByEmailCommand): Observable<void> {
    return this._signsUpByEmailDto.signUpByEmail(email, password, captchaToken).pipe(
      take(1),
      tap(result => {
        this._applicationBus.dispatch(new UserSignedUpEvent(result.userId, email));
        this._alert.open('success', 'signup_successful');
      }),
      catchError(e => {
        this._alert.open(
          'error',
          e && e.name === UserEmailTakenError.code ? e.message : 'social_default_fail_message',
        );
        return of(void 0);
      }),
      mapTo(void 0),
    );
  }

  signUpByFacebook({ intent }: SignsUpByFacebookCommand): Observable<SocialAuth | null> {
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up social facebook'));

    return from(this._socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      catchError(() => {
        this._alert.open('error', 'social_default_fail_message');
        return of(null);
      }),
      filter(socialUser => !!socialUser),
      concatMap(socialUser =>
        this._signsUpByDto.signUpBy(SIGN_UP_PROVIDERS.FACEBOOK, socialUser.authToken).pipe(
          catchError((err: DomainError) => {
            const message = this._errors.get(err.name);
            this._alert.open('error', message);
            return of(null);
          }),
        ),
      ),
      filter(auth => !!auth),
      tap(auth => {
        this._applicationBus.dispatch(
          new SignedInEvent(
            auth.userId,
            auth.tokenType,
            auth.accessToken,
            auth.refreshToken,
            false,
          ),
        );
        this._applicationBus.dispatch(new UserVerifiedEvent());
      }),
      tap(auth =>
        this._applicationBus.dispatch(
          new SocialSignedInEvent(intent, 'Facebook', String(auth.userId)),
        ),
      ),
      take(1),
    );
  }

  signUpByGoogle({ intent }: SignsUpByGoogleCommand): Observable<SocialAuth | null> {
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up social google'));

    return this._socialAuth.authState.pipe(
      catchError(() => {
        this._alert.open('error', 'social_default_fail_message');
        return of(null);
      }),
      filter(socialUser => !!socialUser),
      concatMap(socialUser =>
        this._signsUpByDto.signUpBy(SIGN_UP_PROVIDERS.GOOGLE, socialUser.idToken).pipe(
          catchError(err => {
            const message = this._errors.get(err.name);
            this._alert.open('error', message);
            return of(null);
          }),
        ),
      ),
      filter(auth => !!auth),
      tap(auth => {
        this._applicationBus.dispatch(
          new SignedInEvent(
            auth.userId,
            auth.tokenType,
            auth.accessToken,
            auth.refreshToken,
            false,
          ),
        );
        this._applicationBus.dispatch(new UserVerifiedEvent());
      }),
      tap(auth =>
        this._applicationBus.dispatch(
          new SocialSignedInEvent(intent, 'Google', String(auth.userId)),
        ),
      ),
      take(1),
    );
  }

  // signUpByGoogle({ intent }: SignsUpByGoogleCommand): Observable<SocialAuth | null> {
  //   this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up social google'));

  //   return from(this._socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID)).pipe(
  //     catchError(() => {
  //       this._alert.open('error', 'social_default_fail_message');
  //       return of(null);
  //     }),
  //     filter(socialUser => !!socialUser),
  //     concatMap(socialUser =>
  //       this._signsUpByDto.signUpBy(SIGN_UP_PROVIDERS.GOOGLE, socialUser.idToken).pipe(
  //         catchError(err => {
  //           const message = this._errors.get(err.name);
  //           this._alert.open('error', message);
  //           return of(null);
  //         }),
  //       ),
  //     ),
  //     filter(auth => !!auth),
  //     tap(auth => {
  //       this._applicationBus.dispatch(
  //         new SignedInEvent(
  //           auth.userId,
  //           auth.tokenType,
  //           auth.accessToken,
  //           auth.refreshToken,
  //           false,
  //         ),
  //       );
  //       this._applicationBus.dispatch(new UserVerifiedEvent());
  //     }),
  //     tap(auth =>
  //       this._applicationBus.dispatch(
  //         new SocialSignedInEvent(intent, 'Google', String(auth.userId)),
  //       ),
  //     ),
  //     take(1),
  //   );
  // }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerRecommendationsComponent } from './label-manager-recommendations.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';

@NgModule({
  declarations: [LabelManagerRecommendationsComponent],
  imports: [CommonModule, HuiLoadingModule, LanguageModule, MatIconModule],
  exports: [LabelManagerRecommendationsComponent],
})
export class LabelManagerRecommendationsComponentModule {}

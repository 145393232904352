import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { GETS_APPS_META_DTO_PORT } from '../../../../application/ports/secondary/gets-apps-meta.dto-port';
import { HttpAppsCdnService } from './http-apps-cdn.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpAppsCdnService,
    { provide: GETS_APPS_META_DTO_PORT, useExisting: HttpAppsCdnService },
  ],
})
export class HttpAppsCdnServiceModule {}

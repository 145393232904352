import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamSwitcherControllerComponent } from './team-switcher-controller.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgModuleWithProviders } from 'ng-mocks';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { GETS_CURRENT_TEAM_QUERY } from '../../../../application/ports/primary/gets-current-team.query-port';

@NgModule({
  declarations: [TeamSwitcherControllerComponent],
  imports: [CommonModule, MatIconModule, RouterModule, LanguageModule],
  exports: [TeamSwitcherControllerComponent],
})
export class TeamSwitcherControllerComponentModule {
  static forRoot(
    getsCurrentTeamQueryAdapter: object,
  ): NgModuleWithProviders<TeamSwitcherControllerComponentModule> {
    return {
      ngModule: TeamSwitcherControllerComponentModule,
      providers: [
        {
          provide: GETS_CURRENT_TEAM_QUERY,
          useExisting: getsCurrentTeamQueryAdapter,
        },
      ],
    };
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspacesContext } from './workspaces-context';

export const PATCHES_WORKSPACES_CONTEXT_STORAGE =
  new InjectionToken<PatchesWorkspacesContextStoragePort>('PATCHES_WORKSPACES_CONTEXT_STORAGE');

export interface PatchesWorkspacesContextStoragePort {
  patch(state: Partial<WorkspacesContext>): Observable<void>;
}

import { ChartOptions } from './chart-option.query';

export const PIE_CHART_OPTION: Partial<ChartOptions> = {
  series: [],
  tooltip: { enabled: true },
  chart: {
    type: 'pie',
    width: '80%',
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -5,
        minAngleToShowLabel: 15,
      },
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  labels: ['overIndex', 'index', 'nearIndex', 'noIndex', 'underIndex'],
  dataLabels: {
    enabled: true,
    dropShadow: {
      enabled: false,
    },
  },
  colors: ['#0063ff', '#3677FA', '#4A33FF', '#ac45fc', '#9117fa'],
  title: {
    text: '',
    align: 'center',
    style: {
      fontSize: '24px',
      fontWeight: 600,
      fontFamily: 'Poppins,sans-serif!important',
      color: '#111',
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  noData: {
    text: 'There is no analyzed data yet',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: 'black',
      fontSize: '18px',
      fontFamily: '"Poppins", sans-serif !important',
    },
  },
};

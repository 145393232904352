import { Component, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  ChangesPasswordQueryPort,
  CHANGES_PASSWORD_QUERY,
} from '../../../../application/ports/primary/changes-password.query-port';

@Component({
  selector: 'lib-cobiro-pro-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProChangePasswordComponent implements OnDestroy {
  private _ngDestroy$ = new Subject<void>();

  inputOldPassword = false;

  readonly form: UntypedFormGroup = this._fb.group({
    oldPassword: this._fb.control('', Validators.required),
    newPassword: this._fb.control(''),
  });
  readonly isProcessing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _fb: UntypedFormBuilder,
    @Inject(CHANGES_PASSWORD_QUERY)
    private readonly _changePassword: ChangesPasswordQueryPort,
  ) {}

  onFormSubmitted() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { oldPassword, newPassword } = this.form.value;
    this._changePassword
      .changePassword(oldPassword, newPassword)
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe(success => {
        if (success) {
          this.form.patchValue({ oldPassword: '', newPassword: '' });
          this.form.markAsUntouched();
          this.form.markAsPristine();
        }
      });
  }
  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

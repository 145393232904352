import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserAdditionalDataChangedEvent } from '@app.cobiro.com/core/events';
import {
  SET_UPGRADE_WIDGET_VISIBLE_COMMAND,
  SetsUpgradeWidgetVisibleCommandPort,
} from '../../../application/primary/set-upgrade-widget-visible.command-port';

@Injectable()
export class UpgradeWidgetUserAdditionalDataChangedEventHandler
  implements ApplicationEventsHandler
{
  eventsClasses = [UserAdditionalDataChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SET_UPGRADE_WIDGET_VISIBLE_COMMAND)
    private readonly _setWidgetVisible: SetsUpgradeWidgetVisibleCommandPort,
  ) {}

  handle([event]: [UserAdditionalDataChangedEvent]) {
    this._setWidgetVisible.set(!event.hasTeams);
  }
}

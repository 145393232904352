import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { GetsInstalledAppsIdsDtoPort } from '../../../../application/ports/secondary/gets-installed-apps-ids';

export interface InstallationModelAttributes {
  site_id: number;
  app_identifier: string;
}

export interface InstallationModel {
  id: string;
  type: string;
  attributes: InstallationModelAttributes;
}

@Injectable()
export class HttpInstalledAppsService implements GetsInstalledAppsIdsDtoPort {
  constructor(private _client: GatewayClient) {}

  getAll(siteId: string): Observable<string[]> {
    return this._client
      .get(`v2/app-registry/${siteId}/installations`)
      .pipe(
        map(({ data }: { data: InstallationModel[] }) => this.getInstallationsFromModels(data)),
      );
  }

  private getInstallationsFromModels(installationModels: InstallationModel[]): string[] {
    return installationModels.map(
      ({ attributes }: { attributes: InstallationModelAttributes }) => attributes.app_identifier,
    );
  }
}

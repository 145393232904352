import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Injectable } from '@angular/core';
import { IntegrationIdChangedEvent } from '../../../application/events/integration-id-changed.event';

@Injectable()
export class IntegrationIdChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [IntegrationIdChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  handle([event]: [IntegrationIdChangedEvent]) {
    return;
  }
}

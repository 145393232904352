import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { SignInCompletedEvent } from '@app.cobiro.com/core/events';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerSignInCompletedEventMapper implements DataLayerTrackingMapper {
  eventClass = SignInCompletedEvent;

  mapEvent(event: SignInCompletedEvent) {
    return {
      eventName: '/Hub - Account - AuthData successful - v2',
      eventData: {
        user_id: event.userId,
      },
    };
  }
}

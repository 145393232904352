import { NgModule } from '@angular/core';
import { CREATES_NEW_SUBSCRIPTION_DTO } from '../../../../application/ports/secondary/dto/payments/creates-new-subscription.dto-port';
import { HttpManagesPlanService } from './http-manages-plan.service';

@NgModule({
  providers: [
    HttpManagesPlanService,
    { provide: CREATES_NEW_SUBSCRIPTION_DTO, useExisting: HttpManagesPlanService },
  ],
})
export class HttpManagesPlanServiceModule {}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { ClientAddedEvent } from '../../../application/events/client-added.event';
import { Inject, Injectable } from '@angular/core';
import {
  STORES_NEW_CLIENT_COMMAND,
  StoresNewClientCommandPort,
} from '../../../application/ports/primary/stores-new-client.command-port';

@Injectable()
export class ClientAddedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ClientAddedEvent];
  strategy = ongoingEventsOrchestrationStrategy;
  constructor(
    @Inject(STORES_NEW_CLIENT_COMMAND)
    private _storesNewClientCommandHandlers: StoresNewClientCommandPort[],
  ) {}
  handle([event]: [ClientAddedEvent]) {
    this._storesNewClientCommandHandlers.forEach(handler => {
      handler.storeNewClient(event.client);
    });
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_LABEL_MANAGER_REPORT_LENGTH_QUERY_PORT =
  new InjectionToken<GetsLabelManagerReportLengthQueryPort>(
    'GETS_LABEL_MANAGER_REPORT_LENGTH_QUERY_PORT',
  );

export interface GetsLabelManagerReportLengthQueryPort {
  getReportLength(): Observable<number>;
}

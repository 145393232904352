import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ResendsCodeCommand } from './resends-code.command';

export const RESENDS_CODE_COMMAND = new InjectionToken<ResendsCodeCommandPort>(
  'RESENDS_CODE_COMMAND',
);

export interface ResendsCodeCommandPort {
  resendCode(command: ResendsCodeCommand): Observable<void>;
}

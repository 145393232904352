import { NgModule } from '@angular/core';
import { HttpAnnoucementsServiceModule } from '../adapters/secondary/http-gets-annoucement.service-module';
import { HttpGetsUserDetailsServiceModule } from '../adapters/secondary/http-gets-user-details.service-module';
import { InMemoryServiceModule } from '../adapters/secondary/in-memory.service-module';
import { AnnoucementStateModule } from '../application/state/annoucement.state.module';
import { HomeStateModule } from '../application/state/home.state.module';
import { LoadUserDetailsResolver } from '../adapters/primary/resolvers/load-user-details.resolver';

@NgModule({
  imports: [
    HomeStateModule,
    AnnoucementStateModule,
    InMemoryServiceModule,
    HttpGetsUserDetailsServiceModule,
    HttpAnnoucementsServiceModule,
  ],
  providers: [LoadUserDetailsResolver],
})
export class CobiroProRewriteHomeProvidersModule {}

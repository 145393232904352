import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientDTO } from '../../secondary/dto/clients/client.dto';

export const SETS_SELECTED_CLIENT_COMMAND = new InjectionToken<SetsSelectedClientCommandPort>(
  'SETS_SELECTED_CLIENT_COMMAND',
);

export interface SetsSelectedClientCommandPort {
  setSelectedClient(clientId: string): Observable<ClientDTO>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface HasPaymentMethodsQueryPort {
  hasPaymentMethods(): Observable<boolean>;
}

export const HAS_PAYMENT_METHODS_QUERY_PORT = new InjectionToken<HasPaymentMethodsQueryPort>(
  'HAS_PAYMENT_METHODS_QUERY_PORT',
);

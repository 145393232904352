import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateProfileProgressQuery } from './create-profile-progress.query';

export const GETS_CREATE_PROFILE_PROGRESS_QUERY =
  new InjectionToken<GetsCreateProfileProgressQueryPort>('GETS_CREATE_PROFILE_PROGRESS_QUERY');

export interface GetsCreateProfileProgressQueryPort {
  getCreateProfileProgress(): Observable<CreateProfileProgressQuery>;
}

import { SelectionModel } from '@angular/cdk/collections';

export class ComparableSelectionModel<T> extends SelectionModel<T> {
  compareWith: (o1: T, o2: T) => boolean;

  constructor(
    _multiple?: boolean,
    initial?: T[],
    _emitChanges?: boolean,
    compareWith?: (o1: T, o2: T) => boolean,
  ) {
    super(_multiple, initial, _emitChanges);

    this.compareWith = compareWith ? compareWith : (o1, o2) => o1 === o2;
  }

  override isSelected(value: T): boolean {
    return this.selected.some(x => this.compareWith(value, x));
  }

  override deselect(...values: T[]): void {
    this['_verifyValueAssignment'](values);

    values.forEach(value => {
      const found = this.selected.find(x => this.compareWith(value, x));
      if (found) {
        this['_unmarkSelected'](found);
      }
    });

    this['_emitChangeEvent']();
  }
}

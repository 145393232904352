import { Inject, Injectable } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { CobiroProContextDto } from '../../../application/ports/secondary/cobiro-pro-context.dto';
import { SavesCobiroProContextDtoPort } from '../../../application/ports/secondary/saves-cobiro-pro-context.dto-port';
import { Observable, of } from 'rxjs';
import { GetsCobiroProContextDtoPort } from '../../../application/ports/secondary/gets-cobiro-pro-context.dto-port';

@Injectable()
export class LocalstorageContextService
  implements GetsCobiroProContextDtoPort, SavesCobiroProContextDtoPort
{
  private readonly _storageKey = 'lib-pro-context';
  constructor(@Inject(STORAGE) private _storage: Storage) {}

  getContext(): Observable<CobiroProContextDto> {
    const localStorageContext = this._storage.getItem(this._storageKey)
      ? JSON.parse(this._storage.getItem(this._storageKey))
      : null;
    return of(
      localStorageContext || {
        proModeEnabled: false,
        selectedTeam: { id: null, name: null, userRole: null, avatar: null },
        selectedWorkspace: { id: null, name: null, userRole: null, membersCount: null },
      },
    );
  }

  saveContext(context: CobiroProContextDto): Observable<boolean> {
    this._storage.setItem(this._storageKey, JSON.stringify(context));
    return of(true);
  }
}

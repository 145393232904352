import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { IntegrationActionButtonsComponent } from './integration-action-buttons.component';

@NgModule({
  imports: [CommonModule, LanguageModule],
  declarations: [IntegrationActionButtonsComponent],
  exports: [IntegrationActionButtonsComponent],
})
export class IntegrationActionButtonsComponentModule {}

<div class="d-flex align-items-center position-absolute cs-top-5 cs-right-5">
  <mat-icon
    svgIcon="trash"
    *ngIf="shouldDisplayPreview() | async"
    (click)="onRemoveClicked($event)"
  ></mat-icon>
</div>
<div
  libFilePicker
  (filesChanged)="onFileUpload($event)"
  class="cs-ph-15 d-flex justify-content-center align-items-center h-100"
>
  <ng-container *ngIf="shouldDisplaySpinner() | async; else content">
    <div huiLoading="true"></div>
  </ng-container>
  <ng-template #content>
    <ng-container *ngIf="shouldDisplayPreview() | async; else uploadInstructions">
      <ng-content select="[imagePreview]"></ng-content>
    </ng-container>
    <ng-template #uploadInstructions>
      <div class="text-center">
        <mat-icon svgIcon="upload-cloud" class="cs-text-3 upload-icon"></mat-icon>
        <p>Drag and drop image or <a>browse</a></p>
        <p>Recommended size: <ng-content select="[recommendedSize]"></ng-content></p>
      </div>
    </ng-template>
  </ng-template>
</div>

import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { iif, Observable, of, throwError } from 'rxjs';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { Inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { HasData } from '@cobiro/jsonapi';
import { GetsBillingDataDtoPort } from '../../../application/ports/secondary/gets-billing-data-dto.port';
import { BillingDataDto } from '../../../application/ports/secondary/billing-data.dto';

interface BillingDataAttributes {
  details: {
    countryCode?: string;
    vatNumber?: string;
  };
}

@Injectable()
export class HttpGetsBillingDataService implements GetsBillingDataDtoPort {
  constructor(
    private _gateway: GatewayClient,
    @Inject(TEAM_ID_GETTER) private _teamIdGetter: TeamIdGetter,
  ) {}

  getBillingData(): Observable<BillingDataDto> {
    const teamId = this._teamIdGetter.getTeamId();
    return iif(
      () => !!teamId,
      of(teamId),
      throwError(() => 'TeamId not provided'),
    ).pipe(switchMap((id: string) => this._fetchBillingData(id)));
  }

  private _fetchBillingData(teamId: string): Observable<BillingDataDto> {
    return this._gateway.get<HasData<BillingDataAttributes>>(`v1/sites/teams/${teamId}`).pipe(
      map(response => ({
        countryCode: response.data.attributes.details?.countryCode ?? null,
        vatNumber: response.data.attributes.details?.vatNumber,
      })),
    );
  }
}

import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
  GETS_CURRENT_PLAN_DTO_PORT,
  GetsCurrentPlanDtoPort,
} from '../ports/secondary/gets-current-plan.dto-port';
import { HandlesCurrentPlan } from './handles-current-plan';
import { mapTo, tap } from 'rxjs/operators';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { CURRENT_PLAN_TYPE_STORAGE } from '../../domain/storage/current-plan-type.storage';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { PlanLoadedEvent } from '@app.cobiro.com/core/events';
import { AccountStrategist } from '../../domain/account-strategist';

// TODO create new lib site/payment-plans and move it there

@Injectable()
export class CurrentPlanState implements HandlesCurrentPlan {
  constructor(
    @Inject(CURRENT_PLAN_TYPE_STORAGE)
    private _currentPlanTypeStorage: ReactiveSingleValueStorage<string>,
    @Inject(GETS_CURRENT_PLAN_DTO_PORT) private _getsCurrentPlanDtoPort: GetsCurrentPlanDtoPort,
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
  ) {}

  loadPlan(): Observable<void> {
    return this._getsCurrentPlanDtoPort.get().pipe(
      tap(currentPlan => this._currentPlanTypeStorage.save(currentPlan.planName)),
      tap(currentPlan => {
        this._applicationBus.dispatch(
          new PlanLoadedEvent(
            currentPlan.planName,
            currentPlan.planPeriod,
            new AccountStrategist(currentPlan.planName).value,
          ),
        );
      }),
      mapTo(void 0),
    );
  }
}

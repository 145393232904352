import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { NoWorkspaceAccessComponent } from './no-workspace-access.component';
import { NoWorkspaceAccessModalComponentModule } from '../no-workspace-access-modal/no-workspace-access-modal.component-module';

@NgModule({
  declarations: [NoWorkspaceAccessComponent],
  imports: [CommonModule, MatDialogModule, NoWorkspaceAccessModalComponentModule],
  exports: [NoWorkspaceAccessComponent],
})
export class NoWorkspaceAccessComponentModule {}

/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { ConfirmPaymentMethodDto } from '../../../../application/ports/secondary/confirm-payment-method.dto';
import { from, Observable } from 'rxjs';
import { ENV_CONFIG, GetsConfig, WINDOW } from '@app.cobiro.com/core/utils';
import { ConfirmsPaymentMethodDtoPort } from '../../../../application/ports/secondary/confirms-payment-method.dto-port';
import { switchMap } from 'rxjs/operators';
import { PaymentAttempt } from '@chargebee/chargebee-js-angular-wrapper/lib/types';

interface Chargebee {
  init(data: { site: string }): CharbeeCheckoutInstance;
}

interface CharbeeCheckoutInstance {
  load3DSHandler: () => Promise<Chargebee3DSHandler>;
}

interface Chargebee3DSHandler {
  setPaymentIntent(intent: PaymentIntent): void;
  handleCardPayment(
    cardData: { card: ChargebeeCardData; additionalData: unknown },
    callbacks: {
      success: (intent?: PaymentIntent) => void;
      error: (intent?: PaymentIntent, error?: unknown) => void;
    },
  ): void;
}

interface ChargebeeCardData {
  firstName: string;
  number: string;
  cvv: string;
  expiryMonth: string;
  expiryYear: string;
}

interface PaymentIntent {
  id: string;
  status: string;
  amount: number;
  currency_code: string;
  gateway_account_id: string;
  active_payment_attempt?: PaymentAttempt;
  gateway: string;
  customer_id?: string;
  reference_id?: string;
}

@Injectable()
export class HttpChargebeeConfirmPaymentService implements ConfirmsPaymentMethodDtoPort {
  private readonly _checkoutInstance: CharbeeCheckoutInstance;
  constructor(
    @Inject(ENV_CONFIG) private readonly _envConfig: GetsConfig,
    @Inject(WINDOW) private readonly _window: { Chargebee: Chargebee },
  ) {
    const { site } = this._envConfig.get('chargebee') as {
      site: string;
      publishableKey: string;
    };
    this._checkoutInstance = this._window.Chargebee.init({ site });
  }

  confirm(dto: ConfirmPaymentMethodDto): Observable<string | boolean> {
    return from(this._checkoutInstance.load3DSHandler()).pipe(
      switchMap(threeDSHandler => {
        return new Observable<string | boolean>(subscriber => {
          threeDSHandler.setPaymentIntent(this._mapToChargebeePaymentIntent(dto.paymentIntent));
          threeDSHandler.handleCardPayment(
            {
              card: {
                firstName: dto.card.owner,
                expiryMonth: dto.card.expiryMonth,
                expiryYear: dto.card.expiryYear,
                number: dto.card.number,
                cvv: dto.card.cvv,
              },
              additionalData: {},
            },
            {
              success: () => {
                subscriber.next(true);
                subscriber.complete();
              },
              error: (paymentIntent: PaymentIntent) => {
                try {
                  subscriber.next(paymentIntent.active_payment_attempt?.error_text);
                } catch (e) {
                  console.error(e);
                  subscriber.next(paymentIntent.active_payment_attempt?.error_text);
                }
                subscriber.complete();
              },
            },
          );
        });
      }),
    );
  }

  private _mapToChargebeePaymentIntent(
    dtoIntent: ConfirmPaymentMethodDto['paymentIntent'],
  ): PaymentIntent {
    return {
      id: dtoIntent.id,
      status: dtoIntent.status,
      amount: dtoIntent.amount,
      currency_code: dtoIntent.currencyCode,
      gateway_account_id: dtoIntent.gatewayAccountId,
      gateway: dtoIntent.gateway,
    };
  }
}

import { MarksAsRead } from '../domain/marks-as-read';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UUID } from '@app.cobiro.com/core/events';
import { FORM_TYPES, MessageNotification } from '../domain/messages/message-notification';
import { GatewayClient } from '@app.cobiro.com/common/gateway';

@Injectable()
export class HttpMarksAsReadService implements MarksAsRead {
  constructor(private _gatewayClient: GatewayClient) {}

  markAsRead(notification: MessageNotification): Observable<boolean> {
    return notification.formType === FORM_TYPES.contactForm
      ? this._markContactFormNotificationAsRead(notification.formId, [notification.id])
      : this._markAdvancedFormNotificationAsRead(notification.formId, notification.id);
  }

  private _markContactFormNotificationAsRead(
    contactFormId: string,
    notificationIds: string[],
  ): Observable<boolean> {
    return this._gatewayClient
      .post(`v1/contacts/${contactFormId}/mark_messages_as_read`, {
        data: {
          id: new UUID().value,
          type: 'mark-messages-as-read',
          attributes: {
            messageId: notificationIds,
          },
        },
      })
      .pipe(map(() => true));
  }

  private _markAdvancedFormNotificationAsRead(
    formId: string,
    notificationId: string,
  ): Observable<boolean> {
    return this._gatewayClient
      .post(`v1/advanced-forms/${formId}/read-form-messages`, {
        data: {
          id: new UUID().value,
          type: 'read-form-messages',
          attributes: {
            messageId: notificationId,
          },
        },
      })
      .pipe(map(() => true));
  }
}

<form
  [formGroup]="discountCodeForm"
  *libFeatureFlags="['payment_plans_discount_codes']"
  class="cs-form"
>
  <div class="cs-mt-30">
    <label class="cs-label cs-title-7" data-selector="payment-plans-coupon-code-header">
      {{ 'payment_plans_coupon_code' | language }}
    </label>

    <div class="d-flex">
      <div class="flex-1 cs-control-wrapper">
        <input
          class="cs-input"
          type="text"
          [placeholder]="'payment_plans_discount_code_placeholder' | language"
          formControlName="discountCode"
          data-selector="payments-discount-code-input"
        />
        <p
          class="cs-control-error"
          *ngIf="
            discountCodeForm.get('discountCode').invalid &&
            discountCodeForm.get('discountCode').touched &&
            discountCodeForm.get('discountCode').hasError('invalidCode')
          "
          data-selector="payments-discount-code-invalid-message"
        >
          {{ discountCodeForm.get('discountCode').errors.invalidCode | language }}
        </p>
      </div>
      <button
        class="cs-btn cs-btn-flat cs-ml-10 cs-width-14 0-xs"
        *ngIf="!discountCodeForm.get('discountCode').disabled"
        (click)="onDiscountApply()"
        type="submit"
        [disabled]="discountCodeForm.get('discountCode').invalid"
        [libAnalytics]="['payment-modal', 'apply-discount-code']"
        data-selector="payments-discount-apply"
      >
        {{ 'payment_plans_apply' | language }}
      </button>
      <button
        class="cs-btn cs-btn-flat cs-ml-10 cs-width-140-xs"
        *ngIf="discountCodeForm.get('discountCode').disabled"
        (click)="onDiscountRemove()"
        [libAnalytics]="['payment-modal', 'remove-discount-code']"
        data-selector="payments-discount-remove"
      >
        {{ 'payment_plans_remove_discount_code' | language }}
      </button>
    </div>
  </div>
</form>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SendsPartnerRequestQuery } from './sends-partner-request.query';

export const SENDS_PARTNER_REQUEST_QUERY = new InjectionToken<SendsPartnerRequestQueryPort>(
  'SENDS_PARTNER_REQUEST_QUERY',
);

export interface SendsPartnerRequestQueryPort {
  sendRequest(query: SendsPartnerRequestQuery): Observable<boolean>;
}

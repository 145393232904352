import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Used in only 1-2 other components
@Component({
  selector: 'lib-not-found',
  templateUrl: './not-found.component.html',
  host: {
    class: 'd-flex align-items-center justify-content-center cs-min-height-100-pc-xs',
  },
})
export class NotFoundComponent implements OnInit {
  constructor(private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit() {
    throw new WrongPathError(this._router.url);
  }
}

export class WrongPathError implements Error {
  message: string;
  name = 'WRONG_PATH_ERROR';

  constructor(path: string) {
    this.message = `Cannot find route for ${path}`;
  }
}

import { Observable } from 'rxjs';
import { ConfirmPaymentMethodDto } from './confirm-payment-method.dto';
import { InjectionToken } from '@angular/core';

export interface ConfirmsPaymentMethodDtoPort {
  confirm(dto: ConfirmPaymentMethodDto): Observable<string | boolean>;
}

export const CONFIRMS_PAYMENT_METHOD_DTO_PORT = new InjectionToken<ConfirmsPaymentMethodDtoPort>(
  'CONFIRM_PAYMENT_METHOD_DTO_PORT',
);

import { LatestCobiroProLoginDto } from './latest-cobiro-pro-login.dto';
import { InjectionToken } from '@angular/core';

export interface GetsLatestCobiroProLoginDtoPort {
  getLatestCobiroProLogin(): LatestCobiroProLoginDto;
}

export const GET_LATEST_COBIRO_PRO_LOGIN_DTO = new InjectionToken<GetsLatestCobiroProLoginDtoPort>(
  'GET_LATEST_COBIRO_PRO_LOGIN_DTO',
);

import { Observable } from 'rxjs';
import { ClientServicesDto } from './client-services.dto';
import { InjectionToken } from '@angular/core';

export interface GetsAllClientsServicesDtoPort {
  getAllClientsServices(
    clients: { siteId: string; sitePublicId: string }[],
  ): Observable<ClientServicesDto[]>;
}

export const GETS_ALL_CLIENTS_SERVICES_DTO = new InjectionToken<GetsAllClientsServicesDtoPort>(
  'GETS_ALL_CLIENTS_SERVICES_DTO',
);

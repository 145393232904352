import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { VerifiesUserCommand } from './verifies-user.command';

export const VERIFIES_USER_COMMAND = new InjectionToken<VerifiesUserCommandPort>(
  'VERIFIES_USER_COMMAND',
);

export interface VerifiesUserCommandPort {
  verifyUser(command: VerifiesUserCommand): Observable<void>;
}

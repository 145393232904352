import { NgModule } from '@angular/core';
import { DebounceClickDirective } from '@app.cobiro.com/shared/hui/debounce-click/debounce-click.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [DebounceClickDirective],
  exports: [DebounceClickDirective],
})
export class HuiDebounceClickModule {}

import { MatSnackBar } from '@angular/material/snack-bar';
import { GETS_LANGUAGE, GetsLanguage } from '@app.cobiro.com/common/language';
import { Inject, Injectable } from '@angular/core';

export type HuiAlertType = 'success' | 'flat' | 'primary' | 'warning' | 'error';

@Injectable()
export class HuiAlert {
  constructor(
    private _snackBar: MatSnackBar,
    @Inject(GETS_LANGUAGE) private _languageService: GetsLanguage,
  ) {}

  public open(type: HuiAlertType, messageKey: string, panelClass?: string): void {
    this._snackBar.open(this._languageService.get(messageKey), ' ', {
      panelClass: ['cs-mat-alert', `cs-mat-alert-${type}`, `${panelClass}`],
      duration: 5000,
    });
  }
}

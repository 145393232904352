import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HuiAvatarComponent } from './hui-avatar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HuiAvatarComponent],
  exports: [HuiAvatarComponent],
})
export class HuiAvatarModule {}

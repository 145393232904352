import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelReasonQuery } from './cancel-reason.query';

export const GETS_CANCEL_REASON_QUERY = new InjectionToken<GetsCancelReasonQueryPort>(
  'GETS_CANCEL_REASON_QUERY',
);

export interface GetsCancelReasonQueryPort {
  getCancelReasonList(): Observable<CancelReasonQuery[]>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HuiAlert } from './hui-alert';

@NgModule({
  imports: [CommonModule, MatSnackBarModule],
  providers: [HuiAlert],
})
export class HuiAlertModule {}

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { PlanPaymentCompletedEvent } from '@app.cobiro.com/core/events';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerPlanPaymentCompletedEventMapper implements DataLayerTrackingMapper {
  eventClass = PlanPaymentCompletedEvent;
  mapEvent(event: PlanPaymentCompletedEvent) {
    return {
      eventName: `/Payment - Payment ${event.success ? 'Success' : 'Failed'}`,
      eventData: {
        transactionId: event.transactionId,
        transactionTotal: event.transactionTotal,
        transactionTax: event.transactionTax,
        transactionProducts: [
          {
            sku: `${event.planId}-${event.transactionId}`,
            name: `${event.planName} ${event.planPeriod === 'yearly' ? 'Annual' : 'Monthly'}`,
            price: event.planPrice,
            quantity: 1,
          },
        ],
      },
    };
  }
}

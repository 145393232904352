import { CobiroProContextDto } from '../secondary/cobiro-pro-context.dto';

export class CobiroProContextQuery {
  constructor(
    public readonly proModeEnabled: boolean,
    public readonly selectedTeamId: string | null,
    public readonly selectedTeamName: string | null,
    public readonly selectedTeamUserRole: string | null,
    public readonly selectedTeamAvatar: string | null,
    public readonly selectedWorkspaceId: string | null,
    public readonly selectedWorkspaceName: string | null,
    public readonly selectedWorkspaceAvatar: string | null,
    public readonly selectedWorkspaceMembersCount: number | null,
  ) {}

  static fromDto(dto: CobiroProContextDto): CobiroProContextQuery {
    // TODO WJ - why context elements can be null?
    return new CobiroProContextQuery(
      dto.proModeEnabled,
      dto.selectedTeam?.id,
      dto.selectedTeam?.name,
      dto.selectedTeam?.userRole,
      dto.selectedTeam?.avatar,
      dto.selectedWorkspace?.id,
      dto.selectedWorkspace?.name,
      dto.selectedWorkspace?.avatar,
      dto.selectedWorkspace?.membersCount,
    );
  }
}

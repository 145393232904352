import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import {
  GETS_ALL_TEAM_QUERY,
  GetsAllTeamQueryPort,
} from '../../../../application/ports/primary/gets-all-team.query-port';
import {
  HAS_SUBSCRIPTIONS_QUERY,
  HasSubscriptionsQueryPort,
} from '../../../../application/ports/primary/has-subscriptions.query-port';

@Component({
  templateUrl: './delete-team-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteTeamModalComponent {
  readonly hasSubscriptions$: Observable<{ value: boolean }> = this._hasSubscriptionsQueryPort
    .hasSubscriptions()
    .pipe(map(res => ({ value: res })));
  readonly onlyCompany$: Observable<boolean> = this._getsAllTeamQueryPort
    .getAllTeamQuery()
    .pipe(map(teams => teams.length === 1));

  constructor(
    @Inject(GETS_ALL_TEAM_QUERY)
    private readonly _getsAllTeamQueryPort: GetsAllTeamQueryPort,
    @Inject(HAS_SUBSCRIPTIONS_QUERY)
    private readonly _hasSubscriptionsQueryPort: HasSubscriptionsQueryPort,
    @Inject(MAT_DIALOG_DATA) readonly data: { id: string; name: string },
  ) {}
}

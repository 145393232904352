import {
  LABEL_MANAGER_CONFIG_DTO_STUB,
  LABEL_MANAGER_INITIAL_CONFIG_DTO_STUB,
} from '../secondary/label-manager-config.dto.stub';
import { LabelManagerConfigQuery } from './label-manager-config.query';

export const LABEL_MANAGER_CONFIG_QUERY_STUB: LabelManagerConfigQuery =
  LabelManagerConfigQuery.fromDto(LABEL_MANAGER_CONFIG_DTO_STUB);

export const LABEL_MANAGER_INITIAL_CONFIG_QUERY_STUB: LabelManagerConfigQuery =
  LabelManagerConfigQuery.fromDto(LABEL_MANAGER_INITIAL_CONFIG_DTO_STUB);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyDetailsComponent } from './company-details.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';

@NgModule({
  declarations: [CompanyDetailsComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatIconModule,
    HuiInputModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule.forChild([{ path: '', component: CompanyDetailsComponent }]),
  ],
  exports: [CompanyDetailsComponent],
})
export class CompanyDetailsComponentModule {}

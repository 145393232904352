<section
  class="d-flex flex-column flex-md-row align-items-center justify-content-end cs-mv-20 w-100"
>
  <div
    class="cs-control-wrapper cs-min-width-100-pc-xs cs-min-width-150-md cs-mb-10 cs-mb-0-md w-100"
  >
    <mat-icon svgIcon="search" class="cs-input-icon-left"></mat-icon>
    <input
      data-selector="cobiro-pro-users-table-search"
      class="cs-input cs-input-with-icon-left"
      type="text"
      [placeholder]="'_search' | language"
      [formControl]="searchControl"
    />
  </div>
  <div class="d-flex w-100 cs-min-width-200-xs cs-max-width-200-sm cs-ml-10 align-self-end">
    <button
      data-selector="lib-pro-invite-user"
      class="cs-btn cs-btn-primary cs-mt-30 cs-mt-0-md w-100"
      (click)="onAddMemberClicked()"
    >
      {{ 'cobiro_pro_users_add_user' | language }}
    </button>
  </div>
</section>

import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { InMemoryReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { HuiAlertModule } from '@app.cobiro.com/shared/hui/alert';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { UpgradePlanFlowModule } from '../upgrade/ui/upgrade-plan-flow/upgrade-plan-flow.module';
import { PaymentPlansLoadedGuard } from './adapters/primary/guards/payment-plans-loaded.guard';
import { PaymentPlanRequestedEventHandler } from './adapters/primary/handlers/payment-plan-requested.event-handler';
import { GetsCurrentPlanQueryGetterModule } from './adapters/primary/query-getters/gets-current-plan-query-getter.module';
import { HttpCurrentPlanServiceModule } from './adapters/secondary/infrastructure/http-current-plan-service.module';
import { CurrentPlanState } from './application/state/current-plan.state';
import { DiscountCodeState } from './application/state/discount-code.state';
import { PaymentPlansState } from './application/state/payment-plans.state';
import { PlanEstimateState } from './application/state/plan-estimate.state';
import { CHECKOUT } from './domain/checkout';
import { CHECKS_DISCOUNT_CODE } from './domain/checks-discount-code';
import { GETS_COUNTRIES } from './domain/gets-countries';
import { GETS_PAYMENT_PLAN } from './domain/gets-payment-plans';
import { GETS_PLAN_ESTIMATE } from './domain/gets-plan-estimate';
import { GETS_PLAN_FEATURES } from './domain/gets-plan-features';
import { CURRENT_PLAN_TYPE_STORAGE } from './domain/storage/current-plan-type.storage';
import { DISCOUNT_STORAGE } from './domain/storage/discount.storage';
import { FEATURE_CONTEXT_STORAGE } from './domain/storage/feature-context.storage';
import { PLAN_ESTIMATE_STORAGE } from './domain/storage/plan-estimate.storage';
import { SELECTED_PLAN_STORAGE } from './domain/storage/selected-plan.storage';
import { HttpChargebeeService } from './infrastructure/chargebee/http-chargebee.service';
import { HttpChecksDiscountCodeService } from './infrastructure/checks-discount-code/http-checks-discount-code.service';
import { HttpGetsCountriesService } from './infrastructure/get-countries/http-gets-countries.service';
import { HttpGetsPaymentPlanService } from './infrastructure/gets-payment-plan/http-gets-payment-plan.service';
import { HttpGetsPlanEstimateService } from './infrastructure/gets-plan-estimate/http-gets-plan-estimate.service';
import { HttpGetsPlanFeatures } from './infrastructure/gets-plan-features/http-gets-plan-features.service';
import { HttpUpdatesUserBillingService } from './infrastructure/update-billing-info/http-updates-user-billing.service';
import { UPDATE_USER_BILLING_DTO } from './infrastructure/update-billing-info/updates-user-billing-dto.port';

@NgModule({
  imports: [
    UpgradePlanFlowModule,
    HuiAlertModule,
    LanguageModule,
    HttpCurrentPlanServiceModule,
    GetsCurrentPlanQueryGetterModule,
  ],
  providers: [
    PaymentPlansState,
    DiscountCodeState,
    PaymentPlansLoadedGuard,
    CurrentPlanState,
    PlanEstimateState,
    { provide: WINDOW, useValue: window },
    { provide: GETS_PAYMENT_PLAN, useClass: HttpGetsPaymentPlanService },
    { provide: CHECKOUT, useClass: HttpChargebeeService },
    { provide: GETS_PLAN_FEATURES, useClass: HttpGetsPlanFeatures },
    { provide: GETS_COUNTRIES, useClass: HttpGetsCountriesService },
    { provide: GETS_PLAN_ESTIMATE, useClass: HttpGetsPlanEstimateService },
    { provide: CHECKS_DISCOUNT_CODE, useClass: HttpChecksDiscountCodeService },
    { provide: DISCOUNT_STORAGE, useClass: InMemoryReactiveSingleValueStorage },
    { provide: CURRENT_PLAN_TYPE_STORAGE, useClass: InMemoryReactiveSingleValueStorage },
    { provide: PLAN_ESTIMATE_STORAGE, useClass: InMemoryReactiveSingleValueStorage },
    { provide: FEATURE_CONTEXT_STORAGE, useClass: InMemoryReactiveSingleValueStorage },
    { provide: SELECTED_PLAN_STORAGE, useClass: InMemoryReactiveSingleValueStorage },
    {
      provide: UPDATE_USER_BILLING_DTO,
      useClass: HttpUpdatesUserBillingService,
    },
    provideApplicationEventsHandler(PaymentPlanRequestedEventHandler),
  ],
})
export class PaymentPlansCoreModule {}

import { makePaymentData } from '../../domain/payment-plan.stub';
import { PaymentPlan, PaymentPlanPeriod } from '../../domain/payment-plan';
import { Observable, of } from 'rxjs';
import { PaymentPlansStateModel } from './payment-plans.state';
import { PAYMENT_STATUS } from '../../domain/payment-status.enum';

export class PaymentPlansStateStub {
  get planTypeCollection$(): Observable<Partial<PaymentPlansStateModel>> {
    return of({ selectedPeriod: PaymentPlanPeriod.YEARLY, items: [] });
  }

  get paymentPlans$(): Observable<PaymentPlan[]> {
    return of([]);
  }

  get selectedPlanPeriod$(): Observable<PaymentPlanPeriod> {
    return of(PaymentPlanPeriod.YEARLY);
  }

  get plansForFeature$(): Observable<PaymentPlan[]> {
    return of([]);
  }

  get selectedPlan$() {
    return of({});
  }

  initState(): Observable<boolean> {
    return of(true);
  }
  changePlanPeriod() {}
  changePlanName() {}
  changeSelectedPlan() {}
  startUpgradeFlow() {}
  updatePlan() {
    return of(PAYMENT_STATUS.SUCCESS);
  }
  redirectToPlanComparison() {}
  clear() {}
}

const BASIC_PLANS = ['Starter', 'Professional', 'Premium'];
const FREE_PLAN = 'Free';

export const PAYMENT_PLANS_DATA_STUB = [
  makePaymentData(FREE_PLAN),
  ...BASIC_PLANS.map(plan => makePaymentData(plan)),
];

import { Inject, Injectable } from '@angular/core';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilKeyChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { CHECKS_DISCOUNT_CODE, ChecksDiscountCode } from '../../domain/checks-discount-code';
import { SelectedPlan } from '../../domain/selected-plan';
import { DISCOUNT_STORAGE } from '../../domain/storage/discount.storage';
import { SELECTED_PLAN_STORAGE } from '../../domain/storage/selected-plan.storage';
import { Discount } from '../../domain/discount';
import { DomainError } from '@app.cobiro.com/core/state';
import {
  CouponExpiredError,
  CouponInvalidError,
  CouponNotApplicableError,
} from '../../domain/checks-discount-code-errors';

export interface HandlesDiscountCode {
  discount$: Observable<Discount>;
  checkDiscountCode(code: string): Observable<boolean>;
  clear(): void;
}

@Injectable()
export class DiscountCodeState implements HandlesDiscountCode {
  discount$: Observable<Discount> = this._discountStorage.select();
  private readonly _errorMap = new Map<string, string>([
    [CouponInvalidError.code, 'payment_plans_discount_code_invalid'],
    [CouponExpiredError.code, 'payment_plans_discount_code_expired'],
    [CouponNotApplicableError.code, 'payment_plans_discount_code_not_applicable'],
  ]);

  constructor(
    @Inject(DISCOUNT_STORAGE)
    private _discountStorage: ReactiveSingleValueStorage<Discount>,
    @Inject(CHECKS_DISCOUNT_CODE) private _checksDiscountCode: ChecksDiscountCode,
    @Inject(SELECTED_PLAN_STORAGE)
    private _selectedPlanStorage: ReactiveSingleValueStorage<SelectedPlan>,
    private _alert: HuiAlert,
  ) {}

  checkDiscountCode(code: string): Observable<boolean> {
    return this._selectedPlanStorage.select().pipe(
      distinctUntilKeyChanged('id'),
      map(plan => plan.id),
      take(1),
      switchMap(selectedPlanId => {
        return this._checksDiscountCode.checkCode(code, selectedPlanId);
      }),
      tap(discountCodeCheckResult => {
        if (discountCodeCheckResult.discount) {
          this._discountStorage.save(discountCodeCheckResult.discount);
          return this._alert.open('success', 'payment_plans_discount_code_added');
        }
      }),
      map(discountCodeCheckResult => {
        return !!discountCodeCheckResult.discount;
      }),
      catchError((err: DomainError) => {
        this._alert.open('error', this._errorMap.get(err.name));
        return of(false);
      }),
    );
  }

  clear(): void {
    this._discountStorage.clear();
  }
}

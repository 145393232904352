import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CreateWorkspaceModalComponent } from './create-workspace-modal.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatIconModule, MatDialogModule, ReactiveFormsModule],
  declarations: [CreateWorkspaceModalComponent],
})
export class CreateWorkspaceModalComponentModule {}

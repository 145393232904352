import { NgModule } from '@angular/core';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { UserAdditionalDataLoadRequestedEventHandler } from '../../adapters/event-handlers/user-additional-data-load-requested.event-handler';
import { LOAD_USER_ADDITIONAL_DATA_COMMAND } from '../ports/primary/load-user-additional-data-command.port';
import { UserAdditionalDataState } from './user-additional-data.state';

@NgModule({
  providers: [
    UserAdditionalDataState,
    { provide: LOAD_USER_ADDITIONAL_DATA_COMMAND, useExisting: UserAdditionalDataState },
    provideApplicationEventsHandler(UserAdditionalDataLoadRequestedEventHandler),
  ],
})
export class UserAdditionalDataStateModule {}

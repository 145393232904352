import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import {
  ADD_CLIENT_QUERY,
  AddClientQueryPort,
} from '../../../../../../application/ports/primary/clients/add-client.query-port';
import { BehaviorSubject, map, Subject, switchMap, takeUntil } from 'rxjs';
import { CreateClientForm } from '../../../modals/create-new-client-modal/create-new-client-form/create-new-client-form.component';
import {
  CompletesFirstStepCommandPort,
  COMPLETES_FIRST_STEP_COMMAND,
} from '../../../../../../application/ports/primary/setup/completes-first-step.command-port';
import { ClientDTO } from '../../../../../../application/ports/secondary/dto/clients/client.dto';

@Component({
  selector: 'lib-cobiro-pro-create-shop',
  templateUrl: './create-shop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateShopComponent implements OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  private _isValidForm$ = new BehaviorSubject<boolean>(false);
  private _clientForm$ = new BehaviorSubject<CreateClientForm>(null);
  private _isProcessing$ = new BehaviorSubject<boolean>(false);

  readonly isInvalid$ = this._isValidForm$.asObservable().pipe(map(res => !res));
  readonly isProcessing$ = this._isProcessing$.asObservable();

  constructor(
    @Inject(ADD_CLIENT_QUERY) private readonly _addClientQueryPort: AddClientQueryPort,
    @Inject(COMPLETES_FIRST_STEP_COMMAND)
    private readonly _completesFirstStepCommandPort: CompletesFirstStepCommandPort,
  ) {}

  onFormGroupChange(form: CreateClientForm): void {
    this._clientForm$.next(form);
  }

  onValidityChange(isValid: boolean): void {
    this._isValidForm$.next(isValid);
  }

  onFormSubmitted(): void {
    const formValue = this._clientForm$.getValue();
    this._isProcessing$.next(true);
    this._addClientQueryPort
      .addClient(formValue)
      .pipe(
        switchMap((client: ClientDTO) =>
          this._completesFirstStepCommandPort.completeFirstStep({ client: client }),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe(() => this._isProcessing$.next(false));
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

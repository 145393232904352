import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamSwitcherComponent } from './team-switcher.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChooseTeamModalComponentModule } from '../choose-team-modal/choose-team-modal.component-module';

@NgModule({
  declarations: [TeamSwitcherComponent],
  imports: [CommonModule, MatDialogModule, ChooseTeamModalComponentModule],
  exports: [TeamSwitcherComponent],
})
export class TeamSwitcherModule {}

import { NgModule } from '@angular/core';
import { ApplicationBusCobiroProLogoutDispatcher } from './application-bus-cobiro-pro-logout.dispatcher';
import { COBIRO_PRO_LOGOUT_DISPATCHER } from '../../../application/ports/secondary/cobiro-pro-logout.dispatcher-port';

@NgModule({
  providers: [
    ApplicationBusCobiroProLogoutDispatcher,
    { provide: COBIRO_PRO_LOGOUT_DISPATCHER, useExisting: ApplicationBusCobiroProLogoutDispatcher },
  ],
})
export class ApplicationBusCobiroProLogoutDispatcherModule {}

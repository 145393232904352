import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ShutdownApplicationsQuery } from './shutdown-applications.query';

export const GETS_SHUTDOWN_APPLICATIONS_QUERY =
  new InjectionToken<GetsShutdownApplicationsQueryPort>('GETS_SHUTDOWN_APPLICATIONS_QUERY');

export interface GetsShutdownApplicationsQueryPort {
  getShutdownApplications(): Observable<ShutdownApplicationsQuery>;
}

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { UserInterestedInFeatureEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerUserInterestedInFeatureEventMapper implements DataLayerTrackingMapper {
  eventClass = UserInterestedInFeatureEvent;

  mapEvent(event: UserInterestedInFeatureEvent): DataLayerTrackingData {
    const interested = event.interested ? 'interested' : 'not interested';
    return {
      eventName: `/${event.feature} - ${interested}`,
    };
  }
}

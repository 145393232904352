import { NgModule } from '@angular/core';
import { HttpMarketingPreferencesService } from './http-marketing-preferences.service';
import { GETS_EMAIL_CONSENTS_DTO_PORT } from '../../../../application/ports/secondary/gets-email-consents.dto-port';
import { SAVES_EMAIL_CONSENT_DTO_PORT } from '../../../../application/ports/secondary/saves-email-consent.dto-port';

@NgModule({
  providers: [
    HttpMarketingPreferencesService,
    { provide: SAVES_EMAIL_CONSENT_DTO_PORT, useClass: HttpMarketingPreferencesService },
    { provide: GETS_EMAIL_CONSENTS_DTO_PORT, useClass: HttpMarketingPreferencesService },
  ],
})
export class HttpMarketingPreferencesServiceModule {}

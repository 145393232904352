import { concatMap, map } from 'rxjs/operators';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { TrackingService } from '@app.cobiro.com/tracking';
import { UUID } from '@app.cobiro.com/core/events';
import { UserState } from '@app.cobiro.com/auth';
import { GetsUserFromToken } from '@app.cobiro.com/user/core';

export function appInit(
  featureFlagsState: FeatureFlagsState,
  userState: UserState,
  trackingService: TrackingService,
  storage: Storage,
  getsUser: GetsUserFromToken,
): () => Promise<any> {
  userState.login$
    .pipe(
      map(() => getsUser.get()),
      concatMap(user => featureFlagsState.init(user.userId, user.email)),
    )
    .subscribe();
  return () =>
    userState
      .getAuth()
      .pipe(
        map(auth =>
          auth
            ? {
                id: getsUser.get()?.userId,
                email: getsUser.get()?.email,
              }
            : getAnonymousUser(storage),
        ),
        concatMap(user => {
          trackingService.login({ id: user.id, email: user.email });
          return featureFlagsState.init(user.id, user.email);
        }),
      )
      .toPromise();
}

function getAnonymousUser(storage: Storage) {
  const anonymousSessionId = storage.getItem('lib-anonymous-sess-id') || new UUID().value;
  storage.setItem('lib-anonymous-sess-id', anonymousSessionId);
  return {
    id: anonymousSessionId,
    email: `${anonymousSessionId}@example.com`,
  };
}

<div class="cs-p-40">
  <h2 class="cs-title-4 text-center">{{ 'cobiro_pro_shop_delete' | language }}</h2>

  <p class="cs-mt-40 text-center">
    {{ 'cobiro_pro_rewrite_shop_delete_description' | language }}
  </p>

  <div class="d-flex justify-content-center cs-mt-40">
    <button class="cs-btn cs-btn-stroked" [mat-dialog-close]="false">
      {{ 'cobiro_pro_client_delete_take_back' | language }}
    </button>
    <button class="cs-btn cs-btn-error cs-ml-10" [mat-dialog-close]="true">
      {{ 'cobiro_pro_shop_delete' | language }}
    </button>
  </div>
</div>

import { InjectionToken } from '@angular/core';
import { SORT_ORDER } from '@app.cobiro.com/core/pagination';
import { PaginationDTO, RawFilterValue } from '../pagination.dto';

export interface ExecutesQueryParams {
  get(): {
    pageIndex?: number;
    pageSize?: number;
    sort?: [string, SORT_ORDER];
    filter?: { [key: string]: RawFilterValue };
    search?: string;
  }; // todo strings instead of numbers
  update(pagination: PaginationDTO): void;
}
export const EXECUTES_QUERY_PARAMS = new InjectionToken<ExecutesQueryParams>(
  'EXECUTES_QUERY_PARAMS',
);

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientDTO } from '../../../../application/ports/secondary/dto/clients/client.dto';
import { AddsClientDtoPort } from '../../../../application/ports/secondary/dto/clients/adds-client.dto-port';
import { DeletesClientDtoPort } from '../../../../application/ports/secondary/dto/clients/deletes-client.dto-port';
import { GetsAllClientDtoPort } from '../../../../application/ports/secondary/dto/clients/gets-all-client.dto-port';
import { GetsAllClientDto } from '../../../../application/ports/secondary/dto/clients/gets-all-client.dto';
import { GetsOneClientDtoPort } from '../../../../application/ports/secondary/dto/clients/gets-one-client.dto-port';
import { GetsOneClientDto } from '../../../../application/ports/secondary/dto/clients/gets-one-client.dto';

export interface ClientAttributes {
  siteId: string;
  sitePublicId: string;
  cssIntegrationStatus: 'paused' | 'active' | 'pending' | null;
  merchantId: string | null;
  teamId: string;
  workspaceId: string;
  companyName: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  createdAt?: string;
  url: string;
  avatarUrl: string;
  source: string;
  plan: string;
  archived: boolean;
  cssDomainId: string;
}

@Injectable()
export class HttpClientsService
  implements GetsAllClientDtoPort, GetsOneClientDtoPort, AddsClientDtoPort, DeletesClientDtoPort
{
  constructor(private readonly _gatewayClient: GatewayClient) {}

  getOne(dto: GetsOneClientDto): Observable<ClientDTO> {
    return this._gatewayClient
      .get<HasData<ClientAttributes>>(
        `v1/sites/workspaces/${dto.workspaceId}/clients/${dto.clientId}`,
      )
      .pipe(
        map((client: HasData<ClientAttributes>) => {
          const siteId = client.data.attributes.siteId;
          return {
            id: client.data.id || '',
            siteId: siteId ? String(siteId) : siteId,
            workspaceId: dto.workspaceId,
            teamId: dto.teamId,
            cssIntegrationStatus: client.data.attributes.cssIntegrationStatus,
            merchantSiteId: client.data.attributes.merchantId,
            sitePublicId: client.data.attributes.sitePublicId,
            companyName: client.data.attributes.companyName,
            contactPerson: client.data.attributes.contactPerson,
            contactEmail: client.data.attributes.contactEmail,
            contactPhone: client.data.attributes.contactPhone,
            createdAt: client.data.attributes.createdAt,
            url: client.data.attributes.url,
            source: client.data.attributes.source,
            avatar: client.data.attributes.avatarUrl,
            plan: client.data.attributes.plan,
            installedApps: [],
            archived: client.data.attributes.archived,
            subscriptions: null,
            productStatuses: {
              css: client.data.attributes.cssIntegrationStatus,
              'label-manager': null,
            },
            cssDomainId: client.data.attributes.cssDomainId,
          };
        }),
      );
  }

  getAll(dto: GetsAllClientDto): Observable<ClientDTO[]> {
    return this._gatewayClient
      .get<HasDataCollection<ClientAttributes>>(`v1/sites/workspaces/${dto.workspaceId}/clients`)
      .pipe(
        map((clients: HasDataCollection<ClientAttributes>) => {
          return clients.data
            .filter(data => !data.attributes.archived)
            .map(data => {
              const siteId = data.attributes.siteId;
              return {
                id: data.id || '',
                siteId: siteId ? String(siteId) : siteId,
                workspaceId: dto.workspaceId,
                teamId: dto.teamId,
                merchantSiteId: data.attributes.merchantId,
                sitePublicId: data.attributes.sitePublicId,
                companyName: data.attributes.companyName,
                contactPerson: data.attributes.contactPerson,
                contactEmail: data.attributes.contactEmail,
                contactPhone: data.attributes.contactPhone,
                createdAt: data.attributes.createdAt,
                cssIntegrationStatus: data.attributes.cssIntegrationStatus,
                plan: data.attributes.plan,
                url: data.attributes.url,
                source: data.attributes.source,
                avatar: data.attributes.avatarUrl,
                installedApps: [],
                archived: data.attributes.archived,
                subscriptions: null,
                productStatuses: {
                  css: data.attributes.cssIntegrationStatus,
                  'label-manager': null,
                },
                cssDomainId: data.attributes.cssDomainId,
              };
            });
        }),
      );
  }

  deleteClient(dto: Pick<ClientDTO, 'id' | 'workspaceId'>): Observable<void> {
    return this._gatewayClient
      .put<HasData<never>>(`v1/sites/workspaces/${dto.workspaceId}/clients/${dto.id}/archive`, {})
      .pipe(map(() => void 0));
  }

  add(item: Omit<ClientDTO, 'sitePublicId' | 'plan' | 'installedApps'>): Observable<boolean> {
    const payload: HasData<
      Omit<
        ClientAttributes,
        'cssIntegrationStatus' | 'merchantId' | 'teamId' | 'sitePublicId' | 'plan' | 'archived'
      >
    > = {
      data: {
        id: item.id,
        type: 'clients',
        attributes: {
          workspaceId: item.workspaceId,
          siteId: item.siteId,
          companyName: item.companyName,
          contactPerson: item.contactPerson,
          contactPhone: item.contactPhone,
          contactEmail: item.contactEmail,
          avatarUrl: item.avatar || null,
          url: item.url,
          source: item.source,
          cssDomainId: item.cssDomainId,
        },
      },
    };
    return this._gatewayClient
      .post(`v1/sites/workspaces/${item.workspaceId}/clients`, payload)
      .pipe(map(() => true));
  }

  set(
    item: Pick<
      ClientDTO,
      | 'companyName'
      | 'contactPerson'
      | 'contactEmail'
      | 'contactPhone'
      | 'workspaceId'
      | 'teamId'
      | 'id'
      | 'avatar'
    >,
  ): Observable<void> {
    return this._gatewayClient.patch(`v1/sites/workspaces/${item.workspaceId}/clients/${item.id}`, {
      data: {
        type: 'clients',
        attributes: {
          companyName: item.companyName,
          contactPerson: item.contactPerson,
          contactEmail: item.contactEmail,
          contactPhone: item.contactPhone,
          avatarUrl: item.avatar || null,
        },
      },
    });
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SubmitsCompanyBaseInfoStepCommand } from './submits-company-base-info-step.command';

export const SUBMITS_COMPANY_BASE_INFO_STEP_COMMAND =
  new InjectionToken<SubmitsCompanyBaseInfoStepCommandPort>(
    'SUBMITS_COMPANY_BASE_INFO_STEP_COMMAND',
  );

export interface SubmitsCompanyBaseInfoStepCommandPort {
  submitCompanyBaseInfoStep(command: SubmitsCompanyBaseInfoStepCommand): Observable<void>;
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WorkspaceQuery } from '../../../../../application/ports/primary/workspace.query';
import { Observable } from 'rxjs';
import {
  GetsWorkspacesListQuery,
  GETS_WORKSPACES_LIST_QUERY,
} from '../../../../../application/ports/primary/gets-workspace-list.query';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lib-cobiro-pro-workspace-list-table',
  templateUrl: './workspace-list-table.component.html',
  styleUrls: ['./workspace-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceListTableComponent {
  readonly columns = [
    'cobiro_pro_workspaces_table_workspaces_name',
    'cobiro_pro_workspaces_table_users',
    'cobiro_pro_workspaces_table_shops',
    'cobiro_pro_workspaces_table_edit',
    'cobiro_pro_workspaces_table_delete',
  ];
  readonly workspaces$: Observable<WorkspaceQuery[]> =
    this._getsWorkspacesListQuery.getWorkspaceListQuery();

  constructor(
    @Inject(GETS_WORKSPACES_LIST_QUERY)
    private readonly _getsWorkspacesListQuery: GetsWorkspacesListQuery,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  private onEditButtonClicked(workspace: WorkspaceQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        edit: workspace.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  goToWorkspaceUsers(id: string): void {
    this._router.navigate(['../workspaces', id], {
      relativeTo: this._route,
    });
  }

  private onDeleteButtonClicked(workspace: WorkspaceQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        delete: workspace.id,
      },
      queryParamsHandling: 'merge',
    });
  }
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import {
  PatchesUsersContextStoragePort,
  PATCHES_USERS_CONTEXT_STORAGE,
} from '../../../application/ports/secondary/context/patches-users-context.storage-port';
import { UsersListChangedEvent } from '../../../application/events/users-list-changed.event';

@Injectable()
export class UsersListChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UsersListChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(PATCHES_USERS_CONTEXT_STORAGE)
    private readonly _patchesUsersContextStorage: PatchesUsersContextStoragePort,
  ) {}

  handle([event]: [UsersListChangedEvent]) {
    this._patchesUsersContextStorage.patch({ list: [...event.updatedUsers] });
  }
}

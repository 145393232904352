import { Injectable } from '@angular/core';
import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { SocialSignedInEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';

@Injectable()
export class DataLayerSocialSignedInEventMapper implements DataLayerTrackingMapper {
  eventClass = SocialSignedInEvent;

  mapEvent(event: SocialSignedInEvent): DataLayerTrackingData {
    return {
      eventName: `/Hub - Account - Sign In ${event.provider} - v2`,
      eventData: {
        intent: event.intent,
        user_id: event.userId,
      },
    };
  }
}

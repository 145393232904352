import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { GETS_TEAM_SETTINGS_FORM_ERROR_KEY_DTO_PORT } from '../../../../application/ports/secondary/gets-team-settings-form-error-key-dto.port';
import { GetTeamSettingsFormErrorService } from './get-team-settings-form-error.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    GetTeamSettingsFormErrorService,
    {
      provide: GETS_TEAM_SETTINGS_FORM_ERROR_KEY_DTO_PORT,
      useExisting: GetTeamSettingsFormErrorService,
    },
  ],
})
export class GetTeamSettingsFormErrorServiceModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IsValidCountryQuery } from './is-valid-country.query';

export const GETS_IS_VALID_COUNTRY_QUERY = new InjectionToken<GetsIsValidCountryQueryPort>(
  'GETS_IS_VALID_COUNTRY_QUERY',
);

export interface GetsIsValidCountryQueryPort {
  getIsValidCountry(country: string): Observable<IsValidCountryQuery>;
}

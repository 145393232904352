import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const SETS_CONTEXT_WORKSPACE_QUERY = new InjectionToken<SetsContextWorkspaceQueryPort>(
  'SETS_CONTEXT_WORKSPACE_QUERY',
);

export interface SetsContextWorkspaceQueryPort {
  setContextWorkspace(): Observable<boolean>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface Country {
  name: string;
  code: string;
}

export interface GetsCountries {
  get(): Observable<Country[]>;
}

export const GETS_COUNTRIES = new InjectionToken<GetsCountries>('GETS_COUNTRIES');

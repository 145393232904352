import { NgModule } from '@angular/core';
import { TEAMS_LOADED_DISPATCHER } from '../../../application/ports/secondary/cobiro-pro-teams-loaded.dispatcher-port';
import { CobiroProTeamsLoadedDispatcher } from './cobiro-pro-teams-loaded.dispatcher';
import { COBIRO_PRO_TEAM_CREATED_DISPATCHER } from '../../../application/ports/secondary/cobiro-pro-team-created.dispatcher-port';
import { CobiroProTeamCreatedDispatcher } from './cobiro-pro-team-created.dispatcher';

@NgModule({
  providers: [
    {
      provide: TEAMS_LOADED_DISPATCHER,
      useClass: CobiroProTeamsLoadedDispatcher,
    },
    {
      provide: COBIRO_PRO_TEAM_CREATED_DISPATCHER,
      useClass: CobiroProTeamCreatedDispatcher,
    },
  ],
})
export class CobiroProTeamsDispatchersModule {}

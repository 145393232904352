import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountriesState } from './application/countries.state';
import { GETS_COUNTRIES } from './domain/gets-countries';
import { HttpGetsCountriesService } from './infrastructure/http-gets-countries.service';

@NgModule({
  imports: [CommonModule],
  providers: [CountriesState, { provide: GETS_COUNTRIES, useClass: HttpGetsCountriesService }],
})
export class GoogleCountriesModule {}

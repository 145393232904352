import { Notification, NOTIFICATION_TYPES } from '../domain/notification';
import { MessageNotification } from '../domain/messages/message-notification';

export class NotificationListElementQuery {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly date: Date,
    public readonly type: string,
    public readonly isRead: boolean,
  ) {}

  static fromNotification(notification: Notification): NotificationListElementQuery {
    let title: string;
    switch (notification.type) {
      case NOTIFICATION_TYPES.MESSAGE:
        title = 'New message from your form';
        break;
      case NOTIFICATION_TYPES.NEW_ORDER:
        title = 'New shop order';
        break;
    }
    return {
      id: notification.id,
      date: new Date(notification.date),
      type: notification.type,
      isRead: notification.isRead,
      title,
    };
  }
}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientDTO } from '../../../../application/ports/secondary/client.dto';
import { GetsOneClientDto } from '../../../../application/ports/secondary/gets-one-client.dto';
import { GetsOneClientDtoPort } from '../../../../application/ports/secondary/gets-one-client.dto-port';

export interface ClientAttributes {
  siteId: string;
  sitePublicId: string;
  cssIntegrationStatus: 'paused' | 'active' | 'pending' | null;
  merchantId: string | null;
  teamId: string;
  workspaceId: string;
  companyName: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  createdAt?: string;
  url: string;
  avatarUrl: string;
  source: string;
  plan: string;
  archived: boolean;
  cssDomainId: string;
}

@Injectable()
export class HttpClientsService implements GetsOneClientDtoPort {
  constructor(private readonly _gatewayClient: GatewayClient) {}

  getOne(dto: GetsOneClientDto): Observable<ClientDTO> {
    return this._gatewayClient
      .get<HasData<ClientAttributes>>(
        `v1/sites/workspaces/${dto.workspaceId}/clients/${dto.clientId}`,
      )
      .pipe(
        map((client: HasData<ClientAttributes>) => {
          const siteId = client.data.attributes.siteId;
          return {
            id: client.data.id || '',
            siteId: siteId ? String(siteId) : siteId,
            workspaceId: dto.workspaceId,
            teamId: dto.teamId,
            cssIntegrationStatus: client.data.attributes.cssIntegrationStatus,
            merchantSiteId: client.data.attributes.merchantId,
            sitePublicId: client.data.attributes.sitePublicId,
            companyName: client.data.attributes.companyName,
            contactPerson: client.data.attributes.contactPerson,
            contactEmail: client.data.attributes.contactEmail,
            contactPhone: client.data.attributes.contactPhone,
            createdAt: client.data.attributes.createdAt,
            url: client.data.attributes.url,
            source: client.data.attributes.source,
            avatar: client.data.attributes.avatarUrl,
            plan: client.data.attributes.plan,
            installedApps: [],
            archived: client.data.attributes.archived,
            subscriptions: null,
            productStatuses: {
              css: client.data.attributes.cssIntegrationStatus,
              'label-manager': null,
            },
            cssDomainId: client.data.attributes.cssDomainId,
          };
        }),
      );
  }
}

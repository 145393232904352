import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const SIGNS_UP_BY_EMAIL_DTO = new InjectionToken<SignsUpByEmailDtoPort>(
  'SIGNS_UP_BY_EMAIL_DTO',
);

export interface SignsUpByEmailDtoPort {
  signUpByEmail(
    email: string,
    password: string,
    captchaToken: string,
  ): Observable<{ userId: string }>;
}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  TeamIdGetter,
  TEAM_ID_GETTER,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { UUID } from '@app.cobiro.com/core/events';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { CancelSubscriptionAtEndOfTermSuccessModalComponent } from '../../../adapters/primary/ui/modals/cancel-subscription-modals/cancel-subscription-at-end-of-term-success-modal/cancel-subscription-at-end-of-term-success-modal.component';
import { CancelSubscriptionImmediatelySuccessModalComponent } from '../../../adapters/primary/ui/modals/cancel-subscription-modals/cancel-subscription-immediately-success-modal/cancel-subscription-immediately-success-modal.component';
import { CancelSubscriptionModalComponent } from '../../../adapters/primary/ui/modals/cancel-subscription-modals/cancel-subscription-modal/cancel-subscription-modal.component';
import { CreateNewClientModalComponent } from '../../../adapters/primary/ui/modals/create-new-client-modal/create-new-client-modal.component';
import { ClientDeleteConfirmationModalComponent } from '../../../adapters/primary/ui/modals/delete-client-modals/client-delete-confirmation-modal/client-delete-confirmation-modal.component';
import { ClientNotDeletableModalComponent } from '../../../adapters/primary/ui/modals/delete-client-modals/client-not-deletable-modal/client-not-deletable-modal.component';
import { EditClientModalComponent } from '../../../adapters/primary/ui/modals/edit-client-modal/edit-client-modal.component';
import { AddClientCommand } from '../../ports/primary/clients/add-client.query';
import { AddClientQueryPort } from '../../ports/primary/clients/add-client.query-port';
import {
  CancelReasonQuery,
  CANCEL_REASON_MAP,
} from '../../ports/primary/clients/cancel-reason.query';
import { CancelSubscriptionCSSCommand } from '../../ports/primary/clients/cancel-subscription-css-client.command';
import { CancelSubscriptionCSSCommandPort } from '../../ports/primary/clients/cancel-subscription-css-client.command-port';
import { CancelSubscriptionLMCommand } from '../../ports/primary/clients/cancel-subscription-lm-client.command copy';
import { CancelSubscriptionLMCommandPort } from '../../ports/primary/clients/cancel-subscription-lm-client.command-port';
import { ClientQuery } from '../../ports/primary/clients/client.query';
import { CreateClientCommandPort } from '../../ports/primary/clients/create-client-command.port';
import { CreateClientCommand } from '../../ports/primary/clients/create-client.command';
import { DeletesClientCommand } from '../../ports/primary/clients/deletes-client.command';
import { DeletesClientCommandPort } from '../../ports/primary/clients/deletes-client.command-port';
import { EditClientCommandPort } from '../../ports/primary/clients/edit-client-command.port';
import { EditClientCommand } from '../../ports/primary/clients/edit-client.command';
import { GetsCancelReasonQueryPort } from '../../ports/primary/clients/gets-cancel-reason.query-port';
import { GetsEditClientQueryPort } from '../../ports/primary/clients/gets-edit-client-query.port';
import { GetsProductInfoQuery } from '../../ports/primary/clients/gets-product-info.query';
import { GetsProductInfoQueryPort } from '../../ports/primary/clients/gets-product-info.query-port';
import { GetsSelectedClientQueryPort } from '../../ports/primary/clients/gets-selected-client.query-port';
import { ProductInfoQuery } from '../../ports/primary/clients/product-info.query';
import { PRODUCT_LIST } from '../../ports/primary/clients/product-list.query';
import { SetsSelectedClientCommandPort } from '../../ports/primary/clients/sets-selected-client-command.port';
import { ClientSubscriptionQuery } from '../../ports/primary/clients/subscription.query';
import { ClientsContext } from '../../ports/secondary/context/clientsContext';
import {
  PatchesClientsContextStoragePort,
  PATCHES_CLIENTS_CONTEXT_STORAGE,
} from '../../ports/secondary/context/patches-clients-context.storage-port';
import {
  SELECTS_CLIENTS_CONTEXT_STORAGE,
  SelectsClientsContextStoragePort,
} from '../../ports/secondary/context/selects-clients-context.storage-port';
import {
  CLIENTS_LIST_CHANGED_DISPATCHER,
  ClientsListChangedDispatcherPort,
} from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import {
  ADDS_CLIENT_SITE_DTO,
  AddsClientSiteDtoPort,
} from '../../ports/secondary/dto/clients/adds-client-site.dto-port';
import {
  ADDS_CLIENT_DTO,
  AddsClientDtoPort,
} from '../../ports/secondary/dto/clients/adds-client.dto-port';
import {
  CancelsClientSubscriptionCSSDtoPort,
  CANCELS_CLIENT_SUBSCRIPTION_CSS_DTO,
} from '../../ports/secondary/dto/clients/cancels-client-subscription-css.dto-port';
import {
  CancelsClientSubscriptionLMDtoPort,
  CANCELS_CLIENT_SUBSCRIPTION_LM_DTO,
} from '../../ports/secondary/dto/clients/cancels-client-subscription-lm.dto-port';
import { ClientDTO } from '../../ports/secondary/dto/clients/client.dto';
import {
  DELETES_CLIENT_DTO,
  DeletesClientDtoPort,
} from '../../ports/secondary/dto/clients/deletes-client.dto-port';
import {
  GetsClientSubsciptionsDtoPort,
  GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT,
} from '../../ports/secondary/dto/clients/gets-client-subscriptions.dto-port';
import {
  SETS_CLIENT_DTO,
  SetsClientDtoPort,
} from '../../ports/secondary/dto/clients/sets-client.dto-port';
import { SubscriptionDto } from '../../ports/secondary/dto/clients/subscription.dto';

@Injectable()
export class ClientsState
  implements
    EditClientCommandPort,
    GetsEditClientQueryPort,
    CreateClientCommandPort,
    DeletesClientCommandPort,
    GetsCancelReasonQueryPort,
    CancelSubscriptionCSSCommandPort,
    CancelSubscriptionLMCommandPort,
    CancelSubscriptionLMCommandPort,
    GetsSelectedClientQueryPort,
    SetsSelectedClientCommandPort,
    GetsProductInfoQueryPort,
    AddClientQueryPort
{
  private _currentClientDeleting$ = new BehaviorSubject<string | null>(null);
  private _currentClientIdSubject$ = new BehaviorSubject<string | null>(null);

  constructor(
    @Inject(ADDS_CLIENT_SITE_DTO)
    private readonly _addsClientSite: AddsClientSiteDtoPort,
    @Inject(ADDS_CLIENT_DTO)
    private readonly _addsClient: AddsClientDtoPort,
    @Inject(SETS_CLIENT_DTO)
    private readonly _setsClient: SetsClientDtoPort,
    @Inject(DELETES_CLIENT_DTO)
    private readonly _deletesClient: DeletesClientDtoPort,
    @Inject(CLIENTS_LIST_CHANGED_DISPATCHER)
    private readonly _clientsListChangedDispatcher: ClientsListChangedDispatcherPort,
    @Inject(SELECTS_CLIENTS_CONTEXT_STORAGE)
    private readonly _selectsClientsContextStorage: SelectsClientsContextStoragePort,
    @Inject(PATCHES_CLIENTS_CONTEXT_STORAGE)
    private readonly _patchesClientsContextStorage: PatchesClientsContextStoragePort,
    @Inject(CANCELS_CLIENT_SUBSCRIPTION_CSS_DTO)
    private readonly _cancelsClientSubscriptionCSSDto: CancelsClientSubscriptionCSSDtoPort,
    @Inject(CANCELS_CLIENT_SUBSCRIPTION_LM_DTO)
    private readonly _cancelsClientSubscriptionLMDto: CancelsClientSubscriptionLMDtoPort,
    @Inject(GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT)
    private readonly _getsClientSubsciptionsDtoPort: GetsClientSubsciptionsDtoPort,
    private readonly _alert: HuiAlert,
    private readonly _matDialog: MatDialog,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(WORKSPACE_ID_GETTER) private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(STORAGE) private readonly _storage: Storage,
  ) {}

  setSelectedClient(clientId: string): Observable<ClientDTO> {
    return this._selectsClientsContextStorage.select().pipe(
      take(1),
      switchMap((clientsContext: ClientsContext) => {
        const selectedClient: ClientDTO = clientsContext.list.find(item => item.id === clientId);
        this._storage.setItem('cobiro-pro-current-client', selectedClient.siteId);
        return this._patchesClientsContextStorage.patch({ selectedClient: selectedClient }).pipe(
          take(1),
          map(() => selectedClient),
        );
      }),
    );
  }

  getSelectedClient(): Observable<ClientQuery> {
    return this._selectsClientsContextStorage
      .select()
      .pipe(map((clientsContext: ClientsContext) => clientsContext.selectedClient));
  }

  editClient(command: EditClientCommand): Observable<void> {
    this._currentClientIdSubject$.next(command.id);
    return this._matDialog
      .open(EditClientModalComponent, {
        minWidth: '500px',
        maxWidth: '700px',
        panelClass: 'cs-mat-dialog',
        data: { route: command.route },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(clientFormData => {
          const editedClient: Pick<
            ClientDTO,
            | 'companyName'
            | 'contactPerson'
            | 'contactEmail'
            | 'contactPhone'
            | 'workspaceId'
            | 'teamId'
            | 'id'
            | 'avatar'
          > = {
            ...clientFormData,
            ...{ id: command.id, workspaceId: this._workspaceIdGetter.getWorkspaceId() },
          };
          return this._setsClient.set(editedClient).pipe(
            switchMap(() => this._selectsClientsContextStorage.select().pipe(take(1))),
            tap((clientContext: ClientsContext) => {
              const updatedClients: ClientDTO[] = clientContext.list.map(client =>
                client.id === command.id ? { ...client, ...clientFormData } : client,
              );

              this._clientsListChangedDispatcher.dispatch({ updatedClients: [...updatedClients] });
              this._currentClientIdSubject$.next(null);
              this._alert.open('success', 'cobiro_pro_shop_edit_form_submit_success');
            }),
            map(() => void 0),
          );
        }),
      );
  }

  getEditClientQuery(): Observable<ClientQuery> {
    return combineLatest([
      this._selectsClientsContextStorage.select(),
      this._currentClientIdSubject$.asObservable(),
    ]).pipe(
      map(([clientContext, clientToEdit]) => {
        const clientDTO = clientContext.list?.find(dto => dto.id === clientToEdit);
        if (!clientDTO) {
          return undefined;
        }

        return ClientQuery.fromClientDTO(clientDTO);
      }),
    );
  }

  deleteClient(command: DeletesClientCommand): Observable<void> {
    return command.isDeletable
      ? this._startDeleteClientProcess(command)
      : this._showNotDeletableClientModal(command);
  }

  private _startDeleteClientProcess(command: DeletesClientCommand): Observable<void> {
    return this._matDialog
      .open(ClientDeleteConfirmationModalComponent)
      .afterClosed()
      .pipe(concatMap(result => (result ? this._deleteClient(command) : of(void 0))));
  }

  private _showNotDeletableClientModal(command: DeletesClientCommand): Observable<void> {
    return this._matDialog
      .open(ClientNotDeletableModalComponent, {
        data: { subscriptions: command.subscriptions },
      })
      .afterClosed()
      .pipe(
        filter(result => result !== undefined),
        concatMap(result => {
          switch (result) {
            case false:
              return of(void 0);
            case PRODUCT_LIST.css:
              return this.cancelSubscriptionCSS({
                clientId: command.id,
                siteId: command.siteId,
                userEmail: command.userEmail,
              });
            case PRODUCT_LIST.labelManager:
              return this.cancelSubscriptionLM({
                clientId: command.id,
                subscriptionId: command.subscriptions.find(
                  subscription => subscription.productName === PRODUCT_LIST.labelManager,
                )?.subscriptionId,
                userEmail: command.userEmail,
              });
          }
        }),
      );
  }

  private _deleteClient(command: DeletesClientCommand): Observable<void> {
    this._currentClientDeleting$.next(command.id);

    return this._deletesClient
      .deleteClient({ id: command.id, workspaceId: command.workspaceId })
      .pipe(
        finalize(() => this._currentClientDeleting$.next(null)),
        tap({
          next: () => this._alert.open('success', 'cobiro_pro_shop_deleted_success'),
          error: () => this._alert.open('error', 'cobiro_pro_shop_deleted_failed'),
        }),
        switchMap(() =>
          this._selectsClientsContextStorage.select().pipe(
            take(1),
            map(clientsContext => clientsContext.list),
          ),
        ),
        tap((clients: ClientDTO[]) => {
          const updatedClients: ClientDTO[] = clients.map(client =>
            client.id === command.id ? { ...client, archived: true } : client,
          );
          this._clientsListChangedDispatcher.dispatch({ updatedClients: [...updatedClients] });
        }),
        map(() => void 0),
      );
  }

  getCancelReasonList(): Observable<CancelReasonQuery[]> {
    return of(
      Object.keys(CANCEL_REASON_MAP).map(reasonLabel => new CancelReasonQuery(reasonLabel)),
    );
  }

  cancelSubscriptionCSS(command: CancelSubscriptionCSSCommand): Observable<void> {
    const siteId = command.siteId;
    return this._matDialog
      .open(CancelSubscriptionModalComponent, {
        data: {
          productName: PRODUCT_LIST.css,
        },
        maxWidth: '500px',
        panelClass: 'cs-mat-dialog',
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(({ endOfTerm, reason, comment }) => {
          const reasonCode = CANCEL_REASON_MAP[reason];

          return this._cancelsClientSubscriptionCSSDto
            .cancel({ siteId, endOfTerm, reasonCode, comment: `${command.userEmail}: ${comment}` })
            .pipe(map(() => endOfTerm));
        }),
        mergeMap(cancelledAtEndOfTerm =>
          iif(
            () => !cancelledAtEndOfTerm,
            this._selectsClientsContextStorage.select().pipe(
              take(1),
              map(clientsContext => clientsContext.list),
              tap((clients: ClientDTO[]) => {
                const updatedClients: ClientDTO[] = clients.map(client =>
                  client.id === command.clientId
                    ? {
                        ...client,
                        subscriptions: client.subscriptions.filter(
                          subscription => subscription.productName !== PRODUCT_LIST.css,
                        ),
                        productStatuses: {
                          ...client.productStatuses,
                          css: null,
                        },
                      }
                    : client,
                );
                this._clientsListChangedDispatcher.dispatch({
                  updatedClients: [...updatedClients],
                });
              }),
              map(() => cancelledAtEndOfTerm),
            ),
            of(map(() => cancelledAtEndOfTerm)),
          ),
        ),
        tap((cancelledAtEndOfTerm: boolean) => {
          this._matDialog.open(
            cancelledAtEndOfTerm
              ? CancelSubscriptionAtEndOfTermSuccessModalComponent
              : CancelSubscriptionImmediatelySuccessModalComponent,
            { maxWidth: '500px', panelClass: 'cs-mat-dialog' },
          );
        }),
        map(() => void 0),
      );
  }

  cancelSubscriptionLM(command: CancelSubscriptionLMCommand): Observable<void> {
    const subscriptionId = command.subscriptionId;
    return this._matDialog
      .open(CancelSubscriptionModalComponent, {
        data: {
          productName: PRODUCT_LIST.labelManager,
        },
        maxWidth: '565px',
        panelClass: 'cs-mat-dialog',
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(({ endOfTerm, reason, comment }) => {
          const reasonCode = CANCEL_REASON_MAP[reason];

          return this._cancelsClientSubscriptionLMDto
            .cancel({
              subscriptionId,
              endOfTerm,
              reasonCode,
              comment: `${command.userEmail}: ${comment}`,
            })
            .pipe(
              catchError(() => {
                this._alert.open('error', '_something_went_wrong');
                return of(void 0);
              }),
              map(() => endOfTerm),
            );
        }),
        mergeMap(cancelledAtEndOfTerm =>
          iif(
            () => !cancelledAtEndOfTerm,
            this._selectsClientsContextStorage.select().pipe(
              take(1),
              map(clientsContext => clientsContext.list),
              tap((clients: ClientDTO[]) => {
                const updatedClients: ClientDTO[] = clients.map(client =>
                  client.id === command.clientId
                    ? {
                        ...client,
                        subscriptions: client.subscriptions.filter(
                          subscription => subscription.productName !== PRODUCT_LIST.labelManager,
                        ),
                        productStatuses: {
                          ...client.productStatuses,
                          'label-manager': null,
                        },
                      }
                    : client,
                );
                this._clientsListChangedDispatcher.dispatch({
                  updatedClients: [...updatedClients],
                });
              }),
              map(() => cancelledAtEndOfTerm),
            ),
            of(map(() => cancelledAtEndOfTerm)),
          ),
        ),
        tap((cancelledAtEndOfTerm: boolean) => {
          this._matDialog.open(
            cancelledAtEndOfTerm
              ? CancelSubscriptionAtEndOfTermSuccessModalComponent
              : CancelSubscriptionImmediatelySuccessModalComponent,
            { maxWidth: '500px', panelClass: 'cs-mat-dialog' },
          );
        }),
        map(() => void 0),
      );
  }

  createClient(command: CreateClientCommand): Observable<void> {
    return this._matDialog
      .open(CreateNewClientModalComponent, {
        minWidth: '500px',
        maxWidth: '700px',
        panelClass: 'cs-mat-dialog',
        data: { route: command.route },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(clientFormData => this.addClient(clientFormData)),
        map(() => void 0),
      );
  }

  addClient(query: AddClientCommand): Observable<ClientDTO> {
    const clientId = new UUID().value;
    const sitePublicId = new UUID().value;
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    const teamId = this._teamIdGetter.getTeamId();
    const newClient: Omit<
      ClientDTO,
      | 'cssIntegrationStatus'
      | 'merchantSiteId'
      | 'sitePublicId'
      | 'plan'
      | 'installedApps'
      | 'cssDomainId'
    > = {
      id: clientId,
      siteId: sitePublicId,
      teamId: teamId,
      workspaceId: workspaceId,
      companyName: query.companyName,
      contactPerson: null,
      contactEmail: null,
      contactPhone: null,
      url: query.url,
      avatar: null,
      source: 'outside-cobiro',
      archived: false,
      subscriptions: [],
      productStatuses: {
        css: null,
        'label-manager': null,
      },
    };
    return this._addsClient.add(newClient).pipe(
      switchMap(() =>
        combineLatest([
          this._addsClientSite.add({
            id: sitePublicId,
            url: newClient.url,
            workspaceId: workspaceId,
          }),
          this._selectsClientsContextStorage.select().pipe(take(1)),
        ]),
      ),
      map(([siteId, clientsContext]: [string, ClientsContext]) => {
        const newClientData: ClientDTO = Object.assign({}, newClient, {
          siteId: siteId,
          sitePublicId: sitePublicId,
          merchantSiteId: null,
          source: 'outside',
          installedApps: [],
          archived: false,
          createdAt: new Date().toISOString().replace('T', ' '),
          cssIntegrationStatus: null,
          subscriptions: [],
          plan: null,
          productStatuses: {
            css: null,
            'label-manager': null,
          },
          cssDomainId: null,
        });
        const updatedClients: ClientDTO[] = [newClientData, ...clientsContext.list];
        this._clientsListChangedDispatcher.dispatch({ updatedClients: [...updatedClients] });
        this._alert.open('success', 'cobiro_pro_shop_create_form_submit_success');
        return newClientData;
      }),
    );
  }

  getProductInfo(command: GetsProductInfoQuery): Observable<ProductInfoQuery> {
    if (command.client.subscriptions !== null) {
      const subscription = command.client.subscriptions.find(
        subscription => subscription.productName === command.product,
      )
        ? command.client.subscriptions.find(
            subscription => subscription.productName === command.product,
          )
        : null;
      return of(
        new ProductInfoQuery(subscription, command.client.productStatuses[command.product]),
      );
    }
    return combineLatest([
      this._getsClientSubsciptionsDtoPort.getSubscriptions({
        workspaceId: this._workspaceIdGetter.getWorkspaceId(),
        clientId: command.client.id,
      }),
      this._selectsClientsContextStorage.select().pipe(
        take(1),
        map(context => context.list),
      ),
    ]).pipe(
      map(([subscriptions, list]: [SubscriptionDto[], ClientDTO[]]) => {
        const updatedClients: ClientDTO[] = list.map((client: ClientDTO) =>
          client.id === command.client.id
            ? {
                ...client,
                ...{
                  subscriptions: subscriptions,
                  productStatuses: {
                    css: client.cssIntegrationStatus,
                    'label-manager': subscriptions.find(
                      subs => subs.productName === 'label-manager',
                    )
                      ? ('active' as const)
                      : null,
                  },
                },
              }
            : client,
        );
        this._clientsListChangedDispatcher.dispatch({ updatedClients: [...updatedClients] });

        const updatedClient = updatedClients.find(client => client.id === command.client.id);

        const subscription = subscriptions.find(
          subscription => subscription.productName === command.product,
        )
          ? ClientSubscriptionQuery.fromDTO(
              subscriptions.find(subscription => subscription.productName === command.product),
            )
          : null;

        return new ProductInfoQuery(subscription, updatedClient.productStatuses[command.product]);
      }),
    );
  }
}

import { LabelManagerConfigDto } from './label-manager-config.dto';

export const LABEL_MANAGER_CONFIG_DTO_STUB: LabelManagerConfigDto = {
  id: '1',
  breakEvenROAS: 25,
  lookBackWindow: 17,
  customLabel: 'custom_label_0',
  analysisFrequency: 3,
};

export const LABEL_MANAGER_INITIAL_CONFIG_DTO_STUB: LabelManagerConfigDto = {
  id: '1',
  breakEvenROAS: 500,
  lookBackWindow: 90,
  customLabel: 'custom_label_4',
  analysisFrequency: 7,
};

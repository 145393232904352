import { EventLogMonitor } from '../../event-logger/domain/event-log-monitor';
import { Inject, Injectable } from '@angular/core';
import { SURVEY_TRACKING_MAP, SurveyTrackingMap } from '../domain/survey-tracking-map';
import { LAUNCHES_SURVEY, LaunchesSurvey } from '../domain/launches-survey';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import { take } from 'rxjs/operators';

@Injectable()
export class SurveyMonitor implements EventLogMonitor {
  readonly eventHandlers = Array.from(this._surveyTrackingMap).map(([eventClass, surveyId]) => {
    return {
      eventClass,
      handler: () => {
        this.featureFlagsState
          .hasFlags(['surveys'])
          .pipe(take(1))
          .subscribe(hasFlag => {
            if (hasFlag) {
              this._launchesSurvey.launch(surveyId);
            }
          });
      },
    };
  });
  constructor(
    @Inject(SURVEY_TRACKING_MAP) private _surveyTrackingMap: SurveyTrackingMap,
    @Inject(LAUNCHES_SURVEY) private _launchesSurvey: LaunchesSurvey,
    private featureFlagsState: FeatureFlagsState,
  ) {}
}

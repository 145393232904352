import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetailsQuery } from './user-details.query';

export const GETS_USER_DETAILS_QUERY = new InjectionToken<GetsUserDetailsQueryPort>(
  'GETS_USER_DETAILS_QUERY',
);

export interface GetsUserDetailsQueryPort {
  getUserDetails(): Observable<UserDetailsQuery>;
}

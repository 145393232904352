import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomainError } from '@app.cobiro.com/core/state';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HasData, HasErrorResponse, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { camelCase } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChecksDiscountCode, DiscountCodeCheckResult } from '../../domain/checks-discount-code';
import {
  CouponExpiredError,
  CouponInvalidError,
  CouponNotApplicableError,
} from '../../domain/checks-discount-code-errors';
import {
  Discount,
  DISCOUNT_DURATION_PERIOD_UNITS,
  DISCOUNT_DURATION_TYPES,
} from '../../domain/discount';

export interface DiscountCodeCheckAttributes {
  duration: {
    type: 'period' | 'forever' | 'one_time';
    period: number | null;
    unit: 'day' | 'week' | 'month' | 'year' | null;
  };
  type: 'percentage' | 'flat';
  value: number;
}

@Injectable()
export class HttpChecksDiscountCodeService implements ChecksDiscountCode {
  private _headers = JSON_API_HEADERS;
  private readonly _errorMap = new Map<string, DomainError>([
    [CouponInvalidError.code, new CouponInvalidError()],
    [CouponExpiredError.code, new CouponExpiredError()],
    [CouponNotApplicableError.code, new CouponNotApplicableError()],
  ]);

  constructor(
    private readonly _client: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  checkCode(code: string, planId: string): Observable<DiscountCodeCheckResult> {
    return this._client
      .get<HasData<DiscountCodeCheckAttributes>>(
        `${this._getsConfig.get('gateway')}/v1/payments/discount-codes/${code}?planId=${planId}`,
        {
          headers: this._headers,
        },
      )
      .pipe(
        map(response => {
          return new DiscountCodeCheckResult(this._mapResponseToDiscountCode(response));
        }),
        catchError((error: HasErrorResponse) => {
          const errorCode = error.error?.errors[0]?.code;
          if (!errorCode) {
            throw new CouponInvalidError();
          }

          throw this._errorMap.get(errorCode) ?? new CouponInvalidError();
        }),
      );
  }

  private _mapResponseToDiscountCode(response: HasData<DiscountCodeCheckAttributes>): Discount {
    return new Discount(
      response.data.id,
      response.data.attributes.type,
      response.data.attributes.value,
      {
        type: DISCOUNT_DURATION_TYPES[camelCase(response.data.attributes.duration.type)],
        value: response.data.attributes.duration.period,
        unit: DISCOUNT_DURATION_PERIOD_UNITS[response.data.attributes.duration.unit],
      },
    );
  }
}

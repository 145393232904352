import { InjectionToken } from '@angular/core';
import { ProductMarketingDTO } from './product-marketing.dto';

export const GETS_PRODUCT_MARKETING_LIST_DTO = new InjectionToken<GetsProductMarketingListDtoPort>(
  'GETS_PRODUCT_MARKETING_LIST_DTO',
);

export interface GetsProductMarketingListDtoPort {
  getProductsMarketing(product: string): ProductMarketingDTO;
}

import { Observable } from 'rxjs';
import { UpdateUserBillingDto } from './update-user-billing.dto';
import { InjectionToken } from '@angular/core';
import { UPDATE_ERROR_FIELDS } from '../../domain/update-error-fields';

export interface UpdateDtoError {
  errorField: UPDATE_ERROR_FIELDS | null;
}

export interface UpdatesUserBillingDtoPort {
  updateBilling(updateBillingDto: UpdateUserBillingDto): Observable<boolean>;
}

// TODO: move this file to sites/payment-plans
export const UPDATE_USER_BILLING_DTO = new InjectionToken<UpdatesUserBillingDtoPort>(
  'UPDATE_USER_BILLING_DTO',
);

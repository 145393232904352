import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { CANCELS_INTEGRATION_DTO } from '../../../../application/ports/secondary/cancels-integration.dto-port';
import { CREATES_INTEGRATION_DTO } from '../../../../application/ports/secondary/creates-integration.dto-port';
import { GETS_INTEGRATION_ERROR_DTO } from '../../../../application/ports/secondary/gets-integration-error.dto-port';
import { GETS_INTEGRATION_LINK_STATUS_DTO } from '../../../../application/ports/secondary/gets-integration-link-status.dto-port';
import { GETS_INTEGRATION_DTO } from '../../../../application/ports/secondary/gets-integration.dto-port';
import { LINKS_INTEGRATION_DTO } from '../../../../application/ports/secondary/links-integration.dto-port';
import { SWITCHES_CSS_DOMAIN_DTO } from '../../../../application/ports/secondary/switches-css-domain.dto-port';
import { SWITCHES_INTEGRATION_DTO } from '../../../../application/ports/secondary/switches-integration.dto-port';
import { UPDATES_INTEGRATION_DTO } from '../../../../application/ports/secondary/updates-integration.dto-port';
import { UPGRADES_INTEGRATION_DTO } from '../../../../application/ports/secondary/upgrades-integration.dto-port';
import { HttpIntegrationService } from './http-integration.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpIntegrationService,
    {
      provide: CANCELS_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: CREATES_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: GETS_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: GETS_INTEGRATION_ERROR_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: GETS_INTEGRATION_LINK_STATUS_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: LINKS_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: SWITCHES_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: UPDATES_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: UPGRADES_INTEGRATION_DTO,
      useExisting: HttpIntegrationService,
    },
    {
      provide: SWITCHES_CSS_DOMAIN_DTO,
      useExisting: HttpIntegrationService,
    },
  ],
})
export class HttpIntegrationServiceModule {}

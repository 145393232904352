import { Inject, Injectable } from '@angular/core';
import {
  SignedInEvent,
  SocialSignedInEvent,
  TrackingDataCollectedEvent,
} from '@app.cobiro.com/core/events';
import { DomainError } from '@app.cobiro.com/core/state';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, take, tap } from 'rxjs/operators';
import {
  SIGN_UP_PROVIDERS,
  SIGNS_UP_BY,
  SignsUpBy,
  SocialAuth,
  SocialMissingEmailError,
  SocialUnexpectedError,
} from '../domain/signs-up-by';
import { SignsUpByFacebookCommand } from './ports/primary/signs-up-by-facebook.command';
import { SignsUpByFacebookCommandPort } from './ports/primary/signs-up-by-facebook.command-port';
import { SignsUpByGoogleCommand } from './ports/primary/signs-up-by-google.command';
import { SignsUpByGoogleCommandPort } from './ports/primary/signs-up-by-google.command-port';

@Injectable()
export class SocialLoginState implements SignsUpByFacebookCommandPort, SignsUpByGoogleCommandPort {
  private readonly _errors = new Map<string, string>([
    [SocialMissingEmailError.code, 'facebook_email_unable'],
    [SocialUnexpectedError.code, 'social_default_fail_message'],
  ]);

  constructor(
    @Inject(SIGNS_UP_BY) private _auth: SignsUpBy,
    private _socialAuth: SocialAuthService,
    @Inject(APPLICATION_BUS) private _applicationBus: Dispatcher<ApplicationEvent>,
    private _alert: HuiAlert,
  ) {}

  signUpByFacebook({ intent }: SignsUpByFacebookCommand): Observable<SocialAuth | null> {
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up social facebook'));

    return from(this._socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      catchError(() => {
        this._alert.open('error', 'social_default_fail_message');
        return of(null);
      }),
      filter(socialUser => !!socialUser),
      concatMap(socialUser =>
        this._auth.signUpBy(SIGN_UP_PROVIDERS.FACEBOOK, socialUser.authToken).pipe(
          catchError((err: DomainError) => {
            const message = this._errors.get(err.name);
            this._alert.open('error', message);
            return of(null);
          }),
        ),
      ),
      filter(auth => !!auth),
      tap(auth =>
        this._applicationBus.dispatch(
          new SignedInEvent(auth.userId, auth.tokenType, auth.accessToken, auth.refreshToken),
        ),
      ),
      tap(auth =>
        this._applicationBus.dispatch(
          new SocialSignedInEvent(intent, 'Facebook', String(auth.userId)),
        ),
      ),
      take(1),
    );
  }

  signUpByGoogle({ intent }: SignsUpByGoogleCommand): Observable<SocialAuth | null> {
    this._applicationBus.dispatch(new TrackingDataCollectedEvent('Sign up social google'));

    return this._socialAuth.authState.pipe(
      catchError(() => {
        this._alert.open('error', 'social_default_fail_message');
        return of(null);
      }),
      filter(socialUser => !!socialUser),
      concatMap(socialUser =>
        this._auth.signUpBy(SIGN_UP_PROVIDERS.GOOGLE, socialUser.idToken).pipe(
          catchError(() => {
            return of(null);
          }),
        ),
      ),
      tap(auth =>
        this._applicationBus.dispatch(
          new SignedInEvent(auth.userId, auth.tokenType, auth.accessToken, auth.refreshToken),
        ),
      ),
      tap(auth =>
        this._applicationBus.dispatch(
          new SocialSignedInEvent(intent, 'Google', String(auth.userId)),
        ),
      ),
      take(1),
    );
  }
}

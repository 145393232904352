/* eslint-disable complexity */
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  GetsWorkspaceUsersListQueryPort,
  GETS_WORKSPACE_USERS_LIST_QUERY,
} from '../../../../../application/ports/primary/gets-workspace-users-list.query-port';
import {
  LoadWorkspaceUsersCommandPort,
  LOAD_WORKSPACE_USERS_COMMAND,
} from '../../../../../application/ports/primary/load-workspace-users.command-port';
import { catchError, mergeMap, Observable, of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { UserQuery } from '../../../../../application/ports/primary/user.query';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DELETE_WORKSPACE_USER_COMMAND,
  DeleteWorkspaceUserCommandPort,
} from '../../../../../application/ports/primary/deletes-workspace-user.command-port';
import {
  SetsContextWorkspaceQueryPort,
  SETS_CONTEXT_WORKSPACE_QUERY,
} from '../../../../../application/ports/primary/sets-context-workspace.query-port';
import {
  RESENDS_INVITATION_COMMAND,
  ResendsInvitationCommandPort,
} from '../../../../../application/ports/primary/resends-invitation.command-port';
import { ResendsInvitationCommand } from '../../../../../application/ports/primary/resends-invitation.command';

@Component({
  selector: 'lib-cobiro-pro-workspace-users-table',
  templateUrl: './workspace-users-table.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProWorkspaceUsersTableComponent implements OnInit, OnDestroy {
  @Input() isUsedContext: boolean;
  private _ngDestroy$ = new Subject<void>();
  readonly columns = [
    'cobiro_pro_users_table_user_name',
    'cobiro_pro_users_table_email',
    'cobiro_pro_users_table_role',
    'cobiro_pro_users_table_delete',
  ];
  private routeQueryParams$: Subscription;
  readonly users$: Observable<UserQuery[]> = this._getsUserListQuery.getWorkspaceUserListQuery();

  constructor(
    @Inject(LOAD_WORKSPACE_USERS_COMMAND)
    private readonly _loadUsersCommand: LoadWorkspaceUsersCommandPort,
    @Inject(GETS_WORKSPACE_USERS_LIST_QUERY)
    private readonly _getsUserListQuery: GetsWorkspaceUsersListQueryPort,
    @Inject(DELETE_WORKSPACE_USER_COMMAND)
    private readonly _deleteWorkspaceUserCommandPort: DeleteWorkspaceUserCommandPort,
    @Inject(SETS_CONTEXT_WORKSPACE_QUERY)
    private readonly _setsContextWorkspaceQueryPort: SetsContextWorkspaceQueryPort,
    @Inject(RESENDS_INVITATION_COMMAND)
    private readonly _resendsInvitationCommand: ResendsInvitationCommandPort,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  ngOnInit(): void {
    if (this.isUsedContext) {
      this._setsContextWorkspaceQueryPort.setContextWorkspace().pipe(take(1)).subscribe();
    }
    this._loadUsersCommand.loadWorkspaceUsers().pipe(takeUntil(this._ngDestroy$)).subscribe();
    this.routeQueryParams$ = this._route.queryParams
      .pipe(
        mergeMap(params => {
          if (params['delete']) {
            return this._deleteWorkspaceUserCommandPort
              .deleteUser({
                id: params['delete'],
                route: this._route,
              })
              .pipe(catchError(() => of(undefined)));
          }

          return of(void 0);
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onDeleteButtonClicked(user: UserQuery): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        delete: user.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  onResendInvitationClicked(user: UserQuery): void {
    this._resendsInvitationCommand
      .resendInvitation(new ResendsInvitationCommand(user.id))
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

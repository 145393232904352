import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EntersIntegrationDetailsEditModeCommand } from './enters-integration-details-edit-mode.command';

export const ENTERS_INTEGRATION_DETAILS_EDIT_MODE_COMMAND =
  new InjectionToken<EntersIntegrationDetailsEditModeCommandPort>(
    'ENTERS_INTEGRATION_DETAILS_EDIT_MODE_COMMAND',
  );

export interface EntersIntegrationDetailsEditModeCommandPort {
  enterEditMode(command: EntersIntegrationDetailsEditModeCommand): Observable<void>;
}

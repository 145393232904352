<div class="cs-container">
  <div class="d-flex align-items-center cs-pt-30 cs-pb-30">
    <img src="assets/apps/icon/google_css.svg" />
    <h2 class="cs-title-3 cs-ml-20">{{ 'app_google_css_header' | language }}</h2>
  </div>
</div>
<hr class="cs-divider cs-mb-40" />
<ng-container *ngIf="(isPaused$ | async) === true; else isNotPaused">
  <lib-integration-paused></lib-integration-paused>
</ng-container>

<ng-template #isNotPaused>
  <ng-container *huiLoaderContainer="integrationStatus$ | async as integrationStatus">
    <ng-container *ngIf="integrationStatus.isPending() || integrationStatus.isMissing()">
      <hui-stepper class="integration-stepper d-block">
        <cdk-step>
          <section
            class="cs-container d-flex align-items-start position-relative"
            [class.is-creating]="(isCreating$ | async) || (isEditing$ | async)"
          >
            <mat-accordion class="w-100 cs-mat-accordion">
              <mat-expansion-panel class="cs-mb-0" [expanded]="isSwitchedPanelExpanded$ | async">
                <mat-expansion-panel-header
                  class="d-flex align-items-center br-0 expansion-panel-header"
                  ><span class="d-block">
                    <h4 class="cs-title-4">
                      {{ 'google_css_integration_step_1_switch_title' | language }}
                    </h4>
                    <p
                      class="cs-mt-10"
                      [innerHTML]="'google_css_integration_step_1_switch_subtitle' | language"
                    ></p></span
                ></mat-expansion-panel-header>

                <lib-switch-existing-integration-account-form></lib-switch-existing-integration-account-form>
              </mat-expansion-panel>
              <mat-expansion-panel class="cs-mb-0" [expanded]="isOwnedPanelExpanded$ | async">
                <mat-expansion-panel-header
                  class="d-flex align-items-center br-0 expansion-panel-header"
                >
                  <span class="d-block"
                    ><h4 class="cs-title-4">
                      {{ 'google_css_integration_step_1_create_title' | language }}
                    </h4>
                    <p class="cs-mt-10">
                      {{ 'google_css_integration_step_1_create_subtitle' | language }}
                    </p></span
                  ></mat-expansion-panel-header
                >

                <lib-integration-account-form></lib-integration-account-form>
              </mat-expansion-panel>
            </mat-accordion>
          </section>
        </cdk-step>

        <cdk-step>
          <section class="cs-container d-flex align-items-start position-relative">
            <div class="cs-card" [ngClass]="{ 'cs-min-height-440xs': !isErrored }">
              <ng-container *ngIf="isErrored; then errorTmpl; else noErrorTmpl"></ng-container>

              <ng-template #errorTmpl>
                <lib-integration-error class="cs-mb-20"></lib-integration-error>
              </ng-template>

              <ng-template #noErrorTmpl>
                <lib-switch-in-progress></lib-switch-in-progress>
              </ng-template>

              <lib-integration-action-buttons></lib-integration-action-buttons>
            </div>
          </section>
        </cdk-step>
      </hui-stepper>
    </ng-container>

    <ng-container *ngIf="integrationStatus.isSuccess()">
      <lib-integration-succeeded></lib-integration-succeeded>
    </ng-container>

    <ng-container *ngIf="integrationStatus.isPaused()">
      <lib-integration-paused></lib-integration-paused>
    </ng-container>
  </ng-container>
</ng-template>

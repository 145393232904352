import { NgModule } from '@angular/core';
import { GETS_VAT_NUMBER_VALIDATION_QUERY } from '../ports/primary/gets-vat-number-validation.query-port';
import { TeamSettingsState } from './team-settings.state';
import { GETS_TEAM_SETTINGS_QUERY_PORT } from '../ports/primary/gets-team-settings.query-port';
import { SAVES_TEAM_SETTINGS_COMMAND } from '../ports/primary/saves-team-settings.command-port';
import { LOADS_TEAMS_SETTINGS_COMMAND_PORT } from '../ports/primary/loads-team-settings.command-port';
import { TEAM_UPDATED_DISPATCHER } from '../ports/secondary/team-updated.dispatcher-port';
import { ApplicationBusTeamUpdatedDispatcher } from '../../adapters/secondary/dispatchers/application-bus-team-updated.dispatcher';
import { CLEARS_TEAMS_SETTINGS_COMMAND_PORT } from '../ports/primary/clears-team-settings.command-port';
import { GETS_COUNTRIES_QUERY } from '../ports/primary/gets-countries.query-port';
import { GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT } from '../ports/primary/gets-save-team-command-error.query-port';
import {
  OPENS_NO_TEAMS_BILLING_MESSAGE_COMMAND_PORT,
  OpensNoTeamsBillingMessageCommandPort,
} from '../ports/primary/opens-no-teams-billing-message-command.port';
import { MatDialog } from '@angular/material/dialog';
import { HuiSimpleModalWithEmojiComponent } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/hui-simple-modal-with-emoji.component';
import { Emojis } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/emojis.enum';
import { EmojiBackgroundTypes } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji/emoji-background-types.enum';
import { SAVES_SELECTED_COUNTRY_COMMAND } from '../ports/primary/saves-selected-country.command-port';

@NgModule({
  providers: [
    TeamSettingsState,
    { provide: GETS_TEAM_SETTINGS_QUERY_PORT, useExisting: TeamSettingsState },
    { provide: SAVES_TEAM_SETTINGS_COMMAND, useExisting: TeamSettingsState },
    { provide: LOADS_TEAMS_SETTINGS_COMMAND_PORT, useExisting: TeamSettingsState },
    {
      provide: GETS_SAVE_TEAM_COMMAND_ERROR_QUERY_PORT,
      useExisting: TeamSettingsState,
    },
    {
      provide: TEAM_UPDATED_DISPATCHER,
      useClass: ApplicationBusTeamUpdatedDispatcher,
    },
    {
      provide: CLEARS_TEAMS_SETTINGS_COMMAND_PORT,
      useExisting: TeamSettingsState,
    },
    {
      provide: GETS_COUNTRIES_QUERY,
      useExisting: TeamSettingsState,
    },
    {
      provide: GETS_VAT_NUMBER_VALIDATION_QUERY,
      useExisting: TeamSettingsState,
    },
    {
      provide: OPENS_NO_TEAMS_BILLING_MESSAGE_COMMAND_PORT,
      useFactory: dialog =>
        ({
          open(): void {
            dialog.open(HuiSimpleModalWithEmojiComponent, {
              data: {
                emoji: Emojis.CARD,
                emojiBackground: EmojiBackgroundTypes.TERTIARY,
                title: 'cbr_pro_agency_settings_update_billing',
                text: 'cbr_pro_agency_settings_add_billing',
                buttonText: '_ok',
              },
            });
          },
        } as OpensNoTeamsBillingMessageCommandPort),
      deps: [MatDialog],
    },
    {
      provide: SAVES_SELECTED_COUNTRY_COMMAND,
      useExisting: TeamSettingsState,
    },
  ],
})
export class TeamSettingsStateModule {}

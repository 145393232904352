import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const CREATE_NEW_SUBSCRIPTION_PRE_HOOK = new InjectionToken<CreateNewSubscriptionPreHook>(
  'CREATE_NEW_SUBSCRIPTION_PRE_HOOK',
);

export interface CreateNewSubscriptionPreHook {
  execute(preHookParams: { [key: string]: unknown }): Observable<boolean>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProClientsListControlsComponent } from './clients-list-controls.component';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CobiroProClientsListControlsComponent],
  imports: [CommonModule, MatIconModule, LanguageModule, MatMenuModule, ReactiveFormsModule],
  exports: [CobiroProClientsListControlsComponent],
})
export class CobiroProClientsListControlsComponentModule {}

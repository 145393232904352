import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { WorkspacesSwitcherWidgetComponentModule } from '../workspaces-switcher-widget/workspaces-switcher-widget.component-module';
import { WorkspacesSwitcherComponent } from './workspace-switcher.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    HuiAvatarModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    WorkspacesSwitcherWidgetComponentModule,
  ],
  declarations: [WorkspacesSwitcherComponent],
  exports: [WorkspacesSwitcherComponent],
})
export class WorkspacesSwitcherComponentModule {}

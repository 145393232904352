import { LabelManagerConfigDto } from '../secondary/label-manager-config.dto';

export const CUSTOM_LABEL_LIST = [
  {
    value: 'custom_label_0',
    label: 0,
  },
  {
    value: 'custom_label_1',
    label: 1,
  },
  {
    value: 'custom_label_2',
    label: 2,
  },
  {
    value: 'custom_label_3',
    label: 3,
  },
  {
    value: 'custom_label_4',
    label: 4,
  },
];

export class CustomLabelQuery {
  private constructor(
    public readonly value:
      | 'custom_label_0'
      | 'custom_label_1'
      | 'custom_label_2'
      | 'custom_label_3'
      | 'custom_label_4',
    public readonly label: number,
  ) {}

  static fromDTO(
    dto:
      | 'custom_label_0'
      | 'custom_label_1'
      | 'custom_label_2'
      | 'custom_label_3'
      | 'custom_label_4',
  ): CustomLabelQuery {
    return (
      (CUSTOM_LABEL_LIST.find(el => el.value === dto) as CustomLabelQuery) ||
      (CUSTOM_LABEL_LIST.at(0) as CustomLabelQuery)
    );
  }
}

export class LabelManagerConfigQuery {
  constructor(
    public readonly id: string,
    public readonly breakEvenROAS: number,
    public readonly lookBackWindow: number,
    public readonly customLabel: CustomLabelQuery,
    public readonly analysisFrequency: number,
  ) {}

  static fromDto(dto: LabelManagerConfigDto): LabelManagerConfigQuery {
    return new LabelManagerConfigQuery(
      dto.id,
      dto.breakEvenROAS,
      dto.lookBackWindow,
      CustomLabelQuery.fromDTO(dto.customLabel),
      dto.analysisFrequency,
    );
  }
}

import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InjectionToken, Type } from '@angular/core';

export interface GetsFeatureUsage {
  readonly featureId: string;
  get(route: ActivatedRouteSnapshot): Observable<number>;
}

export const GETS_FEATURE_USAGE = new InjectionToken<GetsFeatureUsage>('GETS_FEATURE_USAGE');

export const provideFeatureUsageGetter = (implementation: Type<GetsFeatureUsage>) => {
  return {
    provide: GETS_FEATURE_USAGE,
    useClass: implementation,
    multi: true,
  };
};

import { Inject, Injectable } from '@angular/core';
import { GetsUserFromToken } from '../domain/gets-user-from-token';
import { STORAGE } from '@app.cobiro.com/core/storage';
import jwtDecode from 'jwt-decode';
import { UserIdentity } from '../domain/user-identity';
import { GetsUserDetails, UserDetailsDTO } from '../domain/gets-user-details';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { map, Observable, of } from 'rxjs';
import { HasData } from '@cobiro/jsonapi';

export interface AuthStorage {
  access_token: string;
  refresh_token: string;
  refreshing: boolean;
  token_type: 'Bearer';
}

export class JwtData {
  aud: string;
  sub: string;
  // tslint:disable-next-line:variable-name
  partner_id: string;
  iat: number;
  nbf: number;
  exp: number;
  scopes: string;
  jti: string;
  email: string;
}
export interface UserDetailsAttributes {
  email: string;
  partnerId: number;
  firstName: string;
  lastName: string;
  country: string;
  group: string;
  createdAt: number;
  avatarUrl: string;
}
@Injectable()
export class StorageGetsUserFromToken implements GetsUserFromToken, GetsUserDetails {
  private readonly _authKey = 'cobiro-auth';

  constructor(
    @Inject(STORAGE) private _storage: Storage,
    private readonly _client: GatewayClient,
  ) {}

  getDetails(): Observable<UserDetailsDTO> {
    const dto = this.get();
    return dto
      ? this._client.get<HasData<UserDetailsAttributes>>(`v2/users/${dto.userId}/details`).pipe(
          map(response => ({
            firstName: response.data.attributes.firstName,
            lastName: response.data.attributes.lastName,
            country: response.data.attributes.country,
            email: response.data.attributes.email,
          })),
        )
      : of(null);
  }

  get(): UserIdentity | null {
    if (!this._storage.getItem(this._authKey)) {
      return null;
    }

    const authStorage: AuthStorage = JSON.parse(this._storage.getItem(this._authKey));

    if (!authStorage || !authStorage.access_token) {
      return null;
    }

    const data = jwtDecode<JwtData>(authStorage.access_token);

    return {
      email: data.email,
      userId: data.sub,
      partnerId: data.partner_id,
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { DigitsInputModule } from '@app.cobiro.com/shared/hui/digits-input/digits-input.module';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { VerifyCodeFormComponentModule } from '@app.cobiro.com/user/core';
import { SocialSignUpModule } from '@app.cobiro.com/user/social-sign-up';
import { VerifyCodeComponent } from './verify-code.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    SocialSignUpModule,
    VerifyCodeFormComponentModule,
    FormsModule,
    HuiInputModule,
    ReactiveFormsModule,
    DigitsInputModule,
    HuiLoadingModule,
  ],
  declarations: [VerifyCodeComponent],
  exports: [VerifyCodeComponent],
})
export class VerifyCodeComponentModule {}

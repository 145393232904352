<form class="cs-form" [formGroup]="form" (ngSubmit)="onFormSubmit()" data-selector="form">
  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label">{{
      'google_css_integration_google_ads_id_input_label' | language
    }}</label>
    <input
      type="text"
      class="cs-input"
      data-selector="form-ads-id-input"
      formControlName="adsId"
      [placeholder]="'google_css_integration_google_ads_id_input_placeholder' | language"
      huiInputError
    />
  </div>

  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label">{{ '_email' | language }}</label>
    <input
      type="text"
      formControlName="managerEmail"
      data-selector="form-manager-email-input"
      class="cs-input"
      [placeholder]="'_email' | language"
      huiInputError="managerEmail"
    />
    <p
      *ngIf="
        form.controls.managerEmail.invalid &&
        form.controls.managerEmail.touched &&
        form.controls.managerEmail.hasError('invalidField')
      "
      class="cs-control-error"
    >
      {{ '_invalid_email' | language }}
    </p>
  </div>
  <ng-container *ngIf="cssMerchantIds$ | async as cssMerchantIds">
    <div *ngIf="cssMerchantIds.length > 1" class="cs-control-wrapper cs-mb-20">
      <label class="cs-label" for="cssDomainId">
        {{ 'google_css_integration_css_domain_label' | language }}
      </label>
      <mat-form-field class="cs-dropdown cs-text-1 cs-c-shade-100">
        <mat-select
          disableRipple="true"
          formControlName="cssDomainId"
          id="cssDomainId"
          panelClass="cs-dropdown-options"
        >
          <mat-option
            *ngFor="let cssMerchantId of cssMerchantIds"
            [value]="cssMerchantId.cssDomainId"
          >
            {{ cssMerchantId.cssDomainName | language }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p
        *ngIf="form.controls.cssDomainId.invalid && form.controls.cssDomainId.touched"
        class="cs-control-error cs-p-0"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>
  </ng-container>
  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label">{{ 'google_css_integration_business_input_label' | language }} </label>
    <input
      type="text"
      class="cs-input"
      formControlName="websiteName"
      data-selector="form-website-name-input"
      huiInputError="websiteName"
    />
  </div>

  <div class="d-flex justify-content-end cs-mt-40">
    <button
      class="cs-btn cs-btn-primary cs-width-160-xs"
      data-selector="integration-form-submit"
      type="submit"
    >
      {{ '_continue' | language }}
    </button>
  </div>
</form>

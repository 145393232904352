import { HasData } from '@cobiro/jsonapi';
import { camelCase } from 'lodash';
import { DiscountCodeCheckAttributes } from '../../../../../adapters/secondary/infrastucture/http-checks-discount-code/http-checks-discount-code.service';

export class DiscountDto {
  constructor(
    public readonly code: string,
    public readonly type: DISCOUNT_TYPES,
    public readonly value: number,
    public readonly duration: DiscountDuration,
  ) {}

  static fromResponse(response: HasData<DiscountCodeCheckAttributes>): DiscountDto {
    return new DiscountDto(
      response.data.id,
      DISCOUNT_TYPES[response.data.attributes.type],
      response.data.attributes.value,
      {
        type: DISCOUNT_DURATION_TYPES[camelCase(response.data.attributes.duration.type)],
        value: response.data.attributes.duration.period,
        unit: DISCOUNT_DURATION_PERIOD_UNITS[response.data.attributes.duration.unit],
      },
    );
  }
}

export interface DiscountDuration {
  type: DISCOUNT_DURATION_TYPES;
  value?: number;
  unit?: DISCOUNT_DURATION_PERIOD_UNITS;
}

export enum DISCOUNT_TYPES {
  percentage = 'percentage',
  flat = 'flat',
}

export enum DISCOUNT_DURATION_TYPES {
  period = 'period',
  forever = 'forever',
  oneTime = 'oneTime',
}

export enum DISCOUNT_DURATION_PERIOD_UNITS {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

import { ApplicationEvent } from '@cobiro/eda';

interface CobiroProTeamsLoadedEventDto {
  id: string;
  name: string;
  avatar: string | null;
  role: string;
  membersCount?: number;
}
export class CobiroProTeamsLoadedEvent extends ApplicationEvent {
  constructor(readonly teams: CobiroProTeamsLoadedEventDto[]) {
    super();
  }
}

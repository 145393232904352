import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface Details {
  firstName: string;
  lastName: string;
  email: string;
  weeklySummary: boolean;
  avatarUrl: string;
}

export interface UpdatesDetails {
  update(data: Partial<Details>): Observable<Details>;
}

export const UPDATES_DETAILS = new InjectionToken<UpdatesDetails>('UPDATES_DETAILS');

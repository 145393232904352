import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspaceDto } from '../secondary/dto/workspace.dto';

export interface LoadsWorkspacesQueryPort {
  loadWorkspaces(teamId: string): Observable<WorkspaceDto[]>;
}

export const LOADS_WORKSPACES_QUERY = new InjectionToken<LoadsWorkspacesQueryPort>(
  'LOADS_WORKSPACES_QUERY',
);

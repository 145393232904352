import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientDTO } from './client.dto';

export const SETS_CLIENT_DTO = new InjectionToken<SetsClientDtoPort>('SETS_CLIENT_DTO');

export interface SetsClientDtoPort {
  set(
    item: Pick<
      ClientDTO,
      'companyName' | 'contactPerson' | 'contactEmail' | 'contactPhone' | 'workspaceId' | 'id'
    >,
  ): Observable<void>;
}

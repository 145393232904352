import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnableProModeResolver } from './adapters/primary/resolvers/enable-pro-mode.resolver';
import { ApplicationBusContextChangedDispatcherModule } from './adapters/secondary/dispatchers/application-bus-context-changed.dispatcher-module';
import { ApplicationBusCobiroProLogoutDispatcherModule } from './adapters/secondary/dispatchers/application-bus-cobiro-pro-logout.dispatcher-module';
import { LocalstorageContextServiceModule } from './adapters/secondary/infrastructure/localstorage-context.service-module';
import { ContextStateModule } from './application/state/context.state-module';
import { FetchCobiroProContextResolverModule } from './adapters/primary/resolvers/fetch-cobiro-pro-context.resolver-module';
import { IsProModeGuard } from './adapters/primary/guards/is-pro-mode.guard';
import { LocalstorageLatestCobiroProLoginServiceModule } from './adapters/secondary/infrastructure/localstorage-latest-cobiro-pro-login.service-module';
import { provideApplicationEventsHandler } from '@cobiro/eda';
import { CobiroProContextUserSignedOutCompletedEventHandler } from './adapters/event-handlers/cobiro-pro-context-user-signed-out-completed.event-handler';
import { VerifyLatestProLoginResolver } from './adapters/primary/resolvers/verify-latest-pro-login.resolver';
import { CobiroProModeDisabledEventHandler } from './adapters/event-handlers/cobiro-pro-mode-disabled.event-handler';
import { TeamIdGetterModule } from './adapters/secondary/team-id-getter/team-id-getter.module';
import { WorkspaceIdGetterModule } from './adapters/secondary/workspace-id-getter/workspace-id-getter.module';
@NgModule({
  imports: [
    CommonModule,
    TeamIdGetterModule,
    WorkspaceIdGetterModule,
    ApplicationBusContextChangedDispatcherModule,
    ApplicationBusCobiroProLogoutDispatcherModule,
    LocalstorageContextServiceModule,
    ContextStateModule,
    FetchCobiroProContextResolverModule,
    LocalstorageLatestCobiroProLoginServiceModule,
  ],
  providers: [
    EnableProModeResolver,
    IsProModeGuard,
    VerifyLatestProLoginResolver,
    provideApplicationEventsHandler(CobiroProContextUserSignedOutCompletedEventHandler),
    provideApplicationEventsHandler(CobiroProModeDisabledEventHandler),
  ],
})
export class CobiroProContextModule {}

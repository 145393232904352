/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsAllWorkspacesDtoPort } from '../../../../application/ports/secondary/gets-all-workspaces.dto-port';
import { HasSubscriptionsDtoPort } from '../../../../application/ports/secondary/has-subscriptions.dto-port';
import { WorkspaceDto } from '../../../../application/ports/secondary/workspace.dto';

interface WorkspaceAttributes {
  name: string;
  counter: number;
  avatar: string;
  clientCounter: number;
  createdAt: string;
}

interface ClientProductAttributes {
  productType: 'css' | 'label-manager';
  planType: 'monthly' | 'annual' | 'business';
  subscriptionId: string | null;
}

interface ClientAttributes {
  products: ClientProductAttributes[];
}

@Injectable()
export class HttpWorkspacesService implements GetsAllWorkspacesDtoPort, HasSubscriptionsDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  hasSubscriptions(workspaceId: string): Observable<boolean> {
    return this._client
      .get<HasDataCollection<ClientAttributes>>(
        `v1/sites/workspaces/${workspaceId}/clients/products`,
      )
      .pipe(
        map((products: HasDataCollection<ClientAttributes>) =>
          products.data.some(data => data.attributes.products.length > 0),
        ),
      );
  }

  getsAll(teamId: string): Observable<WorkspaceDto[]> {
    return this._client
      .get<HasDataCollection<WorkspaceAttributes>>(`v1/sites/teams/${teamId}/workspaces`)
      .pipe(
        map(dataCollection => {
          const userWorkspacesIds: string[] = [];
          const userWorkspaces = dataCollection.data.map(data => {
            userWorkspacesIds.push(data.id);

            return {
              id: data.id,
              name: data.attributes.name,
              membersCount: data.attributes.counter,
              clientCount: data.attributes.clientCounter,
              avatar: data.attributes.avatar,
              createdAt: data.attributes.createdAt,
            };
          });

          return [...new Set(userWorkspacesIds)]
            .map(userWorkspaceId => userWorkspaces.find(team => team.id === userWorkspaceId))
            .filter(Boolean);
        }),
      );
  }
}

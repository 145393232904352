import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateClientCommand } from './create-client.command';

export const CREATE_CLIENT_COMMAND = new InjectionToken<CreateClientCommandPort>(
  'CREATE_CLIENT_COMMAND',
);

export interface CreateClientCommandPort {
  createClient(command: CreateClientCommand): Observable<void>;
}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export const WRONG_MERCHANT_ID_ERROR_KEY = 'wrongMerchantId';

export const merchantIdValidator: ValidatorFn = (control: AbstractControl) => {
  const value = control.value;

  if (!value) {
    return null;
  }

  return value.match(/^[0-9]{7,10}$/) ? null : { wrongMerchantId: true };
};

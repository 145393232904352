import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadImageDto } from '../../../ports/secondary/upload-image.dto';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { UploadsImageDtoPort } from '../../../ports/secondary/uploads-image.dto-port';
import { Image, ImageUploadResponse } from '../../../../../../media-center-image';

@Injectable()
export class HttpUploadsImageService implements UploadsImageDtoPort {
  constructor(
    private readonly _gatewayClient: GatewayClient,
    private readonly _httpClient: HttpClient,
  ) {}

  uploadImage(uploadImageDTO: UploadImageDto): Observable<Image> {
    return this._gatewayClient
      .post('v1/media-center/images', {
        data: {
          type: 'images',
          attributes: {
            mime_type: uploadImageDTO.mimeType,
            content: uploadImageDTO.content,
          },
        },
      })
      .pipe(
        map((response: ImageUploadResponse) => new Image(null, response.data.links, null, null)),
      );
  }
}

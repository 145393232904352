<section
  *ngIf="integration$ | async as query"
  class="cs-container d-flex flex-column flex-sm-row align-items-start"
>
  <div class="cs-card d-flex flex-column cs-min-height-440-xs">
    <div class="d-flex justify-content-between align-items-center w-100">
      <h4 class="cs-title-4">{{ 'cobiro_css_status' | language }}</h4>

      <span class="cs-badge status-badge cs-pv-9 cs-text-2 br-5 d-inline-block cs-badge-success">{{
        'cobiro_pro_css_integration_status_active' | language
      }}</span>
    </div>
    <p
      *ngIf="query.integrationType === 'owned'"
      class="cs-text-1 cs-mt-5"
      data-selector="css-integration-owned-subtitle"
    >
      {{ 'google_css_integration_success_owned_subtitle' | language }}
    </p>
    <p
      *ngIf="query.integrationType === 'switched'"
      class="cs-text-1 cs-mt-5"
      data-selector="css-integration-switched-subtitle"
    >
      {{ 'google_css_integration_success_switched_subtitle' | language }}
    </p>

    <div
      *ngIf="query.integrationType === 'owned' || query.integrationType === 'switched'"
      data-selector="css-integration-switch"
    >
      <ng-container *ngIf="cssMerchantIds$ | async as cssMerchantIds">
        <div *ngIf="cssMerchantIds.length > 1" class="d-flex align-items-center w-100 cs-mt-20">
          <div class="cs-control-wrapper cs-mb-0 flex-1">
            <label class="cs-label" for="cssDomainId">
              {{ 'google_css_integration_current_css_domain' | language }}
            </label>
            <mat-form-field class="cs-dropdown cs-text-1 cs-c-shade-100">
              <mat-select
                [formControl]="cssDomain"
                disableRipple="true"
                id="cssDomainId"
                panelClass="cs-dropdown-options"
              >
                <mat-option
                  *ngFor="let cssMerchantId of cssMerchantIds"
                  [value]="cssMerchantId.cssDomainId"
                >
                  {{ cssMerchantId.cssDomainName | language }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button
            [disabled]="
              query.cssDomainId === cssDomain.value ||
              (cssDomain.invalid && (isProcessing$ | async))
            "
            [class.is-loading]="isProcessing$ | async"
            class="cs-btn cs-btn-primary cs-width-160-xs cs-ml-20"
            data-selector="switch-css-domain"
            type="button"
            (click)="onSwitchCssDomainClicked()"
          >
            <mat-icon class="cs-pr-5" svgIcon="switch-horizontally"></mat-icon>
            {{ '_switch_css' | language }}
          </button>
        </div>
      </ng-container>
    </div>

    <div *ngIf="query.integrationType === 'owned'" data-selector="css-integration-product-approval">
      <p class="cs-title-7 font-weight-bold cs-mt-20">
        {{ 'google_css_integration_success_description_title' | language }}
      </p>
      <p class="cs-text-2 cs-mt-5">
        {{ 'google_css_integration_success_description_content' | language }}
      </p>
    </div>

    <div
      *ngIf="query.integrationType === 'switched'"
      class="cs-mt-20"
      data-selector="css-integration-initiate-linking"
    >
      <mat-accordion class="w-100 cs-mat-accordion">
        <mat-expansion-panel class="cs-mb-0">
          <mat-expansion-panel-header class="d-flex align-items-center br-0 expansion-panel-header"
            ><span class="d-block"
              ><h5 class="cs-title-7">
                {{ 'cobiro_css_link_integration_accordion_title' | language }}
              </h5>
              <p class="cs-text-1 cs-mt-10">
                {{ 'cobiro_css_link_integration_accordion_subtitle' | language }}
              </p></span
            ></mat-expansion-panel-header
          >

          <lib-link-integration></lib-link-integration>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div
      *ngIf="query.integrationType === 'non-css'"
      data-selector="css-integration-non-css-subtitle"
    >
      <p class="cs-text-2 cs-mt-20">
        {{ 'google_non_css_integration_success_description_title' | language }}
      </p>
      <p class="cs-text-2 cs-mt-20">
        {{ 'google_non_css_integration_success_owned_subtitle' | language }}
      </p>
    </div>

    <div *ngIf="query.integrationType === 'non-css'" class="d-flex justify-content-center">
      <button
        class="cs-btn cs-btn-primary w-100 cs-mv-20"
        data-selector="css-cancel-integration-btn"
        (click)="onUpgradeIntegrationClicked(query.integrationId)"
      >
        {{ 'cobiro_css_upgrade_integration_btn' | language }}
      </button>
    </div>
    <p class="cs-text-3 cs-mt-10 cs-mb-10" data-selector="integration-id-info">
      Integration ID: {{ query.integrationId }}
    </p>

    <div class="d-flex justify-content-end mt-auto">
      <button
        class="cs-btn cs-btn-error"
        data-selector="css-cancel-integration-btn"
        (click)="onCancelIntegrationClicked()"
      >
        {{ 'cobiro_css_cancel_integration_btn' | language }}
      </button>
    </div>
  </div>

  <aside
    class="cs-card cs-max-width-300-sm cs-p-20 cs-mt-20 cs-mt-0-sm cs-ml-20-sm"
    data-selector="support-sidebar"
  >
    <img src="assets/google-css-help-image.png" class="cs-max-width-250-xs" />
    <h4 class="cs-title-5 cs-mt-10">
      {{ 'google_css_integration_help_title_3' | language }}
    </h4>
    <p
      class="cs-text-2 cs-mt-10"
      [innerHTML]="'google_css_integration_help_description_3' | language"
    ></p>
  </aside>
</section>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AppliesDiscountCodeCommand } from './applies-discount-code.command';

export const APPLIES_DISCOUNT_CODE_COMMAND = new InjectionToken<AppliesDiscountCodeCommandPort>(
  'APPLIES_DISCOUNT_CODE_COMMAND',
);

export interface AppliesDiscountCodeCommandPort {
  applyDiscountCode(command: AppliesDiscountCodeCommand): Observable<boolean | string>;
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  GOES_TO_NEXT_STEP_COMMAND,
  GoesToNextStepCommandPort,
} from '../../../../../../../application/ports/primary/goes-to-next-step.command-port';
import {
  CREATES_NON_CSS_INTEGRATION_QUERY,
  CreatesNonCssIntegrationQueryPort,
} from '../../../../../../../application/ports/primary/creates-non-css-integration.query-port';
import { Subject, firstValueFrom, map, takeUntil, tap } from 'rxjs';
import {
  IS_DUMMY_INTEGRATION_CREATED_QUERY_PORT,
  IsDummyIntegrationCreatedQueryPort,
} from '../../../../../../../application/ports/primary/is-dummy-integration-created.query-port';
import { FormControl } from '@angular/forms';
import {
  CHANGES_NO_CACHE_FLAG_COMMAND_PORT,
  ChangesNoCacheFlagCommandPort,
} from '../../../../../../../application/ports/primary/change-no-cache-flag.command-port';

@Component({
  selector: 'lib-cobiro-pro-introduction-step',
  templateUrl: './introduction-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntroductionStepComponent implements OnInit, OnDestroy {
  isNoCache = new FormControl();
  private _ngDestroy$ = new Subject<void>();
  readonly isDummyIntegrationCreated$ = this._isDummyIntegrationCreatedQueryPort
    .isCreated()
    .pipe(map((isCreated: boolean) => ({ isCreated: isCreated })));

  constructor(
    @Inject(GOES_TO_NEXT_STEP_COMMAND)
    private readonly _goesToNextStepCommandPort: GoesToNextStepCommandPort,
    @Inject(CHANGES_NO_CACHE_FLAG_COMMAND_PORT)
    private readonly _changesNoCacheFlagCommandPort: ChangesNoCacheFlagCommandPort,
    @Inject(CREATES_NON_CSS_INTEGRATION_QUERY)
    private readonly _createsNonCssIntegrationQueryPort: CreatesNonCssIntegrationQueryPort,
    @Inject(IS_DUMMY_INTEGRATION_CREATED_QUERY_PORT)
    private readonly _isDummyIntegrationCreatedQueryPort: IsDummyIntegrationCreatedQueryPort,
  ) {}

  ngOnInit(): void {
    this.isNoCache.valueChanges
      .pipe(
        takeUntil(this._ngDestroy$),
        tap((res: boolean) => this._changesNoCacheFlagCommandPort.changeNoCacheFlag(!res)),
      )
      .subscribe();

    this.isNoCache.setValue(false);
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }

  async createIntegration(): Promise<void> {
    await firstValueFrom(
      this._createsNonCssIntegrationQueryPort.createIntegration({
        adsId: '1000000001',
        managerEmail: 'non-css-linking@gmail.com',
        merchantId: '100000001',
      }),
    );
  }

  goToNextStep() {
    this._goesToNextStepCommandPort.goToNextStep();
  }
}

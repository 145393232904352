import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PatchesWorkspacesContextStoragePort } from '../../../application/ports/secondary/context/patches-workspaces-context.storage-port';
import { SelectsWorkspacesContextStoragePort } from '../../../application/ports/secondary/context/selects-workspaces-context.storage-port';
import { WorkspacesContext } from '../../../application/ports/secondary/context/workspaces-context';

@Injectable()
export class InMemoryWorkspacesContextStorage
  implements SelectsWorkspacesContextStoragePort, PatchesWorkspacesContextStoragePort
{
  private _subject$: BehaviorSubject<Partial<WorkspacesContext>> = new BehaviorSubject<
    Partial<WorkspacesContext>
  >({ list: [], currentWorkspace: null });

  select(): Observable<Partial<WorkspacesContext>> {
    return this._subject$
      .asObservable()
      .pipe(filter(context => !!context && !!Object.keys(context).length));
  }

  patch(state: Partial<WorkspacesContext>): Observable<void> {
    return of(this._subject$.next({ ...this._subject$.value, ...state }));
  }
}

import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { CONFIRMS_PAYMENT_METHOD_DTO_PORT } from '../../../../application/ports/secondary/confirms-payment-method.dto-port';
import { HttpChargebeeConfirmPaymentService } from './http-chargebee-confirm-payment.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpChargebeeConfirmPaymentService,
    {
      provide: CONFIRMS_PAYMENT_METHOD_DTO_PORT,
      useExisting: HttpChargebeeConfirmPaymentService,
    },
  ],
})
export class HttpChargebeeConfirmPaymentServiceModule {}

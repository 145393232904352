import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

export class UIState<T> {
  private value$: BehaviorSubject<T> | ReplaySubject<T>;

  constructor(private value?: T) {
    this.initStorage(value);
  }

  set(newValue: T): void {
    this.value = newValue;
    this.value$.next(this.value);
  }

  get(): T {
    return this.value;
  }

  select(): Observable<T> {
    return this.value$.asObservable();
  }

  private initStorage(initialValue: T): void {
    const initialValueDefined = initialValue !== undefined;
    this.value$ = initialValueDefined ? new BehaviorSubject(initialValue) : new ReplaySubject(1);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroCssDashboardComponent } from './cobiro-css-dashboard.component';
import { RouterModule } from '@angular/router';
import { CobiroCssStartPageComponentModule } from '../cobiro-css-start-page/cobiro-css-start-page.component.module';
import { CobiroCssIntegrationComponentModule } from '../cobiro-css-integration/cobiro-css-integration.component-module';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [CobiroCssDashboardComponent],
  imports: [
    CommonModule,
    HuiLoaderContainerModule,
    CobiroCssStartPageComponentModule,
    CobiroCssIntegrationComponentModule,
    RouterModule.forChild([{ path: '', component: CobiroCssDashboardComponent }]),
  ],
  exports: [CobiroCssDashboardComponent],
})
export class CobiroCssDashboardComponentModule {}

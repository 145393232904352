import { CobiroProLogoutDispatcherPort } from '../../../application/ports/secondary/cobiro-pro-logout.dispatcher-port';
import { Inject, Injectable } from '@angular/core';
import { CobiroProLogoutEvent } from '@app.cobiro.com/core/events';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';

@Injectable()
export class ApplicationBusCobiroProLogoutDispatcher implements CobiroProLogoutDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus) {}
  dispatch() {
    this._applicationBus.dispatch(new CobiroProLogoutEvent());
  }
}

/* eslint-disable max-lines-per-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EditsIntegrationCommand } from '../../../../application/ports/primary/edits-integration.command';
import {
  EDITS_INTEGRATION_COMMAND,
  EditsIntegrationCommandPort,
} from '../../../../application/ports/primary/edits-integration.command-port';
import {
  GetsCssMerchantIdsQueryPort,
  GETS_CSS_MERCHANT_IDS_QUERY_PORT,
} from '../../../../application/ports/primary/gets-css-merchant-ids.query-port';
import {
  GetsFormErrorQueryPort,
  GETS_FORM_ERROR_QUERY_PORT,
} from '../../../../application/ports/primary/gets-form-error.query-port';
import {
  GETS_FORM_STATUS_QUERY,
  GetsFormStatusQueryPort,
} from '../../../../application/ports/primary/gets-form-status.query-port';
import {
  GETS_INTEGRATION_QUERY,
  GetsIntegrationQueryPort,
} from '../../../../application/ports/primary/gets-integration.query-port';
import { SwitchesIntegrationCommand } from '../../../../application/ports/primary/switches-integration.command';
import {
  SWITCHES_INTEGRATION_COMMAND,
  SwitchesIntegrationCommandPort,
} from '../../../../application/ports/primary/switches-integration.command-port';
import {
  googleAdsIdValidator,
  WRONG_ADS_ID_ERROR_KEY,
} from '../google-ads-id-validator/google-ads-id.validator';
import {
  merchantIdValidator,
  WRONG_MERCHANT_ID_ERROR_KEY,
} from '../merchant-id-validator/merchant-id.validator';

@Component({
  selector: 'lib-switch-existing-integration-account-form',
  templateUrl: './switch-existing-integration-account-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchExistingIntegrationAccountFormComponent implements OnInit, OnDestroy {
  private readonly _destroyed$ = new Subject<void>();
  readonly cssMerchantIds$ = this._getsCssMerchantIdsQueryPort
    .getCssMerchantIds()
    .pipe(
      tap(cssMerchantIds =>
        this.form.patchValue({ cssDomainId: cssMerchantIds.at(0).cssDomainId }),
      ),
    );
  readonly form: UntypedFormGroup = this._formBuilder.group({
    websiteName: ['', Validators.required],
    adsId: ['', [Validators.required, googleAdsIdValidator]],
    managerEmail: ['', [Validators.required, Validators.email]],
    merchantId: ['', [Validators.required, merchantIdValidator]],
    cssDomainId: ['', Validators.required],
  });

  readonly wrongAdsIdErrorKey = WRONG_ADS_ID_ERROR_KEY;
  readonly wrongMerchantIdErrorKey = WRONG_MERCHANT_ID_ERROR_KEY;

  constructor(
    @Inject(EDITS_INTEGRATION_COMMAND)
    private readonly _editsIntegrationCommand: EditsIntegrationCommandPort,
    @Inject(GETS_FORM_STATUS_QUERY)
    private readonly _getsFormStatusQuery: GetsFormStatusQueryPort,
    @Inject(GETS_INTEGRATION_QUERY)
    private readonly _getsIntegrationQuery: GetsIntegrationQueryPort,
    @Inject(SWITCHES_INTEGRATION_COMMAND)
    private readonly _switchesIntegrationCommand: SwitchesIntegrationCommandPort,
    @Inject(GETS_FORM_ERROR_QUERY_PORT)
    private readonly _getsFormErrorQueryPort: GetsFormErrorQueryPort,
    @Inject(GETS_CSS_MERCHANT_IDS_QUERY_PORT)
    private readonly _getsCssMerchantIdsQueryPort: GetsCssMerchantIdsQueryPort,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenToEditModeEntered();
    this.handleFormError();
  }

  private handleFormError(): void {
    this._getsFormErrorQueryPort
      .getFormErrorQuery()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((errorFieldNames: string[]) => {
        errorFieldNames.forEach(errorFieldName => {
          const control = this.form.get(errorFieldName);
          control.setErrors({ ...control.errors, invalidField: true });
        });
        this.form.updateValueAndValidity();
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  async onFormSubmit(): Promise<void> {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsDirty());
      this.form.markAllAsTouched();
      return;
    }

    const { websiteName, adsId, managerEmail, merchantId, cssDomainId } = this.form.value;

    await firstValueFrom(
      this._getsFormStatusQuery.getFormStatusQuery().pipe(
        take(1),
        switchMap(query =>
          query.isEditMode
            ? this._editsIntegrationCommand.editIntegration(
                new EditsIntegrationCommand(
                  websiteName,
                  adsId,
                  managerEmail,
                  cssDomainId,
                  merchantId,
                ),
              )
            : this._switchesIntegrationCommand.switchIntegration(
                new SwitchesIntegrationCommand(
                  websiteName,
                  adsId,
                  managerEmail,
                  merchantId,
                  cssDomainId,
                ),
              ),
        ),
      ),
    );
  }

  private listenToEditModeEntered(): void {
    combineLatest([
      this._getsFormStatusQuery.getFormStatusQuery().pipe(
        map(query => query.isEditMode),
        filter(Boolean),
      ),
      this._getsIntegrationQuery.getIntegrationQuery(),
    ])
      .pipe(takeUntil(this._destroyed$))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(([_, query]) => {
        if (query.integrationType === 'switched') {
          this.form.patchValue({
            websiteName: query.businessName,
            adsId: query.adsId,
            managerEmail: query.email,
            merchantId: query.merchantId,
            cssDomainId: query.cssDomainId,
          });
          return;
        }

        this.form.reset();
        this.form.markAsPristine();
      });
  }
}

import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { HuiDefaultLoaderComponent } from './hui-default-loader/hui-default-loader.component';

const selector = 'huiLoaderContainer';

interface HuiLoaderContainerContext<T> {
  $implicit: T;
  [selector]: T;
}

@Directive({
  selector: `[${selector}]`,
})
export class HuiLoaderContainerDirective<T> {
  private loaderRef: ComponentRef<HuiDefaultLoaderComponent>;
  private context: HuiLoaderContainerContext<T> = {
    $implicit: null,
    [selector]: null,
  };

  @Input(selector)
  set loaded(loaded: T) {
    this.context.$implicit = this.context[selector] = loaded;
    this.setContentVisibility(Boolean(loaded));
  }

  private _noPadding = false;
  @Input() set huiLoaderContainerNoPadding(noPadding: boolean) {
    this._noPadding = noPadding;
    if (this.loaderRef) {
      this.loaderRef.instance.setPaddingEnabled(!noPadding);
    }
  }

  private _customClasses: string[] = [];
  @Input() set huiLoaderContainerCustomClasses(classes: string[]) {
    if (this.loaderRef) {
      if (this._customClasses.length > 0) {
        this.loaderRef.instance.removeClasses(this._customClasses);
      }
      this.loaderRef.instance.addClasses(classes);
    }

    this._customClasses = classes;
  }

  constructor(
    private readonly templateRef: TemplateRef<never>,
    private readonly viewContainer: ViewContainerRef,
    private readonly resolver: ComponentFactoryResolver,
  ) {}

  private setContentVisibility(loaded: boolean) {
    this.viewContainer.clear();
    if (loaded) {
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    } else {
      const factory = this.resolver.resolveComponentFactory(HuiDefaultLoaderComponent);
      this.loaderRef = this.viewContainer.createComponent(factory);
      this.loaderRef.instance.setPaddingEnabled(!this._noPadding);
      this.loaderRef.instance.addClasses(this._customClasses);
    }
  }
}

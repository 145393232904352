import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleLocationsState } from './application/google-locations.state';
import { HttpLocationsApiService } from './infrastructure/http-locations-api.service';

@NgModule({
  imports: [CommonModule],
  providers: [HttpLocationsApiService, GoogleLocationsState],
})
export class GoogleLocationsModule {}

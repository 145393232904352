import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const CALL_PAYMENT_METHOD_COMMAND = new InjectionToken<CallPaymentMethodCommandPort>(
  'CALL_PAYMENT_METHOD_COMMAND',
);

export interface CallPaymentMethodCommandPort {
  dispatchEvent(): Observable<void>;
}

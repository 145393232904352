import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface ReplacePaymentMethodCommand {
  readonly cardOwner: string;
  readonly cvv: string;
  readonly number: string;
  readonly expiryYear: string;
  readonly expiryMonth: string;
}

export interface ReplacesPaymentMethodCommandPort {
  replacePaymentMethod(command: ReplacePaymentMethodCommand): Observable<boolean>;
}

export const REPLACES_PAYMENT_METHOD_COMMAND = new InjectionToken<ReplacePaymentMethodCommand>(
  'REPLACES_PAYMENT_METHOD_COMMAND',
);

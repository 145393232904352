import { Component } from '@angular/core';
import { Discount } from '../../../../core/domain/discount';
import { Observable } from 'rxjs';
import { DiscountCodeState } from '../../../../core/application/state/discount-code.state';
import { PlanEstimateQuery } from '../../../../core/query/plan-estimate.query';
import { SelectedPlanQuery } from './selected-plan.query';
import { filter, map } from 'rxjs/operators';
import { PaymentPlansState } from '../../../../core/application/state/payment-plans.state';
import { PlanEstimateState } from '../../../../core/application/state/plan-estimate.state';

@Component({
  selector: 'lib-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent {
  discount$: Observable<Discount> = this._discountCodeState.discount$;
  planEstimate$: Observable<PlanEstimateQuery> = this._planEstimateState.planEstimate$;

  selectedPlan$: Observable<SelectedPlanQuery> = this._paymentPlansState.selectedPlan$.pipe(
    filter(plan => !!plan),
    map(plan => ({ price: plan.price })),
  );
  private _discountUnitMap = new Map<string, string>([
    ['percentage', '%'],
    ['flat', '$'],
  ]);

  constructor(
    private _discountCodeState: DiscountCodeState,
    private _paymentPlansState: PaymentPlansState,
    private _planEstimateState: PlanEstimateState,
  ) {}

  getPrice(grossPrice: number, discount: Discount | null): number {
    if (!discount) {
      return grossPrice;
    }
    if (discount.type === 'percentage') {
      return (grossPrice * (100 - discount.value)) / 100;
    } else {
      return grossPrice - discount.value;
    }
  }

  getDiscountValue(discount: Discount): string {
    return `${discount.value}${this._discountUnitMap.get(discount.type)}`;
  }
}

import { Injectable } from '@angular/core';
import {
  SetsTeamSwitcherItemsCommandPort,
  TeamSwitcherItem,
} from '../ports/primary/sets-team-switcher-items-command.port';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  GetsTeamSwitcherItemsQuery,
  TeamSwitcherItemQuery,
} from '../ports/primary/gets-team-switcher-items.query';
import { map, take, tap } from 'rxjs/operators';
import { IncrementsTeamMembersCountCommandPort } from '../ports/primary/increments-team-members-count-command.port';

@Injectable()
export class TeamSwitcherState
  implements
    SetsTeamSwitcherItemsCommandPort,
    GetsTeamSwitcherItemsQuery,
    IncrementsTeamMembersCountCommandPort
{
  private readonly _teamSwitcherItems$ = new BehaviorSubject<TeamSwitcherItem[] | null>(null);
  private readonly _teamsMembersCountMap$ = new BehaviorSubject<Map<string, number>>(null);

  setTeamSwitcherItems(teams: TeamSwitcherItem[]): void {
    this._teamSwitcherItems$.next(teams);
    this._teamsMembersCountMap$.next(new Map(teams.map(team => [team.id, team.membersCount])));
  }

  getTeamSwitcherItemsQuery(): Observable<TeamSwitcherItemQuery[] | null> {
    return combineLatest([
      this._teamSwitcherItems$.asObservable(),
      this._teamsMembersCountMap$.asObservable(),
    ]).pipe(
      map(
        ([teams, teamsMembersCount]) =>
          teams && teamsMembersCount && this._adaptToQuery(teams, teamsMembersCount),
      ),
    );
  }

  incrementTeamMembersCount(teamId: string): void {
    this._teamsMembersCountMap$
      .pipe(
        take(1),
        tap((teamMembersCountMap: Map<string, number>) => {
          teamMembersCountMap.set(teamId, teamMembersCountMap.get(teamId) + 1);
          this._teamsMembersCountMap$.next(new Map(teamMembersCountMap.entries()));
        }),
      )
      .subscribe();
  }

  private _adaptToQuery(
    teams: TeamSwitcherItem[],
    teamsMembersCount: Map<string, number>,
  ): TeamSwitcherItemQuery[] {
    return teams.map(
      ({ id, name, avatar, role }) =>
        new TeamSwitcherItemQuery(id, name, avatar, role, teamsMembersCount.get(id)),
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedImageUploadComponent } from './adapters/primary/ui/shared-image-upload.component';
import { UPLOAD_IMAGE_DTO } from './ports/secondary/uploads-image.dto-port';
import { HttpUploadsImageService } from './adapters/secondary/infrastructure/http-upload-image.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HuiFilePickerModule } from '@app.cobiro.com/shared/hui/file-picker/hui-file-picker.module';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { MatIconModule } from '@angular/material/icon';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    HuiFilePickerModule,
    GatewayModule,
    MatIconModule,
    HuiLoadingModule,
  ],
  declarations: [SharedImageUploadComponent],
  exports: [SharedImageUploadComponent],
  providers: [{ provide: UPLOAD_IMAGE_DTO, useClass: HttpUploadsImageService }],
})
export class SharedImageUploadModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodFormComponent } from './payment-method-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiCardExpireDateModule } from '@app.cobiro.com/shared/hui/expire-date/hui-card-expire-date.module';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [PaymentMethodFormComponent],
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    HuiLoaderContainerModule,
    HuiCardExpireDateModule,
    NgxMaskModule.forChild(),
  ],
  exports: [PaymentMethodFormComponent],
})
export class PaymentMethodFormComponentModule {}

import { Observable } from 'rxjs';
import { PaginationQuery } from '@app.cobiro.com/core/pagination';
import { InjectionToken } from '@angular/core';

export interface GetsLatestUsersPaginationQueryPort {
  getUserListPagination(): Observable<PaginationQuery>;
}

export const GETS_LATEST_USERS_PAGINATION_QUERY =
  new InjectionToken<GetsLatestUsersPaginationQueryPort>('GETS_LATEST_USERS_PAGINATION_QUERY');

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BASE_URL, GatewayClient, HEADERS } from './gateway';

@NgModule({
  imports: [CommonModule],
  providers: [GatewayClient],
})
export class GatewayModule {
  static forRoot(
    baseUrl: string,
    headers?: { [name: string]: string | string[] },
  ): ModuleWithProviders<GatewayModule> {
    return {
      ngModule: GatewayModule,
      providers: [
        { provide: BASE_URL, useValue: baseUrl },
        { provide: HEADERS, useValue: headers },
      ],
    };
  }
}

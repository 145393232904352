import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspacesContext } from './workspaces-context';

export const SELECTS_WORKSPACES_CONTEXT_STORAGE =
  new InjectionToken<SelectsWorkspacesContextStoragePort>('SELECTS_WORKSPACES_CONTEXT_STORAGE');

export interface SelectsWorkspacesContextStoragePort {
  select(): Observable<Partial<WorkspacesContext>>;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CardsState } from './application/cards.state';
import { CardListComponent } from './ui/card-list/card-list.component';

@NgModule({
  declarations: [CardListComponent],
  imports: [CommonModule, LanguageModule],
  providers: [CardsState],
  exports: [CardListComponent],
})
export class CardsModule {}

import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { LabelManagerSimulationRequestEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerLabelManagerSimulationRequestEventMapper implements DataLayerTrackingMapper {
  eventClass = LabelManagerSimulationRequestEvent;

  mapEvent(event: LabelManagerSimulationRequestEvent): DataLayerTrackingData {
    return {
      eventName: 'label-manager-simulation-request',
      eventData: {
        integration_id: event.integrationId,
        shop_id: event.shopId,
      },
    };
  }
}

<div
  class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center cs-max-width-500-xs"
>
  <div class="cs-emoji-circle cs-emoji-circle-secondary">❌</div>
  <h4 class="cs-title-4 cs-mt-20 cs-mb-10 text-center">
    {{ 'cobiro_pro_users_remove_user' | language }}
  </h4>
  <p class="cs-text-1 text-center cs-mt-10 cs-mb-40">
    {{
      'cobiro_pro_users_remove_team_user_description'
        | language : [data.userName ? data.userName : 'this user']
    }}
  </p>
  <div class="d-flex justify-content-center">
    <button
      class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-stroked"
      data-selector="confirmation-popup-no-btn"
      [matDialogClose]="false"
    >
      {{ '_cancel' | language }}
    </button>
    <button class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-error" [matDialogClose]="true">
      {{ '_remove' | language }}
      <mat-icon svgIcon="trash"></mat-icon>
    </button>
  </div>
</div>

import { CardInfo } from '../../domain/card-info';

export class CardQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly maskedNumber: string,
    public readonly logo: string,
  ) {}

  static fromCardInfo(cardInfo: CardInfo): CardQuery {
    return new CardQuery(
      cardInfo.id,
      cardInfo.name,
      `XXXX-XXXX-XXXX-${cardInfo.lastDigits}`,
      `assets/payment-plans/images/${cardInfo.brand}.svg`,
    );
  }
}

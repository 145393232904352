import { Injectable } from '@angular/core';
import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { CompleteProfileState } from '../../application/complete-profile.state';

@Injectable()
export class CompleteProfileUserChangedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UserChangedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private _completeProfileState: CompleteProfileState) {}

  handle([event]: [UserChangedEvent]): void {
    this._completeProfileState.setGuestUser(event.user.isGuest);
  }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppMetadataDto } from '../../../../application/ports/secondary/app-metadata.dto';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpAppsCdnService {
  constructor(private _client: HttpClient) {}

  getAppsMeta(): Observable<AppMetadataDto[]> {
    return this._client
      .get(`${window.origin}/assets/cdn/apps/index.json`)
      .pipe(map((appSuggestions: { data: AppMetadataDto[] }) => appSuggestions.data));
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_CURRENT_PLAN_DTO_PORT = new InjectionToken<GetsCurrentPlanDtoPort>(
  'GETS_CURRENT_PLAN_DTO_PORT',
);

export interface GetsCurrentPlanDtoPort {
  get(): Observable<CurrentPlanDto>;
}

export interface CurrentPlanDto {
  planName: string;
  planPeriod: string;
}

import { NgModule } from '@angular/core';
import { GatewayModule } from '@app.cobiro.com/common/gateway';
import { GETS_INSTALLED_APPS_IDS_DTO_PORT } from '../../../../application/ports/secondary/gets-installed-apps-ids';
import { HttpInstalledAppsService } from './http-installed-apps.service';

@NgModule({
  imports: [GatewayModule],
  providers: [
    HttpInstalledAppsService,
    {
      provide: GETS_INSTALLED_APPS_IDS_DTO_PORT,
      useExisting: HttpInstalledAppsService,
    },
  ],
})
export class HttpInstalledAppsServiceModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SetsLabelManagerConfigCommand } from './sets-label-manager-config.command';

export const SETS_LABEL_MANAGER_CONFIG_COMMAND_PORT =
  new InjectionToken<SetsLabelManagerConfigCommandPort>('SETS_LABEL_MANAGER_CONFIG_COMMAND_PORT');

export interface SetsLabelManagerConfigCommandPort {
  setConfig(command: SetsLabelManagerConfigCommand): Observable<boolean>;
}

import { GetsOneClientServicesDtoPort } from '../../../application/ports/secondary/gets-one-client-services.dto-port';
import { Observable, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { map } from 'rxjs/operators';
import { HasDataCollection } from '@cobiro/jsonapi';
import { ClientServicesDto } from '../../../application/ports/secondary/client-services.dto';
import { GetsAllClientsServicesDtoPort } from '../../../application/ports/secondary/gets-all-clients-services.dto-port';

@Injectable()
export class HttpClientServicesService
  implements GetsOneClientServicesDtoPort, GetsAllClientsServicesDtoPort
{
  private _appsMap = new Map<string, string>([
    ['brex', 'websitebuilder'],
    ['google_shopping', 'googleshopping'],
    ['google_search', 'googlesearch'],
    ['google_my_business', 'googlemybusiness'],
    ['cobiro_css', 'cobirocss'],
    ['domain', 'domains'],
    ['ecommerce', 'ecommerce'],
  ]);
  constructor(private _client: GatewayClient) {}

  getOne(siteId: string, sitePublicId: string): Observable<ClientServicesDto> {
    return this._checkApps(siteId, sitePublicId);
  }

  getAllClientsServices(
    clients: { siteId: string; sitePublicId: string }[],
  ): Observable<ClientServicesDto[]> {
    return zip(clients.map(client => this.getOne(client.siteId, client.sitePublicId)));
  }

  private _checkApps(siteId: string, sitePublicId: string): Observable<ClientServicesDto> {
    return this._client
      .get<HasDataCollection<{ app_identifier: string }>>(`v2/app-registry/${siteId}/installations`)
      .pipe(
        map(response => {
          const filteredApps = response.data.filter(installation =>
            this._appsMap.has(installation.attributes.app_identifier),
          );
          return {
            siteId,
            sitePublicId,
            services: filteredApps.map(installation =>
              this._appsMap.get(installation.attributes.app_identifier),
            ),
          };
        }),
      );
  }
}

import { NgModule } from '@angular/core';
import { HttpClientSitesService } from './http-client-sites.service';
import { ADDS_CLIENT_SITE_DTO } from '../../../application/ports/secondary/adds-client-site.dto-port';

@NgModule({
  providers: [
    HttpClientSitesService,
    { provide: ADDS_CLIENT_SITE_DTO, useExisting: HttpClientSitesService },
  ],
})
export class HttpClientSitesServiceModule {}

import { InjectionToken } from '@angular/core';

export interface ClearsContextCommandPort {
  clearContextAndGoToPersonalMode(): void;
  clearContext(): void;
}

export const CLEAR_CONTEXT_COMMAND = new InjectionToken<ClearsContextCommandPort>(
  'CLEAR_CONTEXT_COMMAND',
);

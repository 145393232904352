import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SubmitsCompanyDetailsStepCommand } from './submits-company-details-step.command';

export const SUBMITS_COMPANY_DETAILS_STEP_COMMAND =
  new InjectionToken<SubmitsCompanyDetailsStepCommandPort>('SUBMITS_COMPANY_DETAILS_STEP_COMMAND');

export interface SubmitsCompanyDetailsStepCommandPort {
  submitCompanyDetailsStep(command: SubmitsCompanyDetailsStepCommand): Observable<void>;
}

import { SignInCompletedEvent, UserAdditionalDataChangedEvent } from '@app.cobiro.com/core/events';
import { UserLoginState } from '../application/state/user-login.state';
import { Injectable } from '@angular/core';
import { ApplicationEventsHandler, zipEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class UserSignInCompletedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [SignInCompletedEvent, UserAdditionalDataChangedEvent];
  strategy = zipEventsOrchestrationStrategy;

  constructor(private _userLoginState: UserLoginState) {}

  handle([signInCompletedEvent, userAdditionalDataChangedEvent]: [
    SignInCompletedEvent,
    UserAdditionalDataChangedEvent,
  ]) {
    this._userLoginState.finishLogin(
      signInCompletedEvent.userId,
      userAdditionalDataChangedEvent.hasTeams,
    );
  }
}

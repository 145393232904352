import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY =
  new InjectionToken<GetsLabelManagerLoaderMessageQueryPort>(
    'GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY',
  );

export interface GetsLabelManagerLoaderMessageQueryPort {
  getMessage(): Observable<string>;
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { LabelManagerSetConfigEvent } from '@app.cobiro.com/core/events';
import {
  SETS_LABEL_MANAGER_CONFIG_FOR_RECOMMENDATION_COMMAND_PORT,
  SetsLabelManagerConfigForRecommendationCommandPort,
} from '../../../application/ports/primary/sets-label-manager-config-for-recommendation.command-port';
import { take } from 'rxjs';

@Injectable()
export class LabelManagerSetConfigEventHandler implements ApplicationEventsHandler {
  eventsClasses = [LabelManagerSetConfigEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_LABEL_MANAGER_CONFIG_FOR_RECOMMENDATION_COMMAND_PORT)
    private readonly _setsLabelManagerConfigForRecommendationCommandPort: SetsLabelManagerConfigForRecommendationCommandPort,
  ) {}

  handle([event]: [LabelManagerSetConfigEvent]) {
    this._setsLabelManagerConfigForRecommendationCommandPort.setConfig(event.config).pipe(take(1));
  }
}

<main class="d-flex flex-column flex-md-row cs-bg-shade-0 position-relative">
  <button class="cs-btn cs-btn-ghost-squared position-absolute cs-top-20 cs-right-20">
    <mat-icon
      svgIcon="close"
      class="cs-z-index-1"
      data-selector="cobiro-pro-no-access-modal-close"
      data-analytics="cobiro-pro-no-access-modal-close"
      (click)="onCloseModalClicked()"
    ></mat-icon>
  </button>

  <div
    class="d-flex flex-column justify-content-between cs-width-100-pc-xs h-100 cs-bg-shade-0 cs-p-60"
  >
    <article class="d-flex flex-column justify-content-center align-items-center">
      <h2 class="cs-title-3 cs-mb-30">
        {{ 'cobiro_pro_app_guard_heading' | language }}
      </h2>
      <div class="cs-text-1 d-flex flex-column justify-content-center align-items-center">
        <p class="cs-mb-10">{{ 'cobiro_pro_app_guard_paragraph_1' | language }}</p>
        <p class="cs-mb-30">{{ 'cobiro_pro_app_guard_paragraph_2' | language }}</p>
      </div>

      <button
        class="cs-btn cs-btn-primary cs-width-250-xs"
        (click)="onCloseModalClicked()"
        data-selector="cobiro-pro-ads-guard-ok-button"
      >
        {{ '_ok' | language | uppercase }}
      </button>
    </article>
  </div>
</main>

import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { PaymentFlowStartedDispatcherPort } from '../../../application/ports/secondary/dispatchers/payment-flow-started.dispatcher-port ';
import { PaymentFlowStartedEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class PaymentFlowStartedEventDispatcher implements PaymentFlowStartedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { clientId: string; product: string }) {
    this._applicationBus.dispatch(new PaymentFlowStartedEvent(event.clientId, event.product));
  }
}

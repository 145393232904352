import { NgModule } from '@angular/core';
import { GETS_LATEST_USERS_QUERY } from '../ports/primary/gets-latest-users.query-port';
import { LatestUsersState } from './latest-users.state';
import { USERS_LIST_CHANGED_DISPATCHER } from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import { UsersListChangedEventDispatcher } from '../../adapters/secondary/dispatchers/users-list-changed.event-dispatcher';
import { LOAD_LATEST_USERS_COMMAND } from '../ports/primary/load-latest-users.command-port';
import { GETS_LATEST_USERS_PAGINATION_QUERY } from '../ports/primary/gets-latest-users-pagination.query-port';

@NgModule({
  providers: [
    LatestUsersState,
    { provide: LOAD_LATEST_USERS_COMMAND, useExisting: LatestUsersState },
    { provide: GETS_LATEST_USERS_QUERY, useExisting: LatestUsersState },
    { provide: GETS_LATEST_USERS_PAGINATION_QUERY, useExisting: LatestUsersState },
    { provide: USERS_LIST_CHANGED_DISPATCHER, useClass: UsersListChangedEventDispatcher },
  ],
})
export class LatestUsersStateModule {}

import { Observable } from 'rxjs';
import { ClientListItemQuery } from './client-list-item.query';
import { InjectionToken } from '@angular/core';

export interface GetsPaginatedClientsQueryPort {
  getsPaginatedClients(): Observable<ClientListItemQuery[]>;
  getTotalClients(): Observable<number>;
}

export const GETS_PAGINATED_CLIENTS_QUERY = new InjectionToken<GetsPaginatedClientsQueryPort>(
  'GETS_PAGINATED_CLIENTS_QUERY',
);

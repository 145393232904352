import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JSON_API_HEADERS } from '@cobiro/jsonapi';
import { catchError, map } from 'rxjs/operators';
import { BASE_URL } from '@app.cobiro.com/core/utils';
import { HasData } from '@cobiro/jsonapi';
import { ResendsCodeDtoPort } from '../../application/ports/secondary/resends-code.dto-port';

export interface ResendCodeAttributes {
  email: string;
}

@Injectable()
export class HttpResendsCodeService implements ResendsCodeDtoPort {
  private _headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(private _client: HttpClient, @Inject(BASE_URL) private _baseUrl: string) {}

  resend(email: string): Observable<boolean> {
    const body: HasData<ResendCodeAttributes> = {
      data: {
        type: 'users',
        attributes: {
          email,
        },
      },
    };

    return this._client
      .post(this._baseUrl + '/v1/users/email/verify/resend', body, {
        headers: this._headers,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyWithWorkspaceCreatedEvent } from '@app.cobiro.com/user/register';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class AgencyWithWorkspaceCreatedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [AgencyWithWorkspaceCreatedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _router: Router) {}

  handle([event]: [AgencyWithWorkspaceCreatedEvent]): void {
    this._router.navigate(['/', 'connect'], { queryParams: { signup: '1' } });
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { GetsAllClientsSubsciptionsDto } from './gets-all-clients-subscriptions.dto';
import { ClientSubscriptionDto } from './clients-subscription.dto';

export const GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT =
  new InjectionToken<GetsAllClientsSubsciptionsDtoPort>('GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT');

export interface GetsAllClientsSubsciptionsDtoPort {
  getAllSubscriptions(dto: GetsAllClientsSubsciptionsDto): Observable<ClientSubscriptionDto[]>;
}

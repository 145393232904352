<div class="row cs-mt-20">
  <div class="col-lg-12 col-xl-9">
    <div class="cs-card">
      <ng-container *huiLoaderContainer="paymentSourcesQuery$ | async as paymentSources">
        <h3 class="cs-title-5 cs-mb-20">
          {{ 'cobiro_pro_payment_methods_title' | language }}
        </h3>

        <div class="w-100">
          <!-- <ng-container *huiLoaderContainer="paymentSourcesQuery$ | async; else paymentSourcesList">
          <hui-default-loader></hui-default-loader>
        </ng-container>

        <ng-template #paymentSourcesList> -->
          <ul class="cs-list cs-list-none" *ngIf="(isReplacingPaymentMethod$ | async) === false">
            <li
              class="cs-list-item br-5 cs-b-shade-10 cs-p-20 cs-mt-5 cs-b-1"
              data-selector="payment-sources-list-item"
              *ngFor="let paymentSource of paymentSources; trackBy: trackById"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <p class="cs-title-7">{{ paymentSource.cardOwner }}</p>
                  <p class="cs-text-2">{{ paymentSource.cardNumber }}</p>
                </div>
                <img class="cs-width-60-xs" [src]="paymentSource.brand" />
              </div>
            </li>
          </ul>
          <!-- </ng-template> -->
        </div>

        <ng-container
          *ngIf="(isAddingPaymentMethod$ | async) || (isReplacingPaymentMethod$ | async)"
        >
          <ng-container *libFeatureFlags="['payment_malfunction_popup']; else addCardForm">
            <label class="cs-label">
              <p [innerHTML]="'adding_new_card_issue' | language"></p>
            </label>
          </ng-container>
          <ng-template #addCardForm>
            <form
              data-selector="add-payment-method-card-form"
              [formGroup]="cardForm"
              (ngSubmit)="onCardSubmit()"
            >
              <div class="row">
                <div class="col-12">
                  <label class="cs-label">
                    {{ 'cobiro_pro_settings_payment_methods_card_owner' | language }}
                    <span class="cs-c-error">{{ '_required' | language }}</span>
                  </label>

                  <input
                    formControlName="cardOwner"
                    data-selector="card-owner-input"
                    class="cs-input"
                    type="text"
                    [placeholder]="
                      'cobiro_pro_settings_payment_methods_card_owner_placeholder' | language
                    "
                  />
                  <ng-container *ngIf="hasError('cardOwner', 'required')">
                    <p class="cs-control-error">
                      {{ 'cobiro_pro_settings_payment_methods_required_name' | language }}
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="row cs-mt-20">
                <div class="col-lg-7 cs-mb-20 cs-mb-0-lg">
                  <label class="cs-label">
                    {{ 'cobiro_pro_settings_payment_methods_card_number' | language }}
                    <span class="cs-c-error">{{ '_required' | language }}</span>
                  </label>
                  <input
                    formControlName="cardNumber"
                    class="cs-input"
                    mask="0000 0000 0000 0999 999"
                    [validation]="true"
                    autocomplete="cc-number"
                    placeholder="0000 0000 0000 0000"
                    data-selector="credit-card-number-input"
                  />
                  <ng-container *ngIf="hasError('cardNumber', 'required')">
                    <p class="cs-control-error">
                      {{ 'cobiro_pro_settings_payment_methods_card_number_required' | language }}
                    </p>
                  </ng-container>
                </div>
                <div class="col-lg-3 cs-mb-20 cs-mb-0-lg">
                  <label class="cs-label">
                    {{ 'cobiro_pro_settings_payment_methods_expiration_label' | language }}
                    <span class="cs-c-error">{{ '_required' | language }}</span>
                  </label>
                  <input
                    formControlName="expirationDate"
                    class="cs-input"
                    placeholder="MM/YY"
                    mask="M0/00"
                    [validation]="true"
                    data-selector="credit-card-expiration-date-input"
                    huiCardExpireDate
                  />
                  <ng-container *ngIf="hasError('expirationDate', 'required')">
                    <p class="cs-control-error">
                      {{ 'cobiro_pro_settings_payment_methods_invalid_date' | language }}
                    </p>
                  </ng-container>
                </div>
                <div class="col-lg-2">
                  <label class="cs-label">
                    CVV
                    <span class="cs-c-error">{{ '_required' | language }}</span>
                  </label>

                  <input
                    formControlName="cvv"
                    class="cs-input"
                    placeholder="XXX"
                    mask="0009"
                    [validation]="false"
                    data-selector="credit-card-cvv-input"
                  />
                  <ng-container *ngIf="hasError('cvv', 'required')">
                    <p class="cs-control-error cs-width-80-xs">
                      {{ 'cobiro_pro_settings_payment_methods_invalid_cvv' | language }}
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex justify-content-end cs-mt-40">
                <button
                  [disabled]="cardForm.invalid"
                  [class.is-loading]="isProcessing$ | async"
                  class="cs-btn cs-btn-primary cs-width-120-xs"
                  data-selector="add-payment-method-submit"
                  type="submit"
                >
                  {{ 'cobiro_pro_settings_add_payment_method' | language }}
                </button>
              </div>
            </form>
          </ng-template>
        </ng-container>

        <button
          *ngIf="paymentSources.length === 0 && (isAddingPaymentMethod$ | async) === false"
          class="cs-btn cs-btn-dashed w-100"
          data-selector="add-payment-method"
          type="button"
          (click)="onAddCardClicked()"
        >
          + {{ 'cobiro_pro_settings_add_payment_method' | language }}
        </button>
        <button
          *ngIf="paymentSources.length === 1 && (isReplacingPaymentMethod$ | async) === false"
          class="cs-btn cs-btn-dashed w-100"
          data-selector="add-payment-method"
          type="button"
          (click)="onReplaceCardClicked()"
        >
          {{ 'cobiro_pro_settings_replace_payment_method' | language }}
        </button>
      </ng-container>
    </div>
  </div>
</div>

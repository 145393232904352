/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Component, ChangeDetectionStrategy, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SetsSearchPhraseWorkspaceCommandPort,
  SETS_USERS_LIST_SEARCH_PHRASE_WORKSPACE_COMMAND_PORT,
} from '../../../../../application/ports/primary/sets-search-phrase-workspace-users.command-port';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  AddsUserCommandPort,
  ADDS_USER_COMMAND,
} from '../../../../../application/ports/primary/adds-user.command-port';

@Component({
  selector: 'lib-cobiro-pro-workspace-users-controls',
  templateUrl: './workspace-users-controls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProWorkspaceUsersControlsComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  public readonly searchControl = new UntypedFormControl();
  private routeQueryParams$: Subscription;

  constructor(
    @Inject(SETS_USERS_LIST_SEARCH_PHRASE_WORKSPACE_COMMAND_PORT)
    private readonly _setsSearchPhraseCommand: SetsSearchPhraseWorkspaceCommandPort,
    @Inject(ADDS_USER_COMMAND)
    private readonly _addsUserCommand: AddsUserCommandPort,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(searchValue =>
          this._setsSearchPhraseCommand.setSearchPhrase({ phrase: searchValue }),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();

    this.routeQueryParams$ = this._route.queryParams
      .pipe(
        mergeMap(params => {
          if (params['add']) {
            return this._addsUserCommand
              .addUser({ route: this._route })
              .pipe(catchError(() => of(undefined)));
          }

          return of(void 0);
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onAddMemberClicked(): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        add: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  PAYMENT_STATUS,
  UpdatesPlanCommand,
  UpdatesPlanCommandPort,
} from '@app.cobiro.com/payment-plans';

@Injectable()
export class PaymentUpdatePlanPurchaseState implements UpdatesPlanCommandPort {
  updatesPlan(command: UpdatesPlanCommand): Observable<PAYMENT_STATUS> {
    return of(PAYMENT_STATUS.DEFAULT_FAILED);
  }
}

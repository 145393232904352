<form class="cs-form cs-ph-80-lg cs-ph-20-sm" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="cs-control-wrapper">
    <input
      class="cs-input"
      placeholder="Email"
      formControlName="email"
      data-selector="register-email-input"
    />
    <div class="cs-min-height-20-xs">
      <p *ngIf="hasError('email')" class="cs-ml-5 cs-control-error">
        {{ 'signup_input_not_email' | language }}
      </p>
    </div>
  </div>

  <button
    class="cs-btn cs-btn-primary w-100 d-flex align-items-center justify-content-center cs-mt-10"
    [disabled]="control('email')?.invalid"
    type="submit"
    data-selector="continue-email"
    [libAnalytics]="['sign-up-step-1', 'continue']"
  >
    <span class="cs-c-shade-0">
      {{ '_continue' | language }}
    </span>
  </button>
</form>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_RECOMMENDATION_LIST_EMPTY_COMMAND_PORT =
  new InjectionToken<IsRecommendationListEmptyCommandPort>(
    'IS_RECOMMENDATION_LIST_EMPTY_COMMAND_PORT',
  );

export interface IsRecommendationListEmptyCommandPort {
  isEmpty(): Observable<boolean>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GETS_APPS_DTO_PORT } from './application/port/secondary/gets-applications-dto.port';
import { HttpGetsAppsService } from './adapters/secondary/http-gets-apps.service';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { AppRegistryState } from './application/app-registry.state';
import { GETS_AVAILABLE_APPS_QUERY_PORT } from './application/port/primary/gets-available-apps.query-port';

@NgModule({
  imports: [CommonModule, FeatureFlagsModule],
  providers: [
    AppRegistryState,
    {
      provide: GETS_APPS_DTO_PORT,
      useClass: HttpGetsAppsService,
    },
    {
      provide: GETS_AVAILABLE_APPS_QUERY_PORT,
      useExisting: AppRegistryState,
    },
  ],
})
export class AppRegistryModule {}

import { NgModule } from '@angular/core';
import { CONTEXT_CHANGED_DISPATCHER } from '../../../application/ports/secondary/context-changed.dispatcher-port';
import { ApplicationBusContextChangedDispatcher } from './application-bus-context-changed.dispatcher';

@NgModule({
  providers: [
    ApplicationBusContextChangedDispatcher,
    {
      provide: CONTEXT_CHANGED_DISPATCHER,
      useExisting: ApplicationBusContextChangedDispatcher,
    },
  ],
})
export class ApplicationBusContextChangedDispatcherModule {}

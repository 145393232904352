import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface ChecksAppAccessQueryPort {
  checkAppAccess(appIdentifier: string, siteId: string): Observable<boolean>;
}

export const CHECKS_APP_ACCESS_QUERY = new InjectionToken<ChecksAppAccessQueryPort>(
  'CHECKS_APP_ACCESS_QUERY',
);

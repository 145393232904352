import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig, WINDOW } from '@app.cobiro.com/core/utils';
import { HasData, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { from, Observable, zip } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { ChargebeeEnv } from '../../../../../../../environments/environment.config';
import { Card, CardHolder } from '../../domain/card';
import { Checkout } from '../../domain/checkout';
import { CobiroPaymentConfirmation } from '../../domain/cobiro-payment-confirmation';
import { PAYMENT_STATUS } from '../../domain/payment-status.enum';
import { Gateway, PaymentAttempt, PaymentIntent, PaymentIntentStatus } from '../payment-intent';
import { PaymentIntentAttributes } from './payment-intent.attributes';

export enum CHARGEBEE_PAYMENT_ATTEMPT_ERRORS {
  INSUFFICIENT_FUNDS = '12',
  REFUSED = '2',
}

export interface Chargebee {
  init: ({ site }: { site: string }) => ChargebeeConfig;
}

export interface ChargebeeConfig {
  load: (moduleName: string) => Promise<never>;
  load3DSHandler: () => Promise<Chargebee3DSHandler>;
}

export interface Chargebee3DSHandler {
  setPaymentIntent(intent: PaymentIntent): void;
  handleCardPayment(
    cardData: { card: ChargebeeCard; additionalData: any },
    callbacks: {
      success: (intent?: PaymentIntent) => void;
      error: (intent?: PaymentIntent, error?: any) => void;
    },
  ): void;
}

export interface ChargebeeCard {
  firstName: string;
  lastName?: string;
  number: string;
  cvv: string;
  expiryMonth: string;
  expiryYear: string;
}

@Injectable()
export class HttpChargebeeService implements Checkout {
  private readonly ERROR_CODES: Map<string, PAYMENT_STATUS> = new Map([
    [CHARGEBEE_PAYMENT_ATTEMPT_ERRORS.INSUFFICIENT_FUNDS, PAYMENT_STATUS.INSUFFICIENT_FUNDS],
    [CHARGEBEE_PAYMENT_ATTEMPT_ERRORS.REFUSED, PAYMENT_STATUS.DEFAULT_FAILED],
  ]);

  private _cbInstance;
  private _headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(
    private readonly _client: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
    @Inject(WINDOW) private readonly _window: { Chargebee: Chargebee },
  ) {
    this._cbInstance = this._window.Chargebee.init(this._getsConfig.get<ChargebeeEnv>('chargebee'));
  }

  processCardPayment(
    card: Card,
    productId: string,
    cardHolder: CardHolder,
    countryCode: string,
    discountCode?: string,
  ): Observable<CobiroPaymentConfirmation> {
    return from(this._cbInstance.load('3ds-handler')).pipe(
      switchMap(() =>
        zip(
          from(this._cbInstance.load3DSHandler()),
          this._createPaymentIntent(productId, countryCode, discountCode),
        ),
      ),
      concatMap(
        ([threeDSHandler, paymentIntent]: [
          Chargebee3DSHandler,
          { cobiroIntentId: string; intent: PaymentIntent },
        ]) =>
          this._processPaymentIntent(
            threeDSHandler,
            card,
            paymentIntent.intent,
            paymentIntent.cobiroIntentId,
            cardHolder,
          ),
      ),
    );
  }

  private _processPaymentIntent(
    threeDSHandler: Chargebee3DSHandler,
    card: Card,
    intent: PaymentIntent,
    cobiroIntentId: string,
    cardHolder: CardHolder,
  ): Observable<CobiroPaymentConfirmation | null> {
    return new Observable<CobiroPaymentConfirmation | null>(subscriber => {
      threeDSHandler.setPaymentIntent(intent);
      threeDSHandler.handleCardPayment(
        {
          card: {
            firstName: cardHolder.name,
            expiryMonth: card.expiryMonth,
            expiryYear: card.expiryYear,
            number: card.number,
            cvv: card.cvv,
          },
          additionalData: {},
        },
        {
          success: () => {
            // Triggers when card is 3DS user-authorized
            subscriber.next(new CobiroPaymentConfirmation(PAYMENT_STATUS.SUCCESS, cobiroIntentId));
            subscriber.complete();
          },
          error: (paymentIntent: PaymentIntent) => {
            console.log('Error during payment intent', paymentIntent);
            // Triggers when 3DS authorization fails
            // PaymentIntent from Chargebee confirm
            try {
              subscriber.next(
                new CobiroPaymentConfirmation(
                  this._mapError(paymentIntent.active_payment_attempt),
                  cobiroIntentId,
                ),
              );
            } catch (e) {
              console.error(e);
              subscriber.next(
                new CobiroPaymentConfirmation(PAYMENT_STATUS.DEFAULT_FAILED, cobiroIntentId),
              );
            }

            subscriber.complete();
          },
        },
      );
    });
  }

  private _createPaymentIntent(
    planId: string,
    countryCode: string,
    discountCode?: string,
  ): Observable<{ cobiroIntentId: string; intent: PaymentIntent }> {
    const body = {
      data: {
        type: 'create-payment-intent',
        attributes: {
          planId,
          scope: {
            type: 'site',
            countryCode,
            discountCode,
          },
        },
      },
    };
    return this._client
      .post<HasData<PaymentIntentAttributes>>( // TODO change it into DTO port
        `${this._getsConfig.get('gateway')}/v1/payments/payment-intent`,
        body,
        {
          headers: this._headers,
        },
      )
      .pipe(
        map(response => ({
          cobiroIntentId: response.data.id,
          intent: this._mapPaymentIntent(response.data.attributes),
        })),
      );
  }

  private _mapPaymentIntent(paymentAttributes: PaymentIntentAttributes): PaymentIntent {
    return {
      id: paymentAttributes.vendorIntent.id,
      status: paymentAttributes.vendorIntent.status as PaymentIntentStatus,
      amount: paymentAttributes.vendorIntent.amount,
      currency_code: paymentAttributes.vendorIntent.currencyCode,
      gateway: paymentAttributes.vendorIntent.gateway as Gateway,
      gateway_account_id: paymentAttributes.vendorIntent.gatewayAccountId,
    };
  }

  private _mapError(paymentAttemptStatus: PaymentAttempt): PAYMENT_STATUS {
    const paymentStatus = this.ERROR_CODES.get(paymentAttemptStatus.error_code);

    if (!paymentStatus) {
      throw new UnknownChargebeeCodeError(paymentAttemptStatus.error_code);
    }

    return paymentStatus;
  }
}

export class UnknownChargebeeCodeError extends Error {
  title = 'Unknown Chargebee Code Error';

  constructor(code: string) {
    super();
    this.message = `Unknown Chargebee code ${code}`;
  }
}

import { InjectionToken } from '@angular/core';
import { Class } from '@app.cobiro.com/core/utils';
import { ApplicationEvent } from '@cobiro/eda';

export interface EventMonitoringHandler {
  eventClass: Class<ApplicationEvent>;
  handler: (event: ApplicationEvent) => void;
}

export interface EventLogMonitor {
  readonly eventHandlers: EventMonitoringHandler[];
}

export const EVENT_LOG_MONITOR = new InjectionToken<EventLogMonitor>('EVENT_LOG_MONITOR');

export const provideEventLogMonitor = (monitor: Class<EventLogMonitor>) => {
  return {
    provide: EVENT_LOG_MONITOR,
    useClass: monitor,
    multi: true,
  };
};

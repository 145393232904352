import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorHandlerInterface } from './error-handler-interface';

@Injectable({ providedIn: 'root' })
export class LocalErrorHandler implements ErrorHandler, ErrorHandlerInterface {
  private userId: string;
  private errorHandler: ErrorHandler;

  constructor() {
    this.errorHandler = new ErrorHandler();
  }

  assignUser(userId: string) {
    this.userId = userId;
  }

  handleError(error: any): void {
    this.errorHandler.handleError(error);
  }
}

import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'lib-label-manager-support',
  templateUrl: './label-manager-support.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerSupportComponent {
  @HostBinding('class') classes = 'cs-card d-flex w-100 flex-column align-items-center';
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsQuestionsQuery } from './gets-questions.query';
import { QuestionQuery } from './question.query';

export interface GetsQuestionsQueryPort {
  getQuestions(query: GetsQuestionsQuery): Observable<QuestionQuery[]>;
}

export const GETS_QUESTIONS_QUERY = new InjectionToken<GetsQuestionsQueryPort>(
  'GETS_QUESTIONS_QUERY',
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CreateProfileProgressIndicatorComponentModule } from '../../../create-profile-progress-indicator/create-profile-progress-indicator.component-module';
import { WorkspaceInfoStepComponent } from './workspace-info-step.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    CreateProfileProgressIndicatorComponentModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  declarations: [WorkspaceInfoStepComponent],
  exports: [WorkspaceInfoStepComponent],
})
export class WorkspaceInfoStepComponentModule {}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsClientSubsciptionsDto } from '../../../../application/ports/secondary/gets-client-subscriptions.dto';
import { GetsClientSubsciptionsDtoPort } from '../../../../application/ports/secondary/gets-client-subscriptions.dto-port';
import { ClientSubscriptionDto } from '../../../../application/ports/secondary/subscription.dto';

export interface ClientProductAttributes {
  productType: 'css' | 'label-manager';
  planType: 'monthly' | 'annual' | 'business';
  subscriptionId: string | null;
}

@Injectable()
export class HttpClientsProductService implements GetsClientSubsciptionsDtoPort {
  constructor(private readonly _gatewayClient: GatewayClient) {}
  getSubscriptions(dto: GetsClientSubsciptionsDto): Observable<ClientSubscriptionDto[]> {
    return this._gatewayClient
      .get<HasDataCollection<ClientProductAttributes>>(
        `v1/sites/workspaces/${dto.workspaceId}/clients/${dto.clientId}/products`,
      )
      .pipe(
        map((products: HasDataCollection<ClientProductAttributes>) => {
          return products.data.map(data => ({
            productName: data.attributes.productType,
            planType: data.attributes.planType,
            subscriptionId: data.attributes.subscriptionId,
            productId: data.id,
          }));
        }),
      );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserVerifiedAccountEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserState } from '../application/user.state';

@Injectable()
export class UserVerifiedAccountEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UserVerifiedAccountEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(private readonly _userState: UserState, private readonly _router: Router) {}

  handle([event]: [UserVerifiedAccountEvent]): void {
    this._userState.login(event.email, event.password).subscribe(() => {
      this._router.navigate(['/onboarding']);
    });
  }
}

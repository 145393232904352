function createCustomIconPath(name: string) {
  return `/assets/svg-icons/custom/icon-${name}.svg`;
}

// Try to avoid adding new icons here if similar icons can be found on Iconic
// Basically, the only icons present here would be those made by the Design Team
// https://iconic.app/
export const CUSTOM_ICONS_REGISTRY = new Map([
  // The ones below have been taken from Primitives, but as such are not taken from the Primitives package
  ['calendar', createCustomIconPath('calendar')], // Primitives: calendar
  ['facebook-logo-white', createCustomIconPath('facebook-logo-white')], // Primitives: facebook
  ['grow', createCustomIconPath('grow')], // Primitives: grow
  ['home-hollow', createCustomIconPath('home-hollow')], // Primitives: home
  ['megaphone-hollow', createCustomIconPath('megaphone-hollow')], // Primitives: promote
  ['old-website-builder-hollow', createCustomIconPath('old-website-builder-hollow')], // Primitives: build
  // This is where the Primitives-based icons end
  ['add-white', createCustomIconPath('add-white')],
  ['bag-checked', createCustomIconPath('bag-checked')],
  ['checkmark-filled-blue', createCustomIconPath('checkmark-filled-blue')],
  ['builder', createCustomIconPath('builder')],
  ['button', createCustomIconPath('button')], // B-rex: button
  ['checkmark-green-circled-2', createCustomIconPath('checkmark-green-circled-2')], // B-rex: check
  ['crown', createCustomIconPath('crown')],
  ['cursor-text', createCustomIconPath('cursor-text')],
  ['device-desktop', createCustomIconPath('device-desktop')], // B-rex: device-desktop
  ['device-mobile', createCustomIconPath('device-mobile')], // B-rex: device-mobile
  ['device-tablet', createCustomIconPath('device-tablet')], // B-rex: device-tablet
  ['dot', createCustomIconPath('dot')],
  ['drag-handle', createCustomIconPath('drag-handle')],
  ['ebay-logo', createCustomIconPath('ebay-logo')],
  ['ecommerce', createCustomIconPath('ecommerce')],
  ['edit', createCustomIconPath('edit-hollow')],
  ['envelope', createCustomIconPath('envelope')],
  ['eye-opened', createCustomIconPath('eye-opened')],
  ['eye-closed', createCustomIconPath('eye-closed')],
  ['fab-play', createCustomIconPath('fab-play')],
  ['facebook-ads', createCustomIconPath('facebook-ads')],
  ['git-builder', createCustomIconPath('git-builder')],
  ['google-ads', createCustomIconPath('google-ads')],
  ['google-css', createCustomIconPath('google-css')],
  ['green-checkmark-filled', createCustomIconPath('checkmark-filled')],
  ['heart-black-hollow', createCustomIconPath('heart-black-hollow')], // libs/ecommerce/pim/src/assets/svg-icons
  ['info', createCustomIconPath('info')],
  ['lock-filled-closed', createCustomIconPath('lock-filled-closed')], // B-rex: lock-closed
  ['lock-filled-open', createCustomIconPath('lock-filled-open')], // B-rex: lock-open
  ['newtab', createCustomIconPath('newtab')],
  ['ok', createCustomIconPath('ok')],
  ['phone', createCustomIconPath('phone')], // cobiro/src/assets/images/google-search
  ['play-button', createCustomIconPath('play-button')], // cobiro/src/assets/images/google-search
  ['redo', createCustomIconPath('redo')], // B-rex: redo
  ['redo_brex_ui_redesign', createCustomIconPath('redo_brex_ui_redesign')], // B-rex: redo
  ['seo-tool', createCustomIconPath('seo-tool')],
  ['shopping-stall', createCustomIconPath('shopping-stall')],
  ['success', createCustomIconPath('success')],
  ['undo', createCustomIconPath('undo')],
  ['undo_brex_ui_redesign', createCustomIconPath('undo_brex_ui_redesign')],
  ['zapier', createCustomIconPath('zapier')],
  ['checklist', createCustomIconPath('checklist')],
  ['circle-arrow', createCustomIconPath('circle-arrow')],
  ['product-status-none', createCustomIconPath('product-status-none')],
  ['product-status-active', createCustomIconPath('product-status-active')],
  ['product-status-pending', createCustomIconPath('product-status-pending')],
  ['product-status-paused', createCustomIconPath('product-status-paused')],
  ['message', createCustomIconPath('message')],
  ['external-link', createCustomIconPath('external-link')],
  ['reload', createCustomIconPath('reload')],
  ['cashback', createCustomIconPath('cashback')],
]);

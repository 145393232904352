import { EventLogMonitor } from '../../event-logger/domain/event-log-monitor';
import { Inject, Injectable } from '@angular/core';
import { EventLoggerContext } from '../../event-logger/application/event-logger.context';
import {
  TRACKS_IN_DATA_LAYER,
  TracksInDataLayer,
} from '../../event-logger/domain/tracks-in-data-layer';
import {
  DATA_LAYER_TRACKING_MAPPER,
  DataLayerTrackingMapper,
} from '../domain/data-layer-tracking-mapper';
import { DataLayerTrackingData } from '../domain/data-layer-tracking-data';
import { ApplicationEvent } from '@cobiro/eda';

@Injectable()
export class DataLayerMonitor implements EventLogMonitor {
  readonly eventHandlers = this._dataLayerTrackingMappers.map(mapper => {
    return {
      eventClass: mapper.eventClass,
      handler: (event: ApplicationEvent) => this._trackDataLayerEvent(mapper.mapEvent(event)),
    };
  });

  constructor(
    private readonly _eventLoggerContext: EventLoggerContext,
    @Inject(TRACKS_IN_DATA_LAYER) private readonly _tracksInDataLayer: TracksInDataLayer,
    @Inject(DATA_LAYER_TRACKING_MAPPER)
    private readonly _dataLayerTrackingMappers: DataLayerTrackingMapper[],
  ) {}

  private _trackDataLayerEvent(trackingData: DataLayerTrackingData): void {
    const eventData = trackingData.eventData || {};
    this._tracksInDataLayer.track(trackingData.eventName, {
      ...eventData,
      ...this._eventLoggerContext.get(),
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SignUpByFacebookComponent } from './ui/sign-up-by-facebook/sign-up-by-facebook.component';
import { SignUpByGoogleComponent } from './ui/sign-up-by-google/sign-up-by-google.component';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { SocialLoginModule } from '@abacritt/angularx-social-login';

@NgModule({
  imports: [
    CommonModule,
    FeatureFlagsModule,
    LanguageModule,
    HuiLoadingModule,
    SharedAnalyticsModule,
  ],
  declarations: [SignUpByGoogleComponent, SignUpByFacebookComponent],
  exports: [SignUpByGoogleComponent, SignUpByFacebookComponent],
})
export class SocialSignUpModule {}

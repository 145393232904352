import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamSettingsQuery } from './team-settings.query';

export interface SavesTeamSettingsCommand {
  saveTeamSettings(settings: TeamSettingsQuery): Observable<boolean>;
}

export const SAVES_TEAM_SETTINGS_COMMAND = new InjectionToken<SavesTeamSettingsCommand>(
  'SAVES_TEAM_SETTINGS_COMMAND',
);

import { PaymentPlanPeriod } from './payment-plan';

export type PlanTypeCollection<T> = {
  [key in PaymentPlanPeriod]: T;
};

// TODO: Price in cents?
export class PaymentPlanPriceVO {
  public readonly value: number;
  constructor(
    private _pricePlans: PlanTypeCollection<number>,
    public readonly selectedPlan: PaymentPlanPeriod,
    public readonly currency: string,
  ) {
    this.value = _pricePlans[selectedPlan];
  }

  cloneFromPlans(planType: PaymentPlanPeriod): PaymentPlanPriceVO {
    return new PaymentPlanPriceVO(this._pricePlans, planType, this.currency);
  }
}

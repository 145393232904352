import { GetsTeamSettingsFormErrorKeyDtoPort } from '../../../../application/ports/secondary/gets-team-settings-form-error-key-dto.port';
import { Injectable } from '@angular/core';

@Injectable()
export class GetTeamSettingsFormErrorService implements GetsTeamSettingsFormErrorKeyDtoPort {
  // TODO: split to config
  private readonly _errors: Map<string, string[]> = new Map([
    ['zipCode', ['invalidField', 'required']],
    ['vatNumber', ['invalidField', 'required']],
  ]);
  private readonly _fieldErrorsKeys: Map<string, Map<string, string>> = new Map([
    [
      'zipCode',
      new Map([
        ['invalidField', '_invalid_zip_code'],
        ['required', '_this_field_is_required'],
      ]),
    ],
    [
      'vatNumber',
      new Map([
        ['invalidField', '_invalid_vat_number'],
        ['required', '_this_field_is_required'],
      ]),
    ],
  ]);

  getErrorKey(fieldName: string, controlErrors: { [p: string]: boolean }): string {
    const allErrors = this._errors
      .get(fieldName)
      .filter(errorName => controlErrors && !!controlErrors[errorName]);

    return allErrors.length > 0
      ? this._getErrorTranslationKeyForField(fieldName, allErrors[0])
      : '_wrong_format';
  }

  private _getErrorTranslationKeyForField(fieldName: string, error: string): string | undefined {
    return this._fieldErrorsKeys.get(fieldName).get(error) || '_wrong_format';
  }
}

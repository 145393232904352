<lib-attention-message-widget *ngIf="hasWarning$ | async"> </lib-attention-message-widget>
<lib-impersonation-widget> </lib-impersonation-widget>
<section class="bg-transparent layout-container h-100 d-flex flex-1">
  <article class="layout-body d-flex flex-1 flex-column">
    <main
      class="d-flex flex-column flex-1-0 w-100 overflow-x-hidden overflow-y-auto h-100"
      id="layout-body-scroll-container"
    >
      <ng-content select="[routerOutlet]"></ng-content>
    </main>
  </article>
</section>

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CompanyDetailsDto } from './company-details.dto';

export const GETS_COMPANY_DETAILS_DTO_PORT = new InjectionToken<GetsCompanyDetailsDtoPort>(
  'GETS_COMPANY_DETAILS_DTO_PORT',
);

export interface GetsCompanyDetailsDtoPort {
  getCompanyDetails(id: string): Observable<CompanyDetailsDto>;
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientShortcutDto } from './client-shortcuts.dto';

export interface GetsAllShortcutsForOneClientDtoPort {
  getAll(siteId: string): Observable<ClientShortcutDto[]>;
}

export const GETS_ALL_SHORTCUTS_FOR_ONE_CLIENT_DTO_PORT =
  new InjectionToken<GetsAllShortcutsForOneClientDtoPort>(
    'GETS_ALL_SHORTCUTS_FOR_ONE_CLIENT_DTO_PORT',
  );

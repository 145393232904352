import { DataLayerTrackingMapper } from '../../domain/data-layer-tracking-mapper';
import { PlanSelectedEvent } from '@app.cobiro.com/core/events';
import { DataLayerTrackingData } from '../../domain/data-layer-tracking-data';
import { Injectable } from '@angular/core';

@Injectable()
export class DataLayerPlanSelectedEventMapper implements DataLayerTrackingMapper {
  eventClass = PlanSelectedEvent;
  mapEvent(event: PlanSelectedEvent): DataLayerTrackingData {
    return {
      eventName: '/Payment - Plan selected',
      eventData: {
        context: event.featureId,
        upgrade_plan: event.planId.split('-')[0],
        upgrade_plan_period: event.planId.split('-')[1],
      },
    };
  }
}

<div
  class="position-relative cs-max-width-625-xs d-flex flex-column mx-auto cs-ph-40 cs-pb-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="d-flex flex-column w-100 h-100 justify-content-between">
    <div class="d-flex flex-column cs-mb-30">
      <h3 class="cs-title-4 cs-mb-15" data-selector="cobiro-pro-add-payment-card-header">
        {{ 'cobiro_pro_team_settings_payment_methods' | language }}
      </h3>
      <p class="cs-text-1" data-selector="cobiro-pro-add-payment-card-sub-header">
        {{ 'cobiro_pro_team_settings_payment_methods_desc' | language }}
      </p>
    </div>
    <lib-cobiro-pro-payment-method-form
      class="w-100"
      (formGroupChange)="onFormGroupChange($event)"
      (validityChanged)="onValidityChange($event)"
    ></lib-cobiro-pro-payment-method-form>
  </div>
  <div class="d-flex justify-content-between cs-mt-20 w-100">
    <button
      class="cs-btn cs-btn-stroked"
      data-selector="cobiro-pro-add-payment-card-back-button"
      (click)="goToPreviusStep()"
    >
      <mat-icon svgIcon="arrow-left"></mat-icon>
      {{ '_back' | language }}
    </button>
    <button
      class="cs-btn cs-btn-primary"
      data-selector="cobiro-pro-add-payment-card-submit-button"
      [class.is-loading]="isProcessing$ | async"
      [disabled]="(isInvalid$ | async) && (isProcessing$ | async)"
      (click)="onFormSubmit()"
    >
      {{ '_continue' | language }}
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</div>

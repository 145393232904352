import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductInfoComponent } from './product-info.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [ProductInfoComponent],
  imports: [CommonModule, LanguageModule, MatDialogModule, MatIconModule, HuiLoaderContainerModule],
  exports: [ProductInfoComponent],
})
export class ProductInfoComponentModule {}

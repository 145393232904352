import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationInfoDto } from '../secondary/integration-info.dto';

export const SETS_INTEGRATION_QUERY = new InjectionToken<SetsIntegrationQueryPort>(
  'SETS_INTEGRATION_QUERY',
);

export interface SetsIntegrationQueryPort {
  setIntegration(integration: IntegrationInfoDto): Observable<boolean>;
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Injectable } from '@angular/core';
import {
  CobiroProCompanyDeletedEvent,
  WorkspaceQuantityChangedEvent,
} from '@app.cobiro.com/core/events';
import { Router } from '@angular/router';

@Injectable()
export class CobiroProCompanyDeletedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProCompanyDeletedEvent];
  strategy = ongoingEventsOrchestrationStrategy;
  constructor(private readonly _router: Router) {}

  handle([event]: [CobiroProCompanyDeletedEvent]): void {
    this._router.navigate(['/', 'connect']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HuiStepperComponent } from './hui-stepper/hui-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  imports: [CommonModule, CdkStepperModule],
  declarations: [HuiStepperComponent],
  exports: [HuiStepperComponent, CdkStepperModule],
})
export class HuiStepperModule {}

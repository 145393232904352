import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationErrorDto } from './integration-error.dto';

export const GETS_INTEGRATION_ERROR_DTO = new InjectionToken<GetsIntegrationErrorDtoPort>(
  'GETS_INTEGRATION_ERROR_DTO',
);

export interface GetsIntegrationErrorDtoPort {
  getIntegrationError(siteId: string): Observable<IntegrationErrorDto>;
}

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';

export interface WorkspaceIdGetter {
  getWorkspaceId(): string | null;
}

export const WORKSPACE_ID_GETTER = new InjectionToken<WorkspaceIdGetter>('WORKSPACE_ID_GETTER');

@Injectable()
export class StorageBasedWorkspaceIdGetter implements WorkspaceIdGetter {
  constructor(@Inject(STORAGE) private _localStorage: Storage) {}

  getWorkspaceId(): string | null {
    const workspaceId = JSON.parse(this._localStorage.getItem('lib-pro-context'))?.selectedWorkspace
      ?.id;

    return workspaceId ?? null;
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { InitsCreateProfileFlowCommand } from './inits-create-profile-flow.command';

export const INITS_CREATE_PROFILE_FLOW_COMMAND =
  new InjectionToken<InitsCreateProfileFlowCommandPort>('INITS_CREATE_PROFILE_FLOW_COMMAND');

export interface InitsCreateProfileFlowCommandPort {
  initCreateProfileFlow(command: InitsCreateProfileFlowCommand): Observable<void>;
}

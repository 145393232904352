import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialAuth } from '../../../domain/signs-up-by';
import { SignsUpByGoogleCommand } from './signs-up-by-google.command';

export const SIGNS_UP_BY_GOOGLE_COMMAND = new InjectionToken<SignsUpByGoogleCommandPort>(
  'SIGNS_UP_BY_GOOGLE_COMMAND',
);

export interface SignsUpByGoogleCommandPort {
  signUpByGoogle(command: SignsUpByGoogleCommand): Observable<SocialAuth | null>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface DisablesProModeCommandPort {
  disableProMode(): Observable<boolean>;
}

export const DISABLES_PRO_MODE_COMMAND = new InjectionToken<DisablesProModeCommandPort>(
  'DISABLES_PRO_MODE_COMMAND',
);

import { NgModule } from '@angular/core';
import { HttpClientShortcutsService } from './http-client-shortcuts.service';
import { GETS_ALL_SHORTCUTS_FOR_ONE_CLIENT_DTO_PORT } from '../../../application/ports/secondary/gets-all-shortcuts-for-one-client.dto-port';

@NgModule({
  providers: [
    HttpClientShortcutsService,
    {
      provide: GETS_ALL_SHORTCUTS_FOR_ONE_CLIENT_DTO_PORT,
      useExisting: HttpClientShortcutsService,
    },
  ],
})
export class HttpClientShortcutsServiceModule {}

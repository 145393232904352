import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroCssIntegrationComponent } from './cobiro-css-integration.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { GoogleCssIntegrationStateModule } from '../../../../application/state/google-css-integration.state-module';
import { IntegrationErrorComponentModule } from '../integration-error/integration-error.component-module';
import { IntegrationPausedComponentModule } from '../integration-paused/integration-paused.component-module';
import { IntegrationSucceededComponentModule } from '../integration-succeeded/integration-succeeded.component-module';
import { SwitchInProgressComponentModule } from '../switch-in-progress/switch-in-progress.component-module';
import { IntegrationAccountFormComponentModule } from '../integration-account-form/integration-account-form.component-module';
import { IntegrationActionButtonsComponentModule } from '../integration-action-buttons/integration-action-buttons.component-module';
import { SwitchExistingIntegrationAccountFormComponentModule } from '../switch-existing-integration-account-form/switch-existing-integration-account-form.component-module';

@NgModule({
  declarations: [CobiroCssIntegrationComponent],
  imports: [
    CommonModule,
    LanguageModule,
    HuiLoaderContainerModule,
    ReactiveFormsModule,
    HuiInputModule,
    HuiStepperModule,
    MatDialogModule,
    MatExpansionModule,
    IntegrationAccountFormComponentModule,
    IntegrationActionButtonsComponentModule,
    IntegrationErrorComponentModule,
    IntegrationPausedComponentModule,
    IntegrationSucceededComponentModule,
    GoogleCssIntegrationStateModule,
    SwitchExistingIntegrationAccountFormComponentModule,
    SwitchInProgressComponentModule,
  ],
  exports: [CobiroCssIntegrationComponent],
})
export class CobiroCssIntegrationComponentModule {}

import { InjectionToken } from '@angular/core';
import { ProductDTO } from './product.dto';

export const GETS_PRODUCT_LIST_DTO = new InjectionToken<GetsProductListDtoPort>(
  'GETS_PRODUCT_LIST_DTO',
);

export interface GetsProductListDtoPort {
  getProducts(): ProductDTO[];
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const IS_EDIT_ACTIVE_QUERY = new InjectionToken<IsEditActiveQueryPort>(
  'IS_EDIT_ACTIVE_QUERY',
);

export interface IsEditActiveQueryPort {
  isEditActive(): Observable<boolean>;
}

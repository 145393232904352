<div
  *ngIf="data?.client as client"
  class="d-flex flex-column cs-p-40 justify-content-between align-items-center cs-bg-shade-0 cs-min-height-100vh-xs"
  data-selector="display-client-info-dialog"
>
  <mat-icon
    svgIcon="close"
    class="position-absolute cs-top-30 cs-right-30 cs-cursor-pointer cs-z-index-1"
    data-selector="display-client-info-dialog-close-button"
    (click)="onClose()"
  ></mat-icon>

  <form class="cs-form d-flex flex-column w-100 h-100 justify-content-between">
    <div class="d-flex flex-column">
      <h3 class="cs-title-4 cs-mb-20">
        {{ 'cobiro_pro_display_client_info_modal_title' | language }}
      </h3>

      <div class="cs-mb-30">
        <label class="cs-label">{{ 'cobiro_pro_avatar' | language }}</label>
        <img
          *ngIf="client.avatar; else noAvatar"
          class="cs-width-120-xs cs-height-120-xs border-radius-preview cs-br-50-pc"
          data-selector="client-avatar"
          [src]="client.avatar"
        />
        <ng-template #noAvatar>
          <span data-selector="client-no-avatar">{{
            'cobiro_pro_client_no_avatar' | language
          }}</span>
        </ng-template>
      </div>

      <div class="position-relative cs-mb-30 cs-mt-10">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_client_form_company_name' | language }}
        </label>
        <input
          class="cs-input"
          data-selector="client-company-name"
          disabled="true"
          [value]="client.companyName"
        />
      </div>

      <div class="position-relative cs-mb-30">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_client_form_contact_person' | language }}
        </label>
        <input
          class="cs-input"
          data-selector="client-contact-person"
          disabled="true"
          [value]="client.contactPerson"
        />
      </div>

      <div class="position-relative cs-mb-30">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_client_form_contact_email' | language }}
        </label>
        <a
          class="cs-link"
          data-selector="client-contact-email"
          [href]="'mailto:' + client.contactEmail"
          >{{ client.contactEmail }}</a
        >
      </div>

      <div class="position-relative cs-mb-30">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_client_form_contact_phone' | language }}
        </label>
        <input
          class="cs-input"
          data-selector="client-contact-phone"
          disabled="true"
          [value]="client.contactPhone"
        />
      </div>
    </div>
  </form>
</div>

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IS_INVITED_USER_QUERY = new InjectionToken<IsInvitedUserQueryPort>(
  'IS_INVITED_USER_QUERY',
);

export interface IsInvitedUserQueryPort {
  isInvitedUser(): Observable<boolean>;
}

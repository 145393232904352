import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, map, switchMap, take, tap } from 'rxjs';
import { CancelsIntegrationCommand } from '../../../../application/ports/primary/cancels-integration.command';
import {
  CANCELS_INTEGRATION_COMMAND,
  CancelsIntegrationCommandPort,
} from '../../../../application/ports/primary/cancels-integration.command-port';
import {
  GetsCssMerchantIdsQueryPort,
  GETS_CSS_MERCHANT_IDS_QUERY_PORT,
} from '../../../../application/ports/primary/gets-css-merchant-ids.query-port';
import {
  GETS_INTEGRATION_QUERY,
  GetsIntegrationQueryPort,
} from '../../../../application/ports/primary/gets-integration.query-port';
import {
  SwitchesCssDomainCommandPort,
  SWITCHES_CSS_DOMAIN_COMMAND,
} from '../../../../application/ports/primary/switches-css-domain.command-port';
import {
  UpgradesIntegrationQueryPort,
  UPGRADES_INTEGRATION_QUERY,
} from '../../../../application/ports/primary/upgrades-integration.query-port';

@Component({
  selector: 'lib-integration-succeeded',
  templateUrl: './integration-succeeded.component.html',
  styleUrls: ['./integration-succeeded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationSucceededComponent {
  cssDomain = new UntypedFormControl('', Validators.required);
  private readonly _isProcessing$ = new BehaviorSubject<boolean>(false);
  readonly isProcessing$ = this._isProcessing$.asObservable();
  readonly integration$ = this._getsIntegrationQuery.getIntegrationQuery();
  readonly cssMerchantIds$ = this._getsCssMerchantIdsQueryPort.getCssMerchantIds().pipe(
    switchMap(cssMerchantIds =>
      this.integration$.pipe(
        take(1),
        tap(integration => {
          this.cssDomain.patchValue(integration.cssDomainId);
          this.cssDomain.updateValueAndValidity();
        }),
        map(() => cssMerchantIds),
      ),
    ),
  );

  constructor(
    @Inject(UPGRADES_INTEGRATION_QUERY)
    private readonly _upgradesIntegrationQueryPort: UpgradesIntegrationQueryPort,
    @Inject(CANCELS_INTEGRATION_COMMAND)
    private readonly _cancelsIntegrationCommand: CancelsIntegrationCommandPort,
    @Inject(GETS_INTEGRATION_QUERY)
    private readonly _getsIntegrationQuery: GetsIntegrationQueryPort,
    @Inject(GETS_CSS_MERCHANT_IDS_QUERY_PORT)
    private readonly _getsCssMerchantIdsQueryPort: GetsCssMerchantIdsQueryPort,
    @Inject(SWITCHES_CSS_DOMAIN_COMMAND)
    private readonly _switchesCssDomainCommandPort: SwitchesCssDomainCommandPort,
  ) {}

  // @TODO wj refactor to place it not here (maybe create query with integration status)
  // @TODO and based on that decide which action button to show
  async onCancelIntegrationClicked(): Promise<void> {
    await firstValueFrom(
      this._cancelsIntegrationCommand.cancelIntegration(new CancelsIntegrationCommand()),
    );
  }

  async onUpgradeIntegrationClicked(id: string): Promise<void> {
    await firstValueFrom(
      this._upgradesIntegrationQueryPort.upgradeIntegration({ integrationId: id }),
    );
  }

  async onSwitchCssDomainClicked(): Promise<void> {
    this._isProcessing$.next(true);
    await firstValueFrom(
      this._switchesCssDomainCommandPort.switchCssDomain({ cssDomainId: this.cssDomain.value }),
    ).finally(() => {
      this._isProcessing$.next(false);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProChangePasswordComponent } from './change-password.component';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordFormModule } from '@app.cobiro.com/user/shared';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CobiroProChangePasswordComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', component: CobiroProChangePasswordComponent }]),
    LanguageModule,
    ReactiveFormsModule,
    PasswordFormModule,
    MatIconModule,
  ],
  exports: [CobiroProChangePasswordComponent],
})
export class CobiroProChangePasswordModule {}

import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UpgradePlanProcess } from '../../upgrade-plan-flow/upgrade-plan.process';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-discount-code',
  templateUrl: './discount-code.component.html',
  styleUrls: ['./discount-code.component.scss'],
})
export class DiscountCodeComponent {
  discountCodeProcessingInProgress$: Observable<boolean> =
    this._process.discountCodeProcessingInProgress$.asObservable();

  discountCodeForm = new UntypedFormGroup({
    discountCode: new UntypedFormControl(),
    isValid: new UntypedFormControl(false),
  });

  constructor(private _process: UpgradePlanProcess) {}

  onDiscountRemove() {
    this._process.clearDiscountCode();
    this.discountCodeForm.reset();
    this.discountCodeForm.get('discountCode').enable();
  }

  onDiscountApply() {
    this._process
      .checkDiscountCode(this.discountCodeForm.get('discountCode').value)
      .subscribe(isValid => {
        this.discountCodeForm.patchValue({ isValid });
        this.discountCodeForm.get('discountCode').setErrors({ isValid });
        if (isValid) {
          this.discountCodeForm.get('discountCode').disable();
        }
      });
  }
}

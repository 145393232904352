import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { UserIsInvitedEvent } from '../../../application/events/user-is-invited.event';
import { UserIsInvitedDispatcherPort } from '../../../application/ports/primary/user-is-invited.dispatcher-port';
@Injectable()
export class UserIsInvitedEventDispatcher implements UserIsInvitedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { isUserInvited: boolean }) {
    this._applicationBus.dispatch(new UserIsInvitedEvent(event.isUserInvited));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingPreferencesComponent } from './marketing-preferences.component';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [MarketingPreferencesComponent],
  imports: [
    CommonModule,
    LanguageModule,
    ReactiveFormsModule,
    RouterModule.forChild([{ path: '', component: MarketingPreferencesComponent }]),
  ],
  exports: [MarketingPreferencesComponent],
})
export class MarketingPreferencesComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { NumberOfClientsQuery } from './number-of-clients.query';

export const GETS_NUMBER_OF_CLIENTS_QUERY = new InjectionToken<GetsNumberOfClientsQueryPort>(
  'GETS_NUMBER_OF_CLIENTS_QUERY',
);

export interface GetsNumberOfClientsQueryPort {
  getAvailableNumberOfClients(): Observable<NumberOfClientsQuery[]>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerWidgetComponent } from './label-manager-widget.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [LabelManagerWidgetComponent],
  imports: [
    CommonModule,
    LanguageModule,
    HuiLoaderContainerModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  exports: [LabelManagerWidgetComponent],
})
export class LabelManagerWidgetComponentModule {}

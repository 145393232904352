import { Inject, Injectable } from '@angular/core';
import {
  GETS_COBIRO_PRO_CONTEXT_DTO,
  GetsCobiroProContextDtoPort,
  TEAM_ID_GETTER,
  TeamIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { BASE_URL } from '@app.cobiro.com/core/utils';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddsClientDtoPort } from '../../../application/ports/secondary/adds-client.dto-port';
import { ClientDTO } from '../../../application/ports/secondary/client.dto';
import { DeletesClientDtoPort } from '../../../application/ports/secondary/deletes-client.dto-port';
import { GetsAllClientDtoPort } from '../../../application/ports/secondary/gets-all-client.dto-port';
import { SetsClientDtoPort } from '../../../application/ports/secondary/sets-client.dto-port';

interface ClientAttributes {
  siteId: string;
  sitePublicId: string;
  cssIntegrationStatus: 'paused' | 'active' | 'pending' | null;
  merchantId: string | null;
  teamId: string;
  companyName: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  url: string;
  avatarUrl: string;
  source: string;
  plan: string;
  archived: boolean;
}

@Injectable()
export class HttpClientsService
  implements GetsAllClientDtoPort, AddsClientDtoPort, SetsClientDtoPort, DeletesClientDtoPort
{
  constructor(
    private _client: GatewayClient,
    @Inject(GETS_COBIRO_PRO_CONTEXT_DTO) private _context: GetsCobiroProContextDtoPort,
    @Inject(BASE_URL) private _baseUrl,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
  ) {}

  getAll(criterion: Partial<ClientDTO> = {}): Observable<ClientDTO[]> {
    return this._client
      .get<HasDataCollection<ClientAttributes>>(`v1/sites/teams/${criterion.teamId}/clients`)
      .pipe(
        map((clients: HasDataCollection<ClientAttributes>) => {
          return clients.data
            .filter(data => !data.attributes.archived)
            .map(data => {
              const siteId = data.attributes.siteId;
              return {
                id: data.id || '', // TODO: undefined
                siteId: siteId ? String(siteId) : siteId,
                teamId: criterion.teamId,
                cssIntegrationStatus: data.attributes.cssIntegrationStatus,
                merchantSiteId: data.attributes.merchantId,
                sitePublicId: data.attributes.sitePublicId,
                companyName: data.attributes.companyName,
                contactPerson: data.attributes.contactPerson,
                contactEmail: data.attributes.contactEmail,
                contactPhone: data.attributes.contactPhone,
                url: data.attributes.url,
                source: data.attributes.source,
                avatar: data.attributes.avatarUrl,
                plan: this._extractPlanName(data.attributes.plan),
                installedApps: [],
                archived: data.attributes.archived,
              };
            });
        }),
      );
  }

  add(item: Omit<ClientDTO, 'sitePublicId' | 'plan' | 'installedApps'>): Observable<boolean> {
    const payload: HasData<
      Omit<
        ClientAttributes,
        'cssIntegrationStatus' | 'merchantId' | 'teamId' | 'sitePublicId' | 'plan' | 'archived'
      >
    > = {
      data: {
        id: item.id,
        type: 'clients',
        attributes: {
          siteId: item.siteId,
          companyName: item.companyName,
          contactPerson: item.contactPerson,
          contactPhone: item.contactPhone,
          contactEmail: item.contactEmail,
          avatarUrl: item.avatar || null,
          url: item.url,
          source: item.source,
        },
      },
    };
    return this._client
      .post(`v1/sites/teams/${item.teamId}/clients`, payload)
      .pipe(map(() => true));
  }

  set(
    item: Pick<
      ClientDTO,
      'companyName' | 'contactPerson' | 'contactEmail' | 'contactPhone' | 'teamId' | 'id' | 'avatar'
    >,
  ): Observable<void> {
    return this._client.patch(`v1/sites/teams/${item.teamId}/clients/${item.id}`, {
      data: {
        type: 'clients',
        attributes: {
          companyName: item.companyName,
          contactPerson: item.contactPerson,
          contactEmail: item.contactEmail,
          contactPhone: item.contactPhone,
          avatarUrl: item.avatar || null,
        },
      },
    });
  }

  deleteClient(dto: Pick<ClientDTO, 'id' | 'plan'>): Observable<void> {
    return this._client
      .put<HasData<never>>(
        `v1/sites/teams/${this._teamIdGetter.getTeamId()}/clients/${dto.id}/archive`,
        {},
      )
      .pipe(map(() => void 0));
  }

  private _extractPlanName(plan: string): string {
    if (!plan) {
      return 'free';
    }
    return plan.includes('-') ? plan.substr(0, plan.indexOf('-')) : plan;
  }
}

import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { CobiroProContextQuery } from '../../../application/ports/primary/cobiro-pro-context.query';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
} from '../../../application/ports/primary/gets-cobiro-pro-context.query-port';

@Injectable()
export class IsProModeGuard {
  private readonly _resolvedOnce$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroContextQuery: GetsCobiroProContextQueryPort,
    private readonly _router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._checkProMode();
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this._checkProMode();
  }

  private _checkProMode(): Observable<boolean | UrlTree> {
    return this._getsCobiroContextQuery.getContext().pipe(
      withLatestFrom(this._resolvedOnce$),
      concatMap(([cobiroProContext, resolvedOnce]: [CobiroProContextQuery, boolean]) => {
        if (resolvedOnce) {
          return of(true);
        }

        this._resolvedOnce$.next(true);

        if (cobiroProContext.proModeEnabled && !cobiroProContext.selectedTeamId) {
          return of(this._router.createUrlTree(['/connect']));
        }

        return of(true);
      }),
    );
  }
}

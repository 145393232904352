import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CancelSubscriptionImmediatelySuccessModalComponent } from './cancel-subscription-immediately-success-modal.component';

@NgModule({
  imports: [CommonModule, MatIconModule, LanguageModule],
  declarations: [CancelSubscriptionImmediatelySuccessModalComponent],
  exports: [CancelSubscriptionImmediatelySuccessModalComponent],
})
export class CancelSubscriptionImmediatelySuccessModalComponentModule {}

import { UploadImageDto } from './upload-image.dto';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Image } from '../../../../../media-center-image';

export interface UploadsImageDtoPort {
  uploadImage(image: UploadImageDto): Observable<Image>;
}

export const UPLOAD_IMAGE_DTO = new InjectionToken<UploadsImageDtoPort>('UPLOAD_IMAGE_DTO');

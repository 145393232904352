import { BehaviorSubject, Observable } from 'rxjs';
import { CountryQuery } from '../../query/country.query';
import { Inject, Injectable } from '@angular/core';
import { Country, GETS_COUNTRIES, GetsCountries } from '../../domain/gets-countries';
import { filter, map } from 'rxjs/operators';
import { PlanEstimate } from '../../domain/plan-estimate';
import { GETS_PLAN_ESTIMATE, GetsPlanEstimate } from '../../domain/gets-plan-estimate';
import { ReactiveSingleValueStorage } from '@app.cobiro.com/core/storage';
import { PLAN_ESTIMATE_STORAGE } from '../../domain/storage/plan-estimate.storage';
import { PlanEstimateQuery } from '../../query/plan-estimate.query';
import { SELECTED_PLAN_STORAGE } from '../../domain/storage/selected-plan.storage';
import { SelectedPlan } from '../../domain/selected-plan';
import { DISCOUNT_STORAGE } from '../../domain/storage/discount.storage';
import { Discount } from '../../domain/discount';
import { EstimatePlanCommand } from './estimate-plan.command';
import { EstimatePlanDto } from '../../domain/estimate-plan.dto';
import { EstimateData } from '../../../upgrade/ui/upgrade-plan-flow/estimate-data';

export interface HandlesPlanEstimate {
  countries$: Observable<CountryQuery[]>;
  planEstimate$: Observable<PlanEstimateQuery>;
  estimate(data: EstimateData): void;
  refreshEstimate(): void;
  clear(): void;
}

@Injectable()
export class PlanEstimateState implements HandlesPlanEstimate {
  private _countries = new BehaviorSubject<Country[]>([]);
  private _selectedCountryCode: string;

  readonly countries$: Observable<CountryQuery[]> = this._countries
    .asObservable()
    .pipe(map(countries => countries.map(country => ({ code: country.code, name: country.name }))));

  readonly planEstimate$: Observable<PlanEstimateQuery> = this._planEstimateStorage.select().pipe(
    filter(estimate => !!estimate),
    map(estimate => ({
      vatValue: estimate.vatValue,
      vatPercentage: estimate.vatPercentage,
      nett: estimate.nett,
      gross: estimate.gross,
      originalGrossPrice: estimate.hasValidDiscountCode ? estimate.grossValueBeforeDiscount : null,
    })),
  );

  constructor(
    @Inject(GETS_COUNTRIES) private _getsCountries: GetsCountries,
    @Inject(GETS_PLAN_ESTIMATE) private _getsPlanEstimate: GetsPlanEstimate,
    @Inject(SELECTED_PLAN_STORAGE)
    private _selectedPlanStorage: ReactiveSingleValueStorage<SelectedPlan>,
    @Inject(PLAN_ESTIMATE_STORAGE)
    private _planEstimateStorage: ReactiveSingleValueStorage<PlanEstimate>,
    @Inject(DISCOUNT_STORAGE)
    private _discountCodeStorage: ReactiveSingleValueStorage<Discount>,
  ) {
    this._getsCountries.get().subscribe(countries => {
      this._countries.next(countries);
    });
  }

  estimate(command: EstimatePlanCommand): void {
    this._selectedCountryCode = command.countryCode;
    const selectedPlanId = this._selectedPlanStorage.get().id;
    if (!selectedPlanId) {
      throw new Error('Selected plan id is missing in estimate');
    }

    const dto: EstimatePlanDto = {
      planId: selectedPlanId,
      countryCode: command.countryCode,
      vatNumber: command.vatNumber,
      discountCode: this._discountCodeStorage.get()?.code,
    };

    this._getsPlanEstimate.get(dto).subscribe(estimate => this._planEstimateStorage.save(estimate));
  }

  refreshEstimate() {
    if (this._selectedCountryCode) {
      this.estimate({ countryCode: this._selectedCountryCode });
    }
  }

  clear() {
    this._planEstimateStorage.clear();
    this._selectedCountryCode = null;
  }
}

export class NonNegativeVO {
  private readonly _value: number;
  constructor(_value: number | string) {
    if (isNaN(+_value) || +_value < 0 || Array.isArray(_value)) {
      throw new Error('Invalid argument: non-negative values allowed');
    }
    this._value = +_value;
  }
  valueOf(): number {
    return this._value;
  }

  toString(): string {
    return this._value.toString(10);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { INPUT_ERRORS } from '@app.cobiro.com/shared/hui/input';
import { defaultInputTranslationErrors } from '@app.cobiro.com/shared/hui/input/directives/input-error/default-inputs-translation-errors';
import { HuiInputErrorDirective } from '@app.cobiro.com/shared/hui/input/directives/input-error/hui-input-error.directive';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';
import { delay, filter, Subject, takeUntil, tap } from 'rxjs';
import {
  GETS_INTEGRATION_ERROR_QUERY,
  GetsIntegrationErrorQueryPort,
} from '../../../../application/ports/primary/gets-integration-error.query-port';
import {
  GETS_INTEGRATION_PAUSE_STATUS_QUERY,
  GetsIntegrationPauseStatusQueryPort,
} from '../../../../application/ports/primary/gets-integration-pause-status.query-port';
import {
  GETS_INTEGRATION_STATUS_QUERY,
  GetsIntegrationStatusQueryPort,
} from '../../../../application/ports/primary/gets-integration-status.query-port';
import {
  GETS_OWNED_INTEGRATION_PANEL_QUERY,
  GetsOwnedIntegrationPanelQueryPort,
} from '../../../../application/ports/primary/gets-owned-integration-panel.query-port';
import {
  GETS_SWITCHED_INTEGRATION_PANEL_QUERY,
  GetsSwitchedIntegrationPanelQueryPort,
} from '../../../../application/ports/primary/gets-switched-integration-panel.query-port';
import {
  INITS_STEPPER_COMMAND,
  InitsStepperCommandPort,
} from '../../../../application/ports/primary/inits-stepper.command-port';
import {
  IS_CREATING_INTEGRATION_QUERY,
  IsCreatingIntegrationQueryPort,
} from '../../../../application/ports/primary/is-integration-creating.query-port';
import {
  IS_EDITING_INTEGRATION_QUERY,
  IsEditingIntegrationQueryPort,
} from '../../../../application/ports/primary/is-integration-editing.query-port';
import { LoadsIntegrationCommand } from '../../../../application/ports/primary/loads-integration.command';
import {
  LOADS_INTEGRATION_COMMAND,
  LoadsIntegrationCommandPort,
} from '../../../../application/ports/primary/loads-integration.command-port';

@Component({
  selector: 'lib-cobiro-css-integration',
  templateUrl: './cobiro-css-integration.component.html',
  styleUrls: ['./cobiro-css-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HuiInputErrorDirective,
    {
      provide: INPUT_ERRORS,
      useValue: {
        ...defaultInputTranslationErrors,
      },
    },
  ],
})
export class CobiroCssIntegrationComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _destroyed$ = new Subject<void>();

  @ViewChild(HuiStepperComponent, { static: false }) huiStepper: HuiStepperComponent;

  readonly isCreating$ = this._isEditingQuery.isEditing();
  readonly isEditing$ = this._isCreatingQuery.isCreating();
  readonly isPaused$ = this._getsIntegrationPauseStatusQuery.getIntegrationPauseStatus();
  readonly integrationStatus$ = this._getsIntegrationStatusQuery.getIntegrationStatus();
  readonly isOwnedPanelExpanded$ = this._getsOwnedIntegrationPanelQuery.isOwnedExpanded();
  readonly isSwitchedPanelExpanded$ = this._getsSwitchedIntegrationPanelQuery.isSwitchedExpanded();

  isErrored = false;

  constructor(
    @Inject(GETS_INTEGRATION_ERROR_QUERY)
    private readonly _getsIntegrationErrorQuery: GetsIntegrationErrorQueryPort,
    @Inject(GETS_INTEGRATION_PAUSE_STATUS_QUERY)
    private readonly _getsIntegrationPauseStatusQuery: GetsIntegrationPauseStatusQueryPort,
    @Inject(GETS_INTEGRATION_STATUS_QUERY)
    private readonly _getsIntegrationStatusQuery: GetsIntegrationStatusQueryPort,
    @Inject(GETS_OWNED_INTEGRATION_PANEL_QUERY)
    private readonly _getsOwnedIntegrationPanelQuery: GetsOwnedIntegrationPanelQueryPort,
    @Inject(GETS_SWITCHED_INTEGRATION_PANEL_QUERY)
    private readonly _getsSwitchedIntegrationPanelQuery: GetsSwitchedIntegrationPanelQueryPort,
    @Inject(LOADS_INTEGRATION_COMMAND)
    private readonly _loadsIntegrationCommand: LoadsIntegrationCommandPort,
    @Inject(IS_EDITING_INTEGRATION_QUERY)
    private readonly _isEditingQuery: IsEditingIntegrationQueryPort,
    @Inject(IS_CREATING_INTEGRATION_QUERY)
    private readonly _isCreatingQuery: IsCreatingIntegrationQueryPort,
    @Inject(INITS_STEPPER_COMMAND)
    private readonly _initStepperCommand: InitsStepperCommandPort,
  ) {}

  ngOnInit(): void {
    this._loadsIntegrationCommand
      .loadIntegration(new LoadsIntegrationCommand())
      .pipe(takeUntil(this._destroyed$))
      .subscribe();
    this.checkIfIntegrationIsErrored();
  }

  ngAfterViewInit(): void {
    this.listenToIntegrationStatusChange();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private checkIfIntegrationIsErrored(): void {
    this._getsIntegrationErrorQuery
      .getIntegrationErrorQuery()
      .pipe(filter(Boolean), takeUntil(this._destroyed$))
      .subscribe(res => {
        this.isErrored = true;
      });
  }

  private listenToIntegrationStatusChange(): void {
    this._getsIntegrationStatusQuery
      .getIntegrationStatus()
      .pipe(
        filter(query => query && !query.isSuccess()),
        delay(50),
        tap(query => {
          if (query.isPending()) {
            this.huiStepper.next();
          }
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => {
        this._initStepperCommand.init(this.huiStepper);
      });
  }
}

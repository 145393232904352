<div class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center">
  <div
    [class.cs-emoji-circle]="data?.emoji"
    [class.cs-mb-30]="data?.emoji"
    [ngClass]="data?.emojiBackground"
  >
    {{ data?.emoji }}
  </div>
  <h3 class="cs-title-4 cs-mb-20 text-center">
    {{ data.title | language }}
  </h3>
  <p class="cs-text-1 cs-mb-30 text-center cs-white-space-pre-line">{{ data.text | language }}</p>
  <button
    *ngIf="data?.buttonText"
    class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-primary"
    data-selector="hui-simple-confirmation-popup-btn"
    matDialogClose
  >
    {{ data.buttonText | language }}
  </button>
</div>

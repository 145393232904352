import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface ChangesPassword {
  change(
    email: string,
    password: string,
    passwordConfirmation: string,
    token: string,
  ): Observable<boolean>;
}

export const CHANGES_PASSWORD = new InjectionToken<ChangesPassword>('CHANGES_PASSWORD');

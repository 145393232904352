import { NgModule } from '@angular/core';
import { HomeState } from './home.state';
import { GETS_TODOS_QUERY } from '../ports/primary/gets-todos.query';
import { GETS_USER_DETAILS_QUERY_PORT } from '../ports/primary/gets-user-details.query-port';
import { CURRENT_CLIENTS_QUANTITY_QUERY_PORT } from '../ports/primary/current-clients-quantity.query-port';
import { CURRENT_MEMBERS_QUANTITY_QUERY_PORT } from '../ports/primary/current-members-quantity.query-port';
import { CURRENT_UPGRADES_QUANTITY_QUERY_PORT } from '../ports/primary/current-upgrades-quantity.query-port';
import { SHOW_OVERVIEW_SECTION_QUERY_PORT } from '../ports/primary/show-overview-section.query-port';
import { GETS_LOADING_STATE_COMMAND } from '../ports/primary/gets-loading-state.command-port';
import { GETS_PRODUCT_LIST_QUERY } from '../ports/primary/gets-product-list.query';
import { SHOW_PRODUCT_INFO_COMMAND_PORT } from '../ports/primary/show-product-info.command-port';
import { ProductInfoComponentModule } from '../../adapters/primary/ui/setup-modal/product-info/product-info.component-module';
import { GETS_PRODUCT_MARKETING_LIST_QUERY } from '../ports/primary/gets-product-marketing-list.query';
import { OPEN_SETUP_FLOW_COMMAND } from '../ports/primary/open-setup-flow.command-port';
import { OPEN_SETUP_FLOW_CLICKED_DISPATCHER } from '../ports/secondary/dispatchers/open-setup-flow-clicked.dispatcher-port';
import { OpenSetupFlowEventDispatcher } from '../../adapters/secondary/dispatchers/open-setup-flow.event-dispatcher';
import { GETS_SHOP_LIST_QUERY } from '../ports/primary/gets-shop-list.query';
import { GETS_WORKSPACE_QUANTITY_QUERY_PORT } from '../ports/primary/get-workspace-quantity.query-port';

@NgModule({
  imports: [ProductInfoComponentModule],
  providers: [
    HomeState,
    { provide: GETS_TODOS_QUERY, useExisting: HomeState },
    { provide: GETS_PRODUCT_LIST_QUERY, useExisting: HomeState },
    { provide: GETS_USER_DETAILS_QUERY_PORT, useExisting: HomeState },
    { provide: CURRENT_CLIENTS_QUANTITY_QUERY_PORT, useExisting: HomeState },
    { provide: CURRENT_MEMBERS_QUANTITY_QUERY_PORT, useExisting: HomeState },
    { provide: CURRENT_UPGRADES_QUANTITY_QUERY_PORT, useExisting: HomeState },
    { provide: SHOW_OVERVIEW_SECTION_QUERY_PORT, useExisting: HomeState },
    { provide: GETS_LOADING_STATE_COMMAND, useExisting: HomeState },
    { provide: SHOW_PRODUCT_INFO_COMMAND_PORT, useExisting: HomeState },
    { provide: GETS_PRODUCT_MARKETING_LIST_QUERY, useExisting: HomeState },
    { provide: OPEN_SETUP_FLOW_COMMAND, useExisting: HomeState },
    { provide: OPEN_SETUP_FLOW_CLICKED_DISPATCHER, useClass: OpenSetupFlowEventDispatcher },
    { provide: GETS_SHOP_LIST_QUERY, useExisting: HomeState },
    { provide: GETS_WORKSPACE_QUANTITY_QUERY_PORT, useExisting: HomeState },
  ],
})
export class HomeStateModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AddBillingAddressComponent } from '../../../../adapters/primary/ui/setup-client/steps/add-billing-address/add-billing-address.component';
import { AddPaymentCardComponent } from '../../../../adapters/primary/ui/setup-client/steps/add-payment-card/add-payment-card.component';
import { ChoosePlanComponent } from '../../../../adapters/primary/ui/setup-client/steps/choose-plan/choose-plan.component';
import { ConfirmPaymentComponent } from '../../../../adapters/primary/ui/setup-client/steps/confirm-payment/confirm-payment.component';
import { CreateShopComponent } from '../../../../adapters/primary/ui/setup-client/steps/create-shop/create-shop.component';
import { StepConfigurationQuery } from './step-configuration.query';

export const GETS_STEPS_CONFIGURATION_QUERY = new InjectionToken<GetsStepsConfigurationQueryPort>(
  'GETS_STEPS_CONFIGURATION_QUERY',
);

export interface GetsStepsConfigurationQueryPort {
  getStepsConfiguration(): Observable<
    StepConfigurationQuery<
      | CreateShopComponent
      | ChoosePlanComponent
      | AddBillingAddressComponent
      | AddPaymentCardComponent
      | ConfirmPaymentComponent
    >[]
  >;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerProgramFormComponent } from './partner-program-form.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { ReactiveFormsModule } from '@angular/forms';
import { GETS_USER_DETAILS_QUERY } from '../../../../application/ports/primary/gets-user-details.query-port';
import { NgModuleWithProviders } from 'ng-mocks';

@NgModule({
  declarations: [PartnerProgramFormComponent],
  imports: [CommonModule, HuiLoaderContainerModule, LanguageModule, ReactiveFormsModule],
  exports: [PartnerProgramFormComponent],
})
export class PartnerProgramFormComponentModule {
  static forRoot(
    getsUserDetailsAdapter: object,
  ): NgModuleWithProviders<PartnerProgramFormComponentModule> {
    return {
      ngModule: PartnerProgramFormComponentModule,
      providers: [
        {
          provide: GETS_USER_DETAILS_QUERY,
          useExisting: getsUserDetailsAdapter,
        },
      ],
    };
  }
}

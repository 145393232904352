import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserAdditionalDataLoadRequestedEvent } from '@app.cobiro.com/core/events';
import {
  LOAD_USER_ADDITIONAL_DATA_COMMAND,
  LoadUserAdditionalDataCommandPort,
} from '../../application/ports/primary/load-user-additional-data-command.port';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class UserAdditionalDataLoadRequestedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [UserAdditionalDataLoadRequestedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(LOAD_USER_ADDITIONAL_DATA_COMMAND)
    private readonly _loadUserAdditionalDataCommandPort: LoadUserAdditionalDataCommandPort,
  ) {}

  handle(): void {
    this._loadUserAdditionalDataCommandPort.loadUserAdditionalData();
  }
}

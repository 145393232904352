<div class="vh-90">
  <div
    class="cs-stepper-popup d-flex flex-column flex-md-row cs-max-width-1000-xl"
    libKeyboardBlock
  >
    <div
      class="cs-stepper-popup-content modal-content d-flex pl-4 pl-md-5 pr-4 pr-md-5 cs-pb-40 cs-pt-70 col-12 col-md-6"
    >
      <hui-stepper #stepper [isAnimated]="false">
        <cdk-step>
          <lib-plan-selection-step></lib-plan-selection-step>
        </cdk-step>
        <cdk-step>
          <ng-content *ngIf="stepper.selectedIndex === 1" select="[cardDetailsStep]"></ng-content>
        </cdk-step>
        <cdk-step>
          <ng-content select="[confirmationStep]"></ng-content>
        </cdk-step>
      </hui-stepper>
    </div>
    <div class="col-md-6 p-0">
      <div
        class="d-flex flex-column w-100 h-100 position-relative align-items-center justify-content-center cs-p-70"
      >
        <img
          [src]="
            'assets/payment-plans/images/bg-' +
            (currentlySelectedPlanName$ | async)?.toLowerCase() +
            '.jpg'
          "
          class="w-100 h-100 position-absolute cs-object-fit-cover cs-z-index-0"
          data-selector="upgrade-modal-image"
        />
        <div
          class="w-100 cs-mh-60 cs-z-index-1 br-10 cs-ph-30 cs-pv-40"
          [style]="'background: rgba(255, 255, 255, 0.4)'"
        >
          <!-- TODO: add rgba opacity to styles -->

          <div class="d-flex justify-content-between">
            <div class="position-relative cs-width-100-xs cs-height-100-xs cs-mb-20">
              <div class="position-absolute cs-width-100-xs cs-height-100-xs">
                <img
                  [src]="
                    'assets/payment-plans/images/bg-circle-' +
                    (currentlySelectedPlanName$ | async)?.toLowerCase() +
                    '.svg'
                  "
                />
              </div>
              <div
                class="position-absolute cs-width-100-xs cs-height-100-xs d-flex justify-content-center align-items-center"
              >
                <p class="cs-size-xl d-flex justify-content-center align-items-center">
                  {{ emojiMap.get(currentlySelectedPlanName$ | async) }}
                </p>
              </div>
            </div>

            <div class="d-flex">
              <p class="cs-title-3">
                ${{
                  (selectedPlanDescription$ | async)?.subscriptionPeriods[
                    (currentlySelectedPlanPeriod$ | async)
                  ]
                }}
              </p>
              <p class="cs-text-2 cs-pt-20 cs-pl-5 text-transform-lowercase">
                /{{ 'payment_plans_period_monthly' | language }}
              </p>
            </div>
          </div>

          <div>
            <div class="cs-title-4 cs-mb-15">
              <span>{{ currentlySelectedPlanName$ | async }}</span>
            </div>
            <div class="cs-title-6 cs-mb-15">
              <span data-selector="upgrade-modal-feature-header">
                {{ (selectedPlanDescription$ | async)?.featureHeader | language }}:
              </span>
            </div>

            <ul class="p-0">
              <li
                *ngFor="let description of (selectedPlanDescription$ | async)?.featureDescriptions"
                class="d-flex align-items-center cs-mb-15 cs-text-2 break-word"
              >
                <mat-icon svgIcon="checkmark-filled-blue" class="cs-size-sm cs-mr-10"></mat-icon>
                {{ description | language }}
              </li>
            </ul>
          </div>
        </div>

        <div class="cs-z-index-1">
          <p class="cs-mt-10 cs-text-2">
            <a
              class="cs-link"
              (click)="onComparePlansClicked()"
              data-selector="upgrade-modal-compare-plans"
              >{{ 'payment_plans_compare_plans' | language }}</a
            >
          </p>
        </div>
      </div>
    </div>
    <a class="cs-popup-close cs-cursor-pointer" (click)="closeDialog()">
      <mat-icon svgIcon="close" class="cs-size-md"></mat-icon>
    </a>
    <a
      class="cs-stepper-popup-back-button cs-cursor-pointer"
      *ngIf="!stepper.isFirstStep"
      (click)="onBackButtonClicked()"
      data-selector="back-button"
    >
      <mat-icon class="back-icon cs-size-md" svgIcon="chevron-left"></mat-icon>
      Go back
    </a>
  </div>
</div>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-not-allowed-modal',
  templateUrl: 'not-allowed-modal.component.html',
})
export class NotAllowedModalComponent {
  constructor(private _dialogRef: MatDialogRef<NotAllowedModalComponent>) {}
  onCloseModalClicked() {
    this._dialogRef.close();
  }
}

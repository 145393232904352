import { ApplicationEvent } from '@cobiro/eda';

export class UserProfileUpdatedEvent extends ApplicationEvent {
  constructor(
    readonly profileData: {
      firstName: string;
      lastName: string;
      avatar: string;
      country: string;
      phoneNumber: string;
    },
  ) {
    super();
  }
}

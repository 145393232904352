import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { catchError, take, tap } from 'rxjs/operators';
import {
  ChangesPasswordDtoPort,
  CHANGES_PASSWORD_DTO,
} from '../ports/secondary/changes-password.dto-port';
import { ChangesPasswordQueryPort } from '../ports/primary/changes-password.query-port';

@Injectable()
export class PasswordChangeState implements ChangesPasswordQueryPort {
  constructor(
    @Inject(CHANGES_PASSWORD_DTO) private readonly _changesPassword: ChangesPasswordDtoPort,
    private readonly _alert: HuiAlert,
  ) {}

  changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this._changesPassword.change(oldPassword, newPassword).pipe(
      take(1),
      tap(() => {
        this._alert.open('success', 'change_password_success');
      }),
      catchError(() => {
        this._alert.open('error', 'change_password_fail');
        return of(false);
      }),
    );
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from './user.dto';

export const SENDS_INVITATION_DTO = new InjectionToken<SendsInvitationDtoPort>(
  'SENDS_INVITATION_DTO',
);

export interface SendsInvitationDtoPort {
  sendInvitation(dto: Pick<UserDTO, 'id' | 'email' | 'role' | 'teamId'>): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationLinkStatusQuery } from './integration-link-status.query';

export const GETS_INTEGRATION_LINK_STATUS_QUERY =
  new InjectionToken<GetsIntegrationLinkStatusQueryPort>('GETS_INTEGRATION_LINK_STATUS_QUERY');

export interface GetsIntegrationLinkStatusQueryPort {
  getIntegrationLinkStatus(): Observable<IntegrationLinkStatusQuery>;
}

import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { PersonalSitesLoadedEvent } from '@app.cobiro.com/core/events';
import {
  CLEAR_CONTEXT_COMMAND,
  ClearsContextCommandPort,
} from '../../application/ports/primary/clears-context.command-port';

@Injectable()
export class CobiroProModeDisabledEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [PersonalSitesLoadedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(CLEAR_CONTEXT_COMMAND)
    private readonly _clearContextCommand: ClearsContextCommandPort,
  ) {}

  handle(): void {
    this._clearContextCommand.clearContext();
  }
}

import { Inject, Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { UserSignOutCompletedEvent } from '@app.cobiro.com/core/events';
import {
  SET_LATEST_COBIRO_PRO_LOGIN_COMMAND,
  SetsLatestCobiroProLoginCommandPort,
} from '../../application/ports/primary/sets-latest-cobiro-pro-login.command-port';
import {
  CLEAR_CONTEXT_COMMAND,
  ClearsContextCommandPort,
} from '../../application/ports/primary/clears-context.command-port';

@Injectable()
export class CobiroProContextUserSignedOutCompletedEventHandler
  implements ApplicationEventsHandler
{
  eventsClasses = [UserSignOutCompletedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SET_LATEST_COBIRO_PRO_LOGIN_COMMAND)
    private readonly _setsLatestCobiroProLoginCommandPort: SetsLatestCobiroProLoginCommandPort,
    @Inject(CLEAR_CONTEXT_COMMAND) private readonly _clearsContextCommand: ClearsContextCommandPort,
  ) {}

  handle([event]: [UserSignOutCompletedEvent]) {
    this._setsLatestCobiroProLoginCommandPort.setLatestLogin(event.userId);
    this._clearsContextCommand.clearContextAndGoToPersonalMode();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideUtmResolver } from './adapters/utm.resolver';
import { STORAGE } from '@app.cobiro.com/core/storage';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideUtmResolver('utmInterestResolver', 'utm_interest'),
    {
      provide: STORAGE,
      useValue: localStorage,
    },
  ],
})
export class CoreUtmModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientListItemQuery } from './client-list-item.query';
import { ComparableSelectionModel } from '@app.cobiro.com/shared/hui/comparable-selection-model/comparable-selection-model';

export interface GetsClientsListSelectionQueryPort {
  getClientListSelection(): Observable<ComparableSelectionModel<ClientListItemQuery>>;
}

export const GETS_CLIENTS_LIST_SELECTION_QUERY =
  new InjectionToken<GetsClientsListSelectionQueryPort>('GETS_CLIENTS_LIST_SELECTION_QUERY');

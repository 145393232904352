import { PaymentPlan, PaymentPlanPeriod } from '../../../core/domain/payment-plan';

export class UpgradeablePlanQuery {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly pricePerMonth: {
      yearly: string;
      monthly: string;
    },
    public readonly pricePerYear: {
      yearly: string;
      monthly: string;
    },
    public readonly savingsPerYearInAnnualPayment: string,
  ) {}

  static fromPaymentPlan(paymentPlan: PaymentPlan): UpgradeablePlanQuery {
    return new UpgradeablePlanQuery(
      paymentPlan.id,
      paymentPlan.name,
      {
        yearly: `${paymentPlan.getPriceForPeriod(PaymentPlanPeriod.MONTHLY).currency}${
          paymentPlan.pricedPeriods.yearly
        }`,
        monthly: `${paymentPlan.getPriceForPeriod(PaymentPlanPeriod.MONTHLY).currency}${
          paymentPlan.pricedPeriods.monthly
        }`,
      },
      {
        yearly: `${paymentPlan.getPriceForPeriod(PaymentPlanPeriod.YEARLY).currency}${
          paymentPlan.pricedPeriods.yearly * 12
        }`,
        monthly: `${paymentPlan.getPriceForPeriod(PaymentPlanPeriod.YEARLY).currency}${
          paymentPlan.pricedPeriods.monthly * 12
        }`,
      },
      `${paymentPlan.getPriceForPeriod(PaymentPlanPeriod.YEARLY).currency}${
        paymentPlan.pricedPeriods.monthly * 12 - paymentPlan.pricedPeriods.yearly * 12
      }`,
    );
  }
}

export class CurrentPlanQuery {
  constructor(
    public readonly id: string,
    public readonly pricePerMonth: {
      yearly: number;
      monthly: number;
    } = { yearly: 0, monthly: 0 },
  ) {}
}

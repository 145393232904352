import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientQuery } from './client.query';

export const GETS_SELECTED_CLIENT_QUERY = new InjectionToken<GetsSelectedClientQueryPort>(
  'GETS_SELECTED_CLIENT_QUERY',
);

export interface GetsSelectedClientQueryPort {
  getSelectedClient(): Observable<ClientQuery>;
}

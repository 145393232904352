import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HasDataCollection, JSON_API_HEADERS } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsPlanFeatures } from '../../domain/gets-plan-features';

interface PlanFeaturesAttributes {
  name: string;
  features: { name: string; limit: number | null }[];
}

@Injectable()
export class HttpGetsPlanFeatures implements GetsPlanFeatures {
  readonly headers = new HttpHeaders(JSON_API_HEADERS);

  constructor(
    private readonly _client: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  get(): Observable<Map<string, Map<string, number | null>>> {
    return this._client
      .get<HasDataCollection<PlanFeaturesAttributes>>(
        `${this._getsConfig.get('publicCdn')}/v1/payments/cobiro/plan-features/features.json`,
        {
          params: new HttpParams({ fromObject: { timestamp: String(new Date().getTime()) } }),
          headers: this.headers,
        },
      )
      .pipe(
        map(
          response =>
            new Map(
              response.data.map(featureDTO => [
                featureDTO.attributes.name,
                new Map(
                  featureDTO.attributes.features.map(feature => [feature.name, feature.limit]),
                ),
              ]),
            ),
        ),
      );
  }
}

import { InjectionToken } from '@angular/core';
import { TodoDTO } from './todo.dto';

export const GETS_COBIRO_PRO_AVAILABLE_TODOS_DTO = new InjectionToken<GetsAvailableTodosDtoPort>(
  'GETS_COBIRO_PRO_AVAILABLE_TODOS_DTO',
);

export interface GetsAvailableTodosDtoPort {
  getAvailableTodos(): TodoDTO[];
}

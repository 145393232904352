import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserRegisterState } from '../../../application/user-register.state';
import { EMAIL_REGEX } from './email-regex';

@Component({
  selector: 'lib-register-email-form',
  templateUrl: './register-email-form.component.html',
})
export class RegisterEmailFormComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
  });

  control(name: string) {
    return this.form.get(name);
  }

  hasError(controlName: string) {
    const { invalid, touched } = this.form.get(controlName);
    return invalid && touched;
  }

  constructor(private readonly _state: UserRegisterState) {}

  onSubmit() {
    this._state.setEmail(this.form.get('email').value);
  }
}

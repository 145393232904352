import { NgModule } from '@angular/core';
import { WorkspacesSwitcherWidgetComponent } from './workspaces-switcher-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  imports: [
    MatIconModule,
    MatMenuModule,
    CommonModule,
    RouterModule,
    HuiAvatarModule,
    LanguageModule,
  ],
  declarations: [WorkspacesSwitcherWidgetComponent],
  exports: [WorkspacesSwitcherWidgetComponent],
})
export class WorkspacesSwitcherWidgetComponentModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelManagerStatusComponent } from './label-manager-status.component';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  declarations: [LabelManagerStatusComponent],
  imports: [CommonModule, LanguageModule],
  exports: [LabelManagerStatusComponent],
})
export class LabelManagerStatusComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamDTO } from '../secondary/team.dto';

export interface LoadTeamsCommandPort {
  loadAll(): Observable<TeamDTO[]>;
  loadByCriteria(criteria: { [key: string]: number | string }): Observable<TeamDTO[]>;
}

export const LOAD_TEAMS_COMMAND = new InjectionToken<LoadTeamsCommandPort>('LOAD_TEAMS_COMMAND');

import { NgModule } from '@angular/core';
import { CREATES_WORKSPACE_DTO } from '../../../application/ports/secondary/dto/creates-workspace.dto-port';
import { GETS_ALL_WORKSPACES_DTO } from '../../../application/ports/secondary/dto/gets-all-workspaces.dto-port';
import { GETS_MEMBER_WORKSPACES_DTO } from '../../../application/ports/secondary/dto/gets-member-workspaces.dto-port';
import { HAS_SUBSCRIPTIONS_DTO } from '../../../application/ports/secondary/dto/has-subscriptions.dto-port';
import { HttpWorkspacesService } from './http-workspaces.service';
import { DELETES_WORKSPACE_DTO } from '../../../application/ports/secondary/dto/deletes-workspace.dto-port';
import { EDITS_WORKSPACE_NAME_DTO } from '../../../application/ports/secondary/dto/edits-workspace-name.dto-port';

@NgModule({
  providers: [
    HttpWorkspacesService,
    { provide: GETS_ALL_WORKSPACES_DTO, useExisting: HttpWorkspacesService },
    { provide: HAS_SUBSCRIPTIONS_DTO, useExisting: HttpWorkspacesService },
    { provide: GETS_MEMBER_WORKSPACES_DTO, useExisting: HttpWorkspacesService },
    { provide: CREATES_WORKSPACE_DTO, useExisting: HttpWorkspacesService },
    { provide: DELETES_WORKSPACE_DTO, useExisting: HttpWorkspacesService },
    { provide: EDITS_WORKSPACE_NAME_DTO, useExisting: HttpWorkspacesService },
  ],
})
export class HttpWorkspacesServiceModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SignsUpByEmailCommand } from './signs-up-by-email.command';

export const SIGNS_UP_BY_EMAIL_COMMAND = new InjectionToken<SignsUpByEmailCommandPort>(
  'SIGNS_UP_BY_EMAIL_COMMAND',
);

export interface SignsUpByEmailCommandPort {
  signUpByEmail(command: SignsUpByEmailCommand): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersContext } from './usersContext';

export const PATCHES_USERS_CONTEXT_STORAGE = new InjectionToken<PatchesUsersContextStoragePort>(
  'PATCHES_USERS_CONTEXT_STORAGE',
);

export interface PatchesUsersContextStoragePort {
  patch(state: Partial<UsersContext>): Observable<void>;
}

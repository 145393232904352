<div
  class="user-register-container cs-card cs-min-height-min-content cs-asset-bg-gradient-1 cs-bg-size-contain cs-shadow cs-bg-shade-0 cs-b-none cs-max-width-700-xs cs-min-width-100-pc-xs cs-ph-100-lg cs-pv-70-xxl cs-pv-30-lg cs-pv-20 cs-ph-60-md m-auto br-0"
>
  <div class="d-flex justify-content-center">
    <img class="cs-height-30-xs" src="assets/logos/cobiro-logo-with-text.svg" alt="Cobiro" />
  </div>
  <ng-container *ngIf="stepper.selectedIndex !== 2">
    <ng-content select="[header]"></ng-content>
  </ng-container>

  <hui-stepper
    (animationDone)="onAnimationDone($event)"
    [disableAnimationWithDelay]="stepper.selectedIndex === 1"
    #stepper
  >
    <cdk-step>
      <lib-register-email-form></lib-register-email-form>
    </cdk-step>
    <cdk-step>
      <lib-password-form
        class="d-block cs-ph-20-sm cs-ph-80-lg w-100"
        #passwordFormComponent
        [placeholder]="'_password' | language"
        [formControl]="passwordControl"
        (enterPressed)="onPasswordEnterPressed()"
      >
        <p class="cs-text-1 cs-c-shade-100 cs-mb-5">
          {{ '_password' | language }}
        </p>
      </lib-password-form>
      <re-captcha
        #recaptchaRef="reCaptcha"
        class="cs-mt-40"
        size="invisible"
        (resolved)="$event && this.onCaptchaResolved($event)"
        [siteKey]="siteKey"
      ></re-captcha>
      <div class="cs-ph-80-lg cs-ph-40-sm cs-ph-20-sm">
        <button
          class="cs-btn cs-btn-primary w-100 d-flex align-items-center justify-content-center cs-mt-20"
          [libAnalytics]="['signup-step-2', 'continue']"
          [disabled]="passwordControl.invalid"
          [huiLoading]="(isLoading$ | async) || (captchaResolving$ | async)"
          data-selector="continue-password"
          (click)="onContinueButtonClicked()"
        >
          <span class="cs-text-1 cs-c-shade-0">
            {{ '_continue' | language }}
          </span>
        </button>
      </div>
    </cdk-step>
    <cdk-step>
      <lib-verify-code-form></lib-verify-code-form>
    </cdk-step>
  </hui-stepper>

  <ng-content select="[below-stepper]"></ng-content>

  <small class="d-block text-center cs-mt-80-xxl cs-mt-40-md cs-mt-30 cs-text-2"
    >{{ 'signup_terms_conditions_info' | language }}
    <a
      href="https://cobiro.com/terms-of-service"
      target="_blank"
      rel="noopener noreferrer"
      class="cs-link"
    >
      {{ 'signup_terms_conditions' | language }}
    </a>
    &
    <a
      href="https://cobiro.com/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
      class="cs-link"
    >
      {{ 'signup_privacy_policy' | language }}.
    </a>
  </small>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpGetsUserDetailsService } from './http-gets-user-details.service';
import { HttpGetsAnnoucementsService } from './http-gets-annoucement.service';
import { GETS_ANNOUCEMENT_DTO } from '../../application/ports/secondary/gets-annoucement.dto-port';
import { GETS_ANNOUCEMENT_VISIBILITY_DTO } from '../../application/ports/secondary/gets-annoucement-visibility.dto-port';
import { SETS_ANNOUCEMENT_VISIBILITY_DTO } from '../../application/ports/secondary/sets-annoucement-visibility.dto-port';

@NgModule({
  providers: [
    HttpGetsAnnoucementsService,
    {
      provide: GETS_ANNOUCEMENT_DTO,
      useExisting: HttpGetsAnnoucementsService,
    },
    {
      provide: GETS_ANNOUCEMENT_VISIBILITY_DTO,
      useExisting: HttpGetsAnnoucementsService,
    },
    {
      provide: SETS_ANNOUCEMENT_VISIBILITY_DTO,
      useExisting: HttpGetsAnnoucementsService,
    },
  ],
  imports: [CommonModule],
})
export class HttpAnnoucementsServiceModule {}

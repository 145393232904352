import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { PAYMENT_STATUS } from './payment-status.enum';

export const UPDATES_PLAN_DTO_PORT = new InjectionToken<UpdatesPlanDtoPort>(
  'UPDATES_PLAN_DTO_PORT',
);

export interface UpdatesPlanDtoPort {
  update(transactionId: string): Observable<PAYMENT_STATUS>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LinksIntegrationCommand } from './links-integration.command';

export const LINKS_INTEGRATION_COMMAND = new InjectionToken<LinksIntegrationCommandPort>(
  'LINKS_INTEGRATION_COMMAND',
);

export interface LinksIntegrationCommandPort {
  linkIntegration(command: LinksIntegrationCommand): Observable<void>;
}

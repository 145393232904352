import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  UserImpersonateLogoutEvent,
  UserSignedOutEvent,
  UserSignOutCompletedEvent,
} from '@app.cobiro.com/core/events';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';
import {
  APPLICATION_BUS,
  ApplicationBus,
  ApplicationEventsHandler,
  ongoingEventsOrchestrationStrategy
} from '@cobiro/eda';
import { of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { Logouts, LOGOUTS } from '../domain/logouts';

@Injectable()
export class UserSignedOutEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UserSignedOutEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  private readonly _authKey = 'cobiro-auth';

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    @Inject(STORAGE) private readonly _storage: Storage,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(GETS_USER_FROM_TOKEN) private readonly _getsUserFromToken: GetsUserFromToken,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(LOGOUTS) private readonly _logout: Logouts,
  ) {}

  handle([event]: [UserSignedOutEvent]): void {
    this._logout
      .logout()
      .pipe(
        take(1),
        catchError(() => of(null)),
        finalize(() => {
          const loggedOutUserId = this._getsUserFromToken.get().userId;
          this._storage.removeItem(this._authKey);
          this._applicationBus.dispatch(new UserImpersonateLogoutEvent());
          if (event.redirectToLogin) {
            this._handleNavigation();
          }
          this._applicationBus.dispatch(new UserSignOutCompletedEvent(loggedOutUserId));
        }),
      )
      .subscribe();
  }

  private _handleNavigation(): void {
    if (
      this._activatedRoute.snapshot.queryParams &&
      this._activatedRoute.snapshot.queryParams.next
    ) {
      this._window.location.href = this._activatedRoute.snapshot.queryParams.next;
    } else {
      this._window.location.href = `${this._window.location.origin}/auth/login`;
    }
  }
}

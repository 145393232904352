import { ChangeDetectorRef, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { KeyboardEventIdentifier } from '@app.cobiro.com/shared/hui/utils/keyboard-event-identifier';

@Directive({
  selector: '[huiCardExpireDate]',
})
export class HuiCardExpireDateDirective {
  private _inputRef;

  monthPattern = new RegExp('0?[2-9]');
  digitOrSlashPattern = new RegExp('[0-9/]');

  get inputValue() {
    return this._inputRef.value;
  }

  @HostListener('keydown', ['$event']) onPress(event: KeyboardEvent) {
    const key = event.key;

    if (
      KeyboardEventIdentifier.isNavigationKey(event) ||
      KeyboardEventIdentifier.isMacShortcut(event) ||
      KeyboardEventIdentifier.isWindowsShortcut(event)
    ) {
      return;
    }

    if (!this.digitOrSlashPattern.test(key)) {
      event.preventDefault();
      return;
    }

    if (this.inputValue.length >= 3 && key !== '/') {
      return;
    } else if (this._startsWithZeroOrOne() && this.inputValue.length === 1) {
      this._inputRef.value = this.inputValue + key + '/';
      event.preventDefault();
    } else if (this._matchMonth(key) && key !== '/') {
      this._inputRef.value = '0' + key + '/';
      event.preventDefault();
    }
  }

  constructor(elementRef: ElementRef) {
    this._inputRef = elementRef.nativeElement;
  }

  private _startsWithZeroOrOne() {
    return this.inputValue.startsWith('1') || this.inputValue.startsWith('0');
  }

  private _matchMonth(key: string): boolean {
    return this.monthPattern.test(this.inputValue + key);
  }
}

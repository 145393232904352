import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable, switchMap, take } from 'rxjs';
import {
  LoadClientsCommandPort,
  LOAD_CLIENTS_COMMAND,
} from '../../../application/ports/primary/clients/load-clients.command-port';
import {
  SetsSelectedClientCommandPort,
  SETS_SELECTED_CLIENT_COMMAND,
} from '../../../application/ports/primary/clients/sets-selected-client-command.port';
import { ClientsContext } from '../../../application/ports/secondary/context/clientsContext';
import { ClientDTO } from '../../../application/ports/secondary/dto/clients/client.dto';

@Injectable()
export class LoadSelectedShopResolver {
  constructor(
    @Inject(LOAD_CLIENTS_COMMAND)
    private readonly _loadClientsCommandPort: LoadClientsCommandPort,
    @Inject(SETS_SELECTED_CLIENT_COMMAND)
    private readonly _setSelectedClientCommand: SetsSelectedClientCommandPort,
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot): Observable<ClientDTO> {
    return this._loadClientsCommandPort.loadClients().pipe(
      take(1),
      switchMap((clients: ClientDTO[]) => {
        const selectedClient: ClientDTO = clients.find(
          item => item.id === snapshot.paramMap.get('shopId'),
        );
        return this._setSelectedClientCommand.setSelectedClient(selectedClient.id);
      }),
    );
  }
}

/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasData, HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreatesWorkspaceDto } from '../../../application/ports/secondary/dto/creates-workspace.dto';
import { CreatesWorkspaceDtoPort } from '../../../application/ports/secondary/dto/creates-workspace.dto-port';
import { GetsAllWorkspacesDtoPort } from '../../../application/ports/secondary/dto/gets-all-workspaces.dto-port';
import { GetsMemberWorkspacesDtoPort } from '../../../application/ports/secondary/dto/gets-member-workspaces.dto-port';
import { HasSubscriptionsDtoPort } from '../../../application/ports/secondary/dto/has-subscriptions.dto-port';
import { WorkspaceDto } from '../../../application/ports/secondary/dto/workspace.dto';
import { DeletesWorkspaceDtoPort } from '../../../application/ports/secondary/dto/deletes-workspace.dto-port';
import { DeletesWorkspaceDto } from '../../../application/ports/secondary/dto/deletes-workspace.dto';
import { EditsWorkspaceNameDtoPort } from '../../../application/ports/secondary/dto/edits-workspace-name.dto-port';
import { EditsWorkspaceDto } from '../../../application/ports/secondary/dto/edits-workspace-name.dto';

interface WorkspaceAttributes {
  name: string;
  counter: number;
  avatar: string;
  clientCounter: number;
  createdAt: string;
}

interface ClientProductAttributes {
  productType: 'css' | 'label-manager';
  planType: 'monthly' | 'annual' | 'business';
  subscriptionId: string | null;
}

interface ClientAttributes {
  products: ClientProductAttributes[];
}

@Injectable()
export class HttpWorkspacesService
  implements
    GetsAllWorkspacesDtoPort,
    GetsMemberWorkspacesDtoPort,
    HasSubscriptionsDtoPort,
    CreatesWorkspaceDtoPort,
    DeletesWorkspaceDtoPort,
    EditsWorkspaceNameDtoPort
{
  constructor(private readonly _client: GatewayClient) {}

  editWorkspaceName(dto: EditsWorkspaceDto): Observable<void> {
    const payload = {
      data: {
        id: dto.workspaceId,
        type: 'workspaces',
        attributes: {
          workspaceName: dto.workspaceName,
        },
      },
    };
    return this._client.patch(
      `v1/sites/teams/${dto.teamId}/workspaces/${dto.workspaceId}`,
      payload,
    );
  }

  deleteWorkspace(dto: DeletesWorkspaceDto): Observable<void> {
    return this._client.delete<void>(`v1/sites/teams/${dto.teamId}/workspaces/${dto.workspaceId}`);
  }

  getsMembersWorkspace(teamId: string): Observable<WorkspaceDto[]> {
    return this._client
      .get<HasDataCollection<WorkspaceAttributes>>(`v1/sites/teams/${teamId}/member-workspaces`)
      .pipe(
        map(dataCollection => {
          const userWorkspacesIds: string[] = [];
          const userWorkspaces = dataCollection.data.map(data => {
            userWorkspacesIds.push(data.id);

            return {
              id: data.id,
              name: data.attributes.name,
              membersCount: data.attributes.counter,
              clientCount: data.attributes.clientCounter,
              avatar: data.attributes.avatar,
              createdAt: data.attributes.createdAt,
            };
          });

          return [...new Set(userWorkspacesIds)]
            .map(userWorkspaceId => userWorkspaces.find(team => team.id === userWorkspaceId))
            .filter(Boolean);
        }),
      );
  }

  createWorkspace(dto: CreatesWorkspaceDto): Observable<Pick<WorkspaceDto, 'id' | 'name'>> {
    const payload: HasData<{ workspaceName: string }> = {
      data: {
        type: 'workspaces',
        attributes: {
          workspaceName: dto.workspaceName,
        },
      },
    };
    return this._client.post(`v1/sites/teams/${dto.teamId}/workspaces`, payload).pipe(
      map((res: HasData<{ name: string }>) => ({
        id: res.data.id,
        name: res.data.attributes.name,
      })),
    );
  }

  hasSubscriptions(workspaceId: string): Observable<boolean> {
    return this._client
      .get<HasDataCollection<ClientAttributes>>(
        `v1/sites/workspaces/${workspaceId}/clients/products`,
      )
      .pipe(
        map((products: HasDataCollection<ClientAttributes>) =>
          products.data.some(data => data.attributes.products.length > 0),
        ),
      );
  }

  getsAll(teamId: string): Observable<WorkspaceDto[]> {
    return this._client
      .get<HasDataCollection<WorkspaceAttributes>>(`v1/sites/teams/${teamId}/workspaces`)
      .pipe(
        map(dataCollection => {
          const userWorkspacesIds: string[] = [];
          const userWorkspaces = dataCollection.data.map(data => {
            userWorkspacesIds.push(data.id);

            return {
              id: data.id,
              name: data.attributes.name,
              membersCount: data.attributes.counter,
              clientCount: data.attributes.clientCounter,
              avatar: data.attributes.avatar,
              createdAt: data.attributes.createdAt,
            };
          });

          return [...new Set(userWorkspacesIds)]
            .map(userWorkspaceId => userWorkspaces.find(team => team.id === userWorkspaceId))
            .filter(Boolean);
        }),
      );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseTeamModalComponent } from './choose-team-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { MatIconModule } from '@angular/material/icon';
import { HuiAvatarModule } from '@app.cobiro.com/shared/hui/avatar';
import { LanguageModule } from '@app.cobiro.com/common/language';

@NgModule({
  declarations: [ChooseTeamModalComponent],
  exports: [ChooseTeamModalComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    HuiLoaderContainerModule,
    MatIconModule,
    HuiAvatarModule,
    LanguageModule,
  ],
})
export class ChooseTeamModalComponentModule {}

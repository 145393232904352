/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Component, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  Subject,
  takeUntil,
  tap,
  zip,
} from 'rxjs';
import { CountryQuery } from '../../../../application/ports/primary/country.query';
import {
  GetsCountriesQueryPort,
  GETS_COUNTRIES_QUERY,
} from '../../../../application/ports/primary/gets-countries.query-port';
import {
  GETS_USER_DETAILS_QUERY,
  GetsUserDetailsQueryPort,
} from '../../../../application/ports/primary/gets-user-details.query-port';
import {
  UpdatesUserDetailsQueryPort,
  UPDATES_USER_DETAILS_QUERY,
} from '../../../../application/ports/primary/updates-user-details.query-port';
import { UserDetailQuery } from '../../../../application/ports/primary/user-details.query';

const CountrySelectionRequiredValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null =>
  control?.value !== '' && !instanceOfACountry(control?.value) ? { invalidCountry: true } : null;

function instanceOfACountry(country: any): country is CountryQuery {
  return !!country && typeof country !== 'string' && 'code' in country;
}

@Component({
  selector: 'lib-cobiro-pro-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProPersonalInfoComponent implements OnDestroy {
  private _ngDestroy$ = new Subject<void>();

  readonly isProcessing$ = new BehaviorSubject<boolean>(false);
  readonly form: UntypedFormGroup = this._fb.group({
    email: this._fb.control({ value: '', disabled: true }, [Validators.required]),
    firstName: this._fb.control('', [Validators.required]),
    lastName: this._fb.control('', [Validators.required]),
    country: this._fb.control('', [Validators.required, CountrySelectionRequiredValidator]),
    phoneNumber: this._fb.control(null, Validators.pattern('^[0-9]*$')),
  });

  private readonly _avatar = new ReplaySubject<string>(1);
  readonly avatar$ = this._avatar.asObservable();

  readonly details$: Observable<UserDetailQuery> = zip(
    this._getsUserDetails.get(),
    this._getsCountriesQuery.getCountriesQuery().pipe(filter(countries => !!countries)),
  ).pipe(
    tap(([userDetails, countriesQuery]: [UserDetailQuery, CountryQuery[]]) => {
      const { email, firstName, lastName, phoneNumber, avatar } = userDetails;
      const country =
        userDetails.country &&
        countriesQuery.find(availableCountry => availableCountry.code === userDetails.country);
      this.form.patchValue({ email, firstName, lastName, country, phoneNumber });
      this._avatar.next(avatar);
    }),
    map(([userDetails, _]: [UserDetailQuery, CountryQuery[]]) => userDetails),
  );

  readonly countries$ = combineLatest([
    this._getsCountriesQuery.getCountriesQuery(),
    this.form.get('country').valueChanges.pipe(distinctUntilChanged()),
  ]).pipe(
    takeUntil(this._ngDestroy$),
    map(([countries, controlValue]) => {
      const controlValueString = controlValue?.toString() ?? '';
      return countries.filter(country =>
        country.name.toLowerCase().includes(controlValueString.toLowerCase()),
      );
    }),
  );

  constructor(
    private readonly _fb: UntypedFormBuilder,
    @Inject(GETS_USER_DETAILS_QUERY)
    private readonly _getsUserDetails: GetsUserDetailsQueryPort,
    @Inject(UPDATES_USER_DETAILS_QUERY)
    private readonly _updateUserDetails: UpdatesUserDetailsQueryPort,
    @Inject(GETS_COUNTRIES_QUERY)
    private readonly _getsCountriesQuery: GetsCountriesQueryPort,
  ) {}

  onFormSubmitted(): void {
    this.isProcessing$.next(true);

    this._updateUserDetails
      .update({
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        country: this.form.value.country.code,
        phoneNumber: this.form.value.phoneNumber,
      })
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe(() => {
        this.isProcessing$.next(false);
        this.form.markAsUntouched();
        this.form.markAsPristine();
      });
  }

  onImageChanged(imageUrl: string): void {
    this._avatar.next(imageUrl);
    this._updateUserDetails
      .update({ avatar: imageUrl })
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }

  displayCountryName(countryQuery: CountryQuery): string | null {
    return countryQuery?.name;
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import {
  TeamIdGetter,
  TEAM_ID_GETTER,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';
import { FrontendPagination, PaginationQuery, SORT_ORDER } from '@app.cobiro.com/core/pagination';
import { ComparableSelectionModel } from '@app.cobiro.com/shared/hui/comparable-selection-model/comparable-selection-model';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import { BehaviorSubject, combineLatest, map, Observable, switchMap, take, tap } from 'rxjs';
import { ClientListItemQuery } from '../../ports/primary/clients/client-list-item.query';
import { GetsLatestClientsListPaginationQueryPort } from '../../ports/primary/clients/gets-latest-clients-list-pagination.query-port';
import { GetsLatestClientsListSelectionQueryPort } from '../../ports/primary/clients/gets-latest-clients-list-selection.query-port';
import { GetsLatestClientsQueryPort } from '../../ports/primary/clients/gets-latest-clients.query-port';
import { IsAllSelectedLatestClientsCommandPort } from '../../ports/primary/clients/is-all-selected-latest-clients.command-port';
import { LoadLatestClientsCommandPort } from '../../ports/primary/clients/load-latest-clients.command-port';
import { SetsClientsListSortCommand } from '../../ports/primary/clients/sets-clients-list-sort.command';
import { SetsLatestClientsListSortCommandPort } from '../../ports/primary/clients/sets-latest-clients-list-sort.command-port';
import { ToggleAllLatestClientsCommandPort } from '../../ports/primary/clients/toggle-all-latest-clients.command-port';
import {
  SELECTS_CLIENTS_CONTEXT_STORAGE,
  SelectsClientsContextStoragePort,
} from '../../ports/secondary/context/selects-clients-context.storage-port';
import {
  CLIENTS_LIST_CHANGED_DISPATCHER,
  ClientsListChangedDispatcherPort,
} from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientDTO } from '../../ports/secondary/dto/clients/client.dto';
import { ClientSubscriptionDto } from '../../ports/secondary/dto/clients/clients-subscription.dto';
import {
  GETS_ALL_CLIENT_DTO,
  GetsAllClientDtoPort,
} from '../../ports/secondary/dto/clients/gets-all-client.dto-port';
import {
  GetsAllClientsSubsciptionsDtoPort,
  GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT,
} from '../../ports/secondary/dto/clients/gets-all-clients-subscriptions.dto-port';

@Injectable()
export class LatestClientsListState
  implements
    GetsLatestClientsQueryPort,
    GetsLatestClientsListPaginationQueryPort,
    LoadLatestClientsCommandPort,
    GetsLatestClientsListSelectionQueryPort,
    IsAllSelectedLatestClientsCommandPort,
    ToggleAllLatestClientsCommandPort,
    SetsLatestClientsListSortCommandPort
{
  private readonly _selection$ = new BehaviorSubject<ComparableSelectionModel<ClientListItemQuery>>(
    new ComparableSelectionModel<ClientListItemQuery>(
      true,
      [],
      true,
      (a: ClientListItemQuery, b: ClientListItemQuery) => a.id === b.id,
    ),
  );
  private readonly _pagination: FrontendPagination<ClientDTO> = FrontendPagination.fromRaw(
    this._selectsClientsContextStorage
      .select()
      .pipe(map(clientsContext => clientsContext.list.filter(clientList => !clientList.archived))),
    0,
    5,
    ['createdAt', SORT_ORDER.DESC],
    {},
  );

  constructor(
    @Inject(GETS_ALL_CLIENT_DTO)
    private readonly _getAllClientDTO: GetsAllClientDtoPort,
    @Inject(SELECTS_CLIENTS_CONTEXT_STORAGE)
    private readonly _selectsClientsContextStorage: SelectsClientsContextStoragePort,
    @Inject(CLIENTS_LIST_CHANGED_DISPATCHER)
    private readonly _clientsListChangedDispatcher: ClientsListChangedDispatcherPort,
    @Inject(WORKSPACE_ID_GETTER) private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(GETS_ALL_CLIENTS_SUBSCRIPTIONS_DTO_PORT)
    private readonly _getsAllClientsSubsciptionsDtoPort: GetsAllClientsSubsciptionsDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
  ) {
    this._applicationBus
      .on(CobiroProWorkspaceSwitchedEvent)
      .pipe(switchMap(() => this.loadClients()))
      .subscribe();
  }
  getLatestClientsQuery(): Observable<ClientListItemQuery[]> {
    return this._pagination.getPaginatedList().pipe(
      map(clients => {
        return this._mapClientsDtoToListItemQuery(clients);
      }),
    );
  }

  getClientListPagination(): Observable<PaginationQuery> {
    return this._pagination.getPaginationQuery();
  }

  getClientListSelection(): Observable<ComparableSelectionModel<ClientListItemQuery>> {
    return this._selection$;
  }

  isAllSelected(): Observable<boolean> {
    return combineLatest([this._selection$, this.getLatestClientsQuery()]).pipe(
      take(1),
      map(
        ([selection, clients]: [
          ComparableSelectionModel<ClientListItemQuery>,
          ClientListItemQuery[],
        ]) => {
          const numRows = clients.filter(client => !client.hasPaidPlan()).length;
          const numSelected = selection.selected.length;
          return numRows === numSelected;
        },
      ),
    );
  }

  setSort(command: SetsClientsListSortCommand): void {
    this._pagination.setPage(0);
    this._pagination.setSorting(
      command.key as keyof ClientDTO,
      command.direction.toUpperCase() as SORT_ORDER,
    );
  }

  toggleAllRows(): Observable<boolean> {
    return combineLatest([
      this.isAllSelected(),
      this.getLatestClientsQuery(),
      this._selection$,
    ]).pipe(
      take(1),
      map(
        ([isAllSelected, latestClientsList, selection]: [
          boolean,
          ClientListItemQuery[],
          ComparableSelectionModel<ClientListItemQuery>,
        ]) => {
          isAllSelected
            ? selection.clear()
            : latestClientsList
                .filter(client => !client.hasPaidPlan())
                .forEach(client => selection.select(client));
          return true;
        },
      ),
    );
  }

  loadClients(): Observable<ClientDTO[]> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    const teamId = this._teamIdGetter.getTeamId();
    return combineLatest([
      this._getAllClientDTO.getAll({ workspaceId: workspaceId, teamId: teamId }),
      this._getsAllClientsSubsciptionsDtoPort.getAllSubscriptions({ workspaceId: workspaceId }),
    ]).pipe(
      map(([clients, clientSubscriptions]: [ClientDTO[], ClientSubscriptionDto[]]) =>
        clients.map(client => {
          const subscriptions = clientSubscriptions.find(dto => dto.clientId === client.id)
            ? clientSubscriptions.find(dto => dto.clientId === client.id).subscriptions
            : [];
          return {
            ...client,
            ...{
              subscriptions: subscriptions,
              productStatuses: {
                css: client.cssIntegrationStatus,
                'label-manager': subscriptions?.find(subs => subs.productName === 'label-manager')
                  ? ('active' as const)
                  : null,
              },
            },
          };
        }),
      ),
      tap(clients => this._clientsListChangedDispatcher.dispatch({ updatedClients: clients })),
    );
  }

  private _mapClientsDtoToListItemQuery(clients: ClientDTO[]): ClientListItemQuery[] {
    return clients.map(clientDto => ClientListItemQuery.fromClientDTO(clientDto));
  }
}

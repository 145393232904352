export class Feature {
  constructor(public readonly id: string, public readonly limit: number | null = null) {}

  checkIfUsageIsBelowLimit(usage?: number): boolean {
    if (!this.limit) {
      return true;
    }
    if (usage === null || typeof usage === 'undefined') {
      throw new NoUsageProvidedError(this.id);
    }

    return usage < this.limit;
  }
}

export class NoUsageProvidedError extends Error {
  constructor(featureId: string) {
    super();
    this.message = `No usage provided for feature ${featureId}`;
    this.name = 'NO_USAGE_PROVIDED';
  }
}

import { Injectable } from '@angular/core';
import { PatchesUsersContextStoragePort } from '../../../../application/ports/secondary/context/patches-users-context.storage-port';
import { SelectsUsersContextStoragePort } from '../../../../application/ports/secondary/context/selects-users-context.storage-port';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UsersContext } from '../../../../application/ports/secondary/context/usersContext';

@Injectable()
export class InMemoryUsersContextStorage
  implements SelectsUsersContextStoragePort, PatchesUsersContextStoragePort
{
  private _subject$: BehaviorSubject<Partial<UsersContext>> = new BehaviorSubject<
    Partial<UsersContext>
  >({ list: [] });

  select(): Observable<Partial<UsersContext>> {
    return this._subject$
      .asObservable()
      .pipe(filter(context => !!context && !!Object.keys(context).length));
  }

  setState(state: UsersContext): Observable<void> {
    return of(this._subject$.next(state)).pipe(map(() => void 0));
  }

  patch(state: Partial<UsersContext>): Observable<void> {
    return of(this._subject$.next({ ...this._subject$.value, ...state }));
  }
}

export enum USER_PLAN {
  FREE = 'Free',
  STARTER = 'Starter',
  PROFESSIONAL = 'Professional', // Deprecated
  PREMIUM = 'Premium',
  BUSINESS = 'Business',
}

export class PaymentPlanCardsMapper {
  public static planTypeToFeatureDescriptionHeader: Map<string, string> = new Map<
    USER_PLAN,
    string
  >([
    [USER_PLAN.FREE, 'pricing_free'],
    [USER_PLAN.STARTER, 'pricing_starter'],
    [USER_PLAN.PROFESSIONAL, 'pricing_professional'], // Deprecated
    [USER_PLAN.PREMIUM, 'pricing_premium'],
    [USER_PLAN.BUSINESS, 'pricing_business'],
  ]);

  public static planTypeToFeatureDescriptions: Map<string, string[]> = new Map<USER_PLAN, string[]>(
    [
      [USER_PLAN.FREE, ['pricing_free_point_1', 'pricing_free_point_2', 'pricing_free_point_3']], // TODO: To be introduced later
      [
        USER_PLAN.STARTER,
        [
          'pricing_starter_point_1',
          'pricing_starter_point_2',
          'pricing_starter_point_3',
          'pricing_starter_point_4',
          'pricing_starter_point_5',
        ],
      ],
      // Deprecated
      [
        USER_PLAN.PROFESSIONAL,
        [
          'pricing_professional_point_1',
          'pricing_professional_point_2',
          'pricing_professional_point_3',
          'pricing_professional_point_4',
        ],
      ],
      [
        USER_PLAN.BUSINESS,
        [
          'pricing_business_point_1',
          'pricing_business_point_2',
          'pricing_business_point_3',
          'pricing_business_point_4',
        ],
      ],
      [USER_PLAN.PREMIUM, ['pricing_premium_point_1', 'pricing_premium_point_2']],
    ],
  );
}

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ImpersonatedUser } from '../domain/impersonated-user';
import { map, shareReplay } from 'rxjs/operators';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken, UserIdentity } from '@app.cobiro.com/user/core';
import { ImpersonatesUser } from '../domain/impersonates-user';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { UserAssignedToTrackingEvent, UserAuthorizedEvent } from '@app.cobiro.com/core/events';
import {
  ClearsContextCommandPort,
  CLEAR_CONTEXT_COMMAND,
} from '@app.cobiro.com/cobiro-pro/context';

@Injectable()
export class UserImpersonationState implements ImpersonatesUser {
  private readonly _impersonatedUser$ = new BehaviorSubject<ImpersonatedUser>(null);
  private readonly _storageKey = 'user-impersonate';

  constructor(
    @Inject(STORAGE) private _storage: Storage,
    @Inject(GETS_USER_FROM_TOKEN) private _getsUser: GetsUserFromToken,
    @Inject(APPLICATION_BUS) private _applicationBus: ApplicationBus,
    @Inject(CLEAR_CONTEXT_COMMAND) private readonly _clearsContextCommand: ClearsContextCommandPort,
  ) {
    const user = _storage.getItem(this._storageKey);

    if (user) {
      this._impersonatedUser$.next(JSON.parse(user));
    }
  }

  get impersonatedUser$(): Observable<ImpersonatedUser> {
    return this._impersonatedUser$.asObservable().pipe(shareReplay(1));
  }

  impersonate(accessToken: string): Observable<boolean> {
    this._clearsContextCommand.clearContext();
    this._applicationBus.dispatch(new UserAuthorizedEvent(accessToken, null));

    return of(this._getsUser.get()).pipe(
      map((user: UserIdentity) => {
        if (user) {
          this._applicationBus.dispatch(new UserAssignedToTrackingEvent(user.userId));
          this._setImpersonationUser({ id: user.userId, email: user.email });
          return true;
        }

        return false;
      }),
    );
  }

  clearImpersonation() {
    this._storage.removeItem(this._storageKey);
    this._impersonatedUser$.next(null);
  }

  private _setImpersonationUser(user: ImpersonatedUser) {
    this._storage.setItem(this._storageKey, JSON.stringify(user));
    this._impersonatedUser$.next(user);
  }
}

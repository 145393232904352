import { NgModule } from '@angular/core';
import { GETS_PLAN_ESTIMATE_DTO } from '../../../../application/ports/secondary/dto/payments/gets-plan-estimate.dto-port';
import { HttpGetsPlanEstimateService } from './http-gets-plan-estimate.service';

@NgModule({
  providers: [
    HttpGetsPlanEstimateService,
    { provide: GETS_PLAN_ESTIMATE_DTO, useExisting: HttpGetsPlanEstimateService },
  ],
})
export class HttpGetsPlanEstimateServiceModule {}

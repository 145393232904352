<div
  class="cs-p-40 br-10 d-flex flex-column justify-content-center align-items-center cs-max-width-500-xs"
>
  <div class="cs-emoji-circle cs-emoji-circle-secondary">❌</div>
  <h3 class="cs-title-4 cs-mb-10 text-center cs-mt-20">
    {{ 'cobiro_css_cancel_integration_confirmation_title' | language }}
  </h3>
  <p class="cs-text-1 cs-mb-30 text-center cs-white-space-pre-line">
    {{ 'cobiro_css_cancel_integration_confirmation_description' | language }}
  </p>
  <div class="d-flex justify-content-center">
    <button
      class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-stroked"
      data-selector="confirmation-popup-no-btn"
      [matDialogClose]="false"
    >
      {{ 'cobiro_css_cancel_integration_confirmation_no_btn' | language }}
    </button>
    <button class="cs-mr-10 cs-min-width-100-xs cs-btn cs-btn-error" [matDialogClose]="true">
      {{ 'cobiro_css_cancel_integration_confirmation_yes_btn' | language }}
    </button>
  </div>
</div>

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GetsQuestionsQuery } from '../ports/primary/gets-questions.query';
import { GetsQuestionsQueryPort } from '../ports/primary/gets-questions.query-port';
import { QuestionQuery } from '../ports/primary/question.query';
import {
  GetsQuestionsDtoPort,
  GETS_QUESTIONS_DTO,
} from '../ports/secondary/gets-questions.dto-port';
import { QuestionDto } from '../ports/secondary/question.dto';

@Injectable()
export class FaqState implements GetsQuestionsQueryPort {
  constructor(
    @Inject(GETS_QUESTIONS_DTO)
    private readonly _getsQuestionsDtoPort: GetsQuestionsDtoPort,
  ) {}

  getQuestions(query: GetsQuestionsQuery): Observable<QuestionQuery[]> {
    return this._getsQuestionsDtoPort
      .getQuestions({ productName: query.productName })
      .pipe(
        map((questions: QuestionDto[]) => this._mapQuestionsDtoToListQuestionsQuery(questions)),
      );
  }

  private _mapQuestionsDtoToListQuestionsQuery(questions: QuestionDto[]): QuestionQuery[] {
    return questions.map(dto => QuestionQuery.fromDTO(dto));
  }
}

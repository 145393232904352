import { NgModule } from '@angular/core';
import { GETS_QUESTIONS_DTO } from '../../../../application/ports/secondary/gets-questions.dto-port';
import { HttpGetsQuestionService } from './http-gets-questions.service';

@NgModule({
  providers: [
    HttpGetsQuestionService,
    { provide: GETS_QUESTIONS_DTO, useExisting: HttpGetsQuestionService },
  ],
})
export class HttpGetsQuestionServiceModule {}

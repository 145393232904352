import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CardDto } from './card.dto';

export interface GetsPaymentSourcesDtoPort {
  get(teamId: string): Observable<CardDto[]>;
}

export const GETS_PAYMENT_SOURCES_DTO = new InjectionToken<GetsPaymentSourcesDtoPort>(
  'GETS_PAYMENT_SOURCES_DTO',
);

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmailValidator } from '@app.cobiro.com/shared/validators';
import { UserQuery } from 'libs/cobiro-pro-rewrite/users/src/lib/application/ports/primary/user.query';
import {
  GetsWorkspacesListQuery,
  GETS_WORKSPACES_LIST_QUERY,
} from '../../../../../application/ports/primary/gets-workspace-list.query';

@Component({
  selector: 'lib-cobiro-pro-create-user-modal',
  templateUrl: './edit-access-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAccessModalComponent implements OnDestroy {
  readonly workspaces$ = this._getsWorkspacesListQuery.getWorkspaceListQuery();
  readonly userForm: FormGroup = this._fb.group({
    role: ['basic'],
    workspaces: [],
  });

  constructor(
    private readonly _fb: FormBuilder,
    @Inject(GETS_WORKSPACES_LIST_QUERY)
    private readonly _getsWorkspacesListQuery: GetsWorkspacesListQuery,
    @Inject(MAT_DIALOG_DATA) readonly data: { route: ActivatedRoute; user: UserQuery },
    private readonly _router: Router,
  ) {}

  ngOnDestroy(): void {
    this._router.navigate(['.'], { relativeTo: this.data.route });
  }

  onSendInvitationClicked(): void {
    return;
  }
}

<div class="cs-popup text-left message-popup" *ngIf="modalData">
  <h1 class="cs-popup-title cs-mt-10">
    {{ 'notifications_new_message' | language }}
  </h1>

  <div class="cs-mt-30">
    <div>
      <p class="cs-title-8">
        {{ 'notifications_message_date' | language }}
      </p>
      <span class="cs-text-2" data-selector="message-date">{{
        modalData.date | date: 'dd.MM.YY HH:mm'
      }}</span>
    </div>
    <div class="cs-mt-20" *ngFor="let field of modalData.fields">
      <p class="cs-title-8" data-selector="field-name">
        {{ field.name }}
      </p>
      <span class="cs-text-2" data-selector="field-value">{{ field.value }}</span>
    </div>
  </div>

  <a class="cs-popup-close" (click)="dialogRef.close()">
    <mat-icon svgIcon="close" class="cs-z-index-1 cs-size-sm"></mat-icon>
  </a>
</div>

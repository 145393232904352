import { Dispatcher } from '@cobiro/eda';
import { InjectionToken } from '@angular/core';
import { ClientDTO } from './client.dto';

// TODO (PRO-DEBT): remove dependency to Dispatcher interface
export interface ClientsListLoadedDispatcherPort
  extends Dispatcher<{ clients: ClientDTO[]; teamId: string }> {
  dispatch(event: { clients: ClientDTO[]; teamId: string }): void;
}

export const CLIENTS_LIST_LOADED_DISPATCHER = new InjectionToken<ClientsListLoadedDispatcherPort>(
  'CLIENTS_LIST_LOADED_DISPATCHER',
);

import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  LOADS_TEAMS_SETTINGS_COMMAND_PORT,
  LoadsTeamSettingsCommandPort,
} from '../../../application/ports/primary/loads-team-settings.command-port';
import {
  GETS_TEAM_SETTINGS_QUERY_PORT,
  GetsTeamSettingsQueryPort,
} from '../../../application/ports/primary/gets-team-settings.query-port';
import {
  OPENS_NO_TEAMS_BILLING_MESSAGE_COMMAND_PORT,
  OpensNoTeamsBillingMessageCommandPort,
} from '../../../application/ports/primary/opens-no-teams-billing-message-command.port';

// TODO: decide what to do with two command handlers in one adapter
@Injectable()
export class HasTeamBillingGuard {
  constructor(
    @Inject(LOADS_TEAMS_SETTINGS_COMMAND_PORT)
    private readonly _loadsTeamSettingsCommandPort: LoadsTeamSettingsCommandPort,
    @Inject(GETS_TEAM_SETTINGS_QUERY_PORT)
    private readonly _getsTeamSettingsQueryPort: GetsTeamSettingsQueryPort,
    @Inject(OPENS_NO_TEAMS_BILLING_MESSAGE_COMMAND_PORT)
    private readonly _opensNoTeamsBillingMessageCommandPort: OpensNoTeamsBillingMessageCommandPort,
  ) {}

  canActivate(): Observable<boolean> {
    return this._loadsTeamSettingsCommandPort.loadTeamsSettings().pipe(
      switchMap(() =>
        this._getsTeamSettingsQueryPort
          .getTeamSettings()
          .pipe(map(settings => !!settings.countryCode)),
      ),
      tap(hasBilling => {
        !hasBilling && this._opensNoTeamsBillingMessageCommandPort.open();
      }),
    );
  }
}

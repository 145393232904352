import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesNewSubscriptionCommand } from './creates-new-subscription.command';

export const CREATES_NEW_SUBSCRIPTION_COMMAND =
  new InjectionToken<CreatesNewSubscriptionCommandPort>('CREATES_NEW_SUBSCRIPTION_COMMAND');

export interface CreatesNewSubscriptionCommandPort {
  createNewSubscription(command: CreatesNewSubscriptionCommand): Observable<void>;
}

import { Observable } from 'rxjs';
import { CountryQuery } from './country.query';
import { InjectionToken } from '@angular/core';

export interface GetsCountriesQueryPort {
  getCountriesQuery(): Observable<CountryQuery[]>;
}

export const GETS_COUNTRIES_QUERY = new InjectionToken<GetsCountriesQueryPort>(
  'GETS_COUNTRIES_QUERY',
);

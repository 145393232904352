import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationContext } from './integration.context';

export const PATCHES_INTEGRATION_CONTEXT = new InjectionToken<PatchesIntegrationContextPort>(
  'PATCHES_INTEGRATION_CONTEXT',
);

export interface PatchesIntegrationContextPort {
  patch(data: Partial<IntegrationContext>): Observable<void>;
}

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SETS_WORKSPACE_LIST_SEARCH_PHRASE_COMMAND_PORT,
  SetsSearchPhraseCommandPort,
} from '../../../../../application/ports/primary/sets-search-phrase.command-port';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  mergeMap,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  CreatesWorkspaceCommandPort,
  CREATES_WORKSPACE_COMMAND,
} from '../../../../../application/ports/primary/creates-workspace.command-port';
import {
  EditWorkspaceCommandPort,
  EDIT_WORKSPACE_COMMAND,
} from '../../../../../application/ports/primary/edits-workspace.command-port';
import {
  DeleteWorkspaceCommandPort,
  DELETE_WORKSPACE_COMMAND,
} from 'libs/cobiro-pro-rewrite/workspaces/src/lib/application/ports/primary/deletes-workspace.command-port';

@Component({
  selector: 'lib-cobiro-pro-workspace-list-control',
  templateUrl: './workspace-list-control.component.html',
  styleUrls: ['./workspace-list-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceListControlComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  public readonly searchControl = new FormControl<string>('');
  private routeQueryParams$: Subscription;

  constructor(
    @Inject(SETS_WORKSPACE_LIST_SEARCH_PHRASE_COMMAND_PORT)
    private readonly _setsSearchPhraseCommand: SetsSearchPhraseCommandPort,
    @Inject(CREATES_WORKSPACE_COMMAND)
    private readonly _createsWorkspaceCommandPort: CreatesWorkspaceCommandPort,
    @Inject(EDIT_WORKSPACE_COMMAND)
    private readonly _editWorkspaceCommandPort: EditWorkspaceCommandPort,
    @Inject(DELETE_WORKSPACE_COMMAND)
    private readonly _deleteWorkspaceCommandPort: DeleteWorkspaceCommandPort,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(searchValue =>
          this._setsSearchPhraseCommand.setSearchPhrase({ phrase: searchValue }),
        ),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();

    this.routeQueryParams$ = this._route.queryParams
      .pipe(
        mergeMap(params => {
          if (params['add']) {
            return this._createsWorkspaceCommandPort
              .createWorkspace({
                route: this._route,
                shouldBeRedirected: false,
              })
              .pipe(catchError(() => of(undefined)));
          }

          if (params['edit']) {
            return this._editWorkspaceCommandPort
              .editWorkspace({
                id: params['edit'],
                route: this._route,
              })
              .pipe(catchError(() => of(undefined)));
          }

          if (params['delete']) {
            return this._deleteWorkspaceCommandPort
              .deleteWorkspace({
                id: params['delete'],
                route: this._route,
              })
              .pipe(catchError(() => of(undefined)));
          }

          return of(void 0);
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onCreateNewWorkspaceClicked() {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        add: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

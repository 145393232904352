import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProSelectPlanComponent } from './select-plan.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { SubscriptionListComponentModule } from './subscription-list/subscription-list.component-module';

@NgModule({
  declarations: [CobiroProSelectPlanComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatDialogModule,
    ReactiveFormsModule,
    HuiLoaderContainerModule,
    SubscriptionListComponentModule,
  ],
  exports: [CobiroProSelectPlanComponent],
})
export class CobiroProSelectPlanModule {}

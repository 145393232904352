import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT =
  new InjectionToken<GetsLabelManagerErrorMessageQueryPort>(
    'GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT',
  );

export interface GetsLabelManagerErrorMessageQueryPort {
  getErrorMessage(): Observable<string>;
}

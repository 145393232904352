import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CssFaqComponent } from './css-faq.component';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatExpansionModule } from '@angular/material/expansion';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [CssFaqComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatExpansionModule,
    HuiLoaderContainerModule,
    RouterModule.forChild([{ path: '', component: CssFaqComponent }]),
  ],
  exports: [CssFaqComponent],
})
export class CssFaqComponentModule {}

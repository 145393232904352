import { ClientDTO } from '../secondary/client.dto';
import { InjectionToken } from '@angular/core';

// TODO (PRO-DEBT): Create command Class
export interface StoresClientsListCommandPort {
  storeClientsList(clients: ClientDTO[], teamId?: string): void;
}

export const STORES_CLIENTS_LIST_COMMAND = new InjectionToken<StoresClientsListCommandPort>(
  'STORES_CLIENTS_LIST_COMMAND',
);

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, TokenLoginGuard } from '@app.cobiro.com/auth';
import {
  ShowSignUpFormGuard,
  ShowSignUpFormGuardModule,
} from '@app.cobiro.com/cobiro-pro-rewrite/team-switcher';
import { CheckAccessToAppGuardModule } from '@app.cobiro.com/cobiro-pro-team';
import {
  FetchCobiroProContextResolver,
  IsProModeGuard,
  VerifyLatestProLoginResolver,
} from '@app.cobiro.com/cobiro-pro/context';
import { LoadUserAdditionalDataResolver } from '@app.cobiro.com/user/additional-data';
import { ImpersonatesUserGuard } from '@app.cobiro.com/user/impersonation';
import {
  LoginPageComponent,
  PasswordChangePageComponent,
  PasswordRecoveryPageComponent,
  SaveUserLatestLoginRedirectResolver,
} from '@app.cobiro.com/user/login';
import { VerifyPageComponent } from '@app.cobiro.com/user/register';
import { FeatureFlagGuard } from '@cobiro/ng-feature-flags';
import { NotAuthenticatedGuard } from '../../../../libs/auth/src/lib/application/not-authenticated-guard/not-authenticated.guard';
import { RumResolver } from '../../../../libs/core/logging/src/lib/datadog/rum.resolver';
import { RedirectGoogleComponent } from '../../../../libs/google/shared/src/lib/redirect-google/redirect-google.component';
import { RolesGuard } from '../../../../libs/roles/src/lib/guards/roles.guard';
import { NotFoundComponent } from '../../../../libs/shared/not-found/not-found.component';
import { SuccessNonCssLinkingComponent } from '@app.cobiro.com/cobiro-pro-rewrite/label-manager';

export const routingConfiguration: any = {
  paramsInheritanceStrategy: 'always',
  enableTracing: false,
  scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
  {
    path: 'logout',
    redirectTo: '/user/logout',
  },
  {
    path: 'login',
    redirectTo: '/auth/login',
  },
  // TODO: Move to module
  {
    path: 'user/signup',
    redirectTo: 'auth/register',
  },
  {
    path: 'sites',
    redirectTo: 'connect',
  },
  {
    path: 'auth/register',
    loadChildren: () =>
      import('../pages/sign-up-flow-rewrite/sign-up-flow-rewrite.page-module').then(
        m => m.SignUpFlowRewritePageModule,
      ),
  },
  {
    path: 'auth/register/verify',
    component: VerifyPageComponent,
    data: {
      featureFlags: ['registration_flow'],
    },
    canActivate: [FeatureFlagGuard],
  },
  {
    path: 'auth/impersonate',
    children: [{ path: '', redirectTo: '', pathMatch: 'full' }],
    canActivate: [ImpersonatesUserGuard],
  },
  {
    path: 'auth/register-pro',
    loadChildren: () => import('@app.cobiro.com/user/cobiro-pro').then(m => m.UserCobiroProModule),
  },
  {
    path: 'auth/login',
    data: {
      featureFlags: ['registration_flow'],
      utmInterest: 'utmInterestResolver',
    },
    canActivate: [FeatureFlagGuard, NotAuthenticatedGuard],
    children: [
      {
        path: 'password-recovery',
        component: PasswordRecoveryPageComponent,
      },
      {
        path: 'password-change',
        component: PasswordChangePageComponent,
      },
      {
        path: '',
        component: LoginPageComponent,
        canActivate: [TokenLoginGuard],
      },
    ],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('../../../../libs/auth/src/lib/legacy/legacy-user.module').then(
        m => m.LegacyUserModule,
      ),
  },
  {
    path: 'my-profile',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('../../../cobiro/src/pages/my-profile/my-profile.page-module').then(
        m => m.MyProfilePageModule,
      ),
  },
  {
    path: 'connect',
    canActivate: [AuthenticatedGuard, IsProModeGuard, ShowSignUpFormGuard],
    loadChildren: () =>
      import('../pages/pro-rewrite/pro-rewrite.page-module').then(m => m.ProRewritePageModule),
  },
  {
    path: 'oauth',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('../../../../libs/auth/src/lib/oauth/oauth.module').then(m => m.OAuthModule),
  },
  {
    path: 'redirect',
    children: [
      {
        path: 'google',
        component: RedirectGoogleComponent,
        // DO NOT REMOVE ^. When trying to go to this address manually,
        // it will return 404 and 'undefined' in the URL; it works only with specific components' logic.
      },
    ],
  },
  {
    path: '',
    redirectTo: 'connect',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard, ShowSignUpFormGuard],
    resolve: {
      utmInterest: 'utmInterestResolver',
      hasLogging: RumResolver,
      cobiroProContext: FetchCobiroProContextResolver,
      userAdditionalData: LoadUserAdditionalDataResolver,
      saveLatestUserLoginRedirect: SaveUserLatestLoginRedirectResolver,
    },
    data: { hasTopbar: true, redirectUrl: 'connect' },
    children: [
      {
        path: '',
        canActivateChild: [IsProModeGuard],
        children: [
          {
            path: 'settings', // It is user settings, no sites connection
            loadChildren: () =>
              import('../../../../libs/user/settings/src/lib/settings.module').then(
                m => m.SettingsModule,
              ),
          },
          {
            path: 'admin',
            loadChildren: () =>
              import('../../../../libs/admin-templates/src/lib/admin-templates.module').then(
                m => m.AdminTemplatesModule,
              ),
            canActivate: [RolesGuard],
            data: {
              roles: ['admin'],
            },
          },
        ],
      },
    ],
  },
  {
    path: 'pro-redirect',
    resolve: { verify: VerifyLatestProLoginResolver },
    children: [
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'success-linking',
    component: SuccessNonCssLinkingComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, routingConfiguration),
    CheckAccessToAppGuardModule,
    ShowSignUpFormGuardModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

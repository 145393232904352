import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const CREATES_AGENCY_WITH_WORKSPACE_DTO =
  new InjectionToken<CreatesAgencyWithWorkspaceDtoPort>('CREATES_AGENCY_DTO');

export interface CreatesAgencyWithWorkspaceDtoPort {
  createAgencyWithWorkspace(args: {
    workspaceName: string;
    companyType: string;
    numberOfEmployees: number | null;
    numberOfClients: number | null;
    owner: {
      firstName: string;
      lastName: string;
      companyName: string;
      country: string;
      phoneNumber: string | null;
    };
  }): Observable<void>;
}

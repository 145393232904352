import { NgModule } from '@angular/core';
import { GETS_BILLING_DATA_DTO_PORT } from '../../../../application/ports/secondary/dto/payments/gets-billing-data-dto.port';
import { HttpGetsBillingDataService } from './http-gets-billing-data.service';

@NgModule({
  providers: [
    HttpGetsBillingDataService,
    { provide: GETS_BILLING_DATA_DTO_PORT, useExisting: HttpGetsBillingDataService },
  ],
})
export class HttpGetsBillingDataServiceModule {}

import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GetsEmailConsentsDtoPort } from '../../../../application/ports/secondary/gets-email-consents.dto-port';
import { SavesEmailConsentDtoPort } from '../../../../application/ports/secondary/saves-email-consent.dto-port';
import { EmailConsentDto } from '../../../../application/ports/secondary/email-consent.dto';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { HasData } from '@cobiro/jsonapi';

interface EmailConsentsAttributes {
  [key: string]: boolean;
}

@Injectable()
export class HttpMarketingPreferencesService
  implements SavesEmailConsentDtoPort, GetsEmailConsentsDtoPort
{
  constructor(
    private readonly _client: HttpClient,
    @Inject(ENV_CONFIG) private readonly _getsConfig: GetsConfig,
  ) {}

  saveEmailConsent(channel: string, consent: boolean): Observable<boolean> {
    const payload = {
      data: {
        type: 'email-consents',
        attributes: {
          [channel]: consent,
        },
      },
    };
    return this._client
      .patch(`${this._getsConfig.get('hub')}/v1/users/email-consents`, payload)
      .pipe(map(() => true));
  }

  getEmailConsent(): Observable<EmailConsentDto[]> {
    return this._client
      .get<HasData<EmailConsentsAttributes>>(
        `${this._getsConfig.get('hub')}/v1/users/email-consents`,
      )
      .pipe(
        map(response =>
          Object.keys(response.data.attributes).map(channel => ({
            name: channel,
            consent: response.data.attributes[channel],
          })),
        ),
      );
  }
}

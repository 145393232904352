/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HasData } from '@cobiro/jsonapi';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { ChecksDiscountCodeParamsDto } from '../../../../application/ports/secondary/dto/payments/checks-discount-code-params.dto';
import { DiscountCodeCheckResult } from '../../../../application/ports/secondary/dto/payments/checks-discount-code-result.dto';
import { ChecksDiscountCodeDtoPort } from '../../../../application/ports/secondary/dto/payments/checks-discount-code.dto-port';
import { DiscountDto } from '../../../../application/ports/secondary/dto/payments/discount.dto';

export interface DiscountCodeCheckAttributes {
  duration: {
    type: 'period' | 'forever' | 'one_time';
    period: number | null;
    unit: 'day' | 'week' | 'month' | 'year' | null;
  };
  type: 'percentage' | 'flat';
  value: number;
}

@Injectable()
export class HttpChecksDiscountCodeService implements ChecksDiscountCodeDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  checkDiscountCode(dto: ChecksDiscountCodeParamsDto): Observable<DiscountCodeCheckResult> {
    return this._client
      .get<HasData<DiscountCodeCheckAttributes>>(
        `v1/payments/discount-codes/${dto.code}?planId=${dto.planId}`,
      )
      .pipe(map(response => new DiscountCodeCheckResult(DiscountDto.fromResponse(response))));
  }
}

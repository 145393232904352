import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProWelcomeUserComponent } from './welcome-user.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { RouterModule } from '@angular/router';
import { CobiroProOnboardingListComponentModule } from '../onboarding-list/onboarding-list.component-module';
import { CobiroProOverviewWorkspaceComponentModule } from '../overview-workspace/overview-workspace.component-module';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  declarations: [CobiroProWelcomeUserComponent],
  imports: [
    CommonModule,
    LanguageModule,
    RouterModule,
    HuiLoaderContainerModule,
    CobiroProOnboardingListComponentModule,
    CobiroProOverviewWorkspaceComponentModule,
  ],
  exports: [CobiroProWelcomeUserComponent],
})
export class CobiroProWelcomeUserComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CreatesUserCommand } from './creates-user.command';

export const CREATES_USER_COMMAND = new InjectionToken<CreatesUserCommandPort>(
  'CREATES_USER_COMMAND',
);

export interface CreatesUserCommandPort {
  createUser(command: CreatesUserCommand): Observable<void>;
}

import { SubscriptionDto } from '../../secondary/dto/clients/subscription.dto';
import { ClientPlanQuery } from './plan.query';

export class ClientSubscriptionQuery {
  constructor(
    public readonly productName: string,
    public readonly planType: ClientPlanQuery | null,
    public readonly subscriptionId: string,
  ) {}
  static fromDTO(dto: SubscriptionDto): ClientSubscriptionQuery {
    return new ClientSubscriptionQuery(
      dto.productName,
      dto.planType ? new ClientPlanQuery(dto.planType) : null,
      dto.subscriptionId,
    );
  }
}

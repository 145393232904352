import { NgModule } from '@angular/core';
import { LabelManagerNonCssIntegrationState } from './label-manager-non-css-integration.state';
import { INITS_NON_CSS_INTEGRATION_COMMAND } from '../../ports/primary/inits-non-css-integration.command-port';
import { GETS_AGENCY_ACCESS_COMMAND_PORT } from '../../ports/primary/gets-agency-access-link.command-port';
import { CREATES_NON_CSS_INTEGRATION_QUERY } from '../../ports/primary/creates-non-css-integration.query-port';
import { GOES_TO_NEXT_STEP_COMMAND } from '../../ports/primary/goes-to-next-step.command-port';
import { GOES_TO_PREVIOUS_STEP_COMMAND } from '../../ports/primary/goes-to-previous-step.command-port';
import { GETS_NON_CSS_INTEGRATION_QUERY } from '../../ports/primary/gets-non-css-integration.query-port';
import { IS_DUMMY_INTEGRATION_CREATED_QUERY_PORT } from '../../ports/primary/is-dummy-integration-created.query-port';
import { CHANGES_NO_CACHE_FLAG_COMMAND_PORT } from '../../ports/primary/change-no-cache-flag.command-port';

@NgModule({
  providers: [
    LabelManagerNonCssIntegrationState,
    {
      provide: INITS_NON_CSS_INTEGRATION_COMMAND,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: GETS_AGENCY_ACCESS_COMMAND_PORT,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: CREATES_NON_CSS_INTEGRATION_QUERY,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: GOES_TO_PREVIOUS_STEP_COMMAND,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: GOES_TO_NEXT_STEP_COMMAND,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: GETS_NON_CSS_INTEGRATION_QUERY,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: IS_DUMMY_INTEGRATION_CREATED_QUERY_PORT,
      useExisting: LabelManagerNonCssIntegrationState,
    },
    {
      provide: CHANGES_NO_CACHE_FLAG_COMMAND_PORT,
      useExisting: LabelManagerNonCssIntegrationState,
    },
  ],
})
export class LabelManagerNonCssIntegrationStateModule {}

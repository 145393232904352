import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMetadataDto } from './app-metadata.dto';

export const GETS_APPS_META_DTO_PORT = new InjectionToken<GetsAppsMetaDtoPort>(
  'GETS_APPS_META_DTO_PORT',
);

export interface GetsAppsMetaDtoPort {
  getAppsMeta(): Observable<AppMetadataDto[]>;
}

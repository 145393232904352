import { NgModule } from '@angular/core';
import { HttpClientsService } from './http-clients.service';
import { GETS_ALL_CLIENT_DTO } from '../../../application/ports/secondary/gets-all-client.dto-port';
import { ADDS_CLIENT_DTO } from '../../../application/ports/secondary/adds-client.dto-port';
import { SETS_CLIENT_DTO } from '../../../application/ports/secondary/sets-client.dto-port';
import { DELETES_CLIENT_DTO } from '../../../application/ports/secondary/deletes-client.dto-port';

@NgModule({
  providers: [
    HttpClientsService,
    { provide: GETS_ALL_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: ADDS_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: SETS_CLIENT_DTO, useExisting: HttpClientsService },
    { provide: DELETES_CLIENT_DTO, useExisting: HttpClientsService },
  ],
})
export class HttpClientsServiceModule {}

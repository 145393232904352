import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { CobiroProTeamsLoadedEvent } from '@app.cobiro.com/core/events';
import {
  SETS_TEAM_SWITCHER_ITEMS_COMMAND,
  SetsTeamSwitcherItemsCommandPort,
  TeamSwitcherItem,
} from '../../../application/ports/primary/sets-team-switcher-items-command.port';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ProTeamsSwitcherTeamsLoadedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProTeamsLoadedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(SETS_TEAM_SWITCHER_ITEMS_COMMAND)
    private readonly _setsTeamSwitcherItemsCommandPort: SetsTeamSwitcherItemsCommandPort,
  ) {}

  handle([event]: [CobiroProTeamsLoadedEvent]): void {
    this._setsTeamSwitcherItemsCommandPort.setTeamSwitcherItems(
      this._adaptEventPayloadToTeamSwitcherItems(event),
    );
  }

  private _adaptEventPayloadToTeamSwitcherItems(
    event: CobiroProTeamsLoadedEvent,
  ): TeamSwitcherItem[] {
    return event.teams.map(({ id, name, avatar, role, membersCount }) => ({
      id,
      name,
      avatar,
      role,
      membersCount,
    }));
  }
}

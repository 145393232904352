import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntroductionStepComponent } from './introduction-step.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [IntroductionStepComponent],
  imports: [CommonModule, LanguageModule, MatCheckboxModule, ReactiveFormsModule],
  exports: [IntroductionStepComponent],
})
export class IntroductionStepComponentModule {}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CancelsClientSubscriptionLMDto } from './cancels-client-subscription-lm.dto';

export const CANCELS_CLIENT_SUBSCRIPTION_LM_DTO =
  new InjectionToken<CancelsClientSubscriptionLMDtoPort>('CANCELS_CLIENT_SUBSCRIPTION_LM_DTO');

export interface CancelsClientSubscriptionLMDtoPort {
  cancel(dto: CancelsClientSubscriptionLMDto): Observable<void>;
}

import { Inject, Pipe, PipeTransform } from '@angular/core';
import {
  GETS_TEAM_SETTINGS_FORM_ERROR_KEY_DTO_PORT,
  GetsTeamSettingsFormErrorKeyDtoPort,
} from '../../../application/ports/secondary/gets-team-settings-form-error-key-dto.port';

@Pipe({
  name: 'teamSettingsFormErrorKey',
})
export class TeamSettingsFormErrorKeyPipe implements PipeTransform {
  constructor(
    @Inject(GETS_TEAM_SETTINGS_FORM_ERROR_KEY_DTO_PORT)
    private readonly _getsTeamSettingsFormErrorKeyDtoPort: GetsTeamSettingsFormErrorKeyDtoPort,
  ) {}
  transform(value: string, errors): string {
    return this._getsTeamSettingsFormErrorKeyDtoPort.getErrorKey(value, errors);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from '../secondary/dto/user.dto';

export const LOAD_WORKSPACE_USERS_COMMAND = new InjectionToken<LoadWorkspaceUsersCommandPort>(
  'LOAD_WORKSPACE_USERS_COMMAND',
);

export interface LoadWorkspaceUsersCommandPort {
  loadWorkspaceUsers(): Observable<UserDTO[]>;
}

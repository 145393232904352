import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CobiroProPersonalInfoComponent } from './personal-info.component';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ReactiveFormsModule } from '@angular/forms';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { SharedImageUploadModule } from '@app.cobiro.com/shared/image-upload';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [CobiroProPersonalInfoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', component: CobiroProPersonalInfoComponent }]),
    LanguageModule,
    ReactiveFormsModule,
    HuiLoaderContainerModule,
    SharedImageUploadModule,
    MatAutocompleteModule,
  ],
  exports: [CobiroProPersonalInfoComponent],
})
export class CobiroProPersonalInfoModule {}

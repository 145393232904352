import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Client } from '@optimizely/optimizely-sdk';
import { map } from 'rxjs/operators';
import { GetsFeatureFlags, UserContext } from '../../application/gets-feature.flags';

@Injectable()
export class OptimizelyFeatureFlagsService implements GetsFeatureFlags {
  constructor(private optimizelyClient: Client) {}

  getAll(userContext: UserContext): Observable<Set<string>> {
    return from(this.optimizelyClient.onReady()).pipe(
      map(() =>
        this.optimizelyClient.getEnabledFeatures(userContext.id, { email: userContext.email }),
      ),
      map(flags => new Set(flags)),
    );
  }
}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientServicesQuery } from './client-service.query';

export interface GetsClientServicesQueryPort {
  getClientServices(siteId: string, sitePublicId: string): Observable<ClientServicesQuery>;
}

export const GETS_CLIENT_SERVICES_QUERY = new InjectionToken<GetsClientServicesQueryPort>(
  'GETS_CLIENT_SERVICES_QUERY',
);

import { InjectionToken } from '@angular/core';
import { SaveClientCommand } from './save-client.command';

// TODO (PRO-DEBT): rename file saves-client.command-port.ts

export const SAVES_CLIENT_COMMAND = new InjectionToken<SavesClientCommandPort>(
  'SAVES_CLIENT_COMMAND',
);

export interface SavesClientCommandPort {
  saveClient(command: SaveClientCommand): void;
}

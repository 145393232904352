import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeletesWorkspaceUserCommand } from './deletes-workspace-user.command';

export const DELETE_WORKSPACE_USER_COMMAND = new InjectionToken<DeleteWorkspaceUserCommandPort>(
  'DELETE_WORKSPACE_USER_COMMAND',
);

export interface DeleteWorkspaceUserCommandPort {
  deleteUser(command: DeletesWorkspaceUserCommand): Observable<void>;
}

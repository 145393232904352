import { CreatePaymentSourceDto } from './create-payment-source.dto';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { CardDto } from './card.dto';

export interface CreatesPaymentSourceDtoPort {
  createPaymentSource(
    dto: CreatePaymentSourceDto,
  ): Observable<Pick<CardDto, 'id' | 'lastDigits' | 'brand'>>;
}

export const CREATES_PAYMENT_SOURCE_DTO_PORT = new InjectionToken<CreatesPaymentSourceDtoPort>(
  'CREATES_PAYMENT_SOURCE_DTO_PORT',
);

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientDTO } from './client.dto';
import { GetsOneClientDto } from './gets-one-client.dto';

export const GETS_ONE_CLIENT_DTO = new InjectionToken<GetsOneClientDtoPort>('GETS_ONE_CLIENT_DTO');

export interface GetsOneClientDtoPort {
  getOne(dto: GetsOneClientDto): Observable<ClientDTO>;
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HuiStepperComponent } from '@app.cobiro.com/shared/hui/stepper';
import { UpgradePlanProcess } from './upgrade-plan.process';
import { UpgradePlanModalData } from './upgrade-plan-modal-data';
import { PaymentPlansState } from '../../../core/application/state/payment-plans.state';
import { USER_PLAN } from '../../../core/application/ports/primary/payment-plan-cards-mapper';
import { map } from 'rxjs/operators';
import { PaymentPlanCardQuery } from '../../../core/application/ports/primary/payment-plan-cards.query';
import { combineLatest, Observable } from 'rxjs';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';

@Component({
  selector: 'lib-upgrade-plan-flow-container',
  templateUrl: './upgrade-plan-flow-container.component.html',
  styleUrls: ['./upgrade-plan-flow-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradePlanFlowContainerComponent implements AfterViewInit {
  @ViewChild('stepper') stepper: HuiStepperComponent;

  readonly currentlySelectedPlanName$ = this._paymentPlansState.selectedPlanName$;
  readonly currentlySelectedPlanPeriod$ = this._paymentPlansState.selectedPlanPeriod$;
  readonly paymentPlans$ = combineLatest([this._paymentPlansState.paymentPlans$]).pipe(
    map(([paymentPlans]) => {
      return paymentPlans
        .filter(paymentPlan => !paymentPlan.isFree())
        .map(paymentPlan => PaymentPlanCardQuery.fromDomain(paymentPlan))
        .map(paymentPlan =>
          paymentPlan.name.toLowerCase() === 'starter' // todo temp solution: remove it
            ? this._filterFeatureForStarter(paymentPlan)
            : paymentPlan,
        );
    }),
  );
  readonly selectedPlanDescription$: Observable<PaymentPlanCardQuery> = combineLatest([
    this.currentlySelectedPlanName$,
    this.paymentPlans$,
  ]).pipe(
    map(([planName, planQuery]) => {
      return planQuery.find(plan => plan.name === planName);
    }),
  );
  readonly emojiMap = new Map<USER_PLAN, string>([
    [USER_PLAN.STARTER, '🥳'],
    [USER_PLAN.PROFESSIONAL, '👔'], // Deprecated
    [USER_PLAN.BUSINESS, '👔'],
    [USER_PLAN.PREMIUM, '👑'],
  ]);

  constructor(
    public readonly dialogRef: MatDialogRef<UpgradePlanFlowContainerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public readonly modalData: UpgradePlanModalData,
    private readonly _process: UpgradePlanProcess,
    private readonly _cd: ChangeDetectorRef,
    private readonly _paymentPlansState: PaymentPlansState,
    private readonly _featureState: FeatureFlagsState,
  ) {}

  onBackButtonClicked(): void {
    this._process.previousStep();
  }

  ngAfterViewInit(): void {
    this._process.init(this.stepper, this.dialogRef, this.modalData?.planSelection);

    // since there's stepper update when modal has additional data, we have
    // ExpressionChangedAfterItHasBeenCheckedError
    // to prevent this error happening we run here additional changeDetection hook
    this._cd.detectChanges();
  }

  closeDialog(): void {
    this._process.end(false);
  }

  isMonthly(period: 'yearly' | 'monthly'): boolean {
    return period === 'monthly'; // TODO: change to ennum PaymentPlanPeriod and replace everywhere
  }

  onComparePlansClicked(): void {
    this._paymentPlansState.redirectToPlanComparison();
    this._process.end(false);
  }

  private _filterFeatureForStarter(paymentPlan: PaymentPlanCardQuery): PaymentPlanCardQuery {
    const featureMap = new Map<string, boolean>([]);
    return new PaymentPlanCardQuery(
      paymentPlan.name,
      paymentPlan.description,
      paymentPlan.subscriptionPeriods,
      paymentPlan.featureHeader,
      paymentPlan.featureDescriptions.filter(f => !featureMap.get(f)),
      paymentPlan.isRecommended,
    );
  }
}

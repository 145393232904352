import { NgModule } from '@angular/core';
import {
  SIGNS_UP_BY_FACEBOOK_COMMAND,
  SIGNS_UP_BY_GOOGLE_COMMAND,
} from '@app.cobiro.com/user/social-sign-up';
import { SIGNS_UP_BY_EMAIL_COMMAND } from '../ports/primary/signs-up-by-email.command-port';
import { SignUpState } from './sign-up.state';

@NgModule({
  providers: [
    SignUpState,
    { provide: SIGNS_UP_BY_EMAIL_COMMAND, useExisting: SignUpState },
    {
      provide: SIGNS_UP_BY_FACEBOOK_COMMAND,
      useExisting: SignUpState,
    },
    { provide: SIGNS_UP_BY_GOOGLE_COMMAND, useExisting: SignUpState },
  ],
})
export class SignUpStateModule {}

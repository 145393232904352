<div
  class="position-relative cs-max-width-800-xs d-flex flex-column mx-auto cs-ph-40 cs-pb-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="d-flex flex-column cs-mb-30">
    <h3 class="cs-title-4 cs-mb-15" data-selector="cobiro-pro-setup-select-plan-header">
      {{ 'cobiro_pro_setup_select_a_plan' | language }}
    </h3>
  </div>
  <lib-cobiro-pro-subscription-list
    class="w-100"
    [product]="productName$ | async"
    (savedPlanClicked)="onSavedPlanClicked($event)"
  ></lib-cobiro-pro-subscription-list>
</div>

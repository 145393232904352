import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ClientDTO } from './client.dto';
import { GetsAllClientDto } from './gets-all-client.dto';

export const GETS_ALL_CLIENT_DTO = new InjectionToken<GetsAllClientDtoPort>('GETS_ALL_CLIENT_DTO');

export interface GetsAllClientDtoPort {
  getAll(dto: GetsAllClientDto): Observable<ClientDTO[]>;
}

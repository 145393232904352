import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AnalyticsData } from './analytics-data';

@Directive({
  selector: '[libAnalytics]',
})
export class AnalyticsDirective implements AfterViewInit, OnDestroy {
  @Input() libAnalytics: AnalyticsData;

  private _onDestroy$ = new Subject<void>();

  constructor(private _elRef: ElementRef, private _renderer: Renderer2, private _router: Router) {}

  ngAfterViewInit(): void {
    if (!this.libAnalytics) {
      return;
    }

    if (this.libAnalytics.length < 2) {
      throw new Error('Missing analytics parameters');
    }

    const [container, element] = this.libAnalytics;

    this._router.events.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this._renderer.setAttribute(this._elRef.nativeElement, 'data-page', this._router.url);
    });

    this._renderer.setAttribute(this._elRef.nativeElement, 'data-container', container);
    this._renderer.setAttribute(this._elRef.nativeElement, 'data-element', element);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ENV_CONFIG, provideWindowConfig, WINDOW } from '@app.cobiro.com/core/utils';
import { RumResolver } from './rum.resolver';

const config = provideWindowConfig();

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    RumResolver,
    { provide: WINDOW, useValue: window },
    { provide: ENV_CONFIG, useValue: { get: () => config.name } },
  ],
})
export class DatadogModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ConfirmDeleteUserModalComponent } from './confirm-delete-user-modal.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatDialogModule],
  declarations: [ConfirmDeleteUserModalComponent],
})
export class ConfirmDeleteUserModalComponentModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, switchMap } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';
import { ResendsCodeCommand } from '../../../application/ports/primary/resends-code.command';
import {
  RESENDS_CODE_COMMAND,
  ResendsCodeCommandPort,
} from '../../../application/ports/primary/resends-code.command-port';
import { VerifiesUserCommand } from '../../../application/ports/primary/verifies-user.command';
import {
  VERIFIES_USER_COMMAND,
  VerifiesUserCommandPort,
} from '../../../application/ports/primary/verifies-user.command-port';

@Component({
  selector: 'lib-verify-code-form',
  templateUrl: './verify-code-form.component.html',
  styleUrls: ['./verify-code-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyCodeFormComponent implements OnInit {
  codeControl = new UntypedFormControl('', [Validators.required]);

  isProcessing = false;

  constructor(
    @Inject(RESENDS_CODE_COMMAND) private readonly _resendsCodeCommand: ResendsCodeCommandPort,
    @Inject(VERIFIES_USER_COMMAND) private readonly _verifiesUserCommand: VerifiesUserCommandPort,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._route.queryParamMap
      .pipe(
        map(params => {
          return {
            userId: params.get('userId'),
            pin: params.get('pin'),
          };
        }),
        filter(({ userId, pin }) => !!userId && !!pin),
        take(1),
        switchMap(({ userId, pin }) =>
          this._verifiesUserCommand.verifyUser(new VerifiesUserCommand(+userId, pin)),
        ),
        finalize(() => {
          this.isProcessing = false;
          this._changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();
  }

  submitCode(): void {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this._route.queryParamMap
      .pipe(
        map(params => params.get('userId')),
        take(1),
        switchMap(userId =>
          this._verifiesUserCommand.verifyUser(
            new VerifiesUserCommand(+userId, this.codeControl.value),
          ),
        ),
        finalize(() => {
          this.isProcessing = false;
          this._changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();
  }

  onEnterPressed(event: Event): void {
    event.preventDefault();

    if (this.isProcessing || this.codeControl.invalid) {
      return;
    }

    this.submitCode();
  }

  async onResendClicked(): Promise<void> {
    await firstValueFrom(this._resendsCodeCommand.resendCode(new ResendsCodeCommand()));
  }
}

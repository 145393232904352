import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from './user.dto';

export const RESENDS_INVITATION_DTO = new InjectionToken<ResendsInvitationDtoPort>(
  'RESENDS_INVITATION_DTO',
);

export interface ResendsInvitationDtoPort {
  resendInvitation(dto: Pick<UserDTO, 'id' | 'teamId'>): Observable<void>;
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HasData, HasErrorResponse } from '@cobiro/jsonapi';
import { UUID } from '@app.cobiro.com/core/events';
import { catchError, map } from 'rxjs/operators';
import { DomainError } from '@app.cobiro.com/core/state';
import {
  UserDeleteCannotRemoveProAccountError,
  UserDeleteUnknownError,
  UserDeleteWrongPasswordError,
} from '../../../../application/ports/secondary/deletes-user-error.dto';
import { DeletesUserDtoPort } from '../../../../application/ports/secondary/deletes-user.dto-port';
import { GatewayClient } from '@app.cobiro.com/common/gateway';

export interface DeletesUserAttributes {
  password: string;
}

@Injectable()
export class HttpDeletesUserService implements DeletesUserDtoPort {
  private readonly _errors = new Map<string, DomainError>([
    [UserDeleteWrongPasswordError.code, new UserDeleteWrongPasswordError()],
    [UserDeleteCannotRemoveProAccountError.code, new UserDeleteCannotRemoveProAccountError()],
  ]);

  constructor(private readonly _client: GatewayClient) {}

  delete(password: string): Observable<boolean> {
    const payload: HasData<DeletesUserAttributes> = {
      data: { id: new UUID().value, type: 'delete-my-user', attributes: { password } },
    };

    return this._client.post(`v1/users/delete-my-user`, payload).pipe(
      map(() => true),
      catchError((error: HasErrorResponse) => {
        throw this._handleError(error);
      }),
    );
  }

  private _handleError(err: HasErrorResponse): DomainError {
    const code: string | null = err.error?.errors[0]?.code;
    const error: DomainError | null = this._errors.get(code);

    return error ? error : new UserDeleteUnknownError();
  }
}

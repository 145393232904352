import { Directive, ViewContainerRef } from '@angular/core';

// This is needed for check box input
// Code Sample
// <div class="container" huiInputErrorContainer>
//   <label>
//     <input type="checkbox" formControlName="confirm" /> Some label
//   </label>
// </div>
@Directive({
  selector: '[huiInputErrorContainer]',
})
export class HuiInputErrorContainerDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

import { InjectionToken } from '@angular/core';
import { SetCurrentTeamCommand } from './set-current-team.command';

export const SET_CURRENT_TEAM_COMMAND = new InjectionToken<SetCurrentTeamCommandPort>(
  'SET_CURRENT_TEAM_COMMAND',
);

export interface SetCurrentTeamCommandPort {
  setCurrentTeam(command: SetCurrentTeamCommand): void;
}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export const makeHasSymbolValidator: (symbols: string[]) => ValidatorFn = (symbols: string[]) => {
  if (!symbols || !symbols.length) {
    throw new NoSymbolsProvidedError();
  }
  return (control: AbstractControl) => {
    const regexp = new RegExp(`[${symbols.join('|')}]`);
    return !control.value || regexp.test(control.value) ? null : { noRequiredSymbols: true };
  };
};

export class NoSymbolsProvidedError extends Error {
  message: 'No symbols provided to makeHasSymbolValidator';
  name: 'NO_SYMBOLS_PROVIDED';
}

<div
  class="position-relative cs-max-width-700-xs d-flex flex-column mx-auto cs-ph-40 cs-pb-40 cs-pt-30 justify-content-between align-items-center cs-bg-shade-0"
>
  <div class="d-flex flex-column w-100 h-100 justify-content-between">
    <div class="d-flex flex-column cs-mb-30">
      <h3 class="cs-title-4 cs-mb-15" data-selector="cobiro-pro-setup-client-details-header">
        {{ 'cobiro_pro_setup_shop_details' | language }}
      </h3>
      <p class="cs-text-1" data-selector="cobiro-pro-setup-client-details-sub-header">
        {{ 'cobiro_pro_setup_shop_details_description' | language }}
      </p>
    </div>

    <lib-cobiro-pro-create-new-client-form
      class="d-flex flex-column"
      (formGroupChange)="onFormGroupChange($event)"
      (validityChanged)="onValidityChange($event)"
    ></lib-cobiro-pro-create-new-client-form>
  </div>
  <button
    [class.is-loading]="isProcessing$ | async"
    [disabled]="(isInvalid$ | async) && (isProcessing$ | async)"
    (click)="onFormSubmitted()"
    class="cs-btn cs-btn-primary w-100"
    type="button"
    data-selector="cobiro-pro-create-client-create-btn"
    data-analytics="cobiro-pro-create-client-create-btn"
  >
    {{ '_continue' | language }}
    <mat-icon svgIcon="arrow-right"></mat-icon>
  </button>
</div>

import {
  AppDTO,
  GetsApplicationsDtoPort,
} from '../../application/port/secondary/gets-applications-dto.port';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { HasDataCollection } from '@cobiro/jsonapi';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpGetsAppsService implements GetsApplicationsDtoPort {
  constructor(private _gatewayClient: GatewayClient) {}
  getAll(): Observable<AppDTO[]> {
    return this._gatewayClient
      .get<HasDataCollection<AppDTO>>('v1/app-registry/apps')
      .pipe(map(res => res.data.map(app => app.attributes)));
  }
}

import { Notification, NOTIFICATION_TYPES } from '../notification';

export enum FORM_TYPES {
  contactForm = 'contactForm',
  advancedForm = 'advancedForm',
}

export interface MessageField {
  name: string;
  value: string;
}

export class MessageNotification extends Notification {
  public readonly type = NOTIFICATION_TYPES.MESSAGE;

  constructor(
    public readonly id: string,
    public readonly date: Date,
    isRead: boolean,
    public readonly formId: string,
    public readonly formType: FORM_TYPES,
    public readonly fields: MessageField[],
  ) {
    super(id, date, isRead);
  }
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientsContext } from './clientsContext';

export const PATCHES_CLIENTS_CONTEXT_STORAGE = new InjectionToken<PatchesClientsContextStoragePort>(
  'PATCHES_CLIENTS_CONTEXT_STORAGE',
);

export interface PatchesClientsContextStoragePort {
  patch(state: Partial<ClientsContext>): Observable<void>;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderStepComponent } from './loader-step.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';

@NgModule({
  declarations: [LoaderStepComponent],
  imports: [CommonModule, LanguageModule, HuiLoaderContainerModule, HuiLoadingModule],
  exports: [LoaderStepComponent],
})
export class LoaderStepComponentModule {}

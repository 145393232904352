const STATUS_LABEL_WITH_COLOR_MAP = {
  pending: {
    label: 'cobiro_pro_css_integration_status_pending',
    colorClass: 'css-integration-status-pending',
  },
  active: {
    label: 'cobiro_pro_css_integration_status_active',
    colorClass: 'css-integration-status-active',
  },
  paused: {
    label: 'cobiro_pro_css_integration_status_paused',
    colorClass: 'css-integration-status-paused',
  },
};

export class CssIntegrationStatusQuery {
  private constructor(public readonly label: string, public readonly colorClass: string) {}

  static fromStatus(status: 'pending' | 'active' | 'paused' | null): CssIntegrationStatusQuery {
    if (!status) {
      return new CssIntegrationStatusQuery(
        'cobiro_pro_css_integration_status_none',
        'css-integration-status-none',
      );
    }

    const { label, colorClass } = STATUS_LABEL_WITH_COLOR_MAP[status];
    return new CssIntegrationStatusQuery(label, colorClass);
  }
}

/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TeamIdGetter, TEAM_ID_GETTER } from '@app.cobiro.com/cobiro-pro/context';
import { UUID } from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { concatMap, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { AddUserModalComponent } from '../../adapters/primary/ui/modals/add-user-modal/add-user-modal.component';
import { ConfirmDeleteUserModalComponent } from '../../adapters/primary/ui/modals/confirm-delete-user-modal/confirm-delete-user-modal.component';
import { CreateUserModalComponent } from '../../adapters/primary/ui/modals/create-user-modal/create-user-modal.component';
import { DeleteTeamUserModalComponent } from '../../adapters/primary/ui/modals/delete-team-user-modal/delete-team-user-modal.component';
import { EditAccessModalComponent } from '../../adapters/primary/ui/modals/edit-access-modal/edit-access-modal.component';
import { InviteUserModalComponent } from '../../adapters/primary/ui/modals/invite-user-modal/invite-user-modal.component';
import { AddsUserCommand } from '../ports/primary/adds-user.command';
import { AddsUserCommandPort } from '../ports/primary/adds-user.command-port';
import { ChecksUserInvitationCommand } from '../ports/primary/checks-user-invitation.command';
import { ChecksUserInvitationCommandPort } from '../ports/primary/checks-user-invitation.command-port';
import { CreatesUserCommand } from '../ports/primary/creates-user.command';
import { CreatesUserCommandPort } from '../ports/primary/creates-user.command-port';
import { DeletesTeamUserCommand } from '../ports/primary/deletes-team-user.command';
import { DeletesTeamUserCommandPort } from '../ports/primary/deletes-team-user.command-port';
import { DeletesUserCommand } from '../ports/primary/deletes-user.command';
import { DeletesUserCommandPort } from '../ports/primary/deletes-user.command-port';
import { EditAccessUserCommand } from '../ports/primary/edit-access-user.command';
import { EditAccessUserCommandPort } from '../ports/primary/edit-access-user.command-port';
import { InvitesUserCommand } from '../ports/primary/invites-user.command';
import { InvitesUserCommandPort } from '../ports/primary/invites-user.command-port';
import { ResendsInvitationCommand } from '../ports/primary/resends-invitation.command';
import { ResendsInvitationCommandPort } from '../ports/primary/resends-invitation.command-port';
import {
  SelectsUsersContextStoragePort,
  SELECTS_USERS_CONTEXT_STORAGE,
} from '../ports/secondary/context/selects-users-context.storage-port';
import { UsersContext } from '../ports/secondary/context/usersContext';
import {
  UsersListChangedDispatcherPort,
  USERS_LIST_CHANGED_DISPATCHER,
} from '../ports/secondary/dispatchers/users-list-changed.dispatcher-port';
import { DeletesUserDtoPort, DELETES_USER_DTO } from '../ports/secondary/dto/deletes-user.dto-port';
import {
  ResendsInvitationDtoPort,
  RESENDS_INVITATION_DTO,
} from '../ports/secondary/dto/resends-invitation.dto-port';
import {
  SendsInvitationDtoPort,
  SENDS_INVITATION_DTO,
} from '../ports/secondary/dto/sends-invitation.dto-port';
import { UserDTO } from '../ports/secondary/dto/user.dto';
import { SendsInvitationCommand } from '../ports/primary/sends-invitation.command';
import { ADD_ACCESS_DTO, AddAccessDtoPort } from '../ports/secondary/dto/add-access.dto-port';

@Injectable()
export class UsersState
  implements
    DeletesUserCommandPort,
    InvitesUserCommandPort,
    ResendsInvitationCommandPort,
    ChecksUserInvitationCommandPort,
    AddsUserCommandPort,
    DeletesTeamUserCommandPort,
    CreatesUserCommandPort,
    EditAccessUserCommandPort
{
  constructor(
    @Inject(DELETES_USER_DTO) private readonly _deletesUser: DeletesUserDtoPort,
    @Inject(SENDS_INVITATION_DTO)
    private readonly _sendsInvitationDto: SendsInvitationDtoPort,
    @Inject(RESENDS_INVITATION_DTO)
    private readonly _resendsInvitationDto: ResendsInvitationDtoPort,
    @Inject(USERS_LIST_CHANGED_DISPATCHER)
    private readonly _usersListChangedDispatcher: UsersListChangedDispatcherPort,
    @Inject(SELECTS_USERS_CONTEXT_STORAGE)
    private readonly _selectsUsersContextStorage: SelectsUsersContextStoragePort,
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    @Inject(ADD_ACCESS_DTO)
    private readonly _addAccessDtoPort: AddAccessDtoPort,
    private readonly _alert: HuiAlert,
    private readonly _matDialog: MatDialog,
  ) {}

  editAccessUser(command: EditAccessUserCommand): Observable<void> {
    return this._selectsUsersContextStorage.select().pipe(
      map(conntext => conntext.list.find(user => user.id === command.id)),
      switchMap(user =>
        this._matDialog
          .open(EditAccessModalComponent, {
            minWidth: '300px',
            maxWidth: '400px',
            panelClass: 'cs-mat-dialog',
            data: {
              user: user,
              route: command.route,
            },
          })
          .afterClosed()
          .pipe(),
      ),
    );
  }

  createUser(command: CreatesUserCommand): Observable<void> {
    return this._matDialog
      .open(CreateUserModalComponent, {
        minWidth: '300px',
        maxWidth: '400px',
        panelClass: 'cs-mat-dialog',
        data: {
          route: command.route,
        },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(userFormData =>
          this._sendInvitation({
            userEmail: userFormData.email,
            userRole: userFormData.role,
            workspaceIds: userFormData.workspaces.map(workspace => workspace.id),
          }),
        ),
      );
  }

  private _sendInvitation(command: SendsInvitationCommand): Observable<void> {
    const id = new UUID().value;
    const teamId = this._teamIdGetter.getTeamId();
    const newUserData: UserDTO = {
      id: id,
      teamId: teamId,
      email: command.userEmail,
      role: command.userRole as 'basic_user' | 'owner' | 'admin',
      createdAt: new Date().toISOString().replace('T', ' '),
      workspaces: command.workspaceIds,
    };
    return this._sendsInvitationDto.sendInvitation(newUserData).pipe(
      switchMap(() =>
        command.userRole === 'basic_user'
          ? this._addAccessDtoPort.addAccess({
              teamId: teamId,
              memberId: id,
              workspaceIds: command.workspaceIds,
            })
          : of(void 0),
      ),
      switchMap(() =>
        this._selectsUsersContextStorage.select().pipe(
          take(1),
          map(userContext => userContext.list),
        ),
      ),
      tap({
        next: (userList: UserDTO[]) => {
          const updatedUsers: UserDTO[] = [newUserData, ...userList];
          this._usersListChangedDispatcher.dispatch({ updatedUsers: [...updatedUsers] });
          this._alert.open('success', 'cbr_pro_members_invitation_sent');
        },
        error: err => {
          if (err instanceof HttpErrorResponse && err.status === 409) {
            this._alert.open('error', 'cbr_pro_members_already_invited');
          } else {
            this._alert.open('error', '_something_went_wrong');
          }
          return throwError(() => err);
        },
      }),
      map(() => void 0),
    );
  }

  deleteTeamUser(command: DeletesTeamUserCommand): Observable<void> {
    return this._selectsUsersContextStorage.select().pipe(
      take(1),
      map(context => context.list.find(user => user.id === command.id)),
      switchMap(user =>
        this._matDialog
          .open(DeleteTeamUserModalComponent, {
            minWidth: '400px',
            maxWidth: '700px',
            panelClass: 'cs-mat-dialog',
            data: {
              id: user.id,
              userName: user.name,
              route: command.route,
            },
          })
          .afterClosed()
          .pipe(concatMap(result => (result ? this._deleteTeamUser(command) : of(void 0)))),
      ),
    );
  }

  private _deleteTeamUser(command: DeletesTeamUserCommand): Observable<void> {
    return this._deletesUser
      .deleteUser({ id: command.id, teamId: this._teamIdGetter.getTeamId() })
      .pipe(
        switchMap(() =>
          this._selectsUsersContextStorage.select().pipe(
            take(1),
            map(usersContext => usersContext.list),
          ),
        ),
        tap({
          next: (users: UserDTO[]) => {
            const updatedUsers: UserDTO[] = users.filter(user => user.id !== command.id);
            this._usersListChangedDispatcher.dispatch({ updatedUsers: [...updatedUsers] });
            this._alert.open('success', 'cbr_pro_delete_team_member_success_message');
          },
          error: e => {
            this._alert.open('error', 'cbr_pro_delete_team_member_failed_message');
            return throwError(() => e);
          },
        }),
        map(() => void 0),
      );
  }

  addUser(command: AddsUserCommand): Observable<void> {
    return this._matDialog
      .open(AddUserModalComponent, {
        panelClass: 'cs-mat-dialog',
        data: { route: command.route },
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean));
  }

  checksUserInvitation(command: ChecksUserInvitationCommand): Observable<boolean> {
    return this._selectsUsersContextStorage
      .select()
      .pipe(
        map(
          (userContext: UsersContext) =>
            !!userContext.list.find(user => user.email.includes(command.email)),
        ),
      );
  }

  inviteUser(command: InvitesUserCommand): Observable<void> {
    const id = new UUID().value;
    const teamId = this._teamIdGetter.getTeamId();
    return this._matDialog
      .open(InviteUserModalComponent, {
        panelClass: 'cs-mat-dialog',
        data: { route: command.route },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(userFormData => {
          const newUserData: UserDTO = {
            id: id,
            teamId: teamId,
            email: userFormData.email,
            role: 'admin',
            createdAt: new Date().toISOString().replace('T', ' '),
            workspaces: [],
          };
          return this._sendsInvitationDto.sendInvitation(newUserData).pipe(
            switchMap(() =>
              this._selectsUsersContextStorage.select().pipe(
                take(1),
                map(userContext => userContext.list),
              ),
            ),
            tap({
              next: (userList: UserDTO[]) => {
                const updatedUsers: UserDTO[] = [newUserData, ...userList];
                this._usersListChangedDispatcher.dispatch({ updatedUsers: [...updatedUsers] });
                // TODO when will be per workspace endpoint
                // this._teamMemberInvitedDispatcherPort.dispatch(teamId);
                this._alert.open('success', 'cbr_pro_members_invitation_sent');
              },
              error: err => {
                if (err instanceof HttpErrorResponse && err.status === 409) {
                  this._alert.open('error', 'cbr_pro_members_already_invited');
                } else {
                  this._alert.open('error', '_something_went_wrong');
                }
                return throwError(() => err);
              },
            }),
            map(() => void 0),
          );
        }),
      );
  }

  deleteUser(command: DeletesUserCommand): Observable<void> {
    return this._matDialog
      .open(ConfirmDeleteUserModalComponent)
      .afterClosed()
      .pipe(concatMap(result => (result ? this._deleteUser(command) : of(void 0))));
  }

  private _deleteUser(command: DeletesUserCommand): Observable<void> {
    return this._deletesUser
      .deleteUser({ id: command.userId, teamId: this._teamIdGetter.getTeamId() })
      .pipe(
        switchMap(() =>
          this._selectsUsersContextStorage.select().pipe(
            take(1),
            map(usersContext => usersContext.list),
          ),
        ),
        tap({
          next: (users: UserDTO[]) => {
            const updatedUsers: UserDTO[] = users.filter(user => user.id !== command.userId);
            this._usersListChangedDispatcher.dispatch({ updatedUsers: [...updatedUsers] });
            this._alert.open('success', 'cbr_pro_delete_team_member_success_message');
          },
          error: e => {
            this._alert.open('error', 'cbr_pro_delete_team_member_failed_message');
            return throwError(() => e);
          },
        }),
        map(() => void 0),
      );
  }

  resendInvitation(command: ResendsInvitationCommand): Observable<void> {
    return this._resendsInvitationDto
      .resendInvitation({ teamId: this._teamIdGetter.getTeamId(), id: command.userId })
      .pipe(
        take(1),
        tap({
          next: () => {
            this._alert.open('success', 'cbr_pro_members_invitation_sent');
          },
          error: () => {
            this._alert.open('error', '_something_went_wrong');
          },
        }),
      );
  }
}

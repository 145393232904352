import { InjectionToken } from '@angular/core';
import { UserDTO } from '../dto/user.dto';

export interface UsersListChangedDispatcherPort {
  dispatch(event: { updatedUsers: UserDTO[] }): void;
}

export const USERS_LIST_CHANGED_DISPATCHER = new InjectionToken<UsersListChangedDispatcherPort>(
  'USERS_LIST_CHANGED_DISPATCHER',
);

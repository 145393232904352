import { Inject, Injectable } from '@angular/core';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { OpenSetupFlowClickedEvent } from '../../../application/events/open-setup-flow-clicked.event';
import { OpenSetupFlowClickedDispatcherPort } from '../../../application/ports/secondary/dispatchers/open-setup-flow-clicked.dispatcher-port';

@Injectable()
export class OpenSetupFlowEventDispatcher implements OpenSetupFlowClickedDispatcherPort {
  constructor(@Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus) {}

  dispatch(event: { product: string }) {
    this._applicationBus.dispatch(new OpenSetupFlowClickedEvent(event.product));
  }
}

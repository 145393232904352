import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, GetsConfig } from '@app.cobiro.com/core/utils';
import { Observable } from 'rxjs';
import { Country, GetsCountries } from '../../domain/gets-countries';

@Injectable()
export class HttpGetsCountriesService implements GetsCountries {
  constructor(
    private readonly client: HttpClient,
    @Inject(ENV_CONFIG) private readonly getsConfig: GetsConfig,
  ) {}

  get(): Observable<Country[]> {
    return this.client.get<Country[]>(
      `${this.getsConfig.get('cdn')}/v1/contents/countries/index.json`,
    );
  }
}

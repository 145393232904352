import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HuiKeyboardBlockModule } from '@app.cobiro.com/shared/hui/keyboard-block';
import { HuiStepperModule } from '@app.cobiro.com/shared/hui/stepper';
import { UpgradeFlowStepsModule } from '../upgrade-flow-steps/upgrade-flow-steps.module';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { UpgradePlanFlowContainerComponent } from './upgrade-plan-flow-container.component';
import { ConfirmationStepComponentModule } from '../upgrade-flow-steps/confirmation-step/confirmation-step-component.module';

@NgModule({
  declarations: [UpgradePlanFlowContainerComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    UpgradeFlowStepsModule,
    HuiStepperModule,
    CdkStepperModule,
    HuiKeyboardBlockModule,
    LanguageModule,
    MatIconModule,
    ConfirmationStepComponentModule,
  ],
  exports: [UpgradePlanFlowContainerComponent],
})
export class UpgradePlanFlowModule {}

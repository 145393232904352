<section class="app-main">
  <lib-layout>
    <router-outlet routerOutlet (activate)="onRouteActivated()"></router-outlet>
  </lib-layout>
</section>

<!-- The app-preloader class is there for a reason - please DO NOT remove it  -->
<div class="app-preloader" *ngIf="preloaderVisible$ | async">
  <span [huiLoading]="true"></span>
</div>

<ngx-guided-tour
  skipText="Skip tour"
  [progressIndicatorLocation]="guidedTourProgressIndicatorLocation"
  [progressIndicator]="guidedTourProgressIndicator"
  data-selector="lib-tour"
  lib-tour
></ngx-guided-tour>
<ng-template
  #guidedTourProgressIndicator
  let-currentStepNumber="currentStepNumber"
  let-totalSteps="totalSteps"
>
  Step {{ currentStepNumber }} of {{ totalSteps }}
</ng-template>

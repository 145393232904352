import { Inject, Injectable } from '@angular/core';
import { LoadUserAdditionalDataCommandPort } from '../ports/primary/load-user-additional-data-command.port';
import { BehaviorSubject, zip } from 'rxjs';
import {
  GET_USER_TEAMS_DTO,
  GetsUserTeamsDtoPort,
} from '../ports/secondary/gets-user-teams-dto.port';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  CobiroProTeamsLoadedEvent,
  UserAdditionalDataChangedEvent,
} from '@app.cobiro.com/core/events';
import {
  USER_ADDITIONAL_DATA_CHANGED_DISPATCHER,
  UserAdditionalDataChangedDispatcherPort,
} from '../ports/secondary/user-additional-data-changed.dispatcher-port';
import { APPLICATION_BUS, Dispatcher } from '@cobiro/eda';
import { UserTeamDto } from '../ports/secondary/user-team.dto';

@Injectable()
export class UserAdditionalDataState implements LoadUserAdditionalDataCommandPort {
  private readonly _loaded$ = new BehaviorSubject<boolean>(false);
  constructor(
    @Inject(GET_USER_TEAMS_DTO) private readonly _getUserTeamsDto: GetsUserTeamsDtoPort,
    @Inject(USER_ADDITIONAL_DATA_CHANGED_DISPATCHER)
    private readonly _dispatcher: UserAdditionalDataChangedDispatcherPort,
    @Inject(APPLICATION_BUS) private readonly _teamsLoaded: Dispatcher<CobiroProTeamsLoadedEvent>,
  ) {}

  // TODO: it should be decoupled
  loadUserAdditionalData(): void {
    this._loaded$
      .pipe(
        take(1),
        filter(loaded => !loaded),
        switchMap(() => zip(this._getUserTeamsDto.getUserTeams())),
        tap(this._sendUserAdditionalDataChangedEvent.bind(this)),
      )
      .subscribe();
  }

  private _sendUserAdditionalDataChangedEvent([userTeams]: [UserTeamDto[]]): void {
    this._loaded$.next(true);
    const teams = this._adaptTeams(userTeams);
    // TODO: it shouldnt know about pro
    this._teamsLoaded.dispatch(new CobiroProTeamsLoadedEvent(teams));
    this._dispatcher.dispatch(new UserAdditionalDataChangedEvent(userTeams.length > 0));
  }

  private _adaptTeams(
    userTeams: UserTeamDto[],
  ): { id: string; name: string; avatar: string; role: string; membersCount: number }[] {
    return userTeams.map(userTeam => ({
      id: userTeam.id,
      role: userTeam.role,
      avatar: userTeam.avatar,
      name: userTeam.name,
      membersCount: userTeam.membersCount,
    }));
  }
}

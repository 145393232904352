import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { DeleteTeamUserModalComponent } from './delete-team-user-modal.component';

@NgModule({
  imports: [CommonModule, LanguageModule, MatDialogModule, MatIconModule],
  declarations: [DeleteTeamUserModalComponent],
})
export class DeleteTeamUserModalComponentModule {}

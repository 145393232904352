import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiAlertModule } from '@app.cobiro.com/shared/hui/alert/hui-alert.module';
import { HuiDebounceClickModule } from '@app.cobiro.com/shared/hui/debounce-click';
import { UserCoreModule, VerifyCodeFormComponentModule } from '@app.cobiro.com/user/core';
import { SocialSignUpModule } from '@app.cobiro.com/user/social-sign-up';
import { RegisterPageComponent } from './adapters/primary/ui/register-page/register-page.component';
import { VerifyPageComponent } from './adapters/primary/ui/verify-page/verify-page.component';
import { CoreUsersRegisterModule } from './core-users-register.module';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    MatIconModule,
    HuiDebounceClickModule,
    HuiAlertModule,
    RouterModule,
    UserCoreModule,
    SharedAnalyticsModule,
    CoreUsersRegisterModule.forRoot(),
    SocialSignUpModule,
    VerifyCodeFormComponentModule,
  ],
  declarations: [RegisterPageComponent, VerifyPageComponent],
  exports: [VerifyPageComponent],
})
export class UserRegisterModule {}

import { Observable } from 'rxjs';
import { SaveSelectedClientCommand } from './save-selected-client.command';
import { InjectionToken } from '@angular/core';

export const SAVES_SELECTED_CLIENT_COMMAND_PORT =
  new InjectionToken<SavesSelectedClientCommandPort>('SAVES_SELECTED_CLIENT_COMMAND_PORT');

export interface SavesSelectedClientCommandPort {
  saveSelectedClient(command: SaveSelectedClientCommand): Observable<void>;
}

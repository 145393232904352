import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ChecksUserInvitationCommand } from './checks-user-invitation.command';

export const CHECKS_USER_INVITATION_COMMAND = new InjectionToken<ChecksUserInvitationCommandPort>(
  'CHECKS_USER_INVITATION_COMMAND',
);

export interface ChecksUserInvitationCommandPort {
  checksUserInvitation(command: ChecksUserInvitationCommand): Observable<boolean>;
}

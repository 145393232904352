import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TeamSettingsFormComponent } from './team-settings-form.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { HuiAlertModule } from '@app.cobiro.com/shared/hui/alert';
import { SharedImageUploadModule } from '@app.cobiro.com/shared/image-upload';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TeamSettingsFormErrorKeyPipe } from '../../pipes/team-settings-form-error-key.pipe';
import { HuiLoaderContainerModule } from '@app.cobiro.com/shared/hui/loader-container';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LanguageModule,
    HuiAlertModule,
    SharedImageUploadModule,
    RouterModule.forChild([{ path: '', component: TeamSettingsFormComponent }]),
    MatAutocompleteModule,
    HuiLoaderContainerModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatOptionModule,
  ],
  declarations: [TeamSettingsFormComponent, TeamSettingsFormErrorKeyPipe],
})
export class TeamSettingsFormComponentModule {}

import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export class Token {
  constructor(
    public readonly accessToken: string,
    public readonly refreshToken: string,
    public readonly expiresIn: string,
    public readonly tokenType: string,
  ) {}
}

export interface RefreshesToken {
  refresh(refreshToken: string): Observable<Token>;
}

export const REFRESHES_TOKEN = new InjectionToken<RefreshesToken>('REFRESHES_TOKEN');

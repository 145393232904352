import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const CANCEL_USER_DELETION_QUERY = new InjectionToken<CancelUserDeletionQueryPort>(
  'CANCEL_USER_DELETION_QUERY',
);

export interface CancelUserDeletionQueryPort {
  cancelUserDeletion(): Observable<void>;
}

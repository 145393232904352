import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LinksGoogleAdsLinkingDto } from './links-google-ads-account.dto';

export const LINKS_GOOGLE_ADS_ACCOUNT_DTO_PORT = new InjectionToken<LinksGoogleAdsAccountDtoPort>(
  'LINKS_GOOGLE_ADS_ACCOUNT_DTO_PORT',
);

export interface LinksGoogleAdsAccountDtoPort {
  linkGoogleAdsAccount(dto: LinksGoogleAdsLinkingDto): Observable<boolean>;
}

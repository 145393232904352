import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import {
  GetsUserDetailsQueryPort,
  UserDetailsQuery,
} from '@app.cobiro.com/cobiro-pro-rewrite/layout';
import { map } from 'rxjs/operators';

interface UserDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isGuest: boolean;
  createdAt: Date;
  avatar: string;
}

@Injectable()
export class GetsUserDetailsService implements GetsUserDetailsQueryPort {
  public readonly userDetails$ = new BehaviorSubject<UserDetails | null>(null);

  getUserDetails(): Observable<UserDetailsQuery> {
    return this.userDetails$.asObservable().pipe(
      filter(Boolean),
      map(
        data =>
          new UserDetailsQuery(
            data.id,
            data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : data.email,
            data.email,
            data.firstName,
            data.lastName,
            data.avatar,
            data.phoneNumber,
          ),
      ),
    );
  }
}

import { ApplicationEvent } from '@cobiro/eda';

export class CobiroProClientSelectedEvent extends ApplicationEvent {
  constructor(
    readonly payload: {
      id: string;
      siteId: string;
      name: string;
      teamId: string;
      avatar: string | null;
      sitePublicId: string;
    } | null,
  ) {
    super();
  }
}

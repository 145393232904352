import { Component, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  CancelUserDeletionQueryPort,
  CANCEL_USER_DELETION_QUERY,
} from '../../../../application/ports/primary/cancel-user-deletion.query-port';
import {
  DeletesUserQueryPort,
  DELETES_USER_QUERY,
} from '../../../../application/ports/primary/deletes-user.query-port';
import {
  DisablesEmailNotificationQueryPort,
  DISABLES_EMAIL_NOTIFICATION_QUERY,
} from '../../../../application/ports/primary/disables-email-notification.query-port';

@Component({
  selector: 'lib-cobiro-pro-close-account',
  templateUrl: './close-account.component.html',
  styleUrls: ['./close-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroProCloseAccountComponent implements OnDestroy {
  private _ngDestroy$ = new Subject<void>();

  readonly form = this._fb.group({
    password: this._fb.control('', Validators.required),
  });

  readonly isDeleting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _fb: UntypedFormBuilder,
    @Inject(DELETES_USER_QUERY)
    private readonly _deleteUser: DeletesUserQueryPort,
    @Inject(CANCEL_USER_DELETION_QUERY)
    private readonly _cancelUserDeletion: CancelUserDeletionQueryPort,
    @Inject(DISABLES_EMAIL_NOTIFICATION_QUERY)
    private readonly _disableEmailNotification: DisablesEmailNotificationQueryPort,
  ) {}

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.isDeleting$.next(true);
    const { password } = this.form.value;
    this._deleteUser
      .deleteUser(password)
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe(() => this.isDeleting$.next(false));
  }

  onCancelDeleteClicked(): void {
    this._cancelUserDeletion.cancelUserDeletion().pipe(takeUntil(this._ngDestroy$)).subscribe();
  }

  onDisableEmailNotificationsClicked(): void {
    this._disableEmailNotification
      .disableEmailNotifications()
      .pipe(takeUntil(this._ngDestroy$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}

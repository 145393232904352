import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { InitsNonCssIntegrationCommand } from './inits-non-css-integration.command';

export const INITS_NON_CSS_INTEGRATION_COMMAND =
  new InjectionToken<InitsNonCssIntegrationCommandPort>('INITS_NON_CSS_INTEGRATION_COMMAND');

export interface InitsNonCssIntegrationCommandPort {
  initNonCssIntegration(command: InitsNonCssIntegrationCommand): Observable<void>;
}

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GetsUserDetailsDto } from './gets-user-details.dto';
import { UserDetailsDTO } from './user-details.dto';

export interface GetsUserDetailsDtoPort {
  getUserDetails(dto: GetsUserDetailsDto): Observable<UserDetailsDTO>;
}

export const GETS_USER_DETAILS_DTO = new InjectionToken<GetsUserDetailsDtoPort>(
  'GETS_USER_DETAILS_DTO',
);

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AddNewShopClickedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/home';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';

@Injectable()
export class AddNewShopClickedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [AddNewShopClickedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(TEAM_ID_GETTER) private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  handle([event]: [AddNewShopClickedEvent]): void {
    this._router.navigate(['/', 'connect', this._teamIdGetter.getTeamId(), 'clients'], {
      queryParams: { add: true },
    });
  }
}

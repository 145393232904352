import { Observable } from 'rxjs';
import { PaginationQuery } from '@app.cobiro.com/core/pagination';
import { InjectionToken } from '@angular/core';

export interface GetsLatestClientsListPaginationQueryPort {
  getClientListPagination(): Observable<PaginationQuery>;
}

export const GETS_LATEST_CLIENTS_LIST_PAGINATION_QUERY =
  new InjectionToken<GetsLatestClientsListPaginationQueryPort>(
    'GETS_LATEST_CLIENTS_LIST_PAGINATION_QUERY',
  );

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SkipsCompanyDetailsStepCommand } from './skips-company-details-step.command';

export const SKIPS_COMPANY_DETAILS_STEP_COMMAND =
  new InjectionToken<SkipsCompanyDetailsStepCommandPort>('SKIPS_COMPANY_DETAILS_STEP_COMMAND');

export interface SkipsCompanyDetailsStepCommandPort {
  skipCompanyDetailsStep(command: SkipsCompanyDetailsStepCommand): Observable<void>;
}

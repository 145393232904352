import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceListComponent } from './workspace-list.component';
import { RouterModule } from '@angular/router';
import { WorkspaceListControlComponentModule } from './workspace-list-control/workspace-list-control.component-module';
import { WorkspaceListTableComponentModule } from './workspace-list-table/workspace-list-table.component-module';

@NgModule({
  declarations: [WorkspaceListComponent],
  imports: [
    CommonModule,
    WorkspaceListControlComponentModule,
    WorkspaceListTableComponentModule,
    RouterModule.forChild([{ path: '', component: WorkspaceListComponent }]),
  ],
  exports: [WorkspaceListComponent],
})
export class WorkspaceListComponentModule {}

<form
  class="cs-form"
  [formGroup]="form"
  (ngSubmit)="onFormSubmit()"
  data-selector="switch-integration-form"
>
  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label">{{
      'google_css_integration_google_ads_id_input_label' | language
    }}</label>
    <input
      type="text"
      class="cs-input"
      data-selector="switch-integration-form-ads-id-input"
      formControlName="adsId"
      [placeholder]="'google_css_integration_google_ads_id_input_placeholder' | language"
      huiInputError
    />
    <p
      *ngIf="form.get('adsId').touched && form.get('adsId').hasError(wrongAdsIdErrorKey)"
      class="cs-control-error"
    >
      Wrong Ads ID format. Allowed are: XXX-XXX-XXXX or 10-characters text, without dashes.
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label">{{ '_email' | language }}</label>
    <input
      type="text"
      formControlName="managerEmail"
      data-selector="switch-integration-form-manager-email-input"
      class="cs-input"
      [placeholder]="'_email' | language"
      huiInputError="managerEmail"
    />
    <p
      *ngIf="
        form.controls.managerEmail.invalid &&
        form.controls.managerEmail.touched &&
        form.controls.managerEmail.hasError('invalidField')
      "
      class="cs-control-error"
    >
      {{ '_invalid_email' | language }}
    </p>
  </div>

  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label">{{ 'google_css_integration_business_input_label' | language }} </label>
    <input
      type="text"
      class="cs-input"
      formControlName="websiteName"
      data-selector="switch-integration-form-website-name-input"
      huiInputError="websiteName"
    />
  </div>

  <ng-container *ngIf="cssMerchantIds$ | async as cssMerchantIds">
    <div *ngIf="cssMerchantIds.length > 1" class="cs-control-wrapper cs-mb-20">
      <label class="cs-label" for="cssDomainId">
        {{ 'google_css_integration_css_domain_label' | language }}
      </label>
      <mat-form-field class="cs-dropdown cs-text-1 cs-c-shade-100">
        <mat-select
          disableRipple="true"
          formControlName="cssDomainId"
          id="cssDomainId"
          panelClass="cs-dropdown-options"
        >
          <mat-option
            *ngFor="let cssMerchantId of cssMerchantIds"
            [value]="cssMerchantId.cssDomainId"
          >
            {{ cssMerchantId.cssDomainName | language }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p
        *ngIf="form.controls.cssDomainId.invalid && form.controls.cssDomainId.touched"
        class="cs-control-error cs-p-0"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>
  </ng-container>

  <div class="cs-control-wrapper cs-mb-30">
    <label class="cs-label"
      >{{ 'google_css_integration_merchant_id_input_label' | language }}
    </label>
    <input
      type="text"
      class="cs-input"
      formControlName="merchantId"
      [placeholder]="'google_css_integration_merchant_id_input_placeholder' | language"
      data-selector="switch-integration-form-merchant-id-input"
      huiInputError
    />
    <p
      *ngIf="
        form.get('merchantId').touched && form.get('merchantId').hasError(wrongMerchantIdErrorKey)
      "
      class="cs-control-error"
    >
      Wrong merchant ID format. Allowed are only digids and total length must be 7-10 digits.
    </p>
  </div>

  <div class="d-flex justify-content-end cs-mt-40">
    <button
      class="cs-btn cs-btn-primary cs-width-160-xs"
      data-selector="switch-integration-form-submit"
      type="submit"
    >
      {{ '_continue' | language }}
    </button>
  </div>
</form>

import { Inject, Injectable } from '@angular/core';
import { GetsSettings, Settings } from '../domain/gets-settings';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { Observable } from 'rxjs';
import { HasData } from '@cobiro/jsonapi';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';
import { map } from 'rxjs/operators';

export interface SettingsAttributes {
  weeklySummary: boolean;
}

@Injectable()
export class HttpGetsSettingsService implements GetsSettings {
  constructor(
    private _client: GatewayClient,
    @Inject(GETS_USER_FROM_TOKEN) private _getsUser: GetsUserFromToken,
  ) {}

  get(): Observable<Settings> {
    const userId = this._getsUser.get()?.userId;

    if (!userId) {
      throw new Error('User id is missing');
    }

    return this._client
      .get<HasData<SettingsAttributes>>(`v1/users/${userId}/settings`)
      .pipe(map(response => new Settings(response.data.attributes.weeklySummary)));
  }
}

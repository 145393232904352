import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SendsInvitationCommand } from './sends-invitation.command';

export const SENDS_INVITATION_COMMAND = new InjectionToken<SendsInvitationCommandPort>(
  'SENDS_INVITATION_COMMAND',
);

export interface SendsInvitationCommandPort {
  sendInvitation(command: SendsInvitationCommand): Observable<void>;
}

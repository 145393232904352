import { ApplicationEvent } from '@cobiro/eda';

export class PlanLoadedEvent extends ApplicationEvent {
  constructor(
    public readonly plan: string,
    public readonly period: string,
    public readonly strategistVolume: string,
  ) {
    super();
  }
}

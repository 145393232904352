import { NgModule } from '@angular/core';
import { GETS_PAYMENTS_SOURCES_DTO_PORT } from '../../../../application/ports/secondary/gets-payment-sources.dto-port';
import { GETS_VAT_NUMBER_VALIDATION_DTO } from '../../../../application/ports/secondary/gets-vat-number-validation.dto-port';
import { HttpPaymentsService } from './http-payments.service';

@NgModule({
  providers: [
    HttpPaymentsService,
    { provide: GETS_VAT_NUMBER_VALIDATION_DTO, useExisting: HttpPaymentsService },
    {
      provide: GETS_PAYMENTS_SOURCES_DTO_PORT,
      useClass: HttpPaymentsService,
    },
  ],
})
export class HttpPaymentsServiceModule {}

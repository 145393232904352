import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientDTO } from '../../secondary/dto/clients/client.dto';

export const LOAD_CLIENTS_COMMAND = new InjectionToken<LoadClientsCommandPort>(
  'LOAD_CLIENTS_COMMAND',
);

export interface LoadClientsCommandPort {
  loadClients(): Observable<ClientDTO[]>;
}
